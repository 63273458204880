import React,{useState,useEffect} from 'react' 
import Loader from 'react-loader-spinner'
import {Trans} from 'react-i18next'
export const EditZoomInfo= (props)=>{
    let [zoomName, setzoomName] = useState(props.user.zoom_user_name)
    let [zoomPassword, setZoomPassword] = useState(props.user.zoom_password)
    let [zoomUrl, setzoomUrl] = useState(props.user.personal_zoom_meeting_link)

    return(
        <>
        <div class="card-header"><Trans>editZoom</Trans></div>
        <div class="card-body border-bottom rounded-0">
            <form action = "">
                <div class="form-group row">
                    <div class="col-md-6">
                        <label ><Trans>zoomUserName</Trans>:<span class="text-danger"></span></label>
                        <input defaultValue = "" disabled = {props.loading} autocomplete="off" id = "zoomUserName" type="text" class= {props.eOldPassword == ""?"form-control":"form-control text-red-border"} value = {zoomName == null ? "" :zoomName}  placeholder="Enter Your Zoom Name" onChange = {(e)=>{
                            setzoomName(e.target.value)

                        }} />
                        {props.eOldPassword != '' ? <div class="invalid-feedback">{props.eOldPassword}</div> : null}
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6">
                        <label  for=""><Trans>zoomPassword</Trans>:<span class="text-danger"></span></label>
                        <input defaultValue = "" disabled = {props.loading} autocomplete="off" id = "zoomPassword" type="password" class= {props.ePassword == ""?"form-control":"form-control text-red-border"} value = {zoomPassword == null ? "" :zoomPassword} placeholder="Enter Your Zoom Password" onChange = {(e)=>{
                             setZoomPassword(e.target.value)
                        }} />
                        {props.ePassword != '' ? <div class="invalid-feedback">{props.ePassword}</div> : null}
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>zoomPersonalLink</Trans>:<span class="text-danger"></span></label>
                        <input defaultValue = "" disabled = {props.loading} autocomplete="off" id = "ZoomLink" type="text" class= {props.eCPassword == ""?"form-control":"form-control text-red-border"} value = {zoomUrl == null ? "" :zoomUrl} placeholder="Enter Your Zoom Link"  onChange = {(e)=>{
                            setzoomUrl(e.target.value)
                        }} />
                        {props.eCPassword != '' ? <div class="invalid-feedback">{props.eCPassword}</div> : null}
                    </div>
                </div>
                
                <div style = {{display : 'flex',flexDirection : 'row'}}>  
                    <div class="form-group">
                        <a style = {{pointerEvents : props.loading ? 'none' : 'auto'}} onClick = {props.onClickSaveZoomInfo} class="btn bg_main btn-tele text-light px-5 rounded-0">
                            <Trans>save</Trans></a>
                    </div>
                    {props.loading ?
                    <div style = {{marginTop : 6,marginLeft : 12}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {30}
                            width = {30}
                        />
                    </div> : null}
                </div>
                {props.changePassError != '' ?
                    <div className="alert alert-danger contactUsAlert">{props.changePassError}</div>:null}
                {props.successMessage != '' ?
                    <div className="alert alert-success contactUsAlert">{props.successMessage}</div>:null}
            
            </form>
        </div>
        </>
    )
}