import React, { useEffect, useState } from "react";
// import './styles.css'
import { compose } from "redux";
import Footer2 from "../../Footer/Footer2";
import { getForumData, saveForum, deleteForum } from "../actions/index";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import isOnline  from 'is-online';
import io from "socket.io-client";
import VirtualZone from "../../Header/virtualZone";
import Loader from "react-loader-spinner";
import { Constants } from "../../../Constants";
import Header from "../../Header/Index";
import ForumContent from "./forum";
import Empty from "../empty-container/index";
import ErrorBoundary from '../../ErrorBoundry/index'
import $ from "jquery";
import  PermiumUserModal  from "../../modals/premiumUserModal";
import DeleteModel from "../deleteModel";
import utitlity, {
  countNumberOfUser,
  dateExctrator,
  forumfullName,
  wordsRestrictor,
  getprofileImage,
} from "../../../Utility/utility";
import { fetchPagesData } from "../api";

const Forum = (props) => {
  const [selectedIndex, setIndex] = useState(
    props.headerMenuItems.findIndex((x) => x.href.indexOf("/forum") > -1)
  );
  const [virtualZoneItemIndex,setVirtualZoneIndex] =  useState(props.virtualMenuItems.findIndex(x=>x.path == '/forum'))
  const [loading, setLoading] = useState(false);
  const [availableLocales, setAvaliableLocales] = useState(
    props.availableLocales
  );
  const [forum, setForum] = useState([]);
  const [subject, setSubject] = useState("");
  const [maxsubjectLimit, setSubjectLimit] = useState(200);
  const [description, setDiscription] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [communityQuideLine, setCommuintyQuideLine] = useState(0);
  const [detailedPage, setDetailedPage] = useState(false);
  const [selectedForumId, setForumId] = useState(null);
  const [forumAdminId, setForumAdminId] = useState(null);
  const [numberOfDefaultForum, setDefaultForum] = useState(5);
  const [emailNotificationEnable, setEmailnotification] = useState(0);
  const [deletedItem, setDeletedItem] = useState(null);
  const [userInfo, setUserInfo] = useState("");
  const [userAlert, setUsert] = useState(false);
  const [forumInfo, setForumInfo] = useState({});
  const [guideLineCheckbox, setGuildeline] = useState(false);
  const [enableEmail, setEnableemail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectdeForumIndex,setForumIndex] = useState(null)
  const [pageTitle,setPageTitle] = useState('');
  const [pageDescription,setPageDescription] = useState('')
  var user = utitlity.getLoggedInUser(props.event.alias);
  var socket = props.socket;
  if (socket == null) {
    let socketURL = Constants.socketUrl;
    if (user != null) {
      socketURL +=
        "?userId=" +
        user.id +
        "&userName=" +
        (user.firstName + " " + user.lastName) +
        "&eventAlias=" +
        props.event.alias;
      socket = io.connect(Constants.socketUrl, {
        forceNew: true,
        upgrade: false,
        transports: ["websocket"],
      });
    }
  }

  useEffect(() => {
    window.scroll(0,0)
    async function fetchData() {
      let prams = {
        type:'forum'
      }
      await fetchPagesData(props.event.alias,prams,null,(err,data)=>{
        if(data){
          setPageTitle(data.data.title)
          setPageDescription(data.data.description)
        }
      })
    }
    fetchData();
    if(props.forumdata.length > 0){
    setForum([...props.forumdata])
    }else{
    if (user != null) {
      props.getForumData(props.event.alias, user.token);
    } else {
      props.getForumData(props.event.alias);
    }
  }
    searchForum();
  }, []);
  useEffect(() => {
    window.scroll(0,0)
    if (props.loadingSaveForum == false) {
      document.getElementById("forumCancel").click();
      setSubject("");
      setDiscription("");
      setEnableemail(false);
      setEmailnotification(false);
      setGuildeline(false);
      setCommuintyQuideLine(false);
    }
    if (deletedItem != null) {
      let deletedForum = props.forumdata.filter(
        (item) => item.id != deletedItem
      );
      setForum([...deletedForum]);
      setDeletedItem(null);
    } else {
      if (
        document.getElementById("forumDelete") != undefined &&
        document.getElementById("forumDelete") != null
      ) {
        document.getElementById("forumDelete").click();
      }
      setLoading(props.loadingSaveForum);
      setForum([...props.forumdata]);
    }
    if (props.forumdata != forum && forum.length > 0) {
      socket &&
        socket.emit("updateForum", {
          data: props.forumdata,
          id: props.event.id,
        });
      setSearchQuery("")
    }
  }, [props.forumdata]);

  // useEffect(() => {
  //   searchForum();
  // });

  const OnNavigateToPage = (path) => {
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    }
    props.history.push(path);
  };
  // if(socket != undefined )
  socket &&
    socket.on("updateForum", (data) => {
      if (props.event.id == data.id) {
        setForum([...data.data]);
      }
    });
  const forumDeleted= (id) => {
    // setDeletedItem(id)
    let params = {
      topicId: id,
    };
    props.deleteForum(props.event.alias, params, user.token, forum, id);
  };
  const clearForumData = () => {
    setForumInfo({});
  };
  const showDetailedPage = (value) => {
    setDetailedPage(value);
  };
  const searchForum = () => {
    if (searchQuery.length > 0) {
      let filterForum = props.forumdata.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setForum([...filterForum]);
    }
     else if(searchQuery.length  == 0) {
      setForum([...props.forumdata]);
     }
  };
  const OnClickAccountItem = (index, path) => {
    props.onClickAccountItem(index, path);
    if (index > -1) {
      props.history.replace("");
      props.history.push({
        pathname: props.event.alias + "/account",
        state: { tabIndex: index },
      });
    } else {
      localStorage.removeItem(
        Constants.API_TOKEN + props.event.alias + "user_info"
      );
      window.location.reload();
    }
  };
  const getClasses = ()=>{
    let user = utitlity.getLoggedInUser(props.event.alias)
    if(user == null){
        return 'telehealthBooking content-data'
    }
    else if(user != null && user.isBooking == false){
        return 'telehealthBooking content-data'
    }
    else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
      return 'telehealthBooking content-data'
  }
    else{
        return ''
    }
  }
  return (
    <>
     <ErrorBoundary event={props.event}>
      <DeleteModel
        id="deleteModal"
        forumInfo={forumInfo}
        loadingSaveForum={props.loadingSaveForum}
        forumDeleted={forumDeleted}
        clearForumData={clearForumData}

      />

      <div id="header-top">
        <Header
          selectedIndex={selectedIndex}
          selectedSubIndex = {-1}
          onMenuItemClick={OnNavigateToPage}
          elements={props.headerMenuItems}
          isMarketplace={props.event.is_marketplace}
          facebookLink={props.event.facebook}
          linkedInLink={props.event.linkedIn}
          twitterLink={props.event.twitter}
          instagramLink={props.event.instagram}
          googleLink={props.event.google}
          phone={props.event.phone_number}
          event = {props.event}
          eventLogo={props.event.logo}
          eventName={props.event.name}
          eventAlias={props.event.alias}
          availableLocales={availableLocales}
          languageClick={props.languageClick}
          onLoginClick={() => OnNavigateToPage(props.event.alias + "/login")}
          onSignupClick={() => OnNavigateToPage(props.event.alias + "/sign_up")}
          onClickAccountItem={OnClickAccountItem}
        />
        {user != null && user.isBooking && Constants.showVirtualZone ? (
          <VirtualZone
          selectedItemIndex={props.virtualMenuItems.findIndex(x=>x.path == '/forum')}
            eventAlias={props.event.alias}
            event = {props.event}
            onMenuItemClick={(path) => {
              if (path != "") {
                OnNavigateToPage(path);
              }
            }}
            unReadCount={props.unReadCount}
            onNotificationSeen={props.onNotificationSeen}
            onClearNotifications={props.onClearNotifications}
            notificationsCount={props.unReadNotificationsCount}
            notifications={props.notifications}
            onClickAccountItem={OnClickAccountItem}
            onClickNotification={(path, isExternal) => {
              if (isExternal) {
                window.open(path, "_blank");
              } else {
                OnNavigateToPage(this.props.event.alias + path);
              }
            }}
            menuItems={props.virtualMenuItems}
            user={user}
          />
        ) : null}
      </div>
      <PermiumUserModal
        id="premiumSession"
        iconClass="fa-star"
        title="Premium Content"
        description="<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
        buttonText="Join Now"
        showButton={true}
        onClose = {()=>{
          $("#premiumSession").modal('hide')
          $('.modal-backdrop').remove();
      }}
        onClickBookNow={() => {
          $("#premiumSession").modal("hide");
          $(".modal-backdrop").remove();
          OnNavigateToPage(`${props.event.alias}/membership`);
        }}
      />
      <PermiumUserModal
        id="loginModal"
        iconClass="fa-lock"
        title="User Login"
        description="<p>Please Login before create Topic.</p>"
        buttonText="Login"
        onClose = {()=>{
          $("#loginModal").modal("hide");
          $(".modal-backdrop").remove();
      }}
        showButton={true}
        onClickBookNow={() => {
          $("#loginModal").modal("hide");
          $(".modal-backdrop").remove();
          OnNavigateToPage(`${props.event.alias}/login`);
        }}
      />
      <div
        class="modal fade"
        id="c-topic-modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered ct-modal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header position-relative">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Create Topic
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="forumCancel"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body pb-1">
              <form class="create-topic-form">
                <div class="form-group">
                  <span class="text-counter">
                    <em class="jQTAreaCount">{subject.length}</em> /{" "}
                    <em class="jQTAreaValue">{maxsubjectLimit}</em>
                  </span>
                  <label>Title</label>
                  <input
                    type="text"
                    class="form-control"
                    value={subject}
                    maxLength={maxsubjectLimit}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    rows="5"
                    class="form-control"
                    maxLength={3000}
                    value={description}
                    onChange={(e) => {
                      setDiscription(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div class="alert alert-info" role="alert">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      checked={enableEmail == true ? true : false}
                      onChange={(e) => {
                        setEmailnotification(!emailNotificationEnable);
                        setEnableemail(!enableEmail);
                      }}
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      Notify me via email
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck2"
                      checked={guideLineCheckbox == true ? true : false}
                      onChange={() => {
                        setCommuintyQuideLine(!communityQuideLine);
                        setGuildeline(!guideLineCheckbox);
                      }}
                    />
                    <label
                      class="form-check-label"
                      for="exampleCheck2"
                      id="guideLine"
                    >
                      I have read and understood the{" "}
                      <Link to="/terms-conditions" onClick = {()=>{
                        document.getElementById("forumCancel").click()
                      }}>Community Guidelines</Link>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            {userAlert == false ? null : (
              <div
                class="alert alert-danger mr-2 ml-2"
                role="alert"
                id="error-message"
              >
                {errorMessage}
              </div>
            )}
            <div class="modal-footer border-top-0 pt-0 pb-4">
              {props.loadingSaveForum == true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader type="Oval" color="#00b6ff" height={15} width={15} />
                </div>
              ) : null}
              <button
                type="button"
                class="btn btn-secondary"
                style={{
                  pointerEvents:
                    subject != "" && description != "" ? "auto" : "none",
                }}
                onClick={async() => {
                  isOnline().then((data)=>{
                    if(data ==  true){
                      if (
                        subject.trim().length > 0 &&
                        description.trim().length > 0
                      ) {
                        if (communityQuideLine == true) {
                          let params = {
                            message: description,
                            topic: subject,
                            mailNotificationsEnabled: emailNotificationEnable,
                          };
                          let data =
                            searchQuery.length > 0 ? props.forumdata : forum;
                          props.saveForum(
                            props.event.alias,
                            params,
                            user.token,
                            data
                          );
                        } else {
                          setErrorMessage(
                            "Please Accept Community Guideline first"
                          );
                          setUsert(true);
                          window.setTimeout(function () {
                            setUsert(false);
                            setErrorMessage("");
                          }, 2000);
                        }
                      } else {
                        setErrorMessage("Please Fill correct data first ...");
                        setDiscription("");
                        setSubject("");
                        setUsert(true);
                        window.setTimeout(function () {
                          setUsert(false);
                          setErrorMessage("");
                        }, 2000);
                      }
                    
                    }
                    else{
                      setErrorMessage("Please Check your Internet Connection");
                    setDiscription("");
                    setSubject("");
                    setUsert(true);
                    window.setTimeout(function () {
                      setUsert(false);
                      setErrorMessage("");
                    }, 2000);
                    }
                  }).catch((err)=>{
                  

                  });

                }}
              >
                Create Topic
              </button>
            </div>
          </div>
        </div>
      </div>
      {detailedPage == false ? (
        <>
          <section class={`telehealth-inner-banner bg_primary py-3`}>
            <div class="container py-2 text-center">
              <div class="row">
                <div class="col-12">
                  <h2>
                    <img
                      class="mr-3"
                      src="/images/telehealth-images/ic-forums.svg"
                      alt="kh-icon"
                    />
                    {pageTitle}
                  </h2>
                </div>
              </div>
            </div>
          </section>

          <section class="presentation-sec forums py-3 py-md-4">
            <div class="container pb-3 pb-md-5">
            <p style={{alignItems:'center'}}>{pageDescription}</p>
              <div class="row">
                <div class="col">

                  <h5>Topics</h5>
                </div>
                <div class="col text-right d-sm-none">
                  <button
                    class="btn create-topic"
                    id="create-topic"
                    data-toggle="modal"
                    // style={{pointerEvents: user && user.userRole == 'normalUser'?'none':'auto'}}
                    data-target={
                      user == null ? "#premiumSession" : "#c-topic-modal"
                    }
                    //dat-target="#c-topic-modal"
                  >
                    <i class="fas fa-pencil-alt mr-2"></i> Create Topic
                  </button>
                </div>
              </div>

              <div class="search-feild my-3">
                <div class="row">
                  <div class="col-sm-8 col-md-9 form-inline">
                    <div class="form-group has-clear">
                      <input
                        id="ppt-input"
                        type="text"
                        class="form-control search-input"
                        placeholder="Type your keyword"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          if (e.target.value.length == 0) {
                            setForum([...props.forumdata]);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            searchForum();
                          }
                        }}
                      />
                      <span
                        class={`form-control-clear ${
                          searchQuery.length > 0 ? "" : "d-none"
                        }`}
                        onClick={() => {
                          setSearchQuery("");
                          setForum([...props.forumdata]);
                        }}
                      >
                        <i class="fas fa-times"></i>
                      </span>
                      <button
                        type="submit"
                        class="btn search-btn"
                        style={{pointerEvents:`${searchQuery.trim().length>0 ? "auto":'none'}`}}
                        onClick={() => {
                          searchForum();
                        }}
                      >
                        <i class="fas fa-search mr-2"></i>
                        <span>Search</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3 text-right d-none d-sm-block">
                    <button
                      class="btn create-topic"
                      id="create-topic2"
                      data-toggle="modal"
                      data-target={
                        user == null
                          ? "#loginModal"
                          : user && user.isBooking == false
                          ? "#premiumSession"
                          : "#c-topic-modal"
                      }
                      // data-target="#c-topic-modal"
                    >
                      <i class="fas fa-pencil-alt mr-2"></i> Create Topic
                    </button>
                  </div>
                </div>
              </div>
              {props.loading == false ? (
                forum.length > 0 ? (
                  <>
                    <div class="row mt-3">
                      <div class="col-12">
                        <p>{userInfo}</p>
                        <div class="forums-community">
                          {forum.slice(0, numberOfDefaultForum).map((item,index) => {
                            return (
                              <div
                                class="row py-3 mx-0"
                                onClick={() => {
                                  setForumId(item.id);
                                  setForumAdminId(item.user_id);
                                  setForumIndex(index)
                                  showDetailedPage(true);
                                }}
                              >
                                <a href="#">
                                  <div class="col-lg-7">
                                    <div class="forum-topic">
                                      <div class="c-user">
                                        {item.user.profileImageUrl != null ? (
                                          <img
                                            src={item.user.profileImageUrl}
                                            alt="c-user"
                                          />
                                        ) : (
                                          <img
                                            src="/images/logos/default-user.jpg"
                                            alt="c-user"
                                          />
                                        )}
                                      </div>
                                      <h6>{wordsRestrictor(item.title, 15)}</h6>
                                      <p>
                                        {`by ${
                                          item.user.firstName +
                                          " " +
                                          item.user.lastName
                                        }`}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-lg-5">
                                    <ul class="nav">
                                      <li class="nav-lsit">
                                        {dateExctrator(item.updated_at)}
                                      </li>
                                      <li class="nav-lsit pre-title">
                                        <div class="file-badge">
                                          <i class="fas fa-comment-dots mr-1"></i>
                                          {item.messages.length}
                                        </div>
                                      </li>
                                      <li class="nav-lsit pre-title">
                                        <div class="file-badge">
                                          <i class="fas fa-users mr-1"></i>
                                          {countNumberOfUser(item.messages)}
                                        </div>
                                      </li>
                                      <li class="nav-lsit pre-title">
                                        {user &&
                                        user.userRole == "organizer" ? (
                                          <div
                                            class="delete-topic-btn"
                                            data-toggle="modal"
                                            data-target="#deleteModal"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              let data = {
                                                forumId: item.id,
                                                forum: forum,
                                                userid: item.user_id,
                                                title: item.title,
                                                profile:item.user.profileImageUrl,
                                                name:item.user.firstName + " " + item.user.lastName
                                              };
                                              setForumInfo(data);
                                            }}
                                          >
                                            <img
                                              src="/images/telehealth-images/ic_trash.svg"
                                              alt="Delete"
                                            />
                                          </div>
                                          ) : null} 
                                      </li>
                                    </ul>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {forum.length != numberOfDefaultForum &&
                    forum.length > 5 ? (
                      <div class="row mt-3">
                        <div class="col text-center">
                          <button
                            class="btn create-topic"
                            onClick={() => {
                              let remainforum =
                                forum.length - numberOfDefaultForum;
                              if (remainforum > 5) {
                                setDefaultForum(numberOfDefaultForum + 5);
                              } else {
                                setDefaultForum(
                                  numberOfDefaultForum + remainforum
                                );
                              }
                            }}
                          >
                            View More Topics
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Empty
                    icon={
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="97.263"
                          height="79.662"
                          viewBox="0 0 97.263 79.662"
                        >
                          <g
                            id="forum_placeholder"
                            transform="translate(-7.986 -49)"
                          >
                            <path
                              id="Path_1232"
                              data-name="Path 1232"
                              d="M334.174,296.652H311.113a18.783,18.783,0,0,1-16.543-9.875,34.494,34.494,0,0,0,21.8-26.958,18.793,18.793,0,0,1,7.477,31.855Z"
                              transform="translate(-230.492 -169.556)"
                              fill="#0e9ac8"
                            />
                            <g
                              id="Group_2419"
                              data-name="Group 2419"
                              transform="translate(7.986 49)"
                            >
                              <path
                                id="Path_1233"
                                data-name="Path 1233"
                                d="M104.362,125.685l-8.369-4.036a20.422,20.422,0,0,0,4.985-13.343,20.17,20.17,0,0,0-4.153-12.323,20.525,20.525,0,0,0-9.221-6.8,36.014,36.014,0,1,0-61.809,20.706l-16.921,8.16a1.566,1.566,0,0,0,.681,2.977H51.832a35.859,35.859,0,0,0,11.548-1.9,20.346,20.346,0,0,0,17.242,9.532h23.061a1.566,1.566,0,0,0,.679-2.977ZM51.832,117.9H16.4l12.754-6.15a1.567,1.567,0,0,0,.38-2.562A32.882,32.882,0,1,1,51.832,117.9Zm28.79,7.633a17.215,17.215,0,0,1-14.263-7.565A36.146,36.146,0,0,0,87.094,92.344a17.224,17.224,0,0,1,5.2,28.623,1.566,1.566,0,0,0,.382,2.562l4.149,2Z"
                                transform="translate(-7.986 -49)"
                                fill="#0e9ac8"
                              />
                              <path
                                id="Path_1234"
                                data-name="Path 1234"
                                d="M167.146,193h-37.58a1.566,1.566,0,0,0,0,3.132h37.58a1.566,1.566,0,0,0,0-3.132Z"
                                transform="translate(-104.51 -164.815)"
                                fill="#0e9ac8"
                              />
                              <path
                                id="Path_1235"
                                data-name="Path 1235"
                                d="M167.146,225h-37.58a1.566,1.566,0,0,0,0,3.132h37.58a1.566,1.566,0,0,0,0-3.132Z"
                                transform="translate(-104.51 -190.552)"
                                fill="#0e9ac8"
                              />
                              <path
                                id="Path_1236"
                                data-name="Path 1236"
                                d="M153.619,257H128.566a1.566,1.566,0,0,0,0,3.132h25.053a1.566,1.566,0,0,0,0-3.132Z"
                                transform="translate(-103.705 -216.288)"
                                fill="#0e9ac8"
                              />
                              <path
                                id="Path_1237"
                                data-name="Path 1237"
                                d="M282.132,257h-1.566a1.566,1.566,0,0,0,0,3.132h1.566a1.566,1.566,0,0,0,0-3.132Z"
                                transform="translate(-225.955 -216.288)"
                                fill="#0e9ac8"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    }
                    msg="No Forum Topic Yet"
                  />
                )
              ) : (
                <div
                  style={{
                    top: 400,
                    left: "50%",
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  <Loader type="Oval" color="#00b6ff" height={45} width={45} />
                </div>
              )}
            </div>
          </section>
        </>
      ) : (
        <ForumContent
          showDetailedPage={showDetailedPage}
          forum={forum}
          selectedForumId={selectedForumId}
          forumAdminId={forumAdminId}
          alias={props.event.alias}
          id={props.event.id}
          user={user}
          OnNavigateToPage={OnNavigateToPage}
          selectdeForumIndex = {selectdeForumIndex}
          socket={socket}
          forumMessage={props.forumMessageData}
        />
      )}
      <Footer2
      event = {props.event}
        isMarketplace={props.event.is_marketplace}
        copyRightLogo={props.event.copyright_logo}
        copyRightText={props.event.copyright_text}
        copyRightLink={props.event.copyright_link}
        showCopyRight={props.event.copyright_text || props.event.copyright_logo}
      />
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state) => ({
  loadingSaveForum: state.marketPlaceReducer.loadingSaveForum,
  forumdata: state.marketPlaceReducer.forumdata,
  loading: state.marketPlaceReducer.loading,
  forumMessageData: state.marketPlaceReducer.forumMessageData,
});
const mapDispatchToProps = (dispatch) => ({
  getForumData: (alias, token) => dispatch(getForumData(alias, token)),
  saveForum: (alias, params, token, forum) =>
    dispatch(saveForum(alias, params, token, forum)),
  deleteForum: (alias, params, token, forum, id) =>
    dispatch(deleteForum(alias, params, token, forum, id)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Forum);
