import React from 'react';
import api from './api';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { ExhibitionDataLoaded } from './actions';
import MetaTags from 'react-meta-tags'
import { Constants } from '../../Constants';
import VirtualZone from '../Header/virtualZone';
import { GAPageView,GARecordEvent } from '../../Utility/GA';
class Exibition extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf('/exhibition') > -1) || -1,
            selectedSubIndex: -1,
            textBlock : null,
            eTextBlock : null,
            exibitors : null,
            floorPlan : null,
            loadingError : '',
            menu : this.props.headerMenuItems.find(x=>x.href == (this.props.event.alias+"/exhibition")),
            showComingSoonMsg : false
        };

    }

    async componentDidMount(){
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=exhibition')
        fetch(`/${this.props.event.alias}/exhibition`)
        this.setState({
            textBlock : this.props.textBlock,
            eTextBlock : this.props.eTextBlock,
            exibitors : this.props.exibitors,
            floorPlan : this.props.floorPlan
        })
        var count = 0
        if(this.props.textBlock != null){
            if(this.props.textBlock.length == 0){
                count += 1
            }
        }
        if(this.props.eTextBlock != null){
            if(this.props.eTextBlock.length == 0){
                
                count += 1
            }
        }
        if(this.props.exibitors != null){
            if(this.props.exibitors.length == 0){
                count += 1
            }
        }
        if(this.props.floorPlan != null){
            if(this.props.floorPlan.length == 0){
                count += 1
            }
        }
        if(count == 4){
            this.setState({showComingSoonMsg : true})
        }
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],'Exhibition')
                if(result != null){
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/exhibition') > -1)
                    this.setState({selectedIndex : i,selectedSubIndex})
                    break
                }
            }
        }
        this.count = 0
        if(this.props.textBlock == null){
            await this.getEventExibitions('Text-Block')
        }
        if(this.props.eTextBlock == null){
            await this.getEventExibitions('eText-Block')
        }
        if(this.props.exibitors == null){
            await this.getEventExibitions('Exibition')
        }
        if(this.props.floorPlan == null){
            await this.getEventExibitions('Floor-Plan')
        }
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
    }
    getEventExibitions = async (type) => {
        await api.getEventExibitions(this.state.event.alias,type,(err,data)=>{
            
            if(err == null){
                if(type == "Text-Block"){
                    this.props.exhibitionLoaded(data.exibitions,'textBlock')
                    this.setState({textBlock:data.exibitions,loadingError : ''});
                    if(data.exibitions.length == 0){
                        this.count += 1
                    }
                }
                else if(type == "eText-Block"){
                    this.props.exhibitionLoaded(data.exibitions,'eTextBlock')
                    this.setState({eTextBlock:data.exibitions,loadingError : ''});
                    if(data.exibitions.length == 0){
                        this.count += 1
                    }
                }
                else if(type == "Exibition"){
                    var exibitors = []
                    for(var i=0;i<data.exibitions.length;i++){
                        if(i%4==0){
                            var exibitorRowItems = []
                            exibitorRowItems.push(data.exibitions[i])
                            exibitors.push(exibitorRowItems)
                        }
                        else{
                            exibitorRowItems.push(data.exibitions[i])
                        }
                    }
                    data.exibitions = exibitors
                    this.props.exhibitionLoaded(data.exibitions,'exhibitors')
                    this.setState({exibitors:data.exibitions,loadingError : ''});
                    if(data.exibitions.length == 0){
                        this.count += 1
                    }
                }
                else{
                    this.props.exhibitionLoaded(data.exibitions,'floorPlan')
                    if(data.exibitions.length == 0){
                        this.count += 1
                    }
                    if(this.count == 4){
                        this.setState({showComingSoonMsg : true})
                    }
                    this.setState({floorPlan:data.exibitions,loadingError : ''});
                }
                
                return
            }
            if(this.props.exibitors == null)
                this.setState({loadingError : 'Something went wrong'})
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
        if(this.state.textBlock == null){
            this.getEventExibitions("Text-Block")
        }
        if(this.state.eTextBlock == null){
            this.getEventExibitions("eText-Block")
        }
        if(this.state.exibitors == null){
            this.getEventExibitions("Exibition")
        }
        if(this.state.floorPlan == null){
            this.getEventExibitions("Floor-Plan")
        }
    }
    languageClick = (value) =>{
        console.log("Trigger booking on lan select")
        this.props.languageClick(value);
    }
    subscribeEvent = () =>{
        //console.log("subcribe clicked")
        GARecordEvent("subscribe Clicked", this.user? this.user.id : 'not a user',{
            category : 'Subscribe',
            action : 'Subscribe button clicked',
            userName: this.user? (this.user.firstName+''+this.user.lastName) : 'not a user',
            userEmail: this.user? this.user.email : 'not a user'
        })
    }

    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.menu != null ? this.state.menu.title : 'Exhibition'}</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                <div id="header-top">
                <Header
                    selectedIndex = {this.state.selectedIndex}
                    selectedSubIndex = {this.state.selectedSubIndex}
                    onMenuItemClick = {this.OnNavigateToPage}
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    isMarketplace = {this.props.event.is_marketplace}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    event = {this.props.event}
                    eventAlias = {this.props.event.alias}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                    onClickAccountItem = {this.OnClickAccountItem}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    notifications = {this.props.notifications}
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div>
                <div className = {`content-main`}>
                {this.state.textBlock != null && this.state.eTextBlock != null && this.state.exibitors != null && this.state.floorPlan != null ?
                    <div>
                        {this.state.showComingSoonMsg ?  
                        <div className = "text-center bg-light">
                            <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                        </div> : 
                        <Content 
                            textBlock = {this.state.textBlock}
                            eTextBlock = {this.state.eTextBlock}
                            exibitors = {this.state.exibitors}
                            floorPlan = {this.state.floorPlan}
                        />}
                       {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ? <NewsLetter 
                            title = "SUBSCRIBE OUR NEWS LETTER" 
                            event={this.props.event} 
                            event_alias={this.props.event_alias}
                            buttonText = "Subscribe"
                            type = 'newsLetter'
                            subscribeEvent= {this.subscribeEvent}
                        />:null}
                    </div> :
                    <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                        {this.state.loadingError == "" ?
                            <Loader
                                type = 'Oval'
                                color = "#00b6ff"
                                height = {40}
                                width = {40}
                            />:
                            <ErrorScreen 
                                message = {this.state.loadingError}
                                onPressRetry = {this.OnPressRetry}
                            />
                        }
                    </div>
                }
                <Footer1
                    event = {this.state.event}
                    elements = {this.props.footerMenuItems}
                    onMenuItemClick = {this.OnNavigateToPage}
                />
                </div>
                <Footer2 
                event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    exibitors: state.exhibition.exhibitorsData,
    textBlock : state.exhibition.textBlockData,
    eTextBlock : state.exhibition.eTextBlockData,
    floorPlan : state.exhibition.floorPlanData
})
const mapDispatchToProps = dispatch => ({
    exhibitionLoaded : (data,type) => dispatch(ExhibitionDataLoaded(data,type))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Exibition);