import React from 'react'
import Loader from 'react-loader-spinner'
import ReactHtmlParser from 'react-html-parser';
import Lightbox from 'react-image-lightbox';
import { isMobile } from 'react-device-detect';
import {Trans} from 'react-i18next'
export const Content = (props) => {
    if(props.lobby.length == 0){
        return(
            <section style = {{width : '100%',height : window.innerHeight,display : 'flex',alignItems : 'center',justifyContent : 'center'}}>
                {props.loading ? 
                <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                    <Loader
                        type = 'Oval'
                        color = "#00b6ff"
                        height = {40}
                        width = {40}
                    />
                </div> :
                <div className = "text-center">{props.loadingError}</div>}
            </section>
        )
    }
    return (
        <>
        <section id= "exhibitor-area" class="exhibitor-area">
        {props.selectedLobbyIndex == -1 ?
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-0" >
                    <div class="pyramid">
                        <img src={props.selectedLobbyImage} id = "Map" usemap="#Map" onLoad = {props.templateImageLoaded}/>
                    </div>
                </div>
            </div>
        </div> :
        props.lobby.map((item,index)=>{
            return(
                props.selectedLobbyIndex == index ?
                <div class="container-fluid" id = {"lobby"+index}>
                    <div class="row">
                        <div class="col-12 px-0" style = {{minHeight : props.loadingTemplateImage ? window.innerHeight : 0}}>
                        {props.loadingTemplateImage ?
                            <div style = {{width : '100%',height : '100%',position : 'absolute',zIndex : 1,background : 'rgba(0,0,0,0.5)',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                                <Loader
                                    type = 'Oval'
                                    color = "#fff"
                                    height = {40}
                                    width = {40}
                                />
                            </div> : null}
                            <div class="pyramid">
                                <img src={item.templateImage} id = "Map" usemap="#Map" onLoad = {props.templateImageLoaded}/>
                                <map name="Map" id="Map">
                                    {/* {item.exhibitors.map((exhibitor,index)=>{
                                        return(
                                            <area style = {{cursor : 'pointer'}} onClick = {()=>props.onClickBooth(index)} data-toggle="tooltip" data-placement="top" title={exhibitor.title} class={index < 4 ? "maparea" : "maparea-b"} coords={props.expoHallMapAreas[1][index]} shape="rect"/>
                                        )
                                    })} */}
                                    
                                    {/* <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea" href="" coords="854,515,1277,807" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea" href="" coords="1564,508,1997,807" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea" href="" coords="2142,561,2640,863" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea-b" href="" coords="477,841,1064,1250" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea-b" href="" coords="1651,880,2246,1296" shape="rect"/> */}
                                    {ReactHtmlParser(item.mapArea)}
                                </map>
                                {/* {props.selectedHallIndex > 0 ?
                                <div onClick = {()=>props.onClickBack(index)} class="back-room-btn" id = {"back-imgs"+index}>
                                    <img src="/images/ic_back.svg" alt="back-icon" /> Back
                                </div> : null}
                                {props.selectedHallIndex != props.halls.length - 1 ?
                                <div onClick = {()=>props.onClickNext(index)} class="next-room-btn" id = {"nxt-imgs"+index}>
                                    Next <img src="/images/ic_next.svg" alt="next-icon"/>
                                </div> : null} */}
                                <div onClick = {()=>props.onClickBack(index)} class="back-room-btn" id = {"back-imgs"}>
                                    <i class="fas fa-long-arrow-alt-left"></i>
                                </div>
                                <div onClick = {()=>props.onClickNext(index)} class="next-room-btn" id = {"nxt-imgs"}>
                                    <i class="fas fa-long-arrow-alt-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>:null
            )
        })}
        </section>
        <div class="modal" id="Brochure-modal">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                {props.selectedBranding.link != null ?
                <div class="modal-content bio-modal rounded-0 Brochure-modal">
                    <div class = "modal-header px-4 py-3">
                        <h3 class = "w-100 font-weight-bold">{props.pdfModalTitle}</h3>
                        <button data-dismiss="modal">
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div>
                    <div class="modal-body px-4">
                        <ul class="list-group ptd-download-list">
                            {props.selectedBranding.link.map((link,index)=>{
                                let title = link.title.replace('.pdf','')
                                let longTitleEndEffectCls = ""
                                if(isMobile){
                                    longTitleEndEffectCls = title.length > 20 ? 'titleEffect' : ''
                                }else{
                                    longTitleEndEffectCls = title.length > 65 ? 'titleEffect' : ''
                                }
                                return(
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col-2 col-md-1 pr-0">
                                                <img src="/images/ic_pdf.svg" alt="pdf-icon"/>
                                            </div>
                                            <div class={"col-8 col-md-8 px-1 px-md-0 "+longTitleEndEffectCls}>
                                                <p>{title}</p>
                                            </div>
                                            <div class="col-2 col-md-3">
                                                {props.downloadingPdf && props.selectedPdfId == link.id ?
                                                <>
                                                <div className = "d-none d-md-block float-right mx-2 pt-1">
                                                    <Loader
                                                        type = 'Oval'
                                                        color = "#00b6ff"
                                                        height = {25}
                                                        width = {25}
                                                    />
                                                </div> 
                                                <div style = {{position : 'absolute',top : 5,right : 70}} className = "d-block d-md-none">
                                                    <Loader
                                                        type = 'Oval'
                                                        color = "#00b6ff"
                                                        height = {25}
                                                        width = {25}
                                                    />
                                                </div></> : null}
                                                <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickPdf(link.id,title)} class = "float-right btn d-none d-md-block">
                                                    <Trans>download</Trans>
                                                </a>
                                                <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickPdf(link.id,title)} class = "float-right btn d-block d-md-none">
                                                    <i class="fas fa-arrow-circle-down"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div> : null}
            </div>
        </div>
        <div class="modal" id="video-modal-exhb" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                {props.selectedBranding.link != null ?
                <div class="modal-content bio-modal rounded-0 video-modal video-modal-exhibitor">
                    <div class = "modal-header px-3">
                        {/* <h3>{props.selectedBranding.link[props.selectedVideoIndex].title}</h3> */}
                        <button data-dismiss="modal">
                            <i onClick = {props.onClickCloseVideo} class="fas fa-times-circle"></i>
                        </button>
                    </div>
                    <div class="modal-body p-2">
                
                    <div class="container-fluid">
                        <div class="row mx-0">
                            <div class={props.selectedBranding.link.length == 1 ? "col-md-12 px-0" : "col-md-9 px-0"}>
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item" src={props.selectedVideoLink} allowfullscreen></iframe>
                                </div>
                            </div>
                            {props.selectedBranding.link.length > 1 ?
                            <div class="col-md-3 pl-0 pl-md-1 pr-0">
                                <div class="video-list-mdl bg-light">
                                        <ul class="list-inline">
                                            {props.selectedBranding.link.map((video,index)=>{
                                                if(index == props.selectedVideoIndex){
                                                    return null
                                                }
                                                return(
                                                    <li class="list-inline-item video-list-box">
                                                        {video.thumnail != null ?
                                                        <img src={video.thumnail} alt="video_thumpnil" /> :
                                                        <iframe style = {{width : '100%',height : '100%'}} src={video.link}></iframe>}
                                                        <div onClick = {()=> props.onClickChangeVideo(index)} class="thumpnil-overlay">
                                                            <i class="far fa-play-circle text-white"></i>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                    
                    </div>

                </div> : null}
            </div>
        </div>
        {
            props.showImageSlider && props.selectedBranding.link != null ?
            <Lightbox
              mainSrc={props.selectedBranding.link[props.selectedImageIndex % props.selectedBranding.link.length].link}
              nextSrc={props.selectedBranding.link[(props.selectedImageIndex + 1) % props.selectedBranding.link.length].link}
              prevSrc={props.selectedBranding.link[(props.selectedImageIndex + props.selectedBranding.link.length - 1) % props.selectedBranding.link.length].link}
              onCloseRequest={props.onCloseImageSlider}
              onMovePrevRequest={() =>
                props.onClickChangeImage((props.selectedImageIndex + props.selectedBranding.link.length - 1) % props.selectedBranding.link.length)
              }
              onMoveNextRequest={() =>
                props.onClickChangeImage((props.selectedImageIndex + 1) % props.selectedBranding.link.length)
              }
            /> : null
        }
        </>
    )
}