import React from 'react'
import $ from 'jquery'
import Loader from 'react-loader-spinner'
import DateFnsUtils from '@date-io/date-fns';
import {Trans} from 'react-i18next';
import { Translation } from 'react-i18next';
import utility,{wordsRestrictor} from '../../../Utility/utility'
import _ from 'lodash';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { isMobile } from 'react-device-detect';
// import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/material_green.css";
import ReactTooltip from 'react-tooltip';

export class Content extends React.Component{
    constructor(props){
        super(props)
        this.state = {

            searchText : '',
            onlineFilter : false,
            onlineFilterUI : false,
            selectedParticipantsForMeeting : [],
            meetingSearchText : '',
            meetingModalOpen : false,
            eMeetingTopic : '',
            eDate : '',
            eStartTime : '',
            eEndTime : '',
            eInviteText : '',
            startTimePickerOpenned : false,
            endTimePickerOpenned : false,
            datePickerOpenned : false,
            selectedDate : new Date(),
            selectedStartTime : new Date().setMinutes(new Date().getMinutes()+15),
            selectedEndTime : new Date().setMinutes(new Date().getMinutes()+30),
            startTimerPopupClosed : false,
            endTimerPopupClosed : false,
            datePickerClosed : false,
            selectedParticipant : {},
            showFullParticipantBio : false,
            showModal : false,
            toolTipComponentToggle: true,
            participent :this.props.participants,
            meetingParticipent:this.props.meetingParticipent,
            normalParticipent:this.props.participants,
            onlineparticipentCounter:0,
            onlineParticipentIds:[],
            pleasewait:"Please wait"
            
        }
        this.socket = this.props.socket
        this.scrollDown = true
        this.filteredList = []
        this.count = 1
    }
    
    componentDidMount(){
        // document.addEventListener('usersConnected',()=>{
        //     if(localStorage.getItem("OnlineUser") == 1){
        //     this.props.handleOnFindOnlineParticipants(true)
        //     }
        // })
        // document.addEventListener('userDisconnected',()=>{this.props.handleOnUserDisconnected()})
        let onlineUser = this.props.onlineParticipent.map(item=>item.userId)
        this.setState({normalParticipent:this.props.participants,onlineparticipentCounter:this.props.onlineParticipent.length,onlineParticipentIds:[...onlineUser],participent:this.props.participants,meetingParticipent:this.props.meetingParticipent})
        let val = localStorage.getItem("OnlineUser")
        if(val != null){
            this.setState({onlineFilterUI : val == 0 ? false : true},()=>{
                this.setState({onlineFilter : this.state.onlineFilterUI},()=>{
                    if(this.state.onlineFilter){
                        // this.props.findOnlineParticipants(false)
                    }
                })
            })
        }
        if(window.outerWidth > 767){
            var height = window.innerHeight
            document.getElementById("participant_body").style.height = (height - 150) + 'px'
        }
    }
    componentWillReceiveProps=(nextProps)=>{
        if(nextProps.meetingdata == true){
            document.getElementById('meetingTopic').value = ''
            this.setState({selectedDate : new Date(),selectedStartTime : new Date().setMinutes(new Date().getMinutes()+15), selectedEndTime : new Date().setMinutes(new Date().getMinutes()+30)})
            document.getElementById('inviteText').value = ''
           nextProps.clearMeetingdata()
        }
        let onlineUser = nextProps.onlineParticipent.map(item=>item.userId)
        if(this.state.onlineparticipentCounter != nextProps.onlineParticipent.length){
            this.props.findOnlineParticipants(true)
            this.setState({onlineparticipentCounter:nextProps.onlineParticipent.length,pleasewait:''})
        }
        else{
            if(localStorage.getItem("OnlineUser") == 1){
            setTimeout(()=>{
                this.setState({pleasewait:''})
            },3000)
        }
        }
        this.setState({normalParticipent:nextProps.participants,onlineParticipentIds:[...onlineUser],participent:nextProps.participants},()=>{
        })
        if(this.props.meetingParticipent != nextProps.meetingParticipent){
            this.setState({meetingParticipent:nextProps.meetingParticipent})
        }
       
    }
    componentWillUnmount () {
        // document.removeEventListener('usersConnected',this.props.handleOnFindOnlineParticipants)
    }
    OnChangeSearchText = (e = null,query='') => {
        if(e != null){
        if(localStorage.getItem("OnlineUser") == 1){
            if(e.target.value.length >0){

                let onlineUser = this.props.participants.filter(item=>`${item.firstName} ${item.lastName}`.toLowerCase().includes(e.target.value.toLowerCase())  || `${item.country}`.toLowerCase().includes(e.target.value.toLowerCase()) || `${item.jobTitle}`.toLowerCase().includes(e.target.value.toLowerCase()) || `${item.email}`.toLowerCase().includes(e.target.value.toLowerCase()) || `${item.companyName}`.toLowerCase().includes(e.target.value.toLowerCase()))
                this.setState({normalParticipent:[...onlineUser]})
            }else{
                this.setState({normalParticipent:[...this.props.participants]})
            }
        }else{
            // if(e.target.value.length >0){
            //     let onlineUser = this.props.participants.filter(item=>`${item.firstName} ${item.lastName}`.toLowerCase().includes(e.target.value.toLowerCase()))
            //     this.setState({normalParticipent:[...onlineUser]})
            // }else{
            //     this.setState({normalParticipent:[...this.props.participants]})
            // }
            // this.props.searchParticipants(e.target.value)
            this.props.filterUser(e.target.value)
        }
        
    }else{
        if(localStorage.getItem("OnlineUser") == 1){
            if(query.length >0){
                let onlineUser = this.props.participants.filter(item=>`${item.firstName} ${item.lastName}`.toLowerCase().includes(query.toLowerCase())  || `${item.country}`.toLowerCase().includes(query.toLowerCase()) || `${item.jobTitle}`.toLowerCase().includes(query.toLowerCase()) || `${item.email}`.toLowerCase().includes(query.toLowerCase()) || `${item.companyName}`.toLowerCase().includes(query.toLowerCase()))
                this.setState({normalParticipent:[...onlineUser]})
            }else{
                this.setState({normalParticipent:[...this.props.participants]})
            }
        }else{
            // if(query.length >0){
            //     let onlineUser = this.props.participants.filter(item=>`${item.firstName} ${item.lastName}`.toLowerCase().includes(query.toLowerCase()))
            //     this.setState({normalParticipent:[...onlineUser]})
            // }else{
            //     this.setState({normalParticipent:[...this.props.participants]})
            // }
            // // this.props.searchParticipants(query)
            this.props.filterUser(query)
        }

    
    
    }
    }
    OnChangeMeetingSearchText = (e) => {
        // let meetingUser = []
        // if(e.target.value.length > 0){
        //     meetingUser =this.props.participants.filter(item=> `${item.firstName} ${item.lastName}`.toLowerCase().includes(e.target.value.toLowerCase()) )
        //     this.setState({participent:[...meetingUser]})
        // }
        // else{
        //      meetingUser = [...this.props.participants]
        //      this.setState({participent:[...meetingUser]})
        // }
    this.props.searchParticipants(e.target.value,'meeting')
    }
    OnChangeOnlineToggle = (e) => {
        this.setState({onlineFilterUI : !this.state.onlineFilterUI},()=>{
            this.setState({onlineFilter : !this.state.onlineFilter, toolTipComponentToggle: !this.state.toolTipComponentToggle},()=>{
                this.props.findOnlineParticipants(this.state.onlineFilter)
                localStorage.setItem("participantOnlineToggle",this.state.onlineFilter ? "1" : "0")
                localStorage.setItem("OnlineUser",this.state.onlineFilter ? "1" : "0")
                if(document.getElementById("sp_input") && document.getElementById("sp_input").value.length > 0){
                    this.OnChangeSearchText(null,document.getElementById("sp_input").value)
                }
            })
            setTimeout(() => {
               this.setState({toolTipComponentToggle: !this.state.toolTipComponentToggle}) 
            }, 0);
        })
    }
    OnClickParticipantItem = (selectedParticipant) => {
        let participantBody = document.getElementById("participant_body")
        participantBody.classList.add("participant_body-before")
        this.setState({selectedParticipant},()=>{
            let elem = document.getElementById("spk-modal")
            if(elem != null){
                elem.classList.add("show")
                // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                document.body.style.overflowY = "hidden"
            }
        })
    }
    OnClickParticipantDetailModalMeeting = (id) => {
        let elem = document.getElementById("spk-modal")
        if(elem != null){
            elem.classList.remove("show")
        }
        setTimeout(() => {
           this.OnClickScheduleMeeting(id) 
        }, 300);
    }
    OnClickScheduleMeeting = (id,showPopup = true) => {
        let selectedParticipantsForMeeting = this.state.selectedParticipantsForMeeting
        if(selectedParticipantsForMeeting.length < 1){
            let index = selectedParticipantsForMeeting.findIndex(x=>x.id == id)
            if(index < 0){
                var participant = this.props.participants.find(x=>x.id == id)
                participant.tempId = Math.random().toString(36).substr(2, 9)
                selectedParticipantsForMeeting.push(participant)
                this.setState({selectedParticipantsForMeeting})
            }
            if(!showPopup && isMobile){
                let elem = document.getElementById("meeting-modal-mobile")
                if(elem.classList.contains("show")){
                    elem.classList.remove("show")
                }
            }
        }
        if(showPopup){
            // $('#meeting-modal').modal('show')
            let elem = document.getElementById("meeting-modal")
            if(elem != null){
                elem.classList.add("show")
                $('.participant_body, body').addClass('overflow-hidden');
                $('.participant_body').addClass('participant_body-before');
                // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                document.body.style.overflowY = "hidden"
            }
            this.setState({meetingModalOpen : true})
            this.scrollDown = false
        }
    }
    OnClickCloseMeetingModal = () => {
        // $('#meeting-modal').modal('hide')
        // $('.modal-backdrop').remove()
        let elem = document.getElementById("meeting-modal")
        if(elem != null){
            elem.classList.remove("show")
            // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
            document.body.style.overflowY = "scroll"
            $('.participant_body, body').removeClass('overflow-hidden')
            $('.participant_body').removeClass('participant_body-before')
        }
        this.setState({selectedParticipantsForMeeting : [],meetingModalOpen : false})
        setTimeout(() => {
            this.scrollDown = true
        }, 0);
    }
    OnClickCloseParticipantModalMobile = () => {
        let elem = document.getElementById("meeting-modal-mobile")
        elem.classList.remove("show")
    }
    OnClickRemoveParticipantFromMeeting = (index) => {
        let selectedParticipantsForMeeting = this.state.selectedParticipantsForMeeting
        selectedParticipantsForMeeting.splice(index,1)
        this.setState({selectedParticipantsForMeeting})
    }
    OnClickSendMeetingRequest = () => {
        let selectedParticipantsForMeeting = this.state.selectedParticipantsForMeeting
        if(selectedParticipantsForMeeting.length == 0){
            return
        }
        this.setState({eMeetingTopic : '',eDate : '',eStartTime : '',eEndTime : '',eInviteText : ''})
        let meetingTopic = document.getElementById('meetingTopic').value
        let meetingDate = document.getElementById('basicDate').value
        let startTime = document.getElementById('startTime').value
        let endTime = document.getElementById('endTime').value
        let inviteText = document.getElementById('inviteText').value
        var startingTime = new Date(this.state.selectedStartTime)
        var startingHours = startingTime.getHours()
        var hours = startingHours % 12;
         hours = hours ? hours : 12
        var startingMinutes = startingTime.getMinutes()
        var ampm = startingHours >= 12 ? 'PM' : 'AM'
         var meetingStartTime = `${hours}:${startingMinutes} ${ampm}`
        var canSubmit = true
        let selectedDate = new Date(meetingDate)
        if(meetingTopic == ''){
            this.setState({eMeetingTopic : "Can't be empty"})
            canSubmit = false
        }
        let selectedDateMinutes = (new Date().getHours() * 60) + new Date().getMinutes()
        let startDateObj = new Date(this.state.selectedStartTime)
        if(startDateObj.getDate() == selectedDate.getDate() && startDateObj.getMonth() == selectedDate.getMonth() 
        && startDateObj.getFullYear() == selectedDate.getFullYear() ){
            let minutes = (startDateObj.getHours() * 60) + startDateObj.getMinutes()
            if(minutes < selectedDateMinutes){
                this.setState({eStartTime : "Select time from future"})
                canSubmit = false
            }
        }
        let endDateObj = new Date(this.state.selectedEndTime)
        if(endDateObj.getDate() == selectedDate.getDate() && endDateObj.getMonth() == selectedDate.getMonth() 
        && endDateObj.getFullYear() == selectedDate.getFullYear()){
            let minutes = (startDateObj.getHours() * 60) + startDateObj.getMinutes()
            if(minutes < selectedDateMinutes){
                this.setState({eEndTime : "Select time from future"})
                canSubmit = false
            }
        }
        if(this.state.selectedStartTime > this.state.selectedEndTime){
            this.setState({eStartTime : 'Start Time must be less the end time',eEndTime : "End time must be greater than start time"})
            canSubmit = false
        }
        if(inviteText == ''){
            this.setState({eInviteText : "Can't be empty"})
            canSubmit = false
        }
        if(!canSubmit){
            return
        }
        let month = this.state.selectedDate.getMonth()+1
        if(month < 10){
            month = "0"+month
        }
        let date = this.state.selectedDate.getDate()
        if(date < 10){
            date = "0"+date
        }
        meetingDate = this.state.selectedDate.getFullYear()+"-"+month+"-"+date
        var data = {
            ids : selectedParticipantsForMeeting.map(x=>x.id),
            meeting_date : meetingDate,
            meeting_start_time : meetingStartTime,
            meeting_end_time : endTime,
            meeting_invitation_text : inviteText,
            meeting_topic : meetingTopic,
            temp_ids : selectedParticipantsForMeeting.map(x=>x.tempId),
            startTimeObj : new Date(selectedDate.getFullYear(),selectedDate.getMonth(),selectedDate.getDate(),startDateObj.getHours(),startDateObj.getMinutes(),startDateObj.getSeconds()),
            endTimeObj : new Date(selectedDate.getFullYear(),selectedDate.getMonth(),selectedDate.getDate(),endDateObj.getHours(),endDateObj.getMinutes(),endDateObj.getSeconds())
        }
        this.props.onClickSendMeetingInvite(data)
    }
    OnMeetingScheduledResponse = () => {
        let elem = document.getElementById("meeting-modal")
        if(elem != null){
            elem.classList.remove("show")
            // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
            document.body.style.overflowY = "scroll"
            $('.participant_body, body').removeClass('overflow-hidden')
            $('.participant_body').removeClass('participant_body-before')
        }
        this.setState({selectedParticipantsForMeeting : [],meetingModalOpen : false})
        setTimeout(() => {
            this.scrollDown = true
        }, 0);
    }
    handleOnScroll = (e) => {
        let obj = e.target
        if(isMobile){
            if( $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight - 10 && this.scrollDown){ 
               if (localStorage.getItem("OnlineUser") != 1){
            this.props.loadMore()
               }
            }
        }else{
            if(Math.ceil(obj.scrollTop) >= (obj.scrollHeight - obj.offsetHeight) && this.scrollDown){
                if (localStorage.getItem("OnlineUser") != 1){
                    this.props.loadMore()
                       }
            }
        }
    }
    NameHandler = (firstName = 'User',lastName =  null)=>{
        var firstLetter = ''
        var lastLetter = ''
        if(firstName != null && firstName != "" && firstName != undefined){
            firstLetter = firstName.split("")[0].toUpperCase()
        }
        else{
             if(lastName != "" && lastName != null && lastName != undefined){
                 firstLetter = lastName.split("")[0].toUpperCase()
             }
             else{
                 firstLetter = 'Event'.split('')[0].toUpperCase()
             }
        }
        if(lastName != null && lastName != "" && lastName != undefined){
            lastLetter = lastName.split("")[0].toUpperCase()
        }
        else{

            if(firstName != null && firstName != "" && firstName != undefined){
                firstName.split("").length > 1 ? 
                lastLetter = firstName.split("")[1].toUpperCase():
                lastLetter = firstName.split("")[0].toUpperCase()
            }
            else{
                lastLetter = 'User'.split("")[0].toUpperCase()
            }

        }

        return firstLetter+lastLetter

    }
    render(){
        this.filteredList = this.state.normalParticipent
        if(document.getElementById("sp_input") && document.getElementById("sp_input").value.length>0){
            this.filteredList =this.state.normalParticipent.map(item=>{
                return {...item,fullName:`${item.firstName} ${item.lastName}`.toLowerCase()}})
            this.filteredList = this.filteredList.filter(item=>item.fullName.includes(document.getElementById("sp_input").value.toLowerCase()) || `${item.companyName}`.toLowerCase().includes(document.getElementById("sp_input").value.toLowerCase()) || `${item.country}`.toLowerCase().includes(document.getElementById("sp_input").value.toLowerCase()) || `${item.jobTitle}`.toLowerCase().includes(document.getElementById("sp_input").value.toLowerCase()) || `${item.email}`.toLowerCase().includes(document.getElementById("sp_input").value.toLowerCase()) )
        }
        this.filteredList = _.uniqBy(this.filteredList, 'id').filter((data)=>{
            if(this.state.onlineFilter){
                if(data.isOnline){
                    return data
                }
            }else{
                return data
            }
        })
        this.filteredList = this.filteredList.filter(item =>item.chatAvailabaility != "2")
        if(this.props.filterPar == true){
            this.filteredList = this.filteredList.filter(item=>{
                if(item.chatAvailabaility == "1"){
                    if(item.isOnline == true){
                        if(item.chatAvailabailityDuration != null){
                            if(utility.comparingUserSetTime(item.chatAvailabaility,item.chatAvailabailityDuration)== 'online'){
                                return item
                            }
                        }else{
                            return item
                        }
                       
                    }else{
                       return item
                    }
                }
            })

        }
        

        var filteredMeetingList = []
        if(this.state.meetingModalOpen){
            const filteredMeetingListWithOutAddedElements = this.state.meetingParticipent && this.state.meetingParticipent.filter((data)=>{
                let selecetedMeetingParticipants = this.state.selectedParticipantsForMeeting.map(x=>x.id)
                if(selecetedMeetingParticipants.indexOf(data.id) < 0){
                    return data
                }
            })
            filteredMeetingList = filteredMeetingListWithOutAddedElements
        }
        let overFlowHiddenClass = this.state.showModal ? ' overflow-hidden' : ''
        let onlineToggleText = <Trans>showOnline</Trans>
        let noParticipantText = ""
          // if(this.state.onlineFilter){
            // if(filteredList.length == 0){
            //     onlineToggleText = ""
            // }else{
            //     onlineToggleText = filteredList.length + " user(s) online"
            // }
        // }else{
            // onlineToggleText = "Show Online"
        // }
        if(this.filteredList.length == 0 && !this.props.loadingMore){
            if(this.state.onlineFilter){
                noParticipantText = this.state.pleasewait == '' ?<Trans>noOnline</Trans>:this.state.pleasewait
            }else{
                noParticipantText = <Trans>noParticipants</Trans>
            }
        }
        return(
            <section id = "participant_body" class={"participants-listing position-relative participant_body"+overFlowHiddenClass} onTouchMove={this.handleOnScroll} onScroll = {this.handleOnScroll}>
                {this.state.toolTipComponentToggle ? <ReactTooltip
                    id="main"
                    effect="solid"
                /> : null}
                <div class="container participant-container">
                    <div class="row pt-3">
                        <div class="col-12">
                            <form>
                                <div class="form-row">
                                    <div class="col-6 col-md-6 input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class = "fas fa-search"></i>
                                            </span>
                                        </div>
                                        <input onChange = {this.OnChangeSearchText} type="text" class="form-control" id = "sp_input" 
                                        placeholder={this.props.translation.searchParticipants} />
                                    </div>
                                    <div class="col-6 col-md-6 pl-0 pl-md-3">
                                        <label class="switch mt-2 ml-3">
                                            <input checked = {this.state.onlineFilterUI} type="checkbox" onChange = {(e)=>{
                                                this.OnChangeOnlineToggle(e)
                                                }} />
                                            <span class="slider round"></span>
                                        </label>
                                        <span class = "ml-2 switch-txt">{onlineToggleText}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            </div>

                <div class="container participant-container participants-list-sec py-3">
                {this.props.loadingSearchResults ? <div style = {{width : '100%',height : '100%',position : 'absolute',top : 0,left : 0,background : '#0000000d',zIndex : 999}}>
                    <div style = {{position : 'absolute',left : '50%',top : 250}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {40}
                            width = {40}
                        />
                    </div>              
                </div> : null}
                <div class="row">
                    <div class="col-12 px-0 d-none d-md-block">
                        <div id="wrapper">
                                <table class = "table-responsives" id = "p_details">
                                    <thead>
                                    <tr>
                                        <th id = "thh"><Trans>name</Trans> <i class="fas fa-sort"></i> </th>
                                        <th id = "thh"><Trans>company</Trans><i class="fas fa-sort"></i> </th>
                                        <th id = "thh"><Trans>country</Trans> <i class="fas fa-sort"></i> </th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {[...new Set(this.filteredList)].map((item,index)=>{
                                            return(                                             
                                                <tr>
                                                    <td data-label="Name">
                                                            <div onClick = {()=>this.OnClickParticipantItem(item)} class="spk_info-div">
                                                                <div class="participant-image mr-3">
                                                                    {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                                    <div style={{width:'40px',height:'40px',display:'flex',justifyContent:'center',alignItems:'center',background:`${item.bgColor}`,borderRadius:'50%'}}>
                                                                        {item.firstName == null && item.lastName == null ? <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{this.NameHandler('User')}</p> :  <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{this.NameHandler(item.firstName,item.lastName)}</p>}
                                                                    </div> :
                                                                    <img src={item.profileImageUrl} alt="participant_image" />}
                                                                    {this.props.event.is_jobfair == 1 && (item.hasOwnProperty('userType') &&  `${item.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())?  <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>:null}
                                                                    {item.isOnline != undefined 
                                                                    && item.isOnline && 
                                                                    utility.comparingUserSetTime(item.chatAvailabaility,item.chatAvailabailityDuration) == 'online' ? 
                                                                    <div class = "online"></div> :
                                                                     <div class = "offline"></div>}
                                                                    {/* {item.chatAvailabaility == "0" ? <div class = "offline"></div> : null} */}

                                                                </div>
                                                                <div class = "participant-details">
                                                                    <span onClick={()=>{
                                                                        // this.props.RemoveScroll(1)
                                                                    }} >{
                                                                     item.firstName == null && item.lastName == null ? 'User' :
                                                                    `${item.firstName == null ? " " : `${item.firstName}`.replace(null,'')} ${item.lastName == null ? " " : `${item.lastName}`.replace(null,'')}`}</span> 
                                                                    <div class="post">
                                                                        {item.jobTitle != null ? wordsRestrictor(item.jobTitle,10):item.jobTitle}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </td>

                                                    <td data-label="Company">
                                                        <span class="post">{item.companyName != null ? wordsRestrictor(item.companyName,10):item.companyName}</span>
                                                    </td>
                                                    <td data-label="Country">
                                                        <span class="post">{item.country}</span>
                                                    </td>
                                                    <td class ="ac_btn_td">
                                                        
                                                        <ul class="list-inline mb-0 text-right">
                                                            {utility.comparingUserSetTime(item.chatAvailabaility,item.chatAvailabailityDuration) == 'online' ? <li class="list-inline-item">
                                                                    <a style = {{cursor : 'pointer'}} onClick = {()=>this.props.onClickChat(item.id,item.socketId)} class="btn btn-primary" data-for="main" data-iscapture="true" data-tip="Chat">
                                                                        <i class="far fa-comment"></i>
                                                                    </a>
                                                            </li> : null}
                                                            {this.props.event.is_marketplace == 1 || this.props.event.is_jobfair == 1  ? null :
                                                            <li class="list-inline-item">
                                                                    <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickScheduleMeeting(item.id)} class="btn btn-default" data-for="main" data-iscapture="true" data-tip="Schedule Meeting">
                                                                        <i class="fas fa-calendar-plus"></i>
                                                                    </a>
                                                            </li>
                                                            }
                                                        </ul>
                                                    
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-center my-4">{noParticipantText}</div>
                            {this.props.loadingMore  ? 
                            <div className="text-center my-2" style = {{width : '100%'}}>
                                <Loader
                                    type = 'Oval'
                                    color = "#5B5B5B"
                                    height = {25}
                                    width = {25}
                                />
                            </div>: null}
                        </div>

                    </div>

                    <div class = "col-12 px-0 d-block d-md-none">
                        <div id="wrapper">
                            <table class = "table-responsives" id = "p_details">
                                <tbody>
                                {[...new Set(this.filteredList)].map((item,index)=>{
                                    return(
                                    <tr>
                                        <td data-label="Name">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <div class="participant-image mr-3">
                                                            {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                             <div style={{width:'30px',height:'30px',display:'flex',justifyContent:'center',alignItems:'center',background:`${item.bgColor}`,borderRadius:'50%'}}>
                                                             <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{this.NameHandler(item.firstName)}</p>
                                                         </div> :
                                                            <img src={item.profileImageUrl} alt="participant_image" />}
                                                            {this.props.event.is_jobfair == 1 && (item.hasOwnProperty('userType') &&  `${item.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())?  <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>:null}
                                                            { item.isOnline != undefined &&
                                                             item.isOnline && utility.comparingUserSetTime(item.chatAvailabaility,item.chatAvailabailityDuration) == 'online' ?
                                                              <div class = "online"></div> :
                                                               <div class = "offline"></div>}
                                                        </div>
                                                    </div>
                                                    <div class="col-6 pl-1">
                                                        <div class="spk_info-div">
                                                                <div class = "participant-details">
                                                                { item.firstName == null && item.lastName == null ? 'User':
                                                                `${item.firstName == null ? " " : `${item.firstName}`.replace(null,'')} ${item.lastName == null ? " " : `${item.lastName}`.replace(null,'')}`}
                                                                    <div class="post">
                                                                        {item.jobTitle}
                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 chat-opt pl-0 pt-2 text-right">
                                                        <i onClick = {()=>this.props.onClickChat(item.id,item.socketId)} class="fas fa-comment mx-2"></i>
                                                        {this.props.event.is_marketplace == 1 || this.props.event.is_jobfair == 1 ? null :<i onClick = {()=>this.OnClickScheduleMeeting(item.id)} class="fas fa-calendar-plus mx-2"></i>}
                                                    </div>
                                                </div>
                                        </td>
                                    </tr>
                                    )
                                })}
                                {this.props.loadingMore && localStorage.getItem("OnlineUser") == 0  ? 
                                <div style = {{position : 'absolute',left : '50%'}}>
                                    <Loader
                                        type = 'Oval'
                                        color = "#5B5B5B"
                                        height = {20}
                                        width = {20}
                                    />
                                </div>: null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modall fade left m-modal" id="meeting-modal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="row mx-0">

                                <div class="col-md-6 px-0 border-right mf-side">
                                    <div class="modal-header">
                                        <h6 class = "f-600 mb-0"><Trans>newMeeting</Trans></h6>
                                        <a style = {{cursor : 'pointer'}} onClick = {this.OnClickCloseMeetingModal} class = "close-icons d-block d-md-none">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                                        </a>
                                    </div>
                                    <div class="modal-body l-side-body">
                                        <div class="">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <form action="">
                                                <div class="form-group">
                                                    <label for=""><Trans>meetingTopic</Trans></label>
                                                    <input type="text" class={this.state.eMeetingTopic == "" ? "form-control" : "text-red-border form-control"} id="meetingTopic" />
                                                </div>
                                                <div class="form-group">
                                                    <label for=""><Trans>date</Trans></label>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        disablePast
                                                        className = {this.state.eDate == "" ? "form-control" : "text-red-border form-control"}
                                                        variant="inline"
                                                        format="MMMM, dd yyyy"
                                                        margin="normal"
                                                        id="basicDate"
                                                        value={this.state.selectedDate}
                                                        onChange={(date)=>{
                                                            this.setState({selectedDate : date,datePickerOpenned : false,datePickerClosed : true})
                                                            setTimeout(() => {
                                                                this.setState({datePickerClosed : false})
                                                            }, 0);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            hidden : true
                                                        }}
                                                        PopoverProps={{
                                                            disableRestoreFocus: true,
                                                            onClose: () => {
                                                                this.setState({datePickerOpenned : false})
                                                            }
                                                        }}
                                                        InputProps={{
                                                            onFocus: () => {
                                                                if(!this.state.datePickerClosed){
                                                                    this.setState({datePickerOpenned : true})
                                                                }
                                                            }
                                                        }}
                                                        open={this.state.datePickerOpenned}
                                                    />
                                                </div>

                                                <div class="form-group row">
                                                     
                                                    <div class="col-md-6 mb-3 mb-md-0 d-none d-md-block">
                                                        <label for=""><Trans>startTime</Trans></label>
                                                        <KeyboardTimePicker
                                                            className = {this.state.eStartTime == "" ? "form-control timepickerr" : "form-control timepickerr text-red-border"}
                                                            margin="normal"
                                                            id="startTime"
                                                            value={this.state.selectedStartTime}
                                                            onChange={(date)=>{
                                                                this.setState({selectedStartTime : date,startTimePickerOpenned : false,startTimerPopupClosed : true})
                                                                setTimeout(() => {
                                                                    this.setState({startTimerPopupClosed : false})
                                                                }, 0);
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                                hidden : true
                                                            }}
                                                            PopoverProps={{
                                                                disableRestoreFocus: true,
                                                                onClose: () => {
                                                                  this.setState({startTimePickerOpenned : false})
                                                                }
                                                            }}
                                                            InputProps={{
                                                                onFocus: () => {
                                                                  if(!this.state.startTimerPopupClosed){
                                                                    this.setState({startTimePickerOpenned : true},()=>{
                                                                        let buttons = document.getElementsByClassName('MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary')
                                                                        setTimeout(() => {
                                                                            buttons.item(0).addEventListener('click',(e)=>{
                                                                                this.setState({startTimePickerOpenned : false,startTimerPopupClosed : true})
                                                                                setTimeout(() => {
                                                                                    this.setState({startTimerPopupClosed : false})
                                                                                }, 0);
                                                                            })
                                                                        },0)
                                                                    })
                                                                  }
                                                                }
                                                            }}
                                                            open = {this.state.startTimePickerOpenned}
                                                        />
                                                    </div>
                                                    <div class="col-md-6 pl-3 pl-md-0 d-none d-md-block">
                                                        <label for=""><Trans>endTime</Trans></label>
                                                        <KeyboardTimePicker
                                                            className = {this.state.eEndTime == "" ? "form-control timepickerr" : "form-control timepickerr text-red-border"}
                                                            margin="normal"
                                                            id="endTime"
                                                            value={this.state.selectedEndTime}
                                                            onChange={(date)=>{
                                                                this.setState({selectedEndTime : date,endTimePickerOpenned : false,endTimerPopupClosed : true})
                                                                setTimeout(() => {
                                                                    this.setState({endTimerPopupClosed : false})
                                                                }, 0);
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                                hidden : true
                                                            }}
                                                            PopoverProps={{
                                                                disableRestoreFocus: true,
                                                                onClose: () => {
                                                                  this.setState({endTimePickerOpenned : false})
                                                                }
                                                            }}
                                                            InputProps={{
                                                                onFocus: () => {
                                                                  if(!this.state.endTimerPopupClosed){
                                                                    this.setState({endTimePickerOpenned : true},()=>{
                                                                        let buttons = document.getElementsByClassName('MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary')
                                                                        setTimeout(() => {
                                                                            buttons.item(0).addEventListener('click',(e)=>{
                                                                                this.setState({endTimePickerOpenned : false,endTimerPopupClosed : true})
                                                                                setTimeout(() => {
                                                                                    this.setState({endTimerPopupClosed : false})
                                                                                }, 0);
                                                                            })
                                                                        },0)
                                                                    })
                                                                  }
                                                                }
                                                            }}
                                                            
                                                            open = {this.state.endTimePickerOpenned}
                                                        />
                                                    </div>
                                                    <>
                                                    <div class="col-md-6 mb-3 mb-md-0 d-block d-md-none">
                                                        <label for=""><Trans>startTime</Trans></label>
                                                        <KeyboardTimePicker
                                                            className = {this.state.eStartTime == "" ? "form-control timepickerr" : "form-control timepickerr text-red-border"}
                                                            margin="normal"
                                                            id="startTime"
                                                            value={this.state.selectedStartTime}
                                                            onChange={(date)=>{
                                                                this.setState({selectedStartTime : date,startTimePickerOpenned : false,startTimerPopupClosed : true})
                                                                setTimeout(() => {
                                                                    this.setState({startTimerPopupClosed : false})
                                                                }, 0);
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                                hidden : true
                                                            }}
                                                            PopoverProps={{
                                                                disableRestoreFocus: true,
                                                                onClose: () => {
                                                                  this.setState({startTimePickerOpenned : false})
                                                                }
                                                            }}
                                                            InputProps={{
                                                                onFocus: () => {
                                                                  if(!this.state.startTimerPopupClosed){
                                                                    this.setState({startTimePickerOpenned : true},()=>{
                                                                        let buttons = document.getElementsByClassName('MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary')
                                                                        setTimeout(() => {
                                                                            buttons.item(0).addEventListener('click',(e)=>{
                                                                                this.setState({startTimePickerOpenned : false,startTimerPopupClosed : true})
                                                                                setTimeout(() => {
                                                                                    this.setState({startTimerPopupClosed : false})
                                                                                }, 0);
                                                                            })
                                                                        },0)
                                                                    })
                                                                  }
                                                                }
                                                            }}
                                                            open = {this.state.startTimePickerOpenned && isMobile}
                                                        />
                                                    </div>
                                                    <div class="col-md-6 pl-3 pl-md-0 d-block d-md-none">
                                                        <label for=""><Trans>endTime</Trans></label>
                                                        <KeyboardTimePicker
                                                            className = {this.state.eEndTime == "" ? "form-control timepickerr" : "form-control timepickerr text-red-border"}
                                                            margin="normal"
                                                            id="endTime"
                                                            value={this.state.selectedEndTime}
                                                            onChange={(date)=>{
                                                                this.setState({selectedEndTime : date,endTimePickerOpenned : false,endTimerPopupClosed : true})
                                                                setTimeout(() => {
                                                                    this.setState({endTimerPopupClosed : false})
                                                                }, 0);
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                                hidden : true
                                                            }}
                                                            PopoverProps={{
                                                                disableRestoreFocus: true,
                                                                onClose: () => {
                                                                  this.setState({endTimePickerOpenned : false})
                                                                }
                                                            }}
                                                            InputProps={{
                                                                onFocus: () => {
                                                                  if(!this.state.endTimerPopupClosed){
                                                                    this.setState({endTimePickerOpenned : true},()=>{
                                                                        let buttons = document.getElementsByClassName('MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary')
                                                                        setTimeout(() => {
                                                                            buttons.item(0).addEventListener('click',(e)=>{
                                                                                this.setState({endTimePickerOpenned : false,endTimerPopupClosed : true})
                                                                                setTimeout(() => {
                                                                                    this.setState({endTimerPopupClosed : false})
                                                                                }, 0);
                                                                            })
                                                                        },0)
                                                                    })
                                                                  }
                                                                }
                                                            }}
                                                            open = {this.state.endTimePickerOpenned && isMobile}
                                                        />
                                                    </div>
                                                    </>
                                                </div>

                                                <div class="form-group">
                                                    <label for=""><Trans>invitation</Trans></label>
                                                    <textarea class = {this.state.eInviteText == "" ? "form-control" : "text-red-border form-control"} name="" id="inviteText" rows="2"></textarea>
                                                </div>

                                            </form>
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div class="row b-row mx-0">
                                            <div class="col-12 px-0">
                                                <h6 class = "f-600 w-75 mb-0">
                                                    <Trans>meetingParticipants</Trans>
                                                </h6>
                                                <span class = "p_numbring">
                                                    {this.state.selectedParticipantsForMeeting.length+"/1"}
                                                </span>
                                            </div>
                                        </div>
                                        <ul class="list-group mt-3">
                                            {this.state.selectedParticipantsForMeeting.map((item,index)=>{
                                                return(
                                                    <li class="list-group-item border-0">
                                                        <div class="row">
                                                            <div class="col-3 col-md-2 px-0  d-flex align-items-center">
                                                                <div class="chat-u-img">
                                                                    {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                                    <div style={{width:'40px',height:'40px',display:'flex',justifyContent:'center',alignItems:'center',background:`${item.bgColor}`,borderRadius:'50%'}}>
                                                                        <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{this.NameHandler(item.firstName,item.lastName)}</p>
                                                                    </div>:<img src={item.profileImageUrl == null || item.profileImageUrl == "" ? "/images/profile-placeholder.png" : item.profileImageUrl} alt="participant_image" />}
                                                                    {this.props.event.is_jobfair == 1 && (item.hasOwnProperty('userType') &&  `${item.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())? 
                                                                     <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>:null}
                                                                </div>
                                                            </div>

                                                            <div class="col-9 px-0 col-md-10 d-flex align-items-center">
                                                                <div class="chat-u-name w-100">
                                                                    {
                                                                     item.firstName == null && item.lastName == null ? 'User' : 
                                                                    `${item.firstName == null ? " " : `${item.firstName}`.replace(null,'')} ${item.lastName == null ? " " : `${item.lastName}`.replace(null,'')}`} <br />
                                                                    <span class="post-title">
                                                                    {item.jobTitle != null ? wordsRestrictor(item.jobTitle,10):item.jobTitle} <br />
                                                                    {item.companyName != null ? wordsRestrictor(item.companyName,10):item.companyName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div onClick = {()=>this.OnClickRemoveParticipantFromMeeting(index)} class="add-btnn">
                                                                <img src="/images/ic_chat_close.svg" alt="alt-close-icon" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}

                                        </ul>

                                        <p class = "mt-4 position-relative">

                                            <a class="btn w-100 d-block d-md-none s-parti">
                                                <Trans>addParticipants</Trans>
                                            </a>

                                            <a onClick={this.OnClickSendMeetingRequest} class="btn mt-3 mt-md-0 w-100 mb-1 position-relative">
                                                <Trans>scheduleMeeting</Trans>
                                            </a>
                                            {this.props.sendingInvite ? <div class="d-flex justify-content-center mb-2">
                                                <Loader
                                                    type = 'Oval'
                                                    color = "#00b6ff"
                                                    height = {25}
                                                    width = {25}
                                                />
                                            </div> : null}
                                            <div style = {{width : '100%'}} class = "text-red text-center">{`${this.props.sendingInviteError}`.replace(null,'')}</div>
                                            <div style={{height:20}}></div>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-6 px-0 ml-side d-none d-md-block" id = "h-mobile">
                                    <div class="modal-header">
                                        <h6 class = "f-600 mb-0"><Trans>participants</Trans></h6>
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                            this.OnClickCloseMeetingModal()
                                                    document.getElementById('meetingTopic').value = ''
                                                    this.setState({selectedDate : new Date(),selectedStartTime : new Date().setMinutes(new Date().getMinutes()+15), selectedEndTime : new Date().setMinutes(new Date().getMinutes()+30)})
                                                    document.getElementById('inviteText').value = ''
                                            }} class = "close-icons" id = "close-m">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                                        </a>
                                    </div>
                                    <div class="modal-body">
                                        <div class="">
                                            <>
                                            <form action="">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <i class = "fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <input onChange = {this.OnChangeMeetingSearchText} type="text" class="form-control" id = "m_search" placeholder="Search Participant ..." />
                                                </div>
                                            </form>
                                            {this.props.meetingparticipentloader ? <div style = {{width : '100%',height : '100%',position : 'absolute',top : 0,left : 0,background : '#0000000d',zIndex : 999}}>
                    <div style = {{position : 'absolute',left : '50%',top : 250}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {40}
                            width = {40}
                        />
                    </div>              
                </div> : null}
                                            </>

                                            <ul class="list-group mt-3 r-side-list">
                                                {filteredMeetingList.map((item,index)=>{
                                                    return(
                                                    <li class="list-group-item border-0">
                                                        <div class="row">
                                                            <div class="col-3 col-md-2 px-0  d-flex align-items-center">
                                                                <div class="chat-u-img">
                                                                    {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                                    <div style={{width:'40px',height:'40px',display:'flex',justifyContent:'center',alignItems:'center',background:`${item.bgColor}`,borderRadius:'50%'}}>
                                                                    <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{this.NameHandler(item.firstName,item.lastName)}</p>
                                                                </div> :
                                                                    <img src={item.profileImageUrl} alt="participant_image" />}
                                                                     {this.props.event.is_jobfair == 1 && (item.hasOwnProperty('userType') &&  `${item.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())? 
                                                                     <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>:null}
                                                                    {/* {item.chatAvailabaility == "1" ? <div class = "online"></div> : null}
                                                                    {item.chatAvailabaility == "2" ? <div class = "offline"></div> : null} */}
                                                                     { item.isOnline != undefined &&
                                                                      item.isOnline  && utility.comparingUserSetTime(item.chatAvailabaility,item.chatAvailabailityDuration) == 'online' ?
                                                                       <div class = "online"></div> : 
                                                                       <div class = "offline"></div>}
                                                                </div>
                                                            </div>

                                                            <div class="col-9 px-0 col-md-10 d-flex align-items-center">
                                                                <div class="chat-u-name w-100">
                                                                { item.firstName == null && item.lastName == null ? 'User':
                                                                `${item.firstName == null ? " " : `${item.firstName}`.replace(null,'')} ${item.lastName == null ? " " : `${item.lastName}`.replace(null,'')}`} <br />
                                                                    <span class="post-title">
                                                                        {item.jobTitle} <br />
                                                                        {item.companyName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div onClick = {()=>this.OnClickScheduleMeeting(item.id,false)} class="add-btnn">
                                                                <img src="/images/ic_chat_close.svg" alt="alt-close-icon" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    )
                                                })}
                                            </ul>


                                        </div>
                                    </div>
                                </div>

                        </div>
                        </div>
                    </div>
                </div>
                <div class="modall fade left" id="spk-modal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">
                                <div class="row my-3">
                                    <div class="col-3 text-center">
                                        <div class="participant-image">
                                            {this.state.selectedParticipant.profileImageUrl == null || this.state.selectedParticipant.profileImageUrl == "" ? 
                                                                    <div style={{width:'70px',height:'70px',display:'flex',justifyContent:'center',alignItems:'center',background:`${this.state.selectedParticipant.bgColor}`,borderRadius:'50%'}}>
                                                                        <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{ this.NameHandler(this.state.selectedParticipant.firstName,this.state.selectedParticipant.lastName)}</p>
                                                                    </div>:
                                                <img src={this.state.selectedParticipant.profileImageUrl != "" ? this.state.selectedParticipant.profileImageUrl : "/images/profile-placeholder.png"} alt="participant_image" />}
                                            {this.props.event.is_jobfair == 1 && (this.state.selectedParticipant.hasOwnProperty('userType') &&  `${this.state.selectedParticipant.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())?  <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:'1px',left:'19px'}} alt="badge-img"/>:null}
                                        </div>
                                    </div>

                                    <div class="col-9 d-flex align-items-center">
                                        <div class="participant-details">
                                            <span class="spk-full-name">
                                                {this.state.selectedParticipant.firstName == null && this.state.selectedParticipant.lastName == null ? 'User' :
                                                `${this.state.selectedParticipant.firstName == null ? " " :`${this.state.selectedParticipant.firstName}`.replace(null,'')} ${this.state.selectedParticipant.lastName == null ? " " : `${this.state.selectedParticipant.lastName}`.replace(null,'')}`}
                                            </span>
                                            <div class="post">
                                                {this.state.selectedParticipant.jobTitle} <br/>
                                                {this.state.selectedParticipant.companyName}
                                            </div>
                                        </div>
                                    </div>
                                    { (this.props.event.islinkedinProfileLink != null && this.props.event.islinkedinProfileLink == 1 ) ||
                    (this.props.event.istwitterProfileLink != null && this.props.event.istwitterProfileLink == 1) || 
                    (this.props.event.isfacebookProfileLink != null && this.props.event.isfacebookProfileLink == 1) || 
                    (this.props.event.isinstagramProfileLink != null && this.props.event.isinstagramProfileLink == 1) 
                    // &&
                    // (this.state.selectedParticipant.twitterProfileLink == null) &&
                    // (this.state.selectedParticipant.facebookProfileLink == null) &&
                    // (this.state.selectedParticipant.instagramProfileLink == null) && 
                    // (this.state.selectedParticipant.linkedinProfileLink = null)
                    ? 
                    this.state.selectedParticipant.linkedinProfileLink == null && 
                    this.state.selectedParticipant.twitterProfileLink == null && 
                    this.state.selectedParticipant.facebookProfileLink == null &&
                    this.state.selectedParticipant.instagramProfileLink == null ? null:
                                        <div className="socialMediaContainer" style={{marginTop:'10px'}}>
                                    {(this.props.event.islinkedinProfileLink != null && this.props.event.islinkedinProfileLink == 1) && (this.state.selectedParticipant.linkedinProfileLink != null) ?  <div className="socialMediaIcons" style={{background:"#0077b5"}} onClick = {()=>{
                                        if(this.state.selectedParticipant.linkedinProfileLink != null){
                                        window.open(this.state.selectedParticipant.linkedinProfileLink,'_blank')
                                        }
                                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" color="#fff" viewBox="0 0 24 24"><path fill="#fff" d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                    </div>:null}
                    {(this.props.event.istwitterProfileLink != null && this.props.event.istwitterProfileLink == 1) && (this.state.selectedParticipant.twitterProfileLink != null)  ? <div className="socialMediaIcons" style={{background:"#00acee"}} onClick={() => {
                        if(this.state.selectedParticipant.twitterProfileLink != null){
                            window.open(this.state.selectedParticipant.twitterProfileLink,'_blank')
                            }
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
                    </div>:null}
                    {(this.props.event.isfacebookProfileLink != null && this.props.event.isfacebookProfileLink == 1) && (this.state.selectedParticipant.facebookProfileLink != null) ?
                        <div className="socialMediaIcons" style={{background:"#3b5998"}} onClick={()=>{
                            if(this.state.selectedParticipant.facebookProfileLink != null){
                                window.open(this.state.selectedParticipant.facebookProfileLink,'_blank')
                                }
                        }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                    </div>:null}
                    {(this.props.event.isinstagramProfileLink != null && this.props.event.isinstagramProfileLink == 1) && (this.state.selectedParticipant.instagramProfileLink != null) ? <div className="socialMediaIcons" style={{background:"radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)"}} onClick={()=>{
                        
                            if(this.state.selectedParticipant.instagramProfileLink != null){
                                window.open(this.state.selectedParticipant.instagramProfileLink,'_blank')
                                }
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </div>:null}
                </div>:null}
                                    <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                        let participantBody = document.getElementById("participant_body")
                                        participantBody.classList.remove("participant_body-before")
                                        let elem = document.getElementById("spk-modal")
                                        if(elem != null){
                                            elem.classList.remove("show")
                                            // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
                                            document.body.style.overflowY = "scroll"
                                        }
                                        // this.props.RemoveScroll(0)
                                        setTimeout(() => {
                                            this.setState({selectedParticipant : {}})
                                        }, 0);
                                    }} class="close-icons" id="close-m">
                                        <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                    </a>
                                </div>
                                <div class="spk-detail-text">
                                        {this.state.selectedParticipant.profileBio != null ?
                                        <p class="details pr-3 mb-2">
                                            {!this.state.showFullParticipantBio && this.state.selectedParticipant.profileBio.length >= 1000 ?
                                            this.state.selectedParticipant.profileBio.substring(0,800) :
                                            this.state.selectedParticipant.profileBio}
                                        </p> : null}
                                        {this.state.selectedParticipant.profileBio != null && this.state.selectedParticipant.profileBio.length >= 1000 ?
                                        <a style = {{cursor : 'pointer',textDecoration:'underline'}} onClick = {()=>{
                                            this.setState({showFullParticipantBio : !this.state.showFullParticipantBio})
                                        }} class="text-underline text-dark">{!this.state.showFullParticipantBio ? "Show Full" : "Show Less"}</a> : null}
                                </div>
                                <p class = "mt-4 mt-md-2 mb-0 text-center">
                                   {this.props.event.is_marketplace == 1 || this.props.event.is_jobfair == 1  ? null : <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickParticipantDetailModalMeeting(this.state.selectedParticipant.id)} class="btn mx-1">
                                        <Trans>scheduleMeeting</Trans>
                                    </a>}
                                </p>
                                <p class = "mt-0 mt-md-2 text-center">
                                        
                                </p>
                            </div>

                        </div>
                    </div>
                </div>                      
                <div class="modall fade left m-modal" id="meeting-modal-mobile">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="row mx-0">

                                <div class="col-12 px-0">
                                <div class="modal-header">
                                        <h6 class = "f-600 mb-0"><Trans>participants</Trans></h6>
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                            this.OnClickCloseParticipantModalMobile()
                                            // this.props.RemoveScroll(0)
                                        }
                                        } class = "close-icons" id = "close-m">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                                        </a>
                                    </div>
                                    <div class="modal-body">
                                        <div class="">
                                            <form action="">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <i class = "fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <input onChange = {this.OnChangeMeetingSearchText} type="text" class="form-control" id = "m_search" placeholder="Search Participant ..." />
                                                </div>
                                            </form>

                                            <ul class="list-group mt-3 r-side-list">
                                                {filteredMeetingList.map((item,index)=>{
                                                    return(
                                                    <li class="list-group-item border-0">
                                                        <div class="row">
                                                            <div class="col-3 col-md-2 px-0  d-flex align-items-center">
                                                                <div class="chat-u-img">
                                                                    {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                                    <div style={{width:'40px',height:'40px',display:'flex',justifyContent:'center',alignItems:'center',background:`${item.bgColor}`,borderRadius:'50%'}}>
                                                                    <p style={{margin:'0',padding:'0',fontWeight:'bold',color:'#fff'}}>{this.NameHandler(item.firstName,item.lastName)}</p>
                                                                </div> :
                                                                    <img src={item.profileImageUrl} alt="participant_image" />}
                                                                    {/* {item.chatAvailabaility == "1" ? <div class = "online"></div> : null}
                                                                    {item.chatAvailabaility == "2" ? <div class = "offline"></div> : null} */}
                                                                     {item.isOnline != undefined &&
                                                                      item.isOnline &&
                                                                       utility.comparingUserSetTime(item.chatAvailabaility,item.chatAvailabailityDuration) == 'online' ?
                                                                       <div class = "online"></div> :
                                                                        <div class = "offline"></div>}
                                                                </div>
                                                            </div>

                                                            <div class="col-9 px-0 col-md-10 d-flex align-items-center">
                                                                <div class="chat-u-name w-100">
                                                                {item.lastName == null && item.firstName == null ? 'User' :
                                                                `${item.firstName == null ? " " : `${item.firstName}`.replace(null,'')} ${item.lastName == null ? " " : `${item.lastName}`.replace(null,'')}`} <br />
                                                                    <span class="post-title">
                                                                        {item.jobTitle} <br />
                                                                        {item.companyName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div onClick = {()=>this.OnClickScheduleMeeting(item.id,false)} class="add-btnn">
                                                                <img src="/images/ic_chat_close.svg" alt="alt-close-icon" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    )
                                                })}
                                            </ul>


                                        </div>
                                    </div>
                                </div>

                        </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Content