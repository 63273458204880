import React from 'react';
import api from './api';
import Slider from "react-slick";
import {isMobile} from 'react-device-detect'
import 'slick-carousel'
import $ from 'jquery'
import 'bootstrap'
import { connect } from 'react-redux'
import { PartnersHomeDataLoaded } from '../SponserPage/actions';
import ContentLoader from 'react-content-loader'
import {Trans} from 'react-i18next'
window.$ = window.jQuery = $;
export class Sponsers extends React.Component{
    _isMounted = false
    numberOfSlides = 4
    constructor(props) {
        super(props);
        this.wholeNumberForMaxSlides = 5
        this.state = {
            event: this.props.event,
            event_alias: this.props.event_alias,
            partners : null,
            currentTransitionIndex : 0,
            defaultIndex : 0,
            title : props.title ? props.title : <Trans>partners</Trans>,
            showContent : false,
            isLoading : false
        };
        this.elem = []
    }

    componentDidMount(){
        this._isMounted = true
        if(isMobile){
            this.numberOfSlides = 1.2
            this.wholeNumberForMaxSlides = 2
        }
        else{
            this.numberOfSlides = 4
            this.wholeNumberForMaxSlides = 5
        }
        // setTimeout(() => {
        //     $(".customSlider").css({"min-width": (window.innerWidth*0.70)/this.numberOfSlides});
        // }, 2000);
        
        // $(document).ready(function () {
        //     $('.customer-logos').slick({
        //         slidesToShow: 1,
		// 		slidesToScroll: 1,
		// 		autoplay: true,
		// 		autoplaySpeed: 1500,
		// 		arrows: false,
		// 		dots: false,
		// 		pauseOnHover: false,
		// 		responsive: [{
		// 			breakpoint: 768,
		// 			settings: {
		// 				slidesToShow: 4
		// 			}
		// 		}, {
		// 			breakpoint: 520,
		// 			settings: {
		// 				slidesToShow: 3
		// 			}
		// 		}]
        //     })
        // })
        if(this.props.sponsors != null){
            let title = this.state.title
            if(this.props.sponsors.length == 1){
                title = "supporting organization"
            }
            this.setState({partners:this.props.sponsors,title : title,showContent : true,isLoading : false},()=>{
                // setTimeout(() => {
                //     this.setSliderWith()
                // }, 1000);
                this.setClassForSlideInternalDiv()
            })
            return
        }
        if(this.props.partners != null){
            let showContent = false
            if(this.props.partners.length > 0){
                showContent = true
            }
            this.setState({partners : this.props.partners,showContent,isLoading : false},()=>{
                this.setClassForSlideInternalDiv()
            })
            return
        }
        api.getEventSponsers(this.state.event.alias,this.props.type,(err,data)=>{
            if(err == null && this._isMounted){
                this.props.partnersLoaded(data.sponsers)
                this.setState({partners:data.sponsers},()=>{
                    this.setClassForSlideInternalDiv()
                })
            }
            this.setState({isLoading : false,showContent : true})
        })
    }
    componentDidUpdate(){
        // this.setSliderWith()
    }
    componentWillUnmount(){
        this._isMounted = false
    }
    setClassForSlideInternalDiv () {
        setTimeout(() => {
            let sliderItems = document.getElementsByClassName('slick-slide')
            for(var i=0;i<sliderItems.length;i++){
                sliderItems[i].children[0].className = "slide"
                sliderItems[i].children[0].children[0].style.height = "100%"
            }
        }, 0);
    }
    setSliderWith () {
        if(this.elem.length == 0){
            this.elem = document.getElementsByClassName("slick-track")
        }
        if(this.state.partners != null && this.state.partners.length <= this.wholeNumberForMaxSlides){
            if(this.elem[1] != null){
                let width = (((window.innerWidth*0.535)/this.numberOfSlides + 40) * this.state.partners.length) + 2
                this.elem[1].style.width = width+"px"
            }
        }
    }
    render(){
        if(this.state.isLoading){
            return(
                <section className = {"sponsors py-5 bg-light "}>
                    <div className="container">
                    <h5 className = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>
                    <ContentLoader style = {{padding : 10}} viewBox="0 0 380 40">  
                        <rect x="80" y="0" rx="5" ry="5" width="45" height="35" />
                        <rect x="135" y="0" rx="5" ry="5" width="45" height="35" />
                        <rect x="190" y="0" rx="5" ry="5" width="45" height="35" />
                        <rect x="245" y="0" rx="5" ry="5" width="45" height="35" />
                    </ContentLoader>
                    </div>
                </section>
            )
        }
        const partners = this.state.partners;
        const settings = {
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            dots: false,
            pauseOnHover: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 520,
                settings: {
                    slidesToShow: 2
                }
            }],
            speed: 1500,
            cssEase: "linear",
            swipeToSlide: true,
            // loop: true,
            // className: "row customSlider"+staticClass ,
            // variableWidth: true
        };
        let paddingClass = this.props.inAdditionalPage ? "py-lg-5" : "py-lg-3"
        return(
            partners != null && partners.length > 0 ?
            <section className = {"sponsors py-5 bg-light "+paddingClass}>
                <div class="container">
                    <h5 className = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>
                    <div class="row py-lg-3">
                        <div class="col-md-12 customer-logos">
                        <Slider {...settings}>
                            {partners.map((partner,index)=> {
                                return(
                                    <div>
                                        <img src={partner.image} alt = "sponsor-1"/>
                                    </div>
                                )
                            })}
                        </Slider>
                        </div>
                    </div>
                    {this.props.sponsors == null ?
                    <div className="row py-0 py-lg-1 justify-content-center">
                        <a onClick = {this.props.onClickSeeAllSponsors} style = {{cursor : 'pointer',minWidth : 55}} className = "btn-default see_btn">
                            <Trans>seeAll</Trans></a>
                    </div> : null}

                </div>
            </section> : null
                
        )
    }

}
const mapStateToProps = state => ({
    partners: state.partners.partnersHomeData
})
const mapDispatchToProps = dispatch => ({
    partnersLoaded : data => dispatch(PartnersHomeDataLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Sponsers);