import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import utility from '../../../Utility/utility';
import $ from 'jquery'
import 'bootstrap'
import hexToRgba from 'hex-to-rgba';
import ThirdStep from './thirdStep';
import { Trans } from 'react-i18next'
export class FirstStep extends React.Component {
    constructor(props) {
        super(props)
        this.validPackageItemCount = 0
        this.state = {
            tickets: this.props.tickets || [],
            selectedCurrency: this.props.selectedCurrency,
            selectedDelegateValues: [],
            validPackageItemCount: 0,
            eVipCode: '',
            guestEmail: '',
            eGuestEmail: '',
            backgroundColor: ""
        }
    }
    componentDidMount() {
        localStorage.removeItem("bookingLink")
        setTimeout(() => {
            let style = getComputedStyle(document.querySelector('.bg_primary'))
            var backgroundColor = style.backgroundColor
            var rgb = backgroundColor.match(/\d+/g);
            backgroundColor = "#" + this.componentToHex(parseInt(rgb[0])) + this.componentToHex(parseInt(rgb[1])) + this.componentToHex(parseInt(rgb[2]));
            this.setState({ backgroundColor: backgroundColor })
        }, 0);
        var selectedDelegateValues = []
        for (var i = 0; i < this.props.tickets.length; i++) {
            if (this.props.tickets[i].selectedDelegates != null) {
                selectedDelegateValues.push(this.props.tickets[i].selectedDelegates)
            }
            else {
                selectedDelegateValues.push(0)
            }
        }
        this.setState({ selectedDelegateValues })
        $("#form-line").on('submit', function (event) {
            event.preventDefault();
        });
    }
    componentToHex = (c) => {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
    renderOptions = (numberOfDelegates) => {
        var options = []
        for (var i = 0; i <= numberOfDelegates; i++) {
            options.push(<option>{i}</option>)
        }
        return options
    }
    onClickCurrencyItem = (currency) => {
        this.setState({ selectedCurrency: currency })
        this.props.onCurrencyUpdated(currency)
    }
    OnContinueClick = () => {
        var element = document.getElementById("vip_code")
        var vipCode = ""
        if (element != null) {
            vipCode = document.getElementById("vip_code").value
        }
        if (vipCode != "") {
            if (this.props.eventVipCode.findIndex(x => x.discountCode == vipCode.trim()) < 0) {
                this.setState({ eVipCode: 'Please enter a valid VIP code' })
                return
            }
        }
        this.setState({ eVipCode: '' })
        var tickets = this.props.tickets
        this.props.continueButtonEvent()
        for (var i = 0; i < tickets.length; i++) {
            if (document.getElementById("select" + i) != null) {
                var value = document.getElementById("select" + i).value
                tickets[i].selectedDelegates = value
            }
        }
        var data = {
            tickets: tickets,
            vipCode: vipCode,
            guestEmail: this.state.guestEmail
        }
        this.data = data
        this.props.onProceed(data)
    }
    OnClickContinueAsGuestPopup = () => {
        this.setState({ eGuestEmail: utility.validateEmail(this.state.guestEmail) ? "" : this.props.translation.validEmail }, () => {
            if (this.state.eGuestEmail == "") {
                $('#loginPopup').modal('hide');
                this.data.guestEmail = this.state.guestEmail
                this.props.onProceed(this.data)
            }
        })
    }
    OnSelectValueChanged = (index) => {
        var value = document.getElementById("select" + index).value
        var selectedDelegateValues = this.state.selectedDelegateValues
        selectedDelegateValues[index] = value
        this.setState({ selectedDelegateValues })
    }
    render() {
        let blurClass = this.props.eventExpired ? " blur" : ""
        return (
            <section class={"select-package py-5 event-closed" + blurClass}>
                <div class="container">
                    <div class="row">
                        <div class="col-label pl-3">
                            <p class="f-600 mt-1"><Trans>selectCurrency</Trans></p>
                        </div>
                        <div class="col-select px-3">
                            <ul class="list-inline">
                                {this.props.eventCurrencies.map((item, index) => {
                                    return (
                                        <li class="list-inline-item">
                                            <label class={this.state.selectedCurrency.symbol == item.symbol ? "radio-inline radio-button d-flex justify-content-center active" : "radio-inline radio-button d-flex justify-content-center"}>
                                                <input onClick={() => this.onClickCurrencyItem(item)} type="radio" name="group1" />
                                                {item.currency == "Dollars" ?
                                                    <i class="fas fa-dollar-sign"></i> :
                                                    item.currency == "Euro" ?
                                                        <i class="fas fa-euro-sign"></i> :
                                                        item.currency == "Pounds" ?
                                                            <i class="fas fa-pound-sign"></i> :
                                                            <div style={{ marginTop: -4 }}>
                                                                <i class="fas" style={{ fontSize: 12 }}>{item.symbol}</i></div>
                                                }
                                            </label>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div class="row pt-1">
                        {this.props.tickets.map((item, index) => {
                            this.validPackageItemCount = 0
                            return (
                                <div class="col-lg-4" style={{ display: item.show ? 'block' : 'none' }}>
                                    {/* <h6 class = "f-600 pb-3">{item.title}</h6> */}
                                    <div class="card text-center mb-3 mb-lg-0">
                                        <p class="py-3 px-3 text-left mb-0 bg_primary ticket_title text-center">{item.title}</p>

                                        {item.price.map((price, index) => {
                                            if (item.ticket_conditions.length == 0) {
                                                this.validPackageItemCount = 1
                                            }
                                            if (price.currency == this.state.selectedCurrency.symbol)
                                                return (
                                                    item.ticket_conditions.length > 0 ?
                                                        item.ticket_conditions.map((condition, index) => {
                                                            if (new Date(condition.date) > new Date().setHours(0, 0, 0, 0)) {
                                                                this.validPackageItemCount += 1
                                                            }
                                                            return (
                                                                new Date(condition.date) >= new Date().setHours(0, 0, 0, 0) ?
                                                                    <>
                                                                        <div style={{ backgroundColor: hexToRgba(this.state.backgroundColor, 0.5), color: '#fff' }} class="text-left py-2 px-3 text-center">
                                                                            {condition.discountString != null ?
                                                                                <p class="f-600 mb-0">
                                                                                    {condition.dateString} - <b>{condition.discountString}</b>
                                                                                </p> :
                                                                                <p class="f-600 mb-0">
                                                                                    {condition.dateString}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                        <div class="p-4 dis_div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <span class="f-600 float-left text-left rounded">
                                                                                <h2 class="mb-1 f-600">
                                                                                    {Math.round(price.prices[index]) == 0 ? item.free_ticket_text ? item.free_ticket_text : "Free" :
                                                                                        this.state.selectedCurrency.symbol + Math.round(price.prices[index])}
                                                                                </h2>
                                                                                {condition.title != "" ?
                                                                                    <>
                                                                                        <hr class="hrline  my-0" />
                                                                                        <p class="mt-1 mb-0 dis-p f-600">{condition.title}</p></> : null}
                                                                            </span>
                                                                            {Math.floor(price.actualPrice * (condition.discount_percentage / 100)) == 0 ? null :
                                                                                <span class="price-tag d-flex justify-content-center f-600 float-right bg_secondary">
                                                                                    <p class="mb-0">
                                                                                        <Trans>save</Trans><br />
                                                                                        {this.state.selectedCurrency.symbol + Math.floor(price.actualPrice * (condition.discount_percentage / 100))}
                                                                                    </p>
                                                                                </span>}
                                                                        </div>
                                                                    </> : null
                                                            )
                                                        }) :
                                                        <>
                                                            {item.show_end_date == 1 ?
                                                                <div class="bg-light text-left py-2 px-3">
                                                                    <p class="f-600 mb-0">
                                                                        <Trans>bookBy</Trans> {this.props.eventDateString}
                                                                    </p>
                                                                </div> : null}
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} class="p-4 dis_div">
                                                                <span class="f-600 float-left text-left rounded">
                                                                    <h2 class="mb-1 f-600">
                                                                        {price.price == 0 ? item.free_ticket_text ? item.free_ticket_text : "Free" :
                                                                            this.state.selectedCurrency.symbol + price.price}
                                                                    </h2>
                                                                    {/* <hr class = "hrline  my-0"/>
                                                <p class = "mt-1 mb-0 dis-p f-600">No Discount</p> */}
                                                                </span>
                                                                {/* {price.price == 0 ? null :
                                            <span class = "price-tag d-flex justify-content-center f-600 float-right bg_secondary">
                                                <p class = "mb-0">
                                                Save<br/>
                                                {this.state.selectedCurrency.symbol+ 0}
                                                </p>
                                            </span>} */}
                                                            </div>
                                                        </>

                                                )
                                            return null

                                        })}

                                        {this.validPackageItemCount > 0 ?
                                            <div style={{ borderBottomRightRadius: '0.2rem', borderBottomLeftRadius: '0.2rem', backgroundColor: hexToRgba(this.state.backgroundColor, 0.5), color: '#fff' }} class="py-2 text-left">
                                                <p class="f-600 mb-0 mx-3">
                                                    <span class="float-left"><Trans>selectDelegates</Trans></span>

                                                    <span class="float-right">
                                                        <select value={this.state.selectedDelegateValues[index]} onChange={() => this.OnSelectValueChanged(index)} id={"select" + index} class="form-control rounded-0 f-600 card-form">
                                                            {this.renderOptions(item.delegates)}
                                                        </select>
                                                    </span>

                                                </p>

                                            </div> : null}
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    <div class="paymentDiscriptionArea" style={{ display: 'contents' }}>
                        <div class="col-13 my-5">

                            {/* {this.props.bookingInfo != null ?  ReactHtmlParser(this.props.bookingInfo) : ""} */}
                            {/* <p>
                        <b>Please note:</b> The prices shown do not include the 5% UAE VAT. This will be added on the final invoice.
                    </p>

                    <p>
                        <h6 class = "f-600">Discount </h6>
                        Register 2 delegate(s) and the 3rd and subsequently registered delegates benefit from a 50% discount. This discount only applies to packages that include the main conference.
                    </p>

                    <p>
                        Only one type of discount in addition to early booking discounts can be applied to each booking. If there is a discount associated with the VIP code you enter below, this will take priority over the multi-booking discount unless the multi-booking discount is bigger. So you will always benefit from the biggest discount available to you. Discounts can only be claimed at the time of 
                        registration.
                    </p>

                    <p>
                        If you would like to purchase documentation for this event, please contact <a href = "mailto:svetlana@mwanevents.com" class = "text-red f-600">svetlana@mwanevents.com</a>
                    </p> */}
                            {this.props.bookingInfo != null ?
                                <p>
                                    {ReactHtmlParser(this.props.bookingInfo)}
                                </p> : null}
                            <p>
                                {ReactHtmlParser(this.props.dataProtection)}
                            </p>
                            <p>
                                {ReactHtmlParser(this.props.cancellationPolicy)}
                            </p>
                            {this.props.showVipCodeField ?
                                <div class="form-group float-left w-75-mob vip_code">
                                    <input defaultValue={this.props.vipCode} id="vip_code" type="email" class={this.state.eVipCode == "" ? "form-control" : "form-control text-red-border"} placeholder={this.props.translation.enterDiscountCode}
                                    // onKeyDown ={(e)=>{
                                    //         let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    //         let keyCode = e.which || e.keyCode
                                    //         if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                    //             e.preventDefault();
                                    //             return false
                                    //         }
                                    //         else{
                                    //             return true
                                    //         }

                                    //     }} 
                                    />
                                    <span id="click" class="text-danger" style={{ position: 'absolute' }}>{this.state.eVipCode}</span>
                                </div> : null}

                            <div class="form-group float-md-right">
                                <a onClick={this.OnContinueClick} style={{ cursor: 'pointer' }} class="btn btn-default btn-continue f-600 mt-2">
                                    <Trans>continue</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="modal fade" id="loginPopup">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body model-form p-3 py-md-4 px-md-5 border shadow-sm">
                                <form id="form-line" class="">
                                    <h5 class="text-center mb-3"><Trans>continueBooking</Trans></h5>
                                    <div class="form-group" style={{ marginBottom: '1.5rem' }}>
                                        <input
                                            type="text"
                                            placeholder={this.props.translation.enterYourEmail}
                                            id="email"
                                            onKeyDown={(e) => {
                                                let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                                let keyCode = e.which || e.keyCode
                                                if (keyCode == 32 || upperCaseLetter.includes(e.key)) {
                                                    e.preventDefault();
                                                    return false
                                                }
                                                else {
                                                    return true
                                                }

                                            }}
                                            class={this.state.eGuestEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}
                                            onChange={(e) => this.setState({ guestEmail: e.target.value })}
                                        />
                                        <div className='text-red' style={{ position: 'absolute' }}>{this.state.eGuestEmail}</div>
                                    </div>

                                    <div class="form-group">
                                        {/* <a style = {{justifyContent : 'center',display : 'flex'}} type="submit" class="btn form-control rounded-0">Continue as a Guest</a> */}
                                        <input id="guest-continue" type="submit" class="btn form-control rounded-0" onClick={this.OnClickContinueAsGuestPopup} style={{ justifyContent: 'center', display: 'flex' }} value={this.props.translation.continueAsGuest}></input>
                                    </div>
                                    <div class="form-group text-center">
                                        <span><Trans>or</Trans></span>
                                    </div>
                                    <div class="form-group">
                                        {/* <a style = {{justifyContent : 'center',display : 'flex'}} onClick = {this.props.onLoginClick} type="submit" class="btn form-control rounded-0">Login / Signup</a> */}
                                        <input type="submit" class="btn form-control rounded-0" onClick={() => {
                                            localStorage.setItem("bookingLink", "link")
                                            this.props.onLoginClick()
                                        }} style={{ justifyContent: 'center', display: 'flex' }} value={this.props.translation.loginSignup}></input>
                                        <small id="emailHelp" class="form-text text-muted text-center"><Trans>recommended</Trans></small>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FirstStep;