    const participants = (state = {
        participantsList : [],
        onlineParticipantIds : [],
        participentdata:[],
        meetingparticipent:[],
        participentChatList:[]
    }, action) => {
    switch (action.type) {
        case 'PARTICIPANTS_LIST' :
            return{
                ...state,
                participantsList : action.payload
            }
        case 'ONLINE_PARTICIPANTS' :
            return{
                ...state,
                onlineParticipantIds : action.payload,
                participentdata:action.payload
            }
            case 'ONLINE_USER' :
                return{
                    ...state,
                    participentdata:action.payload
                }
            case 'MEETING_USER':
                return {
                    ...state,
                    meetingparticipent:action.payload
                }
        default:
            return state
        }
  }
  export default participants