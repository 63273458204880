import React from "react";
import api from "./api";
import Header from "../Header/Index";
import Content from "./content";
import Footer1 from "../Footer/Footer1";
import Footer2 from "../Footer/Footer2";
import NewsLetter from "../NewsLetter";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import Loader from "react-loader-spinner";
import $ from 'jquery'
import { ErrorScreen } from "../ErrorScreen";
import utility,{jobBoardNavigator} from "../../Utility/utility";
import Helmet from "react-helmet";
import { useLastLocation } from "react-router-last-location";
import MetaTags from "react-meta-tags";
import { Constants } from "../../Constants";
import { subscribeUser } from "../Notification/subscription";
import { GAPageView, GARecordEvent } from "../../Utility/GA";
import {compose} from 'redux'
import {Trans,withTranslation} from 'react-i18next';
import { RemoveScroll } from "../1 - Sub menu components/Virtual Exhibition/actions";
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingError: "",
      signingUpUser: false,
      signUpError: "",
      hearOptionsAPI :[
        "Facebook",
        "LinkedIn",
        "Twitter",
        "Instagram",
        // this.translation.email,
        // this.translation.friends,
        // this.translation.other,
        "email",
        "friends",
        "other"
    ],
    confirmationdialog:false,
    nextUrl:'',
    dataurl:false,
    customLink:false,
    linkTarget:''
    };
    this.lastLocation = null;
    this.jobFairNavigation = false
    this.jobFairRoute = ''
    
  }

  componentDidMount() {
    GAPageView(
      window.location.hostname +
        window.location.pathname +
        window.location.search
    );
    if(this.props.event.is_jobfair == 1){
      this.jobFairNavigation = true
    }
    fetch("/setCurrentPage?page=signup");
    fetch(`/${this.props.event.alias}/signup`);
    document.addEventListener("navigateToLogin", (event) => {
      this.OnNavigateToPage(this.props.event.alias + "/login");
    });

   

    if (utility.getLoggedInUser(this.props.event.alias) != null) {
      this.OnNavigateToPage(this.props.event.alias + "/account");
    }
    let data = this.props.location.state || {};
    if (data.lastLocation != null) {
      this.lastLocation = data.lastLocation;
    }
    if(data.allJobsNavigate && data.route){
      this.jobFairNavigation = data.allJobsNavigate
      this.jobFairRoute = data.route
    }
  }
  OnConfirmation=(path, sendData = false)=>{
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    } else {
      if (this.lastLocation != null) {
        path = this.lastLocation;
        if (path.indexOf("login") >= 0) {
          path = path.replace("login", "account");
        }
      }
    }
    if (sendData) {
      this.props.history.push({
        pathname: path,
        state: { lastLocation: this.lastLocation },
      });
    } else {
      this.props.history.push(path);
    }
    this.setState({ nextUrl:'',
      dataurl:false})
  }
OnMovetojobBoard = (item,target)=>{
  // let selectedLocale = localStorage.getItem("language"+this.props.event.alias) || 'en';
  let loggerUser = utility.getLoggedInUser(this.props.event.alias)
  jobBoardNavigator(process.env.REACT_APP_JOBFAIR_REDIRECT_URL,item,this.props.event.alias,target,loggerUser)
      // var hash = localStorage.getItem('login_hash')
      // let location =  item+"?hash="+hash+"&URI="
      // +window.location.origin+"&alias="+this.props.event.alias+"&locale="+selectedLocale
      // if(item == process.env.REACT_APP_JOBFAIR_REDIRECT_URL ){
      //   if(loggerUser == null){
      //     window.open(item,target)
      //   }else{
      //     window.open(location,target)
      //   }
      // }
      // else{
      //   window.open(item,target)
      // }
}
  OncustomNavigate = (path,sendData = false,target='_self')=>{
    this.setState({ nextUrl:path,
      dataurl:sendData,customLink:true,linkTarget:target})
      var email = document.getElementById('email') && document.getElementById('email').value
        var firstName = document.getElementById('firstName') && document.getElementById('firstName').value
        var lastName = document.getElementById('lastName') && document.getElementById('lastName').value 
        var password = document.getElementById('password') && document.getElementById('password').value
        var confirmPassword = document.getElementById('confirmPassword') && document.getElementById('confirmPassword').value
        var termsCondition = document.getElementById('termsCondtions') && document.getElementById('termsCondtions').checked
        var aboutUs = document.getElementById('aboutUs') && document.getElementById('aboutUs').value
        if(this.props.event.is_jobfair == 1){
          if(email != '' && firstName!='' && confirmPassword!=''&& termsCondition == true && password != ''){
            $("#userConfirmation").modal('show')
            this.props.RemoveScroll(1)
            window.scrollTo(0,0)
          }else{
            this.OnMovetojobBoard(path,target)
          }
        }else{
          if(email != '' && firstName!=''&&lastName!='' && password != '' && confirmPassword!=''&&termsCondition == true && aboutUs != ''){
            $("#userConfirmation").modal('show')
            this.props.RemoveScroll(1)
            window.scrollTo(0,0)
          }
          else{
            this.OnMovetojobBoard(path,target)
          }
        }
          

  }
  OnNavigateToPage = (path, sendData = false,auto=false) => {
    if(auto == false){
    this.setState({ nextUrl:path,
    dataurl:sendData})
        var email = document.getElementById('email') && document.getElementById('email').value
        var firstName = document.getElementById('firstName') && document.getElementById('firstName').value
        var lastName = document.getElementById('lastName') && document.getElementById('lastName').value 
        var password = document.getElementById('password') && document.getElementById('password').value
        var confirmPassword = document.getElementById('confirmPassword') && document.getElementById('confirmPassword').value
        var termsCondition = document.getElementById('termsCondtions') && document.getElementById('termsCondtions').checked
        var aboutUs = document.getElementById('aboutUs') && document.getElementById('aboutUs').value

  // if(currentUrl.split('/').includes('sign_up')){
    if(this.props.event.is_jobfair == 1){
      if(email != '' && firstName!='' && confirmPassword!=''&& termsCondition == true && password != ''){
        $("#userConfirmation").modal('show')
        this.props.RemoveScroll(1)
        window.scrollTo(0,0)
      }
      else{
        window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    } else {
      if (this.lastLocation != null) {
        path = this.lastLocation;
        if (path.indexOf("login") >= 0) {
          path = path.replace("login", "account");
        }
      }
    }
    if (sendData) {
      this.props.history.push({
        pathname: path,
        state: { lastLocation: this.lastLocation },
      });
    } else {
      this.props.history.push(path);
    }
      }


    }else{
      if(email != '' && firstName!=''&&lastName!='' && password != '' && confirmPassword!=''&&termsCondition == true && aboutUs != ''){
        $("#userConfirmation").modal('show')
        this.props.RemoveScroll(1)
        window.scrollTo(0,0)
      }
      else{
        window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    } else {
      if (this.lastLocation != null) {
        path = this.lastLocation;
        if (path.indexOf("login") >= 0) {
          path = path.replace("login", "account");
        }
      }
    }
    if (sendData) {
      this.props.history.push({
        pathname: path,
        state: { lastLocation: this.lastLocation },
      });
    } else {
      this.props.history.push(path);
    }
      }


    }
  }
    
    else{
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    } else {
      if (this.lastLocation != null) {
        path = this.lastLocation;
        if (path.indexOf("login") >= 0) {
          path = path.replace("login", "account");
        }
      }
    }
    if (sendData) {
      this.props.history.push({
        pathname: path,
        state: { lastLocation: this.lastLocation },
      });
    } else {
      this.props.history.push(path);
    }
  }
  };
  OnPressRetry = () => {
    this.setState({ loadingError: "" });
  };
  OnProcessSignup = (
    firstName='',
    secondName='',
    lastName='',
    email='',
    phone='',
    password='',
    type='',
    companyName='',
    jobTitle='',
    aboutUs='',
    formattedPhone='',
    idomaConsets = false
    
  ) => {
    var data = {
      firstName: firstName,
      lastName: lastName,
      secondName: secondName,
      email: email,
      phone: phone == "" ? null : phone,
      password: password,
      termsCondition: 1,
      type: type,
      profileImageUrl: "",
      domainUrl: window.location.origin,
      companyName: companyName,
      jobTitle: jobTitle,
      hearAboutUs: aboutUs,
      formattedPhone: formattedPhone,
      idioma_consent:idomaConsets
    };
    this.setState({ signingUpUser: true });
    api.signUpUser(this.props.event.alias, data,this.props.event.is_jobfair, (err, user) => {
      this.setState({ signingUpUser: false });
      if (err == null) {
        this.setState({ signUpError: "" });
        user.profileImageUrl = "";
        localStorage.setItem(
          Constants.API_TOKEN + this.props.event.alias + "user_info",
          JSON.stringify(user)
        );
        data.id = user.id
        this.props.onSignUpSuccess(data);
        // subscribeUser(user.id, this.props.event.alias);
        if(this.jobFairNavigation){
            this.navigateToJobFair()
        }else{
            this.OnNavigateToPage('/',false,true)
        }
      } else {
        if (err.toString().indexOf("timeout") > -1) {
          this.setState({
            signUpError: <Trans>loginError</Trans>,
          });
        } else {
          var message = "";
          if (err.processCode == 2) {
            message = err.errors.email[0];
          } else {
            message = err.message;
          }
          this.setState({ signUpError: message });
        }
      }
    });
  };
  languageClick = (value) => {
    //console.log("Trigger signup on lan select",value);
    this.props.languageClick(value);
  };

  signUpEvent= (name,email) => {
        GARecordEvent("signUpClicked",{
            category : 'Signup',
            action : 'Singup button clicked',
            userName: name,
            userEmail: email
        })
  }

  OnClickAccountItem = (index) => {
    this.props.onClickAccountItem(index);
    if (index == null) return;
    if (index > -1) {
      this.props.history.replace("");
      this.props.history.push({
        pathname: this.props.event.alias + "/account",
        state: { tabIndex: index },
      });
    } else {
      localStorage.removeItem(
        Constants.API_TOKEN + this.props.event.alias + "user_info"
      );
      window.location.reload();
    }
  };
  getLastLocation = () => {
    if (this.lastLocation == null) {
      this.lastLocation = useLastLocation();
      if (this.lastLocation != null) {
        this.lastLocation = this.lastLocation.pathname;
      }
    }
    return null;
  };
  navigateToJobFair = () => {
    var hash = localStorage.getItem('login_hash')
    let selectedLocale = localStorage.getItem("language"+this.props.event.alias) || 'en';
    let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
    +window.location.origin+"&alias="+this.props.event.alias+"&locale="+selectedLocale+"&route="+this.jobFairRoute
    window.location.href = location
  }

  componentWillUnmount(){
    this.props.RemoveScroll(0)
    window.scrollTo(0,0)
  }

  render() {
    return (
      <div>
        <this.getLastLocation />
        {this.state.signingUpUser ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
            }}
          />
        ) : null}
        <Helmet>
          <meta charSet="utf-8" />
          <title>Signup</title>
        </Helmet>
        <div id="header-top">
          <Header
            headerindication='signup'
            OncustomNavigate = {this.OncustomNavigate}
            onMenuItemClick={this.OnNavigateToPage}
            elements={this.props.headerMenuItems}
            facebookLink={this.props.event.facebook}
            linkedInLink={this.props.event.linkedIn}
            twitterLink={this.props.event.twitter}
            instagramLink={this.props.event.instagram}
            isMarketplace = {this.props.event.is_marketplace}
            googleLink={this.props.event.google}
            phone={this.props.event.phone_number}
            eventLogo={this.props.event.logo}
            event = {this.props.event}
            eventAlias={this.props.event.alias}
            eventName={this.props.event.name}
            availableLocales={this.props.availableLocales}
            languageClick={this.languageClick}
            onLoginClick={() =>
              this.OnNavigateToPage(
                this.props.event.alias + "/login",
                this.lastLocation != null
              )
            }
          />
        </div>
        <div className={`content-main`}>
          <Content
            eventAlias = {this.props.event.alias}
            onLoginClick={() =>
              this.OnNavigateToPage(
                this.props.event.alias + "/login",
                this.lastLocation != null
              )}
            useTranslation ={this.props.i18n}
            event = {this.props}
            processSignup={this.OnProcessSignup}
            signUpInProcess={this.state.signingUpUser}
            signUpError={this.state.signUpError}
            signUpEvent = {this.signUpEvent}
            hearOptionsAPI ={this.state.hearOptionsAPI}
            joBFAIR = {this.props.event.is_jobfair}
            consetClick = {this.OnNavigateToPage}
          />
          <Footer1
            event={this.props.event}
            elements={this.props.footerMenuItems}
            onMenuItemClick={this.OnNavigateToPage}
          />
        </div>
        <Footer2
        event={this.props.event}
          copyRightLogo={this.props.event.copyright_logo}
          copyRightText={this.props.event.copyright_text}
          copyRightLink={this.props.event.copyright_link}
          showCopyRight={
            this.props.event.copyright_text || this.props.event.copyright_logo
          }
        />
          <div class="modal" id="userConfirmation" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-md confirmation-dialog">
                <div class="modal-content text-center login-req">
                    <div class="modal-body p-3 p-md-5">
                    <div className="user-cross-btn" onClick={()=>{
                      $("#userConfirmation").modal('hide')
                      this.props.RemoveScroll(0)
                       window.scrollTo(0,0)
                    }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x bi-cancel" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-exclamation-circle user-confirmation" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
</svg>
                            <h1>{this.props.event.is_jobfair != 1 ? <Trans>confirm_navigation</Trans>:<Trans>personal_profile</Trans>}</h1>
                            {this.props.event.is_jobfair == 1 ? <p>{<Trans>completePersonalProfile</Trans>}</p>:null}
                            <p>{this.props.event.is_jobfair == 1 ? <Trans>navigate_away</Trans>:<Trans>lost_changes</Trans>}</p>
                            {this.props.event.is_jobfair != 1 ? <p className="confirm_msg"><Trans>are_you_sure</Trans></p>:<p className="confirm_msg"><Trans>applytoJob</Trans></p>}
                            <div class="btn-row mt-3 mt-md-4">
                                <ul class="list-inline" style={{display:"flex",flexDirection:"row-reverse",justifyContent:"center"}}>
                                    <li class="list-inline-item mx-3 telehealth-btn">
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                          $("#userConfirmation").modal('hide')
                                          this.props.RemoveScroll(0)
                                           window.scrollTo(0,0)

                                        }} class="btn">{this.props.event.is_jobfair != 1 ? <Trans>stay_on_this_page</Trans>:<Trans>continue_with_profile</Trans>}</a>
                                    </li>
                                    <li class="list-inline-item mx-3 ">
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                           $("#userConfirmation").modal('hide')
                                           if(this.state.customLink == true){
                                             console.log(this.state.nextUrl)
                                            this.OnMovetojobBoard(this.state.nextUrl,this.state.linkTarget)
                                              this.setState({nextUrl:'',dataurl:false,customLink:'',linkTarget:''})
                                           }else{
                                            this.OnConfirmation(this.state.nextUrl,this.state.dataurl)
                                           }
                                          
                                          this.props.RemoveScroll(0)
                                           window.scrollTo(0,0)
                                        }} class="btn naviagte-btn"><Trans>leave_page</Trans></a>
                                    </li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  RemoveScroll : data => dispatch(RemoveScroll(data))
})

export default compose(withRouter,
  connect(null, mapDispatchToProps),
   withTranslation())(Signup);
