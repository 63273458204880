import axios from '../axios/index'

//const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNmMyZjFhNGE2ZWIwYjI3NGNlYzBhOCIsInR5cGUiOiJjYW5kaWRhdGUiLCJpYXQiOjE2MTc4NjA0NDN9.I1hC81Od4yVI3QAWrg1JgjSc-7FZ8P7l3AXsqoodnBg'
//const token = localStorage.getItem("login_hash")

export default {
  getUserJobs: (token, offset = 5) => {
    //console.log("JOB BOARD API", token)
    const get_data = axios.get(`${process.env.REACT_APP_JOBFAIR_API_ENDPOINT}users/company/jobs`,
      { params: { offset: offset, matchingJobs:true,home: true } ,
      headers: { Authorization: `${token}` } }
    );
    return get_data;
  },
  getRandomJobs: (offset = 5) => {
    const get_data = axios.get(`${process.env.REACT_APP_JOBFAIR_API_ENDPOINT}users/company/jobs`, {
      params: { offset: offset, home: true },
    }); //, { headers: { Authorization : `${token}` } }
    return get_data;
  },
  getJobs: (companyId,offset = 0) => {
    const get_data = axios.get(`${process.env.REACT_APP_JOBFAIR_API_ENDPOINT}users/company/jobs`, {
      params: { offset: offset,company_id:companyId },
    }); //, { headers: { Authorization : `${token}` } }
    return get_data;
  },
};
// , { headers: { Authorization : `${token}` } }
