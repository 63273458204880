import React, { useState, useEffect } from "react";
import api from "./api.js"; //CHANGE PATH TO YOURS
import MyLoader from "./ContentLoader.js";
import "./index.css";
import { Link, useHistory } from "react-router-dom";
import { Constants } from "../../Constants";
import utility from "../../Utility/utility";
import {Trans} from 'react-i18next';

const JobBoard = (props) => {
  let history = useHistory();
  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(null);

  var hash = localStorage.getItem('login_hash')
  const allJobsUrl = process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"?URI="+window.location.origin;
  
  
  //fetching jobs
  useEffect(() => {
    //console.log("Getting Data");
    let temp_user = utility.getLoggedInUser(props.alias); //JSON.parse(localStorage.getItem(Constants.API_TOKEN + props.alias + "user_info"));
    if (temp_user === null || temp_user === undefined) {
      //console.log("Calling as a non user", temp_user);
      api.getRandomJobs().then((res) => {
        if(Array.isArray(res.data)){
          setJobs(res.data);
        }
        setLoad(true);

      }).catch((err) => {

      });
    }
    else {
      setUser(temp_user);
      let token = temp_user.jobFairJwt;
      api
        .getUserJobs(token)
        .then((res) => {
          setJobs(res.data);
          //setTimeout(() => { setLoad(true); }, 5000)
          setLoad(true);
          //console.log("get data", res.data);
        })
        .catch((err) => console.log("GOT AN ERROR" + err));
    }
  }, [history]);
  const onClickDetails =(jobId)=>{
    let selectedLocale = localStorage.getItem("language"+props.alias) || 'en';
    let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?URI="
    +window.location.origin+"&alias="+props.alias+"&locale="+selectedLocale+"&route=/users/company/jobs/"+jobId
    window.location.href = location
  }
  if (!load) {
    return <MyLoader />;
  } else
    if(load && jobs.length == 0){
      return null
    }
    return (
      <>
        <section class="job-board-section bg_secondary py-3">
          <div class="container">
            <h2 class="text-center font-weight-bold text-uppercase text-white">
              <Trans>jobBoard</Trans>
            </h2>

            <div class="row py-3">
              <div class="col-12">
                <ul class="list-group list-group-flush job-board-list">
                  {jobs.map((job) => (
                    <li class="list-group-item" key={job._id}>
                      <div class="row">
                        <div class="col-10 col-md-8 text-left">
                          <span>{job.name}</span>
                        </div>
                        <div class="col-2 col-md-4 text-right">
                          <a style={{cursor: 'pointer'}} onClick={()=> props.onClickSeeAllJobs('/users/company/jobs/'+job._id)} class="job-detail-btn">
                            <span style={{color:'#000'}} class="d-none d-md-block"><Trans>jobDetails</Trans></span>
                            <i class="fas fa-angle-right d-block d-md-none" style={{color:'black'}}></i>
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center py-3">
                  <a
                    style = {{cursor: 'pointer'}}
                    onClick = {()=> props.onClickSeeAllJobs('/candidate-job-search')}
                    target="_self"
                    class=" job-detail-btn see_btn_all"
                  >
                    <Trans>seeAllJobs</Trans>
                  </a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
};
export default JobBoard;
