import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getEventAgenda(eventAlias,userId,type,callback){
        axios.get(`${eventAlias}/getEventAgenda`,
        {
            params: {type: type,isActive:1,isPreview : Constants.isPreview,user_id : userId},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    getUserSessionStatus(eventAlias,jwt,params,callback){
        axios.get(`${eventAlias}/getUserSessionValidationStatus`,
            {
                params: params,
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : `Bearer ${jwt}`
                }
            }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            error.code = 404
            callback(error,null)
        })
    },
    saveUpdateQuestion(eventAlias,isSaving,data,callback){
        axios.post(isSaving ? '/questions/save' : '/questions/update',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data)
            }
            else{
                var error = {
                    code : response.data.error.code,
                    message : response.data.error.message
                }
                callback(error,null)
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    async getAllQuestions(eventAlias,params,callback){
        await axios.get('/questions/get',
        {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    async getAllAnnoucements(eventAlias,params,callback){
        await axios.get('/announcements/get',
        {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    saveUserSessionTime(eventAlias,data,callback){
        axios.post('/certificates/save',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.message)
            }
            else{
                var error = {
                    code : response.data.error.code,
                    message : response.data.error.message
                }
                callback(error,null)
            }
        }).catch(error => {
            callback(error,null)
        })
    }
}