import React from 'react'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import { connect } from 'react-redux'
import {compose} from 'redux'
import utility, { monthNamesShort, weekDays } from '../../../Utility/utility';
import Header from '../../Header/Index';
import VirtualZone from '../../Header/virtualZone';
import { Helmet } from 'react-helmet';
import { Constants } from '../../../Constants';
import appLevelApi from '../../App/api'
import api from './api'
import { Content } from './content'
import {Modal,Button} from 'react-bootstrap'
import { UpdateMyFavourites } from '../../Account/actions'
import accountApi from '../../Account/api'
import Loader from 'react-loader-spinner'
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import { GAPageView } from '../../../Utility/GA'
import { UpdateInvitationsList } from './actions'
import  PermiumUserModal  from "../../modals/premiumUserModal";
export class Invitations extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            showReceived : true,
            updatingInviteId : -1,
            updatingInviteStatus : false,
            allCalenderEvents : [],
            loading : false,
            selectedCalenderViewIndex : 0,
            selectedCalendarDateText : '',
            selectedCalendarEvent : null,
            virtualZoneItemIndex : this.props.virtualMenuItems.findIndex(x=>x.path == '/invitations'),
            loadingInvites : props.loadingInvites,
            showCalendar : true,
            popUpErrorHeading:'',
            popUpErrorMessage:""
        }
        this.allInvites = this.props.allInvites
    }
    async componentWillReceiveProps(nextProps){
        if(this.state.allCalenderEvents.length == 0){
        // document.addEventListener('invitesLoadingStarted',this.handleOnInvitesLoadingStarted)
        // document.addEventListener('invitesLoaded',this.handleOnInvitesFromApiLoaded)
        // document.addEventListener('updateInvites',this.handleOnInviteRecieved)
        let mergedInvites = [...nextProps.allInvites.sent,...nextProps.allInvites.received]
        await this.checkForNewInvites(this.allInvites)
        var allCalenderEvents = this.initializeInvitations(mergedInvites)
        this.myFavourites = []
        if(nextProps.myFavourites != null){
            this.myFavourites = nextProps.myFavourites
            let myFavCalenderItems = this.getMyfavouriteCalenderEvents()
            allCalenderEvents = [...allCalenderEvents,...myFavCalenderItems]
        }else{
            this.setState({loading : true})
            await accountApi.getMyFavorites(nextProps.event.alias,this.user.token,(err,favorites)=>{
                this.setState({loading : false})
                if(err == null){
                    this.myFavourites = favorites
                    this.props.updateMyfavourites(favorites)
                    let myFavCalenderItems = this.getMyfavouriteCalenderEvents()
                    allCalenderEvents = [...allCalenderEvents,...myFavCalenderItems]
                }
                else{
                    console.log('my favs, ',err)
                }
            })
        }
        let currentDate = new Date()
        let selectedCalendarDateText = weekDays[currentDate.getDay()] + " " + currentDate.getDate() + " " + monthNamesShort[currentDate.getMonth()]
        this.setState({allCalenderEvents,selectedCalendarDateText})
        $(".see_invitations").click(()=>{
            this.setState({showCalendar : false})
            $(".tab-invitation-left").addClass('show');
            $("#calendar-area").css("visibility","hidden")
        })

        $(".tab-invitation-left .close-icons").click(()=>{
            $(".tab-invitation-left").removeClass('show');
            $("#calendar-area").css("visibility","visible")
        })
        if(window.outerHeight > 767){
            $(document).ready(()=>{
                let height = $(window).height()
                $('.c_tab-body').css('height',(height - 215)+'px')
            })
        }
    }
    }

    async componentDidMount () {
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=invitations')
        fetch(`/${this.props.event.alias}/invitations`)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        this._isMounted = true
        this.user = utility.getLoggedInUser(this.props.event.alias)
        if(this.user == null){
            this.OnNavigateToPage('/')
            return
        }else{
            if(!this.user.isBooking){
                this.OnNavigateToPage('/')
                return
            }
        }
        document.addEventListener('invitesLoadingStarted',this.handleOnInvitesLoadingStarted)
        document.addEventListener('invitesLoaded',this.handleOnInvitesFromApiLoaded)
        document.addEventListener('updateInvites',this.handleOnInviteRecieved)
        let mergedInvites = [...this.allInvites.sent,...this.allInvites.received]
        await this.checkForNewInvites(this.allInvites)
        var allCalenderEvents = this.initializeInvitations(mergedInvites)
        this.myFavourites = []
        if(this.props.myFavourites != null){
            this.myFavourites = this.props.myFavourites
            let myFavCalenderItems = this.getMyfavouriteCalenderEvents()
            allCalenderEvents = [...allCalenderEvents,...myFavCalenderItems]
        }else{
            this.setState({loading : true})
            await accountApi.getMyFavorites(this.props.event.alias,this.user.token,(err,favorites)=>{
                this.setState({loading : false})
                if(err == null){
                    this.myFavourites = favorites
                    this.props.updateMyfavourites(favorites)
                    let myFavCalenderItems = this.getMyfavouriteCalenderEvents()
                    allCalenderEvents = [...allCalenderEvents,...myFavCalenderItems]
                }
                else{
                    console.log('my favs, ',err)
                }
            })
        }
        let currentDate = new Date()
        let selectedCalendarDateText = weekDays[currentDate.getDay()] + " " + currentDate.getDate() + " " + monthNamesShort[currentDate.getMonth()]
        this.setState({allCalenderEvents,selectedCalendarDateText})
        $(".see_invitations").click(()=>{
            this.setState({showCalendar : false})
            $(".tab-invitation-left").addClass('show');
            $("#calendar-area").css("visibility","hidden")
        })

        $(".tab-invitation-left .close-icons").click(()=>{
            $(".tab-invitation-left").removeClass('show');
            $("#calendar-area").css("visibility","visible")
        })
        if(window.outerHeight > 767){
            $(document).ready(()=>{
                let height = $(window).height()
                $('.c_tab-body').css('height',(height - 215)+'px')
            })
        }
    }
    getMyfavouriteCalenderEvents = () => {
        let events = []
        for(var i=0;i<this.myFavourites.length;i++){
            let item = this.myFavourites[i]
            let agendaDateSplit = item.agenda_details.date.split('-')
            let dateString = agendaDateSplit[2]+"-"+agendaDateSplit[1]+"-"+agendaDateSplit[0]
            let sessionItem = item.session_details
            let timeString = sessionItem.start_time
            let startTime = new Date(dateString + 'T' +timeString)
            timeString = sessionItem.session_endtime
            let endTime = new Date(dateString + 'T' +timeString)
            let subSession = item.parallel_session_details
            let event = {
                title : subSession != null ? subSession.session_title : sessionItem.session_title,
                start : startTime,
                end : endTime
            }
            events.push(event)
        }
        return events
    }
    componentWillUnmount(){
        this._isMounted = false
        document.removeEventListener('invitesLoaded',this.handleOnInvitesFromApiLoaded)
        document.removeEventListener('updateInvites',this.handleOnInviteRecieved)
        document.removeEventListener('invitesLoadingStarted',this.handleOnInvitesLoadingStarted)
    }
    initializeInvitations = (mergedInvites) => {
        var allCalenderEvents = this.state.allCalenderEvents
        for(var i=0;i<mergedInvites.length;i++){
            if(mergedInvites[i].status == 2){
                continue
            }
            let dateString = mergedInvites[i].meeting_date
            let meetingStartTimeSplit = mergedInvites[i].meeting_start_time.split(" ")
            let meetingStarTimeSplitHourMinutes = meetingStartTimeSplit[0].split(":")
            let hour = parseInt(meetingStarTimeSplitHourMinutes[0])
            if(meetingStartTimeSplit[1] == "PM" || meetingStartTimeSplit[1] == "pm"){
                if(meetingStarTimeSplitHourMinutes[0] != 12){
                    hour += 12
                }
            }
            let hours = hour < 10 ? "0"+hour : hour
            let meetingStartTimeString = hours+":"+meetingStarTimeSplitHourMinutes[1]
            let startTime = new Date(dateString + 'T' +meetingStartTimeString)
            let meetingEndTimeSplit = mergedInvites[i].meeting_end_time.split(" ")
            let meetingEndTimeSplitHourMinutes = meetingEndTimeSplit[0].split(":")
            hour = parseInt(meetingEndTimeSplitHourMinutes[0])
            if(meetingEndTimeSplit[1] == "PM" || meetingEndTimeSplit[1] == "pm"){
                if(meetingEndTimeSplitHourMinutes[0] != 12){
                    hour += 12
                }
            }
            hours = hour < 10 ? "0"+hour : hour
            let meetingEndTimeString = hours+":"+meetingEndTimeSplitHourMinutes[1]
            let endTime = new Date(dateString + 'T' +meetingEndTimeString)
            var event = {
                id : i,
                title : mergedInvites[i].meeting_topic,
                start : startTime,
                end: endTime
            }
            allCalenderEvents.push(event)
        }
        return allCalenderEvents
    }
    handleOnInvitesLoadingStarted = (e) => {
       this.setState({loadingInvites : true})
    }
    handleOnInvitesFromApiLoaded = (e) => {
        this.allInvites = e.detail.allInvites
        let allCalenderEvents = this.initializeInvitations(e.detail.allInvites)
        this.setState({loadingInvites : false,allCalenderEvents})
    }
    handleOnInviteRecieved = (e) => {
        let allInvites = e.detail.invites
        this.allInvites = allInvites
        this.checkForNewInvites(allInvites)
    }
    checkForNewInvites =async (allInvites) => { //incase participant is not loaded yet for which the user has received invite
        for(var i=0;i<allInvites.received.length;i++){
            let senderDetails = allInvites.received[i].sender_details
            if(senderDetails == null){
                this.setState({loadingInvites : true})
                await api.getInviatations(this.props.event.alias,this.user.token,((err,invites)=>{
                    if(err == null){
                        this.allInvites = invites
                        this.props.onUpdateInvites(invites)
                        this.setState({loadingInvites : false})
                    }
                }))
                return
            }
        }
        
    }
    OnClickFilterToggle = (val) => {
        this.setState({showReceived : val})
    }
    OnNavigateToPage = (path,data = null) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            this.props.history.push(path)
        }else{
            this.props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    languageClick = (value) =>{
        console.log("Trigger Invitation on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(this.state.callAccepted){
            alert('Call is in process')
            return
        }
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    AcceptRejectInvite = (index,id,status) => {
        if(this.state.updatingInviteStatus){
            return
        }
        this.allInvites.received[index].errorMessage = ""
        var data = {
            invitation_id : id,
            status : status
        }
        this.setState({updatingInviteStatus : true,updatingInviteId : id})
        api.updateInviteStatus(this.props.event.alias,this.user.token,data,(err,res)=>{
            try{
            if(err == null){
                // if(status == 1){
                //     this.allInvites.received[index].status = 1
                // }else{
                //     this.allInvites.received.splice(index,1)
                // }
                this.allInvites.received[index].status = status
                this.props.onUpdateInvites(this.allInvites)
            }else{
                if(err == -1){
                    this.setState({showErrorPopup : true,popUpErrorHeading : 'Login Required',popUpErrorMessage : 'Your session is expired please login again'})
                    $("#premiumSession").modal('show');
                    $('#premiumSession').data('bs.modal').options.backdrop = 'static';
                    localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
                }else{
                    if(err.errorMessage.trim() == "Cannot read properties of undefined (reading 'status')"){
                        this.allInvites.received[index].errorMessage = 'Something went wrong! Please Try Again'
                    }
                    else{
                    this.allInvites.received[index].errorMessage = err.message}
                }
                    
                }
            }
            catch(e){
                console.log(e)
                this.allInvites.received[index].errorMessage = 'Something went wrong! Please Try Again'
            }
            this.setState({updatingInviteStatus : false})
        })
    }
    OnClickEvent = (event) => {
        window.scroll(0,0)
        var selectedCalendarEvent = {
            title : event.title,
            startTime : event.start,
            endTime : event.end
        }
        this.setState({selectedCalendarEvent},()=>{
            let calnedarButton = document.getElementsByClassName('react-add-to-calendar__button')[0]
            calnedarButton.addEventListener('click',()=>{
                setTimeout(() => {
                    let googleLink = document.getElementsByClassName('google-link')[0]
                    googleLink.addEventListener('click',(e)=>{
                        this.setState({selectedCalendarEvent : null})
                    })
                    let outLookLink = document.getElementsByClassName('outlook-link')[0]
                    outLookLink.addEventListener('click',(e)=>{
                        setTimeout(() => {
                            this.setState({selectedCalendarEvent : null})
                        }, 0);
                        
                    })
                    let appleLink = document.getElementsByClassName('apple-link')[0]
                    appleLink.addEventListener('click',(e)=>{
                        setTimeout(() => {
                            this.setState({selectedCalendarEvent : null})
                        },0)
                    })
                }, 0);
            })
        })
    }
    moveTolOgin=()=>{
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
    }
    // handleClosePopup = () => {
    //     this.setState({showErrorPopup : false})
    //     localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
    //     this.OnNavigateToPage(this.props.event.alias+"/login")
    // }
    getCustomToolbar = (toolbar) => {
        let newDate
        this.toolbarDate = toolbar.date;
        const goToDayView = () => {
            this.setState({selectedCalenderViewIndex : 0})
            toolbar.onView('day');
        }
        const goToWeekView = () => {
            this.setState({selectedCalenderViewIndex : 1})
            toolbar.onView('week');
        }
        const goToMonthView = () => {
            this.setState({selectedCalenderViewIndex : 2})
            toolbar.onView('month');
        
            setTimeout(() => {
                // this.setOffRangeDateStyle();
            }, 100)
        
        }
        const goToBack = () => {
            if(this.state.selectedCalenderViewIndex == 0){
                newDate = new Date(this.toolbarDate.getFullYear(),this.toolbarDate.getMonth(),this.toolbarDate.getDate() - 1)
            }else if(this.state.selectedCalenderViewIndex == 1){
                newDate = new Date(this.toolbarDate.getFullYear(),this.toolbarDate.getMonth(),this.toolbarDate.getDate() - 7)
            }else{
                newDate = new Date(this.toolbarDate.getFullYear(),this.toolbarDate.getMonth() - 1,this.toolbarDate.getDate())
            }
            let selectedCalendarDateText = weekDays[newDate.getDay()] + " " + newDate.getDate() + " " + monthNamesShort[newDate.getMonth()]
            this.setState({selectedCalendarDateText})
            toolbar.onNavigate('PREV')
        }
        const goToNext = () => {
            if(this.state.selectedCalenderViewIndex == 0){
                newDate = new Date(this.toolbarDate.getFullYear(),this.toolbarDate.getMonth(),this.toolbarDate.getDate() + 1)
            }else if(this.state.selectedCalenderViewIndex == 1){
                newDate = new Date(this.toolbarDate.getFullYear(),this.toolbarDate.getMonth(),this.toolbarDate.getDate() + 7)
            }else{
                newDate = new Date(this.toolbarDate.getFullYear(),this.toolbarDate.getMonth() + 1,this.toolbarDate.getDate())
            }
            let selectedCalendarDateText = weekDays[newDate.getDay()] + " " + newDate.getDate() + " " + monthNamesShort[newDate.getMonth()]
            this.setState({selectedCalendarDateText})
            toolbar.onNavigate('NEXT')
        }
        let icon = { 'calendar-plus-o': 'left' };
        let items = [
            { google: 'Google' },
            { outlook: 'Outlook' },
            { apple: 'Apple Calendar' }
        ];
        return (
            <div class = "container-fluid calendar-header pt-4">
                <div class="row">
                    <div class="col-sm-4 mb-3 d-none d-sm-block">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            {/* <button type="button" class="btn btn-calendar active">Today</button> */}
                            <button onClick={()=>{
                                goToDayView()
                            }} type="button" class={this.state.selectedCalenderViewIndex == 0 ? "btn btn-calendar active" : "btn btn-calendar"}>Day</button>
                            <button onClick={goToWeekView} type="button" class={this.state.selectedCalenderViewIndex == 1 ? "btn btn-calendar active" : "btn btn-calendar"}>Week</button>
                            <button onClick = {goToMonthView} type="button" class={this.state.selectedCalenderViewIndex == 2 ? "btn btn-calendar active" : "btn btn-calendar"}>Month</button>
                        </div>
                    </div>
                    <div class="col-md-5 text-center">  
                        <ul class="list-inine pl-0">
                            <li class="list-inline-item move-btn">
                                <button type="button" class="btn btn-calendar left-right" onClick = {goToBack}>
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                            </li>
                            <li class="list-inline-item">
                                <h5 class = "today_date">{this.state.selectedCalendarDateText}</h5>
                            </li>
                            <li class="list-inline-item move-btn">
                                <button type="button" class="btn btn-calendar left-right" onClick = {goToNext}>
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="col-md-3 text-center text-md-right">
                        {this.state.selectedCalendarEvent != null ?
                        <AddToCalendar
                            event = {this.state.selectedCalendarEvent}
                            listItems = {items}
                            buttonTemplate = {icon} 
                        />: 
                        <div style = {{fontSize : 14}} class="react-add-to-calendar text-center">
                            Click on any event to add it to calendar
                        </div>}
                    </div> 
                    <div class="col-sm-12 mb-3 mb-sm-0 d-block d-sm-none">
                        <div class="btn-group mob-group" role="group" aria-label="Basic example">
                            {/* <button type="button" class="btn btn-calendar active">Today</button> */}
                            <button onClick={()=>{
                                goToDayView()
                            }} type="button" class={this.state.selectedCalenderViewIndex == 0 ? "btn btn-calendar active" : "btn btn-calendar"}>Day</button>
                            <button onClick={goToWeekView} type="button" class={this.state.selectedCalenderViewIndex == 1? "btn btn-calendar active" : "btn btn-calendar"}>Week</button>
                            <button onClick = {goToMonthView} type="button" class={this.state.selectedCalenderViewIndex == 2 ? "btn btn-calendar active" : "btn btn-calendar"}>Month</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render () {
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <>
             <PermiumUserModal
        id="premiumSession"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
        }}
        onClickBookNow={() => {
        this.moveTolOgin()
          $("#premiumSession").modal("hide");
         $(".modal-backdrop").remove();
          this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Calendar</title>
            </Helmet>
            <div id="header-top">
            <Header
                onMenuItemClick = {this.OnNavigateToPage}  
                elements = {this.props.headerMenuItems}
                facebookLink = {this.props.event.facebook}
                linkedInLink = {this.props.event.linkedIn}
                isMarketplace = {this.props.event.is_marketplace}
                twitterLink = {this.props.event.twitter}
                instagramLink = {this.props.event.instagram}
                googleLink = {this.props.event.google}
                phone = {this.props.event.phone_number}
                eventLogo = {this.props.event.logo}
                event = {this.props.event}
                eventName = {this.props.event.name}
                eventAlias = {this.props.event.alias}
                onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                availableLocales = {this.props.availableLocales}
                languageClick  = {this.languageClick}
                onClickAccountItem = {this.OnClickAccountItem}
            />
            {user != null && user.isBooking && Constants.showVirtualZone ?
            <VirtualZone
                selectedItemIndex = {this.state.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
                event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }}
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                menuItems = {this.props.virtualMenuItems}
                user = {user} 
            /> : null}
            </div>
            {!this.state.loading ?
            <Content
                loadingInvites = {this.state.loadingInvites}
                showReceived = {this.state.showReceived}
                updatingInviteId = {this.state.updatingInviteId}
                updatingInviteStatus = {this.state.updatingInviteStatus}
                onClickFilterToggle = {this.OnClickFilterToggle}
                allInvites = {this.allInvites}
                calendarEvents = {this.state.allCalenderEvents}
                getCustomToolbar = {this.getCustomToolbar}
                onClickUpdateInviteStatus = {this.AcceptRejectInvite}
                onClickEvent = {this.OnClickEvent}
            /> :
            <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                <Loader
                    type = 'Oval'
                    color = "#00b6ff"
                    height = {40}
                    width = {40}
                />
            </div> }
            {/* <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showErrorPopup} 
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    myFavourites : state.myAccount.favourites,
    allInvites : state.invitations.allInvitations
})
const mapDispatchToProps = dispatch => ({
    updateMyfavourites : data => dispatch(UpdateMyFavourites(data)),
    onUpdateInvites : data => dispatch(UpdateInvitationsList(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Invitations);