import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../Agenda/api';
import {ContactInfoLoaded} from './actions'
import {connect} from 'react-redux'
import ContentLoader from 'react-content-loader'
export class ContactInfo extends React.Component{
    _isMounted = false
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias : this.props.event_alias,
            type : this.props.type,
            feature : {},
            showContent : false,
            isLoading : false,
            EventInfo:[],
            title:'Info'
        };
    }

    componentDidMount(){
        this._isMounted = true
        if(this.props.featureLoading != this.state.isLoading){
            this.setState({isLoading:this.props.featureLoading})
        }
        if(this.props.EventInfo.length>0){
            if(this.props.EventInfo[0].hasOwnProperty('additional')){
                this.setState({EventInfo:this.props.EventInfo[0].additional.items,title:this.props.EventInfo[0].additional.title})
                // this.SetImages(nextProps.EventGallery[0].gallery)

            }
        }
        // if(this.props.contactInfo){
        //     let showContent = false
        //     if(this.props.contactInfo.features.length > 0){
        //         showContent = true
        //     }
        //     this.setState({feature : this.props.contactInfo,showContent,isLoading : false})
        //     return
        // }else{
        //     api.getEventFeatures(this.state.event.alias,this.state.type,null,(err,data)=>{
        //         if(err == null && this._isMounted){
        //             if(data.feature != null){
        //                 this.setState({feature:data.feature},()=>{
        //                     if(data.feature.features.length > 0){
        //                         this.setState({showContent : true})
        //                     }
        //                 });
        //                 this.props.contactInfoLoaded(data.feature)
        //             }
                    
        //         }
        //         this.setState({isLoading : false})
        //     })
        // }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.featureLoading != this.state.isLoading){
            this.setState({isLoading:nextProps.featureLoading})
        }
        if(nextProps.EventInfo.length>0){
            if(nextProps.EventInfo[0].hasOwnProperty('additional')){
                this.setState({EventInfo:nextProps.EventInfo[0].additional.items,title:nextProps.EventInfo[0].additional.title})
                // this.SetImages(nextProps.EventGallery[0].gallery)

            }
        }
    }
    componentWillUnmount(){
        this._isMounted = false
    }
    MakeCall=(phone)=>{
        if(phone == null){
              return
          }
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = "tel:"+phone;
        // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL("tel:"+phone);
    }
    render(){
        if(this.state.isLoading){
            return (
                <ContentLoader
                    viewBox="0 0 400 100"
                    {...this.props}
                >
                    <rect x="100" y="9.93" width="70" height="80" />
                    <rect x="180" y="9.67" rx="0" ry="0" width="128.72" height="8" />
                    <rect x="180" y="20.67" rx="0" ry="0" width="69" height="6" />

                </ContentLoader>
            )
        }
        const feature = this.state.EventInfo;
    
        if(this.state.isLoading == false){

            return(
				<section className = "sev_cont py-4 py-lg-3">
					<div className="container">

                        {
                            feature ?
                                feature.map( (feature,index)=>{
                                    // if(feature.isActive == 1 && feature.type == Constants.Additional){
                                        console.log(feature)

                                        return (

											<div className="row py-3" key={index}>
												<div className="col-md-5 text-right d-none d-md-block">
                                                    {
                                                        feature.image ?
															<img src = {feature.image} alt = "blog-1_img"/>
                                                            : null
                                                    }
												</div>

												<div className="col-md-7">
													<h5 className = "font-weight-bold text-capitalize mb-3">{feature.title}</h5>
													<p className = "f-500">{ ReactHtmlParser(feature.description) }</p>
													<div className = "row mx-0">
                                                        {feature.email != null ?
                                                        <p className = "f-500 pr-3 pr-md-2">
                                                            <i className="fas fa-envelope mr-2"></i> {feature.email}
                                                        </p> : null}
                                                        {feature.phone_number != null ?
                                                        <p className = "f-500 pl-0 pl-md-2">
                                                            <i style = {{cursor : 'pointer'}} onClick = {()=>this.MakeCall(feature.phone_number)} className="fas fa-phone mr-2"></i> <span style = {{cursor : 'pointer'}} onClick = {()=>this.MakeCall(feature.phone_number)}> {feature.phone_number}</span>
                                                        </p> : null}
                                                    </div>
                                                    
												</div>
											</div>

                                        );
                                    // }

                                }) : null
                        }

					</div>
				</section>
            );

		}else{
        	return null
		}

    }

}

const mapStateToProps = state => ({
    contactInfo: state.contactInfo.contactInfoData,
    EventInfo:state.appData.allFeature,
    featureLoading:state.appData.featureLoading
})
const mapDispatchToProps = dispatch => ({
    contactInfoLoaded : data => dispatch(ContactInfoLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);