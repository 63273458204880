import { transform } from '@babel/core'
import React,{useState,useEffect} from 'react'
import api from '../api'
import {Trans} from 'react-i18next'
export default function RoundTable(props){
    return(
        <section class = "networking-meeting bg-light py-3">
            <div class="container text-center">
            
                <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item"><a style = {{cursor : 'pointer'}} onClick = {props.onClickNetworking}><Trans>networking</Trans></a></li>
                    <li class="breadcrumb-item active" aria-current="page">{props.meetingName}</li>
                </ol>
                </nav>
                
                <h1>
                    {props.meetingName}
                </h1>
                <p>
                    {props.meetingDescription != null ? props.meetingDescription : "Join the following meetings"}
                </p>

                <div class="row justify-content-center mt-3 mt-md-5 px-1 px-md-0">
                    {props.meetings.map((item,index)=>{
                        return(
                            <div class = "col-12 rt-meeting py-3 px-2 px-lg-4">
                                <div class = "row mx-0">
                                    <div class = "details-meeting col-md-8 text-left">
                                        <p>
                                            <i class="far fa-calendar-alt mr-2"></i> 
                                            {item.dateString}
                                            {item.totalTime != null ?
                                            <span class="ml-3">
							                    <i class="far fa-clock mr-2"></i> 
                                                {item.totalTimeArray.map((time,index)=>{
                                                    let splitterSlash = index == item.totalTimeArray.length - 1 ? "":" / "
                                                    return(
                                                        <span>{time.time + " " + time.country +splitterSlash}</span>
                                                    )
                                                })}
							                </span> : null}
                                        </p>
                                        
                                        <h2>
                                            {item.title}
                                        </h2>
                                        
                                        <ul class = "host-spk-list">
                                            {item.speakers.length > 0 ? 
                                            <li class = "spk-tit"><Trans>speaker</Trans>:</li> : null}
                                            {item.speakers.map((speaker,speakerIndex)=>{
                                                return(
                                                <li class = "spk-name">
                                                    {speaker.name + " " + (speaker.surname == null ? "" :speaker.surname)}
                                                    <div class="host-detail border bg-white">
                                                        <div class = "row mx-0">
                                                        <div class="col-3 pr-0">
                                                            {speaker.image != null ? 
                                                            <img src={speaker.image} class="rounded-circle adj_user" alt="user_1-img" /> : 
                                                            <img src="/images/profile-placeholder.png" class="rounded-circle adj_user" alt="user_1-img"/>}
                                                        </div>
                                                        <div class="col-9 pl-md-0 text-left">
                                                            <h6>{speaker.name + " " + (speaker.surname == null ? "" :speaker.surname)}</h6>
                                                            <p>{speaker.job_title}</p>
                                                            <p class = "dep">{speaker.company}</p>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <div class = "col-md-4 text-right">
                                        <div class="btn-meeting">
                                            {item.isEnd == 0 ?
                                            <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickBook(index)} class = "btn bg_main px-3 py-2">
                                                <Trans>bookSeat</Trans> <i class="fas fa-arrow-right ml-2"></i>
                                            </a> : 
                                            <a style = {{cursor : 'not-allowed'}} class = "btn btn-ended px-3 py-2">
                                                <Trans>ended</Trans>
                                            </a> }
                                        </div>
                                    </div>
                                </div>	
                                
                            </div>
                        )
                    })}
                    {props.meetings.length == 0 ? 
                    <div> <Trans>notMeetings</Trans></div> : null}
                </div>
                
            </div>

        </section>
    )
}