import React from 'react';
import Header from '../../Header/Index';
import Content from './content';
import { withRouter } from 'react-router-dom'
import Footer1 from '../../Footer/Footer1';
import Footer2 from '../../Footer/Footer2';
import queryString from 'query-string'
import api from '../../Booking/api';
import Loader from 'react-loader-spinner'
import { Constants } from '../../../Constants';
import utility from '../../../Utility/utility';
import VirtualZone from '../../Header/virtualZone';
class PaymentSuccessOther extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            event : this.props.event,
            event_alias: this.props.event_alias,
            userEmail : "",
            show : false,
            title : "Payment Failed",
            message : " Sorry, we were unable to process your request.<br/>Please try again later."
        }
    }
    componentDidMount(){
        var data = this.props.location.state
        // var values = queryString.parse(window.location.search)
        let shownCheck = localStorage.getItem("isBookingPageShown")
        if(data == null || shownCheck == 1){
            localStorage.removeItem("isBookingPageShown")
            this.OnNavigateToPage("/")
            return
        }
        if(data.isFreeBooking){
            this.setState({show : true,title : "Booking Failed"})
            localStorage.setItem("isBookingPageShown",1)
            return
        }
        var dataToSend = {
            token : data.token
        }
        api.getTranscationStatus(this.props.event.alias,dataToSend,(err,data)=>{
            if(err != null){
                if(err.toString().indexOf('timeout') > -1 ){
                    this.setState({show : true})
                    return
                }
                this.OnNavigateToPage("/")
            }else{
                this.setState({show : true})
                this.updateTranscationStatus(dataToSend.token)
            }
        })
    }
    updateTranscationStatus = (token) => {
        var data = {
            token : token
        }
        api.sendTranscationStatus(this.props.event.alias,data,(err,data)=>{
            // nothing to do here
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    languageClick = (value) =>{
        console.log("Trigger payment failure others on lan select")
        this.props.languageClick(value);
    }

    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render (){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                <div id="header-top">
                <Header
                    onMenuItemClick = {this.OnNavigateToPage}  
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    isMarketplace = {this.props.event.is_marketplace}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    event = {this.props.event}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    onClickAccountItem = {this.OnClickAccountItem}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    notifications = {this.props.notifications}
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div>
                <div className = {`content-main`}>
                    {this.state.show ? 
                        <Content
                            title = {this.state.title}
                            userEmail = {this.props.userEmail}
                            eventEmail = {this.props.eventEmail}
                            eventPhone = {this.props.eventPhone}
                        /> : 
                        <div style = {{width : '100%',height : 100,display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                            <Loader
                                type = 'Oval'
                                color = "#00b6ff"
                                height = {40}
                                width = {40}
                            />
                        </div>
                    }
                    <Footer1 
                        event = {this.props.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                </div>
                <Footer2 
                event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        )
    }
}
export default withRouter(PaymentSuccessOther)
