import React,{useState} from 'react'
import './styles.css'
import {Link} from 'react-router-dom';
import Loader from 'react-loader-spinner'
import {Trans, withTranslation} from 'react-i18next';
import utility from '../../../Utility/utility';
import { GARecordEvent } from '../../../Utility/GA'
import api from './api';
const PremiumContent  = (props)=>{
	const [email,setEmail] = useState('')
	const [responseMsg,setResponseMsg] = useState(null);
	const [apiError,setApiError] = useState(false);
	const [sendingRequest,setsendingRequest] = useState(false);
	const [emailError,setEmailError] = useState(null)
	
    console.log(props)
	const handleSubmit = (event) => {
        event.preventDefault();
        subscribeEvent()
        const isValid = validate();
		setResponseMsg(null)
		setApiError(false)

        if(isValid){
            if(email){
                setsendingRequest(true)
                api.sendEventSubscription(props.alias.alias,email,'eventUpdate',(err,data)=>{
						setsendingRequest(false)
                        if(err == null){
							setResponseMsg(data.message)
                            setTimeout(() => {
                                setResponseMsg(null)
                            }, 2000);
                        }else{
							setResponseMsg('Login Error');
							setApiError(true)
                            setTimeout(() => {
								setResponseMsg(null);
								setApiError(false)
                            }, 2000);
                        }
                    
                })

            }else{
                setResponseMsg(null);
            }//end of if
        }//end is valid if
      setEmail('')
    }
	const subscribeEvent = () =>{
        //console.log("subcribe clicked")
		var user = utility.getLoggedInUser(props.alias.alias)
        GARecordEvent("subscribe Clicked", user? user.id : 'anonymous user',{
            category : 'Subscribe',
            action : 'Subscribe button clicked',
            userName: user? (user.firstName+''+user.lastName) : 'anonymous user',
            userEmail: user? user.email : 'anonymous user'
        })
    }
	const validate = () =>{

        let emailError = null;

        if(email){

            var emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            emailError = emailValid ? null : <Trans>validEmail</Trans>;
        }else{
            emailError = <Trans>pleaseEnterMail</Trans>;
        }

        if(emailError){
            setEmailError(emailError)
            return false;
        }else{
            setEmailError(emailError)
            return true;
        }


    }
    return(
    //     <section class = "middle-banner text-center">
	// 	<div class="container">
	// 		<div class="row">
	// 			<div class="col-sm-12">
	// 				<p>
	// 					<img src="/images/networking/ic-diamond.svg" alt="icon-diamond"/>
	// 				</p>
	// 				<p>
	// 					Interested to access Premium Content?
	// 				</p>
	// 				<Link to={`${props.alias}/membership`} class = "btn btn-tele">
	// 					Become a Member
	// 				</Link>
	// 			</div>
	// 		</div>
	// 	</div>
	// </section>
	
	<section class = "middle-banner text-center Primary_b">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class = "mx-auto mb-3 icon-circle">
						<img src="/images/networking/newsletter.svg" 
						alt="icon-newsletter"/>
					</div>
					
					<h2 class = "mt-4">Stay in the know</h2>
					<p>
						{/* <!--Interested to access Premium Content?--> */}
						Sign up for TeleHealth Connect's news and updates!
					</p>
					
				</div>
			</div>
			<div class="row pt-3">
				<div class="col-sm-12">
					<form class="form-inline justify-content-center mt-3" onSubmit = {handleSubmit}>
						<div class="form-group col-sm-8 pr-md-0">
							<input type="email" class="form-control input-1 w-100" style={{border: '2px solid #f5b532'}} value={email} placeholder="Enter Your Email" onChange = {(e)=>{
								setEmail(e.target.value)
							}}/>
						</div>
						<div class="form-group col-sm-4 pl-md-0">
							<input type="submit" class="form-control bg_main input-2 w-100"  value="Sign Up"/>
						</div>
						{sendingRequest ?
                                    <div style = {{position : 'absolute',top : 80,left : '50%'}}>
                                        <Loader
                                            type = 'Oval'
                                            color = "#00b6ff"
                                            height = {25}
                                            width = {25}
                                        />
                                    </div> : null}
						{
                                    emailError ?
                                        <div style = {{marginTop : '2%'}} className="col-sm-10 alert alert-danger">{emailError}</div>
                                        :null
                                }
						{
                                        responseMsg ?
                                            <div style = {{marginTop : '2%'}} className={apiError ? "col-sm-10 alert alert-danger" : "col-sm-10 alert alert-success"}>
                                                {responseMsg}
                                            </div>
                                            :null
                                    }
					</form>
				</div>
			</div>
		</div>
	</section>
        )
}

export default PremiumContent