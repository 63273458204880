import React from 'react';
import {Trans} from 'react-i18next'
export const IncomingCallPopup = (props)=>{
    return(
        <div class="modal" id="incomingCall" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content text-center incoming-call">
                    <div class="modal-body px-3 py-4 incoming-call-body">
                            <p class = "call-title">
                                <Trans>incomingCall</Trans> <span>.</span> <span>.</span> <span>.</span>
                            </p>

                            <div class="caller-img my-3 my-md-4">
                                {props.callerImageUrl == null || props.callerImageUrl == "" ? 
                                    <img src="/images/profile-placeholder.png" alt="participant_image" /> :
                                    <img src={props.callerImageUrl} alt="participant_image" />}
                            </div>

                            <p class = "caller-name mb-4 mb-md-5">
                                {props.callerName}
                            </p>

                            <div class="call-action">
                                <ul class="list-inline mt-3">
                                    <li class="list-inline-item mx-3 mx-md-4">
                                        <div onClick = {props.onAcceptCall} class = "call-btn accept-btn">
                                            <i class="fas fa-phone"></i>
                                        </div>
                                        <br />
                                        <span><Trans>accept</Trans></span>
                                    </li>

                                    <li class="list-inline-item mx-3 mx-md-4">
                                        <div onClick = {props.onRejectCall} class = "call-btn reject-btn">
                                            <i class="fas fa-phone"></i>
                                        </div>
                                        <br/>
                                        <span><Trans>reject</Trans></span>
                                    </li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}