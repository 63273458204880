import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import  PermiumUserModal  from '../modals/premiumUserModal';
import RegisterModel from '../RegisterModel';
import {Trans} from 'react-i18next'
export class Content extends React.Component{

    render(){
        return (
            <>
            <PermiumUserModal
                id = "premiumSession" 
                iconClass = "fa-star"
                title = "Premium Video"
                description = "<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
                buttonText = "Book Now"
                showButton = {true}
                onClose = {()=>{
                    $("#premiumSession").modal("hide");
                    $(".modal-backdrop").remove();
                  }}
                onClickBookNow = {()=>{
                    $("#premiumSession").modal('hide')
                    $('.modal-backdrop').remove();
                    this.props.onNavigateToPage('/booking')}
            }/>
            <section class="blog videos py-3">
                <div class="container">
                    <h5 class = "text-center font-weight-bold">{this.props.videosData.title}</h5>
                    <p class="text-center mb-3 mb-md-4">
                        {this.props.videosData.description}
                    </p>
                    <div class="row py-3">
                        {this.props.videosData.videos.map((item,index)=>{
                            return(
                            <div class="col-md-4 mb-3">
                                <div class="thumbnail h-100">
                                    {item.thumbnail == null ?
                                    item.is_premium == 0 || this.props.isPremiumUser?
                                    <iframe style = {{height : '100%',width : '100%'}} class="embed-responsive-item" src={item.link} allowfullscreen></iframe>:
                                    <>
                                        <img src = {item.thumbnail != null ? item.thumbnail : '/images/no_video_thmbnail.svg'} alt = "blog-1_img"/>
                                        <div class="mt-2">
                                            <a style = {{cursor : 'pointer'}} onClick = {()=>this.props.onClickWatchNow(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0">
                                            {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> : 
                                            this.props.isPremiumUser ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> 
                                            :<> <i class="fas fa-lock mr-2"></i> <Trans>premiumVideo</Trans></>}
                                            </a>
                                        </div>
                                    </>:
                                    <>
                                        <img src = {item.thumbnail} alt = "blog-1_img"/>
                                        <div class="mt-2">
                                            <a style = {{cursor : 'pointer'}} onClick = {()=>this.props.onClickWatchNow(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0">
                                            {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i><Trans>watchNow</Trans> </> : 
                                            this.props.isPremiumUser ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> 
                                            :<> <i class="fas fa-lock mr-2"></i> <Trans>premiumVideo</Trans></>}
                                            </a>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            )
                        })}
                    </div>

                </div>
            </section>
            <div class="modal" id="video-modal" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content bio-modal rounded-0 video-modal">
                    
                        <div class = "modal-header px-3">
                            <h3>{this.props.selectedVideoTitle}</h3>
                            <button data-dismiss="modal" onClick = {()=> {
                                    this.props.onCloseVideo()
                                    // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
                                    document.body.style.overflowY = "scroll"
                                }}>
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>
                        <div class="modal-body p-2">
                    
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src={this.props.selectedVideoUrl} allowfullscreen></iframe>
                        </div>
                        
                        </div>

                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Content;