import React from 'react';
import Header from '../../Header/Index';
import Content from './content';
import { withRouter } from 'react-router-dom'
import { Constants } from '../../../Constants';
class PaymentSuccessInvoice extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            event : this.props.event,
            event_alias: this.props.event_alias,
            userEmail : ""
        }
    }
    componentDidMount(){
        // var data = this.props.location.state
        // if(data != null){
        //     this.setState({userEmail : data.invoiceEmail})
        // }
        // else{
        //     this.OnNavigateToPage(this.props.event.alias+"/booking")
        // }
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    OnClickAccountItem = (index) =>{
        this.props.onClickAccountItem(index)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    render (){
        return(
            <div>
                {/* <Header
                    onMenuItemClick = {this.OnNavigateToPage}  
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    onClickAccountItem = {this.OnClickAccountItem}
                /> */}
                <Content 
                    userEmail = {this.props.userEmail}
                    eventEmail = {this.props.eventEmail}
                    eventPhone = {this.props.eventPhone}
                />
            </div>
        )
    }
}
export default withRouter(PaymentSuccessInvoice)
