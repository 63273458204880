import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import { Trans } from 'react-i18next'
export class Content extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            venue: this.props.venue,

        };
        this.buttonClickDisabled = false
    }

    render() {
        setInterval(() => {
            $('#demo').carousel('next')
            this.buttonClickDisabled = true
            setTimeout(() => {
                this.buttonClickDisabled = false
            }, 500);
        }, 5000);
        const venue = this.props.venue;
        let webURL = ""
        const event = this.props.event;

        if (event) {

            if (event.start_date) {
                var start_date = new Date(event.start_date).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });
            }

            if (event.end_date) {
                var end_date = new Date(event.end_date).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });
            }
            var startDateMonth = start_date.split(' ')
            var endDateMonth = end_date.split(' ')
            var finalDateString = ""
            if (startDateMonth[1] == endDateMonth[1]) {
                finalDateString = startDateMonth[0] + " - " + endDateMonth[0] + " " + startDateMonth[1] + " " + startDateMonth[2]
            }
            else {
                finalDateString = start_date + ' - ' + end_date
            }
        }
        if (venue && venue.length > 0) {
            if (venue[0].website != null)
                webURL = venue[0].website.indexOf('http://') == 0 || venue[0].website.indexOf('https://') == 0 ? venue[0].website : '//' + venue[0].website
            return (
                <div>

                    <section class="attending_bnr bg-light position-relative overflow-hidden">

                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 py-3">

                                    <div class="inner-div">
                                        <h4 class="font-weight-bold"><Trans>date</Trans>s</h4>
                                        <h6 class="font-weight-normal">{finalDateString}</h6>

                                        <h4 class="font-weight-bold">{venue[0].name}</h4>
                                        <h6 class="font-weight-normal">
                                            {ReactHtmlParser(venue[0].address)}
                                        </h6>
                                        {webURL != "" ?
                                            <>
                                                <h4 class="font-weight-bold"><Trans>website</Trans></h4>
                                                <h6 class="font-weight-normal text-red">
                                                    <a href={webURL} target="_blank">{venue[0].website}</a>
                                                </h6> </> : null}
                                        {venue[0].price_link != null ?
                                            <>
                                                <h4 class="font-weight-bold"><Trans>discountPrice</Trans></h4>
                                                <h6 class="font-weight-normal text-red">
                                                    <a href={venue[0].price_link} target="_blank">{venue[0].price_link}</a>
                                                </h6> </> : null}
                                    </div>

                                </div>
                                {
                                    venue[0].image != null ?

                                        <div style={{ display: 'flex', alignItems: 'center' }} class="col-md-6 p-0 traning-slider" >
                                            <div id="demo" class="carousel slide" data-ride="carousel">


                                                <div class="carousel-inner">
                                                    {
                                                        venue[0].image != null ?

                                                            venue[0].image.map(function (img, index) {
                                                                var divClass = index == 0 ? "carousel-item active" : "carousel-item";

                                                                if (img.caption != null) {
                                                                    // img.caption = img.caption.substr(0,30)
                                                                    // if(img.caption.length >= 30){
                                                                    //     img.caption += '...'
                                                                    // }
                                                                }
                                                                return (
                                                                    <div class={divClass} key={index}>
                                                                        <img src={img.path} alt="Los Angeles" />
                                                                        {
                                                                            img.caption ?

                                                                                <div class="carousel-caption my_caption venueImageText p-2 text-center f-600">
                                                                                    <p class="mb-0">{img.caption}</p>
                                                                                </div>

                                                                                : null

                                                                        }
                                                                    </div>
                                                                )
                                                            })


                                                            : null

                                                    }


                                                </div>


                                                {
                                                    venue[0].image.length > 1 ?

                                                        // <div class = "carsl-control">
                                                        //     <a class="carousel-control-prev" href="#demo" data-slide="prev">
                                                        //         <span class="carousel-control-prev-icon"></span>
                                                        //     </a>
                                                        //     <a class="carousel-control-next" href="#demo" data-slide="next">
                                                        //         <span class="carousel-control-next-icon"></span>
                                                        //     </a>
                                                        // </div>
                                                        <div class="carsl-control">
                                                            <a style={{ cursor: 'pointer' }} class="carousel-control-prev" onClick={() => {
                                                                if (!this.buttonClickDisabled) {
                                                                    $('#demo').carousel('prev')
                                                                }
                                                            }
                                                            } >
                                                                <span class="carousel-control-prev-icon"></span>
                                                            </a>
                                                            <a style={{ cursor: 'pointer' }} class="carousel-control-next" onClick={() => {
                                                                if (!this.buttonClickDisabled) {
                                                                    $('#demo').carousel('next')
                                                                }
                                                            }
                                                            }>
                                                                <span class="carousel-control-next-icon"></span>
                                                            </a>
                                                        </div>

                                                        : null
                                                }

                                            </div>

                                        </div>

                                        : null
                                }

                            </div>
                        </div>

                    </section>

                    <section class="about-hotel py-3">
                        <div class="container">
                            <div class="row py-3">
                                <div class="col-12 text-main">
                                    <p>{ReactHtmlParser(venue[0].additional_text)}</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {venue[0].extra_text.map((item, index) => {
                        const lightBG = index % 2 == 0 ? 'bg-light' : ''
                        var classes = `about-hotel py-3 ${lightBG}`
                        return (
                            <section class={classes}>
                                <div class="container">
                                    <div class="row py-3">
                                        <div class="col-12 text-main">
                                            <p>{ReactHtmlParser(item)}</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })}

                </div>
            )

        } else {
            return (null)
        }

    }//end of render

}

export default Content;