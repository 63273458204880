
import React,{useState,useEffect,useRef} from 'react'
import io from 'socket.io-client'
import $ from 'jquery'
import utility from '../../Utility/utility'
import agendaApi from '../AgendaTime/api'
import api from './api'
import Linkify from 'react-linkify';
import Loader from 'react-loader-spinner'
import { Constants } from '../../Constants'
import { Trans } from 'react-i18next'
export class MainView extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selectedTabIndex : 0,
            chat : props.loadedAnnouncements,
            questions : props.loadedQuestions,
            chatMessage : "",
            userId : 1001,
            seletectedQuestionsTab : 0,
            pendingQuestionsToUpdate : [],
            pendingChatMessageToUpdate : []
        }
        this.socket = null
        this.deleteInterval = null
        this.navbar = null
        this.pendingQuestionsUpdateInterval = null
    }
    componentDidMount(){
        // this.socket = io( Constants.socketUrl)
        this.socket = this.props.socket
        if(this.socket == null){
            let socketURL =  Constants.socketUrl
            socketURL += "?userId=111&userName=moderator&eventAlias="+this.props.eventAlias
            this.socket = io.connect(socketURL,{forceNew : true,upgrade : false,transports: ['websocket']})
        }
        this.navbar = document.getElementById("nav_bb")
        window.addEventListener('scroll',this.handleScroll,false)
        $(".form-inline").submit(function(e) {
            e.preventDefault();
        });
        for(var i=0;i<this.state.chat.length;i++){
            // var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            // var text1=this.state.chat[i].message.replace(exp, "<a target='_blank' href='$1'>$1</a>");
            // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
            // document.getElementById("chatMessage"+i).innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>'); 
        }

        this.socket.on("chatChannel",(item)=>{
            if(item.eventAlias == this.props.eventAlias && item.agendaId == this.props.selectedSession.agendaId && item.sessionId == this.props.selectedSession.sessionId && item.subSessionId == this.props.selectedSession.subSessionId){
                var chat = this.state.chat
                let index = chat.findIndex(x=>x.id == item.id)
                if(index > -1){
                    if(item.deleted){
                        this.OnClickDeleteChat(index,false)
                    }else{
                        chat[index] = item
                        this.setState({chat : chat})
                    }
                    return
                }   
                let date = new Date(item.time)
                let hours = date.getHours()
                let minutes = date.getMinutes()
                if(minutes < 10){
                    minutes = "0"+minutes
                }
                let ampm = hours >= 12 ? "PM" : "AM"
                if(hours > 12){
                    hours = hours - 12
                }
                item.timeString = hours + ":" + minutes + " "+ampm
                chat.push(item)
                this.setState({chat},()=>{
                    if(this.state.selectedTabIndex == 1){
                        // var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                        // var text1=item.message.replace(exp, "<a target='_blank' href='$1'>$1</a>");
                        // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
                        // document.getElementById("chatMessage"+(chat.length-1)).innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
                        let chatBody = document.getElementById("chat-body")
                        chatBody.scrollTop = chatBody.scrollHeight;
                    }
                })
            }
        })
        this.socket.on("QAChannel",(data)=>{
            let addQuestion = false
            if(this.props.eventAlias == data.eventAlias && this.props.selectedSession.agendaId == data.agendaId && this.props.selectedSession.sessionId == data.sessionId && this.props.selectedSession.subSessionId == data.subSessionId){
                addQuestion = true
            }
            if(!addQuestion){
                return
            }
            var questions = this.state.questions
            let questionIndex = questions.findIndex(x=>x.id == data.id)
            if(data.responseId != null && data.rejected){
                var pendingQuestionsToUpdate = this.state.pendingQuestionsToUpdate
                let pendingQuestion = pendingQuestionsToUpdate.find(x=>x.id == data.id)
                if(pendingQuestion != null){
                    this.UpdateQuestionBackEnd(pendingQuestion)
                    pendingQuestionsToUpdate.splice(pendingQuestionsToUpdate.findIndex(x=>x.id == data.id),1)
                    this.setState({pendingQuestionsToUpdate})
                    return
                }
            }
            if(questionIndex > -1){
                data.updating = questions[questionIndex].updating
                data.rejected = questions[questionIndex].rejected
                data.approved = questions[questionIndex].approved
                questions[questionIndex] = data
                questions.sort((a,b)=>{
                    return new Date(b.time) - new Date(a.time)
                })
                questions.sort((a,b)=>{
                    return b.upVoteCount.count - a.upVoteCount.count
                })
                this.setState({questions})
                return
            }
            let date = new Date(data.time)
            let hours = date.getHours()
            let minutes = date.getMinutes()
            if(minutes < 10){
                minutes = "0"+minutes
            }
            let ampm = hours >= 12 ? "PM" : "AM"
            if(hours > 12){
                hours = hours - 12
            }
            data.timeString = hours+ ":" + minutes + " "+ ampm
            questions.unshift(data)
            this.setState({questions})
        })
        this.pendingQuestionsUpdateInterval = setInterval(this.updatePendingQuestions,2000)
    }
    componentWillUnmount(){
        clearInterval(this.pendingQuestionsUpdateInterval)
    }
    UpdateQuestionBackEnd = (question,deleting = false) => {
        var qDataToUpdate = {
            event_id : this.props.eventAlias,
            event_agenda_id : this.props.selectedSession.agendaId,
            event_session_id : this.props.selectedSession.sessionId,
            question_id : question.responseId,
            is_approved : question.approved ? 1 : deleting ? 2 : 0,
            votes_count : question.upVoteCount.count,
            user_id : question.upVoteCount.by.length > 0 ? question.upVoteCount.by[question.upVoteCount.by.length - 1] : null
        }
        agendaApi.saveUpdateQuestion(this.props.eventAlias,false,qDataToUpdate,(err,data)=>{
            if(data != null){
                
            }
        })
    }
    handleScroll = () => {
        if(this.navbar == null){
            return
        }
        if (window.pageYOffset >= 40) {
            this.navbar.classList.add("affix")
        } else if(window.pageYOffset == 0) {
            this.navbar.classList.remove("affix");
        }
        
    }
    OnClickTab = (index) => {
        if(index == 1){
            setTimeout(() => {
                let chatBody = document.getElementById("chat-body")
                chatBody.scrollTop = chatBody.scrollHeight;
            }, 0);
        }
        window.scrollTo(0,0);
        this.setState({selectedTabIndex : index})
    }
    handleChange = (event) => {
        this.setState({chatMessage : event.target.value})
    }
    // useEffect(()=>{
    //     if(!isFirstRender.current){
            
    //     }
    // },[chat])
    OnFormSubmit = (event) => {
        var chatMessage = this.state.chatMessage
        if(chatMessage == ""){
            return
        }
        var chat = this.state.chat
        let data = {
            id : chat.length + 1,
            time : new Date(),
            message : chatMessage,
            sender : 'Moderator',
            userId : this.state.userId,
            agendaIndex : this.props.selectedSession.agendaIndex,
            sessionIndex : this.props.selectedSession.sessionIndex,
            agendaId : this.props.selectedSession.agendaId,
            sessionId : this.props.selectedSession.sessionId,
            subSessionId : this.props.selectedSession.subSessionId,
            eventAlias : this.props.eventAlias
        }
        let date = new Date(data.time)
        let hours = date.getHours()
        let minutes = date.getMinutes()
        if(minutes < 10){
            minutes = "0"+minutes
        }
        let ampm = hours >= 12 ? "PM" : "AM"
        if(hours > 12){
            hours = hours - 12
        }
        data.timeString = hours + ":" + minutes + " "+ ampm
        chat.push(data)
        this.setState({chat},()=>{
            // var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            // var text1=chatMessage.replace(exp, "<a target='_blank' href='$1'>$1</a>");
            // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
            // document.getElementById("chatMessage"+(chat.length-1)).innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
            let chatBody = document.getElementById("chat-body")
            chatBody.scrollTop = chatBody.scrollHeight;
        })
        this.socket.emit("chatChannel",data)
        this.setState({chatMessage : ""})
        var chatDataToSave = {
            event_id : this.props.eventAlias,
            event_agenda_id : this.props.selectedSession.agendaId,
            event_session_id : this.props.selectedSession.sessionId,
            event_sub_session_id : this.props.selectedSession.subSessionId ,
            message : data.message,
            name : data.sender,
            temp_id : data.id
        }
        api.saveAnnouncement(this.props.eventAlias,chatDataToSave,(err,data)=>{
            if(data != null){
                var chat = this.state.chat
                let chatIndex = chat.findIndex(x=>x.id == data.temp_id)
                chat[chatIndex].responseId = data.announcement.id
                this.setState({chat})
                this.socket.emit("chatChannel",chat[chatIndex])
            }
        })
    }
    OnClickApproveQuestion = (index) => {
        var questions = this.state.questions
        if(questions[index].updating){
            return
        }
        questions[index].approved = true
        if(questions[index].responseId != null){
            this.socket.emit("QAChannel",questions[index])
            this.UpdateQuestionBackEnd(questions[index])
        }else{
            questions[index].updating = true
            var pendingQuestionsToUpdate = this.state.pendingQuestionsToUpdate
            pendingQuestionsToUpdate.push(questions[index])
            this.setState({pendingQuestionsToUpdate})
        }
        this.setState({questions})
    }
    OnClickRejectQuestion = (index) => {
        var questions = this.state.questions
        if(questions[index].updating){
            return
        }
        questions[index].approved = false
        questions[index].rejected = true
        if(questions[index].responseId != null){
            this.socket.emit("QAChannel",questions[index])
           this.UpdateQuestionBackEnd(questions[index],true)
            questions.splice(index,1)
        }else{
            questions[index].updating = true
            var pendingQuestionsToUpdate = this.state.pendingQuestionsToUpdate
            pendingQuestionsToUpdate.push(questions[index])
            this.setState({pendingQuestionsToUpdate})
        }
        this.setState({questions})
    }
    OnClickDeleteChat = (index,emit = true) =>{
        var chat = this.state.chat
        chat[index].deleted = true
        if(chat[index].responseId != null){
            var chatItemToDelete = {
                event_id : this.props.eventAlias,
                event_agenda_id : this.props.selectedSession.agendaId,
                event_session_id : this.props.selectedSession.sessionId,
                announcement_id : chat[index].responseId
            }
            api.deleteAnnouncement(this.props.eventAlias,chatItemToDelete,(err,resData)=>{
                
            })
            if(emit){
                this.socket.emit("chatChannel",chat[index])
            }
        }else{
            var pendingChatMessageToUpdate = this.state.pendingChatMessageToUpdate
            pendingChatMessageToUpdate.push(chat[index])
            this.setState({pendingChatMessageToUpdate})
            if(this.deleteInterval == null){
                this.deleteInterval = setInterval(this.deletePendingAnnouncements, 1000);
            }
        }
        this.setState({chat : chat.filter(x=>!x.deleted)},()=>{
            if(index == this.state.chat.length){
                return
            }
            // var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            // var text1=this.state.chat[index].message.replace(exp, "<a target='_blank' href='$1'>$1</a>");
            // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
            // document.getElementById("chatMessage"+(index)).innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
        })
    }
    deletePendingAnnouncements = () => {
        let pending = this.state.pendingChatMessageToUpdate
        if(pending.length > 0){
            var chatItemToDelete = {
                event_id : this.props.eventAlias,
                event_agenda_id : this.props.selectedSession.agendaId,
                event_session_id : this.props.selectedSession.sessionId
            }
            chatItemToDelete.announcement_id = pending[0].responseId
            api.deleteAnnouncement(this.props.eventAlias,chatItemToDelete,(err,resData)=>{
                let indexToDelete = pending.findIndex(x=>x.id == pending[0].id)
                pending.splice(0,1)
                this.setState({pendingChatMessageToUpdate : pending},()=>{
                    // var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                    // var text1=this.state.chat[this.state.chat.length-1].message.replace(exp, "<a target='_blank' href='$1'>$1</a>");
                    // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
                    // document.getElementById("chatMessage"+(this.state.chat.length-1)).innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
                })
            })
        }else{
            clearInterval(this.deleteInterval)
            this.deleteInterval = null
        }
    }
    updatePendingQuestions = () => {
        let pendingQuestions = this.state.pendingQuestionsToUpdate
        let questions = this.state.questions
        for(var i=0;i<pendingQuestions.length;i++){
            let qIndex = questions.findIndex(x=>x.id == pendingQuestions[i].id)
            if(qIndex > -1){
                if(questions[qIndex].responseId != null){
                    pendingQuestions[i].responseId = questions[qIndex].responseId
                    this.UpdateQuestionBackEnd(pendingQuestions[i],questions[qIndex].rejected)
                    pendingQuestions[i].removed = true
                    if(pendingQuestions[i].approved){
                        questions[qIndex].approved = true
                    }else if(pendingQuestions[i].rejected){
                        questions.splice(qIndex,1)
                    }
                    this.socket.emit('QAChannel',pendingQuestions[i])
                }
            }
        }
        this.setState({questions,pendingQuestionsToUpdate : pendingQuestions.filter(x=>!x.removed)})
    }
    render(){
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
        return(
            <>
            {/* {this.props.sessionLoggedIn ? <div className = "text-center" style = {{width : '100%',height : window.innerHeight,zIndex : 99,position : 'absolute',top : 0,background : '#00000080',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                <Loader
                    type = 'Oval'
                    color = "#fff"
                    height = {50}
                    width = {50}
                />
            </div> : null} */}
            <nav class="navbar navbar-expand-lg navbar-light bg-white">
                <div class="container">
                    <ul class = "nav mobtix-nav">
                        <li class={this.state.selectedTabIndex == 0 ? "nav-item active" : "nav-item"}>
                            <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickTab(0)}>Questions</a>
                        </li>
                        <li class={this.state.selectedTabIndex == 1 ? "nav-item active" : "nav-item"}>
                            <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickTab(1)}>Messages</a>
                        </li>
                    </ul>
                    
                    <ul class = "nav mobtix-nav ml-auto">
                        <li class="nav-item">
                            <a class="btn btn-primary btn-sm text-light" style = {{cursor : 'pointer'}} onClick = {this.props.onLogout}>Logout</a>
                        </li>
                    </ul>
                    
                </div>
            </nav>
            <div style = {{display : this.state.selectedTabIndex == 0 ? "" : "none"}}  class="container mobtix-container py-3 py-md-5">  
                <h6>
                    {this.props.selectedSession.name}
                </h6>
                <ul class="nav nav-tabs rounded text-center w-100 tab-div" id = "nav_bb">
                <li class="nav-item w-50">
                    <a class={this.state.seletectedQuestionsTab == 0 ? "nav-link active py-2 tabside" : "nav-link py-2 tabside"} 
                    data-toggle="tab" href="#home" id = "qus">
                        Latest Questions
                    </a>
                </li>
                <li class="nav-item w-50">
                    <a class={this.state.seletectedQuestionsTab == 1 ? "nav-link active py-2 tabside" : "nav-link py-2 tabside"} data-toggle="tab" href="#menu1" id = "app-qus">
                        Approved Questions
                    </a>
                </li>
                </ul>
                
                <div class = "row tab-content">
                
                    <div class={this.state.seletectedQuestionsTab == 0 ? "col-12 mt-3 tab-pane fade show active" : "col-12 mt-3 tab-pane fade"} id="home"> 
                        {this.state.questions.map((question,index)=>{
                            if(question.deleted || question.approved){
                                return
                            }
                            return(
                                <div class="question p-3 mb-2">
                                    <p>
                                        <span class = "name">{question.senderName}</span> 
                                        <span class = "time ml-3">{question.timeString}</span>
                                        {question.updating ?
                                        <div className = "float-right mx-1 py-1">
                                            <Loader 
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {20}
                                                width = {20}
                                            />
                                        </div> : null}
                                        <button onClick = {()=> this.OnClickRejectQuestion(index)} type="button" class="btn-reject btn-outline-danger btn-sm 
                                        float-right mx-1 py-1 d-none d-md-block">
                                            Reject
                                        </button>
                                        <button onClick = {()=> this.OnClickApproveQuestion(index)} type="button" class="btn-accept btn-outline-success btn-sm 
                                        float-right mx-1 py-1 d-none d-md-block">
                                            Accept
                                        </button>
                                    </p>
                                    <p>
                                        {question.message}
                                    </p>
                                    
                                    <div class = "d-block d-md-none text-right">
                                        <button onClick = {()=> this.OnClickRejectQuestion(index)} type="button" class="btn-reject btn-outline-danger btn-sm mx-1 py-1">
                                            Reject
                                        </button>
                                        <button onClick = {()=> this.OnClickApproveQuestion(index)} type="button" class="btn-accept btn-outline-success btn-sm mx-1 py-1">
                                            Accept
                                        </button>
                                        {question.updating ?
                                        <div className = "float-right mx-1 py-1">
                                            <Loader 
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {20}
                                                width = {20}
                                            />
                                        </div> : null}
                                    </div>
                                </div> 
                            )
                        })}
                    </div>
                    <div class={this.state.seletectedQuestionsTab == 1 ? "col-12 mt-3 tab-pane fade show active" : "col-12 mt-3 tab-pane fade"} id="menu1">
                        {this.state.questions.map((question,index)=>{
                            if(!question.approved){
                                return null
                            }
                            return(
                                <div class="question p-3 mb-2">
                                    <p>
                                        <span class = "name">{question.senderName}</span> 
                                        <span class = "time ml-3">{question.timeString}</span>
                                        {question.upVoteCount.count > 0 ?
                                        <span class = "float-right mr-2 text-success">
                                            <i class="fas fa-thumbs-up"></i>
                                            <span class = "like-num ml-2">{question.upVoteCount.count}</span>
                                        </span> : null}
                                    </p>
                                    <p>{question.message}</p>
                                </div> 
                            )
                        })}
                    </div>

                </div>
            </div>
            <div style = {{display : this.state.selectedTabIndex == 1 ? "" : "none"}} >
            <div class="container mobtix-container py-3 py-md-5">  
                <h6>
                    {this.props.selectedSession.name}
                </h6>
                
                <div class = "row">
                
                    <div id="chat-body" class = "col-12 chat-body">
                        {this.state.chat.length == 0 ?
                        <div class = "chat-div">
                            <div class = "empty-body position-relative">
                                <div class = "empty-msg text-center">
                                    <img src = "/images/message-icon.svg" alt = "msg-icon" />
                                    <p>
                                        You can send messages / announcements to attendees
                                    </p>
                                </div>
                            </div> 
                        </div> : 
                        <div class = "chat-div position-relative">
                            {this.state.chat.map((item,index)=>{
                                if(item.deleted){
                                    return null
                                }
                                return(
                                    <div class="question p-3 mb-2">
                                        <p>
                                            <span class = "name">{item.sender}</span> 
                                            <span class = "time ml-3">{item.timeString}</span>
                                            <button onClick = {()=>this.OnClickDeleteChat(index)} type="button" class="btn btn-outline-danger btn-sm float-right 
                                            mx-1 py-1">
                                                Delete
                                            </button>
                                        </p>
                                        <Linkify componentDecorator={componentDecorator}><p id = {"chatMessage"+index}>
                                            {item.message}
                                        </p></Linkify> 
                                    </div>
                                )
                            })}
                        </div>}
                    </div>

                </div>
            </div>
            <div class = "enter-msg py-3 py-md-4">
                <div class = "container">
                    <form action = {this.OnFormSubmit} class="form-inline">
                        <input value = {this.state.chatMessage} onChange = {this.handleChange} type="text" class="form-control mb-2 mb-md-0 mr-md-2" 
                        placeholder = "Type your question here..." />
                        <button onClick = {this.OnFormSubmit} class = "btn btn-success btn-sm text-light">
                            <Trans>send</Trans>
                        </button>
                    </form>
                </div>
            </div> 
            </div>
            </>
        )
    }
}
export default MainView