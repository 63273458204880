import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getEventBooking(eventAlias,callback){
        axios.get(`${eventAlias}/getEventBooking`,
        {
            params: {event_alias: eventAlias,isActive : 1,isPreview : Constants.isPreview},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
        
    },
    saveOrder(eventAlias,data,callback){
        axios.post(`${eventAlias}/sendEventOrder`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    savePayment(eventAlias,data,callback){
        axios.post(`${eventAlias}/sendOrderInvoice`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    paymentRedirection(eventAlias,data,callback){
        axios.post(`${eventAlias}/getPaymentRedirect`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == 'success'){
                callback(null,response.data)
            }
            else{
                callback({
                    message : 'Something went wrong'
                },null)
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong'
            },null)
        })
    },
    confirmFreeBooking(eventAlias,data,callback){
        axios.post(`${eventAlias}/sendFreeOrder`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == 'success'){
                callback(null,response.data)
            }
            else{
                callback(response.data,null)
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong'
            },null)
        })
    },
    getTranscationStatus(eventAlias,data,callback){
        axios.get(`${eventAlias}/getTransactionStatus`,
        {
            params : data,
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == 'success'){
                callback(null,response.data)
            }
            else{
                callback({
                    message : 'Something went wrong'
                },null)
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong'
            },null)
        })
    },
    sendTranscationStatus(eventAlias,data,callback){
        axios.get(`${eventAlias}/SendTransactionStatus`,
        {
            params : data,
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == 'success'){
                callback(null,response.data)
            }
            else{
                callback({
                    message : 'Something went wrong'
                },null)
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong'
            },null)
        })
    }
}