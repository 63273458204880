import React from 'react' 
import Loader from 'react-loader-spinner'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import $ from "jquery";
import { Constants } from '../../../Constants';
import { withRouter } from 'react-router';
import  PermiumUserModal  from '../../modals/premiumUserModal'
import {Trans} from 'react-i18next'
const EditProfile = (props)=>{
    //console.log("EDIT PROFILE",props.translation)
    console.log(props.saveProfileError)
    const moveTolOgin = ()=>{
        localStorage.removeItem(Constants.API_TOKEN+props.alias+'user_info')
    }
    const OnNavigateToPage = (path,data = null) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            props.history.push(path)
        }else{
            props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    return(
        <>
        <PermiumUserModal
        id="profileSession"
        iconClass="fa-lock"
        title='login'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        onClose = {()=>{
            $("#profileSession").modal("hide");
            $(".modal-backdrop").remove();
        }}
        opacity={0.5}
        zIndex={10000}
        keyboard= {false}
        showButton={true}
        onClickBookNow={() => {
          moveTolOgin()
          $("#profileSession").modal("hide");
         $(".modal-backdrop").remove();
          OnNavigateToPage(`${props.alias}/login`);
          props.expireSession()
        }}/>
        
        <div class="card-header"><Trans>myProfile</Trans></div>
        <div class="card-body border-bottom rounded-0">
            <form action = "">
            
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>firstName</Trans> <span class="text-danger">*</span></label>
                        <input disabled = {props.savingProfile} id="firstName" defaultValue = {props.user.firstName == null ? '' : props.user.firstName} type="text" class= {props.eFirstName == ""?"form-control":"form-control text-red-border"} placeholder="Jhon" />
                        {props.eFirstName != '' ? <div class="invalid-feedback">{props.eFirstName}</div> : null}
                    </div>
                    <div class="col-md-6">
                        <label for=""><Trans>secondName</Trans> </label>
                        <input disabled = {props.savingProfile} id="secondName" defaultValue = {props.user.secondName == null ? '' : props.user.secondName} type="text" class= {"form-control"} placeholder="San" />
                        {/* {props.eSecondName != '' ? <div class="invalid-feedback">{props.eSecondName}</div> : null}*/}
                    </div> 
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>lastName</Trans> <span class="text-danger">*</span></label>
                        <input disabled = {props.savingProfile} id="lastName" defaultValue = {props.user.lastName == null ? '' : props.user.lastName} type="text" class= {props.eLastName == ""?"form-control":"form-control text-red-border"} placeholder="Doe" />
                        {props.eLastName != '' ? <div class="invalid-feedback">{props.eLastName}</div> : null}
                    </div>
                    <div class="col-md-6">
                        <label for=""><Trans>email</Trans> <span class="text-danger">*</span></label>
                        <input disabled = {props.savingProfile} defaultValue = {props.user.email == null ? '' : props.user.email} onKeyDown ={(e)=>{
                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    let keyCode = e.which || e.keyCode
                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                        e.preventDefault();
                                        return false
                                    }
                                    else{
                                        return true
                                    }

                                }} disabled = {true} type="email" class="form-control" placeholder = "example@gmail.com" readonly />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>companyName</Trans> </label>
                        <input disabled = {props.savingProfile} id="companyName" defaultValue = {props.user.companyName == null ? '' : props.user.companyName} type="text" class= {props.eCompanyName == ""?"form-control":"form-control"} placeholder="Apple" />
                        {/* {props.eCompanyName != '' ? <div class="invalid-feedback">{props.eCompanyName}</div> : null} */}
                    </div>

                    <div class="col-md-6">
                        <label for=""><Trans>jobTitle</Trans> </label>
                        <input disabled = {props.savingProfile} id="jobTitle" defaultValue = {props.user.jobTitle == null ? '' : props.user.jobTitle} type="text" class= {props.eJobTitle == ""?"form-control":"form-control"} placeholder="Software Engineer" />
                        {/* {props.eJobTitle != '' ? <div class="invalid-feedback">{props.eJobTitle}</div> : null} */}
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>phNumber</Trans></label>
                        {/* <input disabled = {props.savingProfile} id="phone" defaultValue = {props.user.phone == null ? '' : props.user.phone} type="tel" class= {props.ePhone == ""?"form-control":"form-control text-red-border"} placeholder = "+92301314578" /> */}
                        <PhoneInput
                            disabled = {props.savingProfile}
                            inputStyle = {{width : '99.5%',marginLeft : 2,height : 'calc(2.25rem + 2px)'}}
                            inputClass = {props.ePhone != "" ? "text-red-border" : "phone-picker"}
                            id="phone"
                            country={props.defaultCountry.code}
                            value = {props.phone}
                            jumpCursorToEnd
                            countryCodeEditable = {false}
                            onChange = {(phone,country) => props.onChangePhone(phone,country)}
                            onFocus = {(phone,country,e)=> props.onFocus(country)}
                        />
                        {props.ePhone != '' ? <div class="invalid-feedback">{props.ePhone}</div> : null}
                    </div>
                
                    <div class="col-md-6 select_appearness">
                        <label for=""><Trans>gender</Trans></label>
                        
                        <select disabled = {props.savingProfile} defaultValue = {props.user.gender == null ? '' : props.user.gender} class="form-control form_select" id="sel1">
                            <option value="" selected disabled>{props.translation.select}</option>
                            <option>{props.translation.male}</option>
                            <option>{props.translation.female}</option>
                        </select>
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>city</Trans></label>
                        <input disabled = {props.savingProfile} defaultValue = {props.user.city == null ? '' : props.user.city} type="text" id = "city" class="form-control" placeholder = "London" />
                    </div>
                
                    <div class="col-md-6 select_appearness">
                        <label for=""><Trans>country</Trans></label>
                        <select disabled = {props.savingProfile} defaultValue = {props.user.country == null ? '' : props.user.country} class="form-control form_select" id = "country-select">
                            {props.countryOptions()}
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label for=""><Trans>bio</Trans></label>
                        <textarea defaultValue = {props.user.profileBio} name="message" id="profileBio" class = "form-control" cols="20" rows="3" placeholder={props.translation.aboutMe}></textarea>
                    </div>
                </div>
                <div style = {{display : 'flex',flexDirection : 'row'}}>  
                    <div class="form-group">
                        <a style = {{pointerEvents : props.savingProfile ? 'none' : 'auto'}} onClick = {()=>props.onClickSaveProfile()} class="btn bg_main btn-tele text-light px-5 rounded-0">
                            <Trans>save</Trans></a>
                    </div>
                    {props.savingProfile ?
                    <div style = {{marginTop : 6,marginLeft : 12}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {30}
                            width = {30}
                        />
                    </div> : null}
                </div>{
                props.sessionExpire != 440 ? 
                props.saveProfileError != '' ?
                    <div className="alert alert-danger contactUsAlert">{props.saveProfileError}</div>:null:null
                }
            </form>
        </div>
        </>
    )
}

export default withRouter(EditProfile)