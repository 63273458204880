import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Loader from 'react-loader-spinner'
import utility from '../../Utility/utility';
import api from '../RegisterModel/api'
import $ from 'jquery'
import { Constants } from '../../Constants';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Trans } from 'react-i18next';
export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            page: this.props.page,
            downloadingPdf : false,
            email : '',
            companyName : '',
            firstName : '',
            lastName : '',
            jobTitle : '',
            mobileNumber : '',
            message : '',
            eEmail : '',
            eFirstName : '',
            eLastName : '',
            eCompanyName : '',
            eJobTitle : '',
            eMobileNumber : '',
            eMessage : ''
        };
        this.defaultDailCode = Constants.defaultCountry.dailCode
    }
    componentDidMount(){
        $('#firstName').bind('keypress', this.testInput);
        $('#lastName').bind('keypress', this.testInput);
        $('#cFirstName').bind('keypress', this.testInput);
        $('#cLastName').bind('keypress', this.testInput);
        let user = utility.getLoggedInUser(this.props.event.alias)
		if(user != null){
            let mobileNumber = ''
            if(user.phone != null){
                mobileNumber = user.phone
            }
			this.setState({email : user.email,firstName : user.firstName,lastName : user.lastName,companyName : user.companyName,jobTitle : user.jobTitle,mobileNumber})
		}
    }
    OnClickDownloadPdf = () => {
        var email = this.state.email
		var firstName = this.state.firstName
		var lastName = this.state.lastName
		var companyName = this.state.companyName

		var canRegister = true

		if(!utility.validateEmail(email)){
			this.setState({eEmail : "please enter valid email"})
			canRegister = false
		}
		else{
			this.setState({eEmail : ""})
		}
		if(firstName == ""){
			this.setState({eFirstName : "please enter valid first name"})
			canRegister = false
		}
		else{
			this.setState({eFirstName : ""})
		}
		if(lastName == ""){
			this.setState({eLastName : "please enter valid last name"})
			canRegister = false
		}
		else{
			this.setState({eLastName : ""})
		}
		if(companyName == ""){
			this.setState({eCompanyName : "please enter valid compnay name"})
			canRegister = false
		}
		else{
			this.setState({eCompanyName : ""})
        }
		if(canRegister){
			var obj = {
                email:email,
                firstName:firstName,
				lastName:lastName,
                companyName:companyName,
                type : 'additional-page',
                pageTitle : this.props.page.title
            };
            this.setState({downloadingPdf : true})
            api.sendRegisterInterestUsers(this.props.event.alias,obj,(err,data)=>{
                if(err == null){
					if(data.status == "failed"){
                        return
                    }
                    this.props.onClickDownloadPdf()
                }
            })
        }
    }
    OnClickSubmitContactUs = () => {
        var email = this.state.email
		var firstName = this.state.firstName
		var lastName = this.state.lastName
        var companyName = this.state.companyName
        var jobTitle = this.state.jobTitle
        var mobileNumber = this.state.mobileNumber
        var message = this.state.message
        this.setState({eEmail : '',eFirstName : '',eLastName : '',eCompanyName : '',eJobTitle : '',eMobileNumber : '',eMessage : ''})
        var canSubmit = true
        if(!utility.validateEmail(email)){
            canSubmit = false
            this.setState({eEmail : 'please enter valid email'})
        }
        if(firstName == ''){
            canSubmit = false
            this.setState({eFirstName : 'please enter valid first name'})
        }
        if(lastName == ''){
            canSubmit = false
            this.setState({eLastName : 'please enter valid last name'})
        }
        // if(jobTitle == ''){
        //     canSubmit = false
        //     this.setState({eJobTitle : 'please enter valid job title'})
        // }
        // if(companyName == ''){
        //     canSubmit = false
        //     this.setState({eCompanyName : 'please enter valid company name'})
        // }
        if(message == ''){
            canSubmit = false
            this.setState({eMessage : 'please enter valid message'})
        }
        if(mobileNumber != ''){
            if(mobileNumber.charAt(0) != '+'){
                mobileNumber = "+"+mobileNumber
            }
            if(mobileNumber == this.defaultDailCode){
                mobileNumber = ''
            }else{
                if(!utility.validatePhone(mobileNumber)){
                    canSubmit = false
                    this.setState({eMobileNumber : 'please enter valid mobile number'})
                }
            }
        }
        if(canSubmit){
            this.props.submitContactUsForm({
                email : email,
                first_name : firstName,
                last_name : lastName,
                organization : companyName,
                job_title : jobTitle,
                phone : mobileNumber,
                message : message,
                page_id : this.props.page.id
            })
        }
    }
    OnPDFDownloaded = () => {
        this.setState({downloadingPdf : false})
        $('#additional_download_Modal').modal('hide');
        $('.modal-backdrop').remove();

        $('#message_Modal').modal('show');
    }
    OnChangeTextField = (field,e) => {
        if(field == "email"){
			this.setState({email : e.target.value})
		}
		if(field == "firstName"){
			this.setState({firstName : e.target.value})
		}
		if(field == "lastName"){
			this.setState({lastName : e.target.value})
		}
		if(field == "company"){
			this.setState({companyName : e.target.value})
        }
        if(field == "jobTitle"){
			this.setState({jobTitle : e.target.value})
        }
        if(field == "mobile"){
			this.setState({mobileNumber : e.target.value})
		}
        if(field == "message"){
			this.setState({message : e.target.value})
		}
    }
    OnFocusCountryInput = (country) => {
        this.defaultDailCode = "+"+country.dialCode
    }
    testInput(event) {
        var value = String.fromCharCode(event.which);
        var pattern = new RegExp(/(fiktiv)|[^0-9]/g);
        return pattern.test(value);
    }
    render(){
        const page = this.props.page;
        const divStyle = {
            backgroundImage: 'url(' + page.image + ')',
            // backgroundSize: '100% 600px',
            backgroundRepeat: 'no-repeat',
            // height: '600px',
            position: 'relative',
            backgroundPosition: 'center',
	        backgroundSize: 'cover',
	        height: this.props.event.is_marketplace == 1 ? '40vh' :'70vh'
        };
        if(page){
            return(
                <div>
                    {page.image != null ?
                    <section class = "about_banner customPageBanner" style = {{background:"none",zIndex:'100'}}>
                        {/* {
                            page.image ?
                                <img src = {page.image} class = "w-100" alt = "about-banner"/>
                            :<div className = "headerImage">

                            </div>
                            
                            
                        } */}
                        <div class = "container-fluid px-0">
			<div class = "row mx-0">
				<div class = "col-md-12 px-0 overlay">
					<img class = "w-100" src = {page.image}/>
				</div>
			</div>
		</div>
                        <div style = {{display : page.heading != null ? "" : "none"}} class = "container-fluid traning-title">
                            <div class = "row">
                                <div class = "container">
                                    <div class = "col-12 p-2 p-md-4 headingDynamicPage">
                                        <h4 class = "font-weight-bold text-light mb-0">
                                            {page.heading}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : null}
                    {page.image == null ? 
                    <div style = {{display : page.heading != null ? "" : "none"}} class = "row">
                        <div class = "container">
                            <div class = "col-12 mt-5 headingDynamicPage">
                                <h4 class = "ml-3 ml-md-0 font-weight-bold mb-0">
                                    {page.heading}
                                </h4>
                            </div>
                        </div>
                    </div> : null}
                    <section class = "about-detail py-1" style = {{width : '100%'}}>
                        <div class="container">
                            <div class="row py-3">
                                <div class="col-12 mb-2">
                                    <div class="row d-none d-md-flex">
                                        <p class = "col-md-6 font-weight-medium mb-4 text-center text-md-left" style = {{fontSize : '1.25rem'}}>
                                            {page.sub_heading}
                                        </p>
                                        <div className = "row col-md-6 justify-content-end">
                                            {page.pdf_file != null ?
                                            <div class = "font-weight-medium mr-2 mb-4 text-center text-md-right" style = {{fontSize : '1.25rem'}}>
                                                <a data-target ="#additional_download_Modal" data-toggle = "modal" className = "btn bg_primary">
                                                   <Trans>download</Trans> <i className="fas fa-download ml-2"></i>
                                                </a>
                                            </div> : null}
                                            {/* {page.speakers.length > 0 ? 
                                            <div className="font-weight-medium mr-2 mb-4 text-center text-md-right">
                                                <span onClick = {this.props.onClickBookNow} className = "btn bg_primary">
                                                    <Trans>bookNow</Trans> <i className="fas fa-angle-right ml-2"></i>
                                                </span>
                                            </div>:null} */}
                                            {page.show_contact_form == 1? 
                                            <div className="font-weight-medium mb-4 text-center text-md-right">
                                                <span data-target ="#contact_us_modal" data-toggle = "modal" className = "btn bg_primary">
                                                    <Trans>contactUs</Trans>
                                                </span>
                                            </div>:null}
                                        </div>
                                    </div>
                                    <div class="row d-block d-md-none">
                                        {page.pdf_file != null ?
                                        <div class = "col-md-6 font-weight-medium mb-2 text-center" style = {{fontSize : '1.25rem'}}>
                                            <a data-target ="#additional_download_Modal" data-toggle = "modal" className = "btn mr-2 bg_primary">
                                                <Trans>download</Trans> <i className="fas fa-download ml-2"></i>
                                            </a>
                                            {page.speakers.length > 0 ? 
                                            <span onClick = {this.props.onClickBookNow} className = "btn mr-2 bg_primary">
                                                <Trans>bookNow</Trans><i className="fas fa-angle-right ml-2"></i>
                                            </span> : null}
                                        </div> : null}
                                        {/* {page.show_contact_form == 1? 
                                        <div class = "col-md-6 font-weight-medium mb-2 text-center" style = {{fontSize : '1.25rem'}}>
                                            <span data-target ="#contact_us_modal" data-toggle = "modal" className = "btn bg_primary">
                                                <Trans>ContactUs</Trans> 
                                            </span> 
                                        </div> : null} */}
                                        <p class = "col-md-6 font-weight-medium mt-2 mb-4 text-center text-md-left" style = {{fontSize : '1.25rem'}}>
                                            {page.sub_heading}
                                        </p>
                                    </div>
                                    {ReactHtmlParser(page.description)}
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="modal fade" id="additional_download_Modal">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-body">
                                <h6 class = "text-uppercase f-600 mb-0"><Trans>download</Trans>PDF</h6>
                                <button type="button" class="close mdl_close" data-dismiss="modal">&times;</button>
                                <section class = "order-form py-1">
                                        <div class="container">
                                            <div class="row mt-3 bg-light px-3 py-3 border">
                                            
                                                    <div class="form-group col-md-12 mt-3">
                                                    
                                                    <p><Trans>detailsToDownload</Trans></p>
                                                    
                                                    <p class = "f-14"><Trans>allWorkRequired</Trans> <span class = "text-red f-600">( <i class="fas fa-star-of-life"></i> )
                                                    </span><Trans>infoBelow</Trans></p>
                                                    
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eFirstName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> 
                                                        <Trans>firstName</Trans></label>
                                                        <input value = {this.state.firstName} onChange = {(e)=>this.OnChangeTextField("firstName",e)} id = "firstName" class={this.state.eFirstName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eLastName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
                                                        <Trans>lastName</Trans></label>
                                                        <input value = {this.state.lastName} onChange = {(e)=>this.OnChangeTextField("lastName",e)} id = "lastName" class={this.state.eLastName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eEmail != "" ? "text-red" : ""} ><i class="fas fa-star-of-life text-red"></i><Trans>email</Trans></label>
                                                        <input value = {this.state.email} onChange = {(e)=>this.OnChangeTextField("email",e)} onKeyDown ={(e)=>{
                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    let keyCode = e.which || e.keyCode
                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                        e.preventDefault();
                                        return false
                                    }
                                    else{
                                        return true
                                    }

                                }} id = "email" type="email" class={this.state.eEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eCompanyName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
                                                        <Trans>companyName</Trans></label>
                                                        <input value = {this.state.companyName} onChange = {(e)=>this.OnChangeTextField("company",e)} id = "companyName" class={this.state.eCompanyName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    
                                                    <div class="form-group col-12 text-right mt-3">
                                                        <a onClick = {this.OnClickDownloadPdf}  class="btn bg_primary"><Trans>download</Trans></a>
                                                        {this.state.downloadingPdf ? <div style = {{position : 'absolute',top : 7,right : 130}}>
                                                            <Loader
                                                                type = 'Oval'
                                                                color = "#00b6ff"
                                                                height = {25}
                                                                width = {25}
                                                            />
                                                        </div> : null}
                                                    </div>
                                            </div>
                                        </div>
                                </section>	
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="modal fade" id="contact_us_modal">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-body">
                                <h6 class = "text-uppercase f-600 mb-0"><Trans>contactUs</Trans></h6>
                                <button type="button" class="close mdl_close" data-dismiss="modal">&times;</button>
                                <section class = "order-form py-1">
                                        <div class="container">
                                            <div class="row mt-3 bg-light px-3 py-3 border">
                                            
                                                    <div class="form-group col-md-12 mt-3">
                                                    
                                                    <p><Trans>getInTouch</Trans></p>
                                                    
                                                    <p class = "f-14"><Trans>allWorkRequired</Trans> <span class = "text-red f-600">( <i class="fas fa-star-of-life"></i> )</span>
                                                    <Trans>infoBelow</Trans></p>
                                                    
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eFirstName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> 
                                                        <Trans>firstName</Trans></label>
                                                        <input placeholder = {this.props.translation.enterFirstName} value = {this.state.firstName} onChange = {(e)=>this.OnChangeTextField("firstName",e)} id = "cFirstName" class={this.state.eFirstName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eLastName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
                                                        <Trans>lastName</Trans></label>
                                                        <input placeholder = {this.props.translation.enterLastName} value = {this.state.lastName} onChange = {(e)=>this.OnChangeTextField("lastName",e)} id = "cLastName" class={this.state.eLastName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eEmail != "" ? "text-red" : ""} ><i class="fas fa-star-of-life text-red"></i><Trans>email</Trans></label>
                                                        <input placeholder = {this.props.translation.enterEmail} value = {this.state.email} onChange = {(e)=>this.OnChangeTextField("email",e)} id = "email" type="email" onKeyDown ={(e)=>{
                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    let keyCode = e.which || e.keyCode
                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                        e.preventDefault();
                                        return false
                                    }
                                    else{
                                        return true
                                    }

                                }} class={this.state.eEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eJobTitle != "" ? "text-red" : ""}><Trans>jobTitle</Trans></label>
                                                        <input placeholder = {this.props.translation.enterJobTitle} value = {this.state.jobTitle} onChange = {(e)=>this.OnChangeTextField("jobTitle",e)} id = "jobTitle" class={this.state.eJobTitle != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eCompanyName != "" ? "text-red" : ""}><Trans>companyName</Trans></label>
                                                        <input placeholder = {this.props.translation.enterCompanyName}  value = {this.state.companyName} onChange = {(e)=>this.OnChangeTextField("company",e)} id = "companyName" class={this.state.eCompanyName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label class = {this.state.eMobileNumber != "" ? "text-red" : ""}><Trans>mobNumber</Trans></label>
                                                        <PhoneInput
                                                            disabled = {this.props.submittingContactUsForm}
                                                            inputStyle = {{width : '99.5%',marginLeft : 2,height : 'calc(2.25rem + 2px)'}}
                                                            inputClass = {this.state.eMobileNumber != "" ? "text-red-border" : "phone-picker"}
                                                            id="phone"
                                                            country={Constants.defaultCountry.code}
                                                            value = {this.state.mobileNumber}
                                                            jumpCursorToEnd
                                                            countryCodeEditable = {false}
                                                            onChange = {(phone) => this.OnChangeTextField('mobile',{target : {value : phone}})}
                                                            onFocus = {(phone,country,e)=> this.OnFocusCountryInput(country)}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label class = {this.state.eMessage != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i><Trans>message</Trans></label>
                                                        <textarea placeholder = {this.props.translation.enterMessage} value = {this.state.message} name="message" id="profileBio" class = {this.state.eMessage == "" ? "form-control" : "form-control text-red-border"} cols="20" rows="3" onChange = {(e)=>this.OnChangeTextField("message",e)}></textarea>
                                                    </div>
                                                    <div class="form-group col-12 text-right mt-3">
                                                        <a onClick = {this.OnClickSubmitContactUs}  class="btn bg_primary"><Trans>submit</Trans></a>
                                                        {this.props.submittingContactUsForm ? <div style = {{position : 'absolute',top : 7,right : 110}}>
                                                            <Loader
                                                                type = 'Oval'
                                                                color = "#00b6ff"
                                                                height = {25}
                                                                width = {25}
                                                            />
                                                        </div> : null}
                                                    </div>
                                            </div>
                                        </div>
                                </section>	
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return(null)
        }

    }
}

export default Content;