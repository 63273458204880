import React from 'react';
import {Trans} from 'react-i18next'
import './styles.css'
export const CookieBar = (props) =>{
    return(
        <div id="cookieBar" class="cookies-container position-fixed w-100 bg-white">
            <div class="container-fluid py-3 px-md-5">
                <h6><Trans>acceptCookies</Trans></h6>
                <div class="row">
                    <div class="col-sm-9">
                        <p>
                            <Trans>cookieNote</Trans> <a href="/privacy-policy"><Trans>learnMore</Trans>.</a>
                        </p>
                    </div>
                    <div class="col-sm-3 text-sm-center mt-3 mt-sm-0">
                        <a class="btn btn-cookie telehealth-btn
                        " onClick = {props.onClickClose}>
                            <Trans>cookieButton</Trans>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}