import axios from '../../axios/index'
import { Constants } from '../../../Constants';
import utility from '../../../Utility/utility';

export default {
    async getInviatations(eventAlias,token,callback){
        await axios.get(`${eventAlias}/invitations/get`,
        {
            'Content-Type': 'application/json',
            headers: 
            { 
                'apiToken': Constants.API_TOKEN ,
                'Authorization' : 'Bearer '+token},
        }).then(response=>{
            if(response.data.status == "success"){
                var sentInvites = response.data.invitation_sent
                var receivedInvites = response.data.invitation_receive
                for(var i=0;i<sentInvites.length;i++){
                    sentInvites[i].created_at = utility.convertUTCDateToLocalDate(new Date(sentInvites[i].created_at))
                }
                for(var i=0;i<receivedInvites.length;i++){
                    receivedInvites[i].created_at = utility.convertUTCDateToLocalDate(new Date(receivedInvites[i].created_at))
                }
                sentInvites.sort((a,b)=>{
                    return new Date(b.created_at) - new Date(a.created_at)
                })
                receivedInvites.sort((a,b)=>{
                    return new Date(b.created_at) - new Date(a.created_at)
                })
                var obj = {
                    sent : sentInvites,
                    received : receivedInvites
                }
                callback(null,obj)
            }else{
                callback(response.data.masg,null)
            }
        }).catch(error=>{
            callback({
                message : 'Something went wrong'
            },null)
        });
    },
    async sendInvite(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/invitations/send`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.data)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1)
                }else{
                    var error = {
                        code : response.data.error.code,
                        message : response.data.error.message
                    }
                    callback(error,null)
                }
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    async updateInviteStatus(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/invitations/update_status`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.data)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1)
                }else{
                    var error = {
                        code : response.data.error.code,
                        message : response.data.error.message
                    }
                    callback(error,null)
                }
                
            }
        }).catch(error => {
            callback(error,null)
        })
    }
}