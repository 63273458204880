import React from 'react';
import api from './api';
import Header from '../Header/Index';
import PresentationView, { PresentaionView } from './presentationView';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import $ from 'jquery'
import 'bootstrap'
import DownloadPresenationView from './downloadPresenationView';
import FileSaver,{ saveAs } from 'file-saver';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { PresentaionDataLoaded } from './actions';
import MetaTags from 'react-meta-tags';
import { Constants } from '../../Constants';
import VirtualZone from '../Header/virtualZone';
import { GAPageView } from '../../Utility/GA';
import {withTranslation} from 'react-i18next'
class Presenations extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf('/presentations') > -1) || -1,
            selectedSubIndex : -1,
            presentations : null,
            loadingError : '',
            ePassword : '',
            passwordVerified : false,
            selectedPresenationIndex : -1,
            menu : this.props.headerMenuItems.find(x=>x.href == (this.props.event.alias+"/presentations")),
            showComingSoonMsg : false
        };
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+this.props.event.alias)|| 'en')

    }

    componentDidMount(){
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        fetch('/setCurrentPage?page=presentations')
        fetch(`/${this.props.event.alias}/presentations`)
        this.setState({presentations : this.props.presentations})
        if(this.props.presentations != null){
            if(this.props.presentations.length == 0){
                this.setState({showComingSoonMsg : true})
            }
        }
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],'Presentations')
                if(result != null){
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/presentations') > -1)
                    this.setState({selectedIndex : i,selectedSubIndex})
                    break
                }
            }
        }
        if(this.props.presentations == null){
            this.getEventPresentations()
        }
    }
    getEventPresentations = () => {
        api.getEventPresentations(this.state.event.alias,(err,data)=>{
            if(err == null){
                var presentationsArray = []
                Object.keys(data.presentations).map((key)=>{
                    presentationsArray.push(data.presentations[key])
                })
                if(presentationsArray.length == 0){
                    this.setState({showComingSoonMsg : true})
                }
                this.props.presentationsLoaded(presentationsArray)
                this.setState({presentations:presentationsArray,loadingError : ''});
                return
            }
            if(this.props.presentations == null)
                this.setState({loadingError : 'Something went wrong'})
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
        this.getEventPresentations()
    }
    OnClickSubmit = (value,presentaionIndex) => {
        var filePassword = this.state.presentations[presentaionIndex].file_password
        if(value == ""){
            this.setState({ePassword : this.translation.enterPasscode})
            return
        }
        if(value == filePassword){
            $("#pass_modal").modal('hide');
            $('.modal-backdrop').remove()
            this.setState({ePassword : '',passwordVerified : true,selectedPresenationIndex : presentaionIndex})
        }
        else{
            this.setState({ePassword : this.translation.incorrectPassword})
        }
    }
    OnDownloadPDF = (fileIndex) =>{
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = this.state.presentations[this.state.selectedPresenationIndex].files[fileIndex].path;
        // the filename you want
        a.download = 'test.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(this.state.presentations[this.state.selectedPresenationIndex].files[fileIndex].path);
        // FileSaver.saveAs(this.state.presentations[this.state.selectedPresenationIndex].files[fileIndex].path,"test.pdf")
    }
    OnPressBack = () => {
        this.setState({passwordVerified : false})
    }

    languageClick = (value) =>{
        //console.log("Trigger presentation on lan select")
        this.props.languageClick(value);
    }


    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.menu != null ? this.state.menu.title : 'Presentations'}</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                <div id="header-top">
                <Header
                    selectedIndex = {this.state.selectedIndex}
                    selectedSubIndex = {this.state.selectedSubIndex}
                    onMenuItemClick = {this.OnNavigateToPage}
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    isMarketplace = {this.props.event.is_marketplace}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    event = {this.props.event}
                    eventLogo = {this.props.event.logo}
                    eventAlias = {this.props.event.alias}
                    eventName = {this.props.event.name}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                    onClickAccountItem = {this.OnClickAccountItem}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    notifications = {this.props.notifications}
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div>
                <div className = {`content-main`}>
                    {this.state.presentations != null ?
                        this.state.showComingSoonMsg ?  
                        <div className = "text-center bg-light">
                            <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                        </div> : 
                        !this.state.passwordVerified ?
                        <PresentaionView
                            presentations = {this.state.presentations}
                            passwordError = {this.state.ePassword}
                            onSubmitPassword = {this.OnClickSubmit}
                            translation = {this.translation}
                        /> : 
                        <DownloadPresenationView
                            selectedPresenationIndex = {this.state.selectedPresenationIndex}
                            title = {this.state.presentations[this.state.selectedPresenationIndex].title}
                            files = {this.state.presentations[this.state.selectedPresenationIndex].files}
                            onClickDownload = {this.OnDownloadPDF}
                            onGoBack = {this.OnPressBack}
                        />
                        :
                        <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                            {this.state.loadingError == "" ?
                                <Loader
                                    type = 'Oval'
                                    color = "#00b6ff"
                                    height = {40}
                                    width = {40}
                                />:
                                <ErrorScreen 
                                    message = {this.state.loadingError}
                                    onPressRetry = {this.OnPressRetry}
                                />
                            }
                        </div>
                    }
                    <Footer1
                        event = {this.state.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                </div>
                <Footer2 
                   event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    presentations: state.presentations.presentationData
})
const mapDispatchToProps = dispatch => ({
    presentationsLoaded : data => dispatch(PresentaionDataLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Presenations);