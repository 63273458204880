import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getMeetings(eventAlias,callback){
        axios.get(`${eventAlias}/getEventMeetings`,
            {
                params: {isActive:1,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
                var meetings = response.data.data.meetings
                for(var i=0;i < meetings.length;i++){
                    if(meetings[i].type == "101meeting"){
                        meetings[i].sortId = 0
                    }else if(meetings[i].type == "roundtable"){
                        meetings[i].sortId = 1
                    }else{
                        meetings[i].sortId = 2
                    }
                }
                meetings.sort((a,b)=>{
                    return a.sortId - b.sortId
                })
                callback(null,response.data.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    getMeetingDetail(eventAlias,path,callback){
        axios.get(`${eventAlias}${path}`,
            {
                params: {isActive:1,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
                if(response.data.status == "error"){
                    callback(response.data,null)
                }else{
                    callback(null,response.data)
                }
        }).catch(error => {
            callback(error,null)
        })
    },
    scheduleMeeting(eventAlias,data,callback){
        axios.post(`${eventAlias}/scheduleMeeting`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,{})
            }
            else{
                if(response.data.status == "error"){
                    callback(response.data,null)
                }else{
                    callback({
                        message : response.data.message
                    },null)
                }
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong ! Please try again'
            },null)
        })
    },
    getUserBookedSlots(eventAlias,userId,callback){
        axios.get(`${eventAlias}/getUserBookedSlots`,
            {
                params: {isActive:1,isPreview : Constants.isPreview,userId : userId},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
                if(response.data.status == "success"){
                    callback(null,response.data.speakers)
                }
                else{
                    callback(null,[])
                }
        }).catch(error => {
            callback(error,null)
        })
    },
    bookRoundTableSeat(eventAlias,isGalaSeat,data,callback){
        let apiPath = ""
        if(isGalaSeat){
            apiPath = `${eventAlias}/bookingTheGalaSeat`
        }else{
            apiPath = `${eventAlias}/bookingTheSeat`
        }
        axios.post(apiPath,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,{
                    message : response.data.masg
                })
            }
            else{
                if(response.data.status == "error"){
                    callback(response.data,null)
                }else{
                    callback({
                        message : response.data.masg
                    },null)
                }
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong ! Please try again'
            },null)
        })
    }
}