import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../Agenda/api';
import {compose} from 'redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,withRouter
} from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { BlogsLoaded } from './actions';
import {connect} from 'react-redux'
import Linkify from 'react-linkify';
import ContentLoader from 'react-content-loader'
import {Trans} from 'react-i18next'
export class EventBlog extends React.Component{
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            event_alias : this.props.event_alias,
            type : this.props.type,
            feature : {},
            showContent : false,
            isLoading : true,
            title:'Blogs',
            blogsFeature:[]
        };
    }

    componentDidMount(){
        this._isMounted = true
        if(this.props.featureLoading != this.state.isLoading){
            this.setState({isLoading:this.props.featureLoading})
        }
        if(this.props.Features.length>0){
            if(this.props.Features[0].hasOwnProperty('blogs')){
                this.setState({blogsFeature:this.props.Features[0].blogs.items,title:this.props.Features[0].blogs.title})
            }
        }
        // if(this.props.blogs != null){
        //     let showContent = false
        //     if(this.props.blogs.features.length > 0){
        //         showContent = true
        //     }
        //     this.setState({feature : this.props.blogs,showContent,isLoading : false})
        //     return
        // }
        // this.setState({isLoading : true})
        // api.getEventFeatures(this.state.event.alias,this.state.type,1,(err,data)=>{
        //     if(err == null && this._isMounted){
        //         if(data.feature != null){
        //             data.feature.features = data.feature.features.filter(x=>x.type == "Blog")
        //             console.log(data.feature)
        //             this.props.blogsLoaded(data.feature)
        //             this.setState({feature:data.feature},()=>{
        //                 if(data.feature.features.length > 0){
        //                     this.setState({showContent : true})
        //                 }
        //             });
        //         }
        //     }
        //     this.setState({isLoading : false})
        // })
    }
    componentWillUnmount(){
        this._isMounted = false
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.featureLoading != this.state.isLoading){
            this.setState({isLoading:nextProps.featureLoading})
        }
        if(nextProps.Features.length>0){
            if(nextProps.Features[0].hasOwnProperty('blogs')){
                this.setState({blogsFeature:nextProps.Features[0].blogs.items,title:nextProps.Features[0].blogs.title})
            }
        }

    }
    OnNavigateToPage = (path,data=null) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data){
            this.props.history.push({
                pathname: path,
                state: data
            })
        }else{
            this.props.history.push(path)
        }
    }
    extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };
    OnClickReadMore = (link) => {
        window.scrollTo(0,0)
    }
    render(){
        if(this.state.isLoading){
            return(
                <section class = "blog py-2">
                    <div class="container blog-container">
                    <h5 className = "text-center font-weight-bold text-uppercase pb-3">{this.props.event.is_jobfair == 1 ?'How to Participate':'Blog'}</h5>
                    <ContentLoader style = {{padding : 10}}>
                        <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />
                        <rect x="70" y="14" rx="4" ry="4" width="300" height="8" />
                        <rect x="70" y="30" rx="3" ry="3" width="250" height="6" />
                        <rect x="0" y="60" rx="5" ry="5" width="50" height="50" />
                        <rect x="70" y="74" rx="4" ry="4" width="300" height="8" />
                        <rect x="70" y="90" rx="3" ry="3" width="250" height="6" />
                    </ContentLoader>
                    </div>
                </section>
            )
        }
        const feature = this.state.feature;
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
        if(this.state.isLoading == false){
            if(this.state.blogsFeature.length>0){
            return(
                this.state.blogsFeature.length > 2 ?
                <section className = "blog py-2">
                    <div className="container">
                        {<h5 className = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>}
                        <div className="row justify-content-center py-3">

                            {
                                this.state.blogsFeature ?
                                this.state.blogsFeature.map((feature,index)=>{
                                        let descriptionString = feature.description != null ? this.extractContent(feature.description) : ""
                                        // if(feature.isActive == 1){

                                            var hrefLink =`/blog/${feature.title}/${feature.id}`.replaceAll(' ','-');
                                            var description = descriptionString.length > 250 ? feature.description.substr(0,200) : feature.description
                                            let image = feature.image != null ? feature.image : '/images/image-placeholder.png'
                                            let readMoreVisibility = descriptionString.length >= 180 ? "block" : "none"
                                            let isLocalUrl = true
                                            if(feature.isEnable == 1){
                                                let link = feature.button_link
                                                if (!/^(?:f|ht)tps?\:\/\//.test(link)) {
                                                    link = "http://" + link;
                                                }
                                                let url = new URL(link)
                                                if(url.host.indexOf('preview') > -1 || url.hostname == "localhost"){
                                                    isLocalUrl = true
                                                    let split = url.pathname.split("/")
                                                    if(split.length > 2){
                                                        let path = ""
                                                        for(var i=2;i<split.length;i++){
                                                            path += split[i]
                                                            if(i<split.length-1){
                                                                path += "/"
                                                            }
                                                        }
                                                        hrefLink = "/" + this.props.event.alias + "/" + path
                                                    }
                                                }else{
                                                    isLocalUrl = false
                                                    hrefLink = link
                                                }
                                            }
                                            let heightClass = isMobile ? "" : "h-100"
                                            return (

                                                <div style = {{marginTop : !isMobile && index > 0 && index > 2 ? 15 : 0}} className="col-md-4 mb-3 mb-md-0" key={index}>
                                                    <div className={"thumbnail "+heightClass}>
                                                        {
                                                            <div style = {{
                                                                backgroundImage: `url(${image})`,
                                                                height: 200,
                                                                backgroundSize: "cover",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundPosition: "center"
                                                            }}>
                                                                
                                                            </div>
                                                        }
                                                        <div className="caption">
                                                          {this.state.blogsFeature.length >0 ?  <h6 className = "f-600 text-main my-3">
                                                                {feature.title}
                                                            </h6>:null}
                                                            {/* ReactHtmlParser(description) */}
                                                            <p style = {{marginBottom : descriptionString.length < 180 ? 0 : ''}} className = {descriptionString.length >= 180 ? "blogEffect" : ""}>{ descriptionString.substr(0,180)}</p>
                                                            {/* <Linkify componentDecorator={componentDecorator}><p className = {descriptionString.length >= 250 ? "blogEffect" : ""}>{ descriptionString.substr(0,250)}</p></Linkify> */}
                                                            {/* <span onClick = {()=>this.props.onClickReadMore(index)} style = {{cursor : 'pointer',position : 'absolute',bottom : 12,width : '90%'}} className = "btn bg_primary d-block mx-auto rounded-0"> */}
                                                            {isLocalUrl ?
                                                            <Link onClick = {this.OnClickReadMore} to = {hrefLink} style = {{display : readMoreVisibility}} className = "btn mx-auto rounded-0 readMore">
                                                                <Trans>readMore</Trans>
                                                            </Link> : 
                                                            <a href = {hrefLink} target = "_blank" style = {{display : readMoreVisibility}} className = "btn mx-auto rounded-0 readMore">
                                                                <Trans>readMore</Trans>
                                                            </a>}
                                                            {/* </span> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        // }

                                    }) : null
                            }


                        </div>
                    </div>
                    <div className="row py-2 justify-content-center">
							{this.state.blogsFeature.length>2?<a 
                            onClick = {()=>{this.OnNavigateToPage(this.props.event.alias+'/blogs')}} 
                            style = {{cursor : 'pointer'}} className = "btn-default see_btn">
								<Trans>seeAll</Trans></a>:null}
						</div>
                </section> : 
                <section class = "blog py-2">
                    <div class="container blog-container">
                  {this.state.blogsFeature.length > 0 ?  <h5 className = "text-center font-weight-bold text-uppercase pb-3">{this.state.title}</h5>:null}
                    {this.state.blogsFeature.map((item,index)=>{
                        let image = item.image != null ? item.image : '/images/image-placeholder.png'
                        let dynamicClass = index == 0 ? "bg_primary" : "bg_secondary"
                        let description = item.description.substr(0,420)
                        let hrefLink = `/blog/${item.title}/${item.id}`.replaceAll(' ','-');
                        let textClass = index == 0 ? "" : "blogColor"
                        let readMoreVisibility = description.length >= 420 ? "" : "none"
                        let paddingTextRight = index == 0 ? "px-3" : "px-4"
                        let isLocalUrl = true
                        if(item.isEnable == 1){
                            let link = item.button_link
                            if (!/^(?:f|ht)tps?\:\/\//.test(link)) {
                                link = "http://" + link;
                            }
                            let url = new URL(link)
                            if(url.host.indexOf('preview') > -1 || url.hostname == "localhost"){
                                isLocalUrl = true
                                let split = url.pathname.split("/")
                                if(split.length > 2){
                                    let path = ""
                                    for(var i=2;i<split.length;i++){
                                        path += split[i]
                                        if(i<split.length-1){
                                            path += "/"
                                        }
                                    }
                                    hrefLink = "/" + this.props.event.alias + "/" + path
                                }
                            }else{
                                isLocalUrl = false
                                hrefLink = link
                            }
                        }
                        return(
                            <div class={"row blog-card-vII overflow-hidden "+dynamicClass} id = "blogcard-I">
                            {index == 0 ? 
                                <div class = "col-sm-6 pl-md-5 py-5">
                                    <div style = {{backgroundImage: `url(${image})`}} class = "blog-bg-img">
                                        
                                    </div>
                                </div> : null}
                            
                                <div class = "col-sm-6 pr-md-4">
                                    <div class = {"blog-text py-5 "+paddingTextRight}>
                                        <h4 class = "f-600" class = {textClass}>
                                            {item.title}
                                        </h4>
                                        <p class = {textClass}>
                                            {ReactHtmlParser(description)}
                                        </p>
                                        {isLocalUrl ?
                                        <Link onClick = {this.OnClickReadMore} to = {hrefLink} style = {{display : readMoreVisibility}} class = {index == 0 ? "text-light" : "blogColorLink"} >
                                            <Trans>readMore</Trans>
                                        </Link> : 
                                        <a href = {hrefLink} target = "_blank" style = {{display : readMoreVisibility}} class = {index == 0 ? "text-light" : "blogColorLink"} >
                                            <Trans>readMore</Trans>
                                        </a>}
                                    
                                    </div>
                                </div>
                                {index == 1 ? 
                                <div class = "col-sm-6 pr-md-5 py-5">
                                    <div style = {{backgroundImage: `url(${image})`}} class = "blog-bg-img">
                                        
                                    </div>
                                </div> : null}
                            </div>
                        )
                    })}
                    
                    </div>
                    <div className="row py-2 justify-content-center">
							{this.state.blogsFeature.length>2 ? 
                                <a 
                             onClick = {()=>{this.OnNavigateToPage(this.props.event.alias+'/blogs')}}
                            style = {{cursor : 'pointer'}} className = "btn-default see_btn">
								<Trans>seeAll</Trans></a>:null}
						</div>
                </section>
                
            );
                            }
                            else{
                                return null
                            }

        }else{
            return null
        }//end of flage if
    }
}

const mapStateToProps = state => ({
    blogs: state.blog.blogData,
    Features:state.appData.allFeature,
    featureLoading:state.appData.featureLoading
})
const mapDispatchToProps = dispatch => ({
    blogsLoaded : data => dispatch(BlogsLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EventBlog);