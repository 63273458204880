import axios from '../axios/index'
import { Constants } from '../../Constants';
var fileDownload = require('js-file-download');

export default {
    sendRegisterInterestUsers(eventAlias,data,callback){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            }
        }

        axios.post(`${eventAlias}/sendRegisterInterestUsers`, data, config)
            .then(response => {
                callback(null,response.data)
            }).catch(error => {
            callback(error,null)
        })



    },
    sendAgandaDownload(eventAlias,data,callback){

        const requestBody = {
            firstName: data.firstName,
            email: data.email,
            lastName: data.lastName,
            companyName: data.companyName,
            jobTitle: data.jobTitle,
            mobileNumber: data.mobileNumber
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            }
        }

        axios.post(`${eventAlias}/sendAgandaDownload`, requestBody, config)
            .then(response => {
                this.getEventAgendaPDF(eventAlias,(err,res)=>{
                    callback(null,{
                        agendaDownloaded : true
                    })
                    fileDownload(res.data, 'agenda.pdf')
                });
                callback(null,response.data)
            }).catch(error => {
            callback(error,null)
        })

    },
    getEventAgendaPDF(eventAlias,callback){
        axios.get(`${eventAlias}/getAgandaPdf`,
            {
                timeout : 120000,
                responseType: 'arraybuffer',
                params: {isActive:1,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            callback(null,response)
        }).catch(error => {
            callback(error,null)
        })
    }
}