import React from 'react'
import { withRouter } from 'react-router-dom'
import io from "socket.io-client";
import Peer from "simple-peer";
import $,{data} from 'jquery'
import { connect } from 'react-redux'
import {compose} from 'redux'
import utility, { monthNamesShort } from '../../../Utility/utility';
import Header from '../../Header/Index';
import VirtualZone from '../../Header/virtualZone';
import { Helmet } from 'react-helmet';
import { Constants } from '../../../Constants';
import appLevelApi from '../../App/api'
import api from './api'
import participantApi from '../Participants/api'
import TextChat from './textChat';
import { IncomingCallPopup } from './incomingCallModal';
import VideoCall from './videoCall';
import { CallingPopup } from './callingModal';
import {Modal,Button} from 'react-bootstrap'
import { GAPageView } from '../../../Utility/GA';
import  PermiumUserModal  from "../../modals/premiumUserModal";
import { UpdateChatList } from './actions';
export class UserChat extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            chatParticipantsList : [],
            conversations : {},
            currentConvertation : [],
            chatMessage : '',
            isUpdatingChatList : props.loadingList,
            isLoadingSingleChat : false,
            receivingCall : false,
            caller : null,
            callerSignal : null,
            receivingPeer : null,
            callingUser : false,
            callAccepted : false,
            selectedReciverId : '',
            particpantSearchText : '',
            callDuration : '',
            callMuted : false,
            partnerMuted : false,
            videoDisabled : false,
            partnerVideoDisable : false,
            showErrorPopup : false,
            popUpErrorHeading : '',
            popUpErrorMessage : '',
            showCloseCallButton : false,
            virtualZoneItemIndex : this.props.virtualMenuItems.findIndex(x=>x.path == '/userChat'),
            showChatInVideo : false,
            participants : this.props.participants,
            searchingParticipants : false,
            chatAvailabaility:'',
            duration:'',
            senderId:'',
            avalibility:'',
            pageNumber:2,
        loading:false,
        allUserLoaded:false
        }
        this.socket = this.props.socket
        this.messageReceiverSocketId = ''
        this.chatAvailabaility = ''
        this.duration = ''
        this.receiverSocketIdCall = ''
        this.receiverName = ''
        this.companyName = ''
        this.jobTitle = ''
        this.profileBio = ''
        this.twitterProfile = null
        this.facebookProfile = null
        this.instagramProfile = null
        this.linkedInProfile = null
        this.mySocketId = this.props.mySocketId
        this.selectedParticipantProfileImageUrl = ""
        this.isSelectedParticipantOnline = false
        this._isMounted = false
        this.callHours = 0
        this.callMinutes = 0
        this.callSeconds = 0
        this.stream = null
        this.tempReloadCheck = {}
        this.sessionExpired = false
        this.tempReceiverSocketId = ""
        this.tempMessageData = []
        this.isInitiator = false
        this.callInProgress = false
        this.participantsLoaded = !props.loadingParticipants
        this.pageNo = 2
        this.userType = ''

    }
    async componentDidMount () {

        let chatElement = document.getElementById("chatList")
        let searchBox = document.getElementById("list_input")
        console.log(chatElement)
        // if(chatElement != null){
           
        chatElement.addEventListener("scroll",()=>{
            // if(this.state.allUserLoaded == false){
            console.log(chatElement.scrollTop + chatElement.offsetHeight)
            if(searchBox && searchBox.value.length == 0){
            if(this.state.allUserLoaded == false){
            if((chatElement.scrollTop + chatElement.offsetHeight)+1 >= chatElement.scrollHeight && this.state.loading == false){
                this.setState({loading:true})
                this.loadMoreParticipent("",null,this.state.pageNumber,(data)=>{
                    this.setState({pageNumber:this.state.pageNumber+1,loading:false,participants:[...this.state.participants,...data]})
                    console.log(data)
                    if(data.length < 10){
                        this.setState({allUserLoaded:true})
                    }
                })
            }
    }}
    })

        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=userChat')
        fetch(`/${this.props.event.alias}/userChat`)
        this._isMounted = true
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        document.addEventListener('sessionExpired',(event)=>{
            this.sessionExpired = true
        })
        $(document).on('submit', '#chat-form',(event)=>{
            event.preventDefault();
            this.OnSendMessage()
        });
        this.user = utility.getLoggedInUser(this.props.event.alias)
        if(this.user == null){
            this.OnNavigateToPage(this.props.event.alias+'/login')
            return
        }else{
            if(!this.user.isBooking){
                this.OnNavigateToPage('/')
                return
            }
        }
        // if(this.socket == null){
        //     let socketURL =  Constants.socketUrl
        //     socketURL += "?userId="+this.user.id+"&userName="+(this.user.firstName+" "+this.user.lastName)+"&eventAlias="+this.props.event.alias
        //     this.socket = io.connect(socketURL,{forceNew : true,transports: ["polling"]})
        // }
        document.addEventListener('chatListLoaded',this.handleOnChatListLoadedFromApi)
        document.addEventListener('participantsLoaded',this.handleOnParticipantsLoadedFromApi)
        document.addEventListener('chatListLoadingStarted',this.handleOnChatListLoadingStarted)
        let chatParticipantsList = []
        await this.initializeChatList(this.props.userChatList,(list)=>{
            chatParticipantsList = list
        })
        let conversations = this.state.conversations
        for(var i=0;i<chatParticipantsList.length;i++){
            if(chatParticipantsList[i].id != id){
                conversations[chatParticipantsList[i].id] = []
            }
        }
        this.setState({conversations,chatParticipantsList})
        var data = this.props.location.state
        if(data != null){
            this.messageReceiverSocketId = data.socketId
            var id = data.id
            if(this.props.receivedMessagesWhileAway.length == 0){
                this.setDefaultChat(id,chatParticipantsList)
            }
            window.history.replaceState(null, '')
        }
        let unReadCount = 0
        for(var i=0;i<this.props.receivedMessagesWhileAway.length;i++){
            unReadCount += this.props.receivedMessagesWhileAway[i].count
        }
        if(unReadCount > 0){
            this.setState({isUpdatingChatList : true})
            appLevelApi.getUserChatList(this.props.event.alias,this.user.token,async (userList)=>{
                for(var i=0;i<userList.length;i++)
                {
                    let lastMessage = userList[i].lastMessage 
                    let unReadCountIndex = this.props.receivedMessagesWhileAway.findIndex(x=>x.id == userList[i].id)
                    let index = chatParticipantsList.findIndex(x=>x.id == userList[i].id)
                    if(index < 0){
                        index = this.props.participants.findIndex(x=>x.id == userList[i].id)
                        let participant = null
                        if(index > -1){
                            participant = this.props.participants[index]
                        }else{
                            await this.searchParticipants((item)=>{
                                if(item.length > 0){
                                    participant = item[0]
                                }
                            },'',[userList[i].id])
                        }
                        if(participant == null){
                            continue
                        }
                        this.receiverName = participant.firstName + " " + participant.lastName
                        this.companyName = participant.companyName
                        this.jobTitle = participant.jobTitle
                        this.profileBio = participant.profileBio
                        this.twitterProfile = participant.twitterProfileLink
                        this.facebookProfile = participant.facebookProfileLink
                        this.instagramProfile = participant.instagramProfileLink
                        this.linkedInProfile = participant.linkedinProfileLink
                        this.selectedParticipantProfileImageUrl = participant.profileImageUrl
                        this.userType = this.props.event.is_jobfair == 1 ? participant.userType : ''
                        this.isSelectedParticipantOnline = participant.isOnline
                        participant.message = lastMessage.length > 80 ? (lastMessage.substring(0,80) + " ...") : lastMessage
                        participant.time = this.getTimeFormat(userList[i].lastTime,true)
                        if(unReadCountIndex > -1){
                            participant.unReadMessagesCount = this.props.receivedMessagesWhileAway[unReadCountIndex].count
                        }
                        chatParticipantsList.push(participant)
                        participant.localTime = true
                        this.updateChatList(participant)
                        let conversations = this.state.conversations
                        conversations[userList[i].id] = []
                        this.setState({conversations})
                    }else{
                        if(unReadCountIndex > -1){
                            chatParticipantsList[index].localTime = true
                            chatParticipantsList[index].unReadMessagesCount = this.props.receivedMessagesWhileAway[unReadCountIndex].count + chatParticipantsList[index].unReadMessagesCount
                            chatParticipantsList[index].message = lastMessage.length > 80 ? (lastMessage.substring(0,80) + " ...") : lastMessage
                            chatParticipantsList[index].time = this.getTimeFormat(userList[i].lastTime,true)
                            this.updateChatList(chatParticipantsList[index])
                        }
                    }
                    chatParticipantsList.sort((a,b)=>{
                        return b.unReadMessagesCount - a.unReadMessagesCount
                    })
                }
                let totalUnReadMessageCount = 0
                for(var i=0;i<chatParticipantsList.length;i++){
                    totalUnReadMessageCount += chatParticipantsList[i].unReadMessagesCount
                }
                if(totalUnReadMessageCount == 0){
                    this.props.onResetUnReadCount()
                }
                if(id != null){
                    this.setDefaultChat(id,chatParticipantsList,true)
                }else{
                    this.setState({chatParticipantsList,isUpdatingChatList : false})
                }
            })
        }
        if(this.socket == null){
            return
        }
        this.initializeSocketListeners()
        document.addEventListener('usersConnected',this.handleOnUsersConnected)
        document.addEventListener('userDisconnected',this.handleOnUserDisconnected)
        let chatInput = document.getElementById("chatInput")
        if(chatInput != null){
            chatInput.addEventListener("keypress",this.TextAreaSubmitListener);
        }
    }
    componentWillUnmount(){
        this._isMounted = false
        document.removeEventListener('chatListLoaded',this.handleOnChatListLoadedFromApi)
        document.removeEventListener('participantsLoaded',this.handleOnParticipantsLoadedFromApi)
        document.removeEventListener('usersConnected',this.handleOnUsersConnected)
        document.removeEventListener('userDisconnected',this.handleOnUserDisconnected)
        document.removeEventListener('chatListLoadingStarted',this.handleOnChatListLoadingStarted)
    }
    getInvisibleUser = (id,visible)=>{
        console.log(id,visible)
        this.setState({senderId:id,avalibility:visible})

    }
    initializeChatList = async (list,callback) => {
        let chatParticipantsList = []
        let participants = this.props.participants
        let currentDate = new Date()
        let voidParticipantsCount = 0
        let participantDataFetched = 0
        for(var i=0;i<list.length;i++){
            let index = participants.findIndex(x=>x.id == list[i].id)
            let participant = null
            if(index > -1){
                participant = participants[index]
            }else{
                voidParticipantsCount += 1
                if(!this.state.isUpdatingChatList){
                    this.setState({isUpdatingChatList : true})
                }
                await this.searchParticipants((item)=>{
                    participantDataFetched += 1
                    if(item.length > 0){
                        participant = item[0]
                    }
                    if(participantDataFetched == voidParticipantsCount){
                        this.setState({isUpdatingChatList : false})
                    }
                },'',[list[i].id])
            }
            if(participant == null){
                continue
            }
            var chatParticipant = participant
            let lastMessage = list[i].lastMessage
            let type = list[i].type
            if(lastMessage == null){
                lastMessage = ""
            }
            chatParticipant.messageType = list[i].type
            chatParticipant.message = lastMessage.length > 80 ? (lastMessage.substring(0,80) + " ...") : lastMessage
            if(type == "callLog"){
                chatParticipant.message = "Call ended - "+chatParticipant.message
            }
            if(chatParticipant.timeObj == null){
                if(list[i].localTime){
                    chatParticipant.timeObj = new Date(list[i].lastTime)
                }else{
                    chatParticipant.timeObj = utility.calculateZoneSpecificTime(list[i].lastTime)
                }
            }
            let dateString = ""
            let timeString = ""
            if((chatParticipant.timeObj.getDate() == currentDate.getDate() || chatParticipant.timeObj.getDate() == currentDate.getDate()-1) && chatParticipant.timeObj.getMonth() == currentDate.getMonth() && chatParticipant.timeObj.getFullYear() == currentDate.getFullYear()){
                if(chatParticipant.timeObj.getDate() == currentDate.getDate() - 1){
                    dateString = "Yesterday"
                }
                // timeString = " "+this.getTimeFormat(list[i].lastTime,list[i].localTime ? false : true,false,!list[i].localTime)
                timeString = " "+this.getTimeFormat(list[i].lastTime,false,false,!list[i].localTime)
            }
            else{
                let month = chatParticipant.timeObj.getMonth()+1
                if(month < 10){
                    month = "0"+month
                }
                dateString = chatParticipant.timeObj.getDate()+"/"+month+"/"+chatParticipant.timeObj.getFullYear().toString()
            }
            chatParticipant.time = dateString + timeString
            chatParticipantsList.push(chatParticipant)
        }
        for(var i=0;i<chatParticipantsList.length;i++){
            if(chatParticipantsList[i].unReadMessagesCount == null){
                chatParticipantsList[i].unReadMessagesCount = 0
            }
        }
        for(var i=0;i<this.props.unReadMessages.length;i++){
            let index = chatParticipantsList.findIndex(x=>x.id == this.props.unReadMessages[i].userId)
            if(index > -1){
                chatParticipantsList[index].unReadMessagesCount = this.props.unReadMessages[i].count
            }
        }
        chatParticipantsList.sort((a,b)=>{
            return b.timeObj - a.timeObj
        })
        chatParticipantsList.sort((a,b)=>{
            return b.unReadMessagesCount - a.unReadMessagesCount
        })
        callback(chatParticipantsList)
    }
    initializeSocketListeners = () => {
        this.socket.on('messageReceived',(data)=>{
            let chatParticipantsList = this.state.chatParticipantsList
            if(!this._isMounted){
                console.log('Cannot updated')
                return
            }
            if(this.state.selectedReciverId == data.senderId && data.id != null){
                let currentConversation = this.state.conversations[data.senderId]
                let currentDayChatIndex = currentConversation.findIndex(x=>x.date == "Today")
                let index = currentConversation[currentDayChatIndex].chat.findIndex(x=>x.messageId == data.messageId)
                currentConversation[currentDayChatIndex].chat[index].id = data.id
                this.markMessageRead(-1,currentConversation,null,null)
                return
            }
            if(data.id != null){
                return
            }
            if(data.eventAlias != this.props.event.alias){
                return
            }
            let lastTime = data.time
            data.time = this.getTimeFormat(data.time,false,false,false)
            var conversations = this.state.conversations
            let index = chatParticipantsList.findIndex(x=>x.id == data.senderId)
            if(index < 0){
                let pIndex = this.props.participants.findIndex(x=>x.id == data.senderId)
                let participant = null
                if(pIndex > -1){
                    participant = this.props.participants[pIndex]
                }else{
                    participant = {
                        id : data.senderId,
                        firstName : data.senderName.split(' ')[0],
                        lastName : data.senderName.split(' ')[1],
                        profileImageUrl : data.senderProfileImageUrl,
                        isOnline : true
                    }
                }
                this.receiverName = participant.firstName + " " + participant.lastName
                this.jobTitle = participant.jobTitle
                this.companyName = participant.companyName
                this.profileBio = participant.profileBio
                this.twitterProfile = participant.twitterProfileLink
                        this.facebookProfile = participant.facebookProfileLink
                        this.instagramProfile = participant.instagramProfileLink
                        this.linkedInProfile = participant.linkedinProfileLink
                this.selectedParticipantProfileImageUrl = participant.profileImageUrl
                this.userType = this.props.event.is_jobfair == 1 ? participant.userType : ''
                this.isSelectedParticipantOnline = participant.isOnline
                participant.unReadMessagesCount = 1
                participant.message = data.message.length > 80 ? (data.message.substring(0,80) + " ...") : data.message
                participant.lastTime = lastTime
                participant.time = data.time
                participant.localTime = true
                participant.chatAvailabaility = data.chatAvailabaility
                participant.chatAvailabailityDuration = data.chatAvailabailityDuration
                participant.to = data.unvisbileSenderId
                chatParticipantsList.push(participant)
                conversations[data.senderId] = []
                let chatObj = {
                    date : data.dateString,
                    chat : [data]
                }
                conversations[data.senderId].push(chatObj)
                this.updateChatList(participant,1)
            }else{
                if(chatParticipantsList[index].unReadMessagesCount == null){
                    chatParticipantsList[index].unReadMessagesCount = 0
                }
                chatParticipantsList[index].message = data.message.length > 80 ? (data.message.substring(0,80) + " ...") : data.message
                chatParticipantsList[index].localTime = true
                chatParticipantsList[index].lastTime = lastTime
                chatParticipantsList[index].time = data.time
                if(data.type == "callLog"){
                    chatParticipantsList[index].message = "Call ended - "+chatParticipantsList[index].message
                }
                if(conversations[data.senderId].length == 0){
                    this.tempReloadCheck[data.senderId] = true
                }
                let currentConversation = conversations[data.senderId]
                let conversationIndex = currentConversation.findIndex(x=>x.date == data.dateString)
                if(conversationIndex < 0){
                    let chatObj = {
                        date : data.dateString,
                        chat : [data]
                    }
                    currentConversation.push(chatObj)
                }else{
                    currentConversation[conversationIndex].chat.push(data)
                }
                conversations[data.senderId] = currentConversation
                if(this.state.selectedReciverId != data.senderId){
                    chatParticipantsList[index].unReadMessagesCount += 1
                    this.updateChatList(chatParticipantsList[index],chatParticipantsList[index].unReadMessagesCount)
                }else{
                    this.setState({currentConvertation : conversations[data.senderId]})
                    this.updateChatList(chatParticipantsList[index],chatParticipantsList[index].unReadMessagesCount)
                }
            }
            chatParticipantsList.sort((a,b)=>{
                return b.unReadMessagesCount - a.unReadMessagesCount
            })
            data.me = false
            this.setState({conversations,chatParticipantsList},()=>{
                let chatBody = document.getElementById("chat-inner")
                if(chatBody != null){
                    chatBody.scrollTop = chatBody.scrollHeight;
                }
            })
        })
        this.socket.on('hey',(data)=>{
            this.tempReceiverSocketId = this.messageReceiverSocketId
            this.messageReceiverSocketId = data.from
            let caller = this.props.participants.find(x=>x.socketId == data.from)
            if(caller == null){
                caller = data.caller
            }
            let index = this.state.chatParticipantsList.findIndex(x=>x.id == caller.id)
            if(data.signal != null){
                this.setState({callerSignal : data.signal,caller,selectedReciverId : data.receiverId},()=>{
                    this.OnClickChatParticipant(caller.id,data.from,caller.firstName+" "+caller.lastName,caller.profileImageUrl,true,index,'')
                    if(this.state.callAccepted){
                        this.videoCallRef.startStream()
                    }
                })
            }else{
                if(!this.state.callAccepted){
                    this.setState({receivingCall : true,caller : caller},()=>{
                        $("#incomingCall").modal('show');
                    })
                }
            }
        })
        this.socket.on('callEnded',()=>{
            this.callInProgress = false
            if(this.stream != null){
                this.stream.getTracks().forEach(function(track) {
                    if (track.readyState == 'live') {
                        track.stop();
                    }
                });
            }
            this.isInitiator = false
            document.getElementById("videoChatInput").removeEventListener("keypress",this.TextAreaSubmitListener);
            this.setState({callAccepted : false,callingUser : false,caller : null,callerSignal : null},()=>{
                document.getElementById("chatInput").addEventListener("keypress",this.TextAreaSubmitListener);
            })
            this.socket.removeAllListeners("callAccepted")
            clearInterval(this.callTimer)
        })
        this.socket.on('callDeclined',()=>{
            if(this.tempReceiverSocketId != ""){
                this.messageReceiverSocketId = this.tempReceiverSocketId
            }
            this.callInProgress = false
            this.tempReceiverSocketId = ""
            if(this.state.receivingCall){
                this.setState({receivingCall : false},()=>{
                    $('#incomingCall').modal('hide');
                    $('.modal-backdrop').remove();
                })
            }else{
                this.setState({callingUser : false},()=>{
                    $('#callingUser').modal('hide');
                    $('.modal-backdrop').remove();
                })
            }
        })
        this.socket.on('callMuted',(data)=>{
            this.setState({partnerMuted : data.muted})
        })
        this.socket.on('videoDisabled',(data)=>{
            this.setState({partnerVideoDisable : data.disabled})
        })
    }
    handleOnChatListLoadingStarted = () => {
        this.setState({isUpdatingChatList : true})
    }
    handleOnChatListLoadedFromApi =async (e) => {
        if(this.participantsLoaded){
            let chatParticipantsList = []
            await this.initializeChatList(e.detail.chatList,(list)=>{
                chatParticipantsList = list
            })
            let conversations = this.state.conversations
            for(var i=0;i<chatParticipantsList.length;i++){
                conversations[chatParticipantsList[i].id] = []
            }
            this.setState({conversations,chatParticipantsList ,isUpdatingChatList : false})
            document.getElementById("chatInput").addEventListener("keypress",this.TextAreaSubmitListener);
        }else{
            this.tempChatList = e.detail.chatList
        }
    }
    handleOnParticipantsLoadedFromApi = async (e) => {
        let participants = e.detail.participants
        for(var i=0;i<this.props.onlineParicipants.length;i++){
            let index = participants.findIndex(x=>x.id == this.props.onlineParicipants[i].userId)
            if(index > -1){
                participants[index].isOnline = true
                participants[index].socketId = this.props.onlineParicipants[i].socketId
            }
        }
        this.setState({participants})
        if(this.tempChatList != null){
            let chatParticipantsList = []
            await this.initializeChatList(this.tempChatList,(list)=>{
                chatParticipantsList = list
            })
            let conversations = this.state.conversations
            for(var i=0;i<chatParticipantsList.length;i++){
                conversations[chatParticipantsList[i].id] = []
            }
            this.setState({conversations,chatParticipantsList ,isUpdatingChatList : false})
            this.tempChatList = null
            document.getElementById("chatInput").addEventListener("keypress",this.TextAreaSubmitListener);
        }
        this.participantsLoaded = true
    }
    handleOnUsersConnected = (e) => {
        let index = e.detail.users.findIndex(x=>x.userId == this.state.selectedReciverId)
        if(index > -1){
            let selectedUser = e.detail.users[index]
            this.messageReceiverSocketId = selectedUser.socketId
        }
    }
    handleOnUserDisconnected = (e) => {

    }
    updateChatList = (item,unReadCount = 0) => {
        let userChatList = this.props.userChatList
        let index = userChatList.findIndex(x=>x.id == item.id)
        if(index < 0){
            item.lastMessage = item.message
            if(item.lastTime != null){
                item.lastTime = utility.calculateZoneSpecificTime(item.lastTime)
            }else{
                item.lastTime = new Date()
            }
            userChatList.push(item)
        }else{
            userChatList[index] = item
            userChatList[index].lastMessage = item.message
            if(userChatList[index].lastTime != null){
                userChatList[index].lastTime = utility.calculateZoneSpecificTime(userChatList[index].lastTime)
            }
            else{
                userChatList[index].lastTime = new Date()
            }
        }
        this.props.onUpdateChatList(item,unReadCount)
        this.props.updateChatList(userChatList)  // updating the list in store
    }
    TextAreaSubmitListener = (event) => {
        if(event.which === 13 && !event.shiftKey){
            this.OnSendMessage()
            event.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
        }
    }
    setDefaultChat = async (id,chatParticipantsList,isUpdatingChatList = false) => {
        console.log(chatParticipantsList)
        this.setState({selectedReciverId : id})
        var index = chatParticipantsList.findIndex(x=>x.id == id)
        this.selectedChatListIndex = index
        let conversations = this.state.conversations
        if(conversations[id] == null){
            conversations[id] = []
        }
        if(index > -1){
            this.receiverName = chatParticipantsList[index].firstName + " " + chatParticipantsList[index].lastName
            this.companyName = chatParticipantsList[index].companyName
            this.jobTitle = chatParticipantsList[index].jobTitle
            this.profileBio = chatParticipantsList[index].profileBio
            this.twitterProfile = chatParticipantsList[index].twitterProfileLink
                        this.facebookProfile = chatParticipantsList[index].facebookProfileLink
                        this.instagramProfile = chatParticipantsList[index].instagramProfileLink
                        this.linkedInProfile = chatParticipantsList[index].linkedinProfileLink
            this.selectedParticipantProfileImageUrl = chatParticipantsList[index].profileImageUrl
            this.userType =  chatParticipantsList[index].userType 
            this.isSelectedParticipantOnline = chatParticipantsList[index].isOnline
            let currentConvertation = conversations[id]
            let chatUnReadCountTemp = chatParticipantsList[index].unReadMessagesCount
            chatParticipantsList[index].unReadMessagesCount = 0
            this.setState({currentConvertation})
            await this.getUserChat(id)
            this.markMessageRead(index,currentConvertation,chatUnReadCountTemp,id)
        }else{
            index = this.props.participants.findIndex(x=>x.id == id)
            let participant = this.props.participants[index]
            if(participant == null){
                await this.searchParticipants((item)=>{
                    if(item.length > 0){
                        participant = item[0]
                    }
                },'',[id])
            }
            participant.timeObj = new Date(1964,1,23)
            this.receiverName = participant.firstName + " " + participant.lastName
            this.companyName = participant.companyName
            this.jobTitle = participant.jobTitle
            this.profileBio = participant.profileBio
            this.twitterProfile = participant.twitterProfileLink
                        this.facebookProfile = participant.facebookProfileLink
                        this.instagramProfile = participant.instagramProfileLink
                        this.linkedInProfile = participant.linkedinProfileLink
            this.selectedParticipantProfileImageUrl = participant.profileImageUrl
            this.userType = this.props.event.is_jobfair == 1 ? participant.userType : ''
            this.isSelectedParticipantOnline = participant.isOnline
            chatParticipantsList.push(participant)
        }
        if(isUpdatingChatList){
            this.setState({isUpdatingChatList : false})
        }
        this.setState({chatParticipantsList,conversations})
    }
    SaveChatMessage = (data) => {
        this.tempMessageData.push(data)
        api.saveUserChat(this.props.event.alias,this.user.token,data,(err,messageId,dbId)=>{
            if(err == null){
                let index = this.tempMessageData.findIndex(x=>x.messageId == messageId)
                let allMessages = []
                for(var i=0;i<this.state.currentConvertation.length;i++){
                    allMessages = [...allMessages,...this.state.currentConvertation[i].chat]
                }
                let message = allMessages.find(x=>x.messageId == this.tempMessageData[index].messageId)
                message.id = dbId
                this.tempMessageData.splice(index,1)
                this.socket.emit('messageSent',message)
            }else{
                if(err == -1){
                    this.sessionExpired = true
                    this.setState({showErrorPopup : true,popUpErrorHeading : 'Login Required',popUpErrorMessage : 'Your session is expired please login again'})
                    // chatSessionDialog
                    // $("#chatSessionDialog").modal('show');
                    // $("#chatSessionDialog").data('bs.modal').options.backdrop = 'static';
                    $('#chatSessionDialog').modal({
                        backdrop: 'static',
                        keyboard: false,
                        show:true
                    })
                 $("#chatSessionDialog").data('bs.modal').options.backdrop = 'static';
                // document.getElementById("chatSessionDialog").setAttribute("data-backdrop","static")
                
                    localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
                }
            }
        })
    }
    OnNavigateToPage = (path,data = null) =>{
        if(this.state.callAccepted){
            alert('Call in process')
            return
        }
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            this.props.history.push(path)
        }else{
            this.props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    languageClick = (value) =>{
        console.log("Trigger chat on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(this.state.callAccepted){
            alert('Call is in process')
            return
        }
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnClickChatParticipant = async (id,socketId,name,profileImageUrl,isOnline,index = -1,chatAvailabaility,duration,userType,title,bio,companyName,twitter=null,facebook=null,instagram=null,linkedIn = null) => {
        if(this.state.isLoadingSingleChat){
            return
        }
        this.messageReceiverSocketId = socketId
        this.receiverName = name
        this.companyName = companyName
        this.chatAvailabaility = chatAvailabaility
        this.duration = duration
        this.selectedParticipantProfileImageUrl = profileImageUrl
        this.userType = userType
        this.jobTitle = title
        this.profileBio = bio
        this.twitterProfile = twitter
                        this.facebookProfile = facebook
                        this.instagramProfile = instagram
                        this.linkedInProfile = linkedIn
        this.isSelectedParticipantOnline = isOnline
        let conversations = this.state.conversations
        let chatParticipantsList = this.state.chatParticipantsList
        let currentConvertation = null
        this.setState({selectedReciverId : id})
        this.selectedChatListIndex = index
        if(index > -1){
            currentConvertation = conversations[id] || []
            let chatUnReadCountTemp = chatParticipantsList[index].unReadMessagesCount
            chatParticipantsList[index].unReadMessagesCount = 0
            if(currentConvertation == 0 || this.tempReloadCheck[id]){
                await this.getUserChat(id)
                this.markMessageRead(index,this.state.currentConvertation,chatUnReadCountTemp,id)
            }else{
                this.props.markChatRead(chatUnReadCountTemp,id)
                this.markMessageRead(index,this.state.currentConvertation,chatUnReadCountTemp,id)
                this.setState({currentConvertation},()=>{
                    let chatBody = document.getElementById("chat-inner")
                    chatBody.scrollTop = chatBody.scrollHeight;
                })
            }
        }
        else{
            let pListIndex = chatParticipantsList.findIndex(x=>x.id == id)
            if(pListIndex < 0){
                conversations[id] = []
                index = this.state.participants.findIndex(x=>x.id == id)
                let chatListItem = this.state.participants[index]
                chatListItem.timeObj = new Date(1964,1,23)
                chatParticipantsList.push(chatListItem)
                currentConvertation = []
                this.setState({currentConvertation : currentConvertation,chatParticipantsList,conversations},()=>{
                    let chatBody = document.getElementById("chat-inner")
                    chatBody.scrollTop = chatBody.scrollHeight;
                })
            }
        }
        let chatInput = document.getElementById("chatInput")
        if(chatInput != null){
            chatInput.focus();
        }
        $('#chat-list-modal').modal('hide');
        $('.modal-backdrop').remove();
    }
    getUserChat = async (id) => {
        this.setState({isLoadingSingleChat : true})
        let conversations = this.state.conversations
        await api.getUserChat(this.props.event.alias,this.user.token,id,(err,chat)=>{
            this.setState({isLoadingSingleChat : false})
            if(err == null){
                let currentDate = new Date()
                let currentConversation = []
                for(var i=0;i<chat.length;i++){
                    let string = chat[i].time.replace(" ","T")
                    var time = utility.calculateZoneSpecificTime(string)
                    chat[i].timeObj = time
                    if((time.getDate() == currentDate.getDate() || time.getDate() == currentDate.getDate()-1) && time.getMonth() == currentDate.getMonth() && time.getFullYear() == currentDate.getFullYear()){
                        if(time.getDate() == currentDate.getDate()){
                            chat[i].dateString = "Today"
                        }
                        else{
                            chat[i].dateString = "Yesterday"
                        }
                    }
                    else{
                        chat[i].dateString = time.getDate()+" "+monthNamesShort[time.getMonth()]+" "+time.getFullYear()
                    }
                    chat[i].time = time
                    if(chat[i].senderId == this.user.id){
                        chat[i].me = true
                    }
                    chat[i].time = this.getTimeFormat(new Date(string),false,false)
                    let index = currentConversation.findIndex(x=>x.date == chat[i].dateString)
                    if(index < 0){
                        let obj = {
                            date : chat[i].dateString,
                            timeObj : chat[i].timeObj,
                            chat : [chat[i]]
                        }
                        currentConversation.push(obj)
                    }else{
                        currentConversation[index].chat.push(chat[i])
                    }
                }
                currentConversation.sort(function(a,b){
                    return new Date(a.timeObj) - new Date(b.timeObj);
                });
                for(var i=0;i<currentConversation.length;i++){
                    currentConversation[i].chat.sort(function(a,b){
                        return new Date(a.timeObj) - new Date(b.timeObj);
                    });
                }
                conversations[id] = currentConversation
                this.setState({conversations,currentConvertation : currentConversation},()=>{
                    let chatBody = document.getElementById("chat-inner")
                    if(chatBody != null){
                        chatBody.scrollTop = chatBody.scrollHeight;
                    }
                })
            }else{
                if(err == -1){
                    if(!this.sessionExpired){
                        this.sessionExpired = true
                        this.setState({showErrorPopup : true,popUpErrorHeading : 'Login Required',popUpErrorMessage : 'Your session is expired please login again'})
                            // $("#chatSessionDialog").modal('show');
                            // $('#chatSessionDialog').data('bs.modal').options.backdrop = 'static';
                            $('#chatSessionDialog').modal({
                                backdrop: 'static',
                                keyboard: false,
                                show:true
                            })
                            $('.modal-backdrop').show();
                            $("#chatSessionDialog").data('bs.modal').options.backdrop = 'static';
                            // document.getElementById("chatSessionDialog").setAttribute("data-backdrop","static")
                        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
                    }
                }
            }
        })
    }
    markMessageRead = (index,currentConvertation,unReadCount,id) => {
        let allMessages = []
        for(var i=0;i<currentConvertation.length;i++){
            allMessages = [...allMessages,...currentConvertation[i].chat]
        }
        var unReadMessageIds = allMessages.filter(x=>x.isRead == 0).map(a=>a.id)
        var data = {
            messageIds : unReadMessageIds
        }
        api.updateChat(this.props.event.alias,this.user.token,data,(err,res)=>{
            if(err == null){
                if(unReadCount != null){
                    this.props.markChatRead(unReadCount,id)
                }
            }else{
                if(err == -1){
                    if(!this.sessionExpired){
                        this.sessionExpired = true
                        this.setState({showErrorPopup : true, truepopUpErrorHeading : 'Login Required',popUpErrorMessage : 'Your session is expired please login again'})
                        // $("#chatSessionDialog").modal('show');
                        // $('#chatSessionDialog').data('bs.modal').options.backdrop = 'static';
                        $('#chatSessionDialog').modal({
                            backdrop: 'static',
                            keyboard: false,
                            show:true
                        })
                        $('.modal-backdrop').show();
                        $("#chatSessionDialog").data('bs.modal').options.backdrop = 'static';
                        // document.getElementById("chatSessionDialog").setAttribute("data-backdrop","static")
                        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
                    }
                }
            }
        })
    }
    OnInputTextChanged = (e) => {
        this.setState({chatMessage : e.target.value})
    }
    OnParticipantSeacrhTextChanged = (e) => {
        this.searchParticipants((list)=>{
            this.setState({participants : list,searchingParticipants : false,pageNumber :2})
        },e.target.value)
    }
    OnSendMessage = (callLog = false,callLogText = "") => {
        var messageText = ""
        if(callLog){
            messageText = callLogText
        }else{
            messageText = this.state.chatMessage
        }
        if(messageText.trim() == ""){
            return
        }
        if(this.tempReceiverSocketId != ""){
            this.messageReceiverSocketId = this.tempReceiverSocketId
        }
        var message = {
            message : messageText,
            receiverId : this.state.selectedReciverId == undefined ? this.state.senderId : this.state.selectedReciverId,
            to : this.messageReceiverSocketId == undefined ? this.state.senderId : this.messageReceiverSocketId,
            chatAvailabaility:this.user.chatAvailabaility,
            me : true,
            unvisbileSenderId : this.socket.id,
            receiverName : this.receiverName,
            messageId : Math.random().toString(36).substr(2, 9),
            time : new Date(),
            eventAlias : this.props.event.alias,
            isRead : callLog ? 1 : 0,
            type : callLog ? "callLog" : "default",
            avalibility:this.state.avalibility,
            chatAvailabailityDuration:this.user.chatAvailabailityDuration
        }
        let chatParticipantsList = this.state.chatParticipantsList
        let index = 0
        if(this.selectedChatListIndex > -1){
            index = this.selectedChatListIndex
        }else{
            index = chatParticipantsList.length - 1
        }
        chatParticipantsList[index].message = messageText.length > 80 ? (messageText.substring(0,80) + " ...") : messageText
        chatParticipantsList[index].time = this.getTimeFormat(message.time,false,false,false)
        chatParticipantsList[index].timeObj = new Date()
        if(callLog){
            chatParticipantsList[index].message = "Call ended - "+chatParticipantsList[index].message
        }
        this.SaveChatMessage(message)
        message.senderId = this.user.id
        message.senderName = this.user.firstName + " " + this.user.lastName
        message.senderProfileImageUrl = this.user.profileImageUrl
        message.dateString = "Today"
        this.socket.emit('messageSent',message)
        message.time = this.getTimeFormat(message.time,false,false,false)
        let conversations = this.state.conversations
        let currentConversation =  conversations[this.state.selectedReciverId]
        let conversationIndex = currentConversation.findIndex(x=>x.date == message.dateString)
        if(conversationIndex < 0){
            let chatObj = {
                date : message.dateString,
                chat : [message]
            }
            currentConversation.push(chatObj)
        }else{
            currentConversation[conversationIndex].chat.push(message) 
        }
        // console.log(conversations[this.state.selectedReciverId])
        // if(conversations[this.state.selectedReciverId].length == 0){
        //     conversations[this.state.selectedReciverId] = currentConversation
        //     console.log(conversations[this.state.selectedReciverId])
        //     let index = 0
        //     if(this.selectedChatListIndex > -1){
        //         index = this.selectedChatListIndex
        //     }else{
        //         index = chatParticipantsList.length - 1
        //     }
        //     chatParticipantsList[index].localTime = true
        //     chatParticipantsList[index].message = this.state.chatMessage.length > 80 ? (this.state.chatMessage.substring(0,80) + " ...") : this.state.chatMessage
        //     chatParticipantsList[index].time = this.getTimeFormat(message.time,false,false,false)
        //     chatParticipantsList[index].timeObj = new Date()
        //     this.props.onUpdateChatList(chatParticipantsList[index])
        // }else
        {
            conversations[this.state.selectedReciverId] = currentConversation
            chatParticipantsList[index].localTime = true
            this.updateChatList(chatParticipantsList[index])
        }
        chatParticipantsList.sort((a,b)=>{
            return b.timeObj - a.timeObj
        })
        this.selectedChatListIndex = 0
        this.setState({conversations,currentConvertation : conversations[this.state.selectedReciverId],chatParticipantsList,chatMessage : ''},()=>{
            let chatBody = document.getElementById("chat-inner")
            chatBody.scrollTop = chatBody.scrollHeight;
            let chatList = document.getElementById("chat-list")
            if(chatList != null){
                chatList.scrollTop = -chatList.scrollHeight;
            }
        })
    }
    callPeer = (socketId) => {
        this.isInitiator = true
        let receivingPeer = this.state.chatParticipantsList.find(x=>x.socketId == socketId)
        setTimeout(() => {
            if(this.state.callingUser){
                this.rejectCall()
            }
        }, 60000 * 2);
        this.setState({receivingPeer},()=>{
            $("#callingUser").modal('show');
        })
        this.receiverSocketIdCall = socketId
        this.setState({callingUser : true})
        this.socket.emit("callUser",{
            userToCall : this.messageReceiverSocketId,
            from : this.mySocketId,
            receiverId : this.state.selectedReciverId,
            callerObj : this.user
        })
        this.socket.on('callAccepted',(signal)=>{
            if(signal == null){
                setTimeout(() => {
                    this.setState({showCloseCallButton : true})
                }, 2000);
                this.setState({callAccepted : true,callingUser : false,receivingCall : false},()=>{
                    document.getElementById("videoChatInput").addEventListener("keypress",this.TextAreaSubmitListener);
                    $('#callingUser').modal('hide');
                    $('.modal-backdrop').remove();
                })
            }
        })
    }
    signalPeer = () =>{
        const peer = new Peer({
            initiator : true,
            trickle : false,
            stream : this.stream
        })
        if(this.tempReceiverSocketId != ""){
            this.messageReceiverSocketId = this.tempReceiverSocketId
        }
        peer.on('signal',(data)=>{
            this.socket.emit("callUser",{
                userToCall : this.messageReceiverSocketId,
                signalData : data,
                from : this.mySocketId,
                callerObj : this.user
            })
        })
        peer.on('stream',(stream)=>{
            this.videoCallRef.OnPartnerJoined(stream)
            this.partnerStream = stream
            // this.partnerVideo.current.srcObject = stream
        })
        this.socket.on('callAccepted',(signal)=>{
            if(signal != null){
                peer.signal(signal)
                if(!this.callInProgress){
                    this.startCallTimer()
                    let chatBody = document.getElementById("chat-inner")
                    chatBody.scrollTop = chatBody.scrollHeight;
                    this.callInProgress = true
                }
            }
        })
        
    }
    acceptCall = () =>{
        document.getElementById("chatInput").addEventListener("keypress",this.TextAreaSubmitListener);
        setTimeout(() => {
            this.setState({showCloseCallButton : true})
        }, 2000);
        this.setState({callAccepted : true,callingUser : false,receivingCall : false},()=>{
            document.getElementById("videoChatInput").addEventListener("keypress",this.TextAreaSubmitListener);
            $('#incomingCall').modal('hide');
            $('.modal-backdrop').remove();
            this.socket.emit('acceptCall',{
                to : this.state.caller.socketId
            })
        })
    }
    callStarted = () => {
        const peer = new Peer({
            initiator : false,
            trickle : false,
            stream : this.stream
        })
        console.log('started')
        peer.on('signal',(data)=>{
            this.socket.emit('acceptCall',{
                signal : data,
                to : this.state.caller.socketId
            })
        })
        peer.on('stream',(stream)=>{
            if(this.videoCallRef != null){
                this.videoCallRef.OnPartnerJoined(stream)
            }
            this.partnerStream = stream
            // this.partnerVideo.current.srcObject = stream
        })
        peer.signal(this.state.callerSignal)
        if(!this.callInProgress){
            this.startCallTimer()
            this.callInProgress = true
        }
    }
    startCallTimer = () => {
        clearInterval(this.callTimer)
        this.callSeconds = 0
        this.callMinutes = 0
        this.callHours = 0
        this.callTimer = null
        this.callTimer = setInterval(() => {
            if(this.callSeconds < 60){
                this.callSeconds += 1
            }else{
                this.callSeconds = 0
                if(this.callMinutes < 60){
                    this.callMinutes += 1
                }else{
                    this.callMinutes = 0
                    this.callHours += 1
                }
            }
            let secondsText = this.callSeconds < 10 ? "0"+this.callSeconds : this.callSeconds
            let minutesText = this.callMinutes < 10 ? "0"+this.callMinutes : this.callMinutes
            let hoursText = this.callHours < 10 ? "0"+this.callHours : this.callHours
            this.setState({callDuration : hoursText + ":" + minutesText + ":" + secondsText})
        }, 1000);
    }
    rejectCall = () => {
        let peerSocketId = this.getPeerSocketId()
        this.isInitiator = false
        if(this.state.receivingCall){
            this.setState({receivingCall : false},()=>{
                $('#incomingCall').modal('hide');
                $('.modal-backdrop').remove();
            })
        }else{
            this.setState({callingUser : false},()=>{
                $('#callingUser').modal('hide');
                $('.modal-backdrop').remove();
            })
        }
        this.socket.emit('declineCall',{
            to : peerSocketId
        })
        this.receiverSocketIdCall = ""
        if(this.tempReceiverSocketId != ""){
            this.messageReceiverSocketId = this.tempReceiverSocketId
            this.tempReceiverSocketId = ""
        }
    }
    endCall = () => {
        this.callInProgress = false
        if(this.partnerStream != null){
            this.StopPartnerStream()
        }
        clearInterval(this.callTimer)
        this.socket.emit('endCall',{
            to : this.getPeerSocketId()
        })
        this.isInitiator = false
        document.getElementById("videoChatInput").removeEventListener("keypress",this.TextAreaSubmitListener);
        this.setState({callAccepted : false,callingUser : false,caller : null,callerSignal : null},()=>{
            document.getElementById("chatInput").addEventListener("keypress",this.TextAreaSubmitListener);
        })
        this.receiverSocketIdCall = ""
        if(this.tempReceiverSocketId != ""){
            this.messageReceiverSocketId = this.tempReceiverSocketId
            this.tempReceiverSocketId = ""
        }
        this.setState({showCloseCallButton : false})
        this.socket.removeAllListeners("callAccepted")
        var callTimeString = ""
        if(this.callHours > 0){
            callTimeString += this.callHours+"h "
        }
        if(this.callMinutes > 0){
            callTimeString += this.callMinutes+"m "
        }
        callTimeString += this.callSeconds+"s "
        callTimeString = callTimeString
        this.OnSendMessage(true,callTimeString)
    }
    muteCall = () => {
        this.setState({callMuted : !this.state.callMuted},()=>{
            this.socket.emit('muteCall',{
                muted : this.state.callMuted,
                to : this.getPeerSocketId()
            })
        })
    }
    onDisableVideo = () => {
        this.setState({videoDisabled : !this.state.videoDisabled},()=>{
            this.socket.emit('disableVideo',{
                disabled : this.state.videoDisabled,
                to : this.getPeerSocketId()
            })
        })
        this.tempReceiverSocketId = ""
        this.socket.removeAllListeners("callAccepted")
    }
    StopPartnerStream = () => {
        this.partnerStream.getTracks().forEach(function(track) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }
    getPeerSocketId = () => {
        let peerSocketId = ""
        if(this.receiverSocketIdCall == ""){
            peerSocketId = this.state.caller.socketId
        }else{
            peerSocketId = this.receiverSocketIdCall
        }
        return peerSocketId
    }
    getTimeFormat = (time,serverTime = false,capital = false,caculateOffset = true) => {
        let offset = null
        if(caculateOffset){
            offset = parseInt(utility.getTimezoneOffset())/100
        }else{
            time = ""
        }
        let newTime = serverTime ? utility.convertUTCDateToLocalDate(time) : utility.calculateZoneSpecificTime(time,offset)
        let hours = newTime.getHours()
        let minutes = newTime.getMinutes()
        let amPm = ""
        if(hours >= 12){
            if(hours > 12){
                hours -= 12
            }
            amPm = capital ? "PM" : "pm"
        }else{
            amPm = capital ? "AM" : "am"
        }
        if(minutes < 10){
            minutes = "0"+minutes
        }
        return hours+ ":"+minutes+ " "+amPm
    }
    // handleClosePopup = () => {
    //     console.log("I ma Pop Close Ans You")
    //     this.OnNavigateToPage(this.props.event.alias+"/login")
    // }
    moveTolOgin = ()=>{
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
    }
    searchParticipants = async (callback,query = "",ids = null) => {
        if(query.length > 0){
            this.setState({searchingParticipants : true})
        }
        await participantApi.getEventParticipants(this.props.event.alias,(list)=>{
            for(var i=0;i<this.props.onlineParicipants.length;i++){
                let index = list.findIndex(x=>x.id == this.props.onlineParicipants[i].userId)
                if(index > -1){
                    list[index].isOnline = true
                    list[index].socketId = this.props.onlineParicipants[i].socketId
                }
            }
            callback(list)
        },query,1,ids)
    }
    loadMoreParticipent = async (query = "",ids = null,pageNumber=null,callback) => {
        console.log("Data point")
        // if(query.length > 0){
        //     this.setState({searchingParticipants : true})
        // }
        await participantApi.getEventParticipants(this.props.event.alias,(list)=>{
            for(var i=0;i<this.props.onlineParicipants.length;i++){
                let index = list.findIndex(x=>x.id == this.props.onlineParicipants[i].userId)
                if(index > -1){
                    list[index].isOnline = true
                    list[index].socketId = this.props.onlineParicipants[i].socketId
                }
            }
            callback(list)
        },query,pageNumber,ids)
    }
    setupSocket = () => {
        this.socket = this.props.socket
        if(this.socket != null){
            this.initializeSocketListeners()
        }
    }
    render () {
        const filteredParticipants = this.state.participants
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(this.socket == null){
            this.setupSocket()
        }
        return(
            <>
        <PermiumUserModal
        id="chatSessionDialog"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        opacity={0.5}
        zIndex={10000}
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#chatSessionDialog").modal("hide");
            $(".modal-backdrop").remove();
        }}
        onClickBookNow={() => {
        this.moveTolOgin()
          $("#chatSessionDialog").modal("hide");
         $(".modal-backdrop").remove();
          this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Chat</title>
            </Helmet>
            <div id="header-top">
                <Header
                    onMenuItemClick = {this.OnNavigateToPage}  
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    isMarketplace = {this.props.event.is_marketplace}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    event = {this.props.event}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                    onClickAccountItem = {this.OnClickAccountItem}
                />
                {user != null && user.isBooking && Constants.showVirtualZone ?
                <VirtualZone
                    selectedItemIndex = {this.state.virtualZoneItemIndex}
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    notifications = {this.props.notifications}
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                            if(isExternal){
                                window.open(path,"_blank")
                            }else{
                                this.OnNavigateToPage(this.props.event.alias+path)
                            }
                        }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
            </div>
            {!this.state.callAccepted ?
            <TextChat
                participants = {filteredParticipants}
                chatList = {this.state.chatParticipantsList}
                chat = {this.state.currentConvertation} 
                event = {this.props.event}
                onParticipantSelected = {(id,socketId,name,profileImg,isOnline,index,chatAvailabaility,duration,userType = '',title,bio,company,twitter,facebook,instagram,linkedIn)=> {
                    // console.log(id,socketId,name,profileImg,isOnline,index,chatAvailabaility,duration,userType,title,bio)
                    this.OnClickChatParticipant(id,socketId,name,profileImg,isOnline,index,chatAvailabaility,duration,userType,title,bio,company,twitter,facebook,instagram,linkedIn)}}
                selectedParticipantId = {this.state.selectedReciverId}
                selectedParticipantName = {this.receiverName}
                selectedParticipentCompany = {this.companyName}
                chatAvailabaility={this.chatAvailabaility}
                duration = {this.duration}
                userType = {this.userType}
                selectedParticipantJob = {this.jobTitle}
                selectedParticipantbio = {this.profileBio}
                twitterProfile = {this.twitterProfile}
                facebookProfile = {this.facebookProfile}
                linkedInProfile = {this.linkedInProfile}
                instagramProfile = {this.instagramProfile}
                selectedParticipantProfileImage = {this.selectedParticipantProfileImageUrl}
                selectedParticipantOnline = {this.isSelectedParticipantOnline}
                onInputTextChanged = {this.OnInputTextChanged}
                loadMoreParticipent = {this.loadMoreParticipent}
                onParticipantSeacrhTextChanged = {this.OnParticipantSeacrhTextChanged}
                chatMessage = {this.state.chatMessage}
                loadingSingleChat = {this.state.isLoadingSingleChat}
                updatingChatList = {this.state.isUpdatingChatList}
                laoding={this.state.loading}
                userId = {user != null ? user.id : ''}
                onClickCloseChat = {()=>{
                    this.setState({selectedReciverId : '' ,currentConvertation : []})
                }}
                onClickSendMessage = {this.OnSendMessage}
                onClickCall = {()=>this.callPeer(this.messageReceiverSocketId)}
                searchingParticipants = {this.state.searchingParticipants}
                submitChat = {(e)=>alert('s')}
                getInvisibleUser={this.getInvisibleUser}
            /> : 
            <VideoCall
                ref = {(ref)=> this.videoCallRef = ref}
                chat = {this.state.currentConvertation}
                userVideo = {this.userVideo}
                event = {this.props.event}
                partnerVideo = {this.partnerStream}
                callDuration = {this.state.callDuration}
                onMuteCall = {this.muteCall}
                onDisableVideo = {this.onDisableVideo}
                onEndCall = {this.endCall}
                selectedParticipantId = {this.state.selectedReciverId}
                selectedParticipantName = {this.receiverName}
                selectedParticipantProfileImage = {this.selectedParticipantProfileImageUrl}
                selectedParticipantOnline = {this.isSelectedParticipantOnline}
                onInputTextChanged = {this.OnInputTextChanged}
                chatMessage = {this.state.chatMessage}
                userMuted = {this.state.callMuted}
                partnerMuted = {this.state.partnerMuted}
                videoDisabled = {this.state.videoDisabled}
                partnerVideoDisable = {this.state.partnerVideoDisable}
                onClickCloseChat = {()=>{
                    this.setState({showChatInVideo : false})
                }}
                showChat = {this.state.showChatInVideo}
                onClickChat = {()=>{
                    $('.chats').fadeIn()
                    this.setState({showChatInVideo : true})
                }}
                onUserStreamStarted = {(stream,signalPeer)=> {
                    if(!this.state.callAccepted){
                        return
                    }
                    this.stream = stream
                    if(signalPeer){
                        this.signalPeer()
                    }else{
                        setTimeout(() => {
                            this.callStarted()
                        }, 500);
                    }
                }}
                loadingSingleChat = {this.state.isLoadingSingleChat}
                onClickSendMessage = {this.OnSendMessage}
                caller = {this.state.caller}
                isInitiator = {this.isInitiator}
                showCloseButton = {this.state.showCloseCallButton}
            />
            }
            <IncomingCallPopup
                callerName = {this.state.caller == null ? "" :  (this.state.caller.firstName + " " + this.state.caller.lastName)}
                callerImageUrl = {this.state.caller == null ? "" : this.state.caller.profileImageUrl}
                onAcceptCall = {this.acceptCall}
                onRejectCall = {this.rejectCall}
            />
            <CallingPopup
                receiverName = {this.state.receivingPeer == null ? "" :  (this.state.receivingPeer.firstName + " " + this.state.receivingPeer.lastName)}
                receiverImageUrl = {this.state.receivingPeer == null ? "" : this.state.receivingPeer.profileImageUrl}
                onRejectCall = {this.rejectCall}
            />
            {/* <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showErrorPopup} 
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    participants : state.participants.participantsList,
    userChatList : state.chat.chatList,
    onlineParicipants : state.participants.onlineParticipantIds
})
const mapDispatchToProps = dispatch => ({
    updateChatList : data => dispatch(UpdateChatList(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(UserChat);