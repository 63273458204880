import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getEventSpeakers(eventAlias,type,callback){
        axios.get(`${eventAlias}/getEventSpeakers`,
        {
            params: {type: type,isActive:1,isPreview : Constants.isPreview},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    }
}