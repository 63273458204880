import React,{useState,useEffect} from 'react'
import RoundCircle from '../RoundTable/seatingStyles/roundCircle'
import utility from '../../../Utility/utility'
import api from '../api'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {Trans} from 'react-i18next'
export default function NetworkingGala(props){
    const [selectedSeat,setSelectedSeat] = useState(-1)
    const [selectedTable,setSelectedTable] = useState(0)
    const [bookedSeats,setBookedSeats] = useState(props.meeting.bookedSeats)
    const [bookingSeat,setSeatBookingStatus] = useState(false)
    const [bookingSeatResponse,setBookingSeatResponse] = useState(null)
    const [bookingDisabled,setBookingDisabled] = useState(false)
    const [numberOfTables,setNumberOfTables] = useState([])
    const [showInlineError,setShowInlineError] = useState(false)
    var navigateBack = true
    useEffect(()=>{
        let totalSeats = props.meeting.totalSeats
        let totalTables = Math.ceil(totalSeats / 10)
        for(var i=0;i<totalTables;i++){
            numberOfTables.push(i)
            setNumberOfTables([...numberOfTables])
        }
        let maxNumberOfSeatsPerTable = 10
        let maxMumberOfSeats = maxNumberOfSeatsPerTable * totalTables
        let numberOfExtraSeats = maxMumberOfSeats - totalSeats
        for(var i=maxNumberOfSeatsPerTable - 1 ;i>(maxNumberOfSeatsPerTable - 1) - numberOfExtraSeats;i--){
            var seat = {
                seatIndex : i,
                tableIndex : totalTables - 1,
                userId : 1001
            }
            bookedSeats.push(seat)
            setBookedSeats([...bookedSeats])
        }
        let user = utility.getLoggedInUser(props.eventAlias)
        if(bookedSeats.findIndex(x=>x.userId == user.id) > -1){
            setBookingDisabled(true)
        }
        $("#thankyouModal").on("hidden.bs.modal", function () {
            document.getElementById("thanks-msg").style.display = "block"
            document.getElementById("error-msg").style.display = "none"
            props.onClickNetworking()
            $("#thankyouModal").modal('hide')
            $('body').removeClass('modal-open')
            $('.modal-backdrop').remove()
        })
    },[])
    const OnSeatSelected = (index,tableIndex) => {
        setSelectedSeat(index)
        setSelectedTable(tableIndex)
    }
    const OnClickConfirmBooking = () => {
        if(bookingSeat){
            return
        }
        setBookingSeatResponse(null)
        var responseObj = {}
        if(bookingDisabled){
            responseObj.status = false
            responseObj.message = 'You have already booked a seat for this gala'
            setBookingSeatResponse(responseObj)
            document.getElementById("thanks-msg").style.display = "none"
            document.getElementById("error-msg").style.display = "block"
            $('#thankyouModal').modal('show');
            setTimeout(() => {
                $('body').addClass('modal-open')
            }, 500);
            navigateBack = false
            return
        }
        if(selectedSeat == -1){
            setShowInlineError(true)
            responseObj.status = false
            responseObj.message = 'Select a seat to proceed'
            setBookingSeatResponse(responseObj)
            return
        }
        let user = utility.getLoggedInUser(props.eventAlias)
        var data = {
            meetingId : props.meetingId,
            type : props.meetingType,
            userId : user.id,
            seatIndex : selectedSeat,
            tableIndex : selectedTable
        }
        setSeatBookingStatus(true)
        api.bookRoundTableSeat(props.eventAlias,true,data,(err,resposData)=>{
            setSeatBookingStatus(false)
            if(err){
                responseObj.message = err.isEnable == 0 ? "This meeting is diabled by the admin" : err.message
                responseObj.status = false
                document.getElementById("thanks-msg").style.display = "none"
                document.getElementById("error-msg").style.display = "block"
                setBookingSeatResponse(responseObj)
                $('#thankyouModal').modal('show');
                setTimeout(() => {
                    $('body').addClass('modal-open')
                }, 500);
                return
            }
            bookedSeats.push({
                seatIndex : selectedSeat,
                tableIndex : selectedTable,
                userId : user.id
            })
            setBookedSeats([...bookedSeats])
            setBookingDisabled(true)
            setSelectedSeat(-1)
            // responseObj.message = resposData.message
            // responseObj.status = true
            // setBookingSeatResponse(responseObj)
            // setTimeout(()=>{
            //     setBookingSeatResponse(null)
            // },2000)
            $('#thankyouModal').modal('show');
            setTimeout(() => {
                $('body').addClass('modal-open')
            }, 500);
        })
    }
    const borderTopClass = props.meeting.isEnd ? "" : " border-top"
    return(
        <>
        <section style = {{minHeight : 0}} class = "networking-meeting bg-white py-3">
            <div class="container text-center">
                <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item"><a style = {{cursor : 'pointer'}} onClick = {props.onClickNetworking}>
                        <Trans>networking</Trans></a></li>
                    {/* <li class="breadcrumb-item"><a >{props.meetingName}</a></li> */}
                    <li class="breadcrumb-item active" aria-current="page">{props.meetingName}</li>
                </ol>
                </nav>

                <div class="row justify-content-center mx-1">
                
                    <div class = "col-12 ng-m-section py-3 px-0">
                    
                        <div class = "row mx-0">
                            <div class = "col-12">
                                <h1>
                                    {/* {props.meeting.title} */}
                                    {props.meetingName}
                                </h1>
                                <p>
                                    {props.meetingDescription != null ? props.meetingDescription : "Join the following networking gala"}
                                </p>
                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </section>

        <section class = "time-date-sec bg-light">
            <div class="container">
                <div class="row">
                    <div class = "col-12 ng-m-section py-3 px-0">
                        <h2>
                            <span class = "mr-3"> <i class="far fa-calendar-alt mr-2"></i> {props.meeting.dateString} </span>
                            <br class="d-md-none"/>
                            <i class="far fa-clock mr-2"></i>
                            {props.meeting.totalTimeArray.map((item,index)=>{
                                let splitterSlash = index == props.meeting.totalTimeArray.length - 1 ? "":" / "
                                return(
                                    <span>{item.time+" "}<b>{item.country}</b>{" "+splitterSlash}</span>
                                )
                            })}
                        </h2>
                    </div>
                </div>
            </div>
        </section>

        <section class="nt-gala py-3 py-md-5" style = {{backgroundImage : `url(${props.meeting.image})`}}>
            <div class = "container">
                <div class = "row">
                    <div class = "col-12 py-3 px-0">
                            <div class = "row mx-0 justify-content-center">
                                {props.meeting.speakers.map((speaker,index)=>{
                                    return(
                                        <div class = "col-lg-2 col-md-6 text-center">
                                            <div class = "n-gala-spk mb-3">
                                                {speaker.image != null ?
                                                <img src={speaker.image} class="rounded-circle" 
                                                alt="user_1-img"/> :
                                                <img src="/images/profile-placeholder.png" class="rounded-circle" alt="user_1-img"/>}
                                            </div>

                                            <div class = "ng-spk-info">
                                                <h3>{speaker.name + " " + (speaker.surname == null ? "" :speaker.surname)}</h3>
                                                <p>{speaker.job_title}</p>
                                                <p>{speaker.company}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {!props.meeting.isEnd ? 
                        <div class = "col-12 py-3 px-0">
                            <div class = "row mx-0 justify-content-center">
                                {numberOfTables.map((table,index)=>{
                                    return(
                                        <div class = "col-md-4 ng-rt mb-3 mb-md-0">
                                            <RoundCircle
                                                tableIndex = {index}
                                                bookedSeats = {bookedSeats.filter(x=>x.tableIndex == index).map(a=>a.seatIndex)}
                                                disabled = {bookingDisabled}
                                                selectedSeat = {selectedTable == index ? selectedSeat : -1}
                                                onSeatSelected = {OnSeatSelected}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div> :
                        <div className = "col-12 text-center" style = {{color : 'white',fontSize : 20}}>The gala has been ended</div>}
                </div>
            </div>
        </section>
        {!props.meeting.isEnd ?
        <section class="booking-btn-sec py-3">
            <div class = "container">
                <div class = "row">
                    <div class="col-12 btn-sec py-3 text-center">
                        <a style = {{cursor : 'pointer'}} onClick = {props.onClickNetworking} class="btn cancel_btn mx-md-2 px-3">
                            <Trans>cancel</Trans>
                        </a>
                        <a style = {{cursor : 'pointer'}} onClick = {OnClickConfirmBooking} class="btn bg_main mx-md-2 px-3">
                            <Trans>confirmBooking</Trans>
                        </a>
                        {bookingSeat ?
                        <div style = {{position : 'absolute',left : '50%',transform : 'translate(-50%,-50%)',bottom : -25}}>
                            <Loader
                                type = 'Oval'
                                color = "#00b6ff"
                                height = {25}
                                width = {25}
                            />
                        </div> : null}
                        {bookingSeatResponse != null && showInlineError? 
                        <span style = {{position : 'absolute',fontSize : 13,left : '50%',transform : 'translate(-50%,-50%)',bottom : -18}} class = {bookingSeatResponse.status ? "text-success" : "text-red" }>{bookingSeatResponse.message}</span> : null}
                    </div>
                </div>
            </div>
        </section> : null}

        <section class={"booking-btn-sec py-3"+borderTopClass}>
            <div class = "container-fluid text-center">
                <div class = "row">
                    <div class="col-12 py-3">
                        <div class="sponsor-sec-logo">
                            <ul class = "list-inline sponsor-list mb-0 mt-3">
                                {props.meeting.sponsers.map((sponser,index)=>{
                                    return(
                                        <li class = "list-inline-item">
                                            <img src={sponser.image} alt="amazon_logo"/>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal" id="thankyouModal">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                <div class="modal-body">
                    <div class = "thanks-msg text-center py-3 py-md-4" id = "thanks-msg" style = {{display : 'block'}}>
                        <p>
                            <i class="fas fa-check-circle"></i>
                        </p>
                        <h3>
                            <Trans>thankyou</Trans>
                        </h3>
                        
                        <p>
                            <Trans>bookingConfirmed</Trans> <br/>
                            <Trans>checkMail</Trans>
                        </p>
                        
                        <p>
                            <button type="button" class="btn got-it" data-dismiss="modal">
                                <Trans>gotIt</Trans>
                            </button>
                        </p>
                        
                    </div>
                    <div class = "thanks-msg text-center py-3 py-md-4" id = "error-msg">
                            <p>
                                <i class="fas fa-times-circle" style = {{color : 'red'}}></i>
                            </p>
                            <h3>
                                <Trans>error</Trans>
                            </h3>
                            {bookingSeatResponse != null ? 
                            <p>
                                {bookingSeatResponse.message}
                            </p> : null}
                            
                            <p>
                                <button type="button" class="btn got-it" data-dismiss="modal">
                                    <Trans>gotIt</Trans>
                                </button>
                            </p>
                            
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}