import React from 'react'
import './styles.css'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const AboutUs  = (props)=>{
    return(<section class="about-th py-3 pb-md-2">
    <div class="container py-3 py-md-5">
        <div class="row">
            <div class="col-md-6 mb-3 mb-md-0">
                <h4>
                Establish Strategy and Partnerships:
                </h4>
                <p>
                   {ReactHtmlParser(props.aboutus)}
                </p>
                <a href="#" class = "btn">
                    Learn more
                </a>
            </div>
            <div class="col-md-6 mb-3 mb-md-0">
                <div class="about-video">
                    <div class="embed-responsive embed-responsive-16by9">{
                         props.homepageVideo && props.homepageVideo == null ?
                        <iframe class="embed-responsive-item" 
                        src="https://www.youtube.com/embed/ei0sfGmLcp8" allowfullscreen="">
                        </iframe>
                        :
                        <iframe class="embed-responsive-item" 
                            src={props.homepageVideo && props.homepageVideo.replace('watch?v=','embed/')} allowfullscreen="">
                        </iframe>}
                    </div>		
                </div>
            </div>
        </div>
    </div>
</section>)
}
export default AboutUs