import React,{useState,useEffect} from 'react'
export default function SemiCirlce(props){
    const [bookedSeats] = useState(props.bookedSeats)
    const [selectedSeat,setSelectedSeat] = useState(-1)
    const OnClickSeat = (index) => {
        if(bookedSeats.indexOf(index) < 0 && !props.disabled){
            props.onSeatSelected(index)
            setSelectedSeat(index)
        }
    }
    return(
        <div class="round-table semi-round">
            <p class="text-center">
                Book Seat
            </p>
            <svg class = "spk-chair" xmlns="http://www.w3.org/2000/svg" width="249.158" height="186.458" viewBox="0 0 249.158 186.458">
                <g id="semi-circle" transform="translate(-722.439 -399.318)">
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(0)} class ={bookedSeats.indexOf(0) > -1 ? "svg-pth active" : selectedSeat == 0 ? "svg-pth selected":"svg-pth"} id="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.998, -0.07, 0.07, 0.998, 724, 538.729)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(1)} class ={bookedSeats.indexOf(1) > -1 ? "svg-pth active" : selectedSeat == 1 ? "svg-pth selected":"svg-pth"} id="seat-2" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.985, -0.174, 0.174, 0.985, 771.542, 545.881)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(2)} class ={bookedSeats.indexOf(2) > -1 ? "svg-pth active" : selectedSeat == 2 ? "svg-pth selected":"svg-pth"}  id="seat-3" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(820.096 549.753) rotate(-17)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(3)} class ={bookedSeats.indexOf(3) > -1 ? "svg-pth active" : selectedSeat == 3 ? "svg-pth selected":"svg-pth"} id="seat-4" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(867.554 549.412) rotate(-19)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(4)} class ={bookedSeats.indexOf(4) > -1 ? "svg-pth active" : selectedSeat == 4 ? "svg-pth selected":"svg-pth"} id="seat-5" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(915.117 547.303) rotate(-26)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(5)} class ={bookedSeats.indexOf(5) > -1 ? "svg-pth active" : selectedSeat == 5 ? "svg-pth selected":"svg-pth"} id="seat-6" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.998, -0.07, 0.07, 0.998, 724, 495.729)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(6)} class ={bookedSeats.indexOf(6) > -1 ? "svg-pth active" : selectedSeat == 6 ? "svg-pth selected":"svg-pth"} id="seat-7" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.985, -0.174, 0.174, 0.985, 771.542, 502.881)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(7)} class ={bookedSeats.indexOf(7) > -1 ? "svg-pth active" : selectedSeat == 7 ? "svg-pth selected":"svg-pth"} id="seat-8" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(820.096 506.753) rotate(-17)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(8)} class ={bookedSeats.indexOf(8) > -1 ? "svg-pth active" : selectedSeat == 8 ? "svg-pth selected":"svg-pth"} id="seat-9" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(867.554 506.412) rotate(-19)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(9)} class ={bookedSeats.indexOf(9) > -1 ? "svg-pth active" : selectedSeat == 9 ? "svg-pth selected":"svg-pth"} id="seat-10" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(915.117 504.303) rotate(-26)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(10)} class ={bookedSeats.indexOf(10) > -1 ? "svg-pth active" : selectedSeat == 10 ? "svg-pth selected":"svg-pth"} id="seat-11" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.998, -0.07, 0.07, 0.998, 724, 453.729)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(11)} class ={bookedSeats.indexOf(11) > -1 ? "svg-pth active" : selectedSeat == 11 ? "svg-pth selected":"svg-pth"} id="seat-12" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.985, -0.174, 0.174, 0.985, 771.542, 460.881)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(12)} class ={bookedSeats.indexOf(12) > -1 ? "svg-pth active" : selectedSeat == 12 ? "svg-pth selected":"svg-pth"} id="seat-13" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(820.096 464.753) rotate(-17)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(13)} class ={bookedSeats.indexOf(13) > -1 ? "svg-pth active" : selectedSeat == 13 ? "svg-pth selected":"svg-pth"} id="seat-14" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(867.554 464.412) rotate(-19)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(14)} class ={bookedSeats.indexOf(14) > -1 ? "svg-pth active" : selectedSeat == 14 ? "svg-pth selected":"svg-pth"} id="seat-15" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(915.117 462.303) rotate(-26)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(15)} class ={bookedSeats.indexOf(15) > -1 ? "svg-pth active" : selectedSeat == 15 ? "svg-pth selected":"svg-pth"} id="seat-16" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 723, 411.551)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(16)} class ={bookedSeats.indexOf(16) > -1 ? "svg-pth active" : selectedSeat == 16 ? "svg-pth selected":"svg-pth"} id="seat-17" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="matrix(0.985, -0.174, 0.174, 0.985, 771.119, 417.881)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(17)} class ={bookedSeats.indexOf(17) > -1 ? "svg-pth active" : selectedSeat == 17 ? "svg-pth selected":"svg-pth"} id="seat-18" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(819.672 421.753) rotate(-17)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(18)} class ={bookedSeats.indexOf(18) > -1 ? "svg-pth active" : selectedSeat == 18 ? "svg-pth selected":"svg-pth"} id="seat-19" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(867.13 421.412) rotate(-19)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(19)} class ={bookedSeats.indexOf(19) > -1 ? "svg-pth active" : selectedSeat == 19 ? "svg-pth selected":"svg-pth"} id="seat-20" data-name="seat" d="M28.232,36.881l-21.9-6.028a3.894,3.894,0,0,1-1.809-6.27,3.919,3.919,0,0,1-1.713-.09A3.825,3.825,0,0,1,.134,19.819L4.823,2.787a3.763,3.763,0,1,1,7.257,2L7.39,21.816a4.162,4.162,0,0,1-.769,1.594,3.989,3.989,0,0,1,1.71.187l21.9,6.03a3.948,3.948,0,0,1,1.566.715,4.157,4.157,0,0,1,.155-1.763l4.688-17.031a3.763,3.763,0,1,1,7.256,2L39.207,30.575a3.817,3.817,0,0,1-4.69,2.636,3.881,3.881,0,0,1-1.5-.8,4.146,4.146,0,0,1-.1,1.754A3.9,3.9,0,0,1,29.2,37.013,3.643,3.643,0,0,1,28.232,36.881ZM8.425,22.421l4.764-17.3,22.327,6.147-4.763,17.3Z" transform="translate(914.694 419.303) rotate(-26)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                </g>
            </svg>
            <div class="opt-list mt-3 border-top">
                <ul class="list-inline pt-3">
                    <li class="list-inline-item available">
                        Available
                    </li>
                    <li class="list-inline-item booked">
                        Booked
                    </li>
                    <li class="list-inline-item select">
                        Selected
                    </li>
                </ul>
            </div>
        </div>
    )
}