import React from "react";
import axios from "axios";
import { Constants } from "../../Constants";
import utility from "../../Utility/utility";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { Trans } from "react-i18next";
export class HeaderImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: this.props.event,
      start_date: this.props.event.start_date,
      venue: {},
      imageLoaded: false,
    };
  }
  render() {
    const event = this.props.event;
    const user = utility.getLoggedInUser(this.props.event.alias);
    if (event) {
      if (event.start_date) {
        var start_date = new Date(event.start_date).toLocaleDateString(
          "en-GB",
          {
            day: "numeric",
            month: "long",
            year: "numeric",
          }
        );
      }

      if (event.end_date) {
        var end_date = new Date(event.end_date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
      var startDateMonth = start_date.split(" ");
      var endDateMonth = end_date.split(" ");
      var finalDateString = "";
      if (startDateMonth[1] == endDateMonth[1]) {
        let dateString = "";
        if (startDateMonth[0] == endDateMonth[0]) {
          dateString = startDateMonth[0];
        } else {
          dateString = startDateMonth[0] + " - " + endDateMonth[0];
        }
        finalDateString =
          dateString + " " + startDateMonth[1] + " " + startDateMonth[2];
      } else {
        finalDateString = start_date + " - " + end_date;
      }
      const divStyle = {
        backgroundImage: "url(" + event.background_image + ")",
        backgroundPosition: "top",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "75vh",
        position: "relative",
        cursor:
          this.props.event.homepageBannerLink != null && user == null
            ? "pointer"
            : "auto",
      };
      return (
        this.props.event.homepageBannerContentEnabled == 1 ? 
        <section
          class="main_banner"
          style={divStyle}
          onClick={() => {
            if (this.props.event.homepageBannerLink != null && user == null) {
              window.location.href = this.props.event.homepageBannerLink;
            }
          }}
        >
          {/* {this.state.imageLoaded == false ? (
            <ContentLoader
              speed={2}
              width={400}
              viewBox="0 0 400 300"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="2" y="2" rx="0" ry="0" width="400" height="300" />
            </ContentLoader>
          ) : null} */}
          {/* <div class="container-fluid px-0">
            <div class="row mx-0">
              <div class="col-md-12 px-0 overlay">
                <img
                  class="w-100"
                  src={event.background_image}
                  onLoad={() => {
                    this.setState({ imageLoaded: true });
                  }}
                />
              </div>
            </div>
          </div> */}
          <div
            className="container-fluid pt-5 position-absolute bottom_0"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            {this.props.event.homepageBannerContentEnabled == 1 ? (
              <div className="row">
                <div className="col-md-12 pr-0 pl-0 overlay">
                  <div className="p-4 pr-5 pl-md-5 pl-3 text-light">
                    <h1 className="font-weight-bold">{event.name}</h1>
                    <h5 className="mb-4">{event.sub_heading}</h5>
                    {event.venue_address ? (
                      <i className="fas fa-map-marker-alt"></i>
                    ) : null}

                    <h4 className="mb-4 font-weight-normal ml-4 ml-md-5">
                      {ReactHtmlParser(event.venue_address)}
                    </h4>
                    {start_date ? (
                      <i className="far fa-calendar-alt"></i>
                    ) : null}
                    <h4 className="mb-4 font-weight-normal ml-4 ml-md-5">
                      {finalDateString}
                    </h4>
                    {this.props.event.is_jobfair == 0 ? (
                      <a
                        onClick={this.props.onClickBookNow}
                        className="btn pr-3 pl-3"
                      >
                        <Trans>bookNow</Trans>
                      </a>
                    ) : (
                      <Link
                        to={`/${this.props.event.alias}/login`}
                        onClick={this.props.onClickBookNow}
                        className="btn pr-3 pl-3"
                      >
                        Apply
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </section>:
        <section
        class="main_banner"
        style={{ background: "none", height: "auto" }}
        onClick={() => {
          if (this.props.event.homepageBannerLink != null && user == null) {
            window.location.href = this.props.event.homepageBannerLink;
          }
        }}
      >
        {/* {this.state.imageLoaded == false ? (
          <ContentLoader
            speed={2}
            width={400}
            viewBox="0 0 400 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="2" y="2" rx="0" ry="0" width="400" height="300" />
          </ContentLoader>
        ) : null} */}
        <div class="container-fluid px-0">
          <div class="row mx-0">
            <div class="col-md-12 px-0 overlay">
              <img
                class="w-100"
                src={event.background_image}
                onLoad={() => {
                  this.setState({ imageLoaded: true });
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="container-fluid  position-absolute bottom_0"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          {this.props.event.homepageBannerContentEnabled == 1 ? (
            <div className="row">
              <div className="col-md-12 pr-0 pl-0 overlay">
                <div className="p-4 pr-5 pl-md-5 pl-3 text-light">
                  <h1 className="font-weight-bold">{event.name}</h1>
                  <h5 className="mb-4">{event.sub_heading}</h5>
                  {event.venue_address ? (
                    <i className="fas fa-map-marker-alt"></i>
                  ) : null}

                  <h4 className="mb-4 font-weight-normal ml-4 ml-md-5">
                    {ReactHtmlParser(event.venue_address)}
                  </h4>
                  {start_date ? (
                    <i className="far fa-calendar-alt"></i>
                  ) : null}
                  <h4 className="mb-4 font-weight-normal ml-4 ml-md-5">
                    {finalDateString}
                  </h4>
                  {this.props.event.is_jobfair == 0 ? (
                    <a
                      onClick={this.props.onClickBookNow}
                      className="btn pr-3 pl-3"
                    >
                      <Trans>bookNow</Trans>
                    </a>
                  ) : (
                    <Link
                      to={`/${this.props.event.alias}/login`}
                      onClick={this.props.onClickBookNow}
                      className="btn pr-3 pl-3"
                    >
                      Apply
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      );
    } else {
      return <h1>nothing found</h1>;
    }
  }
}
