import React from 'react';
import {HeaderImage} from '../Header-Image';
import {BannerBottom} from '../Banner-Bottom';
import {WelcomeSection} from '../Welcome-Section';
import {RegisterModel} from '../RegisterModel';
import {Agenda} from '../Agenda';
import {AgendaTime} from '../AgendaTime';
import {SpeakerV1} from '../SpeakerV1';
import {SpeakerV2} from '../SpeakerV2';
import {EventNumbers} from '../EventNumbers';
import {EventBlog} from '../EventBlog';
import {Sponsers} from '../Sponsers';
import {ContactInfo} from '../ContactInfo';
 
export class Content extends React.Component{
    render(){
        return(
            <div>
            <section class = "about_banner">
                <img src = "../../images/background/about_us-bnr.png" class = "w-100" alt = "about-banner"/>
            </section>
            <section class = "about-detail py-3">
                <div class="container">
                    <h5 class = "font-weight-bold text-uppercase text-main mt-3">About Us</h5>
                    <div class="row py-3">
                        <div class="col-12 mb-2">
                            <p class = "f-500 first">
                                The  conference is done in partnership by two expert companies: 
                                <span class = "text-red">Pharma Regulatory Professional (PRA) </span>  and  
                                <span class = "text-red">Phrama Biotech (PBC) Solution.</span>
                            </p>
                        </div>
            
                        <div class="col-sm-6 mb-2">
                            <div class="company_address bg-light p-4 border">
                                <p class = "f-500">
                                    <span class = "f-600">Address:</span> Professional Regulatory Affairs Business Village, Deira, Dubai, UAE
                                </p>
                                <p class = "f-500">
                                    <span class = "f-600">Tel:</span> +9742999398 <br></br>
                                    <span class = "f-600">Fex:</span> +9742999398 <br></br>
                                </p>
                                <p class = "f-500">
                                    <span class = "f-600">Email:</span> info@pra-me.com
                                </p>
                            </div>
                        </div>
            
                        <div class="col-sm-6 mb-2">
                            <div class="company_address bg-light p-4 border">
                                <p class = "f-500">
                                    <span class = "f-600">Address:</span> Pharma Biotech Solution 20 EI Tahreer St. EI Dooki - Cairo - Egypt 
                                </p>
                                <p class = "f-500">
                                    <span class = "f-600">Tel:</span> +20237687342 <br></br>
                                    <span class = "f-600">Fex:</span> +20237687342 <br></br>
                                </p>
                                <p class = "f-500">
                                    <span class = "f-600">Email:</span> info@biotech.com
                                </p>
                            </div>
                        </div>
            
                        <div class="col-12 mt-3 mb-2">
                            <p class = "f-500">
                                <span class = "text-red">Pharma Regulatory Affairs, (PRA) </span>
                                is a GCC Healthcare regulatory affairs consultancy, based in Dubai, UAE.
                            </p>
                            
                            <p class = "f-500">
                                PRA is leading GCC consultacy company formed is 2013 to provide best practice and standards in the Healtcare sector. It provide regulatory and technical support to healthcare providers and companies in the region in sectors such as Pharmaceuticals, herbal, madical devices, homeopathy, FMCG, supplements, cosmetics and personal care products. 
                            </p>
            
                            <p class = "f-500">
                                PRA is involved in wide varity of activities related to healthcare regulatory affairs such as consultancy, education and event management. We cover the GCC region and Jordan. 
                            </p>
            
                            <p class = "f-500">
                                <span class = "text-red">Pharma Biotech Solution, (PBS) </span>
                                is rigonal consultancy Company base in Cairo-Egypt that provides a wide range of regulatory, traning and technical support services to any of the Phramaceutical, Bio Phramaceutical industries in Egypt and eny other regional coutries to cop with the global Phramaceutical standards.
                            </p>
            
                            <p class = "f-500">
                                PBC team has expert knowledge of Gulf Coorperation Concil (GCC) registration requirements for either manufacturing/testing site or Pharmaceutical Products.
                            </p>
            
                        </div>
            
                    </div>
                </div>
            </section>
            </div>
        )
    }
}

export default Content;