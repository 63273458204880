import React from 'react';
import api from './api';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { VenuesDataLoaded } from './actions';
import MetaTags from 'react-meta-tags';
import { Constants } from '../../Constants';
import VirtualZone from '../Header/virtualZone';
import { GAPageView } from '../../Utility/GA';
class Venue extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/venue") > -1) || -1,
            selectedSubIndex: -1,
            venue : null,
            loadingError : '',
            menu : this.props.headerMenuItems.find(x=>x.href == (this.props.event.alias+"/venue")),
            showComingSoonMsg : false
        };

    }

    componentDidMount(){
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        fetch('/setCurrentPage?page=venue')
        fetch(`/${this.props.event.alias}/venue`)
        this.setState({venue : this.props.venues})
        if(this.props.venues != null){
            if(this.props.venues.length == 0){
                this.setState({showComingSoonMsg : true})
            }
        }
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],'Venue')
                if(result != null){
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/venue') > -1)
                    this.setState({selectedIndex : i,selectedSubIndex})
                    break
                }
            }
        }
        if(this.props.venues == null){
            this.getEventVenues()
        }
    }
    getEventVenues = () => {
        api.getEventVenue(this.state.event.alias,(err,data)=>{
            if(err == null){
                if(data.venues.length == 0){
                    this.setState({showComingSoonMsg : true})
                }
                this.props.venuesLoaded(data.venues)
                this.setState({venue:data.venues,loadingError : ''});
                return
            }
            if(this.props.venues == null)
                this.setState({loadingError : 'Something went wrong'})
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
        this.getEventVenues()
    }
    languageClick = (value) =>{
        console.log("Trigger venue on lan select")
        this.props.languageClick(value);
    }

    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.menu != null ? this.state.menu.title : "Venue"}</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                <div id="header-top">
                <Header
                    selectedIndex = {this.state.selectedIndex}
                    selectedSubIndex = {this.state.selectedSubIndex}
                    onMenuItemClick = {this.OnNavigateToPage}
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    isMarketplace = {this.props.event.is_marketplace}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    onClickAccountItem = {this.OnClickAccountItem}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    notifications = {this.props.notifications}
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div>
                <div className = {`content-main`}>
                    {this.state.venue ?
                        <div>
                            {this.state.showComingSoonMsg ?  
                            <div className = "text-center bg-light">
                                <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                            </div> : 
                            <Content event = {this.props.event} venue = {this.state.venue}/>}
                            {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ?
                            <NewsLetter 
                                title = "SUBSCRIBE OUR NEWS LETTER" 
                                event={this.props.event} 
                                event_alias={this.props.event_alias}
                                buttonText = "Subscribe"
                                type = 'newsLetter'
                            />:null}
                        </div> :
                        <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                            {this.state.loadingError == "" ?
                                <Loader
                                    type = 'Oval'
                                    color = "#00b6ff"
                                    height = {40}
                                    width = {40}
                                />:
                                <ErrorScreen 
                                    message = {this.state.loadingError}
                                    onPressRetry = {this.OnPressRetry}
                                />
                            }
                        </div>
                    }
                    <Footer1
                        event = {this.state.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                </div>
                <Footer2 
                    event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    venues: state.venues.venueData
})
const mapDispatchToProps = dispatch => ({
    venuesLoaded : data => dispatch(VenuesDataLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Venue);