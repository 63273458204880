import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from './api';
import $ from 'jquery'
import 'bootstrap'
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import utility from '../../Utility/utility';
import ContentLoader from 'react-content-loader'
import {connect} from 'react-redux'
import { AgendaTimeDataLoaded } from '../Schedule/actions';
import { isMobile } from 'react-device-detect';
import {Trans} from 'react-i18next';
require('jquery-mousewheel')

export class AgendaTime extends React.Component{
	_isMounted = false
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias: this.props.event_alias,
			agenda : {},
			showContent : false,
			loading : true
        };
    }
    componentDidMount(){
		this._isMounted = true
		$(".tab-horizontal-agenda").mCustomScrollbar({
            axis:"x",
            theme:"dark"
		});
		let user = utility.getLoggedInUser(this.props.event.alias)
		let userId = 0
		if(user != null){
			userId = user.id
		}
		if(this.props.agendaTime != null){
			let showContent = false
			if(this.props.agendaTime.length > 0){
				showContent = true
			}
			this.setState({agenda : this.props.agendaTime,showContent,loading : false})
			return
		}
		api.getEventAgenda(this.state.event.alias,userId,this.props.type,(err,data)=>{
			if(err == null && this._isMounted){
				this.props.agendaTimeDataLoaded(data.data)
				this.setState({agenda:data.data,showContent : true});
			}
			this.setState({loading : false})
		})
	}
	componentWillUnmount(){
		this._isMounted = false
	}
	OnMouseEnter = () => {
		// $('body').addClass('stop-scrolling')
	}
	OnMouseLeave = () => {
		// $('body').removeClass('stop-scrolling')
	}
	onClickAgenda = () =>{
		let temp_user = utility.getLoggedInUser(this.props.alias);
		//Indicative.buildEvent('View Full Agenda', 12,[{userName: 'Wahaj'}, {email : 'dummy@gmail.com'}]);
		this.props.onClickFullAgenda()

	}
	renderAgendaTab = (props) => {
		let agenda = props.agenda
		return(
			<div style={{ display:'flex',columnGap:'10px'}}>
			<button className="agendaScroller" onClick={()=>{
				// var agendaydays = document.getElementById("homepageAgenda")
				// if(agendaydays){
				// 	agendaydays.scrollLeft +=400;
				// }
				$("#homepageAgenda").animate({ scrollLeft: "-=300" }, 1000);
			}}><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>
			<ul onMouseEnter = {this.OnMouseEnter} onMouseLeave = {this.OnMouseLeave} className="nav nav-tabs aganda_tab" id="homepageAgenda" role="tablist" style={{overflowX:"scroll"}}>
				{
					agenda.length > 0 ?
						agenda.map(function (agend,index){
						var h_id = '#agenda'+index;

						if(agend.date){
							var newdate = agend.date.split("-").reverse().join("-");
							var start_date = new Date(newdate).toDateString();
						}
						let activeClass = index == 0 ? "active" : ""
						return(
							<li className="nav-item">
								<a className={"nav-link px-3 rounded-0 "+activeClass} data-toggle="tab" href={h_id}>
									<h6 className = "text-center font-weight-bold">{agend.title}</h6>
									<p className = "text-center f-500 text-capitalize mb-0">{start_date}</p>
								</a>
							</li>
						);


						}) : null
				}
			</ul>
			<button className="agendaScroller" onClick={()=>{
				// var agendaydays = document.getElementById("homepageAgenda")
				// if(agendaydays){
				// 	agendaydays.scrollLeft -=400;
				// }
				$("#homepageAgenda").animate({ scrollLeft: "+=300" }, 1000);
				}}><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></button>
			</div>
		)
	}
	render(){
    	const  agenda = this.state.agenda;
		if(this.state.loading){
			return(
				<section className = "agenda_time pt-5 pb-2" id = "ajenda">
					<div className="container">
						<h5 className = "text-center font-weight-bold text-uppercase"><Trans>Agenda</Trans></h5>
						<ContentLoader style = {{padding : 10}} viewBox="0 0 300 25">
							<rect x="15%" y="0" rx="2" ry="2" width="70%" height="5" />
							<rect x="15%" y="10" rx="2" ry="2" width="70%" height="5" />
							<rect x="15%" y="20" rx="2" ry="2" width="70%" height="5" />
						</ContentLoader>
					</div>
				</section>
			)
		}
    	if(agenda.length > 0){

            return(
				<section className = "agenda_time pt-5 pb-2" id = "ajenda">
					<div className="container">
						<h5 className = "text-center font-weight-bold text-uppercase"><Trans>Agenda</Trans></h5>
						<div className="row mt-5">
							<div className="col-md-12">
								<div class = "tab-horizontal-agenda"></div>
                                {/* <!-- Nav tabs --> */}
								{!isMobile ?
								<PerfectScrollbar ref = {(ref)=>this.scrollRef = ref} style = {{height : 'auto',minHeight:0,maxHeight:200}}>
									<this.renderAgendaTab
										agenda = {agenda}
									/>
								</PerfectScrollbar> : 
								<div ref = {(ref)=>this.scrollRef = ref} style = {{overflowY : 'scroll',minHeight:0,maxHeight:200}}>
									<this.renderAgendaTab
										agenda = {agenda}
									/>
								</div>
								}
								{/* </div> */}
							
                                {/* <!-- Tab panes --> */}
								<div className="tab-content" id="agendaDisplay">
                                    {
                                        agenda.length > 0 ?
                                            agenda.map(function (agend,index){
                                                var h_id = 'agenda'+index;
                                                var sessions = agend.sessions.data;
                                                var conClass = index == 0 ? "container tab-pane active" : "container tab-pane fade";
                                                return(
													<div id={h_id} className={conClass} key={index}>
														<br />
														{
															sessions.length > 0 ? sessions.map(function(session,k){
																return(
																	<div className="row tab-row bg-light border py-2 mb-1" key={k}>

																		<div className="col-sm-4 col-md-2 tab-col">
																			<p className = "text-center f-500 text-capitalize mb-0">{session.start_time} - {session.end_time}</p>
																		</div>
																		<div className="col-sm-8 col-md-10">
																			<p className = "font-weight-bold mb-0 text-center text-md-left">{session.title}</p>
																		</div>
																	</div>
																);
																})
																: null
														}


													</div>
												);


											}) : null
                                    }


								</div>
							</div>
						</div>

						<div className = "row">
							<div className="col-md-12 text-center py-3">
								<a onClick = {this.onClickAgenda} style = {{cursor : 'pointer'}} className = "font-weight-bold text-uppercase text-dark text-underline see_btn">
									<Trans>viewFullAgenda</Trans></a>
							</div>
						</div>

					</div>

				</section>
            );

		}else{
			return(null);
        }//end of if
    }

}
const mapStateToProps = state => ({
    agendaTime: state.agenda.agendaTime
})
const mapDispatchToProps = dispatch => ({
    agendaTimeDataLoaded : data => dispatch(AgendaTimeDataLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(AgendaTime);