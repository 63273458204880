import React from 'react' 
import Loader from 'react-loader-spinner'
import utility from '../../../Utility/utility'
import 'bootstrap'
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import {Trans} from 'react-i18next'
export const BookingDetail = (props)=>{
    return(
        <>
        <div class="card-header"><Trans>myBookings</Trans></div>
        <div class=" border-bottom rounded-0" style = {{minHeight : 100,display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
            {props.loading ?
                <div>
                    <Loader
                        type = 'Oval'
                        color = "#00b6ff"
                        height = {30}
                        width = {30}
                    />
                </div> :
                props.bookings.length == 0 ?
                <div >
                    <Trans>noBookings</Trans>
                </div> :
                <div class="table booking_table table-responsive mb-0">
                    <table class = "w-100">
                        <tr>
                            <th><Trans>date</Trans></th>
                            <th><Trans>ticketTitle</Trans></th>
                            <th><Trans>delegates</Trans></th>
                        </tr>
                        {props.bookings.map((item,index)=>{
                            let string = item.created_at.date.replace(" ","T")
                            var date = utility.convertUTCDateToLocalDate(new Date(string))
                            var day = date.getDate()
                            var month = date.getMonth()+1
                            var year = date.getFullYear()
                            date = date.toString()
                            
                            var createdAtSplited = date.split(" ")
                            var dateString = day+'-'+month+'-'+year
                            var timeSplited = createdAtSplited[4].split(":")
                            var timePostFix = "am"
                            var hours = parseInt(timeSplited[0])
                            if(hours >= 12){
                                if(hours > 12){
                                    hours -= 12
                                }
                                timePostFix = "pm"
                            }
                            var minutes = parseInt(timeSplited[1])
                            if(minutes < 10){
                                minutes = "0"+minutes
                            }
                            return(
                                <tr>
                                    <td>{dateString} &nbsp; {hours+":"+minutes+" "+timePostFix}</td>
                                    <td>{item.TicketTitle}</td>
                                    <td>{item.delegates.length} {item.orderSummary && item.orderSummary.items && item.orderSummary.items.length > 0 ? <a class = "ml-md-1" style = {{cursor : 'pointer',color : '#0000EE'}} onClick = {()=>props.onClickSeeMore(index)}>
                                        <Trans>seeMore</Trans></a> : null}</td>
                                </tr>
                            )
                        })}
                        
                    </table>
                    {props.pagesCount.length > 0 ?
                    // <nav style = {{width : '97%',display : 'flex',justifyContent : 'flex-end'}} aria-label="Page navigation example">
                    // <ul class="pagination">
                    //     <li class="page-item">
                    //     <a onClick = {()=>props.onClickPage(props.activePage-1)} class="page-link" aria-label="Previous">
                    //         <span aria-hidden="true">&laquo;</span>
                    //         <span class="sr-only">Previous</span>
                    //     </a>
                    //     </li>
                    //     {props.pagesCount.map((item,index)=>{
                    //         return(
                    //             <li class={props.activePage == item ? "page-item active" : "page-item"}><a class="page-link" onClick = {()=>props.onClickPage(item)}>{item}</a></li>
                    //         )
                    //     })}
                    //     <li class="page-item">
                    //     <a onClick = {()=>props.onClickPage(props.activePage+1)} class="page-link" aria-label="Next">
                    //         <span aria-hidden="true">&raquo;</span>
                    //         <span class="sr-only">Next</span>
                    //     </a>
                    //     </li>
                    // </ul>
                    // </nav>
                    <div className="commentBox">
                        <ReactPaginate
                            previousLabel = '<<'
                            nextLabel = '>>'
                            pageClassName = "page-item"
                            pageLinkClassName = "page-link"
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={props.pagesCount.length}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={props.onClickPage}
                            containerClassName={'pagination justify-content-center'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            previousClassName = 'page-item'
                            previousLinkClassName = 'page-link'
                            nextClassName = 'page-item'
                            nextLinkClassName = 'page-link'
                            breakLinkClassName = 'page-link'
                        />
                    </div>
                    : null}
                </div>
            }
        </div>
        </>
    )
}