import React,{useState,useEffect} from 'react'
export default function FullSeat(props){
    const [bookedSeats] = useState(props.bookedSeats)
    const [selectedSeat,setSelectedSeat] = useState(-1)
    const [totalSeats,setTotalSeats] = useState([])
    useEffect(()=>{
        for(var i=0;i<30;i++){
            totalSeats.push(i)
        }
        setTotalSeats([...totalSeats])
    },[])
    const OnClickSeat = (index) => {
        if(bookedSeats.indexOf(index) < 0 && !props.disabled){
            props.onSeatSelected(index)
            setSelectedSeat(index)
        }
    }
    return(
        <div class="round-table full-seats">
            <p class="text-center">
                Book Seat
            </p>
            {totalSeats.map((item,index)=>{
                return(
                    <li class="list-inline-item">
                        <svg class = "spk-chair" xmlns="http://www.w3.org/2000/svg" width="58.951" height="58.893" viewBox="0 0 58.951 58.893">
                            <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(index)} class ={bookedSeats.indexOf(index) > -1 ? "spk-chair active" : selectedSeat == index ? "spk-chair selected" : "spk-chair" } id="seat" d="M12.081,39.177.921,27.035a3.564,3.564,0,0,1,.235-5.017,3.609,3.609,0,0,1,1.377-.788A3.872,3.872,0,0,1,1.2,20.282a3.606,3.606,0,0,1,.166-5.055L16.973.879A3.635,3.635,0,0,1,22.831,2.55a3.664,3.664,0,0,1,.919-1.312,3.572,3.572,0,0,1,5.026.194L39.937,13.573a3.514,3.514,0,1,1-5.174,4.756L23.6,6.187a3.859,3.859,0,0,1-.881-1.4,3.739,3.739,0,0,1-.995,1.262L6.116,20.4a3.685,3.685,0,0,1-1.342.884,3.9,3.9,0,0,1,1.32,1l11.16,12.141a3.513,3.513,0,1,1-5.173,4.755ZM6.695,21.337,22.612,6.707,33.951,19.042,18.034,33.673Z" transform="translate(31.261 58.186) rotate(-138)" fill="#80d8a6" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                        </svg>
                    </li>
                )
            })}
            <div class="opt-list mt-3 border-top">
                <ul class="list-inline pt-3">
                    <li class="list-inline-item available">
                        Available
                    </li>
                    <li class="list-inline-item booked">
                        Booked
                    </li>
                    <li class="list-inline-item select">
                        Selected
                    </li>
                </ul>
            </div>
        </div>
    )
}