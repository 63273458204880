import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from './api';
import { Constants } from '../../Constants';
import Recaptcha from 'react-recaptcha';
import {Helmet} from "react-helmet";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import utility from '../../Utility/utility';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {Trans} from 'react-i18next'
// import { GARecordEvent } from '../../../../Utility/GA'

class Content extends React.Component{

    constructor(props) {

        super(props);

        this.state = {
            ContactPersons: this.props.ContactPersons,
            ContactForm : this.props.ContactForm,
            event: this.props.event,
            name:null,
            email:null,
            phone:"",
            message:null,
            value:null,

            nameError:null,
            emailError:null,
            phoneError:null,
            recapError:null,
            messageError : null,
            masgClass : null,
            submittingForm : false

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleNameChange = this.handleChange.bind(this);
        this.recaptchaLoad = this.recaptchaLoad.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.countryDailCode = props.defaultCountry.dailCode
        this.translation = this.props.useTranslation.getResourceBundle(localStorage.getItem("language"+this.props.event.alias) || 'en')//'es'
    }//end of constructor
    componentDidMount(){
        this.captchaVerified = false
        $('#name').bind('keypress', this.testInput);
    }
    validate = () =>{

        this.verifyCallback();
        let nameError = null;
        let emailError = null;
        let phoneError = null;
        var messageError = null
        var recapError = null
        recapError = !this.captchaVerified ? <Trans>required</Trans> : null
        nameError = !this.state.name ? <Trans>enterName</Trans> : null;
        if(this.state.phone != ""){
            var phoneValid = utility.validatePhone(this.state.phone)
            phoneError = phoneValid ? null : "Please enter valid phone number.";
        }else{
            phoneError = <Trans>enterPhone</Trans>;
        }

        if(this.state.email){

            var emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            emailError = emailValid ? null : <Trans>valiEmail</Trans>;
        }else{
            emailError = <Trans>pleaseEnterMail</Trans>;
        }
        messageError = this.state.message == null || this.state.message == "" ? <Trans>enterMessage</Trans> : null
        if(emailError || nameError || phoneError || messageError || recapError){
            this.setState({emailError,nameError,phoneError,messageError,recapError});
            return false;
        }else{
            this.setState({emailError,nameError,phoneError,messageError,recapError});
            return true;
        }


    }//end of validate form

    recaptchaLoad = () => {

    }//end of recaptcha load

    verifyCallback = (response) => {
        let recapError = null;
        this.recapResponse = response
        if(!response){
            recapError = "This is required";
        }
        else{
            this.captchaVerified = true
        }
        this.setState({recapError});
    }//end of verifyCallback

    handleSubmit(event){
        event.preventDefault();
        const form = event.target.value;
        //const data = new FormData(form);

        const isValid = this.validate();

        this.props.recordEvent("test", "TEst");
        
        var data = {name:this.state.name,email:this.state.email,phone:this.state.phone,message:this.state.message};

        if(isValid){
            this.setState({submittingForm : true})
            data.phone = data.phone.replace('-',"").replace("(","").replace(")","").replace(/\s/g, "")
            api.sendEventContactForm(this.props.event.alias,data,(err,data)=>{
                if(err == null){

                    this.setState({responseMsg:data.message,submittingForm : false});

                    if(data.status == 'success'){

                        this.setState({name:'',email:'',phone:this.countryDailCode,message:'',masgClass:"alert alert-success"});
                        setTimeout(() => {
                            this.setState({responseMsg : '',masgClass : ''})
                        }, 5000);
                    }else{
                        this.setState({masgClass:"alert alert-danger"});
                    }
                }
            })

        }//end of isValid if

    }

    handleChange(event) {

        if(event.target.id == 'name'){this.setState({name: event.target.value});}
        if(event.target.id == 'email'){this.setState({email: event.target.value});}
        // if(event.target.id == 'phone'){this.setState({phone: event.target.value});}
        if(event.target.id == 'message'){this.setState({message: event.target.value});}

    }

    handleNameChange(event) {
        this.setState({name: event.target.value});
    }
    OnChangeValuePhone = (e,country) => {
        this.countryDailCode = "+"+country.dialCode
        if(e.target != null){
            this.setState({phone : e.target.value})
        }
    }
    testInput(event) {
        var value = String.fromCharCode(event.which);
        var pattern = new RegExp(/(fiktiv)|[^0-9]/g);
        return pattern.test(value);
    }
    render(){

            const ContactFrom = this.props.ContactForm;
            return(
                <div>
                    {/* <Helmet>
                    <script>{`
                        function myMap() {
                            var myCenter = new google.maps.LatLng(25.257026,55.326187);
                            var mapCanvas = document.getElementById("map");
                            var mapOptions = {center: myCenter, zoom: 16};
                            var map = new google.maps.Map(mapCanvas, mapOptions);
                            var marker = new google.maps.Marker({position:myCenter});
                            marker.setMap(map);
                        }
                    `}
                    </script>
                    <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBPzXuqco2t9tb0hoa7M6_G4p-bFisYgyQ&callback=myMap"></script>
                    </Helmet> */}
                    {
                        this.props.ContactPersons ?
                            <section class = "contact_us speaker_detail py-5">
                                <div class="container">
                                    <h5 class = "font-weight-bold text-uppercase text-center"><Trans>contactDetails</Trans></h5>
                                    <div class="row py-5 mb-3 justify-content-center" style = {{display : this.props.ContactPersons.length > 0 ? '' : 'none'}}>

                                        {
                                            this.props.ContactPersons.map(function(person,index){

                                                return(
                                                    <div class="col-md-6 col-lg-3 mb-3 mb-lg-0" key={index}>
                                                        <div class = "card_inner bg-light py-3 px-1 rounded text-center h-100 border">
                                                            <h6 class = "f-600 mem_hd w-50">{person.function}</h6>
                                                            <div class = "user_img">
                                                                {person.image != null ?
                                                                    <img src = {person.image} class = "rounded-circle" alt = "user_1-img" /> :
                                                                    <img src = '/images/profile-placeholder.png' class="rounded-circle" alt = "user_1-img"/>}
                                                            </div>
                                                            <div class = "user_caption mt-4">
                                                                <h6 class = "font-weight-bold text-capitalize">{person.name}</h6>
                                                                {person.phone != null ? <p class = "mb-0 f-500">Tel: {person.phone}</p>:null}
                                                                <p style = {{wordBreak : 'break-word'}} class = "mt-2 f-600 email">{person.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }




                                    </div>
                                </div>


                            </section>

                            :null
                    }


                    {
                        ContactFrom  ?

                            ContactFrom.length > 0 ?
                            <section class = "contact-form pt-3 py-md-5 bg-light">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <form action="" method="post" class = "bg-white py-4 px-3 border" onSubmit={this.handleSubmit} id = "con-form">
                                                <h4 class = "f-600 text-uppercase">{ContactFrom[0].title}</h4>
                                                <p class = "text-main f-600 mb-3">{ContactFrom[0].note}</p>
                                                <div class="form-group">
                                                    <label for="email"><Trans>name</Trans>*</label>
                                                    <input type="text" id="name" name="name" value={this.state.name} onChange={this.handleNameChange} class="form-control" placeholder={this.props.translation.enterName} />
                                                    {
                                                        this.state.nameError ?
                                                            <div className="alert alert-danger contactUsAlert">{this.state.nameError}</div>
                                                            :null
                                                    }

                                                </div>

                                                <div class="form-group">
                                                    <label for="pwd"><Trans>email</Trans>*</label>
                                                    <input type="email" id="email"
                                                    onKeyDown ={(e)=>{
                                                        let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                                        let keyCode = e.which || e.keyCode
                                                        if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                                            e.preventDefault();
                                                            return false
                                                        }
                                                        else{
                                                            return true
                                                        }
                    
                                                    }} 
                                                    name="email" value={this.state.email} onChange={this.handleChange} class="form-control" placeholder={this.props.translation.enterYourEmail} />

                                                    {
                                                        this.state.emailError ?
                                                            <div className="alert alert-danger contactUsAlert">{this.state.emailError}</div>
                                                            :null
                                                    }

                                                </div>

                                                <div class="form-group">
                                                    <label for="email"><Trans>phone</Trans>*</label>
                                                    {/* <input type="text" id="phone" name="phone" value={this.state.phone} onChange={this.handleChange} class="form-control" placeholder="Enter Your Phone" /> */}

                                                    <PhoneInput
                                                        inputStyle = {{width : '99.5%',marginLeft : 2,height : 'calc(2.25rem + 2px)',borderRadius : 0}}
                                                        id="phone"
                                                        country={this.props.defaultCountry.code}
                                                        value = {this.state.phone}
                                                        onChange = {(phone,country,e) => this.OnChangeValuePhone(e,country)}
                                                        jumpCursorToEnd
                                                        countryCodeEditable = {false}
                                                        // onFocus = {(phone,country,e)=> this.OnFocus('phoneNumber',country)}
                                                    />
                                                    {
                                                        this.state.phoneError ?
                                                            <div className="alert alert-danger contactUsAlert">{this.state.phoneError}</div>
                                                            :null
                                                    }
                                                </div>

                                                <div class="form-group">
                                                    <label for="email"><Trans>message</Trans>*</label>
                                                    <textarea name="message" id="message" class = "form-control" cols="20" rows="2" placeholder={this.props.translation.anything} onChange={this.handleChange} value={this.state.message}></textarea>
                                                    {
                                                        this.state.messageError ?
                                                            <div className="alert alert-danger contactUsAlert">{this.state.messageError}</div>
                                                            :null
                                                    }
                                                </div>


                                                <div class="form-group">

                                                    <Recaptcha
                                                        sitekey= {process.env.REACT_APP_RECAPTCHA_KEY}
                                                        render="explicit"
                                                        verifyCallback={this.verifyCallback}
                                                        onloadCallback={this.recaptchaLoad}
                                                        expiredCallback = {()=>this.captchaVerified = false}
                                                        hl= {localStorage.getItem("language"+this.props.event.alias)}
                                                    />

                                                    {
                                                        this.state.recapError ?
                                                            <div className="alert alert-danger contactUsAlert">{this.state.recapError}</div>
                                                            :null
                                                    }

                                                </div>
                                                <div class="form-group">
                                                    <div className={this.state.masgClass}>{this.state.responseMsg}</div>
                                                </div>

                                                <div class="form-group">
                                                    <input type="submit" class="btn send_button w-100" value = {this.translation.send} />
                                                </div>
                                                {this.state.submittingForm ?
                                                <div className = "text-center">
                                                    <Loader
                                                        type = 'Oval'
                                                        color = "#00b6ff"
                                                        height = {25}
                                                        width = {25}
                                                    />
                                                </div> : null}
                                            </form>
                                        </div>

                                        <div class="col-md-6 mb-3">
                                            <div class="company_address bg-white p-4 border">
                                                {/* <p class = "f-500">
                                                    <span class = "f-600">Address:</span> {ContactFrom[0].address}
                                                </p> */}
                                                <p class = "f-500">
                                                    {ContactFrom[0].address != null ? <>
                                                    <span class = "f-600"><Trans>address</Trans>:</span> {ContactFrom[0].address} <br/> </> : null}
                                                    {ContactFrom[0].phone_number != null ? <>
                                                    <span class = "f-600"><Trans>telephone</Trans>:</span> {ContactFrom[0].phone_number} <br /> </> : null}
                                                    {ContactFrom[0].fax != null ? <>
                                                    <span class = "f-600"><Trans>fax</Trans>:</span> {ContactFrom[0].fax} <br /> </>: null}
                                                    <span class = "f-600"><Trans>email</Trans>:</span> {ContactFrom[0].email}
                                                </p>
                                                {/* <p class = "f-500 mb-0">
                                                    <span class = "f-600">Email:</span> {ContactFrom[0].email}
                                                </p> */}
                                            </div>

                                            <div class="map-section mt-1 bg-white border p-2">
                                                <div id="map" style={{width:'100%',height:390,overflow:'hidden',position : 'relative'}}>
                                                    {ReactHtmlParser(ContactFrom[0].map)}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </section>
                                :null
                        :null
                    }


                </div>
            )



    }//end of render

}

export default Content;