import { Constants } from '../../../Constants';
import   utitlity,{ filterSearch } from '../../../Utility/utility';
import axios from '../../axios'
import * as Actions from "../actionTypes";
var fileDownload = require('js-file-download');
let content = []
export const getDataTypes = (eventAlias,params) => {
    return async(dispatch)=>{
        dispatch({type: Actions.LOADING+Actions.DATATYPES})
         return await axios.post(`${eventAlias}/marketPlace/getDataTypes`,params,{
            headers: { 'Content-Type': 'application/json','apiToken': Constants.API_TOKEN },
        }).then(response=>{
            console.log(response.data.data)
            dispatch({
                type: Actions.DATATYPES,
                payload: response.data.data
            })
        }).catch(error=>{
            dispatch({
                type: Actions.DATATYPES,
                payload: []
            })
        })
    }

}
export const getVideos = (eventAlias,params,token=null) => {
    return async(dispatch)=>{
        dispatch({type: Actions.LOADING+Actions.CONTENT})
        dispatch({type:'KNOWLEDGEHUBVIDE'})
         return await axios.post(`${eventAlias}/marketPlace/getDataTypes`,params,{
            headers: { 'Content-Type': 'application/json',
            'apiToken': Constants.API_TOKEN,
            'Authorization' : 'Bearer '+token
         },
        }).then(response=>{
            dispatch({type: Actions.FILTER_ID,payload:response.data.data[0].id})
            axios.post(`${eventAlias}/marketPlace/getData`,{
                type:'videos',
                subType:response.data.data[0].id
            },{
                headers: { 'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token 
            },
            }).then(response=>{
                // console.log(response.data.data) 
                const videosdata = response.data.data.map(item=>{
                    let videoUrl = utitlity.validateYoutubeUrl(
                        item.details.data_url
                      )
                    let thumbNails = ` https://img.youtube.com/vi/${utitlity.getYoutubeVideoId(
                        item.details.data_url
                      )}/sddefault.jpg`
                    return {filters:item.filters,details:{
                        ...item.details,
                        videoUrl:videoUrl,
                        thumbNails:thumbNails


                    }}
                })
                dispatch({
                    type: Actions.VIDEOS,
                    payload: videosdata,
                })
            }).catch(error=>{
                dispatch({
                    type: Actions.VIDEOS,
                    payload: []
                })
            })
        }).catch(error=>{
            dispatch({
                type: Actions.VIDEOS,
                payload: []
            })
        })
    }

}
export const getContent = (eventAlias,params) => {
    return(dispatch)=>{
        dispatch({type: Actions.LOADING+Actions.CONTENT})
        // dispatch({type:"KNOWLEDGEHUBVIDE"})
        axios.post(`${eventAlias}/marketPlace/getData`,params,{
            headers: { 'Content-Type': 'application/json','apiToken': Constants.API_TOKEN }
        }).then(response=>{
            content = response.data.data
            dispatch({
                type: Actions.CONTENT,
                payload: content
            })
        }).catch(error=>{
            dispatch({
                type: Actions.CONTENT,
                payload: null
            })
        })
    }
}
export const getFilters = (eventAlias,params) => {
    return(dispatch)=>{
        dispatch({type: Actions.LOADING+Actions.FILTERS})
        axios.post(`${eventAlias}/marketPlace/getFilters`,params,{
            headers: { 'Content-Type': 'application/json','apiToken': Constants.API_TOKEN }
        }).then(response=>{
            dispatch({
                type: Actions.FILTERS,
                payload: response.data.data
            })
        }).catch(error=>{
            dispatch({
                type: Actions.FILTERS,
                payload: null
            })
        })
    }
}
export const downloadDoc = (eventAlias,docId,name) => {
    return(dispatch)=>{
        dispatch({type: Actions.DOWNLOADING})
        axios.post(`${eventAlias}/marketPlace/downloadDocument`,
        {documentId: docId},{
            headers: { 'Content-Type': 'application/json','apiToken': Constants.API_TOKEN }
        }).then(response=>{
            fileDownload(response.data,name)
            dispatch({type: Actions.DOWNLOADRES})
        }).catch(error=>{
            dispatch({type: Actions.DOWNLOADRES})
        })
    }
}

export const getForumData = (eventAlias,token) => {
    console.log(eventAlias)
    return(dispatch)=>{
        dispatch({type: Actions.LOADING+Actions.DATATYPES})
         axios.get(`${eventAlias}/forum`,{
                headers: { 'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
              }
        }
         ).then(response=>{
            console.log(response.data.data)
            dispatch({
                type: Actions.FORUM_DATA,
                payload: response.data.data
            })
        }).catch(error=>{
            dispatch({
                type: Actions.FORUM_DATA,
                payload: []
            })
        })
    }

}
export const saveForum = (eventAlias,param,token,data) => {
    return(dispatch)=>{
        dispatch({type: Actions.FORUMLOADING})
         axios.post(`${eventAlias}/forum/save`,param,{
                headers: { 'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,

                'Authorization' : 'Bearer '+token
             }
        }
         ).then(response=>{
            console.log(response.data.data)
            dispatch({
                type: Actions.SAVE_FORUM,
                payload: [...response.data.data,...data]
            })
        }).catch(error=>{
            dispatch({
                type: Actions.SAVE_FORUM,
                payload: [...data]
            })
        })
    }

}
export const saveForumMessages = (eventAlias,param,token,data,id = null) => {
    return(dispatch)=>{
        dispatch({type: Actions.FORUMLOADING})
         axios.post(`${eventAlias}/forum/save`,param,{
                headers: { 'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,

                'Authorization' : 'Bearer '+token
             }
        }
         ).then(response=>{
            data.splice(id,0,...response.data.data)
            dispatch({
                type: Actions.SAVE_FORUM_MESSAGE,
                payload: [...data]
            })
        }).catch(error=>{
            dispatch({
                type: Actions.SAVE_FORUM_MESSAGE,
                payload: [...data]
            })
        })
    }

}
export const deleteForum = (eventAlias,param,token,data,id) => {
    return(dispatch)=>{
        dispatch({type: Actions.FORUMLOADING})
         axios.post(`${eventAlias}/forum/removeTopic`,param,{
                headers: { 'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,

                'Authorization' : 'Bearer '+token
             }
        }
         ).then(response=>{
            let filterData = data.filter((item=>item.id != id))
            dispatch({
                type: Actions.SAVE_FORUM,
                payload: [...filterData]
            })
        }).catch(error=>{
            dispatch({
                type: Actions.SAVE_FORUM,
                payload: [...data]
            })
        })
    }

}
export const deleteForumMessage = (eventAlias,param,token,data,id,index) => {
    return(dispatch)=>{
        dispatch({type: Actions.FORUMLOADING_MSG})
         axios.post(`${eventAlias}/forum/removeMessage`,param,{
                headers: { 'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
             }
        }
         ).then(response=>{
             console.log(data)
            dispatch({
                type: Actions.DELETE_FORUM_MESSAGE,
                payload: [...data]
            })
        }).catch(error=>{
            dispatch({
                type: Actions.DELETE_FORUM_MESSAGE,
                payload: [...data]
            })
        })
    }

}
export const getKnowedgeHubHeader = data => ({
    type: 'KNOWLEDGEHUBHEADER',
    payload : data
})
export const applyFilters = (searchText,filters) => {
    let allFilters = Object.keys(filters)
    let appliedFilter = []
    allFilters.map(item=>{
        if(filters[item].length > 0 ){
            appliedFilter[item] = filters[item]
        }
    })
    let filtered = []
    if(Object.keys(appliedFilter).length > 0){
        for(var i=0;i<content.length;i++){
            Object.keys(content[i].filters).filter((f_key)=>{
                for(var key in appliedFilter){
                    if(key == f_key){
                        appliedFilter[key].map(id=>{
                            if(content[i].filters[f_key].findIndex(x=>x.id == id) > -1){
                                filtered.push(content[i])
                            }
                        })
                    }
                }
            })
        }
    }
    else{
        filtered = content
    }

    filtered = filtered.length == 0 && Object.keys(allFilters).length == 0 ? content : filtered
    if(searchText != ''){
        filtered = filterSearch(searchText,filtered,['title','description'])
    }
    return(dispatch)=>{
        dispatch({
            type: Actions.CONTENT,
            payload: filtered
        })
    }
    
}