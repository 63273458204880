import React,{useState,useEffect} from 'react'
import Loader from 'react-loader-spinner'
import {Modal,Button} from 'react-bootstrap'
import utility, { monthNamesShort } from '../../Utility/utility'
import api from './api'
import $ from 'jquery'
import accountApi from '../Account/api'
import {ToastsContainer, ToastsStore} from 'react-toasts';
import { ToastContainer, toast,Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tr } from 'date-fns/locale'
import { withRouter } from "react-router";
import { Constants } from '../../Constants';
import  PermiumUserModal  from "../modals/premiumUserModal";
import {Trans} from 'react-i18next'
function MainView(props){
    const [showUserErrorPopup,setUserErrorPopupStatus] = useState(false)
    const [popupHeading,setPopupHeading] = useState("")
    const [popupMessage,setPopupMessage] = useState("")
    const [loadingDetail,setLoadingStatus] = useState(false)
    const [selectedMeetingIndex,setSelectedMeetingIndex] = useState(-1)
    const [showFullScreenLoader] = useState(props.showFullScreenLoader)
    var fullScreenLoadingView = false
    const [bookingError,setBookingError] = useState(null)
    if(props.selectedMeetingIndex != null){
        setTimeout(() => {
            fullScreenLoadingView = true
            OnClickJoin(props.selectedMeetingIndex,props.meetingsData.meetings[props.selectedMeetingIndex].type,props.meetingsData.meetings[props.selectedMeetingIndex].buttonUrl)
        }, 0);
        props.removeMeetingIndex()
    }
    useEffect(()=>{
        let selectedMeetingIndexTemp = localStorage.getItem("selectedMeetingIndex")
        if(selectedMeetingIndex > -1){
            selectedMeetingIndexTemp = selectedMeetingIndex
        }
        if(selectedMeetingIndexTemp != null){
            let user = utility.getLoggedInUser(props.eventAlias)
            if(user != null){
                OnClickJoin(selectedMeetingIndexTemp,props.meetingsData.meetings[selectedMeetingIndexTemp].type,props.meetingsData.meetings[selectedMeetingIndexTemp].buttonUrl)
            }
            localStorage.removeItem("selectedMeetingIndex")
        }
    },[])
    const OnClickJoin = (index,type,url,meetingName) =>{
        props.joinGalaButton(meetingName)
        // /console.log("PROPS",props)
        if(loadingDetail){
            return
        }
        setSelectedMeetingIndex(index)
        props.onClickJoin(index,type)
        let user = utility.getLoggedInUser(props.eventAlias)
        if(user == null){
            localStorage.setItem("selectedMeetingIndex",index)
            setUserErrorPopupStatus(true)
            setPopupHeading("Login Required")
            setPopupMessage("You must be logged in to proceed")
            $("#NetworkingpremiumSession").modal('show');
        }else{
            checkUserBookingStatus(index,type,url)
        }
    }
    const checkUserBookingStatus = (index,type,url) => {
        let user = utility.getLoggedInUser(props.eventAlias)
        let hasPurchasedTicket = false
        let paymentInProgress = false
        setLoadingStatus(true)
        accountApi.getMyBookings(props.eventAlias,user.token,(err,bookings)=>{
            if(err == null){
                for(var i=0;i<bookings.length;i++){
                    if(bookings[i].orderSummary.status.toLowerCase() == "completed"){
                        paymentInProgress = false
                        hasPurchasedTicket = true
                        break
                    }else{
                        if(bookings[i].orderSummary.type == "invoice" && bookings[i].orderSummary.status.toLowerCase() != "failed"){
                            paymentInProgress = true
                        }else{
                            paymentInProgress = false
                        }
                    }
                }
                if(hasPurchasedTicket){
                    getMeetingDetail(type,url)
                }else{
                    let errorMessage = "You need to have booked at least one paid package to access this content"
                    if(paymentInProgress){
                        errorMessage = "This session and content is only available for paid ticket holders, Your transaction is in progress and once payment is confirmed you can access"
                    }
                    localStorage.setItem("selectedMeetingIndex",index)
                    setLoadingStatus(false)
                    setBookingError({
                        redirect : !paymentInProgress
                    })
                    setUserErrorPopupStatus(true)
                    setPopupHeading("Paid Delegate Session")
                    setPopupMessage(errorMessage)
                    $("#NetworkingpremiumSession").modal('show');
                }
            }else{
                if(err == -1){
                    setUserErrorPopupStatus(true)
                    setPopupHeading('Login Required')
                    setPopupMessage('Your session is expired please login again')
                    $("#NetworkingpremiumSession").modal('show');
                    localStorage.removeItem(Constants.API_TOKEN+props.event.alias+'user_info')
                }
            }
        })
    }
    const setMeetingDifferntTimeArray = (time) =>{
        let timesObjArary = []
        if(time != null){
            let times = time.split("/")
            for(var j=0;j<times.length;j++){
                let timeSplit = times[j].trim().split(" ")
                let ampm = timeSplit[1].toLowerCase()
                ampm = ampm.charAt(0).toUpperCase() + ampm.slice(1)
                let time = timeSplit[0]+ampm
                let country = timeSplit[2]
                var obj = {
                    time : time,
                    country : country
                }
                timesObjArary.push(obj)
            }
        }
        return timesObjArary
    }
    const checkMeetingExpiray = (meeting) => {
        let splitDate = meeting.date.split("/")
        meeting.dateString = splitDate[1] + " " + monthNamesShort[parseInt(splitDate[0] - 1)] + " " +splitDate[2]
        let currentDate = new Date()
        let meetingDate = new Date(meeting.date).getTime()
        let currentUTCTimeInMins = currentDate.getUTCHours() * 60 + currentDate.getMinutes()
        let meetingTimeSplitArr = meeting.time.split(" ")
        let hour = parseInt(meetingTimeSplitArr[0])
        let additionalHours = meetingTimeSplitArr[3].toLowerCase() == "am" ? hour == 12 ? -12 : 0 : hour != 12 ? 12 : 0
        let meetingTimeInMins = (hour + additionalHours) * 60 + parseInt(meetingTimeSplitArr[2])
        currentDate = currentDate.setHours(0,0,0,0)
        if(meetingDate < currentDate){
            meeting.isEnd = true
        }else if(meetingDate == currentDate){
            if(meetingTimeInMins < currentUTCTimeInMins){
                meeting.isEnd = true 
            }
        }
    }
    const getMeetingDetail = (type,url) => {
        api.getMeetingDetail(props.eventAlias,url,(err,data)=>{
            if(err == null){
                if(type == "101meeting"){
                    getUserBookedSlots(type,data.speakers)
                }
                else if(type == "roundtable"){
                    for(var i=0;i<data.roundTable.length;i++){
                        checkMeetingExpiray(data.roundTable[i])
                        data.roundTable[i].totalTimeArray = setMeetingDifferntTimeArray(data.roundTable[i].totalTime)
                        data.roundTable[i].seatingStyle =  data.roundTable[i].type
                    }
                    props.dataLoaded(type,data.roundTable,null,!fullScreenLoadingView)
                }else if(type == "gala"){
                    checkMeetingExpiray(data.networkGala)
                    data.networkGala.totalTimeArray = setMeetingDifferntTimeArray(data.networkGala.totalTime)
                    props.dataLoaded(type,data.networkGala,null,!fullScreenLoadingView)
                }
            }else{
                if(err.isEnable == 0){
                    // props.removeMeeting(type)
                    toast.error('This meeting is disabled by admin', {
                        position: "top-right",
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoadingStatus(false)
            }
        })
    }
    const getUserBookedSlots = (type,speakers) => {
        let user = utility.getLoggedInUser(props.eventAlias)
        api.getUserBookedSlots(props.eventAlias,user.id,(err,data)=>{
            if(err == null){
                props.dataLoaded(type,speakers,data,!fullScreenLoadingView)
            }
            setLoadingStatus(false)
        })
    }
    const OnNavigateToPage = (path,data=null) =>{
        console.log(path)
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data){
            props.history.push({
                pathname: path,
                state: data
            })
        }else{
            props.history.push(path)
        }
    }
    const handleClosePopup = (performAction = false) => {
        setUserErrorPopupStatus(false)
        if(!performAction){
            return
        }
        if(bookingError == null){
            localStorage.removeItem(Constants.API_TOKEN+props.eventAlias+'user_info')
            props.navigate(props.eventAlias+"/login")
        }else{
            if(bookingError.redirect){
                props.navigate(props.eventAlias+"/booking")
            }
        }
        setBookingError(null)
    }
    return(
        <>
        <ToastContainer
            role = "alert"
            position="top-right"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            transition = {Slide}
        />
         <PermiumUserModal
        id="NetworkingpremiumSession"
        iconClass="fa-lock"
        title={popupHeading}
        description={popupMessage}
        buttonText="Ok"
        backdrop='static'
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#NetworkingpremiumSession").modal('hide')
        $('.modal-backdrop').remove();
          }}
        onClickBookNow={() => {
        handleClosePopup(true)
          $("#NetworkingpremiumSession").modal("hide");
         $(".modal-backdrop").remove();
        //  props.navigate(props.eventAlias+"/login")
        // OnNavigateToPage(`${props.eventAlias}/login`);
        }}/>
        {!showFullScreenLoader ?
        <section class = "blog networking py-3 py-md-5">
            <div class="container">
                <h1>
                    {props.meetingsData.pageTitle != null ? props.meetingsData.pageTitle : props.eventName}
                </h1>
                <p>
                    {props.meetingsData.subtitle != null ? props.meetingsData.subtitle : <Trans>greatOpportunity</Trans>}
                </p>
                <div class="row justify-content-center mt-3">
                    {props.meetingsData.meetings.map((item,index)=>{
                        let meetingName = item.meeting_heading
                        if(meetingName == null){
                            if(item.type == "101meeting"){
                                meetingName = "101 meeting"
                            }else if(item.type == "roundtable"){
                                meetingName = "Round table meeting"
                            }else{
                                meetingName = "Networking Gala"
                            }
                        }
                        return(
                            <div class="col-md-4 mb-3 mb-md-0">
                                <div class="thumbnail network-card">
                                    <div style = {{backgroundImage : `url(${item.image})`}} class = "img-sec" id = "one-o-one_img">
                                        {/* <img src = {item.image} alt = {`card${index}-img`}/> */}
                                        <h2>
                                            {meetingName}
                                        </h2>
                                    </div>
                                    <div class = "details-sec p-3 text-center">
                                        <a style = {{cursor : !loadingDetail ? 'pointer' : 'not-allowed'}} onClick = {()=>OnClickJoin(index,item.type,item.buttonUrl,meetingName)} class = "btn btn-join-network">
                                            {item.buttonTitle}
                                            {loadingDetail && selectedMeetingIndex == index ?
                                            <div style = {{float : 'right',marginRight : 0,display : selectedMeetingIndex == index ? 'block' : 'none'}}>
                                                <div class="spinner-border" style = {{width : '1.3rem',height : '1.3rem'}} role="status">
                                                    <span class="sr-only"><Trans>loading</Trans></span>
                                                </div>
                                            </div> : 
                                            <i class="fas fa-arrow-right ml-5 float-right mt-1"></i>}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section> :
        !showUserErrorPopup ?
        <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
            <Loader
                type = 'Oval'
                color = "#00b6ff"
                height = {40}
                width = {40}
            />
        </div> : null}
        {/* <Modal 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showUserErrorPopup} 
            onHide={()=>handleClosePopup(true)}
        >
            <Modal.Header>
            <Modal.Title>{popupHeading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{popupMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="telehealth-btn" onClick={()=>handleClosePopup(true)}>
                   <Trans>close</Trans>
                </Button>
            </Modal.Footer>
        </Modal> */}
        </>
    )
}
export default withRouter(MainView)