export const AgendaDataLoaded = data => ({
    type: 'AGENDA_DATA_LOADED',
    payload : data
})
export const HighLightsDataLoaded = data => ({
    type: 'HIGHLIGHTS_DATA_LOADED',
    payload : data
})
export const AgendaTimeDataLoaded = data => ({
    type: 'AGENDA_TIME_DATA_LOADED',
    payload : data
})
export const userChangeTimeZone = data =>({

    type: 'USER_SELECTED_TIMEZONE',
    payload:data
})