import React from 'react';
import $ from 'jquery'
import 'bootstrap'
import utility from '../../Utility/utility';
import api from '../RegisterModel/api';
import Loader from 'react-loader-spinner'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Constants } from '../../Constants';
import {Trans} from 'react-i18next'
import { GAPageView, GARecordEvent } from '../../Utility/GA'

export class RegisterModel extends React.Component{
	_isMounted = false
	constructor (props) {
		super(props)
		this.state = {
			email : '',
			firstName : '',
			lastName : '',
			companyName : '',
			phoneNumber : '',
			jobTitle : '',
			eEmail : "",
			eFirstName : "",
			eLastName : "",
			eCompanyName : "",
			modelMasg : <Trans>modalMsg</Trans>,

            aEmail : "",
            aFirstName : "",
            aLastName : "",
            aCompanyName : "",
            aJobTitle : "",
            aMobileNumber : "",
			amodelMasg : <Trans>detailsReceived</Trans>,
			downloadingAgenda : false,
			registeringInterest : false,
			mobileNumber : "",
			agendaTitle : props.agendaTitle
		}
	}
	componentDidMount () {
		this._isMounted = true
		$('#registerFirstName').bind('keypress', this.testInput);
		$('#registerLastName').bind('keypress', this.testInput);
		$('#agandaFirstName').bind('keypress', this.testInput);
		$('#agandaLastName').bind('keypress', this.testInput);
		let user = utility.getLoggedInUser(this.props.eventAlias)
		if(user != null){
			let mobileNumber = user.phone != null ? user.phone : ''
			this.setState({email : user.email,firstName : user.firstName,lastName : user.lastName,companyName : user.companyName,jobTitle : user.jobTitle,mobileNumber})
		}
	}
	componentWillUnmount(){
		this._isMounted = false
	}
	OnChangeTextField = (field,e) => {
		if(field == "email"){
			this.setState({email : e.target.value})
		}
		if(field == "firstName"){
			this.setState({firstName : e.target.value})
		}
		if(field == "lastName"){
			this.setState({lastName : e.target.value})
		}
		if(field == "company"){
			this.setState({companyName : e.target.value})
		}
		if(field == "job"){
			this.setState({jobTitle : e.target.value})
		}
	}
	OnClickRegister = () => {
		
		var email = this.state.email
		var firstName = this.state.firstName
		var lastName = this.state.lastName
		var companyName = this.state.companyName

		this.props.registerInterestSubmitEvent(firstName+''+lastName, email)

		var canRegister = true

		if(!utility.validateEmail(email)){
			this.setState({eEmail : "please enter valid email"})
			canRegister = false
		}
		else{
			this.setState({eEmail : ""})
		}
		if(firstName == ""){
			this.setState({eFirstName : "please enter valid first name"})
			canRegister = false
		}
		else{
			this.setState({eFirstName : ""})
		}
		if(lastName == ""){
			this.setState({eLastName : "please enter valid last name"})
			canRegister = false
		}
		else{
			this.setState({eLastName : ""})
		}
		if(companyName == "" || companyName === null){
			this.setState({eCompanyName : "please enter valid compnay name"})
			canRegister = false
		}
		else{
			this.setState({eCompanyName : ""})
		}

		if(canRegister){

			var obj = {
                email:email,
                firstName:firstName,
				lastName:lastName,
				companyName:companyName,
				type : 'register-interest'
			};
			this.setState({registeringInterest : true})
            api.sendRegisterInterestUsers(this.props.eventAlias,obj,(err,data)=>{
                if(err == null && this._isMounted){
					if(data.status == "failed"){
                        this.setState({modelMasg : "Something went worng please try again."})
					}
					this.setState({registeringInterest : false})
                    $('#register_Modal').modal('hide');
                    $('.modal-backdrop').remove();

					$('#message_Modal').modal('show');
					setTimeout(() => {
						$('#message_Modal').modal('hide');
                    	$('.modal-backdrop').remove();
					}, 4000);
                }
            })


		}
	}//end of register model function

    OnClickAgandaDownload = () => {
		//this.props.downloadAgendaFormEvent()

        var email = this.state.email
		var firstName = this.state.firstName
		var lastName = this.state.lastName
		var companyName = this.state.companyName
        var jobTitle = this.state.jobTitle
        var mobileNumber = this.state.mobileNumber

		let name=firstName+""+lastName;	
	
		this.props.downloadAgendaFormEvent(firstName,email)
        //this.props.registerInterestSubmitEvent(firstName+''+lastName, email)
		var canRegister = true
        if(!utility.validateEmail(email)){
            this.setState({aEmail : <Trans>validEmail</Trans>})
            canRegister = false
        }
        else{
            this.setState({aEmail : ""})
        }
        if(firstName == ""){
            this.setState({aFirstName : <Trans>enterFirstName</Trans>})
            canRegister = false
        }
        else{
            this.setState({aFirstName : ""})
        }
        if(lastName == ""){
            this.setState({aLastName : <Trans>enterLastName</Trans>})
            canRegister = false
        }
        else{
            this.setState({aLastName : ""})
        }
        if(companyName == "" || companyName===null){
            this.setState({aCompanyName : <Trans>enterValidCompany</Trans>})
            canRegister = false
        }
        else{
            this.setState({aCompanyName : ""})
        }

        if(jobTitle == "" || jobTitle === null){
            this.setState({aJobTitle : "please enter valid job title"})
            canRegister = false
        }else{
            this.setState({aJobTitle : ""})
		}

        if(!utility.validatePhone(mobileNumber)){
            this.setState({aMobileNumber : "please enter valid job title"})
            canRegister = false
        }else{
			this.setState({aMobileNumber : ""})
        }
        if(canRegister){

            var obj = {
                email:email,
                firstName:firstName,
                lastName:lastName,
                companyName:companyName,
				jobTitle : jobTitle,
				mobileNumber : mobileNumber
			};
			if(this.state.downloadingAgenda){
				return
			}
			this.setState({downloadingAgenda : true})
			if(this.props.onClickSubmit != null){
				this.props.onClickSubmit(obj)
			}else{
				api.sendAgandaDownload(this.props.eventAlias,obj,(err,data)=>{
					if(err == null){
						if(data.agendaDownloaded){
							$('#message_Modal').modal('hide');
							$('.modal-backdrop').remove();
							return
						}

						this.setState({downloadingAgenda : false})
						if(data.status == "failed"){
							this.setState({modelMasg : <Trans>tryAgain</Trans>})
						}else{
							this.setState({modelMasg : <Trans>downloadingPdf</Trans>})
						}
						$('#aganda_Modal').modal('hide');
						$('.modal-backdrop').remove();
	
						$('#message_Modal').modal('show');
						setTimeout(() => {
							$('#message_Modal').modal('hide');
							$('.modal-backdrop').remove();
						}, 4000);
					}
				})
			}
        }
    }//end of aganda downlaod model
	OnChangeValuePhone = (e) => {
		if(e.target != null){
			this.setState({mobileNumber : e.target.value})
		}
	}
	testInput(event) {
        var value = String.fromCharCode(event.which);
        var pattern = new RegExp(/(fiktiv)|[^0-9]/g);
        return pattern.test(value);
	}
	OnApiResponse = (val) => {
		this.setState({downloadingAgenda : false})
	}
	OnClickCloseModal = () => {
		if(this.props.onClickClose != null){
			this.props.onClickClose()
		}
	}
	render(){
        return(
            <div>
  <div class="modal fade" id="register_Modal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        
        <div class="modal-body">
			<h6 class = "text-uppercase f-600 mb-0"><Trans>registerInterest</Trans></h6>
			<button type="button" class="close mdl_close" data-dismiss="modal">&times;</button>
			<section class = "order-form py-1">
					<div class="container">

						<div class="row mt-3 bg-light px-3 py-3 border">
						
								<div class="form-group col-md-12 mt-3">
								   
								   <p>
										<Trans>notAttending</Trans>
								   </p>
								   
								   <p class = "f-14"><Trans>allWorkRequired</Trans> <span class = "text-red f-600">( <i class="fas fa-star-of-life"></i> )
								   </span> <Trans>infoBelow</Trans></p>
								   
								</div>
								 <div class="form-group col-md-6">
									<label class = {this.state.eFirstName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> 
									<Trans>firstName</Trans></label>
									<input value = {this.state.firstName} onChange = {(e)=>this.OnChangeTextField("firstName",e)} id = "registerFirstName" class={this.state.eFirstName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
								 </div>
								 
								 <div class="form-group col-md-6">
									<label class = {this.state.eLastName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
									<Trans>lastName</Trans></label>
									<input value = {this.state.lastName} onChange = {(e)=>this.OnChangeTextField("lastName",e)} id = "registerLastName" class={this.state.eLastName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
								 </div>
								 <div class="form-group col-md-6">
									<label class = {this.state.eEmail != "" ? "text-red" : ""} ><i class="fas fa-star-of-life text-red"></i>
									<Trans>email</Trans></label>
									<input value = {this.state.email} onChange = {(e)=>this.OnChangeTextField("email",e)} id = "registerEmail" type="email" class={this.state.eEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} 
									onKeyDown ={(e)=>{
										let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
										let keyCode = e.which || e.keyCode
										if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
											e.preventDefault();
											return false
										}
										else{
											return true
										}
	
									}}/>
								 </div>
								 <div class="form-group col-md-6">
									<label class = {this.state.eCompanyName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
									<Trans>companyName</Trans> </label>
									<input value = {this.state.companyName} onChange = {(e)=>this.OnChangeTextField("company",e)} id = "registerCompanyName" class={this.state.eCompanyName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
								 </div>
								 
								 <div class="form-group col-12 text-right mt-3">
									<a onClick = {this.OnClickRegister}  class="btn bg_primary"><Trans>register</Trans></a>
									{this.state.registeringInterest ? 
									<div style = {{position : 'absolute',right : 115,top : 6}}>
										<Loader
											type = 'Oval'
											color = "#00b6ff"
											height = {25}
											width = {25}
										/>
									</div> :null}
								 </div>

						</div>

					</div>
			</section>	
        </div>
        
      </div>
    </div>
  </div>

				{/* Aganda Download model */}

				<div class="modal fade" id="aganda_Modal" data-keyboard={this.props.static ? "false" : "true"} data-backdrop={this.props.static ?"static" : "true"}>
					<div class="modal-dialog modal-dialog-centered modal-lg">
						<div class="modal-content">

                            {/* Register Modal body */}
							<div class="modal-body">
								<h6 class = "text-uppercase f-600 mb-0">{this.state.agendaTitle}</h6>
								{this.props.showCloseButton ?
								<button onClick = {this.OnClickCloseModal} type="button" class="close mdl_close" data-dismiss="modal">&times;</button> : null}
								<section class = "order-form py-1">
									<div class="container">

										<div class="row mt-3 bg-light px-3 py-3 border">

											<div class="form-group col-md-12 mt-3">

												<p>{this.props.agendaSubTitle}</p>

												<p class = "f-14"><Trans>allWorkRequired</Trans>
												<span class = "text-red f-600">( <i class="fas fa-star-of-life"></i> )</span> 
												<Trans>infoBelow</Trans></p>

											</div>
											<div class="form-group col-md-6">
												<label class = {this.state.aFirstName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> 
												<Trans>firstName</Trans></label>
												<input value = {this.state.firstName} onChange = {(e)=>this.OnChangeTextField("firstName",e)} id = "agandaFirstName" class={this.state.aFirstName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
											</div>

											<div class="form-group col-md-6">
												<label class = {this.state.aLastName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
												<Trans>lastName</Trans></label>
												<input value = {this.state.lastName} onChange = {(e)=>this.OnChangeTextField("lastName",e)} id = "agandaLastName" class={this.state.aLastName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
											</div>
											<div class="form-group col-md-6">
												<label class = {this.state.aEmail != "" ? "text-red" : ""} ><i class="fas fa-star-of-life text-red"></i>
												<Trans>email</Trans></label>
												<input value = {this.state.email} onChange = {(e)=>this.OnChangeTextField("email",e)} id = "agandaEmail" type="email" class={this.state.aEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}
												onKeyDown ={(e)=>{
													let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
													let keyCode = e.which || e.keyCode
													if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
														e.preventDefault();
														return false
													}
													else{
														return true
													}
				
												}} />
											</div>
											<div class="form-group col-md-6">
												<label class = {this.state.aCompanyName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
												<Trans>companyName</Trans></label>
												<input value = {this.state.companyName} onChange = {(e)=>this.OnChangeTextField("company",e)} id = "agandaCompanyName" class={this.state.aCompanyName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
											</div>

											<div class="form-group col-md-6">
												<label class = {this.state.aJobTitle != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
												<Trans>jobTitle</Trans></label>
												<input value = {this.state.jobTitle} onChange = {(e)=>this.OnChangeTextField("job",e)} id = "agandaJobTitle" class={this.state.aJobTitle != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
											</div>

											<div class="form-group col-md-6">
												<label class = {this.state.aMobileNumber != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>
												<Trans>mobNumber</Trans> </label>
												 {/* <input id = "agandaMobileNumber" class={this.state.aMobileNumber != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} /> */}
												<PhoneInput
													inputStyle = {{width : '99.5%',marginLeft : 2,height : 'calc(2.25rem + 2px)',borderRadius : 0}}
													inputClass = {this.state.aMobileNumber != "" ? "text-red-border" : "phone-picker"}
													id="phone"
													country={Constants.defaultCountry.code}
													value = {this.state.mobileNumber}
													onChange = {(phone,country,e) => this.OnChangeValuePhone(e,country)}
													jumpCursorToEnd
													countryCodeEditable = {false}
													// onFocus = {(phone,country,e)=> this.OnFocus('phoneNumber',country)}
												/>
											</div>

											<div class="form-group col-12 text-right mt-3">
												
												<a onClick = {this.OnClickAgandaDownload} class="btn bg_main">
												{this.state.downloadingAgenda ? <div style = {{position : 'absolute',right : 130}}>
													<Loader
														type = 'Oval'
														color = "#00b6ff"
														height = {25}
														width = {25}
													/>
												</div> : null}
												{this.props.agendaSubmitButtonText}</a>
											</div>

										</div>

									</div>
								</section>
							</div>

						</div>
					</div>
				</div>

  {/* Show alert messages */}

  <div class="modal fade" id="message_Modal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        
        {/** Register Modal body */}
        <div class="modal-body">
			<h6 class = "text-uppercase f-600 mb-0"><Trans>thankyou</Trans></h6>
			<button type="button" class="close mdl_close" data-dismiss="modal">&times;</button>
			<section class = "order-form py-1">
					<div class="container">

						<div class="row mt-3 bg-light px-3 py-3 border">
						
								<div class="form-group col-md-12 mt-3">
								   
								   <p>{this.state.modelMasg}</p>
								   
								</div>

						</div>

					</div>
			</section>	
        </div>
        
      </div>
    </div>
  </div>
            </div>
        );
    }
}

export default RegisterModel;