const agenda = (state = {}, action) => {
    switch (action.type) {
        case 'NETWORKING_MEETINGS_LOADED' : 
            return{
            ...state,
            meetings : action.payload
            }
        default:
            return state
        }
  }
  export default agenda