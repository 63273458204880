import React from 'react' 
import Loader from 'react-loader-spinner'
import {Trans} from 'react-i18next'
export const ChangePassword = (props)=>{
    const [oldPassword,showOldPassword] = React.useState(false)
    const [newPassword,shownewPassword] = React.useState(false)
    const [confirmNewPassword,setConfirmPassword] = React.useState(false)
    return(
        <>
        <div class="card-header"><Trans>changePassword</Trans></div>
        <div class="card-body border-bottom rounded-0">
            <form action = "">
                {props.passwordSet == 1 ?
                <div class="form-group row">
                    <div class="col-md-6">
                        <label ><Trans>oldPassword</Trans><span class="text-danger">*</span></label>
                        <div className="password-design" style={{justifyContent:"center",alignItems:`${props.eOldPassword != '' ? "flex-start" : 'center'}`}}>
                            <div style={{width:"100%"}}>
                        <input defaultValue = "" disabled = {props.loading} id = "oldPassword" type="password" class= {props.eOldPassword == ""?"form-control":"form-control text-red-border"}  />
                        {props.eOldPassword != '' ? <div class="invalid-feedback">{props.eOldPassword}</div> : null}
                        </div>
                        <div onClick={(e)=>{
                            
                            showOldPassword(!oldPassword)
                            let oldPasswordbox = document.getElementById("oldPassword")
                            if(oldPasswordbox){
                                oldPasswordbox.type == "password" ? oldPasswordbox.type = "text" : oldPasswordbox.type = "password"
                            }

                        }}>{
                            oldPassword == false ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>:
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"/></svg>}</div>
                        </div>
                    </div>
                </div> : null}

                <div class="form-group row">
                    <div class="col-md-6">
                        <label  for=""><Trans>newPassword</Trans><span class="text-danger">*</span></label>
                        <div className="password-design" style={{justifyContent:"center",alignItems:`${props.ePassword != '' ? "flex-start" : 'center'}`}} onClick={(e)=>{
                            
                            shownewPassword(!newPassword)
                            let newPasswordbox = document.getElementById("newPassword")
                            if(newPasswordbox){
                                newPasswordbox.type == "password" ? newPasswordbox.type = "text" : newPasswordbox.type = "password"
                            }

                        }} >
                             <div style={{width:"100%"}}>
                        <input defaultValue = "" disabled = {props.loading} id = "newPassword" type="password" class= {props.ePassword == ""?"form-control":"form-control text-red-border"} />
                        {props.ePassword != '' ? <div class="invalid-feedback">{props.ePassword}</div> : null}
                        </div>
                        {newPassword == false ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>:
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"/></svg>}</div>
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for=""><Trans>confirmPassword</Trans><span class="text-danger">*</span></label>
                        <div className="password-design" style={{justifyContent:"center",alignItems:`${props.eCPassword != '' ? "flex-start" : 'center'}`}} onClick={(e)=>{
                            
                            setConfirmPassword(!confirmNewPassword)
                            let newPasswordbox = document.getElementById("confirmPassword")
                            if(newPasswordbox){
                                newPasswordbox.type == "password" ? newPasswordbox.type = "text" : newPasswordbox.type = "password"
                            }

                        }} >
                             <div style={{width:"100%"}}>
                        <input defaultValue = "" disabled = {props.loading} id = "confirmPassword" type="password" class= {props.eCPassword == ""?"form-control":"form-control text-red-border"}  />
                        {props.eCPassword != '' ? <div class="invalid-feedback">{props.eCPassword}</div> : null}
                        </div>
                    
                    {confirmNewPassword == false ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/></svg>:
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.21 0-4 1.791-4 4s1.79 4 4 4c2.209 0 4-1.791 4-4s-1.791-4-4-4zm-.004 3.999c-.564.564-1.479.564-2.044 0s-.565-1.48 0-2.044c.564-.564 1.479-.564 2.044 0s.565 1.479 0 2.044z"/></svg>}</div>
                </div>
                </div>
                <div style = {{display : 'flex',flexDirection : 'row'}}>  
                    <div class="form-group">
                        <a style = {{pointerEvents : props.loading ? 'none' : 'auto'}} onClick = {props.onClickSavePassword} class="btn bg_main btn-tele text-light px-5 rounded-0">
                            <Trans>save</Trans></a>
                    </div>
                    {props.loading ?
                    <div style = {{marginTop : 6,marginLeft : 12}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {30}
                            width = {30}
                        />
                    </div> : null}
                </div>
                {props.changePassError != '' ?
                    <div className="alert alert-danger contactUsAlert">{props.changePassError}</div>:null}
                {props.successMessage != '' ?
                    <div className="alert alert-success contactUsAlert">{props.successMessage}</div>:null}
            
            </form>
        </div>
        </>
    )
}