import React from 'react'
import Footer2 from '../../Footer/Footer2'
import Header from '../../Header/Index';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {getDataTypes,getContent,getKnowedgeHubHeader} from '../actions/index'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import utitlity, { wordsRestrictor } from '../../../Utility/utility';
import Content from './Content';
import { Constants } from "../../../Constants";
import PremiumContent from '../preminumContent/';
import VirtualZone from "../../Header/virtualZone";

import {fetchPagesData} from '../api'

class KnowedgeHub extends React.Component{

    _isMounted = false
    constructor(props){
        super(props);
        this.state = {
            event: {},
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/knowledge-hub") > -1),
            renderComponents : true,
            selectedSubmenuIndex : -1,
            availableLocales : this.props.availableLocales,
			contentType:[],
			virtualZoneItemIndex : this.props.virtualMenuItems.findIndex(x=>x.path == '/knowledge-hub'),
			loading:false,
			selectedType: null,
			pageTitle:'',
			description:''
        }
	this.user = utitlity.getLoggedInUser(props.event.alias)
    }
    OnNavigateToPage = (path,data=null) =>{
        console.log(path)
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data){
            this.props.history.push({
                pathname: path,
                state: data
            })
        }else{
            this.props.history.push(path)
        }
    }
	OnClickAccountItem = (index,path) => {
        this.props.onClickAccountItem(index,path);  
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
	 async componentDidMount(){
		window.scrollTo(0,0)
		if(this.props.knowledgehubHeaderdata != null){
			this.setState({
				pageTitle:this.props.knowledgehubHeaderdata.title,
				description:this.props.knowledgehubHeaderdata.description
			})
		}
		else{let prams = {
			type:'knowledge_hub'
		}
		 await fetchPagesData(this.props.event.alias,prams,null,(err,data)=>{
			if(data){
				this.setState({
					pageTitle:data.data.title,
					description:data.data.description
				})
				this.props.getKnowedgeHubHeader(data.data)
			}
		})}
		if(this.props.contentType.length > 0 ){
            this.setState({contentType:this.props.contentType})
		}else{
        let params = {
            type:'documents'
        }
        this.props.getDataTypes(this.props.event.alias,params)
	}
    }

	componentWillReceiveProps(newProps){
		window.scrollTo(0,0)
		this.setState({contentType:newProps.contentType,loading:newProps.loading})
	}

    render(){
        return (
		<>  
		   <div id="header-top">
           <Header 
				selectedIndex = {this.state.selectedIndex}
				onMenuItemClick = {this.OnNavigateToPage} 
				selectedSubIndex = {-1} 
				elements = {this.props.headerMenuItems}
				isMarketplace = {this.props.event.is_marketplace}
				facebookLink = {this.props.event.facebook}
				linkedInLink = {this.props.event.linkedIn}
				twitterLink = {this.props.event.twitter}
				instagramLink = {this.props.event.instagram}
				event = {this.props.event}
				googleLink = {this.props.event.google}
				phone = {this.props.event.phone_number}
				eventLogo = {this.props.event.logo}
				eventName = {this.props.event.name}
				eventAlias = {this.props.event.alias}
				availableLocales = {this.state.availableLocales}
				languageClick  = {this.languageClick}
				onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
				onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
				onClickAccountItem = {this.OnClickAccountItem}
			/>
			{this.user != null && this.user.isBooking && Constants.showVirtualZone?
            <VirtualZone
			     selectedItemIndex = {this.state.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
				event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }}  
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                    if(isExternal){
                        window.open(path,"_blank")
                    }else{
                        this.OnNavigateToPage(this.props.event.alias+path)
                    }
                }}
                menuItems = {this.props.virtualMenuItems}
                user = {this.user} 
            /> : null}
			</div>
			{this.state.loading != true ?
			!this.state.selectedType ?
			<>
			<section class = {`telehealth-inner-banner bg_primary py-3`}>
				<div class = "container py-2 text-center">
					<div class = "row">
						<div class = "col-12">
							<h2>
								<img class = "mr-3" src = "/images/logos/ic-sm-knowledge.svg" 
								alt = "kh-icon"/>
								{this.state.pageTitle}
							</h2>
						</div>
					</div>
				</div>
			</section>

			<section class = "blog knowledge-hub py-3">
				<div class="container text-center py-3">
					<p>
                      {this.state.description}
					</p>
					<div class="row">
						{this.state.contentType.length>0 ?
						this.state.contentType.map(item=>{
						return <div class="col-md-6 col-lg-3 mb-3 mb-lg-0">
							<div class="thumbnail h-100">
								<div class = "thumbnail-img">
									{/* <img src = "/images/logos/ic-presentation.svg" 
									alt = "icon-card"/> */}
									<img src = {`${process.env.REACT_APP_API_ENDPOINT.replace('api/v1/','')}${item.image}`} 
									alt = "icon-card"/>
								</div>
								<div class="caption">
									<h6 class = "text-main my-3">
										{item.type}
									</h6>
									<p>
										{item.description}
									</p>
									<a onClick={()=> this.setState({selectedType: item})} class = "btn d-block mx-auto rounded-0">
									Discover more &#8594;
									</a>
								</div>
							</div>
						</div>}):  null}
						
						
					</div>
				</div>
			</section>


			{/* <section class = "middle-banner text-center">
				<div class="container">
					<div class="row">
						<div class="col-sm-12">
							<p>
								<img src="/images/networking/ic-diamond.svg" alt="icon-diamond"/>
							</p>
							<p>
								Interested to access Premium Content?
							</p>
							<a href="#" class = "btn">
								Become a Member
							</a>
						</div>
					</div>
				</div>
			</section> */}
			<PremiumContent alias = {this.props.event}/>
			</> : 
			<Content
				eventAlias = {this.props.event.alias}
				id = {this.state.selectedType.id}
				type = {this.state.selectedType.type}
				viewType = {this.state.selectedType.view_type}
				onClickBack = {()=> this.setState({selectedType: null})}
				onNavigateToPage ={this.OnNavigateToPage}
			/>
			:<div style = {{marginTop : 6,display:'flex',justifyContent:'center',height:'100vh',alignItems:'center'}}>
				<Loader
					type = 'Oval'
					color = "#00b6ff"
					height = {45}
					width = {45}
				/>
			</div>}
			<Footer2 
			event = {this.props.event}
				isMarketplace = {this.props.event.is_marketplace}
				copyRightLogo = {this.props.event.copyright_logo}
				copyRightText = {this.props.event.copyright_text}
				copyRightLink = {this.props.event.copyright_link}
				showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
			/>
		</>
    )}
}
const mapStateToProps = state => (
	{
    contentType: state.marketPlaceReducer.dataTypes,
	loading:state.marketPlaceReducer.loading,
	knowledgehubHeaderdata:state.marketPlaceReducer.knowledgehubHeaderdata
	
})
const mapDispatchToProps = dispatch => ({
    getDataTypes : (alias,params) => dispatch(getDataTypes(alias,params)),
	getKnowedgeHubHeader:(data)=>dispatch(getKnowedgeHubHeader(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(KnowedgeHub);