import React from 'react'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import { connect } from 'react-redux'
import {compose} from 'redux'
import utility from '../../../Utility/utility';
import Header from '../../Header/Index';
import VirtualZone from '../../Header/virtualZone';
import { Helmet } from 'react-helmet';
import { Constants } from '../../../Constants';
import appLevelApi from '../../App/api'
import api from './api'
import { Content } from './content'
import {Modal,Button} from 'react-bootstrap'
import accountApi from '../../Account/api'
import Loader from 'react-loader-spinner'
import ImageMap from "image-map";
import imageMapResize from 'image-map-resizer'
import { LobbyDataLoaded } from './actions'
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { GAPageView, GARecordEvent } from '../../../Utility/GA'
import {Trans} from 'react-i18next'
import  PermiumUserModal  from "../../modals/premiumUserModal";
export class Lobby extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading : false,
            loadingErrorMessage : '',
            selectedLobbyIndex : 0,
            virtualZoneItemIndex : this.props.virtualMenuItems.findIndex(x=>x.path == '/lobby'),
            // lobby : [{
            //     id : 1,
            //     templateImage : '/images/exhibitors/Lobby_L5A.jpg',
            //     mapArea : '<area id="branding1" class="maparea" title = "Branding 1" coords="131,33,282,471" shape="rect"/><area id="branding2" class="maparea" title = "Branding 2" coords="521,23,652,483" shape="rect"/><area id="branding3" class="maparea" title = "Branding 3" class="maparea" coords="1329,23,1455,483" shape="rect"/><area id="branding4" class="maparea" title = "Branding 4" coords="1726,30,1877,473" shape="rect"/><area id="branding_video" class="maparea" title = "Videos" coords="925,380,1074,445" shape="rect"/><area id="help_desk" class="maparea" title = "Help Desk" coords="913,536,1088,639" shape="rect"/><area id="room3" class="maparea" title = "Expo Hall" coords="1167,371,1275,502" shape="rect"/><area class="maparea" id="branding_broucher" title = "Broucher" coords="1132,541,1212,665" shape="rect"/><area id="branding_photo_booth" class="maparea" coords="1455,509,1557,592" title="Photo Booth" shape="rect"><area id="room2" title="Agenda" class="maparea" coords="714,371,826,502" shape="rect"/><area id="room1" class="maparea" title="Networking" coords="436,508,528,590" shape="rect"/><area id="branding_program" class="maparea" title="Program" coords="782,548,857,658" shape="rect"/>',
            //     helpDesk : 175,
            //     brandings : [{
            //         type : 'expoHall'
            //     },{
            //         type : 'custom_link',
            //         link : 'https://google.com'
            //     },{
            //         type : 'booth_link',
            //         boothId : 175
            //     },{
            //         type : 'custom_link',
            //         link : 'https://www.apple.com'
            //     }],
            //     rooms:{
            //         auditorium : [
            //             {
            //                 type : 'inner_link',
            //                 link : '/participants'
            //             },{
            //                 type : 'inner_link',
            //                 link : '/agenda'
            //             }
            //         ],
            //         networking : [
            //             {
            //                 type : 'inner_link',
            //                 link : '/participants'
            //             },{
            //                 type : 'inner_link',
            //                 link : '/agenda'
            //             }
            //         ],
            //         expo_hall : [
            //             {
            //                 type : 'inner_link',
            //                 link : '/virtual_exhibition'
            //             }
            //         ],
            //         chat : [
            //             {
            //                 type : 'inner_link',
            //                 link : '/participants'
            //             }
            //         ]
            //     },
            //     visuals : [{
            //         id: "brochure Id",
            //         title: "This is brochure branding",
            //         type: "brochure",
            //         link: [
            //             {
            //                 id: 22,
            //                 thumbnail: "This is thumbnail",
            //                 title: "link title",
            //                 link: "https://eventmanagement.mwancloud.com/company/150/events/40/exibition/16046653605fa5401045ead.pdf"
            //             }
            //         ],
            //     },{
            //         id: "video Id",
            //         title: "This is video branding",
            //         type: "video_link",
            //         link: [
            //             {
            //                 id: 187,
            //                 thumbnail: "This is thumbnail",
            //                 index_type: null,
            //                 title: "link title",
            //                 link: "https://youtu.be/CwKIn4K9rg8"
            //             },{
            //                 link: "https://youtu.be/-Wpa_1vSmko"
            //             }
            //         ]
            //     },{
            //         id: "program Id",
            //         title: "This is a program",
            //         type: "program",
            //         link: [
            //             {
            //                 id: 23,
            //                 thumbnail: "This is thumbnail",
            //                 title: "link title",
            //                 link: "https://eventmanagement.mwancloud.com/company/150/events/40/exibition/16046653605fa5401045ead.pdf"
            //             }
            //         ],
            //     },{
            //         id: "photo booth Id",
            //         title: "This is a photo booth",
            //         type: "photo_booth",
            //         link: [
            //             {
            //                 id: 22,
            //                 thumbnail: "This is thumbnail",
            //                 title: "link title",
            //                 link: "https://eventmanagement.mwancloud.com/company/153/events/55/feature_image/5fb38af0d5c77.jpeg"
            //             },
            //             {
            //                 id: 23,
            //                 thumbnail: "This is thumbnail",
            //                 title: "link title",
            //                 link: "https://eventmanagement.mwancloud.com/company/153/events/55/feature_image/5fb38addc202d.jpeg"
            //             },
            //             {
            //                 id: 24,
            //                 thumbnail: "This is thumbnail",
            //                 title: "link title",
            //                 link: "https://eventmanagement.mwancloud.com/company/153/events/55/feature_image/5fb38ad254220.jpeg"
            //             }
            //         ],
            //     }]
            // }],
            lobby : [],
            showContent : true,
            loadingTemplateImage : true,
            showTootlTip : false,
            tooltipText : '',
            selectedBranding : [],
            selectedVideoIndex : 0,
            selectedLobbyImage : '', // used in case where selected hall index is -1,
            showImageSlider : false,
            selectedImageIndex : 0,
            selectedVideoLink : '',
            pdfModalTitle : '',
            selectedPdfId : ''
        }
        this.changingLobby = false
    }
    async componentDidMount () {
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=lobby')
        fetch(`/${this.props.event.alias}/lobby`)
        this._isMounted = true
        this.user = utility.getLoggedInUser(this.props.event.alias)
        if(this.user == null){
            this.OnNavigateToPage('/')
            return
        }else{
            if(!this.user.isBooking){
                this.OnNavigateToPage('/')
                return
            }
        }
        if(this.props.lobby != null){
            this.setState({lobby : this.props.lobby},()=>{
                this.lobbyDataLoaded()
                // let data = this.props.location.state
                // if(data != null){
                //     this.setState({selectedLobbyIndex: 0,selectedLobbyImage : this.props.lobby[data.hallIndex].templateImage},()=>{
                //         this.OnClickNext(data.hallIndex-1,0)
                //     })
                // }
                window.history.replaceState(null, '')
            })
        }else
        {
            this.setState({loading : true})
            api.getLobby(this.props.event.alias,this.user.token,(err,lobby)=>{
                if(err == null){
                    this.setState({lobby : lobby},()=>{
                        this.lobbyDataLoaded()
                    })
                }else{
                    this.setState({lobby : [],loadingErrorMessage : err.message})
                }
                this.setState({loading : false})
            })
            // this.hallsDataLoaded()
        }
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
    }
    lobbyDataLoaded = () => {
        let lobby = this.state.lobby
        for(var i=0;i<lobby.length;i++){
            let videoBranding = lobby[i].visuals.filter(x=>x.type.indexOf('video_link') > -1)
            if(videoBranding.length > 0){
                for(var j=0;j<videoBranding.length;j++){
                    for(var k=0;k<videoBranding[j].link.length;k++){
                        let url = videoBranding[j].link[k].link
                        if(url.indexOf('youtu') > -1){
                            let id = utility.getYoutubeVideoId(url)
                            videoBranding[j].link[k].link = "https://www.youtube.com/embed/"+id
                        }
                    }
                }
            }
        }
        this.setState({lobby: lobby},()=>{
            if(lobby.length == 1){
                $('#nxt-imgs').addClass('rightslide');
            }
            if(this.state.selectedLobbyIndex > 0){
                $('#back-imgs').addClass('leftslide');
            }
            this.props.onLobbyDataLoaded(lobby)
            if(lobby.length == 0){
                this.setState({loadingErrorMessage : 'Lobby not configured'})
                return
            }
            for(var i=0;i<lobby.length;i++){
                if(i != this.state.selectedLobbyIndex){
                    $('#lobby'+i).fadeOut(0)
                }
            }
            if(lobby.length > 0){
                this.AddClickListeners(0)
            }
            setTimeout(() => {
                imageMapResize()
            }, 0);
            
        })
    }
    componentWillUnmount(){
        // $('.tooltips').remove();
        this._isMounted = false
    }
    AddClickListeners = (index) => {
        let helpDesks =this.state.lobby[this.state.selectedLobbyIndex].helpDesk
        for(var j=1;j<=helpDesks.length;j++){
            let helpDeskElem = document.getElementById("help_desk"+j)
            if(helpDeskElem != null){
                helpDeskElem.addEventListener('click',(e)=>{
                    let index = parseInt(e.target.id.slice(e.target.id.length-1)) - 1
                    this.OnClickBooth('helpDesk',index)
                })
                this.AddMouseListenersToElem(helpDeskElem)
            }
        }
        // let helpDeskElem = document.getElementById("help_desk")
        // if(helpDeskElem != null){
        //     helpDeskElem.addEventListener('click',(e)=>{
        //         this.OnClickBooth('helpDesk')
        //     })
        //     this.AddMouseListenersToElem(helpDeskElem)
        // }
        let videos = this.state.lobby[this.state.selectedLobbyIndex].visuals.filter(x=>x.type.indexOf("video_link") > -1)
        for(var j=1;j<=videos.length;j++){
            let videoElem = document.getElementById("branding_video"+j)
            if(videoElem != null){
                videoElem.addEventListener('click',(e)=>{
                    let type = videos[parseInt(e.target.id.slice(e.target.id.length-1)) - 1].type
                    let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == type)
                    this.OnClickBooth("visual",index)
                })
            }
        }
        let brouchers = this.state.lobby[this.state.selectedLobbyIndex].visuals.filter(x=>x.type.indexOf("brochure") > -1)
        console.log(brouchers)
        for(var j=1;j<=brouchers.length;j++){
            let broucherElement = document.getElementById("branding_broucher"+j)
            if(broucherElement != null){
                broucherElement.addEventListener('click',(e)=>{
                    let type = brouchers[parseInt(e.target.id.slice(e.target.id.length-1)) - 1].type
                    let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == type)
                    this.OnClickBooth("visual",index)
                })
            }
        }
        let programs = this.state.lobby[this.state.selectedLobbyIndex].visuals.filter(x=>x.type.indexOf("branding_program") > -1)
        for(var j=1;j<=programs.length;j++){
            let programElement = document.getElementById("branding_program"+j)
            if(programElement != null){
                programElement.addEventListener('click',(e)=>{
                    let type = programs[parseInt(e.target.id.slice(e.target.id.length-1)) - 1].type
                    let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == type)
                    this.OnClickBooth("visual",index)
                })
            }
        }
        // let videoElem = document.getElementById("branding_video")
        // if(videoElem != null){
        //     videoElem.addEventListener('click',(e)=>{
        //         let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == "video_link")
        //         if(index > -1){
        //             this.OnClickBooth('visual',index)
        //         }
        //     })
        //     this.AddMouseListenersToElem(videoElem)
        // }
        // let broucherElem = document.getElementById("branding_broucher")
        // if(broucherElem != null){
        //     broucherElem.addEventListener('click',(e)=>{
        //         let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == "brochure")
        //         if(index > -1){
        //             this.OnClickBooth('visual',index)
        //         }
        //     })
        //     this.AddMouseListenersToElem(broucherElem)
        // }
        // let programElem = document.getElementById("branding_program")
        // if(programElem != null){
        //     programElem.addEventListener('click',(e)=>{
        //         let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == "branding_program")
        //         if(index > -1){
        //             this.OnClickBooth('visual',index)
        //         }
        //     })
        //     this.AddMouseListenersToElem(programElem)
        // }
        let photoBoothElem = document.getElementById("branding_photo_booth")
        if(photoBoothElem != null){
            photoBoothElem.addEventListener('click',(e)=>{
                let index = this.state.lobby[this.state.selectedLobbyIndex].visuals.findIndex(x=>x.type == "photo_booth")
                if(index > -1){
                    this.OnClickBooth('visual',index)
                }
            })
            this.AddMouseListenersToElem(photoBoothElem)
        }
        for(var j=1;j<=this.state.lobby[index].brandings.length;j++){
            let branding = document.getElementById("branding"+j)
            if(branding != null){
                branding.addEventListener('click',(e)=>{
                    let index = parseInt(e.target.id.slice(e.target.id.length-1)) - 1
                    this.OnClickBooth("branding",index)
                })
                branding.onmouseenter = (e) => {
                    let tooltipText = branding.getAttribute('title')
                    if(tooltipText != null){
                        branding.removeAttribute('title')
                        this.setState({showTootlTip : true,tooltipText})
                    }
                }
                branding.onmouseleave = () => {
                    if(this.state.tooltipText != ''){
                        branding.setAttribute('title',this.state.tooltipText)
                        this.setState({showTootlTip : false})
                    }
                }
            }
        }
        for(var j=1;j<=this.state.lobby[index].visuals.length;j++){
            let item = this.state.lobby[index].visuals[j]
            if(item != null){
                this.AddMouseListenersToElem(item)
            }
        }
        this.ConfigureRooms(index,'auditorium')
        this.ConfigureRooms(index,'networking')
        this.ConfigureRooms(index,'expo_hall')
        this.ConfigureRooms(index,'chat')
    }
    ConfigureRooms = (index,room) => {
        for(var j=1;j<=this.state.lobby[index].rooms[room].length;j++){
            let roomElem = document.getElementById(room+j)
            if(roomElem != null){
                roomElem.addEventListener('click',(e)=>{
                    let index = parseInt(e.target.id.slice(e.target.id.length-1)) - 1
                    let booth = this.state.lobby[index].rooms[room][index]
                    this.OnClickBooth("room",index,booth)
                })
                this.AddMouseListenersToElem(roomElem)
            }
        }
    }
    OnNavigateToPage = (path,data = null) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            this.props.history.push(path)
        }else{
            this.props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    OnClickBooth = (type,index = -1,booth = null) => {
        let lobby = this.state.lobby[this.state.selectedLobbyIndex]
        let selectedBooth = booth
        let isHelpDesk = false
        if(type.indexOf("helpDesk") > -1){
            // GARecordEvent({
            //     category : 'Lobby',
            //     action : 'Help desk clicked',
            //     label : 'By user: '+this.user.id
            // })
            isHelpDesk = true
            selectedBooth = {
                externalLink : this.state.lobby[this.state.selectedLobbyIndex].helpDesk[index]
            }
            if(selectedBooth.externalLink != null){
                selectedBooth.brandings = []
            }
        }
        else if (type == "branding" || type == "room"){
            if(type == "branding"){
                selectedBooth = lobby.brandings[index]
            }
            if(selectedBooth.type == 'booth_link'){
                selectedBooth.externalLink = selectedBooth.boothId
                selectedBooth.brandings = []
            }else if(selectedBooth.type == 'custom_link'){
                GARecordEvent("Lobby brandingClicked", this.user.id, {
                    category : 'Lobby',
                    action : 'Branding clicked '+selectedBooth.link,
                    label : 'By user: '+this.user.id,
                    title: selectedBooth.title ? selectedBooth.title : "not available"
                })
                if(selectedBooth.link == 'https://jobFairProfile.com'){
                    var hash = localStorage.getItem('login_hash')
                    let selectedLocale = localStorage.getItem("language"+this.props.event.alias) || 'en';
                    let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
                    +window.location.origin+"&alias="+this.props.event.alias+"&locale="+selectedLocale
                    window.location.href = location
                }else{
                    window.open(selectedBooth.link,"_blank")
                }
                return
            }else if(selectedBooth.type == "inner_link"){
                GARecordEvent(selectedBooth.link.substr(1)+'Clicked', this.user.id,{
                    category : 'Lobby',
                    action : selectedBooth.link.substr(1) + ' clicked',
                    label : 'By user: '+this.user.id,
                    title: selectedBooth.title ? selectedBooth.title : "not available"

                })
                this.OnNavigateToPage(this.props.event.alias+selectedBooth.link)
                return
            }else if(selectedBooth.type == "none"){
                return
            }
        }else{
            let selectedBranding = lobby.visuals[index]
            this.setState({selectedBranding},()=>{
                if(selectedBranding.type.indexOf('brochure') > -1 || selectedBranding.type.indexOf('branding_program') > -1){
                    if(selectedBranding.type.indexOf('brochure') > -1){
                        GARecordEvent("broucherDownloadClicked", this.user.id,{
                            category : 'Lobby',
                            action : 'Broucher download clicked',
                            label : 'By user: '+this.user.id,
                            title: selectedBranding.title ? selectedBranding.title : "not available"
                        })
                        this.setState({pdfModalTitle : <Trans>brouchers</Trans>})
                    }else{
                        GARecordEvent("programDownloadClicked", this.user.id,{
                            category : 'Lobby',
                            action : 'Program download clicked',
                            label : 'By user: '+this.user.id,
                            title: selectedBranding.title ? selectedBranding.title : "not available"
                        })
                        this.setState({pdfModalTitle : <Trans>program</Trans>})
                    }
                    $('#Brochure-modal').modal('show')
                }
                if(selectedBranding.type.indexOf('video_link') > -1){
                    GARecordEvent("videoClicked", this.user.id,{
                        category : 'Lobby',
                        action : 'Video clicked',
                        label : 'By user: '+this.user.id,
                        title: selectedBranding.title ? selectedBranding.title : "not available"
                        
                    })
                    if(selectedBranding.link.length > 0){
                        this.setState({selectedVideoLink : selectedBranding.link[this.state.selectedVideoIndex].link},()=>{
                            $('#video-modal-exhb').modal('show')
                        })
                    }
                }
                if(selectedBranding.type == 'photo_booth'){
                    GARecordEvent("photoboothCLicked", this.user.id,{
                        category : 'Lobby',
                        action : 'Photobooth clicked',
                        label : 'By user: '+this.user.id,
                        title: selectedBooth.title ? selectedBooth.title : "not available"
                    })
                    if(selectedBranding.link.length > 0){
                        this.setState({showImageSlider : true})
                    }
                    
                }
                if(selectedBranding.type == "none"){
                    return
                }
            })
            return
        }
        this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition/booth/"+(selectedBooth.bothId ? selectedBooth.bothId : selectedBooth.externalLink),{booth : selectedBooth,hallIndex : this.state.selectedLobbyIndex,fromLobby : true,isHelpDesk : isHelpDesk})
    }
    OnClickPDF = (id,title) => {
        this.setState({downloadingPdf : true,selectedPdfId : id})
        let type = this.state.pdfModalTitle == "Program" ? "Program" : "Broucher"
        GARecordEvent(type+"DownloadClicked", this.user.id,{
            category : 'Lobby',
            action : type + ' downloaded '+id,
            label : 'By user: '+this.user.id,
            type: type
        })
        api.downloadBroucher(this.props.event.alias,id,title,(err,data)=>{
            this.setState({downloadingPdf : false})
        })
    }
    AddMouseListenersToElem = (elem) => {
        elem.onmouseenter = (e) => {
            let tooltipText = elem.getAttribute('title')
            if(tooltipText != null){
                elem.removeAttribute('title')
                this.setState({showTootlTip : true,tooltipText})
            }
        }
        elem.onmouseleave = () => {
            if(this.state.tooltipText != ''){
                elem.setAttribute('title',this.state.tooltipText)
            }
            this.setState({showTootlTip : false})
        }
    }
    OnClickNext = (index,duration = 500) =>{
        if(this.changingLobby){
            return
        }
        this.changingLobby = true
        $('#nxt-imgs').addClass('rightslide');
        $('#hall'+index).fadeOut(duration);
        $('#hall'+(index+1)).fadeIn(duration);
        setTimeout(() => {
            this.setState({selectedLobbyIndex : index + 1,loadingTemplateImage : true},()=>{
                setTimeout(() => {
                    if(this.state.selectedLobbyIndex > 0){
                        $('#back-imgs').addClass('leftslide');
                    }
                }, 0);
                if(index + 1 == this.state.lobby.length - 1){
                    $('#nxt-imgs').addClass('rightslide');
                }
                setTimeout(() => {
                    imageMapResize()
                }, 0);
                this.AddClickListeners(this.state.selectedLobbyIndex)
            })
            this.changingLobby = false
        }, duration);
    }
    OnClickBack = (index) => {
        if(this.changingLobby){
            return
        }
        this.changingLobby = true
        $('#back-imgs').removeClass('leftslide');
        $('#hall'+index).fadeOut(500);
        $('#hall'+(index-1)).fadeIn(500);
        setTimeout(() => {
            this.setState({selectedLobbyIndex : index-1,loadingTemplateImage : true},()=>{
                if(index - 1 > 0){
                    $('#back-imgs').addClass('leftslide');
                }
                setTimeout(() => {
                    $('#nxt-imgs').css('right',0);
                }, 0);
                $('#nxt-imgs').addClass('rightslide');
                setTimeout(() => {
                    imageMapResize()
                }, 0);
                this.AddClickListeners(this.state.selectedLobbyIndex)
            })
            this.changingLobby = false
        }, 500);
    }
    languageClick = (value) =>{
        //console.log("Trigger Lobby on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    handleClosePopup = () => {
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
        this.OnNavigateToPage(this.props.event.alias+"/login")
    }
    render () {
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Lobby</title>
            </Helmet>
            <PermiumUserModal
        id="premiumSession"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
        }}
        onClickBookNow={() => {
        this.moveTolOgin()
          $("#premiumSession").modal("hide");
         $(".modal-backdrop").remove();
          this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
            <div id="header-top">
            <Header
                onMenuItemClick = {this.OnNavigateToPage}  
                elements = {this.props.headerMenuItems}
                facebookLink = {this.props.event.facebook}
                linkedInLink = {this.props.event.linkedIn}
                twitterLink = {this.props.event.twitter}
                isMarketplace = {this.props.event.is_marketplace}
                instagramLink = {this.props.event.instagram}
                googleLink = {this.props.event.google}
                phone = {this.props.event.phone_number}
                event = {this.props.event}
                eventLogo = {this.props.event.logo}
                eventName = {this.props.event.name}
                eventAlias = {this.props.event.alias}
                onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                availableLocales = {this.props.availableLocales}
                languageClick  = {this.languageClick}
                onClickAccountItem = {this.OnClickAccountItem}
            />
            {user != null && user.isBooking && Constants.showVirtualZone ?
            <VirtualZone
                selectedItemIndex = {this.state.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
                event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }}
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                menuItems = {this.props.virtualMenuItems}
                user = {user} 
            /> : null}
            </div>
            {this.state.showContent ?
            <Content
                loadingTemplateImage = {this.state.loadingTemplateImage}
                loading = {this.state.loading}
                loadingError = {this.state.loadingErrorMessage}
                lobby = {this.state.lobby}
                selectedLobbyIndex = {this.state.selectedLobbyIndex}
                selectedBranding = {this.state.selectedBranding}
                selectedBooth = {this.state.selectedBooth}
                selectedILobbymage = {this.state.selectedLobbyImage}
                onClickNext = {this.OnClickNext}
                onClickBack = {this.OnClickBack}
                selectedVideoIndex = {this.state.selectedVideoIndex}
                onClickChangeVideo = {(index)=> this.setState({selectedVideoLink : this.state.selectedBranding.link[index].link,selectedVideoIndex : index})}
                selectedVideoLink = {this.state.selectedVideoLink}
                onClickCloseVideo = {() => this.setState({selectedVideoLink : ''})}
                onClickPdf = {this.OnClickPDF}
                pdfModalTitle = {this.state.pdfModalTitle}
                downloadingPdf = {this.state.downloadingPdf}
                selectedPdfId = {this.state.selectedPdfId}
                templateImageLoaded = {()=>{
                    this.setState({loadingTemplateImage : false})
                }}
                showImageSlider = {this.state.showImageSlider}
                selectedImageIndex = {this.state.selectedImageIndex}
                onClickChangeImage = {(index)=> {
                    this.setState({selectedImageIndex : index})
                }}
                onCloseImageSlider = {()=> this.setState({showImageSlider : false})}
            />:null}
            {/* <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showErrorPopup} 
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <MouseTooltip
                visible={this.state.showTootlTip}
                offsetX={15}
                offsetY={10}
                className = "tooltips"
            >
                <span>{this.state.tooltipText}</span>
            </MouseTooltip>
            </>
        )
    }
}
const mapStateToProps = state => ({
    lobby : state.virtualLobby.lobby
})
const mapDispatchToProps = dispatch => ({
    onLobbyDataLoaded : data => dispatch(LobbyDataLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Lobby);