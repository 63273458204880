import React, { useEffect, useState } from "react";
import FloorPlan from "../1 - Sub menu components/Mapfloor/index";
import utitlity, {
  countNumberOfUser,
  dateExctrator,
  forumfullName,
  wordsRestrictor,
  getprofileImage,
} from "../../Utility/utility";
import VirtualZone from "../Header/virtualZone";
import Loader from "react-loader-spinner";
import { Constants } from "../../Constants/index";
import Header from "../Header/Index";
import { Link, withRouter } from "react-router-dom";

const MapFloorMainPage = (props) => {
  const [selectedIndex, setIndex] = useState(
    props.headerMenuItems.findIndex((x) => x.href.indexOf("/virtual-floor-plan") > -1)
  );
  const [loading, setLoading] = useState(false);
  const [availableLocales, setAvaliableLocales] = useState(
    props.availableLocales
  );
  var user = utitlity.getLoggedInUser(props.event.alias);
  const OnClickAccountItem = (index, path) => {
    props.onClickAccountItem(index, path);
    if (index > -1) {
      props.history.replace("");
      props.history.push({
        pathname: props.event.alias + "/account",
        state: { tabIndex: index },
      });
    } else {
      localStorage.removeItem(
        Constants.API_TOKEN + props.event.alias + "user_info"
      );
      window.location.reload();
    }
  };
  const OnNavigateToPage = (path) => {
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    }
    props.history.push(path);
  };
  return (
    <>
      <div id="header-top">
        <Header
          selectedIndex={selectedIndex}
          onMenuItemClick={OnNavigateToPage}
          elements={props.headerMenuItems}
          isMarketplace={props.event.is_marketplace}
          facebookLink={props.event.facebook}
          linkedInLink={props.event.linkedIn}
          twitterLink={props.event.twitter}
          instagramLink={props.event.instagram}
          googleLink={props.event.google}
          phone={props.event.phone_number}
          event = {props.event}
          eventLogo={props.event.logo}
          eventName={props.event.name}
          eventAlias={props.event.alias}
          availableLocales={availableLocales}
          languageClick={props.languageClick}
          onLoginClick={() => OnNavigateToPage(props.event.alias + "/login")}
          onSignupClick={() => OnNavigateToPage(props.event.alias + "/sign_up")}
          onClickAccountItem={OnClickAccountItem}
        />
        {user != null && user.isBooking && Constants.showVirtualZone ? (
          <VirtualZone
            eventAlias={props.event.alias}
            event = {props.event}
            onMenuItemClick={(path) => {
              if (path != "") {
                OnNavigateToPage(path);
              }
            }}
            unReadCount={props.unReadCount}
            onNotificationSeen={props.onNotificationSeen}
            onClearNotifications={props.onClearNotifications}
            notificationsCount={props.unReadNotificationsCount}
            notifications={props.notifications}
            onClickAccountItem={OnClickAccountItem}
            onClickNotification={(path, isExternal) => {
              if (isExternal) {
                window.open(path, "_blank");
              } else {
                OnNavigateToPage(this.props.event.alias + path);
              }
            }}
            menuItems={props.virtualMenuItems}
            user={user}
          />
        ) : null}
      </div>
      <FloorPlan event={props.event} type="seprate" />
    </>
  );
};
export default withRouter(MapFloorMainPage);
