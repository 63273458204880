import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import {compose} from 'redux';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../Agenda/api';
import { Slide } from 'react-slideshow-image';
import Modal from 'react-modal'
import { Swipeable } from 'react-swipeable'
import {isMobile} from 'react-device-detect';
import {withRouter} from 'react-router-dom';
import { Trans } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import { ImagaGalleryLoaded } from './actions'
import 'react-image-lightbox/style.css';
import {connect} from 'react-redux'
import ContentLoader from 'react-content-loader'
export class ImageGallary extends  React.Component {
    _isMounted = false
    selectedVersion = "V1"
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            event_alias : this.props.event_alias,
            type : this.props.type,
            feature : {},
            showImageSlider : false,
            isOpen : false,
            selectedImageIndex : 0,
            showContent : false,
            isLoading : true,
            EventGallery:[],
            title:'Gallery'
        };
    }

    componentDidMount(){
        this._isMounted = true
        if(this.props.EventLoading != this.state.isLoading){
            this.setState({isLoading:this.props.EventLoading})
        }
        if(this.props.EventGallery.length>0){
            if(this.props.EventGallery[0].hasOwnProperty('gallery')){
                this.setState({EventGallery:this.props.EventGallery[0].gallery.items,title:this.props.EventGallery[0].gallery.title})
                this.SetImages(this.props.EventGallery[0].gallery)

            }
        }
        // if(this.props.imageGallery != null){
        //     let showContent = false
        //     if(this.props.imageGallery.features.length > 0){
        //         showContent = true
        //     }
        //     this.setState({feature : this.props.imageGallery,showContent,isLoading : false})
        //     this.SetImages(this.props.imageGallery)
        //     return
        // }
        // api.getEventFeatures(this.state.event.alias,this.state.type,1,(err,data)=>{
        //     if(err == null && this._isMounted){
        //         if(data.feature != null){
        //             this.setState({feature:data.feature},()=>{
        //                 if(data.feature.features.length > 0){
        //                     this.setState({showContent : true})
        //                 }
        //             });
        //             console.log(data.feature)
        //             this.props.imageGalleryLoaded(data.feature)
        //             this.SetImages(data.feature)
        //         }
        //     }
        //     this.setState({isLoading : false})
        // })
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.EventLoading != this.state.isLoading){
            this.setState({isLoading:nextProps.EventLoading})
        }
        if(nextProps.EventGallery.length>0){
            if(nextProps.EventGallery[0].hasOwnProperty('gallery')){
                this.setState({EventGallery:nextProps.EventGallery[0].gallery.items,title:nextProps.EventGallery[0].gallery.title})
                this.SetImages(nextProps.EventGallery[0].gallery)

            }
        }

    }
    componentWillUnmount(){
        this._isMounted = false
    }
    SetImages = (data) => {
        this.images = {
            'V1' : []
        }
        var version = 'V1'
        for(var i=0;i<data.items.length;i++){
            if(data.items[i].image != null){
                this.images[version].push(data.items[i].image)
            }
        }
    }
    OnClickImageItem = (imageIndex,version='V1') => {
        this.selectedVersion = version
        this.selecedImageIndex = imageIndex
        this.setState({showImageSlider : true,selectedImageIndex : imageIndex})
    }
    OnClickNextSlide = () => {
        this.slideRef.goNext()
    }
    OnClickPreviousSlide = () => {
        this.slideRef.goBack()
    }
    renderImageSlider = () => {
        const properties = {
            duration: 150000,
            defaultIndex : this.selecedImageIndex,
            transitionDuration : 300,
            autoPlay : false,
            arrows : false,
            infinite : false
        }
        
        return(
            this.state.showImageSlider ?
                <Lightbox
                  mainSrc={this.images[this.selectedVersion][this.state.selectedImageIndex% this.images[this.selectedVersion].length] }
                  nextSrc={this.images[this.selectedVersion][(this.state.selectedImageIndex + 1) % this.images[this.selectedVersion].length]}
                  prevSrc={this.images[this.selectedVersion][(this.state.selectedImageIndex + this.images[this.selectedVersion].length - 1) % this.images[this.selectedVersion].length]}
                  onCloseRequest={() => this.setState({ showImageSlider: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      selectedImageIndex: (this.state.selectedImageIndex + this.images[this.selectedVersion].length - 1) % this.images[this.selectedVersion].length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      selectedImageIndex: (this.state.selectedImageIndex + 1) % this.images[this.selectedVersion].length,
                    })
                  }
                  keyRepeatLimit = {540}
                /> : null
        )
        return(
            <Modal
                className="Modal__Bootstrap modal-dialog"
                isOpen={this.state.showImageSlider}
                style = {{
                    overlay: {zIndex: 1000,position : 'fixed',top : -4,bottom : -4,left : -4,right : -4,background : 'rgba(0, 0, 0, 0.8)'}
                }}
            >
                <div className="modal-content" style = {{background : 'transparent',borderColor : 'transparent'}}>
                <button onClick = {()=>this.setState({showImageSlider : false})} style = {{position : 'fixed',right : '1%',top : '2%',background : 'transparent',borderColor : 'transparent'}} type="button" id="close-button" aria-label="Close">
                    <svg width="30" height="30">
                        <g stroke="rgb(160,160,160)" stroke-width="4">
                            <line x1="5" y1="5" x2="25" y2="25"></line>
                            <line x1="5" y1="25" x2="25" y2="5"></line>
                        </g>
                    </svg>
                </button>
                <div style = {{width : '100%',height : window.innerHeight,display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                {!isMobile ? 
                    <button onClick = {this.OnClickPreviousSlide} style = {{position : 'fixed',left : '1%'}} type="button" id="previous-button" aria-label="Previous" class="baguetteBox-button">
                        <svg width="44" height="60">
                            <polyline points="30 10 10 30 30 50" stroke="rgba(255,255,255,0.5)" stroke-width="4" stroke-linecap="butt" fill="none" stroke-linejoin="round"></polyline>
                        </svg>
                    </button> : null}
                {!isMobile ?
                    <button onClick = {this.OnClickNextSlide} style = {{position : 'fixed',right : '1%'}} type="button" id="next-button" aria-label="Next" class="baguetteBox-button">
                        <svg width="44" height="60">
                            <polyline points="14 10 34 30 14 50" stroke="rgba(255,255,255,0.5)" stroke-width="4" stroke-linecap="butt" fill="none" stroke-linejoin="round"></polyline>
                        </svg>
                    </button> : null}
                    <div className="slide-container" style = {{width : '100%'}}>
                        <Slide ref = {(ref)=>this.slideRef = ref} {...properties}>
                            {this.images != null ?
                            this.images[this.selectedVersion].map((item,index)=>{
                                return(
                                    <div className="each-slide" style = {{width : '100%'}}>
                                        <Swipeable onSwipedRight = {()=>this.slideRef.goBack()} onSwipedLeft = {()=>this.slideRef.goNext()}>
                                            <div style = {{width : 516,height : 343,background : 'transparent'}}>
                                                <img src = {item} alt = {index} style = {{height : '100%',width : '100%'}} />
                                            </div>
                                        </Swipeable>
                                       
                                    </div>
                                )
                            }): null}
                        </Slide>
                    </div>
                </div>
                </div>
            </Modal>
        )
    }
    OnNavigateToPage = (path,data=null) =>{
        console.log(path)
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data){
            this.props.history.push({
                pathname: path,
                state: data
            })
        }else{
            this.props.history.push(path)
        }
    }
    render(){
        if(this.state.isLoading){
            return (
                <ContentLoader
                    width={800}
                    height={465}
                    viewBox="0 0 800 465"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...this.props}
                >
                    <rect x="130" y="58" rx="2" ry="2" width="150" height="150" />
                    <rect x="320" y="58" rx="2" ry="2" width="150" height="150" />
                    <rect x="500" y="58" rx="2" ry="2" width="150" height="150" />
                    <rect x="130" y="238" rx="2" ry="2" width="150" height="150" />
                    <rect x="320" y="238" rx="2" ry="2" width="150" height="150" />
                    <rect x="500" y="238" rx="2" ry="2" width="150" height="150" />
                </ContentLoader>
            )
        }
        const event = this.props.event;
        const feature = this.state.EventGallery;

        if(this.state.isLoading == false){
            if(feature && feature.length >0){
            if(event.gallary_layout == 'V1'){
                return(
                    <section className = "py-5" id = "event-photo">
                        {this.renderImageSlider()}
                        <div className="container gallery-container">
                           {feature && feature.length > 0 ? <h5 className = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>:null}

                            <div className = "tz-gallery">
                                <div className="row mb-2 pt-3 pb-3">

                                    {
                                        feature ?
                                        feature.map((feature,index) => {
                                                console.log(feature)
                                                var activeName = index == 0 ? "active" : "";
                                                return (
                                                    <div className="col-md-3 col-6" key={index}>
                                                        <div className = "card">
                                                            <a className="lightbox img-link-frame" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickImageItem(index)}>
                                                                <img src={feature.image} alt="ph-1" className="card-img-top"/>
                                                            </a>

                                                        </div>
                                                    </div>
                                                );

                                            })
                                            : null
                                    }

                                </div>

                            </div>
                            
                        </div>
                        <div className="row py-2 justify-content-center">
							{feature.length > 2 ?<a 
                            onClick = {()=>{this.OnNavigateToPage(this.props.event.alias+'/gallery')}} 
                            style = {{cursor : 'pointer'}} className = "btn-default see_btn">
								<Trans>seeAll</Trans></a>:null}
						</div>
                    </section>
                );
            }else{
                return(
                    <section className = "py-5 bg-light" id="event-photo">
                        <div className="container">
                            <h5 className = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>
                            <div className="row py-5">

                                {
                                    feature ?
                                    feature.map((feature,index) =>{

                                            var activeName = index == 0 ? "active" : "";
                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <div className = "gallery_card">
                                                        <img src={feature.image} style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickImageItem(index)} className="img-thumbnail" alt="Cinque Terre" />
                                                        <h6 className = "f-600 mt-2">{feature.title}</h6>
                                                    </div>
                                                </div>
                                            );

                                        })
                                        : null
                                }
                                {this.renderImageSlider()}
                            </div>
                        </div>
                        <div className="row py-2 justify-content-center">
                        {feature.length > 2 ?<a 
                            onClick = {()=>{this.OnNavigateToPage(this.props.event.alias+'/gallery')}} 
                            style = {{cursor : 'pointer'}} className = "btn-default see_btn">
								<Trans>seeAll</Trans></a>:null}
						</div>
                    </section>
                );
            }
            }
        else{
            return null
        }

        }else{
            return null
        }//end of flage if

    }

}

const mapStateToProps = state => ({
    imageGallery: state.imageGallery.imageGalleryData,
    EventGallery:state.appData.allFeature,
    EventLoading:state.appData.featureLoading
})
const mapDispatchToProps = dispatch => ({
    imageGalleryLoaded : data => dispatch(ImagaGalleryLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ImageGallary)