import React from 'react'
import { withRouter } from 'react-router-dom'
import io from "socket.io-client";
import Peer from "simple-peer";
import $ from 'jquery'
import { connect } from 'react-redux'
import {compose} from 'redux'
import utility from '../../../Utility/utility';
import Header from '../../Header/Index';
import VirtualZone from '../../Header/virtualZone';
import Content from './content';
import { Helmet } from 'react-helmet';
import { Constants } from '../../../Constants';
import inviteApi from '../Invitation/api'
import {Modal,Button} from 'react-bootstrap'
import { GAPageView } from '../../../Utility/GA';
import randomColor from 'randomcolor';
import Loader from 'react-loader-spinner'
import api from './api'
import { UpdateParticipantsList,meetingParticipent } from './actions';
import {withTranslation} from 'react-i18next'
import {RemoveScroll} from '../Virtual Exhibition/actions/index'
import PermiumUserModal  from "../../modals/premiumUserModal";
export class Participants extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            participants : this.props.participants,
            meetingparticipent:this.props.meetingparticipent,
            searchText : '',
            onlineFilter : false,
            sendingInvite : false,
            sendingInviteError : '',
            virtualZoneItemIndex : this.props.virtualMenuItems.findIndex(x=>x.path == '/participants'),
            loading : props.loading,
            loadingMore : false,
            loadingSearchResults : false,
            meetingparticipentloader:false,
            filterPar:false,
            popUpErrorMessage:"",
            popUpErrorHeading:"",
            firstCall:false,
            count:1,
            meetinguser:this.props.participants,
            meetingdata:false

        }
        this.socket = this.props.socket
        this.sortToggle = false
        this.allInvitionDates = []
        this.pageNo = 1
        this.pageNoNormal = 1
        this.pageNoSearch = 1
        this.pageNoOnline = 1
        this.invitesLoadedFromApi = false
        this.allParticipantsLoaded = {
            search : false,
            normal : false,
            online : false
        }
        this.searchQuery = ""
        this.onlineParicipantsInitialLoadAmount = 30
        this.onlineParticipantsLoadMoreAmount = 10
        this.participantsToFetch = []
        let eventAlias= localStorage.getItem("alias")
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+eventAlias) || 'en')
    }
    componentWillReceiveProps(nextProps){
        if(this.props.meetingparticipent  != nextProps.meetingparticipent){
            this.setState({meetingparticipent:[...nextProps.meetingparticipent]})

        }
    }
    componentDidMount () {
        console.log(this.props)
        this.props.RemoveScroll(1)
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=participants')
        fetch(`/${this.props.event.alias}/participants`)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        this.user = utility.getLoggedInUser(this.props.event.alias)
        if(this.user == null){
            this.OnNavigateToPage(this.props.event.alias+'/login')
            return
        }else{
            if(!this.user.isBooking){
                this.OnNavigateToPage('/')
                return
            }
        }
        console.log(this.state.participants)

        let participants = this.state.participants.filter(x=>x.id != this.user.id)
        let bgColorUserList = participants.map(item=>
            {
                if(!item.hasOwnProperty('bgColor')){
                    return {...item,bgColor:randomColor()}
                }else{
                    return item
                }
            })
        console.log(this.state.participants)
        this.setState({participants:bgColorUserList})
        this.initializeInvites()
        $(".s-parti").click(function(){
            $('#meeting-modal-mobile').addClass('show');
            $('body').removeClass('overflow-hidden');
            $('.participant_body').removeClass('participant_body-before');
        })
        document.addEventListener('participantsLoaded',(e)=>{
            console.log(e)
            this.handleOnParticipantsLoadedFromApi(e.detail.participants)
        })
        document.addEventListener('invitesLoaded',this.handleOnInvitesFromApiLoaded)
        document.addEventListener('usersConnected',this.handleOnUsersConnected)
        document.addEventListener('userDisconnected',this.handleOnUserDisconnected)
    }
    // componentWillReceiveProps=(nextProps)=>{
    //     this.setState({participants:nextProps.participants})
    //     let currentValue = localStorage.getItem("OnlineUser")
    //     if(currentValue == 1 && nextProps.participants.length > 0  ){
    //         this.handleOnFindOnlineParticipants(true)
    //         this.setState({count:this.state.count + 1})
    //     }
    // }
    componentWillUnmount () {
        this.props.RemoveScroll(0)
        document.removeEventListener('participantsLoaded',this.handleOnParticipantsLoadedFromApi)
        document.removeEventListener('invitesLoaded',this.handleOnInvitesFromApiLoaded)
        document.removeEventListener('usersConnected',this.handleOnUsersConnected)
        document.removeEventListener('userDisconnected',this.handleOnUserDisconnected)
    }
    initializeInvites = () => {
        if(this.props.allInvites != undefined){
        var mergedInvites = [...this.props.allInvites.sent,...this.props.allInvites.received]
        for(var i=0;i<mergedInvites.length;i++){
            let dateString = mergedInvites[i].meeting_date
            let meetingStartTimeSplit = mergedInvites[i].meeting_start_time.split(" ")
            let meetingStarTimeSplitHourMinutes = meetingStartTimeSplit[0].split(":")
            let hour = parseInt(meetingStarTimeSplitHourMinutes[0])
            if(meetingStartTimeSplit[1] == "PM" || meetingStartTimeSplit[1] == "pm"){
                if(meetingStarTimeSplitHourMinutes[0] != 12){
                    hour += 12
                }
            }
            let meetingStartTimeString = hour+":"+meetingStarTimeSplitHourMinutes[1]
            let startTime = new Date(dateString + 'T' +meetingStartTimeString)
            let meetingEndTimeSplit = mergedInvites[i].meeting_end_time.split(" ")
            let meetingEndTimeSplitHourMinutes = meetingEndTimeSplit[0].split(":")
            hour = parseInt(meetingEndTimeSplitHourMinutes[0])
            if(meetingEndTimeSplit[1] == "PM" || meetingEndTimeSplit[1] == "pm"){
                if(meetingEndTimeSplitHourMinutes[0] != 12){
                    hour += 12
                }
            }
            let meetingEndTimeString = hour+":"+meetingEndTimeSplitHourMinutes[1]
            let endTime = new Date(dateString + 'T' +meetingEndTimeString)
            var item = {
                startTime : startTime,
                endTime : endTime
            }
            this.allInvitionDates.push(item)
        }
    }
    }
    handleOnParticipantsLoadedFromApi = (participants) => {
        for(var i=0;i<this.props.onlineParicipants.length;i++){
            let index = participants.findIndex(x=>x.id == this.props.onlineParicipants[i].userId)
            if(index > -1){
                participants[index].isOnline = true
                participants[index].socketId = this.props.onlineParicipants[i].socketId
            }
        }
        let bgColorUserList = participants.map(item=>
            {
                if(!item.hasOwnProperty('bgColor')){
                    return {...item,bgColor:randomColor()}
                }else{
                    return item
                }
            })
        this.setState({participants:[...bgColorUserList],meetingparticipent:[...bgColorUserList],loading : false})
        this.props.updateParticipantsList(bgColorUserList)
        this.props.meetingParticipent1(bgColorUserList)
    }
    handleOnInvitesFromApiLoaded = (e) => {
        this.initializeInvites()
    }
    handleOnUsersConnected = (e) => {
        let onlineUsers = e.detail.users
        let participants = this.state.participants
        for(var i=0;i<onlineUsers.length;i++){
            let index = participants.findIndex(x=>x.id == onlineUsers[i].userId)
            if(index > -1){
                participants[index].isOnline = true
                participants[index].socketId = onlineUsers[i].socketId
            }
        }
        let bgColorUserList = participants.map(item=>
            {
                if(!item.hasOwnProperty('bgColor')){
                    return {...item,bgColor:randomColor()}
                }else{
                    return item
                }
            })
        this.setState({participants:[...bgColorUserList]})
    }
    handleOnUserDisconnected = (e) => {
        let disconnectdUser = e.detail.user
        let participants = this.state.participants
        let index = participants.findIndex(x=>x.id == disconnectdUser.userId)
        if(index > -1){
            participants[index].isOnline = false
            participants[index].socketId = ''
        }
        let bgColorUserList = participants.map(item=>
            {
                if(!item.hasOwnProperty('bgColor')){
                    return {...item,bgColor:randomColor()}
                }else{
                    return item
                }
            })
        this.setState({participants:[...bgColorUserList]})
        // this.setState({participants})
    }
    OnNavigateToPage = (path,data = null) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            this.props.history.push(path)
        }else{
            this.props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    languageClick = (value) =>{
        console.log("Trigger booking on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnClickSort = (sortBy) => {
        let participants = this.state.participants
        this.sortToggle = !this.sortToggle
        if(sortBy == "name"){
            participants.sort(function(a, b){
                if(a.firstName < b.firstName) { return -1; }
                if(a.firstName > b.firstName) { return 1; }
                return 0;
            })
        }
        else if(sortBy == "company"){
            participants.sort(function(a, b){
                if(a.companyName < b.companyName) { return -1; }
                if(a.companyName > b.companyName) { return 1; }
                return 0;
            })
        }else{
            participants.sort(function(a, b){
                if(a.country < b.country) { return -1; }
                if(a.country > b.country) { return 1; }
                return 0;
            })
        }
        if(!this.sortToggle){
            participants.reverse()
        }
        this.setState({participants})
    }
    clearMeetingdata = ()=>{
        this.setState({meetingdata:false})
    }
    OnClickSendMeetingRequest = (data) => {
        let inviteStartTimeSplit = data.meeting_start_time.split(" ")
        let inviteStartTimeSplitHourMinutes = inviteStartTimeSplit[0].split(":")
        let hour = parseInt(inviteStartTimeSplitHourMinutes[0])
        if(inviteStartTimeSplit[1] == "PM" || inviteStartTimeSplit[1] == "pm"){
            if(inviteStartTimeSplitHourMinutes[0] != 12){
                hour += 12
            }
        }
        let inviteStartTimeString = hour+":"+inviteStartTimeSplitHourMinutes[1]
        let startTime = new Date(data.meeting_date + 'T' +inviteStartTimeString)
        let inviteEndTimeSplit = data.meeting_end_time.split(" ")
        let inviteEndTimeSplitHourMinutes = inviteEndTimeSplit[0].split(":")
        hour = parseInt(inviteEndTimeSplitHourMinutes[0])
        if(inviteEndTimeSplit[1] == "PM" || inviteEndTimeSplit[1] == "pm"){
            if(inviteEndTimeSplitHourMinutes[0] != 12){
                hour += 12
            }
        }
        let inviteEndTimeString = hour+":"+inviteEndTimeSplitHourMinutes[1]
        let endTime = new Date(data.meeting_date + 'T' +inviteEndTimeString)
        for(var i=0;i<this.allInvitionDates.length;i++){
            if(startTime >= this.allInvitionDates[i].startTime && startTime <= this.allInvitionDates[i].endTime){
                this.setState({sendingInviteError : 'You have a meeting already scheduled in this time slot'})
                return
            }
            if(endTime >= this.allInvitionDates[i].startTime && endTime <= this.allInvitionDates[i].endTime){
                this.setState({sendingInviteError : 'You have a meeting already scheduled in this time slot'})
                return
            }
        }
        if(this.state.sendingInvite){
            return
        }
        this.setState({sendingInvite : true,sendingInviteError : ''})
        let user = utility.getLoggedInUser(this.props.event.alias)
        inviteApi.sendInvite(this.props.event.alias,user.token,data,async (err,resData)=>{
            if(err == null){
                this.setState({meetingdata:true})
                let newInvites = []
                let failedParticipants = []
                for(var i=0;i<resData.length;i++){
                    if(resData[i].id == null){
                        let index = data.temp_ids.indexOf(resData[i].temp_id) 
                        let participant = this.props.participants.find(x=>x.id == data.ids[index])
                        await this.fetchParticipants((list)=>{
                            if(list.length > 0){
                                participant = list[0]
                            }
                        },"",[data.ids[index]])
                        participant.errorMessage = resData[i].message
                        failedParticipants.push(participant)
                        continue
                    }
                    let invite = data
                    invite.id = resData[i].id
                    let index = data.temp_ids.indexOf(resData[i].temp_id)
                    let participant = this.props.participants.find(x=>x.id == data.ids[index])
                    await this.fetchParticipants((list)=>{
                        if(list.length > 0){
                            participant = list[0]
                        }
                    },"",[data.ids[index]])
                    invite.receiver_details = {
                        firstName : participant.firstName,
                        lastName : participant.lastName,
                        jobTitle : participant.jobTitle,
                        companyName : participant.companyName,
                        profileImageUrl : participant.profileImageUrl,
                    }
                    newInvites.push(invite)  
                }
                if(failedParticipants.length == 0){
                    this.contentRef.OnMeetingScheduledResponse()
                }
                else{
                    var errorMessage = ""
                    for(var i=0;i<failedParticipants.length;i++){
                        errorMessage += failedParticipants[i].firstName + " " + failedParticipants[i].lastName + " : " + failedParticipants[i].errorMessage
                    }
                    if(errorMessage.trim() == "Cannot read properties of undefined (reading 'status')"){
                        this.setState({sendingInviteError:'Something went wrong! Please Try Again'})
                    }
                    else{
                    this.setState({sendingInviteError : errorMessage})
                    }
                }
                this.props.onAddNewInvite(newInvites,'sender')
                for(var i=0;i<newInvites.length;i++){
                    this.allInvitionDates.push({
                        startTime : newInvites[i].startTimeObj,
                        endTime : newInvites[i].endTimeObj
                    })
                }
            }else{
                if(err == -1){
                    this.contentRef.OnMeetingScheduledResponse()
                    this.setState({showErrorPopup : true,popUpErrorHeading : 'Login Required',popUpErrorMessage : 'Your session is expired please login again'})
                    $("#premiumSession").modal('show');
                    localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
                }else{
                    if(err.message.trim() == "Cannot read properties of undefined (reading 'status')"){
                        this.setState({sendingInviteError:'Something went wrong! Please Try Again'})
                    }
                    else{
                        this.setState({sendingInviteError : err.message})
                    }
                }
            }
            this.setState({sendingInvite : false})
        })
        
    }
    handleClosePopup = () => {
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
        this.OnNavigateToPage(this.props.event.alias+"/login")
    }
    loadMoreParticipants = () =>{
        if(this.state.loadingMore ){
            return
        }
        if(this.searchQuery != ""){
            if(this.allParticipantsLoaded.search){
                return
            }
            this.pageNoSearch += 1
            this.pageNo = this.pageNoSearch
        }else if(this.state.onlineFilter){
            if(this.allParticipantsLoaded.online){
                return
            }
        }
        else{
            if(this.allParticipantsLoaded.normal){
                return
            }
            this.pageNoNormal += 1
            this.pageNo = this.pageNoNormal
        }
        this.pageNo = this.pageNo +1
        this.setState({loadingMore : true})
        this.fetchParticipants((participants)=>{
            if(participants.length < 10){
                if(this.searchQuery != ''){
                    this.allParticipantsLoaded.search = true
                }else if(this.state.onlineFilter){
                    this.allParticipantsLoaded.online = true
                }
                else{
                    this.allParticipantsLoaded.normal = true
                }
            }
            let listToMerge = []
            for(var i=0;i<participants.length;i++){
                if(this.state.participants.findIndex(x=>x.id == participants[i].id) < 0){
                    listToMerge.push(participants[i])
                }
            }
            let bgColorUserList = [...this.state.participants,...listToMerge].map(item=>
                {
                    if(!item.hasOwnProperty('bgColor')){
                        return {...item,bgColor:randomColor()}
                    }else{
                        return item
                    }
                })
            let meetingBgColor = [...this.state.meetingparticipent,...listToMerge].map(item=>
                {
                    if(!item.hasOwnProperty('bgColor')){
                        return {...item,bgColor:randomColor()}
                    }else{
                        return item
                    }
                })
            this.setState({participants : [...bgColorUserList],meetingparticipent:[...meetingBgColor],loadingMore : false},()=>{
                this.props.updateParticipantsList(this.state.participants)
                this.props.meetingParticipent1(this.state.meetingparticipent)
            })
            if(this.state.onlineFilter){
                this.onlineParicipantsInitialLoadAmount += this.onlineParticipantsLoadMoreAmount
            }
        },'',this.participantsToFetch.slice(this.onlineParicipantsInitialLoadAmount,this.onlineParicipantsInitialLoadAmount+this.onlineParticipantsLoadMoreAmount))
    }
    searchParticipants = (query) => {
        this.searchQuery = query
        this.setState({meetingparticipentloader : true})
        if(this.searchQuery.length > 0){
            this.pageNo = this.pageNoSearch
        }else{
            this.pageNoNormal = 1
            this.pageNo = this.pageNoNormal
            this.allParticipantsLoaded.normal = false
        }
        this.fetchuser((participants)=>{
            let listToMerge = this.props.participants
            for(var i=0;i<participants.length;i++){
                if(this.state.meetingparticipent.findIndex(x=>x.id == participants[i].id) < 0){
                    listToMerge.push(participants[i])
                }
            }
            let bgColorUserList = participants.map(item=>
                {
                    if(!item.hasOwnProperty('bgColor')){
                        return {...item,bgColor:randomColor()}
                    }else{
                        return item
                    }
                })
            this.props.meetingParticipent1(bgColorUserList)
            this.setState({meetingparticipent:bgColorUserList,meetingparticipentloader : false})
        },query)
    }
    filterUser = (query,type = '') => {
        this.searchQuery = query
        this.setState({loadingSearchResults : true})
        if(this.searchQuery.length > 0){
            this.pageNo = this.pageNoSearch
        }else{
            this.pageNoNormal = 1
            this.pageNo = this.pageNoNormal
            this.allParticipantsLoaded.normal = false
        }
        this.fetchuser((participants)=>{
            let listToMerge = this.props.participants
            for(var i=0;i<participants.length;i++){
                if(this.state.participants.findIndex(x=>x.id == participants[i].id) < 0){
                    listToMerge.push(participants[i])
                }
            }
            let bgColorUserList = participants.map(item=>
                {
                    if(!item.hasOwnProperty('bgColor')){
                        return {...item,bgColor:randomColor()}
                    }else{
                        return item
                    }
                })
            this.props.updateParticipantsList(bgColorUserList)
            this.setState({participants:bgColorUserList,loadingSearchResults : false})

        },query)
    }
    searchMeetingParticipent = (query) => {
        this.searchQuery = query
        this.setState({loadingSearchResults : true})
        if(this.searchQuery.length > 0){
            this.pageNo = this.pageNoSearch
        }else{
            this.pageNoNormal = 1
            this.pageNo = this.pageNoNormal
            this.allParticipantsLoaded.normal = false
        }
        this.fetchuser((participants)=>{
            let listToMerge = this.props.participants
            for(var i=0;i<participants.length;i++){
                if(this.state.participants.findIndex(x=>x.id == participants[i].id) < 0){
                    listToMerge.push(participants[i])
                }
            }
            let bgColorUserList = participants.map(item=>
                {
                    if(!item.hasOwnProperty('bgColor')){
                        return {...item,bgColor:randomColor()}
                    }else{
                        return item
                    }
                })
            this.props.meetingParticipent1(listToMerge)
            this.setState({meetingparticipent:bgColorUserList,loadingSearchResults : false})
        },query)
    }
    fetchParticipants = async (callback,query = "",ids=null) => {
        await api.getEventParticipants(this.props.event.alias,(list)=>{
            for(var i=0;i<this.props.participentdata.length;i++){
                let index = list.findIndex(x=>x.id == this.props.participentdata[i].userId)
                if(index > -1){
                    list[index].isOnline = true
                    list[index].socketId = this.props.participentdata[i].socketId
                }
            }
            list = list.filter(x=>x.id != this.user.id)
            callback(list)
        },query,this.pageNo,ids)
    }
    fetchuser = async (callback,query = "",ids=null) => {
        await api.getEventParticipants(this.props.event.alias,(list)=>{
            console.log(list)
            for(var i=0;i<this.props.participentdata.length;i++){
                let index = list.findIndex(x=>x.id == this.props.participentdata[i].userId)
                if(index > -1){
                    list[index].isOnline = true
                    list[index].socketId = this.props.participentdata[i].socketId
                }
            }
            list = list.filter(x=>x.id != this.user.id)
            console.log(list)
            callback(list)
        },query,1,ids)
    }
    moveTolOgin=()=>{
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
    }
    handleOnFindOnlineParticipants = (val) => {
        this.setState({onlineFilter : val,filterPar:val})
        if(!val){
            return
        }
        let onlineParticipants = this.state.participants.filter(x=>x.isOnline).map(a=>a.id)
        this.participantsToFetch = this.props.onlineParicipants.map(x=>x.userId).filter((e)=>{
            return onlineParticipants.indexOf(e) < 0 && e!=this.user.id 
        },onlineParticipants)
        if(this.participantsToFetch.length > 0){
            this.setState({loadingMore : true})
            this.fetchParticipants((list)=>{
                for(var i=0;i<list.length;i++){
                    list[i].isOnline = true
                }
                let bgColorUserList = [...this.state.participants,...list].map(item=>
                    {
                        if(!item.hasOwnProperty('bgColor')){
                            return {...item,bgColor:randomColor()}
                        }else{
                            return item
                        }
                    })
                this.setState({participants : [...bgColorUserList],loadingMore : false})
            },"",this.participantsToFetch.slice(0,this.onlineParicipantsInitialLoadAmount))
        }
    }
    render () {
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <>
             <PermiumUserModal
        id="premiumSession"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
        }}
        onClickBookNow={() => {
        this.moveTolOgin()
          $("#premiumSession").modal("hide");
         $(".modal-backdrop").remove();
          this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Participants</title>
            </Helmet>
            <div id="header-top">
            <Header
                onMenuItemClick = {this.OnNavigateToPage}  
                elements = {this.props.headerMenuItems}
                facebookLink = {this.props.event.facebook}
                linkedInLink = {this.props.event.linkedIn}
                twitterLink = {this.props.event.twitter}
                isMarketplace = {this.props.event.is_marketplace}
                instagramLink = {this.props.event.instagram}
                googleLink = {this.props.event.google}
                phone = {this.props.event.phone_number}
                eventLogo = {this.props.event.logo}
                event = {this.props.event}
                eventName = {this.props.event.name}
                eventAlias = {this.props.event.alias}
                onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                availableLocales = {this.props.availableLocales}
                languageClick  = {this.languageClick}
                onClickAccountItem = {this.OnClickAccountItem}
            />
            {user != null && user.isBooking && Constants.showVirtualZone?
            <VirtualZone
                selectedItemIndex = {this.state.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
                event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }} 
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                menuItems = {this.props.virtualMenuItems}
                user = {user} 
            /> : null}
            </div>
            {this.state.loading ?
            <div style = {{display:'flex',justifyContent : 'center',alignItems : 'center',height : window.innerHeight}}>
                <Loader
                    type = 'Oval'
                    color = "#00b6ff"
                    height = {40}
                    width = {40}
                />
            </div> :
            <Content
                ref = {(ref)=>this.contentRef = ref}
                onClickSort = {this.OnClickSort}
                onClickChat = {(userId,socketId)=>{
                    var data = {
                        id : userId,
                        socketId : socketId
                    }
                    this.OnNavigateToPage(this.props.event.alias+'/userChat',data)
                }}
                event = {this.props.event}
                meetingParticipent= {this.state.meetingparticipent}
                meetingparticipentloader={this.state.meetingparticipentloader}
                RemoveScroll = {this.props.RemoveScroll}
                meetinguser={this.state.meetinguser}
                sendingInvite = {this.state.sendingInvite}
                sendingInviteError = {this.state.sendingInviteError}
                onClickSendMeetingInvite = {this.OnClickSendMeetingRequest}
                clearMeetingdata = {this.clearMeetingdata}
                meetingdata = {this.state.meetingdata}
                onlineParticipent = {this.props.onlineParicipants}
                searchMeetingParticipent = {this.searchMeetingParticipent}
                participants = {this.state.participants}
                loadingMore = {this.state.loadingMore}
                filterUser = {this.filterUser}
                loadMore = {this.loadMoreParticipants}
                socket = {this.props.socket}
                handleOnFindOnlineParticipants = {this.handleOnFindOnlineParticipants}
                loadingSearchResults = {this.state.loadingSearchResults}
                searchParticipants = {this.searchParticipants}
                filterPar = {this.state.filterPar}
                handleOnUserDisconnected = {this.handleOnUserDisconnected}
                user = {user}
                onlineUsersCount = {this.props.onlineUsersCount}
                findOnlineParticipants = {this.handleOnFindOnlineParticipants}
                translation ={this.translation}
            />}
            {/* <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showErrorPopup} 
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"  className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    participants : state.participants.participantsList,
    allInvites : state.invitations.allInvitations,
    meetingparticipent:state.participants.meetingparticipent,
    onlineParicipants : state.participants.onlineParticipantIds,
    participentdata:state.participants.participentdata
})
const mapDispatchToProps = dispatch => ({
    updateParticipantsList : data => dispatch(UpdateParticipantsList(data)),
    meetingParticipent1:data=>dispatch(meetingParticipent(data)),
    RemoveScroll : data => dispatch(RemoveScroll(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Participants);