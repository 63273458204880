import axios from '../axios'
import { Constants } from "../../Constants";
export const fetchPagesData = async(eventAlias,param =  null,token=null,callback)=>{
    console.log(eventAlias)
             axios.get(`${eventAlias}/marketPlace/pageDetails`,{
                    params:param,
                    headers: { 'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
    
                    'Authorization' : 'Bearer '+token
                 }
            }
             ).then(response=>{
                 console.log(response)
                 callback(null,response.data)

            }).catch(error=>{
                callback(error,null)
            })

}