import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Trans} from 'react-i18next'
import './styles.css'

export class Footer2 extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            
        };

    }

    render(){
        return(
            // this.props.isMarketplace == "0"?
            <section class = {`${this.props.isMarketplace == "0"? 'bg_primary ': 'footer-bootom-tele '}footer-bootom py-3`}>
                <div class="container">
                    <div class="row">
                        <div class={this.props.showCopyRight ? "col-md-6 text-center text-md-left mb-2" : "col-md-6 text-center text-md-left" }>
                            <li ><Link style = {{textDecoration : 'none'}} class = "text-light mr-md-3" to="/terms-conditions"><Trans>termsAndConditions</Trans></Link></li>
                            <li ><Link style = {{textDecoration : 'none'}} class = "text-light mr-md-3" to="/privacy-policy"><Trans>privacyPolicy</Trans></Link></li>
                            {this.props.event.is_jobfair != 1 ? <li ><Link style = {{textDecoration : 'none'}} class = "text-light mr-md-3" to="/cancellation-policy"><Trans>cancellationPolicy</Trans></Link></li>:null}

                        </div>
                        
                        <div  class="col-md-6 text-center text-md-right">
                        {/* <span style = {{fontSize : 13,position : 'relative' , top : 5}} class = "text-light">
                                Powered by <a href = "https://www.mwanmobile.com/" target = "_blank" class ="event-logo text-light mx-2"><img style = {{width : '18px'}} src = "/logo192.png"/>MWAN Mobile</a>
                        </span> */}
                            <img style = {{cursor : 'pointer'}} src = "/images/logos/powered-badge3.svg" alt="event-logo" onClick = {()=> window.open("https://www.mwanmobile.com/","_blank")}/>
                        </div>
                    </div>
                </div>
                {this.props.showCopyRight ?
                <div class="pl-md-0 container">
                    <div class="col-md-6 text-center text-md-left">
                        <span style = {{fontSize : 13}}  class = "text-light">
                            <Trans>copyright</Trans>© {new Date().getFullYear()}
                            <a class = "ml-2" href = {this.props.copyRightLink} target = "_blank" class ="event-logo">
                                {this.props.copyRightText ? " "+this.props.copyRightText :
                                    // <div style = {{
                                    //     width : 55,
                                    //     height : 19,
                                    //     // position : 'absolute',
                                    //     backgroundImage : `url(${this.props.copyRightLogo})`,
                                    //     backgroundSize: 'contain',
                                    //     backgroundPosition:'left',
                                    //     backgroundRepeat: 'no-repeat'
                                    // }}></div>
                                    <img class = "ml-1" style = {{width : '55px'}} src = {this.props.copyRightLogo}/>
                                }
                        </a>
                        </span>
                    </div>
                </div> : null}
            </section>
            // :null
        );
    }
}

export default Footer2;