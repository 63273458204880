const sponsors = (state = {}, action) => {
    switch (action.type) {
        case 'ALL_VIDEOS_LOADED' : 
            return{
            ...state,
            allVideosData : action.payload
            }
        case 'HOME_VIDEOS_DATA_LOADED' : 
            return{
            ...state,
            homeVideosData : action.payload
            }
        default:
            return state
        }
  }
  export default sponsors