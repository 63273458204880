
import axios from '../../../axios/index'
import { Constants } from '../../../../Constants';
export default {
    async senEmailToBoothRepresentative(event,params,token,callback){
        await axios.post(`${event}/representative/mail`,params,
        {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token
                }
            
            }).then(response=>{
            callback(null,response.data)
        }).catch(error=>{
            callback({
                message : 'Something went wrong'
            },null)
        });
    },
    async sendPublicMessage(event,params,token,callback){
        await axios.post(`${event}/publicChat/booth`,params,
        {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token
                }
            
            }).then(response=>{
            callback(null,response.data)
        }).catch(error=>{
            callback({
                message : 'Something went wrong'
            },null)
        });
    }

}