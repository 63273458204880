import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { Constants } from "../../../Constants";

const HeaderImage = (props) => {
  useEffect(() => {
    let background = document.getElementById("th-main_banner");
    background.style.backgroundImage = `url(${props.event.background_image})`;
  });
  return (
    <section class={`th-main_banner`} id="th-main_banner">
      <div className="container position-absolute">
        <div className="row">
          <div className="col-md-12 pr-0 pl-0 overlay">
            <div className="p-2 pr-5 pl-md-5 pl-3 text-light text-area">
              <h1 className="font-weight-bold main-heading">{props.event.name}</h1>
              <p className="mb-4 main-subheading">{props.event.sub_heading}</p>
              <div>
                {props.user == null ? (
                  <Link
                    to={`/${props.event.alias}/membership`}
                    class="btn mx-2 btn-tele"
                  >
                    Become a Member
                  </Link>
                ) : props.user != null && props.user.isBooking == false ? (
                  <Link
                    to={`/${props.event.alias}/membership`}
                    class="btn mx-2 btn-tele"
                  >
                    Become a Member
                  </Link>
                ) : null}
                <Link
                  
                  class="btn mx-2 btn-tele"
                  onClick={(e) => {
                    e.preventDefault();
                    var email = "sample@gmail.com";
                    var subject = "Invite Colleague";
                    var link = `Join the platform at _____ and you can`
                    var emailBody = `Hi there, %0D%0A have recently joined Telehealth Connect – a great new marketplace and members platform which enables the Telehealth ecosystem. %0D%0A The platform connects global professionals, organizations, and investors from the Telehealth industry and provides an ongoing information exchange and business connection service. %0D%0A ${link}: %0D%0A • 	Search and view members profiles. %0D%0A •	Chat and email with members and exhibitors directly %0D%0A •	Browse the knowledge hub’s library of videos, whitepapers and more to find information you are looking for  %0D%0A •	 Visit the virtual exhibition zone and explore solutions for your business needs`;
                    var attach = "path";
                    document.location =
                      "mailto:" + "?subject=" + subject+"&body="+emailBody;
                  }}
                >
                  Invite Colleague
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeaderImage;
