import React from 'react';
import ReactHtmlParser from 'react-html-parser';
export const AdditionalContent = (props)=>{
    return(
        <section class = "about-detail py-1" style = {{width : '100%'}}>
        <div class="container">
            <div class="row py-3">
                <div class="col-12 mb-2">
                    {ReactHtmlParser(props.content)}
                </div>
            </div>
        </div>
    </section>
    )
}