import React from 'react';
import Header from '../Header/Index';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import { ErrorScreen } from '../ErrorScreen';
import { Constants } from '../../Constants';
import { withRouter } from 'react-router-dom';
export class PageNotFound extends React.Component{
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    languageClick = (value) =>{
        console.log("Trigger Page not found on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index) =>{
        this.props.onClickAccountItem(index)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    render(){
        return(
            <>
            <Header 
                selectedIndex = {-1}
                onMenuItemClick = {this.OnNavigateToPage}  
                elements = {this.props.headerMenuItems}
                facebookLink = {this.props.event.facebook}
                linkedInLink = {this.props.event.linkedIn}
                twitterLink = {this.props.event.twitter}
                isMarketplace = {this.props.event.is_marketplace}
                instagramLink = {this.props.event.instagram}
                googleLink = {this.props.event.google}
                phone = {this.props.event.phone_number}
                event = {this.props.event}
                eventLogo = {this.props.event.logo}
                eventName = {this.props.event.name}
                eventAlias = {this.props.event.alias}
                onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                availableLocales = {this.props.availableLocales}
                languageClick  = {this.languageClick}
                onClickAccountItem = {this.OnClickAccountItem}
            />
            <ErrorScreen 
                message = "Page Not Found 404" 
                is404 = {true}
                onPressRetry = {()=>{
                    this.OnNavigateToPage('/')
                }}
            />
            <Footer1
                event = {this.props.event}
                elements = {this.props.footerMenuItems}
                onMenuItemClick = {this.OnNavigateToPage}
            />
            <Footer2
                event = {this.props.event} 
                copyRightLogo = {this.props.event.copyright_logo}
                copyRightText = {this.props.event.copyright_text}
                copyRightLink = {this.props.event.copyright_link}
                showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
            />
            </>
        )
    }
}
export default withRouter(PageNotFound)