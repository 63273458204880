import React,{useState,useEffect} from 'react'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import Loader from 'react-loader-spinner'
import utility from '../../Utility/utility';
import api from './api'
import {Trans} from 'react-i18next'
export default function OneonOneMeetingView(props){
    const [selectedSpeaker,setSpeaker] = useState({})
    const [selectedDateIndex,setDateIndex] = useState(0)
    const [selectedSlot,setSelectedSlot] = useState(null)
    const [previousSlot,setPrevSlot] = useState({})
    const [confirmingSlot,setCofirmSlotStatus] = useState(false)
    const [bookingError,setBookingError] = useState("")
    const [bookingDisabled,setBookingDisabled] = useState(false)
    const [userBookedSlots,setUserBookedSlots] = useState([])
    const [showInlineError,setShowInlineError] = useState(false)
    const [dropDownValue,setDropDownValue] = useState('')
    useEffect(()=>{
        $("#myModal").on("hidden.bs.modal", function () {
            setCofirmSlotStatus(false)
            setBookingError("")
            document.getElementById("modal-booking").style.display = "block"
            document.getElementById("thanks-msg").style.display = "none"
            document.getElementById("error-msg").style.display = "none"
        })
        setUserBookedSlots(props.userBookedSlots)
    },[])
    const checkSpeakerSlotExpiry = (speaker) => {
        speaker.schedules.sort((a,b)=>{
            let aDateSplit = a.date.split('-')
            let aDateString = aDateSplit[2]+","+aDateSplit[1]+","+aDateSplit[0]

            let bDateSplit = b.date.split('-')
            let bDateString = bDateSplit[2]+","+bDateSplit[1]+","+bDateSplit[0]

            return new Date(aDateString) - new Date(bDateString)
        })
        let currentDate = new Date()
        let currentDateSeconds = currentDate.getHours() * 3600 + currentDate.getMinutes() * 60 + currentDate.getMinutes()
        currentDate = currentDate.setHours(0,0,0,0)
        for(var i=0;i<speaker.schedules.length;i++){
            let dateSplit = speaker.schedules[i].date.split('-')
            let dateString = dateSplit[2]+","+dateSplit[1]+","+dateSplit[0]
            if(new Date(dateString).getTime() < currentDate){
                for(var j=0;j<speaker.schedules[i].slots.length;j++){
                    speaker.schedules[i].slots[j].expired = true
                }
            }else if(new Date(dateString).getTime() == currentDate){
                for(var j=0;j<speaker.schedules[i].slots.length;j++){
                    let slotStartTime = utility.slotStartTimeInSeconds(speaker.schedules[i].slots[j].time)
                    if(slotStartTime < currentDateSeconds){
                        speaker.schedules[i].slots[j].expired = true
                    }
                }
            }
        }
        return speaker
    }
    const OnSpeakerSelected = (speaker) => {
        let index = 0
        if(speaker != selectedSpeaker){
            setDateIndex(0)
        }else{
            index = selectedDateIndex
        }
        let speakerUpdated = checkSpeakerSlotExpiry(speaker)
        if(speakerUpdated.schedules.length > 0){
            setDropDownValue(speakerUpdated.schedules[index].date)
        }
        setSpeaker(speakerUpdated)
    }
    const renderOptions = () => {
        var options = []
        if(selectedSpeaker.schedules != null){
            for(var i=0;i<selectedSpeaker.schedules.length;i++){
                options.push(<option>{selectedSpeaker.schedules[i].date}</option>)
            }
        }
        return options
    }
    const onDropdownSelected = (e)=>{
        setBookingError("")
        let val = e.target.value
        setDropDownValue(val)
        let index = selectedSpeaker.schedules.findIndex(x=>x.date == val)
        setDateIndex(index)
    }
    const OnClickConfirmBooking = () => {
        if(confirmingSlot){
            return
        }
        setShowInlineError(false)
        setBookingError("")
        var selectedDateSlots = selectedSpeaker.schedules[selectedDateIndex].slots
        var expiredOrBookedSlotCount = 0
        for(var i=0;i<selectedDateSlots.length;i++){
            if(selectedDateSlots[i].isBooked == 1 || selectedDateSlots[i].expired){
                expiredOrBookedSlotCount += 1
            }
        }
        if(expiredOrBookedSlotCount == selectedDateSlots.length){
            setShowInlineError(true)
            setBookingError("No time slot availaible for booking in selected date")
            return
        }
        if(selectedSlot == null){
            setShowInlineError(true)
            setBookingError("No time slot selected , select a time slot to proceed")
            return
        }
        let speaker = userBookedSlots.filter(x=>x.id == selectedSpeaker.id)
        if(speaker.length > 0){
            let selectedDate = selectedSpeaker.schedules[selectedDateIndex].date
            for(var i=0;i<speaker.length;i++){
                if(speaker[i].schedules.findIndex(x=>x.date == selectedDate) > -1){
                    setBookingError("You have already booked a slot for this speaker in the selected date")
                    document.getElementById("modal-booking").style.display = "none"
                    document.getElementById("error-msg").style.display = "block"
                    return
                }
            }
        }
        let userBookedSlotsTemp = []
        for(var i=0;i<userBookedSlots.length;i++){
            let schedules = userBookedSlots[i].schedules
            for(var j=0;j<schedules.length;j++){
                userBookedSlotsTemp = [...userBookedSlotsTemp, ...schedules[j].slots]
            }
        }
        let hasAlreadyBookedInGivenSlot = utility.checkSlotAvailiblityForUser(selectedSlot.time,userBookedSlotsTemp)
        if(hasAlreadyBookedInGivenSlot){
            setBookingError("You have already booked a slot in given time frame please select another")
            document.getElementById("modal-booking").style.display = "none"
            document.getElementById("error-msg").style.display = "block"
            return
        }
        
        setCofirmSlotStatus(true)
        let user = utility.getLoggedInUser(props.eventAlias)
        var data = {
            meetingId : props.meetingId,
            meetingType : props.meetingType,
            speakerId : selectedSpeaker.id,
            scheduleId : selectedSpeaker.schedules[selectedDateIndex].id,
            selectedDate : selectedSpeaker.schedules[selectedDateIndex].date,
            slotId : selectedSlot.id,
            slotTime : selectedSlot.time,
            userId : user.id,
            userEmail : user.email
        }
        api.scheduleMeeting(props.eventAlias,data,(err,res)=>{
            if(err == null){
                let slotIndex = selectedSpeaker.schedules[selectedDateIndex].slots.findIndex(x=>x.id == selectedSlot.id)
                selectedSpeaker.schedules[selectedDateIndex].slots[slotIndex].isBooked = 1
                document.getElementById("modal-booking").style.display = "none"
                document.getElementById("thanks-msg").style.display = "block"
                if(userBookedSlots.findIndex(x=>x.id == selectedSpeaker.id) < 0){
                    userBookedSlots.push({
                        id : selectedSpeaker.id,
                        schedules : [{
                            id : selectedSpeaker.schedules[selectedDateIndex].id,
                            date : selectedSpeaker.schedules[selectedDateIndex].date,
                            slots : [selectedSlot]
                        }]
                    })
                }else{
                    let date = selectedSpeaker.schedules[selectedDateIndex].date
                    let index = userBookedSlots.findIndex(x=>x.id == selectedSpeaker.id)
                    if(userBookedSlots[index].schedules.findIndex(x=>x.date == date) > -1){
                        userBookedSlots[index].schedules[selectedDateIndex].slots.push(selectedSlot)
                    }else{
                        userBookedSlots[index].schedules.push(selectedSpeaker.schedules[selectedDateIndex])
                    }
                }
            }else{
                setBookingError(err.isEnable == 0 ? "This meeting is ended by admin" : err.message)
                if(err.isEnable == 0){
                    setBookingDisabled(true)
                }
                document.getElementById("modal-booking").style.display = "none"
                document.getElementById("error-msg").style.display = "block"
            }
            setCofirmSlotStatus(false)
        })
        
    }
    const OnClickHome = () => {
        props.onClickNetworking()
    }
    const OnClickSlotItem = (slot) => {
        if(slot.isBooked == 1 || slot.expired){
            return
        }
        if(selectedSlot == null){
            setBookingError("")
        }
        if(slot == previousSlot){
            setSelectedSlot(null)
            setPrevSlot(null)
        }else{
            setSelectedSlot(slot)
            setPrevSlot(slot)
        }
    }
    const OnClickGotItErrorPopup = () => {
        document.getElementById("modal-booking").style.display = "block"
        document.getElementById("error-msg").style.display = "none"
        if(bookingDisabled){
            props.onClickNetworking()
            $("#myModal").modal('hide')
            $('body').removeClass('modal-open')
            $('.modal-backdrop').remove()
        }
    }
    return(
        <section class = "networking-meeting bg-light py-3">
            <div class="container text-center">
            
                {props.mainViewNavigationEnabled ? 
                <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item"><a style = {{cursor : 'pointer'}} onClick = {OnClickHome}><Trans>networking</Trans></a></li>
                    <li class="breadcrumb-item active" aria-current="page">{props.meetingName}</li>
                </ol>
                </nav> : null}
            
                <h1>
                    {props.meetingName}
                </h1>
                <p>
                    {props.meetingDescription != null ? props.meetingDescription 
                    : <Trans>selectSpeaker</Trans>}
                </p>
                <div class="row justify-content-center mt-3">
                    {props.meetingData.map((speaker,index)=>{
                        if(Array.isArray(speaker)){
                            return null
                        }
                        return(
                        <div class="col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class = "card_inner bg-white py-3 px-2 border text-center h-100">
                                <div class = "user_img">
                                    {speaker.image ?
                                    <img src = {speaker.image} class = "rounded-circle" alt = "user_1-img"/> : 
                                    <img src="/images/profile-placeholder.png" class="rounded-circle mt-2 adj_user" alt="user_1-img"/>}
                                </div>
                                <div class = "user_caption mt-4">
                                    <h6 class = "font-weight-bold">{speaker.name + " "+(speaker.surname == null ? "" : speaker.surname)}</h6>
                                    <p class = "mb-0">{speaker.job_title} {speaker.company != null ? ", "+speaker.company : ""}</p>
                                    {speaker.description != null ?
                                    <p class = "mt-3">
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>OnSpeakerSelected(speaker)} data-toggle="modal" data-target="#bioModal">
                                            <Trans>seeBio</Trans></a>
                                    </p> : null}
                                </div>
                                {/* <div class = "sch-btn py-3 px-2 text-center">
                                    <a style = {{cursor : 'pointer'}} onClick = {()=> OnSpeakerSelected(speaker)} class = "btn sch-meeting" data-toggle="modal" data-target="#myModal">
                                        <Trans>scheduleMeeting</Trans>
                                        <i class="fas fa-arrow-right float-right mt-1"></i>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        )
                    })}
                </div>
                
            </div>
            
        {/* The Modal  */}
        <div class="modal" id="myModal">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">

                {/* Modal body  */}
                <div class="modal-body">
                    <div class = "modal-booking" id = "modal-booking">
                    
                        <h2><Trans>bookTime</Trans></h2>
                        <h3>{props.meetingName}</h3>
                        
                        <div class = "speaker-details">
                        
                            <div class = "spk-pic">
                                {selectedSpeaker.image != null ?
                                <img src = {selectedSpeaker.image} class = "rounded-circle" alt = "user_1-img"/> : 
                                <img src="/images/profile-placeholder.png" class="rounded-circle mt-2 adj_user" alt="user_1-img"/>}
                            </div>
                            
                            <div class = "spk-infos">
                                <h4>{selectedSpeaker.name + " "+(selectedSpeaker.surname == null ? "" : selectedSpeaker.surname)}</h4>
                                <p>{selectedSpeaker.job_title}</p>
                                <p class = "f-500">{selectedSpeaker.company}</p>
                            </div>
                        
                        </div>
                        
                        <div class = "chosse-time row mt-3">
                            <div class = "col-md-9">
                                <p class = "mb-0">
                                    <span><Trans>availableTime</Trans></span>
                                </p>
                            </div>
                            <div class = "col-md-3">
                                <select value = {dropDownValue} onChange = {onDropdownSelected} class="form-control" id="dateSelector">
                                    {renderOptions()}
                                </select>
                            </div>
                        </div>
                        {selectedSpeaker.schedules != null && selectedSpeaker.schedules.length > 0?
                        <div class = "Select-time w-100 mt-3 justify-content-center">
                            {selectedSpeaker.schedules[selectedDateIndex].slots.map((slot,index)=>{
                                const selectedClass = slot.isBooked == 1 ? " selected" : ""
                                const activeClass = selectedSlot != null ? selectedSlot.id == slot.id && selectedSlot.isBooked == 0 ? " active" : "" : ""
                                const endedClass = slot.expired ? " ended" : ""
                                return(
                                    <div onClick = {()=> OnClickSlotItem(slot)} class = {"time-box text-center"+selectedClass+activeClass+endedClass}>
                                        <p class = "mb-0">
                                            {slot.time}
                                        </p>
                                    </div>
                                )
                            })}
                            
                        </div> : null}
                        
                        {/* Modal footer  */}
                        <div class="modal-footer mb-footer px-0 mt-3 mt-md-4">
                            {!confirmingSlot ?
                            <>
                            <button type="button" class="btn btn-defult border" data-dismiss="modal">
                                <Trans>cancel</Trans>
                            </button>
                            
                            <button style = {{cursor : confirmingSlot ? 'not-allowed' : 'pointer'}} onClick = {OnClickConfirmBooking} type="button" class="btn continue" id = "continue-btn">
                                <Trans>confirmBooking</Trans>
                            </button>
                            </>:
                            <div style = {{marginRight : 20}}>
                                <Loader
                                    type = 'Oval'
                                    color = "#00b6ff"
                                    height = {30}
                                    width = {30}
                                />
                            </div>}
                        </div>
                        {showInlineError ? 
                        <span style = {{position : 'absolute',right : 30,bottom : 10}} class = "text-red">{bookingError}</span> : null}
                    </div>
                    
                    <div class = "thanks-msg text-center py-3 py-md-4" id = "thanks-msg">
                        <p>
                            <i class="fas fa-check-circle"></i>
                        </p>
                        <h3>
                            <Trans>thankyou</Trans>
                        </h3>
                        
                        <p>
                            <Trans>bookingConfirmed</Trans> <br/>
                            <Trans>checkMail</Trans>
                        </p>
                        
                        <p>
                            <button type="button" class="btn got-it" data-dismiss="modal">
                                <Trans>gotIt</Trans>
                            </button>
                        </p>
                        
                    </div>
                    <div class = "thanks-msg text-center py-3 py-md-4" id = "error-msg">
                        <p>
                            <i class="fas fa-times-circle" style = {{color : 'red'}}></i>
                        </p>
                        <h3>
                            <Trans>error</Trans>
                        </h3>
                        
                        <p>
                            {bookingError}
                        </p>
                        
                        <p>
                            <button type="button" class="btn got-it" onClick = {OnClickGotItErrorPopup}>
                                <Trans>gotIt</Trans>
                            </button>
                        </p>
                        
                    </div>
                    
                </div>

                </div>
            </div>
        </div>

        <div class="modal" id="bioModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content bio-modal">
                
                    <div class = "modal-header px-3 px-md-5">
                        <h3>{selectedSpeaker.name + " "+(selectedSpeaker.surname == null ? "" : selectedSpeaker.surname)}</h3>
                        <button data-dismiss="modal">
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div>
                {/* Modal body  */}
                    <div class="modal-body px-3 px-md-5">
                        {ReactHtmlParser(selectedSpeaker.description)}
                    </div>

                </div>
            </div>
        </div>

        </section>
    )
}