import React from 'react';
import api from './api';
import scheduleApi from '../Schedule/api'
import Header from '../Header/Index';
import Content from './content';
import $ from 'jquery'
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import { withRouter } from 'react-router-dom';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import MetaTags from 'react-meta-tags'
import { Constants } from '../../Constants';
import VirtualZone from '../Header/virtualZone';
import {Modal,Button} from 'react-bootstrap'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { UpdateMyFavourites } from './actions';
import { GAPageView, GARecordEvent } from '../../Utility/GA';
import {Trans,withTranslation} from 'react-i18next'
import  PermiumUserModal  from "../modals/premiumUserModal"
class Account extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loadingError : '',
            selectedTab : 0,
            loggedInUser : null,
            loading : false,
            saveAccountError : '',
            accountUpdatedMessage : '',
            myBookings : [],
            editingProfile : false,
            showingBookingDetail : false,
            editZoom:false,
            myFavorites : [],
            showErrorPopup : false,
            popUpErrorHeading : '',
            popUpErrorMessage : '',
            sessionExpire:null,
            favoritesInfo:false,
            socialinfo: false,
            bookingInfo:false,
            deleteAccountMessage:'',
            successDeleteAccount:''
        };
        this.accountUpdated = false
        this.translation = this.props.i18n.getDataByLanguage(localStorage.getItem("language"+localStorage.getItem("alias"))||'en')
        this.user = utility.getLoggedInUser(this.props.event.alias)
    }

    componentDidMount(){
        //console.log("TRANSLATION IN INDEX",this.translation)
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=profile')
        fetch(`/${this.props.event.alias}/profile`)
        var data = this.props.location.state
        if(data != null){
            this.setState({selectedTab : data.tabIndex})
        }
        var loggedInUser = utility.getLoggedInUser(this.props.event.alias)
        if(loggedInUser != null){
            this.setState({loggedInUser},()=>{
                // this.GetMyAccount()
                if(this.state.selectedTab == 1){
                    this.GetMyBookings()
                }else if(this.state.selectedTab == 2){
                    this.GetMyFavorites()
                }
            })
        }
        else{
            this.OnNavigateToPage(this.props.event.alias+'/login')
        }
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        
    }
    getBookingInfo = ()=>{
        this.setState({bookingInfo:false})
    }
    GetMyAccount = () => {
        this.setState({loading : true})
        api.getAccountDetail(this.props.event.alias,this.state.loggedInUser.token,(err,user)=>{
            this.setState({loading : false})
            if(err == null){
                user.profileImageUrl = this.state.loggedInUser.profileImageUrl
                user.token = this.state.loggedInUser.token
                user.isBooking = this.state.loggedInUser.isBooking
                localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(user))
                if(this.state.selectedTab == 1){
                    this.GetMyBookings()
                }else if(this.state.selectedTab == 2){
                    this.GetMyFavorites()
                }
                this.setState({loggedInUser : user})
            }
            else{
                console.log('error, ',err)
            }
        })
    }
    expireSession = ()=>{
        this.setState({sessionExpire:null})
    }
    onDeleteAccount = ()=>{
        this.setState({loading : true,deleteAccountMessage : '',successDeleteAccount:''})
        
        var loggedUser = JSON.parse(localStorage.getItem(Constants.API_TOKEN+this.props.event.alias+'user_info'))
        api.deleteAccount(this.props.event.alias,loggedUser.token,Constants.API_TOKEN,(err,data,useData)=>{
            this.setState({loading : false})
            if(err == null){
            if( useData && useData.errorCode == 440){
                this.setState({sessionExpire:useData.errorCode})
                $("#profileSession").modal("show")
            }
            this.setState({successDeleteAccount:data.message})
            this.setState({loading : false})
    
                    GARecordEvent("profileUpdatedClicked", this.user.id,{
                        category : 'Profile',
                        action : 'Profile updated',
                        label : 'By user: '+loggedUser.id
                    })
                // localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(updatedUser))
                setTimeout(() => {
                    this.setState({successDeleteAccount : ''})
                    localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
                }, 3000);

            }else{
                if(err.message.trim() == "Cannot read properties of undefined (reading 'status')"){
                    this.setState(({deleteAccountMessage:"Something Went Wrong Please try Again"}))
                }
                else{
                    this.setState(({deleteAccountMessage:err.message}))
                }
                setTimeout(() => {
                    this.setState({deleteAccountMessage : ''})
                }, 3000);

            }




        
    })
}
onReactiveAccount = ()=>{
    this.setState({loading : true,deleteAccountMessage : '',successDeleteAccount:''})
    
    var loggedUser = JSON.parse(localStorage.getItem(Constants.API_TOKEN+this.props.event.alias+'user_info'))
    api.reactivateAccount(this.props.event.alias,loggedUser.token,Constants.API_TOKEN,(err,data,useData)=>{
        this.setState({loading : false})
        if(err == null){
        if( useData && useData.errorCode == 440){
            this.setState({sessionExpire:useData.errorCode})
            $("#profileSession").modal("show")
        }
        this.setState({successDeleteAccount:data.message})
        this.setState({loading : false})

                GARecordEvent("profileUpdatedClicked", this.user.id,{
                    category : 'Profile',
                    action : 'Profile updated',
                    label : 'By user: '+loggedUser.id
                })
            // localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(updatedUser))
            setTimeout(() => {
                this.setState({successDeleteAccount : ''})
                localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
            }, 3000);

        }else{
            if(err.message.trim() == "Cannot read properties of undefined (reading 'status')"){
                this.setState(({deleteAccountMessage:"Something Went Wrong Please try Again"}))
            }
            else{
                this.setState(({deleteAccountMessage:err.message}))
            }
            setTimeout(() => {
                this.setState({deleteAccountMessage : ''})
            }, 3000);

        }




    
})
}
    OnUpdateAccount = (data,changingPassword = false,uploadingImage = false,imageURl) => {
        this.changingPassword = changingPassword
        if(uploadingImage){
            let user = this.state.loggedInUser
            user.profileImageUrl = imageURl
            this.setState({loggedInUser : user})
        }
        let body = {}
        if(changingPassword){
            body.current_password = data.oldPassword
            body.new_password = data.password
        }else if(!uploadingImage){
            body.first_name = data.firstName
            body.second_name = data.secondName
            body.last_name = data.lastName
            body.contact_number = data.formattedPhone
        }
        this.setState({loading : true,saveAccountError : ''})
        api.updateAccount(this.props.event.alias,this.state.loggedInUser.token,data,(err,data,useData)=>{
            if( useData && useData.errorCode == 440){
                this.setState({sessionExpire:useData.errorCode})
                $("#profileSession").modal("show")
            }
            this.setState({loading : false})
            if(err == null){
                this.saveZoomInfo()
                this.saveSocialInfo()
                var message = data.message
                if(this.changingPassword){
                    // api.jobFairUpdatePassword(body,this.state.loggedInUser.jobFairJwt,(error,data)=>{

                    // })
                    message = <Trans>passwordChanged</Trans>
                    let oldPasswordField = document.getElementById("oldPassword")
                    if(oldPasswordField != null){
                        oldPasswordField.value = ""
                    }
                    document.getElementById("newPassword").value = ""
                    document.getElementById("confirmPassword").value = ""
                    this.changingPassword = false
                    
                }else{
                    if(this.props.event.is_jobfair == 1){
                        if(!uploadingImage){
                            api.jobFairUpdateUser(body,this.state.loggedInUser.jobFairJwt,(error,data)=>{

                            })
                        }
                    }
                    GARecordEvent("profileUpdatedClicked", this.user.id,{
                        category : 'Profile',
                        action : 'Profile updated',
                        label : 'By user: '+this.state.loggedInUser.id
                    })
                }
                var updatedUser = data.user
                updatedUser.token = this.state.loggedInUser.token
                updatedUser.isBooking = this.state.loggedInUser.isBooking
                updatedUser.jobFairJwt = this.state.loggedInUser.jobFairJwt
                localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(updatedUser))
                this.setState({loggedInUser : data.user,accountUpdatedMessage : message,editingProfile : false})
                this.accountUpdated = true
                if(this.contentRef != null){
                    this.contentRef.OnProfilePictureUpLoaded()
                }
                setTimeout(() => {
                    this.setState({accountUpdatedMessage : ''})
                }, 3000);
            }
            else{
                if(err.message.trim() == "Cannot read properties of undefined (reading 'status')"){
                    this.setState({saveAccountError: 'Something went wrong! Please Try Again'})
                }
                else{
                this.setState({saveAccountError : err.message})
                }
            }
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    GetMyBookings = () => {
        this.setState({loading : true})
        api.getMyBookings(this.props.event.alias,this.state.loggedInUser.token,(err,bookings)=>{
            this.setState({loading : false})
            if(err == null){
                this.setState({myBookings : bookings,bookingInfo:true},()=>{
                    // this.contentRef.adjustBookingsArray()
                })
            }
            else{
                console.log('bookings, ',err)
            }
        })
    }
    GetMyFavorites = () => {
        if(this.props.myFavourites != null){
            this.setState({myFavorites : this.props.myFavourites,favoritesInfo:true})
            // this.contentRef && this.contentRef.myFavouritesLoaded(this.props.myFavourites)
            return
        }
        this.setState({loading : true})
        api.getMyFavorites(this.props.event.alias,this.state.loggedInUser.token,(err,favorites)=>{
            this.setState({loading : false})
            if(err == null){
                this.setState({myFavorites : favorites,favoritesInfo:true},()=>{
                    // this.contentRef &&  this.contentRef.myFavouritesLoaded(favorites)
                    this.props.updateMyfavourites(favorites)
                })
            }
            else{
                console.log('my favs, ',err)
            }
        })
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
    }
    OnClickLoginUser = (email,password) => {

    }
    languageClick = (value) =>{
        //console.log("Trigger Account on lan select");
        this.props.languageClick(value);
    }
    editProfileEvent= () => {
        //console.log("Edit Profile button clicked")
        GARecordEvent("Edit profile button Clicked", this.user.id,{
            category : 'Edit profile',
            action : 'Edit button clicked',
            userName: this.user.firstName+''+this.user.lastName,
            userEmail: this.user.email
        })

        this.setState({editingProfile : true})
    }
    editZoomInfo = ()=>{
        this.setState({editZoom:true})
    }
    editSocialInfo = ()=>{
        this.setState({socialinfo:true})
    }
    saveSocialInfo = ()=>{
        this.setState({socialinfo:false})
    }
    saveZoomInfo=()=>{
        this.setState({editZoom:false})
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(this.state.loading){
            return
        }
        if(index > -1){
            this.setState({loading : false,accountUpdatedMessage : '',saveAccountError : '',editingProfile : false,showingBookingDetail : false})
            this.setState({selectedTab : index})
            if(index == 0){
                // if(this.accountUpdated){
                //     this.GetMyAccount()
                //     this.accountUpdated = false
                // }
            }
            else if(index == 1){
                this.GetMyBookings()
            }else if(index == 2){
                this.GetMyFavorites()
            }
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnMarkFavorite = (data) => {
        let user = this.user
        if(user != null){
            scheduleApi.markAgendaFavorite(this.props.event.alias,user.token,data,(err,resObj)=>{
                if(err != null){
                    if(err == -1){
                        this.shouldNavigate = true
                        this.setState({showErrorPopup : true,popUpErrorHeading : 'Login Required',popUpErrorMessage : 'Your session is expired please login again'})
                        $("#premiumSession").modal('show');
                        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
                    }
                }else{
                    let favourites = this.props.myFavourites
                    if(favourites != null){
                        let index = favourites.findIndex(x=>x.id == resObj.id)
                        if(index < 0){
                            favourites.push(resObj)
                        }else{
                            favourites.splice(index,0)
                        }
                        this.props.updateMyfavourites(favourites)
                    }
                }
            })
        }
    }
    handleClosePopup = () => {
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
        this.OnNavigateToPage(this.props.event.alias+'/login')
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
          
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    moveTolOgin=()=>{
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
    }
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                 <PermiumUserModal
        id="premiumSession"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
        }}
        onClickBookNow={() => {
        this.moveTolOgin()
          $("#premiumSession").modal("hide");
         $(".modal-backdrop").remove();
          this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Account</title>
                </Helmet>
                <div id="header-top">
                <Header
                    onMenuItemClick = {this.OnNavigateToPage}
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    isMarketplace = {this.props.event.is_marketplace}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                     event = {this.props.event}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                    onClickAccountItem = {this.OnClickAccountItem}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    notifications = {this.props.notifications} 
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div>
                    <div className = {`content-main`}>
                        <Content
                            ref = {(ref)=>this.contentRef = ref}
                            countries = {this.props.countries}
                            deleteAccountMessage={this.state.deleteAccountMessage}
                            successDeleteAccount = {this.state.successDeleteAccount}
                            loading = {this.state.loading}
                            socialinfo = {this.state.socialinfo}
                            editSocialInfo = {this.editSocialInfo}
                            saveSocialInfo = {this.saveSocialInfo}
                            favoritesInfo={this.state.favoritesInfo}
                            onDeleteAccount = {this.onDeleteAccount}
                            onReactiveAccount = {this.onReactiveAccount}
                            getBookingInfo = {this.getBookingInfo}
                            bookingInfo = {this.state.bookingInfo}
                            selectedTab = {this.state.selectedTab}
                            event = {this.props.event}
                            onClickAccountItem = {this.OnClickAccountItem}
                            loggedInUser = {this.state.loggedInUser}
                            updateProfile = {this.OnUpdateAccount}
                            savingProfile = {this.state.loading}
                            successMessage = {this.state.accountUpdatedMessage}
                            saveProfileError = {this.state.saveAccountError}
                            myBookings = {this.state.myBookings}
                            myFavorites = {this.state.myFavorites}
                            sessionExpire = {this.state.sessionExpire}
                            expireSession = {this.expireSession}
                            editProfile = {this.editProfileEvent }
                            saveZoomInfo = {this.saveZoomInfo}
                            editingProfile = {this.state.editingProfile}
                            onMarkFavorite = {this.OnMarkFavorite}
                            editZoomInfo = {this.editZoomInfo}
                            showBookingDetail = {()=> this.setState({showingBookingDetail : true})}
                            hideBookingDetail = {()=> this.setState({showingBookingDetail : false})}
                            showingBookingDetail = {this.state.showingBookingDetail}
                            zoomEdit={this.state.editZoom}
                            translation= {this.translation.translation}
                        />
                    </div>
                    {/* <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                        {this.state.loadingError == "" ?
                            <Loader
                                type = 'Oval'
                                color = "#00b6ff"
                                height = {40}
                                width = {40}
                            />:
                            <ErrorScreen 
                                message = {this.state.loadingError}
                                onPressRetry = {this.OnPressRetry}
                            />
                        }
                    </div> */}
                {/* <Footer1
                    event = {this.props.event}
                    elements = {this.props.footerMenuItems}
                    onMenuItemClick = {this.OnNavigateToPage}
                />
                <Footer2 
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                /> */}
                {/* <Modal 
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showErrorPopup} 
                    onHide={this.handleClosePopup}
                >
                    <Modal.Header>
                    <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal> */}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    myFavourites : state.myAccount.favourites
})
const mapDispatchToProps = dispatch => ({
    updateMyfavourites : data => dispatch(UpdateMyFavourites(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Account);