import React from 'react';
import {Constants} from '../../Constants';
import api from '../Agenda/api';
import CountUp from 'react-countup';
import $ from 'jquery';
import VisibilitySensor from 'react-visibility-sensor'
import IntersectionVisible from 'react-intersection-visible'
import ContentLoader from 'react-content-loader'
import { EventNumbersLoaded } from '../App/actions';
import {connect} from 'react-redux'
export class EventNumbers extends React.Component{
    _isMounted = false
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias : this.props.event_alias,
            type : this.props.type,
            isVisible : false,
            feature : {},
            animationCompletedStatus : [],
            showData : false,
            isLoading : true,
            EventNumber:[],
            title:'Event Counter'

        };
    }

    componentDidMount(){
        this._isMounted = true
        console.log(this.props)
        if(this.props.featureLoading != this.state.isLoading){
            this.setState({isLoading:this.props.featureLoading})
        }
        if(this.props.EventNumber.length>0){
            if(this.props.EventNumber[0].hasOwnProperty('counter')){
                this.setState({EventNumber:this.props.EventNumber[0].counter.items,title:this.props.EventNumber[0].counter.title})
                 var tempArr = []
                    for(var i=0;i<this.props.EventNumber[0].counter.items.length;i++){
                        tempArr.push({
                            shouldShow : this.props.EventNumber[0].counter.items[i].numbers.indexOf('+') > -1 ? true : false,
                            completed : false
                        })
                    }
                    let showData = false
                    if(this.props.EventNumber[0].counter.items.length > 0){
                        showData = true
                    }
                    this.setState({animationCompletedStatus : tempArr,showData})

            }
        }
        // if(this.props.eventNumbers != null){
        //     var tempArr = []
        //     for(var i=0;i<this.props.eventNumbers.features.length;i++){
        //         tempArr.push({
        //             shouldShow : this.props.eventNumbers.features[i].numbers.indexOf('+') > -1 ? true : false,
        //             completed : false
        //         })
        //     }
        //     let showData = false
        //     if(this.props.eventNumbers.features.length > 0){
        //         showData = true
        //     }
        //     this.setState({animationCompletedStatus : tempArr,feature:this.props.eventNumbers,showData,isLoading : false})
        //     return
        // }
        // api.getEventFeatures(this.state.event.alias,this.state.type,null,(err,data)=>{
        //     if(err == null && this._isMounted){
        //         if(data.feature != null){
        //             this.props.eventNumbersLoaded(data.feature)
        //             this.setState({feature:data.feature});
        //             var tempArr = []
        //             for(var i=0;i<data.feature.features.length;i++){
        //                 tempArr.push({
        //                     shouldShow : data.feature.features[i].numbers.indexOf('+') > -1 ? true : false,
        //                     completed : false
        //                 })
        //             }
        //             let showData = false
        //             if(data.feature.features.length > 0){
        //                 showData = true
        //             }
        //             this.setState({animationCompletedStatus : tempArr,showData})
        //         }
        //         this.setState({isLoading : false})
        //     }
        // })
    }
    componentWillReceiveProps(nextProps){
        console.log(nextProps)
        if(nextProps.featureLoading != this.state.isLoading){
            this.setState({isLoading:nextProps.featureLoading})
        }
        if(nextProps.EventNumber.length>0){
            if(nextProps.EventNumber[0].hasOwnProperty('counter')){
                this.setState({EventNumber:nextProps.EventNumber[0].counter.items,title:nextProps.EventNumber[0].counter.title})
                 var tempArr = []
                    for(var i=0;i<nextProps.EventNumber[0].counter.items.length;i++){
                        tempArr.push({
                            shouldShow : nextProps.EventNumber[0].counter.items[i].numbers.indexOf('+') > -1 ? true : false,
                            completed : false
                        })
                    }
                    let showData = false
                    if(nextProps.EventNumber[0].counter.items.length > 0){
                        showData = true
                    }
                    this.setState({animationCompletedStatus : tempArr,showData})

            }
        }
    }
    componentWillUnmount(){
        this._isMounted = false
    }
    OnAnimationStarted = (index) => {
        var tempArr = this.state.animationCompletedStatus
        if(tempArr[index].shouldShow){
            tempArr[index].completed = true
        }
        this.setState({animationCompletedStatus : tempArr})
    }
    OnAnimationEnded = (index) => {
        var tempArr = this.state.animationCompletedStatus
        if(tempArr[index].shouldShow){
            tempArr[index].completed = true
        }
        this.setState({animationCompletedStatus : tempArr})
    }
    function( $ ){
        alert("yes yes");
    }
    OnVisibilityChange = (isVisible) => {
        this.setState({isVisible : isVisible})
    }
    render(){
        if(this.state.isLoading){
            return (
                <ContentLoader
                    speed={1}
                    viewBox="0 0 340 64"
                    {...this.props}
                >
                    <rect x="50" y="23" rx="0" ry="0" width="60" height="13" />
                    <rect x="140" y="23" rx="0" ry="0" width="60" height="13" />
                    <rect x="230" y="23" rx="0" ry="0" width="60" height="13" />
                </ContentLoader>
            )
        }
        const feature = this.state.EventNumber;

        if (feature){

            var flage = 0;

            if(feature != null){
                if(feature.length > 0){
                    flage = 1;
                }

            }
            if(flage == 1){
                return(
                    <IntersectionVisible onShow = {()=>this.OnVisibilityChange(true)} onHide = {()=>this.OnVisibilityChange(false)}>
                        <section class="event_counter justify-content-center bg_primary pt-4 pb-2 text-light">
						<div class="container">
							<h5 class = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>
							<div class="row justify-content-center py-2 py-md-3 text-center">

                                {
                                    feature ?
                                        feature.map((item,index)=>{
                                            // if(item.isActive == 1){
                                                var newItem = Object.assign({},item)
                                                if(newItem.numbers.indexOf('+') == newItem.numbers.length-1){
                                                    newItem.numbers = newItem.numbers.substring(0,newItem.numbers.length-1)
                                                }
                                                return (

													<div id = {""+this.state.isVisible} class={index == feature.length - 1 ? "col-md-3 col-sm-6" : "col-md-3 col-sm-6 count-col"} key={index}>
														
                                                            {this.state.isVisible ?
                                                            <div style = {{display : 'flex',flexDirection : 'row',alignItems : 'center',justifyContent : 'center'}}>
                                                                <CountUp onStart = {()=>this.OnAnimationStarted(index)} onEnd={()=>this.OnAnimationEnded(index)} className = "font-weight-bold counter eventCount" start = {0} duration = {4} delay = {0.1} end = {newItem.numbers} />
                                                                {this.state.animationCompletedStatus[index].completed ? <h1 style = {{marginTop : 8}} class = "font-weight-bold counter" data-counter-time="4000"
															    data-counter-delay="1000">+</h1> : null}
                                                            </div>: 
                                                            <h1 class = "font-weight-bold counter" data-counter-time="4000"
															data-counter-delay="1000">{item.numbers}</h1>}
														<h5 class = "f-500">{item.title}</h5>
													</div>

                                                );
                                            // }

                                        }) : null
                                }


							</div>
						</div>
					</section>
                    </IntersectionVisible>
				);

			}else{
            	return(null);
			}//end of flag if

		}else{
            return(null)
        }//end of feature if

    }
}
const mapStateToProps = state => ({
    eventNumbers: state.appData.eventNumbers,
    EventNumber:state.appData.allFeature,
    featureLoading:state.appData.featureLoading
})
const mapDispatchToProps = dispatch => ({
    eventNumbersLoaded : data => dispatch(EventNumbersLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(EventNumbers);