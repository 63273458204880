import React from 'react';
import {Trans} from 'react-i18next' 
class Content extends React.Component{
    
    render(){
        return(
            <div class="content">
                <section class="delegate-details my-5">
                    <div class="container bg-light border py-2 text-center">
                        <div class="success-icon mt-3">
                            <img src="/images/icons/cancel.svg" />
                        </div>
                        <h5 class="text-uppercase my-3"> {this.props.title} </h5>
                        <div class="row  py-3">
                            <div class="col-12 px-5">
                            
                                <p>
                                    <Trans>sorry</Trans><br/><Trans>tryAgain</Trans>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        )
    }
}
export default Content
