import React from 'react'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import { connect } from 'react-redux'
import {compose} from 'redux'
import utility from '../../../Utility/utility';
import Header from '../../Header/Index';
import VirtualZone from '../../Header/virtualZone';
import { Helmet } from 'react-helmet';
import { Constants } from '../../../Constants';
import appLevelApi from '../../App/api'
import api from './api'
import { Content } from './content'
import {Modal,Button} from 'react-bootstrap'
import accountApi from '../../Account/api'
import Loader from 'react-loader-spinner'
import ImageMap from "image-map";
import imageMapResize from 'image-map-resizer'
import { ExhibitionHallsLoaded } from './actions'
import { getFloordata } from '../Mapfloor/actions'
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { GAPageView, GARecordEvent } from '../../../Utility/GA';
import  PermiumUserModal  from "../../modals/premiumUserModal";
export class VirtualExhibition extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading : false,
            loadingErrorMessage : '',
            selectedHallIndex : 0,
            virtualZoneItemIndex : this.props.virtualMenuItems.findIndex(x=>x.path == '/virtual_exhibition'),
            // expoHalls : [{
            //     id : 1,
            //     templateImage : '/images/exhibitors/exhibition@2x.png',
            //     mapArea : '<area id="exhibitor1" title="Visit Company Booth" class="maparea" coords="122,517,617,822" shape="rect"/><area id="exhibitor2" title="Visit Company Booth" class="maparea" coords="854,515,1277,807" shape="rect"/>',
            //     exhibitors : [{
            //         bothId : 1,
            //         title : 'MWAN',
            //         templateImage : '/images/exhibitors/Booth1.png',
            //         mapArea : '<area id="branding_broucher" title="Open PDF" coords="262,266,401,619" class="maparea" shape="rect"/><area id="branding2" title="Open Branding 2" class="maparea" coords="1483,268,1644,611" shape="rect"/><area id="branding_video" title="Open Video" class="maparea" coords="920,212,1176,358" shape="rect"/><area id="branding1" title="Open Branding 1" class="maparea" coords="834,441,1214,607" shape="rect"/>',
            //         brandings : [{
            //             id: "brochure Id",
            //             title: "This is brochure branding",
            //             type: "brochure",
            //             link: [
            //                 {
            //                     id: 22,
            //                     thumbnail: "This is thumbnail",
            //                     title: "link title",
            //                     link: "https://eventmanagement.mwancloud.com/company/150/events/40/exibition/16046653605fa5401045ead.pdf"
            //                 }
            //             ]
            //         },
            //         {
            //             id: "video Id",
            //             title: "This is video branding",
            //             type: "video_link",
            //             link : [{
            //                 link : 'https://www.youtube.com/watch?v=3-Cg58wuBC8',
            //                 thumbnail : '',
            //                 title : 'Practical and clinical aspects of telehealth for health professionals, 27th April 2020'
            //             },
            //             {
            //                 link : 'https://player.vimeo.com/video/76979871',
            //                 thumbnail : '',
            //                 title : 'Bello Chao'
            //             }]
            //         },{
            //             id: "branding Id",
            //             title: "This is branding",
            //             type: "branding",
            //             link: [
            //                 {
            //                     id: 23,
            //                     thumbnail: "This is thumbnail",
            //                     title: "link title",
            //                     link: "www.google.com"
            //                 },
            //                 {
            //                     id: 26,
            //                     thumbnail: "This is thumbnail",
            //                     title: "link title",
            //                     link: "www.facebook.com"
            //                 }
            //             ]
            //         }]
            //     },{
            //         bothId : 2,
            //         templateImage : '/images/exhibitors/Booth1.png',
            //         brandings : [{
            //             id : 111,
            //             title : 'MWAN',
            //             link : '',
            //             type : ''
            //         }]
            //     },
            //     {
            //         bothId : 3,
            //         templateImage : '/images/exhibitors/Booth1.png',
            //         brandings : [{
            //             id : 111,
            //             title : 'MWAN',
            //             link : '',
            //             type : ''
            //         }]
            //     },
            //     {
            //         bothId : 4,
            //         templateImage : '/images/exhibitors/Booth1.png',
            //         brandings : [{
            //             id : 111,
            //             title : 'MWAN',
            //             link : '',
            //             type : ''
            //         }]
            //     },
            //     {
            //         bothId : 5,
            //         templateImage : '/images/exhibitors/Booth1.png',
            //         brandings : [{
            //             id : 111,
            //             title : 'MWAN',
            //             link : '',
            //             type : ''
            //         }]
            //     },
            //     {
            //         bothId : 6,
            //         templateImage : '/images/exhibitors/Booth1.png',
            //         brandings : [{
            //             id : 111,
            //             title : 'MWAN',
            //             link : '',
            //             type : ''
            //         }]
            //     }]
            // }],
            expoHalls : [],
            selectedBooth : {},
            selectedBranding : {},
            selectedVideoIndex : 0,
            showContent : true,
            loadingTemplateImage : true,
            showTootlTip : false,
            tooltipText : '',
            selectedHallmage : '', // used in case where selected hall index is -1
            selectedHall:-1
        }
        this.expoHallMapAreas = {
            1 : ['122,517,617,822','854,515,1277,807','1564,508,1997,807','2142,561,2640,863','477,841,1064,1250','1651,880,2246,1296']
        }
        this.boothMapAreas = {
            1 : ['262,266,401,619','1483,268,1644,611','920,212,1176,358']
        }
        this.changingHall = false
    }
    async componentDidMount () {
        document.body.style.overflowY = "scroll"
        this.props.getFloordata(this.props.event.alias)
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=virtual_exhibition')
        fetch(`/${this.props.event.alias}/virtual_exhibition`)
        this._isMounted = true
        this.user = utility.getLoggedInUser(this.props.event.alias)
        if(this.user == null){
            this.OnNavigateToPage('/')
            return
        }else{
            if(!this.user.isBooking){
                this.OnNavigateToPage('/')
                return
            }
        }
        if(this.props.halls != null){
            this.setState({expoHalls : this.props.halls},()=>{
                this.hallsDataLoaded()
                let data = this.props.location.state
                if(data != null){
                    this.setState({selectedHallIndex : -1,selectedHallmage : this.props.halls[data.hallIndex].templateImage},()=>{
                        this.OnClickNext(data.hallIndex - 1,0)
                    })
                }
                window.history.replaceState(null, '')
            })
        }else
        {
            this.setState({loading : true})
            api.getExhibitors(this.props.event.alias,this.user.token,(err,halls)=>{
                if(err == null){
                    this.setState({expoHalls : halls},()=>{
                        this.hallsDataLoaded()
                    })
                }else{
                    this.setState({expoHalls : [],loadingErrorMessage : err.message})
                }
                this.setState({loading : false})
            })
            // this.hallsDataLoaded()
        }
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
    }
    hallsDataLoaded = () => {
        let expoHalls = this.state.expoHalls
        let hallId = this.props.match.params.id
        if(hallId){
            let index = expoHalls.findIndex(x=>x.id == hallId)
            if(index > -1){
                this.setState({selectedHallIndex: index})
                if(index == this.state.expoHalls.length - 1){
                    $('#nxt-imgs').addClass('rightslide'); 
                }
            }
        }
        this.allExhibitors = []
        for(var i=0;i<this.state.expoHalls.length;i++){
            this.allExhibitors = [...this.state.expoHalls[i].exhibitors,...this.allExhibitors]
        }
        for(var i=0;i<expoHalls.length;i++){
            for(var j=0;j<expoHalls[i].exhibitors.length;j++){
                if(expoHalls[i].exhibitors[j].length == 0 ){
                    break
                }
                let videoBranding = expoHalls[i].exhibitors[j].brandings.find(x=>x.type == "video_link")
                let simpleBrandings = expoHalls[i].exhibitors[j].brandings.find(x=>x.type == "branding")
                if(videoBranding != null){
                    for(var k=0;k<videoBranding.link.length;k++){
                        let url = videoBranding.link[k].link
                        if(url.indexOf('youtu') > -1){
                            let id = utility.getYoutubeVideoId(url)
                            videoBranding.link[k].link = "https://www.youtube.com/embed/"+id
                        }
                    }
                }
                if(simpleBrandings != null){
                    for(var l=0;l<simpleBrandings.link.length;l++){
                        let url = simpleBrandings.link[l].link
                        if(url != 'jobFair'){
                            if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
                                simpleBrandings.link[l].link = "http://" + url;
                            }
                        }
                    }
                }
            }
        }
        this.setState({expoHalls},()=>{
            if(expoHalls.length == 1){
                $('#nxt-imgs').addClass('rightslide');
            }
            if(this.state.selectedHallIndex > 0){
                $('#back-imgs').addClass('leftslide');
            }
            this.props.onHallsLoaded(expoHalls)
            if(expoHalls.length == 0){
                this.setState({loadingErrorMessage : 'Exhibition not configured'})
                return
            }
            for(var i=0;i<expoHalls.length;i++){
                if(i != this.state.selectedHallIndex){
                    $('#hall'+i).fadeOut(0)
                }
            }
            if(expoHalls.length > 0){
                this.AddClickListeners(0)
            }
            setTimeout(() => {
                imageMapResize()
            }, 0);
            
        })
    }
    componentWillUnmount(){
        // $('.tooltips').remove();
        this._isMounted = false
    }
    AddClickListeners = (index) => {
        let firstBrandingIndex = this.state.expoHalls[index].exhibitors.findIndex(x=>x.type != "default_exhibitor")
        for(var j=1;j<=this.state.expoHalls[index].exhibitors.length;j++){
            let branding = document.getElementById("branding"+j)
            if(branding != null){
                branding.addEventListener('click',(e)=>{
                    let index = parseInt(e.target.id.replace('branding',''))
                    index -= 1
                    this.OnClickBooth(firstBrandingIndex+index)
                })
                // branding.onmouseenter = (e) => {
                //     let tooltipText = branding.getAttribute('title')
                //     branding.removeAttribute('title')
                //     this.setState({showTootlTip : true,tooltipText})
                // }
                // branding.onmouseleave = () => {
                //     branding.setAttribute('title',this.state.tooltipText)
                //     this.setState({showTootlTip : false})
                // }
            }
            let elem = document.getElementById("exhibitor"+j)
            if(elem != null){
                elem.addEventListener('click',(e)=>{
                    let index = parseInt(e.target.id.replace('exhibitor',''))
                    index -= 1
                    this.OnClickBooth(index)
                })
                // elem.onmouseenter = (e) => {
                //     let tooltipText = elem.getAttribute('title')
                //     elem.removeAttribute('title')
                //     this.setState({showTootlTip : true,tooltipText})
                // }
                // elem.onmouseleave = () => {
                //     elem.setAttribute('title',this.state.tooltipText)
                //     this.setState({showTootlTip : false})
                // }
            }
        }
    }
    AddToTipToArea = () =>{
        $(document).ready(function() {
			// Tooltip only Text
			// $('.maparea').hover(function(){
            //         // Hover over code
            //         setTimeout(() => {
            //             var title = $(this).attr('title');
            //             $(this).data('tipText', title).removeAttr('title');
            //             $('<p class="tooltips"></p>')
            //             .text(title)
            //             .appendTo('body')
            //             .fadeIn('slow');
            //         }, 0);
			// }, function() {
            //         // Hover out code
            //         setTimeout(() => {
            //             $(this).attr('title', $(this).data('tipText'));
            //             $('.tooltips').remove();
            //         },0)
			// }).mousemove(function(e) {
			// 		var mousex = e.pageX + 20; //Get X coordinates
			// 		var mousey = e.pageY - 5; //Get Y coordinates
			// 		$('.tooltips')
			// 		.css({ top: mousey, left: mousex })
			// });
		});
    }
    OnNavigateToPage = (path,data = null) =>{
        if(path == this.props.event.alias+"/virtual_exhibition"){
            this.setState({selectedHallIndex : 0},()=>{
                if(this.state.expoHalls.length == 1){
                    $('#nxt-imgs').addClass('rightslide');
                }
                $('#back-imgs').removeClass('leftslide');
            })
        }
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            this.props.history.push(path)
        }else{
            this.props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    OnClickBooth = (index) => {
        let exhibitors = this.state.expoHalls[this.state.selectedHallIndex].exhibitors
        if(index >= exhibitors.length){
            return
        }
        let selectedBooth = exhibitors[index]
        if(selectedBooth.type == 'none'){
            return
        }
        if(selectedBooth.type == 'custom_link'){
            GARecordEvent("brandingClicked", this.user.id,{
                category : 'Virtual Exhibition',
                action : 'Branding clicked '+selectedBooth.externalLink,
                user : 'By user: '+this.user.email,
                booth: selectedBooth.title ? selectedBooth.title : "not available"
            })
            window.open(selectedBooth.externalLink,"_blank")
            return
        }
        if(selectedBooth.type == 'exhibitor_link'){
            let exhibitorIndex = this.allExhibitors.findIndex(x=>x.bothId == selectedBooth.externalLink)
            if(exhibitorIndex < 0 ){
                var hasNoInfo = true
            }else{
                selectedBooth = this.allExhibitors[exhibitorIndex]
            }
        }
        if(!hasNoInfo){
            if(selectedBooth.brandings == null){
                return
            }
        }
        this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition/booth/"+selectedBooth.bothId,{booth : selectedBooth,hallIndex : this.state.selectedHallIndex})
    }
    OnClickBrandingItem = (branding) => {
        this.setState({selectedBranding : branding})
        if(branding.type == 'broucher'){
            $('#Brochure-modal').modal('show')
        }
        if(branding.type == 'video'){
            $('#video-modal-exhb').modal('show')
        }
    }
    OnClickNext = (defaultIndex = -1,duration = 500) =>{
        let index = 0
        if(defaultIndex > -1){
            index = defaultIndex
            if(this.defaultIndex == 0){
                index = -1
            }
        }else{
            index = this.state.selectedHallIndex
        }
        if(this.changingHall){
            return
        }
        this.changingHall = true
        $('#nxt-imgs').addClass('rightslide'); 
        setTimeout(() => {
            if(index + 1 < this.state.expoHalls.length - 1){
                $('#nxt-imgs').removeClass('rightslide'); 
            }
        }, 500);
        $('#hall'+index).fadeOut(duration);
        $('#hall'+(index+1)).fadeIn(duration);
        setTimeout(() => {
            this.setState({selectedHallIndex : index + 1,loadingTemplateImage : true},()=>{
                let url = new URL(window.location.href);
                if(url.href.indexOf('hall') > -1){

                    let previousId = this.state.expoHalls[index].id
                    url.href = url.href.replace(previousId,this.state.expoHalls[this.state.selectedHallIndex].id)
                    console.log(url.href)
                }else{
                    url += '/hall/'+this.state.expoHalls[this.state.selectedHallIndex].id
                }
                window.history.replaceState(null, null, url);
                setTimeout(() => {
                    if(this.state.selectedHallIndex > 0){
                        $('#back-imgs').addClass('leftslide');
                    }
                }, 0);
                setTimeout(() => {
                    imageMapResize()
                }, 0);
                this.AddClickListeners(this.state.selectedHallIndex)
            })
            this.changingHall = false
        }, duration);
    }
    OnVisitHall = (defaultIndex = -1,id = 0,duration = 500) =>{
        this.setState({selectedHall:defaultIndex})
        let index = 0
        if(defaultIndex > -1){
            index = defaultIndex
            if(this.defaultIndex == 0){
                index = -1
            }
        }else{
            index = this.state.selectedHallIndex
        }
        if(this.changingHall){
            return
        }
        this.changingHall = true
        $('#nxt-imgs').addClass('rightslide'); 
        setTimeout(() => {
            if(index  < this.state.expoHalls.length - 1){
                $('#nxt-imgs').removeClass('rightslide'); 
            }
        }, 500);
        $('#hall'+index).fadeOut(duration);
        $('#hall'+(index)).fadeIn(duration);
        setTimeout(() => {
            this.setState({selectedHallIndex : defaultIndex,loadingTemplateImage : true},()=>{
                let url = new URL(window.location.href);
                if(url.href.indexOf('hall') > -1){
                     let currentUrl = url.href.split('/')
                     currentUrl[currentUrl.length-1] = id;
                    let previousId = this.state.expoHalls[index].id
                    console.log(previousId)
                    console.log(url.href)
                    url.href = currentUrl.join('/')
                    console.log(url.href)
                }else{
                    url += '/hall/'+this.state.expoHalls[this.state.selectedHallIndex].id
                }
                window.history.replaceState(null, null, url);
                setTimeout(() => {
                    if(this.state.selectedHallIndex > 0){
                        $('#back-imgs').addClass('leftslide');
                    }
                }, 0);
                setTimeout(() => {
                    imageMapResize()
                }, 0);
                this.AddClickListeners(this.state.selectedHallIndex)
            })
            this.changingHall = false
        }, duration);
    }

    OnClickBack = () => {
        let index = this.state.selectedHallIndex
        if(this.changingHall){
            return
        }
        this.changingHall = true
        $('#back-imgs').removeClass('leftslide');
        $('#hall'+index).fadeOut(500);
        $('#hall'+(index-1)).fadeIn(500);
        setTimeout(() => {
            this.setState({selectedHallIndex : index-1,loadingTemplateImage : true},()=>{
                let url = new URL(window.location.href);
                if(url.href.indexOf('hall') > -1){
                    let previousId = this.state.expoHalls[index].id
                    url.href = url.href.replace(previousId,this.state.expoHalls[this.state.selectedHallIndex].id)
                }else{
                    url += '/hall/'+this.state.expoHalls[this.state.selectedHallIndex].id
                }
                window.history.replaceState(null, null, url);
                if(index - 1 > 0){
                    $('#back-imgs').addClass('leftslide');
                }
                // setTimeout(() => {
                //     $('#nxt-imgs').css('right',20);
                // }, 0);
                $('#nxt-imgs').removeClass('rightslide');
                setTimeout(() => {
                    imageMapResize()
                }, 0);
                this.AddClickListeners(this.state.selectedHallIndex)
                this.AddToTipToArea()
            })
            this.changingHall = false
        }, 500);
    }
    languageClick = (value) =>{
        //console.log("Trigger virtual exibition on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    handleClosePopup = () => {
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
        this.OnNavigateToPage(this.props.event.alias+"/login")
    }
    render () {
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Exhibition</title>
            </Helmet>
            <PermiumUserModal
        id="premiumSession"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        onClose = {()=>{
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
        }}
        keyboard= {false}
        showButton={true}
        onClickBookNow={() => {
        this.moveTolOgin()
          $("#premiumSession").modal("hide");
         $(".modal-backdrop").remove();
          this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
            <div id="header-top">
            <Header
                onMenuItemClick = {this.OnNavigateToPage}  
                elements = {this.props.headerMenuItems}
                facebookLink = {this.props.event.facebook}
                linkedInLink = {this.props.event.linkedIn}
                twitterLink = {this.props.event.twitter}
                instagramLink = {this.props.event.instagram}
                isMarketplace = {this.props.event.is_marketplace}
                googleLink = {this.props.event.google}
                phone = {this.props.event.phone_number}
                eventLogo = {this.props.event.logo}
                 event = {this.props.event}
                eventName = {this.props.event.name}
                eventAlias = {this.props.event.alias}
                onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                availableLocales = {this.props.availableLocales}
                languageClick  = {this.languageClick}
                onClickAccountItem = {this.OnClickAccountItem}
            />
            {user != null && user.isBooking && Constants.showVirtualZone ?
            <VirtualZone
                selectedItemIndex = {this.state.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
                event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }}
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                menuItems = {this.props.virtualMenuItems}
                user = {user} 
                showHomeButton = {false}
            /> : null}
            </div>
            {this.state.showContent ?
            <Content
                mapfloordata = {this.props.mapfloordata}
                selectedHall = {this.state.selectedHall}
                loadingTemplateImage = {this.state.loadingTemplateImage}
                loading = {this.state.loading}
                alias = {this.props.event.alias}
                event = {this.props.event}
                loadingError = {this.state.loadingErrorMessage}
                halls = {this.state.expoHalls}
                exaibitor = {this.state.expoHalls[0]}
                expoHallMapAreas = {this.expoHallMapAreas}
                boothMapAreas = {this.boothMapAreas}
                selectedHallIndex = {this.state.selectedHallIndex}
                selectedBranding = {this.state.selectedBranding}
                selectedBooth = {this.state.selectedBooth}
                selectedHallmage = {this.state.selectedHallmage}
                onClickNext = {this.OnClickNext}
                OnVisitHall = {this.OnVisitHall}
                onClickBack = {this.OnClickBack}
                onClickBooth = {this.OnClickBooth}
                onClickBrandingItem = {this.OnClickBrandingItem}
                OnNavigateToPage = { this.OnNavigateToPage}
                selectedVideoIndex = {this.state.selectedVideoIndex}
                onClickChangeVideo = {(index)=> this.setState({selectedVideoIndex : index})}
                templateImageLoaded = {()=>{
                    this.setState({loadingTemplateImage : false})
                }}
            />:null}
            {/* <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showErrorPopup} 
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}
            {/* <MouseTooltip
                visible={this.state.showTootlTip}
                offsetX={15}
                offsetY={10}
                className = "tooltips"
            >
                <span>{this.state.tooltipText}</span>
            </MouseTooltip> */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    halls : state.virtualExhibition.halls,
    mapfloordata : state.mapFloorReducer.mapfloordata

})
const mapDispatchToProps = dispatch => ({
    onHallsLoaded : data => dispatch(ExhibitionHallsLoaded(data)),
    getFloordata: (alias) => dispatch(getFloordata(alias)),
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(VirtualExhibition);