import React from "react";
import api from "../../Agenda/api";
import { Helmet } from "react-helmet";
import Header from "../../Header/Index";
import VirtualZone from "../../Header/virtualZone";
import utility from '../../../Utility/utility';
import { Constants } from '../../../Constants';
import {withRouter} from 'react-router';
import { connect } from 'react-redux'
import {compose} from 'redux'
import Content from './content'
import { getBlogsData } from "./action";

class Blogs extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            blogs:[],
            loading:false,
            title:'',
            // selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/blogs") > -1)
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/blogs") > -1),
            renderComponents : true,
            selectedSubmenuIndex : -1,
        }

        this.user = utility.getLoggedInUser(this.props.event.alias)
        this.virtualZoneItemIndex = this.props.virtualMenuItems.findIndex(x=>x.path == '/blogs')
    }


  componentDidMount(){
 if(this.props.Blogsdata.length == 0 ){
    this.getBlogs()
 }
 else{
    this.setState({blogs:[...this.props.Blogsdata],title:this.props.title})
 }
    
  }

  componentWillReceiveProps(nextProps){
      if(this.props.Blogsdata != nextProps.Blogsdata){
      this.setState({blogs:[...nextProps.Blogsdata],title:nextProps.title})
      }
  }

  getBlogs = ()=>{
    this.setState({loading:true})
    api.getEventFeatures(this.props.event.alias, "Blog", null, (err, data) => {
        if(data){
        this.props.getBlogsData({features:data.feature.features,title:data.feature.title})
        // debugger
        this.setState({blogs:[...data.feature.features],title:data.feature.title,loading:false})
        }
      });
  }
  OnClickAccountItem = (index,path) =>{
    this.props.onClickAccountItem(index,path)
    if(index == null)
        return
    if(index > -1){
        this.props.history.replace('')
        this.props.history.push({
            pathname : this.props.event.alias+'/account',
            state : {tabIndex : index}
        })
    }
    else{
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
        window.location.reload()
    }
}
  OnNavigateToPage = (path,data = null) =>{
    window.scrollTo(0,0)
    if(path.indexOf('home') > -1){
        path = "/"
    }else if(path != '/'){
        path = "/"+path
    }
    if(data == null){
        this.props.history.push(path)
    }else{
        this.props.history.push({
            pathname : path,
            state : data
        })
    }
}
render(){
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
      </Helmet>
      <div id="header-top">
        <Header
          onMenuItemClick = {this.OnNavigateToPage}
          elements={this.props.headerMenuItems}
          facebookLink={this.props.event.facebook}
          linkedInLink={this.props.event.linkedIn}
          twitterLink={this.props.event.twitter}
          isMarketplace={this.props.event.is_marketplace}
          instagramLink={this.props.event.instagram}
          googleLink={this.props.event.google}
          phone={this.props.event.phone_number}
          eventLogo={this.props.event.logo}
          event={this.props.event}
          selectedIndex = {this.state.selectedIndex}
          selectedSubIndex = {this.state.selectedSubmenuIndex}
          eventName={this.props.event.name}
          eventAlias={this.props.event.alias}
          onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
          onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
          availableLocales={this.props.availableLocales}
          // languageClick  = {this.languageClick}
          onClickAccountItem = {this.OnClickAccountItem}
        />
      </div>
      {this.user != null && this.user.isBooking && Constants.showVirtualZone?
            <VirtualZone
                selectedItemIndex = {this.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
                event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    this.setState({selectedIndex : -1})
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }} 
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                menuItems = {this.props.virtualMenuItems}
                user = {this.user} 
            /> : null}
            <Content
            blogs = {this.state.blogs}
            loading = {this.state.loading}
            event = {this.props.event}
            title={this.state.title}
            
            />
    </>
  );
}
};

const mapStateToProps = state =>({
    Blogsdata : state.sepratePageBlog.blogdata,
    title:state.sepratePageBlog.pageTitle
})
const mapDispatchToProps = dispatch => ({
    getBlogsData : data => dispatch(getBlogsData(data)) 
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Blogs);
