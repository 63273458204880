import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
// import zoom from '../../zoom-us/index.js'
import io from 'socket.io-client'
import utility,{wordsRestrictor} from '../../Utility/utility';
import {Modal,Button, Overlay} from 'react-bootstrap'
import api from '../AgendaTime/api'
import componentApi from './api'
import YouTube from 'react-youtube';
import {compose} from 'redux';
import { Constants } from '../../Constants/index.js';
import Linkify from 'react-linkify';
import {isChrome, isMobile} from 'react-device-detect'
import Loader from 'react-loader-spinner'
import overlay from '../../Utility/overlay'
import devTools from 'devtools-detect'
import ReactTooltip from 'react-tooltip';
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import Marquee from "react-fast-marquee";
import {Trans} from 'react-i18next'
import { MDBCollapse } from "mdbreact";
import  PermiumUserModal  from '../modals/premiumUserModal';
import './styles.css'
import { withTranslation } from 'react-i18next';
// import ReactPlayer from 'react-player'
export class session extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selectedSpeaker : {},
            selectedTabIndex : isMobile ? -1 : 0,
            showSessionDisconnectPopup : false,
            chat : props.savedAnnouncements,
            questions : props.savedQuestions,
            unReadChatCount : "",
            unReadQuestionsCount : "",
            unReadGroupChatCount : props.unReadGroupChatCount,
            qMessage : "",
            sessionPassedTimePercent: 0,
            overAllSessionPassedTimePercent : 0,
            currentPassedTimePercent : 0,
            streamEnded : false,
            groupChat : props.groupChat,
            groupChatMessage : '',
            replyingToMessageId : '',
            replyChatMessage : {},
            showFullSpeakerDescription : false,
            showFullAttendeeBio:false,
            showNextSessionNotification: false,
            nextSessionTime: -1,
            sessionId: null,
            collapseID: "collapsibleNavbar0",
            selectedPollsFilter: 0,
            polls: props.polls,
            pollQuestion : [],
            polls_data :[],
            selectedAnswerId :[],
            selected_options:[],
            collapseid:[],
            collapseId:null,
            loader:false,
            loader_id:null,
            readPoll:false,
            numberOfNewPoll:0,
            optionToSubmitedQuestion : [],
            filterTarget:'0',
            readPoll:true,
            type:'',
            loadingGroupChat: props.loadingSessionGroupChat,
            loadingQuestions: props.loadingSessionQuestions,
            loadingAnnouncements: props.loadingSessionAnnouncements,
            loadingPolls: props.loadingSessionPolls,
            realTime:true,
            attendeeName:'',
            attendeePhoto:'',
            attendeeCompanyName:'',
            attendeeJobTitle:"",
            attendeeprofileBio:'',
            activeTab:0,
            newPoll:false,
            firstPoll:false,
            errorMessageHeading:"",
            errorMessageJoin:"",
            msgCount:0,
            ProgresssMessage :'',
            videoId: props.videoId,
            multiLangualSessoins: props.multiLangualSessoins,
            selectedVideoLocale: props.selectedVideoLocale,
            availableVideoLocales: [],
            vimeoUrl: props.streamLink
        }
        this.agendaIndex = -1
        this.sessionIndex = -1
        this.zoomChatButtonClicked = false
        this.zoomQAButtonClicked = false
        this.user = null
        this.unReadChatCount = 0
        this.unReadQuestionsCount = 0
        this.saveTimeAgenda = 0
        this.eventCertificationCompletionTime = props.certificateCompletionTime
        this.sessionCertificateCompletionTime = ((props.sessionDuration/60)*100)/100
        this.currentCertificateCompletionTime = props.certificateCompletionTime
        this.sessionPassedTimeInSec = this.props.savedSessionTime
        this.overAllSessionsWatchedTimeInSec = props.overAllSessionsWatchedTime
        this.sessionWatched = props.overAllSessionsWatchedTime
        this.devTootInterval = null
        this.lastQuestionId = this.props.lastQuestionId
        this.youtubePlayer = null
        this.tempMessageData = []
        this.tempMessageDataForTimer = []
        this.secondsPassedAfterFirstMessage = 0
        this.timer = null
        this._isMounted = false
        this.certificateText = "Request For Certificate, If you have attended the event for certain time duration"
        this.videoTimerSecs = 0
        this.fullScreenMode = false
        this.inspectOpenCheckInterval = null
        this.saveSessionTime = true
        this.sessionEndTimer = null
        this.nextSessionRemainingTimeInterval = null
        this.sessionId = null
        this.videoPaused = false
        this.collapse = null
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+this.props.eventAlias) || 'en')
    }
    onBubbleRemove=()=>{
        this.setState({msgCount:0})
        this.props.clearCurrentAgendadata()
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.certificateSave == true ){
            this.SaveUserSessionAttendedTime()
            nextProps.cancelSaveCertificate()
        }
        let countNumber = localStorage.getItem(`${nextProps.eventAlias}${nextProps.agendaId}${nextProps.sessionId}${nextProps.subSessionId}${this.props.user.id}`)
        if(nextProps.pollQuestion != this.props.pollQuestion){
                this.setState({pollQuestion:[...nextProps.pollQuestion],polls_data:[...nextProps.pollQuestion],loadingPolls: false,newPoll:true},()=>{
                    this.filterPoll(this.state.filterTarget,'next')
                })

                if(this.state.selectedTabIndex != 5){
                    this.setState({numberOfNewPoll:this.state.numberOfNewPoll + Math.abs((nextProps.pollQuestion || []).length  -  this.props.pollQuestion.length)})
                }
            }
        if(this.sessionWatched != nextProps.overAllSessionsWatchedTime){
            this.sessionWatched = nextProps.overAllSessionsWatchedTime
        }
        if(nextProps.saveUserSession == true){
            this.SaveUserSessionAttendedTime()
            this.props.saveSession()
        }
        if(countNumber == null){
            if(this.selectedTabIndex != 1){
            if(nextProps.groupChat.length != this.state.groupChat.length){
                this.setState({msgCount:nextProps.groupChat.length})
            }
        }
        }else{
            if(this.selectedTabIndex != 1){
                if(nextProps.groupChat.length != this.state.groupChat.length){
                    this.setState({msgCount:nextProps.groupChat.length - parseInt(countNumber)})
                }
            }
        }
        if(nextProps.savedAnnouncements != this.state.savedAnnouncements){
            this.setState({chat: nextProps.savedAnnouncements,loadingAnnouncements: false})
        }
        if(nextProps.savedQuestions != this.state.savedQuestions){
            this.setState({questions: nextProps.savedQuestions,loadingQuestions: false})
        }
        if(nextProps.unReadGroupChatCount != this.state.unReadGroupChatCount && this.state.unReadGroupChatCount < this.props.unReadGroupChatCount){
            if(this.selectedTabIndex != 1){
            if((countNumber != null) && (countNumber != "") && (nextProps.unReadGroupChatCount != "")){
                this.setState({unReadGroupChatCount:parseInt(nextProps.unReadGroupChatCount) - parseInt(countNumber)})
            }else{
            this.setState({unReadGroupChatCount: nextProps.unReadGroupChatCount})
            }
        }

        }
        if(nextProps.groupChat != this.state.groupChat){
            this.setState({groupChat: nextProps.groupChat,loadingGroupChat: false})
        }
        if(nextProps.multiLangualSessoins){
            let availableVideoLocales = ['en']
            availableVideoLocales = availableVideoLocales.concat(nextProps.multiLangualSessoins.map(x=>x.locale))
            this.setState({availableVideoLocales})
            if(this.props.selectedVideoLocale != "en"){
                this.handleOnChangeVideo(this.props.selectedVideoLocale,false)
            }
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.sessionId != this.props.sessionId){
            this.initializeSession()
        }
    }
    initializeSession(){
        this.setState({chat: this.props.savedAnnouncements,questions: this.props.savedQuestions,
            unReadGroupChatCount: this.props.unReadGroupChatCount,groupChat:this.props.groupChat,
        },
        )
        let countNumber = localStorage.getItem(`${this.props.eventAlias}${this.props.agendaId}${this.props.sessionId}${this.props.subSessionId}${this.props.user.id}`)
        if(countNumber == null){
        if(this.props.groupChat.length != this.state.groupChat.length){
            this.setState({msgCount:this.props.groupChat.length})
        }
    }else{
        if(this.props.groupChat.length != this.state.groupChat.length){
            this.setState({msgCount:this.props.groupChat.length - parseInt(countNumber)})
        }
    }

        this.unReadChatCount = 0
        this.unReadQuestionsCount = 0
        this.eventCertificationCompletionTime = this.props.certificateCompletionTime
        this.sessionCertificateCompletionTime = Math.round((this.props.sessionDuration/60)*100)/100
        this.currentCertificateCompletionTime = this.props.certificateCompletionTime
        this.sessionPassedTimeInSec = this.props.savedSessionTime
        this.overAllSessionsWatchedTimeInSec = this.props.overAllSessionsWatchedTime
        this.sessionWatched = this.props.overAllSessionsWatchedTime
        this.lastQuestionId = this.props.lastQuestionId
        this.tempMessageData = []
        this.tempMessageDataForTimer = []
        this.secondsPassedAfterFirstMessage = 0
        this.certificateText = "Request For Certificate, If you have attended the event for certain time duration"
        this.videoTimerSecs = 0
        this.fullScreenMode = false
        this.saveSessionTime = true
        this.sessionEndTimer = null
        this.sessionTimeCounter = null
        this.nextSessionRemainingTimeInterval = null
        this.sessionId = null
        if(this.props.streamType == 1 && this.props.nextSession && this.props.nextSession.isBreak != "1"){
            let offSetTime = 5   // 5 minutes before session end show new session notification
            let currentTime = new Date()
            let timeZoneSplit = this.props.defaultTimeZone.split(":")
            let timeZoneHour = parseInt(timeZoneSplit[0])
            let selectedTimeZone = parseFloat(timeZoneHour + "." + timeZoneSplit[1])

            let localTimeOffSet = currentTime.getTimezoneOffset() / 60
            localTimeOffSet *= -1
            let timeOffset = Math.abs(localTimeOffSet - selectedTimeZone)
            if(localTimeOffSet < selectedTimeZone){
                timeOffset = -timeOffset
            }
            let sessionEndTime
            if(localTimeOffSet != selectedTimeZone){
                sessionEndTime = utility.calculateZoneSpecificTime(this.props.sessionEndTime,timeOffset)
            }else{
                sessionEndTime = new Date(this.props.sessionEndTime)
            }
            if(currentTime.getDate() == sessionEndTime.getDate()){
                let timeDifference = Date.parse(sessionEndTime) - Date.parse(currentTime)
                let offset = timeDifference - (offSetTime * 60000)
                while(offset < 0 && timeDifference > 0){
                    offSetTime -= 1
                    offset = timeDifference - (offSetTime * 60000)
                }
                if(timeDifference < 0){
                    offSetTime = 0
                }
                this.sessionEndTimer = setTimeout(() => {
                    this.setState({showNextSessionNotification : true,nextSessionTime: offSetTime})
                    localStorage.removeItem("streamTypeForNextSession")
                    if(offSetTime > 0){
                        this.nextSessionRemainingTimeInterval = setInterval(() => {
                            if(this.state.nextSessionTime > 0){
                                this.setState({nextSessionTime: this.state.nextSessionTime - 1})
                            }
                        }, 60000);
                    }
                }, offset);
            }
        }
        this.agendaIndex = localStorage.getItem("agendaIndex")
        let sessionDetails = localStorage.getItem("sessionDetails")
        this.sessionIndex = -1
        this.subSessionIndex = -1
        this.sessionCertificatedRequestEnabled = 0
        if(sessionDetails != null){
            sessionDetails = JSON.parse(sessionDetails)
            this.sessionIndex = sessionDetails.sessionIndex
            this.subSessionIndex = sessionDetails.subSessionIndex
            this.sessionCertificatedRequestEnabled = sessionDetails.certificateEnabled == 1 ? true : false
            if(this.sessionCertificatedRequestEnabled){
                this.currentCertificateCompletionTime = this.sessionCertificateCompletionTime
                this.certificateText = "Request For Certificate, If you have attended the session for certain time duration"
            }
        }
        this.eventCertificationCompletionTimeSecs = this.eventCertificationCompletionTime * 3600
        this.sessionCertificateCompleteTimeInSecs = this.sessionCertificateCompletionTime * 3600
        if(this.sessionPassedTimeInSec > 0){
            // this.calculateSessionTimePassedPercent(this.sessionCertificatedRequestEnabled)
            // this.calculatePercentage = setInterval(() => {
                this.calculatePercentage(this.sessionCertificatedRequestEnabled)
            // }, 1000);
        }
        if(this.overAllSessionsWatchedTimeInSec > 0){
            // this.calculateEventWatchedTimePassedPercent(this.sessionCertificatedRequestEnabled)
            this.calculatePercentage(this.sessionCertificatedRequestEnabled)
        }
        setTimeout(() => {
            if(this.state.currentPassedTimePercent >= 100){
                this.saveSessionTime = false
            } 
        }, 0);
        if(this.subSessionIndex == null){
            this.subSessionIndex = -1
        }
		localStorage.removeItem("agendaIndex")
        localStorage.removeItem("agendaId")
        localStorage.removeItem("streamType")
        localStorage.removeItem("sessionDetails")
        // if(this.props.streamType == 1){
        //     // this.sessionCertificateInterval = setInterval(()=>this.calculateSessionTimePassedPercent(this.sessionCertificatedRequestEnabled),1000)
        //     // this.eventCertificateInterval = setInterval(()=>this.calculateEventWatchedTimePassedPercent(this.sessionCertificatedRequestEnabled),1000)
        //     this.calculatePercentageprogress = setInterval(() => {
        //         this.calculatePercentage(this.sessionCertificatedRequestEnabled)
        //     }, 1000);
        // }
        if(this.props.isVimeo == 1){
            var iframe = document.querySelector('iframe');
            this.vimeoPlayer = new window.Vimeo.Player(iframe);
            setInterval(() => {
                this.vimeoPlayer.getCurrentTime().then((secs)=> {
                    let overAllSeconds = Math.round(secs)
                    let minutes = Math.round(overAllSeconds / 60)
                    
                    let currentSeconds = overAllSeconds - (minutes*60)
                    this.timeString = minutes+"m"+currentSeconds+"s"
                })
            }, 500);
            this.vimeoPlayer.on('pause',(data)=>{
                this.videoPaused = true
            })
            this.vimeoPlayer.on('play',(data)=>{
                this.videoPaused = false
            })
            this.vimeoPlayer.on('ended', (data)=> {
                this.handleOnStreamEnded()
            });
        }
    }
    handleKeyDown = (e) => {
        if(e.keyCode == 123) {
            return false;
         }
         if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
            return false;
         }
         if(e.metaKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
            return false;
         }
         if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
            return false;
         }
         if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
            return false;
         }
    }
    handleRightClick = (event) => {
        if(Constants.contentBlocked){
            event.preventDefault()
        }
    }
    onUnload = (e) =>{
        // console.log('unloaded')
        if(this.props.streamType == 1 && !this.state.streamEnded){
            this.SaveUserSessionAttendedTime()
            e.preventDefault()
            var message = 'Are you sure to leave the session in the middle ?';
            e.returnValue = message;
            return message;
        }
    }
    componentDidMount(){
        this.setState({pollQuestion:[...this.props.pollQuestion],polls_data:[...this.props.pollQuestion]},(state)=>{
            // this.setState({collapseId:this.props.pollQuestion[0].id})
            this.filterPoll(0)

        })
        this.initializeSession()
        document.getElementById("groupChatInput").addEventListener("keypress", this.OnSubmitGroupChatTextArea);
        document.getElementById("questionInput").addEventListener("keypress", this.OnSubmitQuestion);
        if(!isChrome && devTools.isOpen){
            if(process.env.REACT_APP_ENV_NAME != "local" && process.env.REACT_APP_ENV_NAME != "development" && Constants.contentBlocked){
                this.OnClickBack(false)
                return
            }
        }
        let navbar = document.getElementById("stickyMenu")
        if(navbar && navbar.classList.contains('sticky')){
            navbar.classList.remove("sticky");
        }
        // if it has sticky class then definetly will have stickyMenuBG you separate check just for sanity check for future
        if(navbar && navbar.classList.contains('stickyMenuBG')){
            navbar.classList.remove("stickyMenuBG")
        }
        this._isMounted = true
        
        window.addEventListener("beforeunload", this.onUnload)
        if(window.outerWidth > 767) {
			$(document).ready(function(){
				var height = $(window).height();
				$('.group-chat').css('min-height', (height - 131) + 'px');
			});

			$(document).ready(function(){
                var height = $(window).height();
                $('.video-frame').css('height', (height - 262) + 'px');
			});

        }
        window.addEventListener('devtoolschange',(e)=>{
            if(!isChrome && e.detail.isOpen && Constants.contentBlocked && process.env.REACT_APP_ENV_NAME != "local" && process.env.REACT_APP_ENV_NAME != "development"){
                this.OnClickBack(false)
            }
        })
        window.addEventListener('resize',function () {
            $(document).ready(function(){
                var height = $(window).height();
                $('.video-frame').css('height', (height - 262) + 'px');
			});
        })
		// var triggerb = document.getElementById("dismiss-details");
		// var topdiv = document.getElementById("spkr-det");

		// triggerb.addEventListener('click', function() {
		// 	if ($('#spkr-det').css('display') == 'block') {
		// 	$(topdiv).slideUp();
		//   } else {
		// 	$(topdiv).slideDown();
		//   }
        // });
        $(document).on('submit', '#form-inline', function(event){
            event.preventDefault();
        });
        document.addEventListener('keydown',this.handleKeyDown)
        document.addEventListener('contextmenu',this.handleRightClick)
        this.user = utility.getLoggedInUser(this.props.eventAlias)
        let userName = this.user.firstName + " "+ this.user.lastName
        let webinarId = this.props.webinarId;
        // if(this.props.streamType != 1) // below code is executed only in case if this is a live stream
        //     return
        
        this.socket = this.props.socket
        if(this.socket == null){
            let socketURL =  Constants.socketUrl
            socketURL += "?userId="+this.user.id+"&userName="+(this.user.firstName+" "+this.user.lastName)+"&eventAlias="+this.props.eventAlias
            this.socket = io.connect( Constants.socketUrl,{forceNew : true,upgrade : false,transports: ['websocket']})
        }
        this.socket.on('updatePoll',async(data)=>{
            if(data.session_id == this.props.sessionId &&
                 data.parallel_session_id == this.props.subSessionId &&
                  this.props.agendaId == data.agenda_id){
                    var startTime = performance.now();
                    console.log(data.submitedQuestionData)
                    console.log(this.state.polls_data)
                    var startTime = performance.now();   
                    console.log(this.state.polls_data)  
                    const selectedQuestion = this.state.polls_data.find(item=>item.id == data.question_id)
                    console.log(selectedQuestion)
                    let optionUpdated =  selectedQuestion.options.map(option=>{
                        if(data.option_id.includes(option.id)){
                            return {...option,totalAnswer:option.totalAnswer+1}
                        }
                        else
                        {
                            return {...option}
                        }
                    })
                    var updatePoll = {...selectedQuestion,options:[...optionUpdated],totalCount:selectedQuestion.totalCount+data.submitedQuestionData.length}
                    var endTime = performance.now();
                    console.log(endTime-startTime)
                    let index = this.state.polls_data.findIndex(item=>item.id == data.question_id)
                    let filterPoll = this.state.polls_data.filter(item=>item.id != data.question_id)
                    filterPoll.splice(index,0,updatePoll)
                    console.log(filterPoll)
                    if(this.state.filterTarget == 0){
                    this.setState({pollQuestion:[...filterPoll],polls_data:[...filterPoll]},()=>{
                    this.filterPoll(this.state.filterTarget,'socket')
                    //this.collapse = [updatePoll,...filterPoll][0].id
                    this.collapse = this.collapse
            })}else{
                this.setState({polls_data:[...filterPoll]},()=>{
                    this.filterPoll(this.state.filterTarget,'socket')
                })
                
            }
        }})
        this.socket.on("channel1",(data)=>{
            if(!this._isMounted){
                return
            }
            if(data.eventType == "liveStreamCheck"){
                if(this.user.id == data.userId && this.user.token != data.userToken){
                    this.setState({showSessionDisconnectPopup : true,errorMessageHeading : 'Error',errorMessageJoin : 'Another user is using your screen to view live stream only one screen is allowed for one user at a time'})
                    $("#sessionExpire").modal('show')
                    this.setState({streamEnded: true})
                    setTimeout(() => {
                        $("#sessionExpire").modal('hide')
                        this.setState({showSessionDisconnectPopup : false})
                        // zoom.endMeeting()
                        this.OnClickBack()
                        localStorage.removeItem(Constants.API_TOKEN+this.props.eventAlias+'user_info')
                        window.location.reload()
                    }, 5000);
                }else{
                    
                }
            }
        })
        this.socket.emit("channel1",{
            eventType : 'liveStreamCheck',
            userId : this.user.id,
            userToken : this.user.token
        })
        this.socket.on("chatChannel",(item)=>{ // announcments
            if(!this._isMounted){
                return
            }
            if(item.eventAlias == this.props.eventAlias && item.agendaId == this.props.agendaId && item.sessionId == this.props.sessionId && item.subSessionId == this.props.subSessionId){
                var chat = this.state.chat
                let index = chat.findIndex(x=>x.id == item.id)
                if(index > -1){
                    if(item.deleted){
                        chat.splice(index,1)
                    }else{
                        chat[index] = item
                    }
                    this.setState({chat})
                    return
                }    
                if(item.userId == this.user.id){
                    item.me = true
                }else{
                    item.me = false
                }
                let date = utility.calculateZoneSpecificTime(item.time)
                let hours = date.getHours()
                let minutes = date.getMinutes()
                if(minutes < 10){
                    minutes = "0"+minutes
                }
                let ampm = hours >= 12 ? "PM" : "AM"
                if(hours > 12){
                    hours = hours - 12
                }
                item.timeString = hours + ":" + minutes + " "+ampm
                chat.push(item)
                if(this.state.selectedTabIndex != 3){
                    this.unReadChatCount += 1
                    this.setState({unReadChatCount : this.unReadChatCount.toString()})
                }
                this.setState({chat},()=>{
                    // var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                    // var text1=item.message.replace(exp, "<a target='_blank' href='$1'>$1</a>");
                    // var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
                    // document.getElementById("chatMessage"+(chat.length - 1)).innerHTML = text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
                    let chatTabPane = document.getElementById("tab-pane-chat")
                    chatTabPane.scrollTop = chatTabPane.scrollHeight;
                })
            }
        })
        this.socket.on("QAChannel",(item)=>{
            if(!this._isMounted){
                return
            }
            if(item.eventAlias == this.props.eventAlias && item.agendaIndex == this.agendaIndex && item.sessionIndex == this.sessionIndex && item.subSessionIndex == this.subSessionIndex){
                var questions = this.state.questions
                let index = questions.findIndex(x=>x.id == item.id)
                if(index < 0){
                    this.lastQuestionId += 1
                }
                if(item.approved){
                    if(index > -1){
                        item.upVoteCount.showChecked = item.upVoteCount.by.findIndex(x=>x == this.user.id) > -1 ? true : false
                        questions[index] = item
                    }
                    else{
                        questions.push(item)
                    }
                    if(this.state.selectedTabIndex != 2){
                        this.unReadQuestionsCount += 1
                        this.setState({unReadQuestionsCount : this.unReadQuestionsCount.toString()})
                    }
                    questions.sort((a,b)=>{
                        return new Date(a.time) - new Date(b.time)
                    })
                    questions.sort((a,b)=>{
                        return b.upVoteCount - a.upVoteCount
                    })
                    this.setState({questions})
                    setTimeout(() => {
                        let questionTabPane = document.getElementById("tab-pane-question")
                        questionTabPane.scrollTop = questionTabPane.scrollHeight;
                    }, 0);
                }
                if(item.rejected){
                    if(index > -1){
                        questions.splice(index,1)
                        this.setState({questions})
                        setTimeout(() => {
                            let questionTabPane = document.getElementById("tab-pane-question")
                            questionTabPane.scrollTop = questionTabPane.scrollHeight;
                        }, 0);
                    }
                }
            }
        })
        this.socket.on('groupChatMessageReceived',(message)=>{
            if(!this._isMounted){
                return
            }
            if(message.eventAlias == this.props.eventAlias && message.agendaId == this.props.agendaId && message.sessionId == this.props.sessionId && message.subSessionId == this.props.subSessionId){
                var groupChat = this.state.groupChat
                if(message.id != null || message.isDelete == 1 || message.likeCountUpdated){
                    let index = -1
                    if(message.isReply == 1){
                        index = groupChat.findIndex(x=>x.messageId == message.parentId)
                        let childIndex = groupChat[index].replies.findIndex(x=>x.messageId == message.messageId)
                        if(message.isDelete == 1){
                            groupChat[index].replies[childIndex].isDelete = 1
                        }else if(message.likeCountUpdated){
                            groupChat[index].replies[childIndex] = message
                        }
                        else{
                            groupChat[index].replies[childIndex].id = message.id
                        }
                    }else{
                        index = groupChat.findIndex(x=>x.messageId == message.messageId)
                        if(message.isDelete == 1){
                            groupChat[index].isDelete = 1
                        }else if(message.likeCountUpdated){
                            groupChat[index] = message
                        }else{
                            groupChat[index].id = message.id
                        }
                    }
                    this.setState({groupChat},()=>{
                        let groupChatTab = document.getElementById("tab-pane-groupChat")
                        groupChatTab.scrollTop = groupChatTab.scrollHeight;
                    })
                    return
                }
                this.tempMessageDataForTimer.push(message)
                if(this.timer == null){
                    this.timer = setInterval(this.GroupChatTimerFunction, 15000);
                }
                if(this.state.selectedTabIndex != 1){

                    let unReadGroupChatCount = this.state.unReadGroupChatCount
                    let numberOfMsg = 0
                    if(unReadGroupChatCount == ""){
                        unReadGroupChatCount = 1
                    }else{
                        unReadGroupChatCount = parseInt(unReadGroupChatCount) + 1
                    }
                    unReadGroupChatCount = unReadGroupChatCount.toString()
                    this.setState({unReadGroupChatCount})

                    if(this.state.msgCount == 0){
                        this.setState({msgCount:1})
                    }
                    else{
                        this.setState({msgCount:this.state.msgCount+1})
                    }
                }
                message.me = false
                if(message.isReply == 0){
                    groupChat.push(message)
                }else{
                    var index = groupChat.findIndex(x=>x.messageId == message.parentId)
                    groupChat[index].replies.push(message)
                }
                this.setState({groupChat})

                if(this.state.selectedTabIndex == 1){
                    let messages = localStorage.getItem(`${this.props.eventAlias}${this.props.agendaId}${this.props.sessionId}${this.props.subSessionId}${this.props.user.id}`)
                    if(messages != null && messages != ""){
                    localStorage.setItem(`${this.props.eventAlias}${this.props.agendaId}${this.props.sessionId}${this.props.subSessionId}${this.props.user.id}`,parseInt(messages)+1)
                    }
                    else{
                        // if(this.props.unReadGroupChatCount != ""){
                        localStorage.setItem(`${this.props.eventAlias}${this.props.agendaId}${this.props.sessionId}${this.props.subSessionId}${this.props.user.id}`,this.state.groupChat.length) 
                        // }
                    }
                    }
                }
        })
        if(this.props.streamType == 1){
        //     this.sessionCertificateInterval = setInterval(()=>this.calculateSessionTimePassedPercent(this.sessionCertificatedRequestEnabled),1000)
        //     this.eventCertificateInterval = setInterval(()=>this.calculateEventWatchedTimePassedPercent(this.sessionCertificatedRequestEnabled),1000)
            this.calculatePercentageprogress = setInterval(() => {
                this.calculatePercentage(this.sessionCertificatedRequestEnabled)
            }, 1000);
        }
        if(isChrome){
            var element = new Image;
            var devtoolsOpen = false;
            element.__defineGetter__("id", ()=>{
                devtoolsOpen = true; // This only executes when devtools is open.
                if(this.fullScreenMode){
                    overlay.hide()
                }
                this.OnClickBack(false)
            });
            if(process.env.REACT_APP_ENV_NAME != "local" && Constants.contentBlocked){
                this.inspectOpenCheckInterval = setInterval(function() {
                    devtoolsOpen = false;
                }, 1000);
            }
        }
    }
    OnSubmitGroupChatTextArea = (event) => {
        if(event.which === 13 && !event.shiftKey){
            // event.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
            this.OnClickSendGroupMessage()
            event.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
        }
    }
    OnSubmitReplyChatTextArea = (event) => {
        if(event.which === 13 && !event.shiftKey){
            this.OnSubmitReply(this.parentMessageId)
            event.preventDefault();
        }
    }
    OnSubmitQuestion = (event) => {
        if(event.which === 13 && !event.shiftKey){
            // event.target.form.dispatchEvent(new Event("submit", {cancelable: true}));
            this.OnClickSendQuestion()
            event.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
        }
    }
    componentWillUnmount(){
        this._isMounted = false
        // if(this.props.streamType == 1){
        //     zoom.leaveMeeting()
        // }
        console.log(this.props.streamType)
        if(this.props.streamType == 1){
            this.SaveUserSessionAttendedTime()
        }
        clearInterval(this.devTootInterval)
        clearTimeout(this.sessionEndTimer)
        clearInterval(this.calculatePercentageprogress)
        this.setState({msgCount:0})
        
        document.removeEventListener('keydown',this.handleKeyDown)
        document.removeEventListener('contextmenu',this.handleRightClick)
        window.removeEventListener("beforeunload", this.onUnload)
        clearInterval(this.sessionCertificateInterval)
        if(!this.videoPaused && !this.state.streamEnded){
            let link = ""
            if(this.props.isVimeo == 0){
                if(this.youtubePlayer != null){
                    link = this.props.streamLink.replace("i_d",this.state.videoId)+`&start=${Math.round(this.youtubePlayer.getCurrentTime())}`
                }
            }else{
                link = this.state.vimeoUrl+'#t='+this.timeString
            }
            this.props.onClickBackToAgendaView(link)
        }
    }
    calculateSessionTimePassedPercent = (sessionTimerEnabled = false) => {
        if(Math.floor(this.sessionWatched/60) > this.props.eventCertificateCompletion ){
            this.setState({currentPassedTimePercent : 100})
        }else{
        this.sessionPassedTimeInSec = this.sessionPassedTimeInSec + 1
        this.sessionWatched = this.sessionWatched + 1
        let completionPercent = ((this.sessionWatched/60) / this.props.eventCertificateCompletion) * 100
        completionPercent = Math.round(completionPercent*100)/100
        this.setState({sessionPassedTimePercent : completionPercent})
        if(sessionTimerEnabled){
            this.setState({currentPassedTimePercent : completionPercent})
        }
        if(completionPercent >= 100){
            this.setState({currentPassedTimePercent : 100})
            clearInterval(this.sessionCertificateInterval)
        }
    }
    }
    calculatePercentage = (sessionTimerEnabled = false)=>{
        if(Math.floor(this.sessionWatched/60) >this.props.eventCertificateCompletion ){
            this.setState({currentPassedTimePercent : 100})
        }
        else if(this.saveTimeAgenda >= 100){
            // this.setState({currentPassedTimePercent : 100})
            this.setState({currentPassedTimePercent : 100})
            clearInterval(this.calculatePercentage)
        } 
        else{
        this.sessionPassedTimeInSec = this.sessionPassedTimeInSec + 1
        this.sessionWatched = this.sessionWatched + 1
        let completionPercent = ((this.sessionWatched/60) / this.props.eventCertificateCompletion) * 100
        completionPercent = Math.round(completionPercent*100)/100
        this.saveTimeAgenda = completionPercent
        this.setState({sessionPassedTimePercent : completionPercent})
        if(sessionTimerEnabled){
            this.setState({currentPassedTimePercent : completionPercent})
        }
        if(completionPercent >= 100){
            // this.SaveUserSessionAttendedTime()
            this.setState({currentPassedTimePercent : 100})
            clearInterval(this.calculatePercentage)
        }
    }
    }
    calculateEventWatchedTimePassedPercent = (sessionTimerEnabled = false) => {
        this.overAllSessionsWatchedTimeInSec = this.overAllSessionsWatchedTimeInSec + 1
        let completionPercent = (this.overAllSessionsWatchedTimeInSec / this.props.eventCertificateCompletion) * 100
        completionPercent = Math.round(completionPercent*100)/100
        this.setState({overAllSessionPassedTimePercent : completionPercent})
        if(!sessionTimerEnabled){
            // this.setState({currentPassedTimePercent : completionPercent})
        }
        if(completionPercent >= 100){
            // this.setState({currentPassedTimePercent : 100})
            clearInterval(this.eventCertificateInterval)
        }
    }
    OnClickBio = (index) => {
        this.setState({selectedSpeaker : this.props.speakers[index]},()=>{
            // var trigger = document.getElementById("bio"+index);
            // var topdiv = document.getElementById("spkr-det");
            // if ($('#spkr-det').css('display') == 'none') {
            //     $(topdiv).slideDown();
            // } else {
            //     $(topdiv).slideUp();
            // }
            $("#spk-modal").modal('show');
        })
    }
    showDialog = (id,type,replies=[],senderId = 0)=>{
        if(type=="message"){
            console.log("I am Message")
            let filterUser  = this.state.groupChat.filter(item=>item.id == id)[0]
            if(this.props.user.id != filterUser.senderId){
                this.setState({attendeeName:filterUser.senderName,
                    attendeePhoto:filterUser.profileImageUrl,
                    attendeeJobTitle:filterUser.jobTitle,
                    attendeeCompanyName:filterUser.companyName,
                    attendeeprofileBio:filterUser.profileBio
                })
             $("#spk-modal-chat").modal('show');
            }
        }
        else{
            let filterReply  = replies.filter(item=>item.id == id)[0]
            console.log(filterReply)
            if(this.props.user.id != filterReply.senderId){
                this.setState({attendeeName:filterReply.senderName,
                    attendeePhoto:filterReply.profileImageUrl,
                    attendeeJobTitle:filterReply.jobTitle,
                    attendeeCompanyName:filterReply.companyName,
                    attendeeprofileBio:filterReply.profileBio
                })
                console.log(this.state.attendeeprofileBio)
             $("#spk-modal-chat").modal('show');
            }
        }
        
        
    }
    OnClickTab = (index) => {
        if(index == 5){
            this.setState({readPoll:false})
        }
        else{
            this.setState({readPoll:true})
        }
    
        let elem = null
        if(index == 1){
            this.setState({unReadGroupChatCount : ""})
            elem = document.getElementById("tab-pane-groupChat")
        }else if(index == 2){
            this.unReadQuestionsCount = 0
            this.setState({unReadQuestionsCount : ""})
            elem = document.getElementById("tab-pane-question")
        }else if(index == 3){
            this.unReadChatCount = 0
            this.setState({unReadChatCount : ""})
            elem = document.getElementById("tab-pane-chat")
        }
        if(elem != null){
            elem.scrollTop = elem.scrollHeight
        }
        // var topdiv = document.getElementById("spkr-det");
        // topdiv.style.display = "none"
        this.setState({selectedTabIndex : index},()=>{
            if(index == 1){
                let textArea = document.getElementById("groupChatInput")
                textArea.focus()
            }
        })
    }
    OnClickBack = (saveSessionTime = true) => {
        if(this.socket != null){
            this.socket.disconnect()
        }
        this.props.onClickBackToAgendaView()
        if(this.props.streamType == 1 && saveSessionTime){
            this.SaveUserSessionAttendedTime()
        }
        if(this.inspectOpenCheckInterval != null){
            clearInterval(this.inspectOpenCheckInterval)
        }
        if(this.timer != null){
            clearInterval(this.timer)
        }
    }
    OnClickReloadPage = () => {
        localStorage.setItem("agendaIndex",this.agendaIndex)
        localStorage.setItem("sessionIndex",this.sessionIndex)
        localStorage.setItem("subSessionIndex",this.subSessionIndex)
        localStorage.getItem("streamType",this.props.streamType)
        window.location.reload()
    }
    OnClickSendChatMessage = (message) => {
        var chats = this.state.chat
        let chat = {
            id : chats.length + 1,
            agendaIndex : this.agendaIndex,
            sessionIndex : this.sessionIndex,
            eventAlias : this.props.eventAlias,
            userId : this.user.id,
            message : message,
            time : Date.now()
        }
        this.socket.emit("chatChannel",chat)
    }
    OnClickSendQuestion = () => {
        if(this.state.qMessage == ""){
            return
        }
        var questions = this.state.questions
        let date = new Date() // for local client
        let hours = date.getHours()
        let minutes = date.getMinutes()
        if(minutes < 10){
            minutes = "0"+minutes
        }
        let ampm = hours >= 12 ? "PM" : "AM"
        if(hours > 12){
            hours = hours - 12
        }
        let timeString = hours + ":" + minutes + " "+ampm
        let question = {
            id : this.lastQuestionId + 1,
            agendaIndex : this.agendaIndex,
            sessionIndex : this.sessionIndex,
            subSessionIndex : this.subSessionIndex,
            agendaId : this.props.agendaId,
            sessionId : this.props.sessionId,
            subSessionId : this.props.subSessionId,
            eventAlias : this.props.eventAlias,
            message : this.state.qMessage,
            userId : this.user.id,
            senderName : this.user.firstName + " "+this.user.lastName,
            approved : false,
            upVoteCount : {
                count : 0,
                by : []
            },
            timeString : timeString,
            time : date // for server and multiple clients
        }
        this.lastQuestionId += 1
        questions.push(question)
        setTimeout(() => {
            let questionTabPane = document.getElementById("tab-pane-question")
            questionTabPane.scrollTop = questionTabPane.scrollHeight;
        }, 0);
        if(this.props.streamType == 1){
            this.socket.emit("QAChannel",question)
        }
        this.setState({qMessage : ""})
        this.SaveQuestionBackEnd(question)
    }
    OnClickUpdateQuestion = (index) => {
        var questions = this.state.questions
        let question = this.state.questions[index]
        if(question.upVoteCount.by.findIndex(x=>x == this.user.id) < 0){
            question.upVoteCount.count += 1
            question.upVoteCount.showChecked = true
            question.upVoteCount.by.push(this.user.id)
            this.setState({questions})
            if(this.props.streamType == 1){
                this.socket.emit("QAChannel",this.state.questions[index])
            }
            this.UpdateQuestionBackEnd(question)
        }
    }
    UpdateQuestionBackEnd = (question) => {
        var qDataToUpdate = {
            event_id : this.props.eventAlias,
            event_agenda_id : this.props.agendaId,
            event_session_id : this.props.sessionId,
            event_sub_session_id : this.props.subSessionId,
            question_id : question.responseId,
            is_approved : question.approved ? 1 : 0,
            votes_count : question.upVoteCount.count,
            user_id : question.upVoteCount.by.length > 0 ? question.upVoteCount.by[question.upVoteCount.by.length - 1] : null
        }
        api.saveUpdateQuestion(this.props.eventAlias,false,qDataToUpdate,(err,data)=>{
            if(data != null){
                
            }
        })
    }
    SaveQuestionBackEnd = (question) => {
        var qDataToSave = {
            temp_id : question.id,
            event_id : this.props.eventAlias,
            event_agenda_id : this.props.agendaId,
            event_session_id : this.props.sessionId,
            event_sub_session_id : this.props.subSessionId,
            question : question.message,
            name : question.senderName,
            user_id : question.userId,
            votes_count : question.upVoteCount.count
        }
        api.saveUpdateQuestion(this.props.eventAlias,true,qDataToSave,(err,data)=>{
            if(data != null){
                let questions = this.state.questions
                let questionIndex = questions.findIndex(x=>x.id == data.temp_id)
                questions[questionIndex].responseId = data.question.id
                this.setState({questions : questions})
                if(this.props.streamType == 1){
                    this.socket.emit("QAChannel",questions[questionIndex])
                }
            }
        })
    }
    handleChange = (event) => {
        this.setState({qMessage : event.target.value})
    }
    handleOnStreamEnded = (event) => {
        this.setState({streamEnded : true})
        if(this.props.streamType == 1){
            clearInterval(this.sessionCertificateInterval)
            clearInterval(this.eventCertificateInterval)
        }
    }
    onReady = (event) => {
        this.youtubePlayer = event.target;
        if(isMobile){
            setTimeout(() => {
                this.youtubePlayer.playVideo()
            }, 500);
        }else{
            this.youtubePlayer.playVideo()
        }
        
    };
    onVideoPaused = () => {
        this.videoPaused = true
        if(!this.fullScreenMode){
            clearInterval(this.videoTimerInterval)
        }
    }
    onVideoResumed = () => {
        this.videoPaused = false
        if(!this.fullScreenMode){
            this.videoTimerInterval = setInterval(this.videoPlayTimer, 1000);
        }
    }
    SaveUserSessionAttendedTime = () => {
        // if(!this.saveSessionTime){
        //     return
        // }
        let sessionrecordTime = Math.floor(Math.abs(this.props.savedSessionTime - this.sessionPassedTimeInSec))
        var data = {
            event_id : this.props.eventAlias,
            event_agenda_id : this.props.agendaId,
            event_session_id : this.props.subSessionId == 0 ? this.props.sessionId : null,
            event_parallel_session_id : this.props.subSessionId == 0 ? null : this.props.subSessionId,
            user_id : this.user.id,
            time_secs : sessionrecordTime + this.props.savedSessionTime
            // time_secs:0
        }
        // api.saveUserSessionTime(this.props.eventAlias,data,(err,data)=>{

        // })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'apiToken': Constants.API_TOKEN },
            body: JSON.stringify(data)
        };
        fetch(process.env.REACT_APP_API_ENDPOINT+"certificates/save", requestOptions)
    }
    // Group Chat Methods
    handleChangeChatInput = (e) => {
        this.setState({groupChatMessage : e.target.value})
    }
    handleChangeReplyInput = (e,messageId) => {
        this.autosize(e.target)
        var replyChatMessage = this.state.replyChatMessage
        replyChatMessage[messageId] = e.target.value
        this.setState({replyChatMessage})
    }
    OnClickSendGroupMessage = () =>{
        if(this.state.groupChatMessage.trim() == ""){
            return
        }
        var message = {
            messageId : Math.random().toString(36).substr(2, 9),
            message : this.state.groupChatMessage,
            agendaId : this.props.agendaId,
            sessionId : this.props.sessionId,
            subSessionId : this.props.subSessionId,
            parentId : 0,
            isReply : 0
        }
        this.SaveGroupChatMessage(message)
        message.senderName = this.user.firstName + " " + this.user.lastName
        message.eventAlias = this.props.eventAlias
        message.me = true
        message.date = new Date()
        message.timeString = "Now"
        message.seconds = 0
        message.likeCount = 0
        message.likedBy = []
        message.senderId = this.user.id
        message.profileImageUrl = this.user.profileImageUrl
        message.companyName = this.props.user.companyName
        message.jobTitle = this.props.user.jobTitle
        message.profileBio = this.props.user.profileBio

        this.tempMessageDataForTimer.push(message)
        if(this.timer == null){
            this.timer = setInterval(this.GroupChatTimerFunction, 15000);
        }
        message.type = 'group'
        message.replies = []
        // if(this.props.streamType == 1){
            this.socket.emit('messageSent',message)
        // }
        var groupChat = this.state.groupChat
        groupChat.push(message)
        this.setState({groupChat,groupChatMessage : ''},()=>{
            let groupChatTab = document.getElementById("tab-pane-groupChat")
            groupChatTab.scrollTop = groupChatTab.scrollHeight;
        })
    }
    GroupChatTimerFunction = () => {
        let groupChat = this.state.groupChat
        for(var i=0;i<this.tempMessageDataForTimer.length;i++){
            let index = -1
            let item = null
            if(this.tempMessageDataForTimer[i].isReply == 0){
                index = groupChat.findIndex(x=>x.messageId == this.tempMessageDataForTimer[i].messageId)
                item = groupChat[index]
            }else{
                index = groupChat.findIndex(x=>x.messageId == this.tempMessageDataForTimer[i].parentId)
                var childIndex = groupChat[index].replies.findIndex(x=>x.messageId == this.tempMessageDataForTimer[i].messageId)
                item = groupChat[index].replies[childIndex]
            }
            item.seconds += 15
            var minutesPassed = Math.floor(item.seconds / 60)
            var hoursPassed = Math.floor(item.seconds / 3600)
            let timeString = ""
            if(item.seconds < 60){
                timeString = item.seconds + " secs ago"
            }else if(minutesPassed < 60){
                timeString = minutesPassed + " mins ago"
            }else{
                timeString = hoursPassed + " hrs ago"
            }
            item.timeString = timeString
            if(this.tempMessageDataForTimer[i].isReply == 0){
                groupChat[index] = item
            }else{
                groupChat[index].replies[childIndex] = item
            }
        }
        this.setState({groupChat})
    }
    OnClickReply = (messageId) => {
        this.setState({replyingToMessageId : messageId},()=>{
            // let groupChatTab = document.getElementById("tab-pane-groupChat")
            // groupChatTab.scrollTop = groupChatTab.scrollHeight;
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#replyInput").offset().top
            }, 2000);
        })
        this.parentMessageId = messageId
    }
    OnSubmitReply = (parentMessageId,dbId) => {
        if(this.state.replyChatMessage[parentMessageId] == null || this.state.replyChatMessage[parentMessageId] == ""){
            return
        }
        console.log(this.props.user)
        let message = this.state.groupChat.find(x=>x.messageId == parentMessageId)
        let replyMessage = {
            message : this.state.replyChatMessage[parentMessageId],
            messageId : Math.random().toString(36).substr(2, 9),
            parentId : parentMessageId,
            isReply : 1,
            agendaId : this.props.agendaId,
            sessionId : this.props.sessionId,
            subSessionId : this.props.subSessionId,
            jobTitle : this.props.user.jobTitle
        }
        this.SaveGroupChatMessage(replyMessage)
        replyMessage.senderName = this.user.firstName + " " + this.user.lastName
        replyMessage.date = new Date()
        replyMessage.me = true
        replyMessage.type = 'group'
        replyMessage.timeString = "Now"
        replyMessage.seconds = 0
        replyMessage.likeCount = 0
        replyMessage.likedBy = []
        replyMessage.senderId = this.user.id
        replyMessage.eventAlias = this.props.eventAlias
        replyMessage.profileImageUrl = this.props.user.profileImageUrl
        replyMessage.companyName = this.props.user.companyName
        replyMessage.jobTitle = this.props.user.jobTitle
        replyMessage.profileBio = this.props.user.profileBio
        this.tempMessageDataForTimer.push(replyMessage)
        if(this.timer == null){
            this.timer = setInterval(this.GroupChatTimerFunction, 15000);
        }
        // if(this.props.streamType == 1){
            this.socket.emit('messageSent',replyMessage)
        // }
        console.log(replyMessage)
        message.replies.push(replyMessage)
        var replyChatMessage = this.state.replyChatMessage
        replyChatMessage[parentMessageId] = ""
        this.setState({replyingToMessageId : '',replyChatMessage},()=>{
            // let groupChatTab = document.getElementById("tab-pane-groupChat")
            // groupChatTab.scrollTop = groupChatTab.scrollHeight;
        })
    }
    hasDecimal =  (num)=>{
        return !!(num % 1);
    }
    OnClickDeleteMessage = (message) => {
        let index = -1
        let groupChat = this.state.groupChat
        let updatedMessage = null
        if(message.isReply == 0){
            index = groupChat.findIndex(x=>x.messageId == message.messageId)
            groupChat[index].isDelete = 1
            updatedMessage = groupChat[index]
        }else{
            index = groupChat.findIndex(x=>x.messageId == message.parentId)
            let childIndex = groupChat[index].replies.findIndex(x=>x.messageId == message.messageId)
            groupChat[index].replies[childIndex].isDelete = 1
            updatedMessage = groupChat[index].replies(childIndex)
        }
        // if(this.props.streamType == 1){
            this.socket.emit("messageSent",updatedMessage)
        // }
        this.setState({groupChat})
        this.DeleteMessageFromDatabase(message.id)
    }
    OnClickLikeGroupMessage = (message) => {
        if(this.user.id == message.senderId){
            return
        }
        let index = -1
        let groupChat = this.state.groupChat
        let updatedMessage = null
        let incrementCount = true
        let indexOfLikedMessage = message.likedBy.indexOf(this.user.id)
        if(indexOfLikedMessage > -1){
            incrementCount = false
        }
        if(message.isReply == 0){
            index = groupChat.findIndex(x=>x.messageId == message.messageId)
            updatedMessage = groupChat[index]
        }else{
            index = groupChat.findIndex(x=>x.messageId == message.parentId)
            let childIndex = groupChat[index].replies.findIndex(x=>x.messageId == message.messageId)
            updatedMessage = groupChat[index].replies[childIndex]
        }
        if(incrementCount){
            updatedMessage.likeCount += 1
            updatedMessage.likedBy.push(this.user.id)
        }else{
            updatedMessage.likeCount -= 1
            updatedMessage.likedBy.splice(indexOfLikedMessage,1)
        }
        updatedMessage.likeCountUpdated = true
        // if(this.props.streamType == 1){
            this.socket.emit("messageSent",updatedMessage)
        // }
        this.setState({groupChat})
        this.UpdateLikeCountDatabase(message.id,updatedMessage.likeCount,message.isReply)
    }
    SaveGroupChatMessage = (message) => {
        this.tempMessageData.push(message)
        componentApi.saveGroupChatMessage(this.props.eventAlias,this.user.token,message,(err,messageId,dbID)=>{
            if(err == null){
                let index = this.tempMessageData.findIndex(x=>x.messageId == messageId)
                let message = null
                if(this.tempMessageData[index].isReply == 0){
                    message = this.state.groupChat.find(x=>x.messageId == this.tempMessageData[index].messageId)
                }else{
                    let pIndex = this.state.groupChat.findIndex(x=>x.messageId == this.tempMessageData[index].parentId)
                    let childIndex = this.state.groupChat[pIndex].replies.findIndex(x=>x.messageId == this.tempMessageData[index].messageId)
                    message = this.state.groupChat[pIndex].replies[childIndex]
                }
                message.id = dbID
                this.tempMessageData.splice(index,1)
                // if(this.props.streamType == 1){
                    this.socket.emit('messageSent',message)
                // }
            }else{
                if(err == -1){
                    this.sessionExpired = true
                    this.setState({showSessionDisconnectPopup : true,errorMessageHeading : 'Login Required',errorMessageHeading : 'Your session is expired please login again'})
                    $("#sessionExpire").modal('show')
                    localStorage.removeItem(Constants.API_TOKEN+this.props.eventAlias+'user_info')
                }
            }
        })
    }
    DeleteMessageFromDatabase = (messageId) => {
        var data = {
            messageId : messageId,
            agendaId : this.props.agendaId,
            sessionId : this.props.sessionId,
            subSessionId : this.props.subSessionId
        }
        componentApi.deleteGroupChatMessage(this.props.eventAlias,this.user.token,(err,message)=>{
            
        })
    }
    UpdateLikeCountDatabase = (messageId,count,isReply) => {
        var data = {
            messageId : messageId,
            likeCount : count,
            isReply : isReply
        }
        componentApi.updateLikeCount(this.props.eventAlias,this.user.token,data,(err,message)=>{
            
        })
    }
    handleClosePopup = () => {
        if(this.sessionExpired){
            this.setState({showErrorPopup : false})
            localStorage.removeItem(Constants.API_TOKEN+this.props.eventAlias+'user_info')
            this.props.NavigateToLogin()
        }else{
            this.OnClickBack(false)
        }
    }
    OnClickRequestCertificate = (type) => {
        if(!this.props.certificateRequestSent && this.state.currentPassedTimePercent < 100){
            this.SaveUserSessionAttendedTime()
            setTimeout(()=>{
                this.props.onClickRequestCertificate(null)
            },1000)
            
            return
        }else if(!this.props.certificateRequestSent && this.state.currentPassedTimePercent >= 100){
            var data = {
                event_id : this.props.eventAlias,
                type : this.sessionCertificatedRequestEnabled ? "session" : "event",
                event_session_id : this.props.sessionId,
                event_parallel_session_id : this.props.subSessionId == 0 ? null : this.props.subSessionId
            }
            this.SaveUserSessionAttendedTime()
            setTimeout(()=>{
                this.props.onClickRequestCertificate(data)
            },1000)
           

        }else{
            var data = {
                event_id : this.props.eventAlias,
                type : this.sessionCertificatedRequestEnabled ? "session" : "event",
                event_session_id : this.props.sessionId,
                event_parallel_session_id : this.props.subSessionId == 0 ? null : this.props.subSessionId
            }
            this.SaveUserSessionAttendedTime()
            setTimeout(()=>{
                this.props.onClickRequestCertificate(data)
            },1000)

        }
    }
    handleOnFullScreenClosed = (e) => {
        if(this.props.streamType == 2){
            this.youtubePlayer.seekTo(this.videoTimerSecs - 2)
        }
        this.youtubePlayer.playVideo()
        document.removeEventListener('overlayclosed',this.handleOnFullScreenClosed)
        setTimeout(() => {
            this.fullScreenMode = false  
        }, 500);
    }
    OnClickFullScreen = () => {
        let startQuery = ""
        if(this.props.streamType == 2){
            startQuery = "&start="+this.videoTimerSecs
        }
        overlay.show(`<iframe class=embed-responsive-item src=${this.props.streamLink+startQuery} allow="autoplay" frameborder=0 width=100% height=100%></iframe>`)
        this.fullScreenMode = true
        this.youtubePlayer.pauseVideo()
        document.addEventListener('overlayclosed',this.handleOnFullScreenClosed)
    }
    filterPoll = (e,id='')=>{
        console.log(this.collapse)
        let AnswerQuestion=[]
        let UnanswerdQuestion = []
        if(e == 1){  
            this.state.polls_data.map((item)=> {
                if(item.status=="2" && item.answer.length > 0 ){
                    AnswerQuestion.push(item)

                }
                else if(item.status=="2" && item.answer.length == 0 ){
                    AnswerQuestion.push(item)
                }

            })
            this.setState({pollQuestion:AnswerQuestion}) 
        }
        else if(e == 0){
            this.state.polls_data.map((item)=>{
                if(item.status=="1" && item.answer.length > 0 ){
                    UnanswerdQuestion.push(item)

                }
                else if(item.status=="1" && item.answer.length == 0 ){
                    UnanswerdQuestion.push(item)
                }   
            }) 
            if(UnanswerdQuestion.length>0){
                var activeAnswerPoll = UnanswerdQuestion.filter(item=>item.answer.length > 0)
                var activeUnanswerPoll = UnanswerdQuestion.filter(item=>item.answer.length == 0)  
              UnanswerdQuestion = [...activeUnanswerPoll,...activeAnswerPoll]
            } 

            console.log(UnanswerdQuestion)
            this.setState({pollQuestion:UnanswerdQuestion})
              
        }
        if(id != 'socket'){
        if(UnanswerdQuestion.length>0 || AnswerQuestion.length>0)
        if(e == 1){
          this.collapse = AnswerQuestion[0].id
        }
        else{
            this.collapse = UnanswerdQuestion[0].id
        }
    }
    console.log(this.collapse)
    }
    videoPlayTimer = () => {
        this.videoTimerSecs += 1
    }
    autosize = (el) => {
        setTimeout(function(){
            el.style.cssText = 'height:auto';
            // for box-sizing other than "content-box" use:
            el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
        },0);	
    }
    toggleCollapse = collapseID => {
        console.log("I am Calling")
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    collapseDropDown = (id)=>{
        if(this.collapse == id){
            this.collapse = null

        }else{
            this.collapse = id
     
        }
    }
    async handleOnSubmitPoll(question_id,type){
        const filterQuestion = this.state.polls_data.filter(item=>question_id == item.id)[0]
        var answerSelectedOption = []
        var submitedQuestionOption = []
        var optionIds = []

        filterQuestion.options.map(item=>{
            if(this.state.selectedAnswerId.includes(item.id)){
                answerSelectedOption.push(item.id)
            }
        })
       this.state.selected_options.map(answer =>{
                if(answerSelectedOption.includes(answer.optionId)){
                    submitedQuestionOption.push({id:answer.optionId,title:answer.title})
                    optionIds.push(answer.optionId)
                }
            })
        
        let selectFinalId = type== 1 ? [optionIds[0]]:optionIds
        let numberofAnswers = type ==1 ? [submitedQuestionOption[0]]:submitedQuestionOption
        
       const params = {
            poll_id:question_id,
            option_id: selectFinalId
        }
        await componentApi.savePollQuestion(this.props.eventAlias,this.props.user.token,params,async(err,data)=>{ 
            if(data){
                this.setState({loader:false,loader_id:null,realTime:false})
                console.log(data)
                var filterQuestion = this.state.polls_data.find(item=>item.id == question_id)
                var selectedoptions = filterQuestion.options.map(item=>{
                    if(selectFinalId.includes(item.id)){
                        return {...item,totalAnswer:item.totalAnswer+1}

                    }
                    else{
                        return item
                    }
                })
                var selectedAnswer = filterQuestion.options.filter(item=>selectFinalId.includes(item.id))

                console.log(selectedAnswer)
                let updatedPoll = this.state.polls_data.map(item=>{
                if(item.id == data[0].id){
                    return {...item,totalCount:item.totalCount + selectFinalId.length ,options:[...selectedoptions],answer:[...selectedAnswer]}
                }else{
                    return item
                }})
                this.setState({polls_data:[...updatedPoll],firstPoll:true},()=>{
                    this.filterPoll(this.state.filterTarget,'socket')
                    //this.collapse = this.collapse
                })

                console.log(this.state.polls_data)
                const params = {
                    session_id:this.props.sessionId,
                    parallel_session_id:this.props.subSessionId,
                    agenda_id:this.props.agendaId,
                    question_id:question_id,
                    option_id:selectFinalId,
                    userId :this.props.user.id,
                    submitedQuestionData:numberofAnswers,
        
                }
                this.socket.emit('pollUpdate',params)
                this.setState({selectedAnswerId:[],selected_options:[]})
            }
        })
    }
    handleOnChangeVideo = (locale,updateUrl = true) => {
        if(updateUrl){
            if(this.state.selectedVideoLocale == locale){
                return
            }
            let url = new URL(window.location.href.replace("locale="+this.state.selectedVideoLocale,"locale="+locale))
            window.history.replaceState(null, null, url);
        }
        let id = this.props.videoId
        let vimeoUrl = this.props.streamLink
        if(locale != "en"){
            let obj = this.props.multiLangualSessoins.find(x=>x.locale.toLowerCase() == locale)
            if(this.props.isVimeo == 0){
                id = obj.id
            }else{
                vimeoUrl = obj.link + "?&autoplay=1"
            }
        }
        localStorage.setItem("videoLocale",locale) // TODO: need a better way
        this.setState({videoId: id,vimeoUrl,selectedVideoLocale: locale})
        this.props.onLanguageChanged(locale)
    }
    render(){
        if(this.state.pollQuestion != undefined && this.state.pollQuestion.length>0 && this.state.newPoll== true){
            this.setState({newPoll:false})
                this.collapse = this.state.pollQuestion[0].id
        }
        if(this.state.firstPoll == true){
            this.collapse = this.state.pollQuestion[0].id
            this.setState({firstPoll:false})
        }
        let selectedSpeaker = this.state.selectedSpeaker
        const youtubeOptions = {
            height: '100%',
            width: '100%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              start : 0,
              autoplay: 1,
              rel:0,
              controls : 1,
              modestbranding : 1,
              showinfo : 0,
              autohide : 1,
              wmode : 'transparent'
            }
        };
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
        let mobileTabContentClass = this.state.selectedTabIndex == -1 ? "d-none" : ""
        let selectedSpeakerDescription = ""
        if(selectedSpeaker != null){
            selectedSpeakerDescription = utility.extractContent(selectedSpeaker.description)
        }
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              If you have attended the event for the required duration you will be eligible for a certificate
            </Tooltip>
        );

        const timeTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
             Switch Time
            </Tooltip>
        );
        return(<div>
            {/* <ReactTooltip
                id="main"
                effect="solid"
                place = "bottom"
                multiline = {true}
            /> */}
            <PermiumUserModal
        id="sessionExpire"
        iconClass="fa-lock"
        title='Login Required'
        description='Your session is expired please login again'
        buttonText="Ok"
        backdrop='static'
        keyboard= {false}
        showButton={true}
        onClose = {()=>{
            $("#sessionExpire").modal("hide");
            $(".modal-backdrop").remove();
        }}
        onClickBookNow={() => {
        this.handleClosePopup()
          $("#sessionExpire").modal("hide");
         $(".modal-backdrop").remove();
        //   this.OnNavigateToPage(`${this.props.event.alias}/login`);
        }}/>
            {this.props.loadingNextSession ?
            <div className="d-flex justify-content-center align-items-center" style={{width : '100%',height: window.innerHeight,background: '#191919bf',position:'absolute',top: 0,zIndex:999}}>
                <Loader
                    type = 'Oval'
                    color = "#fff"
                    height = {50}
                    width = {50}
                />
            </div> : null}
            <section class = "live_stream group-chat overflow-hidden position-relative">
                <div class="container-fluid " id = "live_stream">
                    <div class = "row">
                        <div class = "col-12 px-md-0" style={{padding:0}}>
                            <div class = "row live_screen mx-0">
                                <div id="video-area" class = "col-lg-7 col-xl-7 px-0 pl-md-0 bg-dark">
                                    {this.props.isVimeo == 0 ?
                                    <div class = "video-frame" style={{backgroundColor: 'black'}}>
                                        <div class="embed-responsive embed-responsive-16by9 video-frame">
                                        {this.state.streamEnded ? 
                                        <div class="text-center" style = {{width : '100%',position:'absolute',zIndex : 999,height : '100%',background : 'black',display : 'flex',justifyContent : 'center',alignItems : 'center',top : 0,color : '#fff'}}>{this.props.streamType == 1 ? "Live Session Stream has ended. The recorded video of the session will be uploaded and available soon" : "Video Ended"}</div> : null}
                                        {Constants.contentBlocked ?
                                        <>
                                            <div class="text-center" style = {{width : '100%',position:'absolute',zIndex : 999,height : isMobile ? '25%' : '89%',display : 'flex',justifyContent : 'center',alignItems : 'center',top : 0}}></div>
                                            <div onClick = {this.OnClickFullScreen} class="text-center" style = {{width : '7%',position:'absolute',zIndex : 999,height : '10%',bottom : 0,right : 5,cursor : 'pointer'}}></div>
                                        </>:null}
                                        <div id="video-frame" style = {{width : '100%',height : '100%',backgroundColor : 'black',pointerEvents : 'all' }}>
                                            <YouTube id="video-player" containerClassName="youtubeIframe" onReady = {this.onReady} onEnd={this.handleOnStreamEnded} onPause = {this.onVideoPaused} onPlay = {this.onVideoResumed} opts={youtubeOptions} videoId={this.state.videoId} /> 
                                            {/* <ReactPlayer config = {youtubeOptions}  url = {this.props.streamLink} onEnded={this.handleOnStreamEnded}/> */}
                                        </div> 
                                        </div>
                                    </div> :
                                    <div class = "video-frame">
                                        <div class="embed-responsive embed-responsive-16by9 video-frame">
                                            <iframe id="video-frame" src={this.state.vimeoUrl} width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>}
                                </div>
                                
                                <div class = "col-lg-5 col-xl-5 px-0">
                                    <div class = "tab-sides pr-md-0">
                                        <ul class = "nav nav-tabs nav-fill session_tab mt-2 mt-md-0 desktop-tab" role = "tablist">
                                            {this.props.speakers.length > 0 ? <li class = "nav-item">
                                                <a onClick = {()=>this.OnClickTab(0)} class={this.state.selectedTabIndex == 0 ? "nav-link active" : "nav-link"} id="speaker" data-toggle="tab" href="#spk_tab">
                                                    <p class = "icon-img mic">
                                                        <i class="fas fa-microphone"></i>
                                                    </p>
                                                    <span class = "link-title">SPEAKERS</span>
                                                    <div class = "title-show">
                                                        SPEAKERS
                                                    </div>
                                                </a>
                                            </li>:null}
                                            <li class = "nav-item">
                                                <a onClick = {()=>{
                                                    localStorage.setItem(`${this.props.eventAlias}${this.props.agendaId}${this.props.sessionId}${this.props.subSessionId}${this.props.user.id}`,this.state.groupChat.length)
                                                    this.OnClickTab(1)
                                                    this.setState({msgCount:0})

                                                    }} class={this.state.selectedTabIndex == 1 ? "nav-link active" : "nav-link"} id="certificate" data-toggle="tab" href="#chat_tab">
                                                    <p class = "icon-img certf">
                                                        <i class="far fa-comment"></i>
                                                       {this.state.msgCount > 0  ?  
                                                       <span class="badge badge-danger chat-badge">{this.state.msgCount>100 ? '99+':this.state.msgCount}</span>
                                                       :null} 
                                                    </p>
                                                    <span class = "link-title">CHAT</span>
                                                    <div class = "title-show">
                                                        CHAT
                                                    </div>  
                                                </a>
                                            </li>
                                            <li class = "nav-item">
                                                <a onClick = {()=>this.OnClickTab(2)} class={this.state.selectedTabIndex == 2 ? "nav-link active" : "nav-link"} id="question" data-toggle="tab" href="#q_tab">
                                                    <p class = "icon-img qus">
                                                        <i class="fas fa-question-circle"></i>
                                                        <span class="badge badge-danger chat-badge">{this.state.unReadQuestionsCount}</span>
                                                    </p>
                                                    <span class = "link-title">QUESTIONS</span> 
                                                    <div class = "title-show">
                                                        QUESTIONS
                                                    </div> 
                                                </a>
                                            </li>
                                            <li class = "nav-item">
                                                <a onClick = {()=>{
                                                    this.OnClickTab(5)
                                                    this.setState({numberOfNewPoll:0})
                                                }
                                                    } class={this.state.selectedTabIndex == 5 ? "nav-link active" : "nav-link"} id="polls" data-toggle="tab" href="#polls_tab">
                                                    <p class = "icon-img certf">
                                                        <i class="fas fa-chart-bar"></i>
                                                        {(this.state.selectedTabIndex != 5 && this.state.numberOfNewPoll != 0 && this.state.readPoll) ? <span class="badge badge-danger chat-badge">{this.state.numberOfNewPoll}</span> : null}
                                                    </p>
                                                    
                                                    <span class = "link-title">POLLS</span>
                                                    <div class = "title-show">
                                                        POLLS
                                                    </div>
                                                </a>
                                            </li>
                                            <li class = "nav-item">
                                                <a onClick = {()=>this.OnClickTab(3)} class={this.state.selectedTabIndex == 3 ? "nav-link active" : "nav-link"} id="updates" data-toggle="tab" href="#updates_tab">
                                                    <p class = "icon-img poll">
                                                        <i class="fas fa-bell"></i>
                                                        <span class="badge badge-danger chat-badge">{this.state.unReadChatCount}</span>
                                                    </p>
                                                    <span class = "link-title">UPDATES</span>
                                                    <div class = "title-show">
                                                        UPDATES
                                                    </div> 
                                                </a>
                                            </li>
                                            {this.props.streamType == "1" && this.props.sessionCertificateEnabled == "1" ?
                                            <li class = "nav-item">
                                                <a onClick = {()=>this.OnClickTab(4)} class={this.state.selectedTabIndex == 4 ? "nav-link active" : "nav-link"} id="certificate" data-toggle="tab" href="#C_tab">
                                                    <p class = "icon-img certf">
                                                        <i class="fas fa-award"></i>
                                                    </p>
                                                    <span class = "link-title"> CERTIFICATE</span> 
                                                    <div class = "title-show">
                                                        CERTIFICATE
                                                    </div> 
                                                </a>
                                            </li> : null}
                                        </ul>
                                        <div class={"tab-content "+mobileTabContentClass} id = "session-tab-content">
                                            <a style = {{cursor : 'pointer'}} onClick = {()=> {
                                                    $('body').removeClass('overflow-hidden')
                                                    this.setState({selectedTabIndex : -1}) 
                                                }} class="tab-close-icon d-block d-md-none" id="close-tab">
                                                <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                            </a>
                                            <div class={this.state.selectedTabIndex == 0 ? "tab-pane show active" : "tab-pane"} id="spk_tab" role="tabpanel" aria-labelledby="home-tab">
                                                <div class="tab-pane-inner">
                                                <div class="tab-header py-3 px-3 d-block d-md-none">
                                                    <h5><Trans>speakers</Trans></h5>
                                                </div>
                                                <div className = "speakers-inner mt-60">
                                                {this.props.speakers.map((speaker,index)=>{
                                                    return(
                                                        <div class = "d-flex spk_div">
                                                            <div class = "px-2 pt-2 float-left">
                                                            {speaker.image != null ? 
                                                                <img src={speaker.image} class="rounded-circle mt-2 adj_user" alt="user_1-img"/> :
                                                                <img src="/images/profile-placeholder.png" class="rounded-circle mt-2 adj_user" alt="user_1-img"/>}
                                                            </div>
                                                            <div class = "px-2 pt-2 float-right mt-2">
                                                                <h6>{speaker.name + " " + (speaker.surname == null ? "" :speaker.surname)}</h6>
                                                                <p class = "mb-0 mt-1">{speaker.job_title}</p>
                                                                <p class = "my-1">{speaker.company}</p>
                                                                <p class = "my-1">
                                                                {speaker.description != null ?
                                                                    <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickBio(index)} id = "bio" class = "mr-2 spk_info-div">Full Bio</a> : null}
                                                                    {/* <a href = "" class = "mr-2">Schedule 1 to 1 meeting</a> */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                                </div>
                                            </div>
                                            <div class={this.state.selectedTabIndex == 2 ? "tab-pane show active" : "tab-pane"} id="q_tab">
                                            <div class="tab-pane-inner tab-pane-question" id = "tab-pane-question">
                                            <div class="tab-header py-3 px-3 d-block d-md-none">
                                                <h5>Questions</h5>
                                            </div>
                                            {this.state.questions.length > 0 ?
                                            <div className = "question-inner mt-60 pb-5 pb-lg-0">
                                            {this.state.questions.map((question,index)=>{
                                                let thumbClass = question.upVoteCount.count > 0 && question.upVoteCount.showChecked ? "fas" : "far"
                                                return(
                                                    <div class="question_div">
                                                        <ul class = "q_top">
                                                            <li>
                                                                {question.senderName}
                                                            </li>
                                                            <li>
                                                                {question.timeString}
                                                            </li>
                                                        </ul>
                                                        <p style = {{overflowWrap : 'break-word'}}>
                                                            {question.message}
                                                        </p>
                                                        <i onClick = {()=>this.OnClickUpdateQuestion(index)} class={ thumbClass + " fa-thumbs-up"}></i>
                                                    </div>
                                                )
                                            })}
                                            </div>:
                                            <div class="blank-tab h-100 bg-white position-relative">
                                                <div class="row align-items-center h-100 mx-0">
												<div class="col text-center px-4">
                                                    {this.state.loadingQuestions ?
                                                    <div>
                                                        <div class="loader-inner ball-beat">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        </div>
                                                    </div>:
													<>
                                                    <img class="mb-3" src="/images/icons/ask.svg" alt="blank-icon"/>
													<h5><Trans>askQuestion</Trans></h5>
                                                    <p>
                                                        <Trans>askQuestion1</Trans>
                                                    </p>
                                                    </>}
												</div>
											</div>
                                            </div>}
                                            
                                            </div>
                                                
                                            <div class="chat-card-footer bg-light border q_input">
                                                <form id = "form-inline">
                                                    <div class="row mx-0 p-1">
                                                            <div class="col-9 col-md-10 pl-0">
                                                                <textarea disabled={this.state.loadingQuestions} placeholder = "Type your question.." id="questionInput" onChange = {this.handleChange} value = {this.state.qMessage} class = "form-control rounded-0" rows="1" ></textarea>
                                                            </div>
                                                            <div class="col-3 col-md-2 px-0">
                                                                <button disabled={this.state.loadingQuestions} onClick = {this.OnClickSendQuestion} type = "submit" class = "btn w-100">Submit </button>
                                                            </div>
                                                        </div>
                                                </form>
                                            </div>

                                            </div>
                                            <div class={this.state.selectedTabIndex == 3 ? "tab-pane show active" : "tab-pane"} id="updates_tab">
                                                <div class="tab-pane-inner" id = "tab-pane-chat">
                                                <div class="tab-header py-3 px-3 d-block d-md-none">
                                                    <h5>Updates</h5>
                                                </div>
                                                {this.state.chat.length > 0 ?
                                                <div className = "updates-inner mt-60">
                                                {this.state.chat.map((item,index)=>{
                                                    return(
                                                        <div class="msg-div">
                                                            <ul class = "q_top">
                                                                <li>
                                                                    {item.sender}
                                                                </li>
                                                                <li>
                                                                    {item.timeString}
                                                                </li>
                                                            </ul>
                                                            <Linkify componentDecorator={componentDecorator}><p style = {{overflowWrap : 'break-word'}} id = {"chatMessage"+index}>
                                                                {item.message}
                                                            </p></Linkify>
                                                            <hr/>
                                                        </div>
                                                    )
                                                    })}
                                                </div>:
                                                <div class = "row align-items-center h-100 mx-0">
                                                    <div class = "blank-tab h-100 bg-white position-relative w-100">
                                                        <div class = "row align-items-center h-100 mx-0">
                                                            <div class = "col text-center px-4">
                                                            {this.state.loadingAnnouncements ?
                                                                <div>
                                                                    <div class="loader-inner ball-beat">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    </div>
                                                                </div>:
                                                                <>
                                                                <img class = "mb-3" src = "/images/icons/loud-speaker.svg" alt = "blank-icon" />
                                                                <h5><Trans>noUpdates</Trans></h5>
                                                                <p>
                                                                    <Trans>noUpdates1</Trans>
                                                                </p>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                </div>
                                            </div>
                                            <div class={this.state.selectedTabIndex == 4 ? "tab-pane show active" : "tab-pane"} id="C_tab">
                                                <div class="tab-pane-inner tab-pane-certificate">
                                                    <div class="tab-header py-3 px-3 d-block d-md-none">
                                                        <h5>Certificate</h5>
                                                    </div>
                                                    <div class="certificate-div text-center mt-60">
                                                        <p>
                                                            {this.certificateText}
                                                        </p>

                                                        <div class="progress-sec py-3">
                                                            <p>
                                                            Total Duration <span>{parseFloat(this.props.eventCertificateCompletion/60).toFixed(2)} Hours</span>
                                                            </p>
                                                            <div class="progress text-center">
                                                                <div class="progress-bar" style={{width : this.state.currentPassedTimePercent.toString()+"%"}}></div>
                                                            </div>
                                                            <p class = "per mt-2">{this.state.currentPassedTimePercent.toString()+"%"}</p>
                                                        </div>

                                                        <button onClick = {()=> {
                                                            if(this.state.currentPassedTimePercent < 100){
                                                                // this.SaveUserSessionAttendedTime()
                                                                this.setState({ProgresssMessage:"Complete the required time given to become eligible for the certificate"})
                                                                 setTimeout(()=>{
                                                                   this.setState({ProgresssMessage:""})
                                                                 },3000)
                                                            }else{
                                                                this.OnClickRequestCertificate("event")
                                                            }
                                                            
                                                            
                                                            }} style = {{PointerEvent : this.state.currentPassedTimePercent >= 100 && !this.props.certificateRequestSent ? 'pointer':'none'}} class="btn btn-block">
                                                            request certificate
                                                        </button>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={renderTooltip}
                                                        >
                                                            <p class="text-center mt-2 cer-help-text" tabindex="0" data-for="main" data-iscapture="true" 
                                                        data-tip="If you have attended the event for the required duration you will be eligible for a certificate">
                                                            How it Works?
                                                        </p>
                                                        </OverlayTrigger>
                                                        
                                                        {this.props.requestingCertificate ?
                                                        <div className = "mt-2">
                                                            <Loader
                                                                type = 'Oval'
                                                                color = "#00b6ff"
                                                                height = {20}
                                                                width = {20}
                                                            />
                                                        </div> : null}
                                                        
                                                            <span className = {this.props.cerificateRequestResponse.isError ? "text-red mt-2" : "text-success mt-2"} >{this.props.cerificateRequestResponse.message}</span>
                                                          { this.state.ProgresssMessage != '' ? <span className = "text-red mt-2" >{this.state.ProgresssMessage}</span>:null
                                                            }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class={this.state.selectedTabIndex == 1 ? "tab-pane show active" : "tab-pane"} id="chat_tab">
                                                <div class="tab-pane-inner chat-card bg-white position-relative">
                                                    <div class="tab-header py-3 px-3 d-block d-md-none">
                                                        <h5>Chat</h5>
                                                    </div>
                                                    {this.state.groupChat.length > 0 ?
                                                    <div class="chat-card-msg p-2 mt-60" id = "tab-pane-groupChat">
                                                    {this.state.groupChat.map((item,index)=>{
                                                        let heartClass = this.user == null ? "far" : item.likedBy.indexOf(this.user.id) > -1 ? "fas" : "far"
                                                        let showLike = this.user == null ? false : item.senderId == this.user.id && item.likeCount == 0 ? false : true
                                                        let senderName = this.user == null ? "" : this.user.id == item.senderId ? "Me" : item.senderName
                                                        return(
                                                            <>
                                                            <div class="msg-row px-2" data-is={item.timeString}>
                                                                <div class="chat-msg my-2 text-dark">
                                                                <div class="chat-imag">
                                                                    {item.profileImageUrl == null || item.profileImageUrl == "" ?
                                                                    <img src="/images/profile-placeholder.png" alt="chat-img"/> : 
                                                                    <img src={item.profileImageUrl} alt="chat-img"/>}
                                                                </div>
                                                                    <ul class="list-inline mb-1">
                                                                        <li class={`list-inline-item ${item.senderId == this.props.user.id ? " " : "hoverEffect"}`}>
                                                                            <span class="f-600" onClick={()=>{
                                                                                this.showDialog(item.id,'message',[])
                                                                            }}>{`${senderName}`.toString().toLowerCase().includes(null) ? `${senderName}`.toString().replace(null,''):senderName}</span>
                                                                        </li>
                                                                        <li class="list-inline-item">
                                                                            <span class="time-day">
                                                                                {item.timeString}
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    {item.message}
                                                                    {showLike ?
                                                                    <span class="rate-star">
                                                                        <i onClick = {()=>this.OnClickLikeGroupMessage(item)} class={"heart-ic fa-heart "+heartClass}></i>
                                                                        {item.likeCount > 0 ?<span>{item.likeCount}</span> : null}
                                                                    </span> : null}
                                                                </div>
                                                                <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickReply(item.messageId)}>Reply</a>
                                                            </div>
                                                            {item.replies.map((reply,replyIndex)=>{
                                                                let heartClassReply = this.user == null ? "far" : reply.likedBy.indexOf(this.user.id) > -1 ? "fas" : "far"
                                                                let showReplyLike= this.user == null ? false : reply.senderId == this.user.id && reply.likeCount == 0 ? false : true
                                                                let replierName = this.user == null ? "" : this.user.id == reply.senderId ? "Me" : reply.senderName
                                                                return(
                                                                <div class="msg-row px-2 msg-replies">
                                                                    <div class="chat-msg my-2 msg-replies text-dark ">
                                                                    <div class="chat-imag">
                                                                        {reply.profileImageUrl == null || reply.profileImageUrl == "" ?
                                                                        <img src="/images/profile-placeholder.png" alt="chat-img"/> : 
                                                                        <img src={reply.profileImageUrl} alt="chat-img"/>}
                                                                    </div>
                                                                        <ul class="list-inline mb-1">
                                                                        <li class={`list-inline-item ${reply.senderId == this.props.user.id ? " " : "hoverEffect"}`}>
                                                                                <span class="f-600" onClick={()=>{
                                                                                    this.showDialog(reply.id,'reply',item.replies,item.id)
                                                                                }}>{replierName}</span>
                                                                            </li>
                                                                            <li class="list-inline-item">
                                                                                <span class="time-day">
                                                                                    {reply.timeString}
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        {reply.message}
                                                                        {showReplyLike ?
                                                                        <span class="rate-star">
                                                                            <i onClick = {()=>this.OnClickLikeGroupMessage(reply)} class={"heart-ic fa-heart "+heartClassReply}></i>
                                                                            {reply.likeCount > 0 ?<span>{reply.likeCount}</span> : null}
                                                                        </span> : null}
                                                                    </div>
                                                                    <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickReply(item.messageId)}>Reply</a>
                                                                </div>
                                                                )
                                                            })}
                                                            
                                                            {this.state.replyingToMessageId == item.messageId ?
                                                            <div class="msg-row px-2">
                                                                <div class="chat-msg reply-text my-2 text-dark ">
                                                                    <form id = "form-inline">
                                                                        <div class="chat-imag">
                                                                            {this.user.profileImageUrl == null || this.user.profileImageUrl == "" ?
                                                                            <img src="/images/profile-placeholder.png" alt="chat-img"/> : 
                                                                            <img src={this.user.profileImageUrl} alt="chat-img"/>}
                                                                        </div>
                                                                        <i onClick = {()=>{
                                                                            this.setState({replyingToMessageId : "",replyChatMessage : {}})
                                                                        }} class="fas fa-times-circle"></i>
                                                                        <textarea placeholder = {this.translation.typeyourMessage} id="replyInput" onKeyPress = {this.OnSubmitReplyChatTextArea} autoFocus onChange = {(e)=>this.handleChangeReplyInput(e,item.messageId)} type="text" class="form-control" rows = {2}/>
                                                                        <button onClick = {()=>this.OnSubmitReply(item.messageId,item.id)} type = "submit" class = "btn">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.712" height="15.119" viewBox="0 0 20.712 19.119">
                                                                                <path d="M3.375,23.619l20.712-9.559L3.375,4.5v7.435l14.837,2.124L3.375,16.184Z" transform="translate(-3.375 -4.5)" fill="#fff"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </form>
                                                                </div>
                                                            </div> : null}
                                                            </>
                                                        )
                                                    })}
                                                    </div> : 
                                                    <div class = "blank-tab h-100 bg-white position-relative">
                                                        <div class = "row align-items-center h-100 mx-0">
                                                            <div class = "col text-center px-4">
                                                            {this.state.loadingGroupChat ?
                                                                <div>
                                                                    <div class="loader-inner ball-beat">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    </div>
                                                                </div>:
                                                                <>
                                                                <img class = "mb-3" src = "/images/icons/discussion.svg" alt = "blank-icon"/>
                                                                <h5><Trans>emptyGroupChat</Trans></h5>
                                                                <p>
                                                                    <Trans>emptyGroupChat1</Trans>
                                                                </p>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    <div class="chat-card-footer bg-light border">
                                                        <form id = "form-inline">
                                                            <div class="row mx-0 p-1">
                                                                <div class="col-9 col-md-10 px-0">
                                                                    <textarea disabled = {this.state.loadingGroupChat} placeholder = {this.translation.typeyourMessage} autoFocus id = "groupChatInput" onChange = {this.handleChangeChatInput} value = {this.state.groupChatMessage} class = "form-control rounded-0" rows="1"></textarea>
                                                                </div>
                                                                <div class="col-3 col-md-2 pr-0">
                                                                    <button disabled = {this.state.loadingGroupChat} onClick = {this.OnClickSendGroupMessage} type = "submit" class = "btn w-100">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.712" height="19.119" viewBox="0 0 20.712 19.119">
                                                                            <path d="M3.375,23.619l20.712-9.559L3.375,4.5v7.435l14.837,2.124L3.375,16.184Z" transform="translate(-3.375 -4.5)" fill="#fff"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class={this.state.selectedTabIndex == 5 ? "tab-pane show active" : "tab-pane"} id="polls_tab">
                                                <div class="tab-pane-inner tab-pane-poll tab-pane-polls">
                                                    <div class="tab-header py-3 px-3 d-block d-lg-none">
                                                        <h5>Polls</h5>
                                                    </div>
                                                <div className="poll-div mt-60">
                                                    <div class="row mx-0">
                                                            <div class={`col text-center poll-tab border c-poll-btns ${this.state.activeTab == 0 ? 'actv':''}`} onClick = {()=>{
                                           
                                                                this.setState({filterTarget:0,activeTab:0})
                                                                this.filterPoll(0)
                                                                this.setState({selectedAnswerId:[],selected_options:[]})
                                                            }}>
                                                                Active Poll(s)
                                                            </div>
                                                            <div class={`col text-center poll-tab border c-poll-btns ${this.state.activeTab == 1 ? 'actv':''}`} onClick = {()=>{
                                                        
                                                                this.setState({filterTarget:1,activeTab:1})
                                                                this.filterPoll(1)
                                                                this.setState({selectedAnswerId:[],selected_options:[]})
                                                            }}>
                                                                Past Poll(s)
                                                            </div>
                                                    </div>
                                                    {/* : null} */}
                                                    {this.state.pollQuestion.length > 0 ?
                                                    <div class="poll-card mt-60">
                                                        
                                                        <div id="accordion" class = "poll-accordion bg-light border border-top-0 pt-2">
                                                            {this.state.pollQuestion.map((item,index)=>{
                                                                let headerClass = item.answer.length > 0 ? 'card-header pb-1 pt-3': 'card-header'
                                                                return(
                                                                item.status != 0 ?
                                                                <div class="card text-left mb-3">
                                                                    <div class={` header ${this.collapse == item.id ? headerClass : headerClass +" collapsed"}`}  id="delegateHeader" onClick = {async()=>{
                                                                        this.collapseDropDown(item.id)
                                                                        this.toggleCollapse("collapsibleNavbar"+0)}}>
                                                                        <h5 class="mb-0">
                                                                            {item.title}
                                                                        </h5>
    
                                                                        {item.answer.length > 0  ?
                                                                        <>
                                                                        <h6 class="mb-0" style={{cursor:"auto"}}>
                                                                            Your Answer
                                                                        </h6>
                                                                        <p class = "mb-2">
                                                                            {item.answer.map(item=>{
                                                                                return <p>{item.title}</p>
                                                                            })}
                                                                        </p></> : null}
                                                                        
                                                                        <i class="fas fa-angle-down"></i>
                                                                    </div>
                                                                    <MDBCollapse 
                                                                    // id={"collapsibleNavbar"+0} 
                                                                    isOpen={ this.collapse == item.id}>
                                                                        {item.answer.length == 0 && item.status == "1" ?
                                                                        <>{
                                                                        // item.options.map((option,qIndex)=>{
                                                                        //     return(
                                                                                <div class="card-body pt-1">
                                                                                    <form>{
                                                                                        item.options.map((option,qIndex)=>{
                                                                                    return(
                                                                                        <label class={`mr-4 ${item.type == 2 ? 'form-check-label check-cont' : 'cm_cont '}`} id = {item.id}>
                                                                                            {option.title}
                                                                                            {item.type == 2 ?
                                                                                            <input class="form-check-input" name="check" type="checkbox" value={option.title} id={item.id} onClick = {(e)=>{
                                                                                                this.setState({type:"check"})
                                                                                                if(this.state.selectedAnswerId.includes(option.id)){
                                                                                                    let filterId = this.state.selectedAnswerId.filter(item=>item != option.id)
                                                                                                    let filterData = this.state.selected_options.filter(item=>item.optionId != option.id)
                                                                                                    this.setState({selectedAnswerId:[...filterId],selected_options:[...filterData]})
                                                                                                }else{
                                                                                                    this.setState({selectedAnswerId:[option.id,...this.state.selectedAnswerId],selected_options:[{optionId:option.id,title:option.title},...this.state.selected_options]})

                                                                                                }
                                                 
                                                                                            }}/> :
                                                                                            <input type="radio" name="radio" value={option.title} id={item.id} onClick = {(e)=>{
                                                                                
                           
                                                                                                    this.setState({selectedAnswerId:[option.id,...this.state.selectedAnswerId],selected_options:[{optionId:option.id,title:option.title},...this.state.selected_options],type:"radio"})
                                                                                                
                                                    
                                                                                
                                                                                            }}/>
                                                                                            }
                                                                                            <span class="checkmark">
                                                                                                <div></div>
                                                                                            </span>
                                                                                        </label>)})}
                                                                                    </form>
                                                                                    
                                                                                </div>
                                                                            // )
                                                                        // })
                                                                    }    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',columnGap:'5px'}}>
                                                                        <div className={`text-center question_submit_btn ${item.options.some(r=> this.state.selectedAnswerId.includes(r.id)) ? " ":"disable-btn"}`} style={{marginBottom: '10px !important'}} >
                                                                                        <a href="#" class="btn btn-primary" style={{fontSize:"14px",marginBottom: '10px !important',display:"flex",columnGap:'5px'}} onClick={(e)=>{
                                                                                            e.preventDefault()
                                                                                            if(this.state.loader != true){
                                                                                            this.handleOnSubmitPoll(item.id,item.type)
                                                                                            this.setState({loader:true,loader_id:item.id})
                                                                                            }
                        
                                                                                        }} >
                                                                                            Submit Your Answer  {
                                                                                        this.state.loader == true && this.state.loader_id == item.id ?<span className = "text-center" style = {{right : 8}}>
                                                                                        <Loader
                                                                                            type = 'Oval'
                                                                                            color = "#00b6ff"
                                                                                            height = {20}
                                                                                            width = {20}
                                                                                        />
                                                                                    </span> : null}
                                                                                        </a>
                                                                                    </div>
                            
                                                                               
                                                                                    </div>
                                                                                    
                                                                                    </>:
                                                                        <div class="card-body">
                                                                            <div class="row mb-3 p-row">
                                                                                <div class="col">
                                                                                    <p class="mb-0">
                                                                                        Poll Results
                                                                                    </p>
                                                                                </div>
                                                                                <div class="col text-right">
                                                                                    <p class="mb-0">
                                                                                        {`${item.totalCount} ${item.totalCount > 1 ? 'Answers' : 'Answer'}`}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {item.options.map((option,qIndex)=>{
                                                                                let percent = option.totalAnswer / item.totalCount * 100
                                                                                return(
                                                                                    <div class="progress-sec mb-2">
                                                                                        <p class = "mb-1">
                                                                                            {option.title}
                                                                                        </p>
                                                                                        <div class="progress ml-0">
                                                                                            <div class="progress-bar" style={{width: percent+'%'}}></div>
                                                                                        </div>
                                                                                        <span>{ option.totalAnswer == 0 && item.totalCount==0 ? 0: this.hasDecimal(percent) == true ? percent.toFixed(2) : percent}%</span> 
                                                                                        <p class = "mt-1 tot-num">
                                                                                            {option.totalAnswer} / {item.totalCount}
                                                                                        </p>                                                    
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        } 
                                                                    </MDBCollapse>
                                                                </div>:null
                                                                )
                                                            })}
                                                        </div>
                                                    </div> : 
                                                    <div class = "blank-tab h-100 bg-white position-relative">
                                                    <div class = "row align-items-center h-100 mx-0">
                                                        <div class = "col text-center px-4">
                                                        {this.state.loadingPolls ?
                                                            <div>
                                                                <div class="loader-inner ball-beat">
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                </div>
                                                            </div>:
                                                            <>
                                                            <img class = "mb-3" src = "/images/icons/poll-ic.svg" style={{marginTop:'60px'}} alt = "blank-icon" />
                                                            <h5><Trans>noPolls</Trans></h5>
                                                            <p>
                                                                <Trans>noPolls1</Trans>
                                                            </p>
                                                            </>}
                                                        </div>
                                                    </div>
                                                </div>}
                                                </div> 
                                            </div>
                                        </div>
                                    </div>	
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="section logo_section pb-3">
                <div class="container-fluid bg-white">
                {this.state.availableVideoLocales.length > 1 ? <div class = "row live_screen">
					<div class = "col-lg-6 col-xl-7 py-2 bg-light">
						<div class = "d-flex justify-content-center align-items-center select-lang-col">
							<span class = "mr-2 text-sm">Choose language:</span>
                            {this.state.availableVideoLocales.map((item)=>{
                                return(
                                    <button onClick={()=> this.handleOnChangeVideo(item)} class = {this.state.selectedVideoLocale == item ? "btn btn-primary btn-sm mr-2 active" : "btn btn-primary btn-sm mr-2"}>{item.toUpperCase()}</button>
                                )
                            })}
							
						</div>
					</div>
				</div> : null}
                <div class = "row live_screen py-3">
                    <div class="col-md-2 position-relative text-center pr-md-0 d-none d-md-block">
                        <div class="logo-mevent">
                            <p class = "com_p">Brought to you by</p>
                            <img src = "/images/logos/mwan_events_logo.svg" class = "com_logo" alt = "mwan_event_logo"/>
                        </div>
                    </div>
                    {this.props.sponsors.length > 0 ?
                    <div class = "col-md-10 pl-md-0">
                        <h6 class = "ss-hd  ml-4">Session Sponsors and Partners</h6>
                        {this.props.sponsors.length > 5 ?
                        <Marquee pauseOnHover speed={80} className="Marquee-content">
                            {this.props.sponsors.map((sponsor,index)=>{
                                return(
                                    <div class="Marquee-tag">
                                        <img src = {sponsor.image} alt = "spon_logo"/>
                                    </div>
                                )
                            })}
                        </Marquee> : 
                        <ul class = "sponsors-logo-list">
                        {this.props.sponsors.map((sponsor,index)=>{
                            return(
                                <li>
                                <div class = "logo-img-area">
                                    <img src = {sponsor.image} alt = "spon_logo"/>
                                </div>
                            </li>
                            )
                        })}
                        </ul>}
                    </div> : null}
                    <div class="col-md-2 position-relative text-center mt-3 d-block d-md-none">
                        <div class="">
                            <p class = "com_p">Brought to you by</p>
                            <img src = "/images/logos/mwan_events_logo.svg" class = "com_logo" alt = "mwan_event_logo"/>
                        </div>
                    </div>
                </div>
                </div>
                </div> 
                <div class="modall fade right" id="spk-modal-chat">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">
                                <div class="row my-3">
                                    <div class="col-3 text-center">
                                        <div class="participant-image">
                                        {this.state.attendeePhoto != null && this.state.attendeePhoto != "" ? 
                                            <img src={this.state.attendeePhoto} alt="participant_image"/> :
                                            <img src="/images/profile-placeholder.png" alt="participant_image"/>}
                                        </div>
                                    </div>

                                    <div class="col-9 d-flex align-items-center">
                                        <div class = "participant-details">
                                            <span class = {`spk-full-name`}>
                                                {this.state.attendeeName == null ? "" :this.state.attendeeName}
                                            </span>
                                            <div class="post">
                                                {this.state.attendeeJobTitle} <br/>
                                                {this.state.attendeeCompanyName}
                                            </div>
                                        </div>
                                    </div>
                                    <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                        $('#spk-modal-chat').modal('hide');
                                        $('.modal-backdrop').remove();
                                    }} class = "close-icons" id = "close-m">
                                        <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                    </a>
                                </div>
                            <div id="speakerDetail" class="spk-detail-text">
                                    {this.state.attendeeprofileBio != null && this.state.attendeeprofileBio != undefined ?
                                    <p class = "details pr-3">
                                        <p style={{textAlign:'justify'}}>
                                        {!this.state.showFullAttendeeBio && (this.state.attendeeprofileBio || '').length >= 700 ?
                                            this.state.attendeeprofileBio.substring(0,700) :
                                            this.state.attendeeprofileBio}
                                    </p></p> : null}
                                    {this.state.attendeeprofileBio!= null && (this.state.attendeeprofileBio || '').length >= 700 ?
                                    <a style = {{cursor : 'pointer',textDecoration:'underline'}} onClick = {()=>{
                                        this.setState({showFullAttendeeBio : !this.state.showFullAttendeeBio},()=>{
                                            if(!this.state.showFullAttendeeBio){
                                                var myDiv = document.getElementById('speakerDetail');
                                                myDiv.scrollTop = 0
                                            }
                                        })
                                    }} class="text-underline text-dark">{!this.state.showFullAttendeeBio ? "Show Full" : "Show Less"}</a> : null}
                            </div>
                            
                            {/* <p class = "mt-4 text-center">
                                <a href = {"mailto:"+this.state.selectedSpeaker.email} class="btn mx-1">
                                    Drop Email
                                </a>
                            </p> */}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modall fade right" id="spk-modal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">
                                <div class="row my-3">
                                    <div class="col-3 text-center">
                                        <div class="participant-image">
                                        {selectedSpeaker.image != null ? 
                                            <img src={selectedSpeaker.image} alt="participant_image"/> :
                                            <img src="/images/profile-placeholder.png" alt="participant_image"/>}
                                        </div>
                                    </div>

                                    <div class="col-9 d-flex align-items-center">
                                        <div class = "participant-details">
                                            <span class = "spk-full-name">
                                                {(selectedSpeaker.surname == null ? "" :selectedSpeaker.surname) +" "+selectedSpeaker.name}
                                            </span>
                                            <div class="post">
                                                {selectedSpeaker.job_title} <br/>
                                                {selectedSpeaker.company}
                                            </div>
                                        </div>
                                    </div>
                                    <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                        $('#spk-modal').modal('hide');
                                        $('.modal-backdrop').remove();
                                    }} class = "close-icons" id = "close-m">
                                        <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                    </a>
                                </div>
                            <div id="speakerDetail" class="spk-detail-text">
                                    {selectedSpeaker.description != null ?
                                    <p class = "details pr-3">
                                        {!this.state.showFullSpeakerDescription && selectedSpeakerDescription.length >= 700 ?
                                            ReactHtmlParser(selectedSpeaker.description.substring(0,700)) :
                                            ReactHtmlParser(selectedSpeaker.description)}
                                    </p> : null}
                                    {selectedSpeaker.description != null && selectedSpeakerDescription.length >= 700 ?
                                    <a style = {{cursor : 'pointer',textDecoration:'underline'}} onClick = {()=>{
                                        this.setState({showFullSpeakerDescription : !this.state.showFullSpeakerDescription},()=>{
                                            if(!this.state.showFullSpeakerDescription){
                                                var myDiv = document.getElementById('speakerDetail');
                                                myDiv.scrollTop = 0
                                            }
                                        })
                                    }} class="text-underline text-dark">{!this.state.showFullSpeakerDescription ? "Show Full" : "Show Less"}</a> : null}
                            </div>
                            
                            {/* <p class = "mt-4 text-center">
                                <a href = {"mailto:"+this.state.selectedSpeaker.email} class="btn mx-1">
                                    Drop Email
                                </a>
                            </p> */}
                            </div>

                        </div>
                    </div>
                </div>
                <section class="mobile-tab-bottom d-block d-md-none">
                    <ul class = "nav nav-tabs session_tab nav-fill" role = "tablist">
                        {this.props.speakers.length > 0 ? <li class = "nav-item mob-tab-item">
                                <a onClick = {()=>{
                                        $('body').addClass('overflow-hidden')
                                        this.OnClickTab(0)
                                    }} class={this.state.selectedTabIndex == 0 ? "nav-link active" : "nav-link"} id="speaker" data-toggle="tab" href="#spk_tab">
                                <p class = "icon-img mic">
                                    <i class="fas fa-microphone"></i>
                                </p>
                                <Trans>speakers</Trans>
                            </a>
                        </li>:null}
                        <li class = "nav-item mob-tab-item">
                                <a onClick = {()=>{
                                        $('body').addClass('overflow-hidden')
                                        localStorage.setItem(`${this.props.eventAlias}${this.props.agendaId}${this.props.sessionId}${this.props.subSessionId}${this.props.user.id}`,this.state.groupChat.length)
                                                    this.OnClickTab(1)
                                                    this.setState({msgCount:0})
                                    }} class={this.state.selectedTabIndex == 1 ? "nav-link active" : "nav-link"} class="nav-link" id="certificate" data-toggle="tab" href="#chat_tab">
                                <p class = "icon-img certf">
                                    <i class="far fa-comment"></i>
                                    {this.state.msgCount > 0 ? 
                                    <span class="badge badge-danger chat-badge">{this.state.msgCount>100 ? '99+':this.state.msgCount}</span>
                                    :null}
                                </p>
                                Chat
                            </a>
                        </li>
                        <li class = "nav-item mob-tab-item">
                                <a onClick = {()=>{
                                        $('body').addClass('overflow-hidden')
                                        this.OnClickTab(2)
                                    }} class={this.state.selectedTabIndex == 2 ? "nav-link active" : "nav-link"} class="nav-link" id="question" data-toggle="tab" href="#q_tab">
                                <p class = "icon-img qus">
                                    <i class="fas fa-question-circle"></i>
                                    <span class="badge badge-danger chat-badge">{this.state.unReadQuestionsCount}</span>
                                </p>
                                Questions
                            </a>
                        </li>
                        <li class = "nav-item mob-tab-item">
                                <a onClick = {()=>{
                                            $('body').addClass('overflow-hidden')
                                            this.OnClickTab(3)
                                        }} class={this.state.selectedTabIndex == 0 ? "nav-link active" : "nav-link"} class="nav-link" id="polls" data-toggle="tab" href="#updates_tab">
                                <p class = "icon-img poll">
                                    <i class="fas fa-bell"></i>
                                    <span class="badge badge-danger chat-badge">{this.state.unReadChatCount}</span>
                                </p>
                                Updates
                            </a>
                        </li>
                        {this.props.streamType == "1" && this.props.sessionCertificateEnabled == "1" ?
                        <li class = "nav-item mob-tab-item">
                                <a onClick = {()=>{
                                        $('body').addClass('overflow-hidden')
                                        this.OnClickTab(4)
                                    }} class={this.state.selectedTabIndex == 4 ? "nav-link active" : "nav-link"} class="nav-link" id="certificate" data-toggle="tab" href="#C_tab">
                                <p class = "icon-img certf">
                                    <i class="fas fa-award"></i>
                                </p>
                                Certificate
                            </a>
                        </li> : null}
                    </ul>
                </section>
                </div>
            </section>         
            {/* <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showSessionDisconnectPopup}
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.errorMessageHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.errorMessageJoin}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {this.state.showNextSessionNotification && this.props.nextSession ?
            <div class="next-secession animate__animated animate__slideInRight">
                <div class="row inner-cont">
                    <div class="col-12">
                        <h6 class="text-white mb-0 animate__animated animate__fadeInLeft animate__delay-1s">
                            Next Session: {this.state.nextSessionTime > 0 ? this.state.nextSessionTime > 1 ? ` (in ${this.state.nextSessionTime} minutes)` : ` (in ${this.state.nextSessionTime} minute)`:''}
                        </h6>
                        <p class = "animate__animated animate__fadeInLeft animate__delay-2s">
                        {this.props.nextSession.title && wordsRestrictor(this.props.nextSession.title,13)}
                        </p>
                        {this.state.nextSessionTime == 0 ?
                        <a onClick = {(e)=>{
                            let updatedStateObj = {
                                showNextSessionNotification: false,
                                selectedVideoLocale: "en"
                            }
                            if(this.state.nextSessionTime == 0){
                                if(this.props.nextSession.isVimeo == 1){
                                    updatedStateObj.vimeoUrl = this.props.nextSession.link
                                }else{
                                    updatedStateObj.videoId = this.props.nextSession.id
                                }
                                this.setState(updatedStateObj)
                                this.props.onClickStartNewSection(this.subSessionIndex)
                                clearInterval(this.devTootInterval)
                                clearTimeout(this.sessionEndTimer)
                                this.SaveUserSessionAttendedTime()
                            }else{
                                this.OnClickBack()
                            }
                            this.onBubbleRemove()
                        }} 
                        class={this.state.nextSessionTime  == 0 ? "btn btn-watch animate" : "btn btn-watch"} style = {{display: 'inline',cursor: 'pointer'}}>
                            <i class="far fa-play-circle mr-2"></i> 
                            {this.state.nextSessionTime == 0 ? 'Watch':'Agenda'}                  
                        </a> : null}
                    </div>
                </div>
            </div> : null}
            </div>
        )
    }
}
export default compose(withTranslation())(session);