import React from 'react';
import api from './api';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import utility, { monthNames } from '../../Utility/utility';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { Constants } from '../../Constants';
import VirtualZone from '../Header/virtualZone';
import $ from 'jquery'
import { AllVideosLoaded } from './actions';
import './styles.css'
import { GAPageView, GARecordEvent } from '../../Utility/GA';
import RegisterModel from '../RegisterModel';
import { useLastLocation } from 'react-router-last-location';
class VideoPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/videos") > -1) || -1,
            selectedSubIndex: -1,
            videosData : null,
            loading : true,
            loadingError : '',
            showComingSoonMsg : false,
            selectedVideoUrl : '',
            selectedVideoTitle : ''
        };
        this.user = utility.getLoggedInUser(this.props.event.alias)
        this.isBooking = false
        this.showContactModal = true
    }

    componentDidMount(){
        fetch('/setCurrentPage?page=videos')
        fetch(`/${this.props.event.alias}/videos`)
        let videoContactFormShown = localStorage.getItem(Constants.API_TOKEN+this.props.event.alias+"videoContactForm")
        if(videoContactFormShown == "1"){
            this.showContactModal = false
        }
        this.showContactModal = false // change request
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        if(this.user != null){
            this.isBooking = this.user.isBooking
        }
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        if(this.props.allVideosData != null){
            this.setState({videosData : this.props.allVideosData})
        }else{
            this.getVideos()
        }
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],'Videos')
                if(result != null){
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/videos') > -1)
                    this.setState({selectedIndex : i,selectedSubIndex})
                    break
                }
            }
        }
    }
    getVideos = async () => {
        this.setState({loading : true})
        if(this.props.videosData != null){
            let pVideoIndex = this.props.videosData.videos.findIndex(x=>x.is_premium == "1")
            var videoUrl = null
            if(pVideoIndex > -1){
                videoUrl = this.props.videosData.videos[pVideoIndex].link
            }else{
                videoUrl = "1"
            }
            if(videoUrl == null && this.isBooking){
                this.loadVideosFromApi()
            }else{
                this.setState({videosData : this.props.videosData,loading : false},()=>{
                    if(this.state.videosData.videos.length > 0 && this.showContactModal){
                        $("#aganda_Modal").modal('show')
                    }
                })
            }
            return
        }else{
            // if(this.user != null){
            //     await api.getFormSubmittionStatus(this.props.event.alias,this.user.id,(status)=>{
            //         this.showContactModal = !status
            //     })
            // }
        }
        this.loadVideosFromApi()
    }
    donwloadAgendaFormEvent=(name,email) =>{
        
        console.log("Watch videos")
        GARecordEvent("watchVideosSubmitClicked", this.user ? this.user.id:"non user",{
            category : 'Watch Video Submit',
            action : 'Watch video form submit',
            userName: this.user? (this.user.firstName+''+this.user.lastName) :"anonymous user",
            userEmail: this.user ? (this.user.email) :"not available"
        })
    }
    loadVideosFromApi = () => {
        var params = {
            view_premium : this.user == null ? 0 : this.isBooking ? 1 : 0
        }
        api.getAllVideos(this.state.event.alias,params,(err,data)=>{
            if(err == null){
                this.setState({videosData : data,loading : false})
                this.props.videosLoaded(data)
                if(data.videos.length > 0 && this.showContactModal){
                    $("#aganda_Modal").modal('show')
                }
                return
            }else{
                this.setState({loadingError : 'Something went wrong while fetching data',loading : false})
            }
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    OnClickWatchNow = (isPremium,videoUrl,title,dateString) => {
        // let completeTitle = (title == null ? "" : title) + " " + dateString
        let completeTitle = title == null ? "" : title
        if(isPremium == 0){
            this.setState({selectedVideoUrl : videoUrl,selectedVideoTitle : completeTitle},()=>{
                $("#video-modal").modal('show')
                // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                document.body.style.overflowY = "hidden"
            })
        }else{
            if(this.user != null && this.isBooking){
                this.setState({selectedVideoTitle : completeTitle,selectedVideoUrl : videoUrl},()=>{
                    $("#video-modal").modal('show')
                    // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                    document.body.style.overflowY = "hidden"
                })
            }else{
                $("#premiumSession").modal('show')
                // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                document.body.style.overflowY = "hidden"
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        }
    }
    handleClickOutside = () => {
        // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
        document.body.style.overflowY = "scroll"
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
        this.getEventVenues()
    }
    languageClick = (value) =>{
        console.log("Trigger video page on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnSaveFormDetails = (data) => {
        if(this.user != null){
            data.userId = this.user.id
        }
        api.saveContactForm(this.props.event.alias,data,(err,res)=>{
            if(err == null){
                $('#aganda_Modal').modal('hide');
                $('.modal-backdrop').remove();
            }
            localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+"videoContactForm","1")
            this.registerModelRef.OnApiResponse(err == null)
        })
    }
    getLastLocation = () => {
        if(this.lastLocation == null){
            this.lastLocation = useLastLocation()
            if(this.lastLocation != null){
                this.lastLocation = this.lastLocation.pathname
            }
        }
        return null
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                <this.getLastLocation />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Videos</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                <div id="header-top">
                <Header
                    selectedIndex = {this.state.selectedIndex}
                    selectedSubIndex = {this.state.selectedSubIndex}
                    onMenuItemClick = {this.OnNavigateToPage}
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    isMarketplace = {this.props.event.is_marketplace}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    event = {this.props.event}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                    onClickAccountItem = {this.OnClickAccountItem}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    onClickAccountItem = {this.OnClickAccountItem}
                    notifications = {this.props.notifications}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user}
                /> : null}
                </div>
                <div className = {`content-main`}>
                    {!this.state.loading ?
                        <div>
                            {this.state.showComingSoonMsg ?  
                            <div className = "text-center bg-light">
                                <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                            </div> : 
                            <Content 
                                event = {this.props.event} 
                                videosData = {this.state.videosData}
                                selectedVideoUrl = {this.state.selectedVideoUrl}
                                selectedVideoTitle = {this.state.selectedVideoTitle}
                                onClickWatchNow = {this.OnClickWatchNow}
                                onNavigateToPage = {(path)=> this.OnNavigateToPage(this.props.event.alias+path)}
                                isPremiumUser = {this.user == null ? false : this.isBooking}
                                onCloseVideo = {()=> this.setState({selectedVideoUrl : ''})}
                            />}
                            {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ?
                            <NewsLetter 
                                title = "SUBSCRIBE OUR NEWS LETTER" 
                                event={this.props.event} 
                                event_alias={this.props.event_alias}
                                buttonText = "Subscribe"
                                type = 'newsLetter'
                            />:null}
                        </div> :
                        <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                            {this.state.loadingError == "" ?
                                <Loader
                                    type = 'Oval'
                                    color = "#00b6ff"
                                    height = {40}
                                    width = {40}
                                />:
                                <ErrorScreen 
                                    message = {this.state.loadingError}
                                    onPressRetry = {this.OnPressRetry}
                                />
                            }
                        </div>
                    }
                    <Footer1
                        event = {this.state.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                </div>
                <Footer2 
                    event = {this.state.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
                <RegisterModel
                    ref = {(ref)=> this.registerModelRef = ref}
                    eventAlias={this.props.event.alias} 
                    agendaTitle = "Watch Videos"
                    agendaSubTitle = "Please provide your details to be able to watch videos"
                    agendaSubmitButtonText = "Watch"
                    type = "contactForm"
                    static = {true}
                    showCloseButton = {true}
                    onClickClose = {()=>{
                        let path = ""
                        if(this.lastLocation != null){
                            path = this.lastLocation
                            path = path.substr(1)
                        }else{
                            path = "/"
                        }
                        this.OnNavigateToPage(path)
                    }}
                    onClickSubmit = {this.OnSaveFormDetails}
                    downloadAgendaFormEvent={this.donwloadAgendaFormEvent}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    videosData : state.videos.allVideosData
})
const mapDispatchToProps = dispatch => ({
    videosLoaded : data => dispatch(AllVideosLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(VideoPage);