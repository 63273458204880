import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Constants } from "../../../../Constants";
import {
  applyFilters,
  downloadDoc,
  getContent,
  getFilters,
} from "../../actions";
import utitlity,{
  downloadURI,
  getPaginatedData,
  getPaginationGroup,
} from "../../../../Utility/utility";
import { Paginator } from "../../../Paginator";
import Loader from "react-loader-spinner";
import  PermiumUserModal  from "../../../modals/premiumUserModal";
import $ from "jquery";
function Content(props) {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [dataLimit, setDataLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [appliedSearchFilter, setAppliedSearchFilter] = useState("");
  const filterContentRef = useRef();
  const firstUpdate = useRef(true);
  const inputRefs = useRef([]);
  const [downloadFileIndex, setDownloadFileIndex] = useState(0);
  const user = utitlity.getLoggedInUser(props.eventAlias)
  useEffect(() => {
    props.getData(props.eventAlias, { type: "documents", subType: props.id });
    props.getFilters(props.eventAlias, {
      type: "documents",
      subType: props.id,
    });
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (appliedSearchFilter.length > 0) {
      setAppliedSearchFilter("");
      filterData();
    }
  }, [searchText]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (appliedFilters.length == 0) {
      for (var i = 0; i < inputRefs.current.length - 1; i++) {
        inputRefs.current[i].checked = false;
      }
      filterData();
    }
  }, [appliedFilters]);
  const setFilters = (key, value) => {
    let filters = appliedFilters;
    if (filters[key] == null) {
      filters[key] = [value];
    } else {
      let index = filters[key].indexOf(value);
      if (index > -1) {
        filters[key] = filters[key].filter((x) => x != value);
      } else {
        filters[key].push(value);
      }
    }
    setAppliedFilters(filters);
  };
  const filterData = () => {
    if (searchText.length > 0) {
      setAppliedSearchFilter(searchText);
    }
    props.applyFilters(searchText, appliedFilters);
  };
  const getData = () => {
    if (props.data) {
      return getPaginatedData(props.data, activePage, dataLimit);
    }
    return [];
  };
  const getPages = () => {
    if (props.data) {
      return getPaginationGroup(props.data.length, dataLimit, activePage);
    }
    return [];
  };
  const getFilters = () => {
    if (props.filters) {
      return props.filters;
    }
    return {};
  };
  const downloadFile = (item, index) => {
    if (item.details.is_allowed == "1") {
      if (item.details.is_free == "1" && item.details.data_url) {
        downloadURI(
          item.details.data_url,
          item.details.title + "." + item.details.file_type
        );
      } else {
        setDownloadFileIndex(index);
        props.downloadFile(
          props.eventAlias,
          item.details.id,
          item.details.title + "." + item.details.file_type
        );
      }
    } else {
      $("#premiumFileModal").modal("show");
    }
  };
  return (
    <>
      <section class={`telehealth-inner-banner bg_primary py-3`}>
        <div class="container py-2 text-center">
          <div class="row">
            <div class="col-12">
              <h2>{props.type}</h2>
            </div>
            <a onClick={props.onClickBack} class="btn btn-back">
              <span>&#x2039;</span> Knowledge Hub
            </a>
          </div>
        </div>
      </section>
      <section
        class="presentation-sec py-3"
        onClick={() => filterContentRef.current.classList.add("hiddens")}
      >
        <div class="container">
          <div class="search-feild my-3">
            <div class="row">
              <div class="col-8 col-md-9 form-inline">
              {(props && props.loading == false) && (props && props.data && props.data.length > 0)  ?
                <div class="form-group has-clear">
                  <input
                    value={searchText}
                    disabled={props.loading}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    id="ppt-input"
                    type="text"
                    class="form-control search-input"
                    placeholder="Type your keyword"
                  />
                  <span
                    onClick={() => setSearchText("")}
                    class={
                      "form-control-clear " +
                      (searchText.length == 0 ? "d-none" : "d-block")
                    }
                  >
                    <i class="fas fa-times"></i>
                  </span>
                  <button
                    onClick={filterData}
                    type="submit"
                    class="btn search-btn"
                  >
                    <i class="fas fa-search mr-2"></i>
                    <span>Search</span>
                  </button>
                </div>:null}
              </div>
              <div class="col-4 col-md-3 text-right">
                <div class="filter-area position-relative">
                  {getData().length > 0 ?<button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        !props.loadingFilters &&
                        !props.loading &&
                        Object.keys(getFilters()).length > 0
                      ) {
                        filterContentRef.current.classList.toggle("hiddens");
                      }
                    }}
                    class="btn btn-filter"
                  >
                    <i class="fas fa-filter mr-2"></i> Filters
                  </button>:null}
                  <div
                    onClick={(e) => e.stopPropagation()}
                    ref={filterContentRef}
                    class="filter-option hiddens"
                  >
                    <div class="filter-opt-header">
                      <button
                        onClick={() => {
                          setAppliedFilters([]);
                        }}
                        class="btn reset-filter"
                      >
                        Reset Filter
                      </button>
                      <button onClick={filterData} class="btn apply-filter">
                        Apply Filter
                      </button>
                      <div
                        onClick={() =>
                          filterContentRef.current.classList.add("hiddens")
                        }
                        class="cross-filter"
                      >
                        <i class="fas fa-times"></i>
                      </div>
                    </div>
                    <div class="filter-opt-body p-4">
                      {Object.keys(getFilters()).map((key, fIndex) => {
                        return (
                          <div class="form-group">
                            <p>
                              {" "}
                              {key[0].toUpperCase() +
                                key.slice(1, key.length)}:{" "}
                            </p>
                            {getFilters()[key].map((item, i) => {
                              return (
                                <div
                                  class="form-check form-check-inline"
                                  key={fIndex + i}
                                >
                                  <input
                                    ref={(el) => {
                                      if (inputRefs.current.indexOf(el) < 0)
                                        inputRefs.current.push(el);
                                    }}
                                    class="form-check-input"
                                    type="checkbox"
                                    id={"gridCheck" + fIndex + i}
                                    key={"inputkey" + fIndex + i}
                                    onChange={() => setFilters(key, item.id)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for={"gridCheck" + fIndex + i}
                                  >
                                    {item.value[0].toUpperCase() +
                                      item.value.slice(1, item.value.length)}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.loading ? (
            <div
              style={{
                width: "100%",
                minHeight: 360,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader type="Oval" color="#00b6ff" height={45} width={45} />
            </div>
          ) : 
          props && props.data &&  props.data.length > 0 ?
          props.viewType == "grid" ? (
            <section class="traning-listing py-3 py-md-4">
              <div class="container py-3 py-md-4">
                {appliedSearchFilter.length > 0 ? (
                  <div class="row mx-0 py-3 result-text">
                    <div class="col-12">
                      <p class="mb-0">
                        Showing result for
                        <span>"{appliedSearchFilter}"</span>
                        <button
                          onClick={() => {
                            setSearchText("");
                          }}
                          class="btn btn-clear ml-2"
                        >
                          Clear
                        </button>
                      </p>
                    </div>
                  </div>
                ) : null}
                {getData().map((item, index) => {
                  return (
                    <div class="traning-list">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="img-side-col">
                            {item.details.thumbnail ? (
                              <img
                                src={item.details.thumbnail}
                                alt={"blog-1_img" + index}
                              />
                            ) : (
                              <img
                                src="/images/image-placeholder.png"
                                alt={"blog-1_img" + index}
                              />
                            )}
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="detail-side-col">
                            <h6>{item.details.title}</h6>
                            <p>{item.details.description}</p>
                            <ul class="nav">
                              <li class="nav-lsit pr-3">
                                <div
                                  class={
                                    "file-badge " +
                                    (item.details.file_type.indexOf("doc") > -1
                                      ? "doc"
                                      : item.details.file_type == "ppt"
                                      ? "ppt"
                                      : "pdf")
                                  }
                                >
                                  {item.details.file_type.toUpperCase()}
                                </div>
                              </li>
                              <li class="nav-lsit pr-3">
                                <span class="file-size">
                                  {item.details.file_size}
                                </span>
                              </li>
                              <li class="nav-lsit px-3">
                                <a
                                  onClick={() => downloadFile(item, index)}
                                  class={
                                    "btn " +
                                    (item.details.is_allowed == 0
                                      ? "btn-lock"
                                      : "btn-download telehealth-btn")
                                  }
                                >
                                  {item.details.is_allowed == 0 ? (
                                    <i class="fas fa-lock mr-2"></i>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#E3733B"
                                    >
                                      <path d="M0 0h24v24H0z" fill="none" />
                                      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                                    </svg>
                                  )}{" "}
                                  Downlaod
                                </a>
                                {props.downloading &&
                                downloadFileIndex == index ? (
                                  <div class="ml-2 d-flex align-items-center">
                                    <Loader
                                      type="Oval"
                                      color="#00b6ff"
                                      height={20}
                                      width={20}
                                    />
                                  </div>
                                ) : null}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          ) : (
            <div class="pres-listing-area pt-3">
              {appliedSearchFilter.length > 0 ? (
                <div class="row mx-0 py-3 result-text">
                  <div class="col-12">
                    <p class="mb-0">
                      Showing result for
                      <span>"{searchText}"</span>
                      <button
                        onClick={() => {
                          setSearchText("");
                        }}
                        class="btn btn-clear ml-2"
                      >
                        Clear
                      </button>
                    </p>
                  </div>
                </div>
              ) : null}
              {getData().map((item, index) => {
                return (
                  <div class="list-row mb-2">
                    <div class="row mx-0">
                      <div class="col-md-6 mb-3 mb-md-0">
                        <ul class="nav">
                          <li class="nav-lsit pr-3">
                            <i class="fas fa-file-alt"></i>
                          </li>
                          <li class="nav-lsit pr-3 pre-title">
                            {item.details.title}
                          </li>
                        </ul>
                      </div>

                      <div class="col-md-3 mb-3 mb-md-0">
                        <ul class="nav">
                          <li class="nav-lsit px-3">
                            <div
                              class={
                                "file-badge " +
                                (item.details.file_type.indexOf("doc") > -1
                                  ? "doc"
                                  : item.details.file_type == "ppt"
                                  ? "ppt"
                                  : "pdf")
                              }
                            >
                              {item.details.file_type.toUpperCase()}
                            </div>
                          </li>
                          <li class="nav-lsit px-3">
                            <span class="file-size">
                              {item.details.file_size}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div class="col-md-3 text-md-right d-flex justify-content-end">
                        <button
                          onClick={() => downloadFile(item, index)}
                          class={
                            "btn " +
                            (item.details.is_allowed == 0
                              ? "btn-lock"
                              : "btn-download telehealth-btn")
                          }
                        >
                          {item.details.is_allowed == 0 ? (
                            <i class="fas fa-lock mr-2"></i>
                          ) : (
                            <img
                              class="mr-1"
                              src="/images/telehealth-images/download.svg"
                              alt="download-ic"
                            />
                          )}
                          Download
                        </button>
                        {props.downloading && downloadFileIndex == index ? (
                          <div class="ml-2 d-flex align-items-center">
                            <Loader
                              type="Oval"
                              color="#00b6ff"
                              height={20}
                              width={20}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )
        : <div className = "text-center bg-light">
        <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
    </div>}
          {getPages() && getPages().length > 0 ? (
            <Paginator
              pages={getPages()}
              dataLimit={dataLimit}
              setActivePage={(page) => setActivePage(page)}
              setDataLimit={(item) => setDataLimit(item)}
            />
          ) : null}
        </div>
      </section>
      <PermiumUserModal
        id="premiumFileModal"
        iconClass="fa-star"
        title="Premium Content"
        description="<p>You need to buy a membership plan to access this file.</p>"
        buttonText="Join Now"
        onClose = {()=>{
          $("#premiumFileModal").modal("hide");
          $(".modal-backdrop").remove();
      }}
        showButton={true}
        onClickBookNow={() => {
          $("#premiumFileModal").modal("hide");
          $(".modal-backdrop").remove();
          props.onNavigateToPage(`${props.eventAlias}/membership`);
        }}
      />
    </>
  );
}
const mapStateToProps = (state) => ({
  loading: state.marketPlaceReducer.loadingContent,
  loadingFilters: state.marketPlaceReducer.loadingFilters,
  data: state.marketPlaceReducer.content,
  filters: state.marketPlaceReducer.filters,
  downloading: state.marketPlaceReducer.downloading,
});
const mapDispatchToProps = (dispatch) => ({
  getData: (eventAlias, params) => dispatch(getContent(eventAlias, params)),
  getFilters: (eventAlias, params) => dispatch(getFilters(eventAlias, params)),
  downloadFile: (eventAlias, docId, name) =>
    dispatch(downloadDoc(eventAlias, docId, name)),
  applyFilters: (searchText, filters) =>
    dispatch(applyFilters(searchText, filters)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Content);
