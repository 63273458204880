import React from 'react';
import Modal from 'react-modal';
import utility from '../../../Utility/utility';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import 'bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { withRouter } from 'react-router-dom'
import validator from 'validator';
import { Constants } from '../../../Constants';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { RemoveScroll } from '../../1 - Sub menu components/Virtual Exhibition/actions/index'
export class SecondStep extends React.Component {
    delegates = []
    constructor(props) {
        super(props)
        this.state = {
            selectedDelegateIndex: 0,
            selectedDelegte: { title: "Mr", jobTitle: "" },
            tickets: this.props.tickets || [],
            showAlert: false,
            eFirstName: "",
            ePhoneNumber: "",
            eLastName: "",
            eMobileNumber: "",
            eNationality: "",
            eEmailWork: "",
            eCompanyName: "",
            eAddress1: "",
            eAddress2: "",
            eCity: "",
            eCountry: "",
            eCompanyTelephone: "",
            eCompanyWebsite: "",
            inCompletePackageIndexs: [],
            delegateInfoSaved: false,
            response: null
        }
        this.selectedPackageIndex = 0
        this.savedDelegatesCount = 0
        this.defaultDailCodes = {
            mobileNumber: "",
            phoneNumber: "",
            companyTelephone: ""
        }
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    componentDidMount() {

        console.log(this)
        $(window).on('popstate', this.removeScrollStep);
        this.defaultDailCode = Constants.defaultCountry.dailCode
        this.savedDelegatesCount = 0

        let height = document.getElementById('stickyMenu').offsetHeight + 20
        $('html, body').animate({
            scrollTop: $("#order-summary").offset().top - height
        }, 800);
        $('#firstName').bind('keypress', this.testInput);
        $('#lastName').bind('keypress', this.testInput);
        this.setPackage()
    }
    removeScrollStep = () => {
        this.props.RemoveScroll(0)



    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.order != prevProps.order) {
            this.setPackage()
        }
        if (this.props.delegateInfoSave == true) {
            this.OnDelegateInfoSaved()
            this.props.onSecondStep()
        }
    }
    setPackage() {
        this.packages = this.props.order.packages
        if (this.props.packageToEdit > -1) {
            $('#delegate_modal').modal('show');
            setTimeout(() => {
                $('body').addClass('modal-open')
            }, 500);
            this.OnClickEnterDelegate(this.props.packageToEdit)
        }
        this.totalDelegates = 0
        for (var i = 0; i < this.packages.length; i++) {
            this.totalDelegates += parseInt(this.packages[i].numberOfDelegates)
        }
    }
    OnContinueClick = () => {
        var completedDelegatesCount = 0
        var singlePackageDelegateInfoCount = 0
        this.setState({ inCompletePackageIndexs: [] })
        for (var i = 0; i < this.packages.length; i++) {
            singlePackageDelegateInfoCount = 0
            for (var j = 0; j < this.packages[i].delegates.length; j++) {
                if (this.packages[i].delegates[j] != undefined) {
                    if (this.packages[i].delegates[j].infoCompleted) {
                        singlePackageDelegateInfoCount += 1
                        completedDelegatesCount += 1
                    }
                }
            }
            if (singlePackageDelegateInfoCount != this.packages[i].numberOfDelegates) {
                var inCompletePackageIndexs = this.state.inCompletePackageIndexs
                inCompletePackageIndexs.push(i)
                this.setState({ inCompletePackageIndexs }, () => {
                })
                $('#shake-div' + i).addClass('shake-horizontal');
            }
        }
        // var updatedOrder = this.props.order
        // updatedOrder.packages = this.packages
        // var data = {
        //     order : updatedOrder
        // }
        // this.props.onProceed(data)
        // return
        if (this.totalDelegates == 0) {
            this.alertMessage = "No delegates selected"
            this.setState({ showAlert: true })
            return
        }
        if (completedDelegatesCount == this.totalDelegates) {
            for (var i = 0; i < this.packages.length; i++) {
                for (var j = 0; j < this.packages[i].delegates.length; j++) {
                    if (this.packages[i].delegates[j].phoneNumber.length < 5) {
                        this.packages[i].delegates[j].phoneNumber = ""
                    }
                    if (this.packages[i].delegates[j].companyTelephone.length < 5) {
                        this.packages[i].delegates[j].companyTelephone = ""
                    }
                }
            }
            var updatedOrder = this.props.order
            updatedOrder.packages = this.packages
            var data = {
                order: updatedOrder
            }
            this.props.onProceed(data)
        }
        else {
            // this.alertMessage = "Please complete all delegate(s) info"
            // this.setState({showAlert : true})

        }
    }

    OnClickSave = (type) => {
        var delegateItem = this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex]
        this.completeInformationText = "Please fill all information"
        var infoCompleted = true
        if (delegateItem == null) {
            delegateItem = {}
        }
        delegateItem.title = delegateItem.title == null ? "Mr" : delegateItem.title
        delegateItem.firstName = delegateItem.firstName == null ? "" : delegateItem.firstName
        delegateItem.lastName = delegateItem.lastName == null ? "" : delegateItem.lastName
        delegateItem.phoneNumber = delegateItem.phoneNumber == null ? "" : delegateItem.phoneNumber
        delegateItem.mobileNumber = delegateItem.mobileNumber == null ? "" : delegateItem.mobileNumber
        delegateItem.nationality = delegateItem.nationality == null ? "" : delegateItem.nationality
        delegateItem.emailWork = delegateItem.emailWork == null ? "" : delegateItem.emailWork
        delegateItem.companyName = delegateItem.companyName == null ? "" : delegateItem.companyName
        delegateItem.address1 = delegateItem.address1 == null ? "" : delegateItem.address1
        delegateItem.city = delegateItem.city == null ? "" : delegateItem.city
        delegateItem.jobTitle = delegateItem.jobTitle == null ? "" : delegateItem.jobTitle
        delegateItem.state = delegateItem.state == null ? "" : delegateItem.state
        delegateItem.address2 = delegateItem.address2 == null ? "" : delegateItem.address2
        delegateItem.zipCode = delegateItem.zipCode == null ? "" : delegateItem.zipCode
        delegateItem.companyTelephone = delegateItem.companyTelephone == null ? "" : delegateItem.companyTelephone
        delegateItem.companyVatNo = delegateItem.companyVatNo == null ? "" : delegateItem.companyVatNo
        delegateItem.companyWebsite = delegateItem.companyWebsite == null ? "" : delegateItem.companyWebsite
        delegateItem.country = delegateItem.country == null || delegateItem.country == "" ? "" : delegateItem.country
        delegateItem.packageIndex = this.selectedPackageIndex
        var animated = false
        if (delegateItem.firstName.trim() == "") {
            infoCompleted = false
            if (!animated) {
                $('#delegate_modal').animate({
                    scrollTop: $("#firstName").offset().top
                }, 800);
                animated = true
            }
            this.setState({ eFirstName: "enter valid name" })
        }
        else {
            this.setState({ eFirstName: "" })
        }
        if (delegateItem.phoneNumber != this.defaultDailCodes.phoneNumber) {
            if (!utility.validatePhone(delegateItem.phoneNumber)) {
                infoCompleted = false
                if (!animated) {
                    $('#delegate_modal').animate({
                        scrollTop: $("#phoneNumber").offset().top
                    }, 800);
                    animated = true
                }

                this.setState({ ePhoneNumber: 'enter valid phone' })
            }
            else {
                this.setState({ ePhoneNumber: '' })
            }
        }
        else {
            this.setState({ ePhoneNumber: '' })
        }
        if (delegateItem.lastName.trim() == "") {
            infoCompleted = false
            if (!animated) {
                $('#delegate_modal').animate({
                    scrollTop: $("#lastName").offset().top
                }, 800);
                animated = true
            }
            this.setState({ eLastName: "enter valid name" })
        }
        else {
            this.setState({ eLastName: "" })
        }
        if (!utility.validatePhone(delegateItem.mobileNumber)) {
            infoCompleted = false
            if (!animated) {
                $('#delegate_modal').animate({
                    scrollTop: $("#mobileNumber").offset().top
                }, 800);
                animated = true
            }

            this.setState({ eMobileNumber: 'enter valid phone' })
        }
        else {
            this.setState({ eMobileNumber: '' })
        }
        if (!utility.validateEmail(delegateItem.emailWork)) {
            infoCompleted = false
            if (!animated) {
                $('#delegate_modal').animate({
                    scrollTop: $("#emailWork").offset().top
                }, 800);
                animated = true
            }
            this.setState({ eEmailWork: "enter valid name" })
        }
        else {
            this.setState({ eEmailWork: "" })
        }
        // if(delegateItem.nationality == ""){
        //     infoCompleted = false
        //     if(!animated){
        //         $('#delegate_modal').animate({
        //             scrollTop: $("#nationality").offset().top
        //         }, 800);
        //         animated = true
        //     }
        //     this.setState({eNationality : "enter valid nationality"})
        // }
        // else{
        //     this.setState({eNationality : ""})
        // }
        if (delegateItem.companyName == "") {
            infoCompleted = false
            if (!animated) {
                $('#delegate_modal').animate({
                    scrollTop: $("#companyName").offset().top
                }, 800);
                animated = true
            }
            this.setState({ eCompanyName: "enter valid name" })
        }
        else {
            this.setState({ eCompanyName: "" })
        }
        // if(delegateItem.address1 == ""){
        //     infoCompleted = false
        //     if(!animated){
        //         $('#delegate_modal').animate({
        //             scrollTop: $("#address1").offset().top
        //         }, 800);
        //         animated = true
        //     }
        //     this.setState({eAddress1 : "enter valid name"})
        // }
        // else{
        //     this.setState({eAddress1 : ""})
        // }
        // if(delegateItem.city == ""){
        //     infoCompleted = false
        //     if(!animated){
        //         $('#delegate_modal').animate({
        //             scrollTop: $("#city").offset().top
        //         }, 800);
        //         animated = true
        //     }
        //     this.setState({eCity : "enter valid name"})
        // }
        // else{
        //     this.setState({eCity : ""})
        // }
        if (delegateItem.country == "") {
            infoCompleted = false
            if (!animated) {
                $('#delegate_modal').animate({
                    scrollTop: $("#country").offset().top + 50
                }, 800);
                animated = true
            }

            this.setState({ eCountry: "enter valid name" })
        }
        else {
            this.setState({ eCountry: "" })
        }
        if (delegateItem.companyTelephone != this.defaultDailCodes.companyTelephone) {
            if (!utility.validatePhone(delegateItem.companyTelephone)) {
                infoCompleted = false
                if (!animated) {
                    $('#delegate_modal').animate({
                        scrollTop: $("#companyTelephone").offset().top
                    }, 800);
                    animated = true
                }

                this.setState({ eCompanyTelephone: 'enter valid phone' })
            }
            else {
                this.setState({ eCompanyTelephone: '' })
            }
        }
        else {
            this.setState({ eCompanyTelephone: '' })
        }
        if (delegateItem.companyWebsite != "") {
            if (!utility.validateUrl(delegateItem.companyWebsite)) {
                infoCompleted = false
                $('#delegate_modal').animate({
                    scrollTop: $("#companyWebsite").offset().top
                }, 800);
                this.setState({ eCompanyWebsite: 'enter valid phone' })
            }
            else {
                this.setState({ eCompanyWebsite: '' })
            }
        }
        else {
            this.setState({ eCompanyWebsite: '' })
        }
        // if
        if (type == "finish") {
            if (infoCompleted == true) {
                this.completeInformationText = ""
                delegateItem.infoCompleted = true
                $('#delegate_modal').animate({ scrollTop: 150 }, 'slow');
                this.props.onUpdateOrder(this.props.order)
            }
        }
        else {
            if (infoCompleted == true) {
                delegateItem.infoCompleted = true
                this.completeInformationText = ""
                $('#delegate_modal').animate({ scrollTop: 150 }, 'slow');
                this.props.onUpdateOrder(this.props.order, false)
            }
            else {
                delegateItem.infoCompleted = false
                this.completeInformationText = ""
                $('#delegate_modal').animate({ scrollTop: 150 }, 'slow');
                this.props.onUpdateOrder(this.props.order, false)
            }
        }
    }
    OnChangeValue = (key, event, country = null) => {
        if (event.target == null) {
            return
        }
        var selectedDelegte = this.state.selectedDelegte
        selectedDelegte[key] = event.target.value
        this.setState({ selectedDelegte })

    }
    OnFocus = (key, e, country) => {
        this.defaultDailCodes[key] = "+" + country.dialCode
    }
    OnValueSubmitted = (key, event) => {

        var delegateItem = this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex] || {}
        if (delegateItem.id == null) {
            delegateItem.id = utility.makeid(12)
        }
        if (event)
            delegateItem[key] = event.target.value
        this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex] = delegateItem
        this.props.onUpdatePackageInfo(this.packages)
    }
    OnChangeSelector = (key, id) => {
        var value = document.getElementById(id).value
        var selectedDelegte = this.state.selectedDelegte
        var delegateItem = this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex] || {}

        selectedDelegte[key] = delegateItem[key] = value

        this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex] = delegateItem
        this.setState({ selectedDelegte })
    }

    OnClickEnterDelegate = (index) => {
        setTimeout(() => {
            $('body').addClass('modal-open')
        }, 250);
        console.log(this.packages)
        document.getElementById('delegate_modal').style.display = 'block'
        this.selectedPackageIndex = index
        var delegateIndex = this.packages[this.selectedPackageIndex].selectedDelegateIndex
        if (delegateIndex == null) {
            delegateIndex = 0
        }
        this.setState({ selectedDelegateIndex: delegateIndex })
        if (this.packages[index].delegates.length > 0) {
            var selectedDelegte = this.packages[index].delegates[delegateIndex]
            this.packages[this.selectedPackageIndex].selectedDelegateIndex = 0
            if (selectedDelegte == null) {
                selectedDelegte = {}
            }
            if (selectedDelegte.phoneNumber == this.defaultDailCode) {
                this.defaultDailCodes.phoneNumber = this.defaultDailCode
            }
            if (selectedDelegte.companyTelephone == this.defaultDailCode) {
                this.defaultDailCodes.companyTelephone = this.defaultDailCode
            }
            if (selectedDelegte.phoneNumber == "") {
                selectedDelegte.phoneNumber = this.defaultDailCode
                this.defaultDailCodes.phoneNumber = this.defaultDailCode
            }
            if (selectedDelegte.mobileNumber == "") {
                selectedDelegte.mobileNumber = this.defaultDailCode
                this.defaultDailCodes.mobileNumber = this.defaultDailCode
            }
            if (selectedDelegte.companyTelephone == "") {
                selectedDelegte.companyTelephone = this.defaultDailCode
                this.defaultDailCodes.companyTelephone = this.defaultDailCode
            }
            this.setState({ selectedDelegte }, () => {
            })
        }
        else {
            selectedDelegte = {}
            // this.defaultDailCodes = {
            //     mobileNumber : "",
            //     phoneNumber : "",
            //     companyTelephone : ""
            // }
            // selectedDelegte.phoneNumber = this.defaultDailCode
            // selectedDelegte.mobileNumber = this.defaultDailCode
            // selectedDelegte.companyTelephone = this.defaultDailCode

            this.setState({ selectedDelegte: selectedDelegte })
        }
        this.defaultDelegateIndex = delegateIndex
        this.props.onPressEnterDelegate(index)
        if (this.packages[this.selectedPackageIndex].delegates[0] != null) {
            if (this.packages[this.selectedPackageIndex].delegates[0].infoCompleted) {
                this.firstDelegate = Object.assign({}, this.packages[this.selectedPackageIndex].delegates[delegateIndex])
            }
        } else {
            this.firstDelegate = null
        }
    }
    OnClickDelegateItem = (index) => {
        // if(this.firstDelegate != null){
        //     this.packages[this.selectedPackageIndex].delegates.splice(this.defaultDelegateIndex,1,this.firstDelegate)
        // }
        this.packages[this.selectedPackageIndex].selectedDelegateIndex = index
        var selectedDelegte = this.packages[this.selectedPackageIndex].delegates[index] == null ? {} : this.packages[this.selectedPackageIndex].delegates[index]
        var delegateItem = this.packages[this.selectedPackageIndex].delegates[index] || {}
        if (delegateItem.phoneNumber == null) {
            this.defaultDailCodes['phoneNumber'] = this.defaultDailCode
        }
        if (delegateItem.companyTelephone == null) {
            this.defaultDailCodes['companyTelephone'] = this.defaultDailCode
        }
        selectedDelegte.title = delegateItem.title || "Mr"
        selectedDelegte.jobTitle = delegateItem.jobTitle || ""
        selectedDelegte.firstName = delegateItem.firstName || ""
        selectedDelegte.lastName = delegateItem.lastName || ""
        selectedDelegte.phoneNumber = delegateItem.phoneNumber || this.defaultDailCode
        selectedDelegte.nationality = delegateItem.nationality || ""
        selectedDelegte.emailWork = delegateItem.emailWork || ""
        selectedDelegte.mobileNumber = delegateItem.mobileNumber || this.defaultDailCode
        selectedDelegte.companyName = delegateItem.companyName || ""
        selectedDelegte.country = delegateItem.country || ""
        selectedDelegte.state = delegateItem.state || ""
        selectedDelegte.address1 = delegateItem.address1 || ""
        selectedDelegte.address2 = delegateItem.address2 || ""
        selectedDelegte.city = delegateItem.city || ""
        selectedDelegte.zipCode = delegateItem.zipCode || ""
        selectedDelegte.companyTelephone = delegateItem.companyTelephone || this.defaultDailCode
        selectedDelegte.companyVatNo = delegateItem.companyVatNo || ""
        selectedDelegte.companyWebsite = delegateItem.companyWebsite || ""
        selectedDelegte.useCompnayInfo = delegateItem.useCompnayInfo || false
        this.packages[this.selectedPackageIndex].delegates[index] = selectedDelegte
        this.setState({
            selectedDelegateIndex: index,
            selectedDelegte: selectedDelegte,
            eLastName: "",
            eFirstName: "",
            eCountry: "",
            eEmailWork: "",
            eMobileNumber: "",
            ePhoneNumber: "",
            eNationality: "",
            eCompanyName: "",
            eCity: "",
            eAddress1: "",
            eCompanyTelephone: "",
            eCompanyWebsite: ""
        })
        this.props.onClickDelegateItem(index)
    }
    OnClickBack = () => {
        this.props.onClickGoBack()
    }
    OnHandleCloseAlert = () => {
        this.setState({ showAlert: false })
    }
    OnUseCompanyDetailCheck = () => {
        var val = document.getElementById("sameCompanyCheck").checked
        var selectedDelegate = this.state.selectedDelegte
        selectedDelegate.useCompnayInfo = val

        var delegateItem = this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex] || {}
        delegateItem.useCompnayInfo = val

        if (val) {
            var previousDelegate = this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex - 1]
            var currentDelegate = this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex]

            selectedDelegate.companyName = delegateItem.companyName = previousDelegate == null ? '' : previousDelegate.companyName
            selectedDelegate.state = delegateItem.state = previousDelegate == null ? '' : previousDelegate.state
            selectedDelegate.address1 = delegateItem.address1 = previousDelegate == null ? '' : previousDelegate.address1
            selectedDelegate.address2 = delegateItem.address2 = previousDelegate == null ? '' : previousDelegate.address2
            selectedDelegate.city = delegateItem.city = previousDelegate == null ? '' : previousDelegate.city
            selectedDelegate.country = delegateItem.country = previousDelegate == null ? this.props.countries[0] : previousDelegate.country
            selectedDelegate.zipCode = delegateItem.zipCode = previousDelegate == null ? '' : previousDelegate.zipCode
            selectedDelegate.companyTelephone = delegateItem.companyTelephone = previousDelegate == null ? '' : previousDelegate.companyTelephone
            selectedDelegate.companyVatNo = delegateItem.companyVatNo = previousDelegate == null ? '' : previousDelegate.companyVatNo
            selectedDelegate.companyWebsite = delegateItem.companyWebsite = previousDelegate == null ? '' : previousDelegate.companyWebsite
            if (selectedDelegate.companyTelephone == '') {
                selectedDelegate.companyTelephone = this.defaultDailCode
            }
            this.packages[this.selectedPackageIndex].delegates[this.state.selectedDelegateIndex] = delegateItem
        }
        this.setState({ selectedDelegate })
    }
    OnClickCloseDelegateModal = (moveNext = false) => {
        var singlePackageDelegateInfoCount = 0
        for (var i = 0; i < this.packages.length; i++) {
            singlePackageDelegateInfoCount = 0
            for (var j = 0; j < this.packages[i].delegates.length; j++) {
                if (this.packages[i].delegates[j] != null && this.packages[i].delegates[j].infoCompleted) {
                    singlePackageDelegateInfoCount += 1
                }
            }
            if (singlePackageDelegateInfoCount == this.packages[i].numberOfDelegates) {
                var inCompletePackageIndexs = this.state.inCompletePackageIndexs
                if (inCompletePackageIndexs.indexOf(i) > -1) {
                    inCompletePackageIndexs.splice(i, 1)
                    this.setState({ inCompletePackageIndexs })
                }
            }
        }
        $("#delegate_modal").modal('hide')
        this.props.RemoveScroll(0)
        $('body').removeClass('modal-open')
        $('.modal-backdrop').remove()
        if (moveNext) {
            this.OnContinueClick()
        }
    }
    renderCountryOptions = () => {
        var options = []
        for (var i = 0; i < this.props.countries.length; i++) {
            if (i == 0) {
                options.push(<option value="" selected disabled>{this.props.translation.select}</option>)
            } else {
                options.push(<option>{this.props.countries[i]}</option>)
            }
        }
        return options
    }
    renderNationalityOptions = () => {
        var options = []
        for (var i = 0; i < this.props.nationalities.length; i++) {
            if (i == 0) {
                options.push(<option value="" selected disabled>{this.props.translation.select}</option>)
            } else {
                options.push(<option>{this.props.nationalities[i]}</option>)
            }
        }
        return options
    }
    OnDelegateInfoSaved = () => {
        this.savedDelegatesCount += 1
        if (this.state.selectedDelegateIndex < this.packages[this.selectedPackageIndex].numberOfDelegates - 1) {
            this.setState({ delegateInfoSaved: true, response: this.props.response })
            setTimeout(() => {
                this.setState({ delegateInfoSaved: false, response: null })
            }, 1500);
            // $('#delegate_modal').animate({ scrollTop: 0 }, 'slow');
            this.OnClickDelegateItem(this.state.selectedDelegateIndex + 1)
        } else {
            this.OnClickCloseDelegateModal(this.totalDelegates == this.savedDelegatesCount)
        }
    }
    testInput(event) {
        var value = String.fromCharCode(event.which);
        var pattern = new RegExp(/(fiktiv)|[^0-9]/g);
        return pattern.test(value);
    }
    renderDelegatesTab = () => {
        return (
            <ul class="nav nav-tabs aganda_tab delegate_info_modal" role="tablist">
                {
                    this.props.order.packages.length > 0 ?
                        this.props.order.packages[this.selectedPackageIndex].delegatesInfo.map((item, index) => {
                            return (
                                <li class="nav-item">
                                    <a class={index == this.state.selectedDelegateIndex ? "nav-link bookingTab active" : "nav-link bookingTab"} data-toggle="tab" href="" onClick={() => this.OnClickDelegateItem(index)}>{this.props.event.is_marketplace == 1 ? 'Member ' : 'Delegate'} {item}</a>
                                </li>
                            )
                        }) : null}
            </ul>
        )
    }
    render() {
        return (
            <div>
                <section class="order-summery mt-5 mb-2">
                    <div class="container">
                        <div class="row">
                            <div class="summery-hd bg-light py-3 w-100">
                                <h6 class="px-4 f-500 text-uppercase mb-0"><Trans>orderSummary</Trans></h6>
                            </div>
                            {this.props.order.packages.filter(x => x.isActive == 1).length == 0 ?
                                <div style={{ marginTop: 10, marginBottom: 10, marginLeft: 5 }}><Trans>noDelegates</Trans></div> :
                                this.props.order.packages.map((item, index) => {
                                    if (item.numberOfDelegates == 0) {
                                        return null
                                    }
                                    return (
                                        // <div style = {{width : '100%',height : 150,alignItems : 'center'}} class = {index == this.props.order.packages.length - 1 && index != 0 ? "row border-top" : "row"}>
                                        <>
                                            <div class="col-4 col-md-8 px-2 px-md-5 py-2">
                                                {/* <div style = {{marginLeft : 30}}> */}
                                                <p class="f-600 mb-1">{item.title} <a class="f-500 mb-1 ml-2">{this.props.selectedCurrency.symbol + item.price + " ( x " + item.numberOfDelegates + " )"}</a> </p>
                                                <br /><p id={"shake-div" + index} class="f-500 mb-1 delegate_link">
                                                    <a onClick={() => {
                                                        this.props.RemoveScroll(1)
                                                        this.OnClickEnterDelegate(index)
                                                    }} href="#" data-toggle="modal" data-target="#delegate_modal">{this.props.event.is_marketplace == 1 ? 'Enter Member Details' : <Trans>enterDelegatesDetails</Trans>}</a></p>
                                                {this.state.inCompletePackageIndexs.indexOf(index) > -1 ?
                                                    <span id="click" class="text-danger"><Trans>clickOnLink</Trans></span> : null}
                                            </div>
                                            <div class="col-8 col-md-4 py-2 px-2 px-md-5 float-right">
                                                <table class="table table-borderless price_table">
                                                    <tr>
                                                        <td class="f-td" align="right"><Trans>basePrice</Trans></td>
                                                        <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + item.basePrice}</td>
                                                    </tr>
                                                    {item.showMultiDelegateDiscount ?
                                                        <tr>
                                                            <td class="f-td" align="right"><Trans>multiDelagates</Trans>{this.props.multiDiscountPercent}%: </td>
                                                            <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + item.multiDelegateDiscount}</td>
                                                        </tr> : null}
                                                    {item.showVipDiscount ?
                                                        <tr>
                                                            <td class="f-td" align="right">{this.props.discountName} {this.props.vipDiscountPercent}%: </td>
                                                            <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + item.vipDiscount}</td>
                                                        </tr> : null}
                                                    <tr>
                                                        <td class="f-td f-500" align="right"><Trans>subTotal</Trans></td>
                                                        <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + item.subTotal}</td>
                                                    </tr>

                                                </table>
                                            </div>
                                        </>
                                        // </div>
                                    )
                                })}

                        </div>
                        {this.props.order.packages.filter(x => x.isActive == 1).length > 0 ?
                            <div class="row justify-content-end border-top" >

                                <div class="col-8 col-md-4 pt-2 pr-0 px-md-5">
                                    {/* <div> */}
                                    <table class="table table-borderless price_table total_price">

                                        <tr>
                                            <td align="right" class="f-600 f-td"><Trans>newSubTotal</Trans> </td>
                                            <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + this.props.order.newSubTotal}</td>
                                        </tr>

                                        <tr>
                                            <td align="right" class="f-600 f-td">VAT @ {this.props.order.vatPercent}%: </td>
                                            <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + this.props.order.vatDiscount}</td>
                                        </tr>

                                        <tr>
                                            <td align="right" class="f-600 f-td"><Trans>totalPrice</Trans></td>
                                            <td class="f-600 pl-3">{this.props.selectedCurrency.symbol + this.props.order.totalPrice}</td>
                                        </tr>

                                    </table>
                                    {/* </div> */}
                                </div>
                            </div> : null}

                    </div>
                </section>

                <section class="order-form py-3">

                    <div class="container">

                        <div class="row d-none-mob">

                            <div class="col-md-6 mb-3 mb-sm-0">
                                <a onClick={this.OnClickBack} style={{ cursor: 'pointer' }} class="btn btn-default f-600">
                                    <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans>
                                </a>
                            </div>

                            <div class="col-md-6 text-sm-right">
                                <a onClick={this.OnContinueClick} style={{ cursor: 'pointer' }} class="btn btn-default f-600">
                                    <Trans>continue</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                </a>
                            </div>

                        </div>

                        <div class="row d-block d-md-none">

                            <div class="col-12 mb-4 mb-sm-0 text-center">

                                <a onClick={this.OnClickBack} style={{ cursor: 'pointer', marginRight: 5 }} class="btn btn-default f-600">
                                    <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans>
                                </a>

                                <a onClick={this.OnContinueClick} style={{ cursor: 'pointer', marginLeft: 5 }} class="btn btn-default f-600">
                                    <Trans>continue</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                </a>

                            </div>

                        </div>

                    </div>

                </section>
                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    isOpen={this.state.showAlert}
                    onRequestClose={this.OnHandleCloseAlert}
                    style={{ overlay: { zIndex: 1000 } }}
                >
                    <div className="modal-content">
                        <div class="modal-header border-0">
                            <h4 class="modal-title">{this.alertMessage}</h4>
                            <button type="button" onClick={() => {
                                this.OnHandleCloseAlert()
                            }} class="close" data-dismiss="modal">&times;</button>
                        </div>
                    </div>
                </Modal>
                <div>
                    {/* <div ref = "modal" class="modal fade" id="alert_modal">
                    <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header border-0">
                            <h4 class="modal-title">Please enter delegate information</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                    </div>
                </div>
                </div> */}
                    <div class="modal fade" id="delegate_modal" data-backdrop="static" data-keyboard="false" style={{ overflow: "scroll !important" }}>                    <div class="modal-dialog modal-xl" >
                        <div class="modal-content" id="inner_delegate_modal">
                            {this.props.savingDelegate || this.state.delegateInfoSaved ?
                                <div style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: 99, height: '100%', background: '#000000ab' }}>
                                    {this.props.savingDelegate ?
                                        <>
                                            <Loader
                                                type='Oval'
                                                color="#00b6ff"
                                                height={50}
                                                width={50}
                                            />
                                            <div style={{ marginTop: 20, color: '#fff' }}><Trans>savingDelegate</Trans></div>
                                        </> :
                                        this.state.delegateInfoSaved && this.state.response != null ?
                                            <>
                                                <div class="success-icon mt-3">
                                                    {this.state.response.error ? <img src="/images/icons/close.svg" /> : <img src="/images/icons/right.svg" />}
                                                </div>
                                                <div style={{ marginTop: 20, color: '#fff' }}>{this.state.response.message}</div>
                                            </> : null}
                                </div> : null}
                            <div class="modal-header border-0">
                                <h4 class="modal-title">{this.packages != null ? this.packages[this.selectedPackageIndex] != null ? this.packages[this.selectedPackageIndex].title : '' : ""}</h4>
                                <button type="button" class="close" onClick={() => {
                                    this.props.RemoveScroll(0)
                                    this.OnClickSave("cancel")
                                    this.OnClickCloseDelegateModal(false)
                                }}>&times;</button>
                            </div>
                            <div class="modal-body">
                                {!isMobile ?
                                    <PerfectScrollbar style={{ height: 'auto', minHeight: 0, maxHeight: 200 }}>
                                        <this.renderDelegatesTab />
                                    </PerfectScrollbar> :
                                    <div style={{ overflowY: 'scroll', minHeight: 0, maxHeight: 200 }}>
                                        <this.renderDelegatesTab />
                                    </div>}
                                <div class="tab-content">
                                    <div id="home" class="container tab-pane active">
                                        <section class="order-form py-3">
                                            <div class="container">
                                                <div class="row mt-3 bg-light px-3 py-3 border">

                                                    <div class="form-group col-md-12 mt-3">
                                                        <h7 className="f-500 text-red" style={{ position: 'absolute', top: -25 }}>{this.completeInformationText}</h7>
                                                        <h6 class="text-uppercase f-600">{this.props.event.is_marketplace == 1 ? "Member " : <Trans>delegate</Trans>} {this.state.selectedDelegateIndex + 1}<Trans>details</Trans></h6>

                                                        <p class="f-14"><Trans>allWorkRequired</Trans><span class="text-red f-600">( <i class="fas fa-star-of-life"></i> )</span><Trans>infoBelow</Trans></p>

                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className="bookingLabel"> <Trans>title</Trans> <i class="fas fa-star-of-life text-red"></i></label>
                                                        <select onChange={() => this.OnChangeSelector('title', 'titleSelector')} value={this.state.selectedDelegte.title == null ? "Mr" : this.state.selectedDelegte.title} class="form-control rounded-0 selector" id="titleSelector">
                                                            <option>Mr</option>
                                                            <option>Mrs</option>
                                                            <option>Ms</option>
                                                            <option>Dr</option>
                                                            <option>Prof</option>
                                                            <option>Sheikh</option>
                                                            <option>Sheikha</option>
                                                            <option>Sir</option>
                                                            <option>Brig</option>
                                                            <option>Gen</option>
                                                            <option>Adm</option>
                                                            <option>Amb</option>
                                                            <option>Brig Gen</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label><Trans>jobTitle</Trans></label>
                                                        <input
                                                            onChange={(text) => this.OnChangeValue('jobTitle', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('jobTitle', event)}
                                                            value={this.state.selectedDelegte.jobTitle == null ? "" : this.state.selectedDelegte.jobTitle}
                                                            class="form-control rounded-0" />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={` bookingLabel ${this.state.eFirstName != "" ? "text-red" : ""}`} > <Trans>firstName</Trans> <i class="fas fa-star-of-life text-red"></i></label>
                                                        <input
                                                            id="firstName"
                                                            onKeyDown={(e) => {
                                                                let specialCharacter = ["~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "<", ">", ",", ".", "?", "/", "", ":", ";", "'"]
                                                                let keyCode = e.which || e.keyCode
                                                                if (specialCharacter.includes(e.key)) {
                                                                    e.preventDefault();
                                                                    return false
                                                                }
                                                                else {
                                                                    return true
                                                                }

                                                            }}
                                                            onChange={(text) => this.OnChangeValue('firstName', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('firstName', event)}
                                                            value={this.state.selectedDelegte.firstName == null ? "" : this.state.selectedDelegte.firstName}
                                                            class={this.state.eFirstName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={`bookingLabel ${this.state.eLastName != "" ? "text-red" : ""}`}><Trans>lastName</Trans> <i class="fas fa-star-of-life text-red"></i></label>
                                                        <input
                                                            id="lastName"
                                                            onKeyDown={(e) => {
                                                                let specialCharacter = ["~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "<", ">", ",", ".", "?", "/", "", ":", ";", "'"]
                                                                let keyCode = e.which || e.keyCode
                                                                if (specialCharacter.includes(e.key)) {
                                                                    e.preventDefault();
                                                                    return false
                                                                }
                                                                else {
                                                                    return true
                                                                }

                                                            }}
                                                            onChange={(text) => this.OnChangeValue('lastName', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('lastName', event)}
                                                            value={this.state.selectedDelegte.lastName == null ? "" : this.state.selectedDelegte.lastName}
                                                            class={this.state.eLastName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>
                                                    <div id="phoneNumber" class="form-group col-md-6">
                                                        <label> <Trans>phNumber</Trans></label>
                                                        {/* <input
                                                    id = "phoneNumber"
                                                    onChange = {(text)=>this.OnChangeValue('phoneNumber',text)} 
                                                    onBlur = {(event)=>this.OnValueSubmitted('phoneNumber',event)} 
                                                    value = {this.state.selectedDelegte.phoneNumber == null ? "" : this.state.selectedDelegte.phoneNumber}
                                                    class={this.state.ePhoneNumber != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/> */}
                                                        <PhoneInput
                                                            inputStyle={{ width: '99.5%', marginLeft: 2, height: 'calc(2.25rem + 2px)', borderRadius: 0 }}
                                                            inputClass={this.state.ePhoneNumber != "" ? "text-red-border" : "phone-picker"}
                                                            id="phone"
                                                            country={Constants.defaultCountry.code}
                                                            value={this.state.selectedDelegte.phoneNumber == null ? "" : this.state.selectedDelegte.phoneNumber}
                                                            onChange={(phone, country, e) => this.OnChangeValue('phoneNumber', e, country)}
                                                            onBlur={(e) => this.OnValueSubmitted('phoneNumber', e)}
                                                            jumpCursorToEnd
                                                            countryCodeEditable={false}
                                                            onFocus={(phone, country, e) => this.OnFocus('phoneNumber', phone, country)}
                                                            autocompleteSearch={false}
                                                        />
                                                    </div>
                                                    <div id="mobileNumber" class="form-group col-md-6">
                                                        <label className={` bookingLabel ${this.state.eMobileNumber != "" ? "text-red" : ""}`}><Trans>mobNumber</Trans> <i class="fas fa-star-of-life text-red"></i></label>
                                                        {/* <input
                                                    id = "mobileNumber"
                                                    onChange = {(text)=>this.OnChangeValue('mobileNumber',text)} 
                                                    onBlur = {(event)=>this.OnValueSubmitted('mobileNumber',event)} 
                                                    value = {this.state.selectedDelegte.mobileNumber == null ? "" : this.state.selectedDelegte.mobileNumber} 
                                                    class={this.state.eMobileNumber != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/> */}
                                                        <PhoneInput
                                                            inputStyle={{ width: '99.5%', marginLeft: 2, height: 'calc(2.25rem + 2px)', borderRadius: 0 }}
                                                            inputClass={this.state.eMobileNumber != "" ? "text-red-border" : "phone-picker"}
                                                            id="phone"
                                                            country={Constants.defaultCountry.code}
                                                            countryCodeEditable={false}
                                                            value={this.state.selectedDelegte.mobileNumber == null ? "" : this.state.selectedDelegte.mobileNumber}
                                                            onChange={(phone, country, e) => this.OnChangeValue('mobileNumber', e, country)}
                                                            onBlur={(e) => this.OnValueSubmitted('mobileNumber', e)}
                                                            autocompleteSearch={false}
                                                        />
                                                    </div>

                                                    <div id="nationality" class="form-group col-md-6">
                                                        <label className={this.state.eNationality != "" ? "text-red" : ""}><Trans>nationality</Trans></label>
                                                        {/* <input
                                                    id = "nationality"
                                                    onChange = {(text)=>this.OnChangeValue('nationality',text)} 
                                                    onBlur = {(event)=>this.OnValueSubmitted('nationality',event)} 
                                                    value = {this.state.selectedDelegte.nationality == null ? "" : this.state.selectedDelegte.nationality} 
                                                    class={this.state.eNationality != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/> */}
                                                        <select onChange={() => this.OnChangeSelector('nationality', 'nationalitySelector')} value={this.state.selectedDelegte.nationality == null ? "" : this.state.selectedDelegte.nationality} class={this.state.eNationality != "" ? "form-control rounded-0 selector text-red-border" : "form-control rounded-0 selector"} id="nationalitySelector">
                                                            {this.renderNationalityOptions()}
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={` bookingLabel ${this.state.eEmailWork != "" ? "text-red" : ""}`}><Trans>workEmail</Trans> <i class="fas fa-star-of-life text-red"></i></label>
                                                        <input
                                                            id="emailWork"
                                                            onChange={(text) => {
                                                                this.OnChangeValue('emailWork', text)
                                                            }}
                                                            onKeyDown={(e) => {
                                                                let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                                                let keyCode = e.which || e.keyCode
                                                                if (keyCode == 32 || upperCaseLetter.includes(e.key)) {
                                                                    e.preventDefault();
                                                                    return false
                                                                }
                                                                else {
                                                                    return true
                                                                }

                                                            }}
                                                            onBlur={(event) => this.OnValueSubmitted('emailWork', event)}
                                                            value={this.state.selectedDelegte.emailWork == null ? "" : this.state.selectedDelegte.emailWork}
                                                            class={this.state.eEmailWork != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>

                                                    <div class="form-group col-md-12 mt-3">
                                                        <h6 class="text-uppercase f-600"><Trans>companyDetails</Trans></h6>
                                                        {this.state.selectedDelegateIndex > 0 ?
                                                            <label class="form-check-label check-cont mr-4">
                                                                <input checked={this.state.selectedDelegte.useCompnayInfo} onChange={this.OnUseCompanyDetailCheck} id="sameCompanyCheck" class="form-check-input" type="checkbox" />
                                                                <Trans>same</Trans>
                                                                <span class="checkmark"></span>
                                                            </label> : null}
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={` bookingLabel ${this.state.eCompanyName != "" ? "text-red" : ""}`}><Trans>companyName</Trans> <i class="fas fa-star-of-life text-red"></i></label>
                                                        <input
                                                            id="companyName"
                                                            onChange={(text) => this.OnChangeValue('companyName', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('companyName', event)}
                                                            value={this.state.selectedDelegte.companyName == null ? "" : this.state.selectedDelegte.companyName}
                                                            class={this.state.eCompanyName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label> <Trans>state</Trans></label>
                                                        <input
                                                            onChange={(text) => this.OnChangeValue('state', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('state', event)}
                                                            value={this.state.selectedDelegte.state == null ? "" : this.state.selectedDelegte.state}
                                                            class="form-control rounded-0" />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={this.state.eAddress1 != "" ? "text-red" : ""}><Trans>add1</Trans> </label>
                                                        <input
                                                            id="address1"
                                                            onChange={(text) => this.OnChangeValue('address1', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('address1', event)}
                                                            value={this.state.selectedDelegte.address1 == null ? "" : this.state.selectedDelegte.address1}
                                                            class={this.state.eAddress1 != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label> <Trans>add2</Trans> </label>
                                                        <input
                                                            onChange={(text) => this.OnChangeValue('address2', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('address2', event)}
                                                            value={this.state.selectedDelegte.address2 == null ? "" : this.state.selectedDelegte.address2}
                                                            class="form-control rounded-0" />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={this.state.eCity != "" ? "text-red" : ""}> <Trans>city</Trans> </label>
                                                        <input
                                                            id="city"
                                                            onChange={(text) => this.OnChangeValue('city', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('city', event)}
                                                            value={this.state.selectedDelegte.city == null ? "" : this.state.selectedDelegte.city}
                                                            class={this.state.eCity != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>

                                                    <div id="country" class="form-group col-md-6">
                                                        <label className={`bookingLabel ${this.state.eCountry != "" ? "text-red" : ""}`}><Trans>country</Trans> <i class="fas fa-star-of-life text-red"></i>  </label>
                                                        {/* <input
                                                    onChange = {(text)=>this.OnChangeValue('country',text)} 
                                                    onBlur = {(event)=>this.OnValueSubmitted('country',event)} 
                                                    value = {this.state.selectedDelegte.country == null ? "" : this.state.selectedDelegte.country} 
                                                    class="form-control rounded-0" /> */}
                                                        <select onChange={() => this.OnChangeSelector('country', 'countrySelector')} value={this.state.selectedDelegte.country == null ? "" : this.state.selectedDelegte.country} class={this.state.eCountry != "" ? "form-control rounded-0 selector text-red-border" : "form-control rounded-0 selector"} id="countrySelector">
                                                            {this.renderCountryOptions()}
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label> <Trans>zip</Trans> </label>
                                                        <input
                                                            onChange={(text) => this.OnChangeValue('zipCode', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('zipCode', event)}
                                                            value={this.state.selectedDelegte.zipCode == null ? "" : this.state.selectedDelegte.zipCode}
                                                            class="form-control rounded-0" />
                                                    </div>

                                                    <div id="companyTelephone" class="form-group col-md-6">
                                                        <label className={this.state.eCompanyTelephone != "" ? "text-red" : ""}> <Trans>companyTelephone</Trans> </label>
                                                        {/* <input
                                                    id = "companyTelephone"
                                                    onChange = {(text)=>this.OnChangeValue('companyTelephone',text)} 
                                                    onBlur = {(event)=>this.OnValueSubmitted('companyTelephone',event)} 
                                                    value = {this.state.selectedDelegte.companyTelephone == null ? "" : this.state.selectedDelegte.companyTelephone} 
                                                    class={this.state.eCompanyTelephone != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} /> */}
                                                        <PhoneInput
                                                            inputStyle={{ width: '99.5%', marginLeft: 2, height: 'calc(2.25rem + 2px)', borderRadius: 0 }}
                                                            inputClass={this.state.eCompanyTelephone != "" ? "text-red-border" : "phone-picker"}
                                                            id="phone"
                                                            countryCodeEditable={false}
                                                            country={Constants.defaultCountry.code}
                                                            value={this.state.selectedDelegte.companyTelephone == null ? "" : this.state.selectedDelegte.companyTelephone}
                                                            onChange={(phone, country, e) => this.OnChangeValue('companyTelephone', e, country)}
                                                            onBlur={(e) => this.OnValueSubmitted('companyTelephone', e)}
                                                            jumpCursorToEnd
                                                            onFocus={(phone, country, e) => this.OnFocus('companyTelephone', phone, country)}
                                                        />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label> <Trans>companyVatNum</Trans> </label>
                                                        <input
                                                            onChange={(text) => this.OnChangeValue('companyVatNo', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('companyVatNo', event)}
                                                            value={this.state.selectedDelegte.companyVatNo == null ? "" : this.state.selectedDelegte.companyVatNo}
                                                            class="form-control rounded-0" />
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label className={this.state.eCompanyWebsite != "" ? "text-red" : ""}> <Trans>companyWebsite</Trans> </label>
                                                        <input
                                                            id="companyWebsite"
                                                            onChange={(text) => this.OnChangeValue('companyWebsite', text)}
                                                            onBlur={(event) => this.OnValueSubmitted('companyWebsite', event)}
                                                            value={this.state.selectedDelegte.companyWebsite == null ? "" : this.state.selectedDelegte.companyWebsite}
                                                            class={this.state.eCompanyWebsite != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                                    </div>

                                                </div>
                                                {/* <div class = "row py-3 float-left">
                                        {this.props.savingDelegate ?
                                        <Loader
                                            type = 'Oval'
                                            color = "#00b6ff"
                                            height = {25}
                                            width = {25}
                                        />:
                                        this.props.response ?
                                        <span style = {{marginTop : 3}} class = {this.props.response.error ? "text-red" : "text-success"}>{this.props.response.message}</span> : null}
                                    </div> */}
                                                <div class="row py-3 float-right">
                                                    {this.packages != null && this.packages.length > 0 ?
                                                        <button onClick={() => { this.OnClickSave("finish") }} type="button" class="btn bg_primary">{this.state.selectedDelegateIndex < this.packages[this.selectedPackageIndex].numberOfDelegates - 1 ? <Trans>save</Trans>
                                                            : <Trans>finish</Trans>}</button> : null}

                                                </div>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    RemoveScroll: data => dispatch(RemoveScroll(data))
})
export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(SecondStep);