import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./styles.css";
import $ from "jquery";
import { Trans } from "react-i18next";
import { withRouter } from "react-router";
import imageMapResize from "image-map-resizer";
import ImageMapper from "react-image-mapper";
import ReactTooltip from "react-tooltip";
import { includes } from "lodash";

const MapFloorContent = (props) => {
  const [showDialog, setShowDialog] = useState("none");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [dialogX, setDialogX] = useState(null);
  const [dialogY, setDialogY] = useState(null);
  const [blockHeight, setBlockHeight] = useState(0);
  const [boothId, setBooth] = useState(null);
  const dialogRef = useRef(null);
  const containerRef = useRef(null);
  const [corner, setCorner] = useState([]);
  const hoverRef = useRef(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  // hoverRef.current = hoverOnDialog
  const [hoveredArea, setHoverArea] = useState(null);
  const [screenWidth, setScreenWidth] = useState(
    100 * window.innerWidth * 0.01
  );
  const [homeScreenWidth, setHomeScreenWidth] = useState(
    80 * window.innerWidth * 0.01
  );
  const [screenHeight, setScreenHeight] = useState(
    49.7 * window.innerWidth * 0.01
  );
  const currentPath = window.location.href.split("/");
  useEffect(() => {
    window.addEventListener("resize", function (event) {
      if (window.location.href.split("/").includes("virtual-floor-plan")) {
        setScreenWidth(100 * window.innerWidth * 0.01);
      } else {
        setHomeScreenWidth(80 * window.innerWidth * 0.01);
      }

      setScreenHeight(49.7 * window.innerWidth * 0.01);
    });
    console.log(props.mapFloorData);
    setTimeout(() => {
      imageMapResize();
    }, 0);
  }, [props.mapFloorData]);
  useEffect(() => {
    if (showDialog == "block") {
      let x = dialogX;
      let y = dialogY;
      x -= dialogRef.current.offsetWidth / 4;
      y = parseInt(y) + 10;
      if (x < 0) {
        x = 0;
      }
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      while (x + dialogRef.current.offsetWidth > containerWidth) {
        x -= 15;
      }
      if (y + dialogRef.current.offsetHeight > containerHeight) {
        y -= dialogRef.current.offsetHeight + blockHeight + 20;
      }
      setDialogX(x);
      setDialogY(y);
    }
  }, [showDialog]);
  const OnNavigateToPage = (path, data = null) => {
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    }
    if (data == null) {
      props.history.push(path);
    } else {
      props.history.push({
        pathname: path,
        state: data,
      });
    }
  };
  const userNavigation = () => {
    if (props.user == null) {
      window.location.href =
        process.env.REACT_APP_JOBFAIR_REDIRECT_URL + `company/${companyId}`;
      setShowDialog("none");
    } else if (
      props.user != null &&
      props.user.jobBoardProfileCompleted == 1 &&
      props.user.isBooking == true
    ) {
      OnNavigateToPage(props.alias + `/virtual_exhibition/booth/${boothId}`);
      setShowDialog("none");
    } else if (
      props.user != null &&
      props.user.jobBoardProfileCompleted == 0 &&
      props.user.isBooking == false
    ) {
      let selectedLocale =
        localStorage.getItem("language" + props.alias) || "en";
      var hash = localStorage.getItem("login_hash");
      let path = `/company/${companyId}`;
      let location =
        process.env.REACT_APP_JOBFAIR_REDIRECT_URL +
        "?hash=" +
        hash +
        "&URI=" +
        window.location.origin +
        "&alias=" +
        props.alias +
        "&locale=" +
        selectedLocale +
        "&route=" +
        path;
      window.location.href = location;
      setShowDialog("none");
    } else if (
      props.user != null &&
      props.user.jobBoardProfileCompleted == null &&
      props.user.isBooking == false
    ) {
      let selectedLocale =
        localStorage.getItem("language" + props.alias) || "en";
      var hash = localStorage.getItem("login_hash");
      let path = `/company/${companyId}`;
      let location =
        process.env.REACT_APP_JOBFAIR_REDIRECT_URL +
        "?hash=" +
        hash +
        "&URI=" +
        window.location.origin +
        "&alias=" +
        props.alias +
        "&locale=" +
        selectedLocale +
        "&route=" +
        path;
      window.location.href = location;
      setShowDialog("none");
    }
    //   else if(props.user != null && props.user.jobBoardProfileCompleted == 1 && props.user.isBooking ==  false){
    //     window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL+`company/${companyId}`
    //   }
  };
  const showBoothData = (
    index,
    position,
    x = null,
    y = null,
    blockHeight = null
  ) => {
    // if(position == 'PERSIST'){
    //   hoverRef.current = true
    //   return
    // }
    if (showDialog == "block") {
      setShowDialog("none");
    }
    setImage("");
    setDescription("");
    setCompanyId(null);
    setDialogX(null);
    setDialogY(null);
    setBooth(null);
    setShowDialog("none");
    let selectedBooth = props.mapFloorData.virtualFloorPlan.booths[index];
    if (position == "ENTER") {
      if (selectedBooth.type == "custom_link") {
      } else if (selectedBooth.type == "booth") {
        setImage(selectedBooth.image);
        if (selectedBooth.details != null) {
          setDescription(
            ReactHtmlParser(selectedBooth.details.substring(0, 200))
          );
        }
        setCompanyId(selectedBooth.jobPortalCompany);
        setDialogX(x);
        setDialogY(y);
        setBlockHeight(blockHeight);
        setShowDialog("block");
        setBooth(selectedBooth.id);
      }
    } else {
      setImage("");
      setDescription("");
      setCompanyId(null);
      setDialogX(null);
      setDialogY(null);
      setBooth(null);
      setShowDialog("none");
    }
  };
  const enterArea = (area) => {
    setHoverArea(area);
  };

  const leaveArea = (area) => {
    setHoverArea(null);
  };

  const getTipPosition = (area) => {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  };
  const getViewport = () => {
    var viewPortWidth;
    var viewPortHeight;

    // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
    if (typeof window.innerWidth != "undefined") {
      viewPortWidth = window.innerWidth;
      viewPortHeight = window.innerHeight;
    }

    // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
    else if (
      typeof document.documentElement != "undefined" &&
      typeof document.documentElement.clientWidth != "undefined" &&
      document.documentElement.clientWidth != 0
    ) {
      viewPortWidth = document.documentElement.clientWidth;
      viewPortHeight = document.documentElement.clientHeight;
    }

    // older versions of IE
    else {
      viewPortWidth = document.getElementsByTagName("body")[0].clientWidth;
      viewPortHeight = document.getElementsByTagName("body")[0].clientHeight;
    }
    return [viewPortWidth, viewPortHeight];
  };
  const hideDialog = () => {
    setTimeout(() => {
      if (!hoverRef.current) {
        showBoothData(-1, "LEAVE");
      }
    }, 100);
  };
  const selectBoth = (area, index, event) => {
    var vpWH = []; // viewport Width-Height
    var vpW, vpH;
    console.log(props.mapFloorData.virtualFloorPlan);
    let coordinates = event.target.getAttribute("coords").split(",");
    let shape = event.target.getAttribute("shape");
    var coordX = coordinates[0];
    var coordY = coordinates[3];
    var height = coordinates[3] - coordinates[1];
    if (shape == "poly") {
      coordY = coordinates[7];
      height = coordinates[7] - coordinates[1];
    }
    setTimeout(() => {
      showBoothData(index, "ENTER", coordX, coordY, height);
    }, 0);
  };
  return props.mapFloorData && props.mapFloorData.virtualFloorPlan ? (
    <>
      <section
        class={`floor-map-sec ${props.type == "seprate" ? "" : "py-3 py-md-5"}`}
      >
        {/* <p style={{position:"absolute",top:top,left:left,zIndex:1000}}>Awais Niaz</p> */}
        <div
          class={`text-center container-fluid map-container ${
            props.type == "seprate" ? "" : "map-container1"
          }`}
        >
          <div
            class="row row-container"
            style={{ marginTop: `${props.type == "seprate" ? "" : "15px"}` }}
          >
            <div
              ref={containerRef}
              class="col-md-12 position-relative floorContainer"
            >
              <div
                ref={dialogRef}
                class={`path-details ${
                  props.type == "seprate" ? "fullpagemap" : "homepage"
                }`}
                id="detail_modal"
                style={{
                  display: showDialog,
                  left: window.innerWidth > 600 ? dialogX + "px" : 0 + "px",
                  top: window.innerWidth > 600 ? dialogY + "px" : 0 + "px",
                  zIndex: 800,
                  width: window.innerWidth > 600 ? "445px" : "100%",
                }}
                // onMouseEnter={()=> showBoothData(-1,'PERSIST')} onMouseLeave={()=> {
                //   hoverRef.current = false
                //   showBoothData(-1,'LEAVE')
                // }
                // }
              >
                {" "}
                <span
                  class="cross-dtl"
                  onClick={() => {
                    setShowDialog("none");
                  }}
                >
                  <i class="fas fa-times"></i>
                </span>
                {image != "" || image != null ? (
                  <img src={image} alt="logo-sponsor" />
                ) : null}
                <p>{description}</p>
                {companyId != null ? (
                  <p class="text-right">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (props.user == null) {
                          userNavigation();
                        } else {
                          if (props.user != null) {
                            if (
                              props.user.userRole != "boothRepresentative" &&
                              props.user.userRole != "organizer"
                            ) {
                              userNavigation();
                            } else {
                              OnNavigateToPage(
                                props.alias +
                                  `/virtual_exhibition/booth/${boothId}`
                              );
                              setShowDialog("none");
                            }
                          }
                        }
                      }}
                    >
                      {props.user == null ? (
                        <Trans>seeCompany</Trans>
                      ) : (props.user != null &&
                          props.user.userRole == "boothRepresentative") ||
                        props.user.userRole == "organizer" ? (
                        <Trans>visit_our_booth</Trans>
                      ) : props.user != null &&
                        props.user.isBooking == true &&
                        props.user.jobBoardProfileCompleted == 1 ? (
                        <Trans>visit_our_booth</Trans>
                      ) : (
                        <Trans>seeCompany</Trans>
                      )}
                    </a>
                  </p>
                ) : null}
              </div>
              {/* <img 
              src={
                Object.keys(props.mapFloorData).length > 0 &&
                props.mapFloorData.virtualFloorPlan.image
              }
              id = "Map" 
              usemap="#Map"
              className="map"
              width="2000" height="2604"
            />
            <map name="Map" id="Map">
              {Object.keys(props.mapFloorData).length > 0 &&
                ReactHtmlParser(
                  props.mapFloorData.virtualFloorPlan.map_area
                ).map((item, index) => {
                  return (
                    <>
                    <area
                      class={`${item.props.className} marea`}
                      id={item.props.id}
                      coords={item.props.coords}
                      //   href="#"
                      shape={item.props.shape}
                      onClick={(e) => {
                        console.log(item.props.coords)
                        console.log(e.clientX)
                        console.log(e.clientY)
                        var vpWH = []; // viewport Width-Height
                        var vpW, vpH;
                        let coordinates = e.target.getAttribute('coords').split(',')
                        let shape = e.target.getAttribute('shape')
                        var coordX = coordinates[0];
                        var coordY = coordinates[3];
                        var height = coordinates[3] - coordinates[1]
                        if(shape == 'poly'){
                          coordX = coordinates[1]
                          coordY = coordinates[7];
                          height = coordinates[7] - coordinates[1]
                        }
                        // var modal = document.getElementById("detail_modal")
                        // vpWH = getViewport();
                        // vpW = vpWH[0];
                        // vpH = vpWH[1];
                        // if ( coordX > vpW/2 ) { coordX -= modal.offsetWidth; }
                        // if ( coordY > vpH/2 ) { coordY -= modal.offsetHeight; }
                      showBoothData(index, "ENTER", coordX, coordY,height);
                      }}
                      onMouseOver={()=>{
                console.log(props.mapFloorData.virtualFloorPlan)
                        
                      }}
                    />
                    </>
                  );
                })}
            </map> */}
              <ImageMapper
                src={
                  Object.keys(props.mapFloorData).length > 0 &&
                  props.mapFloorData.virtualFloorPlan.image
                }
                className="MapView"
                map={{
                  name: "my-map",
                  areas: ReactHtmlParser(
                    props.mapFloorData.virtualFloorPlan.map_area
                  ).map((item) => {
                    return {
                      name: item.props.title,
                      fillColor: "#44645664",
                      shape: item.props.shape,
                      id: item.props.id,
                      setTooltipContent: "Job Fair",
                      coords: [
                        ...item.props.coords
                          .split(",")
                          .map((item) => parseInt(item)),
                      ],
                    };
                  }),
                }}
                // width={window.location.href.split('/').includes('virtual-floor-plan') ? screenWidth :homeScreenWidth}
                height={screenHeight}
                imgWidth={"100%"}
                onMouseEnter={(area, index, event) => {
                  console.log(event);
                  // const rect = event.getBoundingClientRect();
                  // setTop(rect.top + window.scrollY)
                  // setLeft(rect.left + window.scrollX)
                }}
                onClick={(area, index, event) => {
                  selectBoth(area, index, event);
                }}
                // onMouseEnter={(area) => enterArea(area)}
                // // onMouseLeave={(area) => leaveArea(area)}
              ></ImageMapper>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : null;
};
export default withRouter(MapFloorContent);
