import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../Agenda/api';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import $ from 'jquery'
import 'bootstrap'
import { TestimonialsLoaded } from './actions';
import {connect} from 'react-redux'
import ContentLoader from 'react-content-loader'
export class Testimonials extends React.Component{
    _isMounted = false
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias : this.props.event_alias,
            type : this.props.type,
            feature : {},
            selectedSlide : 0,
            showData : false,
            isLoading : false,
            testimonials:'Testimonials',
            EventTestimonial:[],
            title:'Testimonials'
        };
    }
    componentWillReceiveProps(nextProps){
        console.log(nextProps)
        if(nextProps.EventLoading != this.state.isLoading){
            this.setState({isLoading:nextProps.EventLoading})
        }
        if(nextProps.EventTestimonila.length>0){
            if(nextProps.EventTestimonila[0].hasOwnProperty('testimonials')){
                this.setState({EventTestimonial:nextProps.EventTestimonila[0].testimonials.items,title:nextProps.EventTestimonila[0].testimonials.title})
            }
        }

    }

    componentDidMount(){
        this._isMounted = true
        setInterval(() => {
            $('#cmt-slider').carousel('next')
        }, 5000);
        if(this.props.EventLoading != this.state.isLoading){
            this.setState({isLoading:this.props.EventLoading})
        }
        if(this.props.EventTestimonila.length>0){
            if(this.props.EventTestimonila[0].hasOwnProperty('testimonials')){
                this.setState({EventTestimonial:this.props.EventTestimonila[0].testimonials.items,title:this.props.EventTestimonila[0].testimonials.title})
            }
        }
        // if(this.props.testimonials != null){
        //     let showData = false
        //     if(this.props.testimonials.features.length > 0){
        //         showData = true
        //     }
        //     this.setState({feature : this.props.testimonials,showData,isLoading : false})
        //     return
        // }
        // api.getEventFeatures(this.state.event.alias,this.state.type,null,(err,data)=>{
        //     if(err == null && this._isMounted){
        //         if(data.feature != null){
        //             this.props.testimonialsLoaded(data.feature)
        //             this.setState({feature:data.feature,showData : true});
        //         }
        //     }
        //     this.setState({isLoading : false})
        // })
    }
    componentWillUnmount(){
        this._isMounted = false
    }
    render(){
        if(this.state.isLoading){
            return (
                <ContentLoader
                    width={400}
                    height={160}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...this.props}
                    >
                    <rect x="80" y="73" rx="2" ry="2" width="254" height="4" />
                    <rect x="78" y="88" rx="2" ry="2" width="254" height="4" />
                    <rect x="150" y="103" rx="2" ry="2" width="118" height="4" />
                    <circle cx="205" cy="27" r="22" />
                    <circle cx="190" cy="151" r="3" />
                    <circle cx="206" cy="151" r="3" />
                    <circle cx="222" cy="151" r="3" />
                    <rect x="37" y="55" rx="32" ry="32" width="10" height="10" />
                    <rect x="37" y="46" rx="0" ry="0" width="3" height="15" />
                    <rect x="50" y="55" rx="32" ry="32" width="10" height="10" />
                    <rect x="50" y="46" rx="0" ry="0" width="3" height="15" />
                    <rect x="340" y="118" rx="32" ry="32" width="10" height="10" />
                    <rect x="353" y="118" rx="32" ry="32" width="10" height="10" />
                    <rect x="347" y="123" rx="0" ry="0" width="3" height="15" />
                    <rect x="360" y="123" rx="0" ry="0" width="3" height="15" />
                </ContentLoader>
            )
        }
        const feature = this.state.EventTestimonial;
        var flage = 0;
        if(feature != null){
            if(feature.length > 0){
                flage = 1;
            }

        }

        if(flage == 1){
            return(
                <section class = "comment-carousel bg_primary py-3">
                    <h5 className="text-center font-weight-bold text-uppercase " style={{color:"white"}}>{this.state.title}</h5>
                    <div class="container">
                        
                        <div class="row py-3 text-center text-light">
                            <div class="col-md-12 my-3">
                                <div id="cmt-slider" class="carousel slide w-100" data-ride="carousel">
                                    
                                    <ul class="carousel-indicators cmt_indicators">
                                        {
                                            feature ?
                                                feature.map( (feature,index)=> {

                                                    var activeName = index == this.state.selectedSlide ? "active" : "";
                                                    return (
                                                        <li data-target="#cmt-slider" data-slide-to={index}
                                                            className={activeName}></li>
                                                    );

                                                })
                                                : null
                                        }

                                    </ul>

                                    <div class="carousel-inner">

                                        {
                                            feature ?
                                                feature.map((feature, index) => {
                                                    var activeName = index == this.state.selectedSlide ? "carousel-item active" : "carousel-item";
                                                    // if(feature.isActive == 1 && feature.type == Constants.Testimonial){

                                                        return(
                                                            <div className={activeName}>
                                                                <p>{ ReactHtmlParser(feature.description) }</p>
                                                                <h4 className = "font-weight-bold mt-2">{feature.title}</h4>
                                                            </div>
                                                        );

                                                    // }else{
                                                    //     return(null);
                                                    // }

                                                }) : null
                                        }


                                    </div>

                                </div>
                                {/* <Carousel autoPlay = {true} showArrows = {false} showThumbs = {false} infiniteLoop = {true} interval = {5000}>
                                        {
                                            feature.features ?
                                                feature.features.map(function (feature, index) {
                                                    var activeName = index == 0 ? "carousel-item active" : "carousel-item";
                                                    if(feature.isActive === 1 && feature.type == Constants.Testimonial){

                                                        return(
                                                            <div style = {{height : 225,background : '#20022e'}}>
                                                                <p>{ ReactHtmlParser(feature.description) }</p>
                                                                <h4 className = "font-weight-bold mt-2">{feature.title}</h4>
                                                            </div>
                                                        );

                                                    }else{
                                                        return(null);
                                                    }

                                                }) : null
                                        }
                                    </Carousel> */}
                            </div>
                        </div>
                    </div>
                </section>
            );

        }else{
            return(null);
        }//end of feature if

    }
}

const mapStateToProps = state => ({
    testimonials: state.testimonials.testimonialsData,
    EventTestimonila:state.appData.allFeature,
    EventLoading:state.appData.featureLoading
})
const mapDispatchToProps = dispatch => ({
    testimonialsLoaded : data => dispatch(TestimonialsLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);