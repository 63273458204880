import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $, { data } from 'jquery'
import 'bootstrap'
import utility from '../../Utility/utility';
import { UserProfile } from './Info/UserProfile';
import { BookingDetail } from './Info/BookingDetail';
import { ChangePassword } from './Info/ChangePassword';
import { ZoomInfo } from './Info/ZoomInfo';
import { EditZoomInfo } from './Info/EditZoomInfo';
import { MyFavorites } from './Info/MyFavorites';
import BookingDetailExtended from './Info/BookingDetailExtended';
import  EditProfile  from './Info/EditProfile';
import { Constants } from '../../Constants';
import {Trans} from 'react-i18next';
import {compose} from 'redux';
import { withTranslation } from 'react-i18next';
import { SocialMediaInfo } from './Info/SocialMedia';
import { EditSocialMedia } from './Info/EditSocialMedia';
import { DeleteAccount } from './Info/deleteAccount';

export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            selectedTab : 0,
            eFirstName : '',
            eLastName : '',
            eSecondName : '',
            ePhone : '',
            eOldPassword : '',
            ePassword : '',
            eCPassword : '',
            bookingsToShow : [],
            bookingCountInOnePage : 7,
            pagesCount : [],
            activePage : 1,
            phone : '',
            eCompanyName : '',
            eJobTitle : '',
            selectedProfileImage : null,
            crop: {
                unit: '%',
                x: 30,
                y:15,
                width: 40,
                aspect: 1 / 1
            },
            myFavorites : [],
            croppedImageUrl : null,
            selectedSpeaker : {},
            showFullSpeakerDescription : false
        }
        this.defaultDailCode = Constants.defaultCountry.dailCode
        this.moreBioToggle = false
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+this.props.event.alias) || 'en')
    }
    componentDidMount(){
        //console.log("Translation in content", this.props.translation)
        $('#firstName').bind('keypress', this.testInput);
        $('#lastName').bind('keypress', this.testInput);
        $('#secondName').bind('keypress', this.testInput);
        if(window.outerWidth > 767){
            var height = window.innerHeight
            // document.getElementById("participant_body").style.height = (height - 131) + 'px'
        }
    }
    componentWillReceiveProps(nextProps){

        if(nextProps.favoritesInfo == true){
            this.myFavouritesLoaded(nextProps.myFavorites)
        }
        if(nextProps.bookingInfo == true){
            this.adjustBookingsArray()
            nextProps.getBookingInfo()
        }
        if(this.state.bookingsToShow.length != nextProps.myBookings.length){
            var bookingsToShow = []
        
        if(nextProps.myBookings.length > this.state.bookingCountInOnePage){
            var pagesCount = []
            var counter = Math.ceil(nextProps.myBookings.length/this.state.bookingCountInOnePage)
            for(var i=0;i<counter;i++){
                pagesCount.push(i+1)
            }
            this.setState({pagesCount})
            for(var i=0;i<this.state.bookingCountInOnePage;i++){
                bookingsToShow.push(nextProps.myBookings[i])
            }
        }
        else{
            bookingsToShow = nextProps.myBookings
        }
        this.setState({bookingsToShow})

        }
    }
    adjustBookingsArray = () => {
        var bookingsToShow = []
        
        if(this.props.myBookings.length > this.state.bookingCountInOnePage){
            var pagesCount = []
            var counter = Math.ceil(this.props.myBookings.length/this.state.bookingCountInOnePage)
            for(var i=0;i<counter;i++){
                pagesCount.push(i+1)
            }
            this.setState({pagesCount})
            for(var i=0;i<this.state.bookingCountInOnePage;i++){
                bookingsToShow.push(this.props.myBookings[i])
            }
        }
        else{
            bookingsToShow = this.props.myBookings
        }
        this.setState({bookingsToShow})
    }
    myFavouritesLoaded = (arr) =>{
        this.setState({myFavorites : arr})
    }
    onClickPageItem = (page) => {
        page = page.selected + 1
        if(page < 1 || page > this.state.pagesCount.length){
            return
        }
        window.scrollTo(0,0)
        this.setState({activePage : page})
        var bookingsToShow = []
        var startIndex = ((page-1)*this.state.bookingCountInOnePage)
        var endIndex = page*this.state.bookingCountInOnePage
        if(endIndex > this.props.myBookings.length){
            endIndex = this.props.myBookings.length
        }
        
        for(var i=startIndex;i<endIndex;i++){
            bookingsToShow.push(this.props.myBookings[i])
        }
        
        this.setState({bookingsToShow})
    }
    OnClickTab= (index) => {
        this.props.onClickAccountItem(index)
    }
    OnClickSeeMore = (index) => {
        index = ((this.state.activePage - 1) * this.state.bookingCountInOnePage ) + index
        var booking = this.props.myBookings[index]
        this.bookingTitle = booking.TicketTitle
        this.delegates = booking.delegates
        this.orderSummary = booking.orderSummary
        this.ticketInfo = this.orderSummary.items.find(x=>x.title == this.bookingTitle)
        this.props.showBookingDetail()
    }
    OnClickEditProfile = () => {
        this.props.editProfile()
    }
    OnClickHideBookingDetail = () => {
        this.props.hideBookingDetail()
    }
    OnClickUpdateProfile = () => {
        var firstName = document.getElementById('firstName').value
        var lastName = document.getElementById('lastName').value
        var secondName = document.getElementById('secondName').value
        var phone = this.state.phone
        var gender = document.getElementById('sel1').value
        var city = document.getElementById('city').value
        var country = document.getElementById('country-select').value
        var companyName = document.getElementById('companyName').value
        var jobTitle = document.getElementById('jobTitle').value
        var profileBio = document.getElementById('profileBio').value
        this.setState({eFirstName : '',eLastName : '',eSecondName : '',ePhone : '',eCompanyName : '',eJobTitle : ''})
        var phoneStatus = true
        if(phone != null){
            if(phone == this.defaultDailCode){
                phone = ""
            }
            if(phone != ""){
                if(phone.charAt(0) != "+"){
                    phone = "+"+phone
                }
                phoneStatus = utility.validatePhone(phone)
            }
        }
        if(!utility.validName(firstName)){
            this.setState({eFirstName : this.translation.pleaseEnterValid})
        }
        if(!utility.validName(secondName)){
            this.setState({eSecondName : this.translation.pleaseEnterValid})
        }
        if(!utility.validName(lastName)){
            this.setState({eLastName : this.translation.pleaseEnterValid})
        }
        if(companyName == ""){
            this.setState({eCompanyName : 'Please enter a valid company name'})
        }
        if(jobTitle == ""){
            this.setState({eJobTitle : 'Please enter a valid job title'})
        }
        if(!phoneStatus){
            this.setState({ePhone : 'Please enter a valid phone'})
        }

        if(!utility.validName(firstName) || !utility.validName(lastName) || !phoneStatus){
            return
        }
        if(phone && phone.length > 0){
            let phoneWithOutCode = phone.substr(this.defaultDailCode.length,phone.length)
            var formattedPhoneNumner = this.defaultDailCode+" "+phoneWithOutCode
        }
        var data = {
            firstName : firstName,
            lastName : lastName,
            secondName : secondName,
            phone : phone,
            gender : gender,
            city : city,
            country : country,
            companyName : companyName,
            jobTitle : jobTitle,
            profileBio : profileBio,
            formattedPhone : phone
        }
        this.props.updateProfile(data)
    }
    OnFocus = (country) => {
        this.defaultDailCode = "+"+country.dialCode
    }
    OnClickSavePassword = () =>{
        var oldPassword = ""
        var oldPasswordInput = document.getElementById('oldPassword')
        if(oldPasswordInput != null){
            oldPassword = document.getElementById('oldPassword').value
        }
        
        var newPass = document.getElementById('newPassword').value
        var confirmPass = document.getElementById('confirmPassword').value

        this.setState({eOldPassword : '',ePassword : '',eCPassword : ''})
        
        var oldPassValidated = utility.validPassFormat(oldPassword)
        var newPassValidated = utility.validPassFormat(newPass)
        if(oldPasswordInput == null ){
            oldPassValidated = true
        }
        if(!oldPassValidated){
            this.setState({eOldPassword : <Trans>minCh</Trans>})
        }
        if(!newPassValidated){
            this.setState({eOldPassword : <Trans>minCh</Trans>})
            this.setState({ePassword : <Trans>minCh</Trans>})
        }
        if(confirmPass == ""){
            this.setState({eCPassword : <Trans>minCh</Trans>})
        }
        if(newPass != confirmPass){
            this.setState({eCPassword : <Trans>notMatch</Trans>})
        }

        if(!oldPassValidated ||
            !newPassValidated ||
            newPass != confirmPass){
                return
            }
        var data = {
            oldPassword : oldPassword,
            password : newPass,
            passwordSet : this.props.loggedInUser.passwordSet,
            isChangingPassword : true
        }
        this.props.updateProfile(data,true)
    }
    SetPhoneNumberOnLoad = () => {
        if(this.state.phone == "" && this.props.loggedInUser != null){
            this.setState({phone : this.props.loggedInUser.phone})
        }
    }
    onZoomInfoChange = ()=>{
        let zoomUser = document.getElementById("zoomUserName")
        let zoomPassword = document.getElementById("zoomPassword")
        let zoomLink = document.getElementById("ZoomLink")
        var zoomUserValue = null
        var zoomPasswordValue = null
        var zoomLinkValue = null
        if(zoomUser){
            if(zoomUser.value == ""){
                zoomUserValue = null
            }
            else{
                zoomUserValue = zoomUser.value
            }
        }
        if(zoomPassword){
            if(zoomPassword.value == ""){
                zoomPasswordValue = null
            }
            else{
                zoomPasswordValue = zoomPassword.value
            }
        }
        if(zoomLink){
            if(zoomLink.value == ""){
                zoomLinkValue = null
            }
            else{
                zoomLinkValue = zoomLink.value
            }
        }
        
        var data = {
            zoom_password:zoomPasswordValue,
            zoom_user_name:zoomUserValue,
            personal_zoom_meeting_link:zoomLinkValue
        }
        this.props.updateProfile(data)
        // if(this.props.loading == true){
        // this.props.saveZoomInfo()
        // }
    }
    onSaveSocialMedia = ()=>{
        let linkedINLink = document.getElementById("LinkedIn")
        let twitterlink = document.getElementById("Twitter")
        let instLink = document.getElementById("instagram")
        let facebookLink  = document.getElementById("facebook")
        var twitter = null
      var facebook = null
      var linkedIn = null 
      var instagram = null
       if(facebookLink ){
       facebookLink.value == "" ? facebook = null : facebook  = facebookLink.value}
       if(twitterlink){
           twitterlink.value == "" ? twitter = null :twitter = twitterlink.value }
        if(instLink){
            instLink.value ==  ""?instagram = null :instagram = instLink.value
        }
        if(linkedINLink){
            linkedINLink.value == ""?linkedIn = null : linkedIn = linkedINLink.value
        }
        var data = {
            facebookProfileLink:facebook,
            twitterProfileLink:twitter,
            linkedinProfileLink:linkedIn,
            instagramProfileLink:instagram

        }
        this.props.updateProfile(data)
    }
    OnChangePhone = (phone,country)=>{
        let dialCode = country.dialCode
        let phoneWithOutCode= phone.replace(dialCode,'')
        let phoneNumber = dialCode+' '+phoneWithOutCode
        this.setState({phone: phoneNumber})
    }
    testInput(event) {
        var value = String.fromCharCode(event.which);
        var pattern = new RegExp(/(fiktiv)|[^0-9]/g);
        return pattern.test(value);
    }
    OnImageSelected = (e) => {
        if(e.target.files.length > 0){
            $('#edit-img').modal('show');
            this.setState({selectedProfileImage : e.target.files[0]})
        }
        e.target.value = null
    }
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop })
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            this.imageToUpload = blob
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }
    OnClickUploadProfileImage = () => {
        $('#edit-img').modal('hide');
        $('.modal-backdrop').remove();
        const formData = new FormData();
        formData.append(
            "profileImage",
            this.imageToUpload,
            this.imageToUpload.name
        )
        this.props.updateProfile(formData,false,true,this.fileUrl)
    }
    OnProfilePictureUpLoaded = () => {
        this.setState({selectedProfileImage : null})
    }
    OnClickMoreBio = () => {
        let elem = document.getElementById("more-less")
        if(elem.innerHTML == "more"){
            elem.innerHTML = " less"
        }else{
            elem.innerHTML = "more"
       }
       $('#hidden-text, #dots').toggle();
    }
    OnUpdateFavouriteItem = (index) => {
        let myFavorites = this.state.myFavorites
        let item = myFavorites[index]
        if(item.is_favourite == 1){
            item.is_favourite = 0
        }else{
            item.is_favourite = 1
        }
        var data = {
            agenda_id : item.agenda_id,
            session_id : item.session_id,
            sub_session_id : item.sub_session_id == null ? 0 : item.sub_session_id,
            is_favourite : item.is_favourite
        }
        this.props.onMarkFavorite(data)
        this.setState({myFavorites})
    }
    renderCountryOptions = () => {
        //console.log("HERE",this.props.translation)
        var options = []
        for(var i=0;i<this.props.countries.length;i++){
            if(i==0){
                options.push(<option value="" selected disabled>{this.props.translation.select}</option>)
            }else{
                options.push(<option>{this.props.countries[i]}</option>)
            }
        }
        return options
    }
    render(){
        this.SetPhoneNumberOnLoad()
        let selectedSpeakerDescription = ""
        if(this.state.selectedSpeaker.speaker_description != null){
            selectedSpeakerDescription = utility.extractContent(this.state.selectedSpeaker.speaker_description)
        }
        return(
            <section id = "participant_body" class="account-content bg-light py-3 py-md-5 position-relative favourite-section">
                <div class="container content">
                    <div class="row">
                        <div class="col-md-3 mb-3 mb-md-0">
                            <div class="side-box">
                                <div class="side-ul">
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <a id = "account" onClick = {()=>this.OnClickTab(0)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 0 ? "nav-link active" : "nav-link" }><Trans>myProfile</Trans></a>
                                        </li>
                                       {this.props.event.isFree == 0 ?  <li class="nav-item">
                                            <a id = "booking" onClick = {()=>this.OnClickTab(1)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 1 ? "nav-link active" : "nav-link" }><Trans>myBookings</Trans></a>
                                        </li>:null}
                                        <li class="nav-item">
                                            <a id = "booking" onClick = {()=>this.OnClickTab(2)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 2 ? "nav-link active" : "nav-link" }><Trans>myFavourites</Trans></a>
                                        </li>
                                       {this.props.event.is_jobfair == 1 ? 
                                         (this.props.loggedInUser && this.props.loggedInUser.userRole == "companyOwner") ||  (this.props.loggedInUser && this.props.loggedInUser.userRole == "companyModerator") ?
                                       <li class="nav-item">
                                            <a id = "booking" onClick = {()=>this.OnClickTab(4)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 4 ? "nav-link active" : "nav-link" }><Trans>myZoom</Trans></a>
                                        </li>:null:null}
                                        {(this.props.event.isfacebookProfileLink == null || this.props.event.isfacebookProfileLink == 0 ) &&
(this.props.event.isinstagramProfileLink == null || this.props.event.isinstagramProfileLink == 0 ) &&
(this.props.event.islinkedinProfileLink == null || this.props.event.islinkedinProfileLink == 0 ) &&
(this.props.event.istwitterProfileLink == null || this.props.event.istwitterProfileLink == 0 ) ? null :
                                            <li class="nav-item">
                                            <a onClick = {()=>this.OnClickTab(5)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 5 ? "nav-link active" : "nav-link" }>My Social Media</a>
                                        </li>}
                                        <li class="nav-item">
                                            <a onClick = {()=>this.OnClickTab(3)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 3 ? "nav-link active" : "nav-link" }><Trans>changePassword</Trans></a>
                                        </li>
                                        <li class="nav-item">
                                            <a onClick = {()=>this.OnClickTab(6)} style = {{cursor : 'pointer'}} class={this.props.selectedTab == 6 ? "nav-link active" : "nav-link" }>Delete Account</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-9">
                            <div class="inner_panel">
                                <div class="card">
                                    {this.props.selectedTab == 0 ?
                                    this.props.loggedInUser != null ?
                                        !this.props.editingProfile ?
                                        <UserProfile
                                            loading = {this.props.loading}
                                            onClickEditProfile = {this.OnClickEditProfile}
                                            user = {this.props.loggedInUser}
                                            onImageSelected = {this.OnImageSelected}
                                            selectedImage = {this.state.selectedProfileImage}
                                            crop = {this.state.crop}
                                            onImageLoaded = {this.onImageLoaded}
                                            onCropComplete = {this.onCropComplete}
                                            onCropChange = {this.onCropChange}
                                            onUploadImage = {this.OnClickUploadProfileImage}
                                            profileBio = {this.props.loggedInUser.profileBio != null ? this.props.loggedInUser.profileBio : this.props.translation.addProfileBio}
                                            onClickMore = {this.OnClickMoreBio}
                                            successMessage = {this.props.successMessage}
                                            translation ={this.props.translation}
                                        /> :
                                        <EditProfile 
                                            user = {this.props.loggedInUser}
                                            onClickSaveProfile = {this.OnClickUpdateProfile}
                                            expireSession = {this.props.expireSession}
                                            sessionExpire = {this.props.sessionExpire}
                                            eFirstName = {this.state.eFirstName}
                                            eLastName = {this.state.eLastName}
                                            eSecondName = {this.state.eSecondName}
                                            alias = {this.props.event.alias}
                                            eCompanyName = {this.state.eCompanyName}
                                            eJobTitle = {this.state.eJobTitle}
                                            phone = {this.state.phone != null ? this.state.phone : Constants.defaultCountry.dailCode}
                                            onChangePhone = {this.OnChangePhone}
                                            ePhone = {this.state.ePhone}
                                            savingProfile = {this.props.savingProfile}
                                            saveProfileError = {this.props.saveProfileError}
                                            successMessage = {this.props.successMessage}
                                            onFocus = {this.OnFocus}
                                            defaultCountry = {Constants.defaultCountry}
                                            countryOptions = {this.renderCountryOptions}
                                            translation ={this.props.translation}
                                        /> : null :
                                        this.props.selectedTab == 1 ?
                                            !this.props.showingBookingDetail?
                                                <BookingDetail
                                                    loading = {this.props.loading}
                                                    bookings = {this.state.bookingsToShow}
                                                    onClickSeeMore = {this.OnClickSeeMore}
                                                    pagesCount = {this.state.pagesCount}
                                                    activePage = {this.state.activePage}
                                                    onClickPage = {this.onClickPageItem}
                                                />:
                                                <BookingDetailExtended 
                                                    defaultIndex = {0}
                                                    title = {this.bookingTitle}
                                                    delegates = {this.delegates}
                                                    orderSummary = {this.orderSummary}
                                                    ticketInfo = {this.ticketInfo}
                                                    onClickBack = {this.OnClickHideBookingDetail}
                                                /> :
                                        this.props.selectedTab == 4 ? 
                                        this.props.zoomEdit == true ?
                                        <EditZoomInfo
                                        passwordSet = {this.props.loggedInUser.passwordSet}
                                        eOldPassword = {this.state.eOldPassword}
                                        saveSocialInfo = {this.props.saveSocialInfo}
                                        ePassword = {this.state.ePassword}
                                        user = {this.props.loggedInUser} 
                                        eCPassword = {this.state.eCPassword}
                                        onClickSaveZoomInfo = {this.onZoomInfoChange}
                                        moveToMainPage = {this.props.editZoomInfo}
                                        loading = {this.props.loading}
                                        changePassError = {this.props.saveProfileError}
                                        successMessage = {this.props.successMessage}/>
                                        :
                                        <ZoomInfo
                                        passwordSet = {this.props.loggedInUser.passwordSet}
                                        eOldPassword = {this.state.eOldPassword}
                                        user = {this.props.loggedInUser}
                                        ePassword = {this.state.ePassword}
                                        eCPassword = {this.state.eCPassword}
                                        onClickSaveZoomInfo = {this.props.editZoomInfo}
                                        loading = {this.props.loading}
                                        changePassError = {this.props.saveProfileError}
                                        successMessage = {this.props.successMessage}
                                        
                                    />:this.props.selectedTab == 5 ? 
                                    // this.props.socialinfo == true ?
                                    <EditSocialMedia
                                    passwordSet = {this.props.loggedInUser.passwordSet}
                                    eOldPassword = {this.state.eOldPassword}
                                    ePassword = {this.state.ePassword}
                                    event = {this.props.event}
                                    user = {this.props.loggedInUser} 
                                    saveSocialInfo = {this.props.saveSocialInfo}
                                    eCPassword = {this.state.eCPassword}
                                    onClickSaveZoomInfo = {this.onSaveSocialMedia}
                                    moveToMainPage = {this.props.editZoomInfo}
                                    loading = {this.props.loading}
                                    changePassError = {this.props.saveProfileError}
                                    successMessage = {this.props.successMessage}/>
                                    :
                                //     <SocialMediaInfo
                                //     passwordSet = {this.props.loggedInUser.passwordSet}
                                //     eOldPassword = {this.state.eOldPassword}
                                //     user = {this.props.loggedInUser}
                                //     ePassword = {this.state.ePassword}
                                //     eCPassword = {this.state.eCPassword}
                                //     onClickSaveZoomInfo = {this.props.editSocialInfo}
                                //     loading = {this.props.loading}
                                //     changePassError = {this.props.saveProfileError}
                                //     successMessage = {this.props.successMessage}
                                    
                                // />:

                                        this.props.selectedTab == 2 ?
                                        <MyFavorites
                                            loading = {this.props.loading}
                                            favorites = {this.state.myFavorites}
                                            onUpdateFavouriteItem = {this.OnUpdateFavouriteItem}
                                            selectedSpeaker = {this.state.selectedSpeaker}
                                            onClickSpeaker = {(speaker)=>{
                                                this.setState({selectedSpeaker : speaker},()=>{
                                                    let elem = document.getElementById("spk-modal")
                                                    if(elem != null){
                                                        elem.classList.add("show")
                                                        $(' .favourite-section, body').addClass('overflow-hidden');
				                                        $('.favourite-section').addClass('favourite-section-before');
                                                        // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                                                        document.body.style.overflowY = "hidden"
                                                    }
                                                })
                                            }}
                                        /> :
                                        this.props.selectedTab == 6 ?
                                        <DeleteAccount
                                        loading = {this.props.loading}
                                        loggedInUser = {this.props.loggedInUser}
                                        changePassError = {this.props.saveProfileError}
                                        successMessage = {this.props.successMessage}
                                        onDeleteAccount = {this.props.onDeleteAccount}
                                        deleteAccountMessage={this.props.deleteAccountMessage}
                                        successDeleteAccount = {this.props.successDeleteAccount}
                                        onReactiveAccount = {this.props.onReactiveAccount}
                                        />:
                                        <ChangePassword 
                                            passwordSet = {this.props.loggedInUser.passwordSet}
                                            eOldPassword = {this.state.eOldPassword}
                                            ePassword = {this.state.ePassword}
                                            eCPassword = {this.state.eCPassword}
                                            onClickSavePassword = {this.OnClickSavePassword}
                                            loading = {this.props.loading}
                                            changePassError = {this.props.saveProfileError}
                                            successMessage = {this.props.successMessage}
                                        />
                                        }
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="modall fade left" id="spk-modal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">

                            <div class="modal-body">
                                <div class="row my-3">
                                    <div class="col-3 text-center">
                                        <div class="participant-image">
                                            <img src={this.state.selectedSpeaker.image != null ? this.state.selectedSpeaker.image : "/images/profile-placeholder.png"} alt="participant_image" />
                                        </div>
                                    </div>

                                    <div class="col-9 d-flex align-items-center">
                                        <div class="participant-details">
                                            <span class="spk-full-name">
                                                {this.state.selectedSpeaker.speaker_name + " "+(this.state.selectedSpeaker.surname == null ? "" : this.state.selectedSpeaker.surname)}
                                            </span>
                                            <div class="post">
                                                {this.state.selectedSpeaker.job_title} <br/>
                                                {this.state.selectedSpeaker.company}
                                            </div>
                                        </div>
                                    </div>
                                    <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                        let elem = document.getElementById("spk-modal")
                                        if(elem != null){
                                            elem.classList.remove("show")
                                            // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
                                            document.body.style.overflowY = "scroll"
                                            $('.favourite-section, body').removeClass('overflow-hidden')
				                            $('.favourite-section').removeClass('favourite-section-before')
                                        }
                                        this.setState({selectedSpeaker : {}})
                                    }} class="close-icons" id="close-m">
                                        <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                    </a>
                                </div>
                                <div id="speakerDetail" class="spk-detail-text">
                                        {this.state.selectedSpeaker.speaker_description != null ?
                                        <p class="details pr-3 mb-2">
                                            {!this.state.showFullSpeakerDescription && selectedSpeakerDescription.length >= 700 ?
                                            ReactHtmlParser(this.state.selectedSpeaker.speaker_description.substring(0,700)) :
                                            ReactHtmlParser(this.state.selectedSpeaker.speaker_description)}
                                        </p> : null}
                                        {this.state.selectedSpeaker.speaker_description != null && selectedSpeakerDescription.length >= 700 ?
                                        <a style = {{cursor : 'pointer',textDecoration:'underline'}} onClick = {()=>{
                                            this.setState({showFullSpeakerDescription : !this.state.showFullSpeakerDescription},()=>{
                                                if(!this.state.showFullSpeakerDescription){
                                                    var myDiv = document.getElementById('speakerDetail');
                                                    myDiv.scrollTop = 0
                                                }
                                            })
                                        }} class="text-underline text-dark">{!this.state.showFullSpeakerDescription ? "Show Full" : "Show Less"}</a> : null}
                                </div>
                                <p class = "mt-4 mt-md-2 mb-0">
                                </p>
                                {/* {this.state.selectedSpeaker.speaker_email != null ?
                                <p class = "mt-0 mt-md-2 text-center">
                                    <a href = {"mailto:"+this.state.selectedSpeaker.speaker_email} class="btn mx-1">
                                        Drop Email
                                    </a>
                                </p> : null} */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>	
        )
    }//end of render

}

export default compose(withTranslation())(Content);