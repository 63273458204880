import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { MDBBtn, MDBCollapse } from 'mdbreact'
import axios from '../axios/index'
import { isMobile } from 'react-device-detect'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import 'bootstrap'
import utility, { jobBoardNavigator } from '../../Utility/utility'
import { Constants } from '../../Constants'
import { withRouter } from 'react-router'
import './index.css'
import { Trans } from 'react-i18next'
export class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedSubIndex: -1,
      menu: false,
      collapseID: '',
      toggler: false,
      socails: [
        {
          href: props.facebookLink,
          text: 'Facebook',
          icon: 'fab fa-facebook-f'
        },
        {
          href: props.twitterLink,
          text: 'Twitter',
          icon: 'fab fa-twitter'
        },
        {
          href: props.linkedInLink,
          text: 'LinkedIn',
          icon: 'fab fa-linkedin-in'
        }
        // {
        //   href: props.googleLink,
        //   text: "Google+",
        //   icon: "fab fa-google-plus-g mr-2",
        // },
        // {
        //   href: props.instagramLink,
        //   text: "Instagram",
        //   icon: "fab fa-instagram",
        // },
      ],
      userLoggedIn: false,
      availableLocales: this.props.availableLocales
    }
    this.dropDownClicked = false
    this.navbar = null
    this.sticky = null
    this.nameArray = []
    this.once = false
    this.user = utility.getLoggedInUser(this.props.eventAlias)

    this.selectedLocale = localStorage.getItem(
      'language' + this.props.eventAlias
    )
    //console.log("selectedLocale", this.selectedLocale)
    //console.log("availablelocales", this.props.availableLocales)
    this.selectedDropDownLanguage = this.props.availableLocales.find(
      x => x.value == this.selectedLocale
    )
    //!this.selectedDropDownLanguage ? this.selectedDropDownLanguage = this.props.availableLocales : this.selectedDropDownLanguage = []
    //console.log("selectedDropdown", this.selectedDropDownLanguage)
  }
  componentDidMount () {
    this.user = utility.getLoggedInUser(this.props.eventAlias)
    if (this.props.event.is_jobfair == 1) {
      let currentUrl = window.location.href.split('/')
      let freshChatElment =
        document.getElementsByClassName('fc-widget-normal')[0]
      if (freshChatElment) {
        if (
          window.innerWidth < 1100 &&
          (currentUrl.includes('participants') ||
            currentUrl.includes('userChat'))
        ) {
          freshChatElment.style.display = 'none'
        } else {
          freshChatElment.style.display = 'block'
        }
      }
    } else {
      if (
        this.user != null &&
        this.user.isBooking == true &&
        Constants.showVirtualZone == true
      ) {
        let currentUrl = window.location.href.split('/')
        let freshChatElment =
          document.getElementsByClassName('fc-widget-normal')[0]
        if (freshChatElment) {
          if (
            window.innerWidth < 1100 &&
            (currentUrl.includes('participants') ||
              currentUrl.includes('userChat'))
          ) {
            freshChatElment.style.display = 'none'
          } else {
            freshChatElment.style.display = 'block'
          }
        }
      } else {
        let freshChatElment = document.getElementById('fc_frame')
        if (freshChatElment) {
          freshChatElment.classList.add('freshChatIcons')
        }
      }
    }
    window.addEventListener('resize', () => {
      let currentUrl = window.location.href.split('/')
      if (this.props.event.is_jobfair == 1) {
        let currentUrl = window.location.href.split('/')
        let freshChatElment =
          document.getElementsByClassName('fc-widget-normal')[0]
        if (freshChatElment) {
          if (
            window.innerWidth < 1100 &&
            (currentUrl.includes('participants') ||
              currentUrl.includes('userChat'))
          ) {
            freshChatElment.style.display = 'none'
          } else {
            freshChatElment.style.display = 'block'
          }
        }
      } else {
        if (
          this.user != null &&
          this.user.isBooking == true &&
          Constants.showVirtualZone == true
        ) {
          let currentUrl = window.location.href.split('/')
          let freshChatElment =
            document.getElementsByClassName('fc-widget-normal')[0]
          if (freshChatElment) {
            if (
              window.innerWidth < 1100 &&
              (currentUrl.includes('participants') ||
                currentUrl.includes('userChat'))
            ) {
              freshChatElment.style.display = 'none'
            } else {
              freshChatElment.style.display = 'block'
            }
          }
        } else {
          let freshChatElment =
            document.getElementsByClassName('fc-widget-normal')[0]
          if (freshChatElment) {
            freshChatElment.style.display = 'none'
          }
        }
      }
    })

    this.navbar = document.getElementById('stickyMenu')
    this.sticky = this.navbar.offsetHeight

    // $(window).scroll(function(){
    //   if ($(this).scrollTop() > 40) {
    //      $('#virtualStickyMenu').addClass("sticky");
    //      // add padding top to show content behind navbar
    //   //    $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
    //    }else{
    //      $('#virtualStickyMenu').removeClass("sticky");
    //     // remove padding top from body
    //   //    $('body').css('padding-top', '0');
    //    }
    //  });
    window.addEventListener('scroll', () => {
      // if(document.getElementById("header-id")){
      //   if($('.navbar-collapse').hasClass('show') && window.pageYOffset > 20){

      //     document.getElementById("header-id").style.marginTop = '550px'
      //   }
      //   else{
      //     document.getElementById("header-id").style.marginTop = '15px'
      //   }
      // }
      let navbar =
        document.getElementById('stickyMenu') &&
        document.getElementById('stickyMenu').offsetHeight
      var userData = JSON.parse(
        localStorage.getItem(
          Constants.API_TOKEN + this.props.eventAlias + 'user_info'
        )
      )
      if (userData != null || Constants.showVirtualZone == true) {
        if (
          userData != null &&
          userData.isBooking == true &&
          Constants.showVirtualZone == true &&
          userData.jobBoardProfileCompleted != null
        ) {
          document.getElementById('stickyMenu') &&
            document
              .getElementById('stickyMenu')
              .classList.toggle('scroller', window.scrollY > 0)
        }
        // if(userData != null && userData.isBooking == true && Constants.showVirtualZone ==  true && userData.jobBoardProfileCompleted == null){
        //   document.getElementById("stickyMenu") && document.getElementById("stickyMenu").classList.toggle('scroller',window.scrollY > 0)
        // }
        else {
          // document.getElementById("stickyMenu").classList.toggle('affix',window.scrollY > 0)
          if (document.documentElement.scrollHeight > 1000) {
            if (window.pageYOffset > 30) {
              this.navbar && this.navbar.classList.add('affix')
            } else {
              if (window.pageYOffset == 0) {
                this.navbar && this.navbar.classList.remove('affix')
              }
            }
          } else {
            if (window.pageYOffset == 0) {
              this.navbar && this.navbar.classList.remove('affix')
            }
          }
        }
      } else {
        // document.getElementById("stickyMenu").classList.toggle('affix',window.scrollY > 0)
        if (document.documentElement.scrollHeight > 750) {
          if (window.pageYOffset >= 30) {
            this.navbar && this.navbar.classList.add('affix')
          } else {
            if (window.pageYOffset == 0) {
              this.navbar && this.navbar.classList.remove('affix')
            }
          }
        } else {
          if (window.pageYOffset == 0) {
            this.navbar && this.navbar.classList.remove('affix')
          }
        }
      }
    })

    var test = this.props.eventName
    if (isMobile) {
      this.nameArray = [this.props.eventName]
    } else {
      this.nameArray = test.match(/.{1,20}/g)
    }
    document.addEventListener('mousedown', this.handleClickOutside)
    $(document).ready(function () {
      // $('.dropdown-menu li').on('click', function() {
      // var getValue = $(this).text();
      // $('.dropdown-select').text(getValue);
      // });

      setTimeout(() => {
        if ($('.profile-dropdown').hasClass('show')) {
          $('.profile-dropdown').removeClass('show')
        }
      }, 0)
    })

    $('.dropdown-language').click(() => {
      //console.log("CLICKED BUTTON")
      if (!$('.lang-menu').hasClass('show')) {
        $('.lang-menu').addClass('show')
      } else {
        $('.lang-menu').removeClass('show')
      }
    })

    $('.header_dropdown').click(() => {
      if (!$('.header_dropdown').hasClass('show')) {
        $('.header_dropdown').addClass('show')
        $('.job-board').removeClass('show')
        //$(".lang-menu").removeClass("show")
      } else {
        $('.header_dropdown').removeClass('show')
      }
      if (
        this.user == null ||
        !this.user.isBooking ||
        !Constants.showVirtualZone
      ) {
        setTimeout(() => {
          if (!$('.profile-dropdown').hasClass('show')) {
            $('.profile-dropdown').addClass('show')
          } else {
            $('.profile-dropdown').removeClass('show')
          }
        }, 100)
      }
    })
    // window.addEventListener("scroll", this.handleScroll, false);
    // this.navbar = document.getElementById("stickyMenu");
    // this.sticky = this.navbar.offsetTop;
    this.dropDownContainer = document.getElementById('dropDownContent')
    this.profileIcon = document.getElementById('profileContainer')
  }
  componentDidUpdate (prevProps) {
    if (
      this.props.selectedIndex &&
      this.props.selectedSubIndex != prevProps.selectedSubIndex
    ) {
      this.setState({ selectedSubIndex: this.props.selectedSubIndex })
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.menuCollapse == true) {
      this.closeCollapse()
      nextProps.naviagtorLevel()
    }
  }
  closeCollapse = () => {
    this.setState({ collapseID: '' })
  }
  handleScroll = () => {
    if (this.user == null || !this.user.isBooking) {
      if (document.documentElement.scrollHeight > 760) {
        if (window.pageYOffset >= this.sticky) {
          this.navbar.classList.add('sticky')
          this.navbar.classList.add('stickyMenuBG')
          document.getElementById('eventTitle').style.display = 'none'
        } else {
          if (window.pageYOffset == 0) {
            this.navbar.classList.remove('sticky')
            this.navbar.classList.remove('stickyMenuBG')
            document.getElementById('eventTitle').style.display = ''
          }
        }
      } else {
        if (window.pageYOffset == 0) {
          this.navbar.classList.remove('sticky')
          this.navbar.classList.remove('stickyMenuBG')
          document.getElementById('eventTitle').style.display = ''
        }
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
    $('.header_dropdown').unbind()
    window.removeEventListener('scroll', this.handleScroll)
  }
  OnClickAccountDropDown = () => {
    this.dropDownClicked = true
    // if(isMobile){
    //    if(this.profileIcon != null){
    //       this.dropDownContainer.style.top = (this.profileIcon.offsetTop + 29)+"px"
    //    }
    // }
  }
  handleClickOutside = () => {
    setTimeout(() => {
      if (this.dropDownClicked) {
        this.dropDownClicked = false
        return
      }
      if (
        this.user == null ||
        !this.user.isBooking ||
        !Constants.showVirtualZone
      ) {
        // if ($(".header_dropdown").hasClass("show")) {
        //   $(".header_dropdown").removeClass("show");
        // }
        if ($('.profile-dropdown').hasClass('show')) {
          $('.profile-dropdown').removeClass('show')
        }
        if ($('.lang-menu').hasClass('show')) {
          $('.lang-menu').removeClass('show')
        }
      }
    }, 200)
  }
  OnToggleMenu = () => {
    this.setState({ menu: !this.state.menu })
  }
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }))
    this.setState({ toggler: !this.state.toggler }, state => {
      // if(document.getElementById("header-id")){
      //   if(this.state.toggler == true && window.pageYOffset > 20){
      //     document.getElementById("header-id").style.marginTop = '550px'
      //   }
      //   else{
      //     document.getElementById("header-id").style.marginTop = '15px'
      //   }
      // }
    })
  }
  OnClickSocialIcon = link => {
    window.open(link, '_blank')
  }
  MakeCall = () => {
    if (this.props.phone == null) {
      return
    }
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = 'tel:' + this.props.phone
    // the filename you want
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL('tel:' + this.props.phone)
  }
  OnClickAccountItem = index => {
    this.props.onClickAccountItem(index)
  }
  languageClick = value => {
    //console.log("trigger header on language select")

    //var value = e.target.value
    //console.log("language value", value)
    this.props.languageClick(value)
  }
  HideOpenDrownDown = () => {
    if ($('.profile-dropdown').hasClass('show')) {
      $('.profile-dropdown').removeClass('show')
    }
    if ($('.header_dropdown').hasClass('show')) {
      $('.header_dropdown').removeClass('show')
    }
  }
  onHeaderMenuClick = (item, target) => {
    if (this.props.headerindication == 'signup') {
      this.props.OncustomNavigate(item, false, target)
    } else {
      // let selectedLocale = localStorage.getItem("language"+this.props.eventAlias) || 'en';
      // var hash = localStorage.getItem('login_hash')
      // let location =  item+"?hash="+hash+"&URI="
      // +window.location.origin+"&alias="+this.props.eventAlias+"&locale="+selectedLocale
      // let BASE_url = new URL(process.env.REACT_APP_JOBFAIR_REDIRECT_URL)
      // let customurl = new URL(item)
      // if(customurl.hostname == BASE_url.hostname ){
      //   if(this.user == null){
      //     window.open(item,target)
      //   }else{
      //    window.open(location,target)
      //   }
      // }else{
      //  window.open(item,target)
      // }
      jobBoardNavigator(
        process.env.REACT_APP_JOBFAIR_REDIRECT_URL,
        item,
        this.props.eventAlias,
        target,
        this.user
      )
    }
  }
  OnNavigateToPage = (path, data = null) => {
    window.scrollTo(0, 0)
    if (path.indexOf('home') > -1) {
      path = '/'
    } else if (path != '/') {
      path = '/' + path
    }
    if (data == null) {
      this.props.history.push(path)
    } else {
      this.props.history.push({
        pathname: path,
        state: data
      })
    }
  }
  handleOnLogoLoaded = () => {
    let event = new CustomEvent('eventLogoImageLoaded')
    document.dispatchEvent(event)
  }

  reactiveAccount = async () => {
    this.setState({ deleteAccountLoading: true })
    await axios
      .post(
        `${this.props.event.alias}/deleteAccount/reActivate`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            apiToken: Constants.API_TOKEN,
            Authorization:
              'Bearer ' + utility.getLoggedInUser(this.props.event.alias).token
          }
        }
      )
      .then(response => {
        this.setState({
          deleteAccountLoading: false,
          accountReactivate:
            'Your Account Reactivate successfully.Please Login again'
        })
        setTimeout(() => {
          localStorage.removeItem(
            Constants.API_TOKEN + this.props.event.alias + 'user_info'
          )
          window.location.reload()
        }, 3000)
      })
      .catch(error => {
        this.setState({ deleteAccountLoading: false })
      })
  }

  render () {
    const show = this.state.menu ? 'show' : ''
    if (!this.once) {
      setTimeout(() => {
        this.HideOpenDrownDown()
      }, 99)
      this.once = true
    }
    let loggedUser = utility.getLoggedInUser(this.props.eventAlias)
    //let bottomMargin = loggedUser == null ? "mb-3" : "";
    return Constants.showVirtualZone && loggedUser && loggedUser.isBooking ? (
      <>
        {/* {this.user != null  && this.user.account_deleted == "1" ?<div class=" accountWarning alert alert-warning flex" style={{display:'flex'
    }} role="alert">
    {this.state.accountReactivate}
    {this.state.deleteAccountLoading == true ? <div> <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {25}
                                        width = {25}
                                    /></div>: null}
    <button className='btn btn-warning' onClick={()=>{
      //  this.OnNavigateToPage(`${this.props.eventAlias}/account`)
      this.reactiveAccount()
    }}>Reactive</button>
  </div>:null} */}
        <section
          id='stickyMenu'
          class={`header ${
            this.props.isMarketplace != '0' ? `bg_primary2 ` : `bg_primary`
          }  px-lg-3 pt-2 pt-md-3 pb-0`}
        >
          <div
            class={`${
              this.props.isMarketplace != '0' ? 'container' : 'container-fluid'
            } pb-3`}
          >
            <div class='row'>
              <div class='col-6 col-lg-4'>
                <Link class='navbar-brand text-light' to='/'>
                  <div class='logo-area v-zone-event-logo'>
                    {this.props.eventLogo != null ? (
                      <img
                        src={this.props.eventLogo}
                        alt='logo-img'
                        class='img-fluid'
                      />
                    ) : (
                      this.nameArray.map((item, index) => {
                        return (
                          <h4 class='text-uppercase font-weight-bold mb-0'>
                            {item}
                          </h4>
                        )
                      })
                    )}
                  </div>
                </Link>
              </div>
              <button
                id='float-btn'
                style={{
                  position: 'absolute',
                  background: 'transparent',
                  border: 'none'
                }}
                onClick={() => {
                  var agendaLink = localStorage.getItem('AgendaLink')
                  if (agendaLink != null) {
                    var locale = localStorage.getItem('videoLocale')
                    if (locale != null) {
                      agendaLink += '?locale=' + locale
                    } else {
                      agendaLink += '?locale=en'
                    }
                    this.OnNavigateToPage(
                      `${this.props.eventAlias}/agenda${agendaLink}`
                    )
                    localStorage.removeItem('AgendaLink')
                  }
                }}
              ></button>
              <div class='col-6 col-lg-8 text-right text-light position-rlative top_10'>
                <span class='ml-2 dsk-icon'>
                  {this.state.socails.map((s, i) => {
                    if (s.href) {
                      return (
                        <a
                          onClick={() => this.OnClickSocialIcon(s.href)}
                          style={{ cursor: 'pointer' }}
                          class='text-light header-social-icon'
                        >
                          <i class={s.icon}></i>
                        </a>
                      )
                    }
                  })}
                </span>
                {this.state.availableLocales &&
                this.state.availableLocales.length > 1 ? (
                  <div
                    class='dropdown dropdown-language lang-dropdown v_zone-dsk dsk-icon'
                    onClick={this.OnClickAccountDropDown}
                  >
                    <button id='dLabel' class='dropdown-select' type='button'>
                      <img
                        src={this.selectedDropDownLanguage.flag}
                        width='15'
                      />{' '}
                      {this.selectedDropDownLanguage.value.toUpperCase()}
                      <span class='caret'></span>
                    </button>
                    <ul
                      class='dropdown-menu lang-menu'
                      aria-labelledby='dLabel'
                    >
                      {this.state.availableLocales.map(obj => (
                        <li onClick={() => this.languageClick(obj.value)}>
                          <img src={obj.flag} width='15' />{' '}
                          {obj.value.toUpperCase()}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <span class='ml-2'>
                  <a
                    id='OpenEvent-menu'
                    class='text-light header-social-icon menu-btn'
                    onClick={() => {
                      $('.vz-menu-close').show()
                      $('.vz-event-menu').addClass('actives')
                      $('.vz-menu-close')
                        .removeClass('fadeouts')
                        .addClass('fadeIns')
                      $('body').addClass('modal-open')
                      if ($('.v-zone-nc').hasClass('show')) {
                        $('#icon-change-b').click()
                      }
                    }}
                  >
                    <i class='fas fa-bars mr-2 dsk-icon'></i>
                    <i class='fas fa-ellipsis-v d-lg-none'></i>
                    <span class='dsk-icon'>
                      {' '}
                      <Trans>more</Trans>{' '}
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </section>
        <div class='vz-event-menu py-4 py-lg-0'>
          <div class='event-title bg_primary px-4 py-4  d-none d-lg-block'>
            {this.props.eventName}
          </div>
          <span
            class='cross-btn'
            onClick={() => {
              $('.vz-event-menu').removeClass('actives')
              $('.vz-menu-close').removeClass('fadeIns').addClass('fadeouts')
              setTimeout(function () {
                $('.vz-menu-close').hide()
              }, 300) //Same time as animation
              $('body').removeClass('modal-open')
              //
            }}
          >
            <i class='fas fa-times'></i>
          </span>
          <ul class='nav justify-content-center mb-5 d-lg-none'>
            <li class='nav-item px-2'>
              {this.state.availableLocales &&
              this.state.availableLocales.length > 1 ? (
                <div
                  class='dropdown dropdown-language lang-dropdown v_zone'
                  onClick={() => {
                    this.OnClickAccountDropDown()
                  }}
                >
                  <button id='dLabel' class='dropdown-select' type='button'>
                    <img src={this.selectedDropDownLanguage.flag} width='15' />{' '}
                    {this.selectedDropDownLanguage.value.toUpperCase()}
                    <span class='caret'></span>
                  </button>
                  <ul class='dropdown-menu lang-menu' aria-labelledby='dLabel'>
                    {this.state.availableLocales.map(obj => (
                      <li onClick={() => this.languageClick(obj.value)}>
                        <img src={obj.flag} width='15' />{' '}
                        {obj.value.toUpperCase()}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </li>
            {this.props.phone != null ? (
              <li class='nav-item px-2 pb-3'>
                <a class='nav-link header-social-icon w-auto'>
                  <i class='fas fa-phone mr-1'></i>
                  <span style={{ cursor: 'pointer' }} onClick={this.MakeCall}>
                    {' '}
                    {this.props.phone}
                  </span>
                </a>
              </li>
            ) : null}
          </ul>
          <div class='menu-center'>
            <ul class='nav flex-column'>
              {this.props.elements.map((item, index) => {
                let activeClass =
                  this.props.selectedIndex == index &&
                  this.props.selectedSubIndex < 0
                    ? 'active'
                    : ''
                return (
                  <li class={'nav-item border-bottom'} id='sideMenu'>
                    <a
                      style={{ cursor: 'pointer' }}
                      class={'nav-link text-uppercase pl-3 ' + activeClass}
                      onClick={() => {
                        $('.vz-event-menu').removeClass('actives')
                        $('.vz-menu-close')
                          .removeClass('fadeIns')
                          .addClass('fadeouts')
                        setTimeout(function () {
                          $('.vz-menu-close').hide()
                        }, 300) //Same time as animation
                        $('body').removeClass('modal-open')
                        //
                        if (
                          !`${window.location}`.toString().includes(item.href)
                        ) {
                          item.href.includes('https') ||
                          item.href.includes('http')
                            ? this.onHeaderMenuClick(item.href, item.target)
                            : this.props.onMenuItemClick(item.href)
                        }
                      }}
                    >
                      {item.text}
                    </a>
                    {item.children ? (
                      <ul class='nav flex-column dropdown-ul pl-3 ml-3'>
                        {item.children.map((child, childIndex) => {
                          activeClass =
                            this.state.selectedSubIndex == childIndex &&
                            this.props.selectedIndex == index
                              ? 'active'
                              : ''
                          return (
                            <li class='nav-item'>
                              <a
                                style={{ cursor: 'pointer' }}
                                class={'nav-link sidemenuLink ' + activeClass}
                                onClick={() => {
                                  $('.vz-event-menu').removeClass('actives')
                                  $('.vz-menu-close')
                                    .removeClass('fadeIns')
                                    .addClass('fadeouts')
                                  setTimeout(function () {
                                    $('.vz-menu-close').hide()
                                  }, 300) //Same time as animation
                                  $('body').removeClass('modal-open')
                                  if (
                                    !`${window.location}`
                                      .toString()
                                      .includes(child.href)
                                  ) {
                                    child.href.includes('https') ||
                                    child.href.includes('http')
                                      ? this.onHeaderMenuClick(
                                          child.href,
                                          child.target
                                        )
                                      : this.props.onMenuItemClick(child.href)
                                  }
                                }}
                              >
                                {child.text}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    ) : null}
                  </li>
                )
              })}
            </ul>
            <ul class='nav justify-content-center mt-5 d-lg-none'>
              <li class='nav-item'>
                {this.state.socails.map((s, i) => {
                  if (s.href) {
                    return (
                      <a
                        onClick={() => this.OnClickSocialIcon(s.href)}
                        style={{ cursor: 'pointer' }}
                        class='nav-link header-social-icon'
                      >
                        <i class={s.icon}></i>
                      </a>
                    )
                  }
                })}
              </li>
            </ul>
            <div class='vz-menu-footer py-4 border-top text-center d-lg-none'>
              <a href='https://www.mwanmobile.com/' target='_blank'>
                <img src='/images/logos/powered-badge3.svg' alt='event-logo' />
              </a>
            </div>
          </div>
        </div>
        <div
          class='vz-menu-close'
          onClick={() => {
            $('.vz-event-menu').removeClass('actives')
            $('.vz-menu-close').removeClass('fadeIns').addClass('fadeouts')
            setTimeout(function () {
              $('.vz-menu-close').hide()
            }, 300) //Same time as animation
            $('body').removeClass('modal-open')
          }}
        ></div>
      </>
    ) : (
      <section
        id='stickyMenu'
        class={`header ${
          this.props.isMarketplace != '0' ? `bg_primary2` : `bg_primary`
        } px-md-3 pt-2 pt-md-3 pb-0`}
      >
        {loggedUser !== null ? (
          <div
            class={`${
              this.props.isMarketplace == '0' ? 'container-fluid' : 'container'
            } d-none d-lg-block `}
          >
            <div class='row'>
              <div className='col-6 col-lg-4'>
                <Link
                  id='eventTitle'
                  style={{ textDecoration: 'none' }}
                  class='text-light navbar-brand'
                  to='/'
                >
                  {this.props.eventLogo != null ? (
                    // <div class = "logo-box" style = {{
                    //    top : loggedUser != null && loggedUser.isBooking ? -61 : -70,
                    //    backgroundImage: `url(${this.props.eventLogo})`
                    // }}></div>
                    <div class='logo-area'>
                      <img
                        onLoad={this.handleOnLogoLoaded}
                        src={this.props.eventLogo}
                        alt='logo-img'
                        class='img-fluid'
                      />
                    </div>
                  ) : (
                    this.nameArray.map((item, index) => {
                      return (
                        <h4 class='text-uppercase font-weight-bold mb-0'>
                          {item}
                        </h4>
                      )
                    })
                  )}
                </Link>
              </div>
              <div
                id='profileContainer'
                class='col-md-12 col-6 col-lg-8 text-left text-lg-right text-light position-rlative top_10'
              >
                {this.props.phone != null ? (
                  <span class='ph_num border rounded p-1 mr-3'>
                    <i class='fas fa-phone mr-2'></i>
                    <span style={{ cursor: 'pointer' }} onClick={this.MakeCall}>
                      {' '}
                      {this.props.phone != null ? this.props.phone : ''}
                    </span>
                  </span>
                ) : null}
                <span class='ml-2'>
                  {this.state.socails.map((s, i) => {
                    if (s.href) {
                      return (
                        <a
                          onClick={() => this.OnClickSocialIcon(s.href)}
                          style={{ cursor: 'pointer' }}
                          class='text-light header-social-icon'
                        >
                          <i class={s.icon}></i>
                        </a>
                      )
                    }
                  })}
                </span>

                {this.state.availableLocales &&
                this.state.availableLocales.length > 1 ? (
                  <span>
                    <div
                      class='dropdown dropdown-language lang-dropdown'
                      onClick={this.OnClickAccountDropDown}
                    >
                      <button id='dLabel' class='dropdown-select' type='button'>
                        <img
                          src={this.selectedDropDownLanguage.flag}
                          width='15'
                        />{' '}
                        {this.selectedDropDownLanguage.value.toUpperCase()}
                        <span class='caret'></span>
                      </button>
                      <ul
                        class='dropdown-menu lang-menu'
                        aria-labelledby='dLabel'
                      >
                        {/* <option selected >English</option>  */}
                        {this.state.availableLocales.map(obj => (
                          <li onClick={() => this.languageClick(obj.value)}>
                            <img src={obj.flag} width='15' />{' '}
                            {obj.value.toUpperCase()}
                          </li>

                          // <option value={obj.value}>{obj.name}</option>
                        ))}
                      </ul>
                    </div>
                  </span>
                ) : null}
                {Constants.showVirtualZone == false ||
                (Constants.showVirtualZone == true &&
                  loggedUser.jobBoardProfileCompleted == 0) ||
                (Constants.showVirtualZone == true &&
                  loggedUser.jobBoardProfileCompleted == null) ||
                (Constants.showVirtualZone == true &&
                  loggedUser.jobBoardProfileCompleted == 1 &&
                  loggedUser.isBooking == false) ? (
                  <span>
                    <div class='user_btn ml-0 ml-md-3 mt-1 float-right'>
                      <div
                        class='dropdown header_dropdown'
                        onClick={this.OnClickAccountDropDown}
                      >
                        <a class='btn text-light dropdown-toggle rounded-0 p-0'>
                          {loggedUser.profileImageUrl == '' ||
                          loggedUser.profileImageUrl == null ? (
                            <i
                              class='fas fa-user-circle header-user'
                              style={{ fontSize: 25 }}
                            ></i>
                          ) : (
                            <img
                              class='profileImage'
                              src={loggedUser.profileImageUrl}
                            />
                          )}
                        </a>
                        <div class='dropdown-menu profile-dropdown dropdown-menu-right border-0'>
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(0)}
                          >
                            <Trans>myProfile</Trans>
                          </a>
                          {this.user &&
                          utility.getJobBoardAccessStatus(
                            this.user.boardType
                          ) ? (
                            <a
                              class='dropdown-item'
                              onClick={() =>
                                this.props.onClickAccountItem(null, '')
                              }
                            >
                              <Trans>visitJobBoard</Trans>
                            </a>
                          ) : null}
                          {this.props.event.isFree == 0 ? (
                            <a
                              class='dropdown-item'
                              onClick={() => this.OnClickAccountItem(1)}
                            >
                              <Trans>myBookings</Trans>
                            </a>
                          ) : null}
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(2)}
                          >
                            <Trans>myFavourites</Trans>
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(3)}
                          >
                            <Trans>changePassword</Trans>
                          </a>
                          <div class='dropdown-divider'></div>
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(-1)}
                          >
                            <Trans>logout</Trans>
                          </a>
                        </div>
                      </div>
                    </div>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {loggedUser == null ? (
          // ||
          // !loggedUser.isBooking ||
          // !Constants.showVirtualZone
          <div
            class={`${
              this.props.isMarketplace == '0' ? 'container-fluid' : 'container'
            } d-none d-lg-block `}
          >
            {/* <span>{document.documentElement.scrollHeight}</span> */}

            <div class='row'>
              <div className='col-6 col-lg-4'>
                <Link
                  id='eventTitle'
                  style={{ textDecoration: 'none' }}
                  class='text-light navbar-brand'
                  to='/'
                >
                  {this.props.eventLogo != null ? (
                    // <div class = "logo-box" style = {{
                    //    top : loggedUser != null && loggedUser.isBooking ? -61 : -70,
                    //    backgroundImage: `url(${this.props.eventLogo})`
                    // }}></div>
                    <div class='logo-area'>
                      <img
                        onLoad={this.handleOnLogoLoaded}
                        src={this.props.eventLogo}
                        alt='logo-img'
                        class='img-fluid'
                      />
                    </div>
                  ) : (
                    this.nameArray.map((item, index) => {
                      return (
                        <h4 class='text-uppercase font-weight-bold mb-0'>
                          {item}
                        </h4>
                      )
                    })
                  )}
                </Link>
              </div>
              <div class='col-md-12 col-6 col-lg-8  text-left text-lg-right text-light position-rlative top_10'>
                {/* {this.props.phone != null ? (
                  <span class="ph_num border rounded p-1 mr-3">
                    <span style={{ cursor: "pointer" }} onClick={this.MakeCall}>
                      {" "}
                      {this.props.phone != null ? this.props.phone : ""}
                    </span>
                  </span>
                ) : null}
                <span
                  class={
                    loggedUser == null ||
                    !loggedUser.isBooking ||
                    !Constants.showVirtualZone
                      ? "ml-2 socialIcons"
                      : "ml-2"
                  }
                >
                  {this.state.socails.map((s, i) => {
                    if (s.href) {
                      return (
                        <a
                          onClick={() => this.OnClickSocialIcon(s.href)}
                          style={{ cursor: "pointer" }}
                          class="text-light"
                        >
                          <i class={s.icon}></i>
                        </a>
                      );
                    }
                  })}

                </span>
                */}
                {loggedUser === null ? (
                  // <div class={"container-fluid d-none d-lg-block " }>
                  //<div class="row">
                  //<div class="col-md-12 text-left text-lg-right text-light position-rlative top_10">
                  <div>
                    {/* <span class="ph_num border rounded p-1 mr-3">
                <i class="fas fa-phone mr-2"></i>
              <span>+9714 299 9389</span>
              </span> */}
                    {this.props.phone != null ? (
                      <span class='nav-link header-social-icon w-auto'>
                        <i class='fas fa-phone mr-2'></i>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={this.MakeCall}
                        >
                          {' '}
                          {this.props.phone != null ? this.props.phone : ''}
                        </span>
                      </span>
                    ) : null}

                    <span class='ml-2'>
                      {this.state.socails.map((s, i) => {
                        if (s.href) {
                          return (
                            <a
                              onClick={() => this.OnClickSocialIcon(s.href)}
                              style={{ cursor: 'pointer' }}
                              class='text-light header-social-icon'
                            >
                              <i class={s.icon}></i>
                            </a>
                          )
                        }
                      })}
                      {/* <a href = "" class = "text-light header-social-icon">
						      <i class="fab fa-facebook-f"></i>
					      </a>
					      <a href = "" class = "text-light header-social-icon">
						      <i class="fab fa-twitter"></i>
					      </a>
					      <a href = "" class = "text-light header-social-icon">
                  <i class="fab fa-linkedin-in"></i>
					      </a> */}
                    </span>

                    {this.state.availableLocales &&
                    this.state.availableLocales.length > 1 ? (
                      <span>
                        <div
                          class='dropdown dropdown-language lang-dropdown'
                          onClick={this.OnClickAccountDropDown}
                        >
                          <button
                            id='dLabel'
                            class='dropdown-select'
                            type='button'
                          >
                            <img
                              src={this.selectedDropDownLanguage.flag}
                              width='15'
                            />{' '}
                            {this.selectedDropDownLanguage.value.toUpperCase()}
                            <span class='caret'></span>
                          </button>

                          <ul
                            class='dropdown-menu lang-menu'
                            aria-labelledby='dLabel'
                          >
                            {/* <option selected >English</option>  */}
                            {this.state.availableLocales.map(obj => (
                              <li onClick={() => this.languageClick(obj.value)}>
                                <img src={obj.flag} width='15' />{' '}
                                {obj.value.toUpperCase()}
                              </li>

                              // <option value={obj.value}>{obj.name}</option>
                            ))}
                          </ul>
                        </div>
                      </span>
                    ) : null}

                    {/* {process.env.REACT_APP_ENV_NAME !== "production" ?  */}
                    {this.props.event.is_jobfair == 1 ? (
                      <span class='ml-0 ml-md-3 float-left float-sm-right mt-1'>
                        <a
                          onClick={this.props.onSignupClick}
                          style={{ cursor: 'pointer' }}
                          class={`bg_secondary py-1 px-3 mr-2`}
                        >
                          <Trans>register-bth</Trans>
                        </a>
                      </span>
                    ) : (
                      <span class='ml-0 ml-md-3 float-left float-sm-right mt-1'>
                        <a
                          onClick={this.props.onLoginClick}
                          style={{ cursor: 'pointer' }}
                          class={`
                      bg_secondary
                       py-1 px-3 mr-2`}
                        >
                          <Trans>login</Trans>
                        </a>
                        <a
                          onClick={this.props.onSignupClick}
                          style={{ cursor: 'pointer' }}
                          class={`bg_secondary py-1 px-3 mr-2`}
                        >
                          {this.props.isMarketplace == 1 ? (
                            'Join Now'
                          ) : (
                            <Trans>signUp</Trans>
                          )}
                        </a>
                      </span>
                    )}
                    {/* : null}  */}
                  </div>
                ) : //</div>
                //</div>
                //</div>
                null}
              </div>
            </div>
          </div>
        ) : null}

        <div
          class={`${
            this.props.isMarketplace == '0' ? 'container-fluid' : 'container'
          } position-rlative top_10 d-none d-lg-block`}
          id='stickyMenu'
        >
          <div class='row'>
            <div class='col-12'>
              <nav class='navbar navbar-expand-lg pl-0 pr-0 pb-0'>
                {/* <Link
                  id="eventTitle"
                  style={{ textDecoration: "none" }}
                  class="text-light navbar-brand"
                  to="/"
                >
                  {this.props.eventLogo != null ? (
                    // <div class = "logo-box" style = {{
                    //    top : loggedUser != null && loggedUser.isBooking ? -61 : -70,
                    //    backgroundImage: `url(${this.props.eventLogo})`
                    // }}></div>
                    <div class="logo-area">
                      <img
                        onLoad={this.handleOnLogoLoaded}
                        src={this.props.eventLogo}
                        alt="logo-img"
                        class="img-fluid"
                      />
                    </div>
                  ) : (
                    this.nameArray.map((item, index) => {
                      return (
                        <h4 class="text-uppercase font-weight-bold mb-0">
                          {item}
                        </h4>
                      );
                    })
                  )}

                </Link> */}
                <button
                  class='navbar-toggler'
                  type='button'
                  data-toggle='collapse'
                  onClick={this.toggleCollapse('collapsibleNavbar')}
                >
                  <span class='navbar-toggler-icon'></span>
                </button>

                <div class='collapse navbar-collapse' id='collapsibleNavbar'>
                  <ul class='navbar-nav text-center p-3 p-lg-0 nav-fill w-100'>
                    {/* works here  */}
                    {this.props.elements.map((item, index) => {
                      return (
                        <li
                          class={`nav-item dropdown ${
                            this.props.selectedIndex == index ? 'active' : ''
                          }`}
                        >
                          {item.children != null && item.children.length > 0 ? (
                            <>
                              <a
                                class={
                                  this.props.selectedIndex == index
                                    ? `nav-link text-light dropdown-toggle active`
                                    : 'nav-link text-light dropdown-toggle'
                                }
                                id='navbarDropdownMenuLink'
                                // data-toggle="dropdown"
                                aria-haspopup='true'
                                aria-expanded='false'
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  item.title != 'More'
                                    ? item.href.includes('https') ||
                                      item.href.includes('http')
                                      ? this.onHeaderMenuClick(
                                          item.href,
                                          item.target
                                        )
                                      : this.props.onMenuItemClick(item.href)
                                    : {}
                                }
                              >
                                {item.text}
                              </a>

                              <ul
                                class='dropdown-menu bg_primary  bg_primary_dropdown dropdown-menu-right swing-in-top-fwd'
                                aria-labelledby='navbarDropdownMenuLink'
                              >
                                {item.children.map((item, childIndex) => {
                                  return item.children != null &&
                                    item.children.length > 0 ? (
                                    <li class='dropdown dropdown-submenu'>
                                      <a
                                        class='dropdown-item dropdown-toggle nav-submenu'
                                        id='submenu'
                                        // data-toggle="dropdown"
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          if (
                                            !`${window.location}`
                                              .toString()
                                              .includes(item.href)
                                          ) {
                                            item.href.includes('https') ||
                                            item.href.includes('http')
                                              ? this.onHeaderMenuClick(
                                                  item.href,
                                                  item.target
                                                )
                                              : this.props.onMenuItemClick(
                                                  item.href
                                                )
                                          }
                                        }}
                                      >
                                        {item.text}
                                      </a>

                                      <ul
                                        style={{
                                          left:
                                            index ==
                                            this.props.elements.length - 1
                                              ? -160
                                              : -160
                                        }}
                                        class='dropdown-menu bg_primary bg_primary_dropdown swing-in-top-fwd'
                                        aria-labelledby='submenu'
                                      >
                                        {item.children.map((item, index) => {
                                          return (
                                            <li>
                                              <a
                                                class='dropdown-item bg_primary nav-submenu'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                  if (
                                                    !`${window.location}`
                                                      .toString()
                                                      .includes(item.href)
                                                  ) {
                                                    item.href.includes(
                                                      'https'
                                                    ) ||
                                                    item.href.includes('http')
                                                      ? this.onHeaderMenuClick(
                                                          item.href,
                                                          item.target
                                                        )
                                                      : this.props.onMenuItemClick(
                                                          item.href
                                                        )
                                                  }
                                                }}
                                              >
                                                {item.text}
                                              </a>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </li>
                                  ) : (
                                    <a
                                      class='dropdown-item'
                                      id='navbarDropdownMenuLink'
                                      // data-toggle="dropdown"
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        if (
                                          !`${window.location}`
                                            .toString()
                                            .includes(item.href)
                                        ) {
                                          item.href.includes('https') ||
                                          item.href.includes('http')
                                            ? this.onHeaderMenuClick(
                                                item.href,
                                                item.target
                                              )
                                            : this.props.onMenuItemClick(
                                                item.href
                                              )
                                        }
                                      }}
                                    >
                                      {item.text}
                                    </a>
                                  )
                                })}
                              </ul>
                            </>
                          ) : (
                            <a
                              class={
                                this.props.selectedIndex == index
                                  ? `nav-link text-light active`
                                  : 'nav-link text-light'
                              }
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (
                                  !`${window.location}`
                                    .toString()
                                    .includes(item.href)
                                ) {
                                  item.href.includes('https') ||
                                  item.href.includes('http')
                                    ? this.onHeaderMenuClick(
                                        item.href,
                                        item.target
                                      )
                                    : this.props.onMenuItemClick(item.href)
                                }
                              }}
                            >
                              {item.text}
                            </a>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* Language dropdown for mobile */}
        <div
          class='container-fluid position-rlative d-block d-lg-none px-0'
          id='sticky'
        >
          <div class='row mx-lg-3'>
            <div class='col-md-12'>
              <div>
                <nav
                  class='navbar navbar-expand-lg p-0 mobile-menus border-bottom'
                  id='header-id'
                >
                  <button
                    class='navbar-toggler mynav_toggler mynav_toggler-b'
                    type='button'
                    onClick={this.toggleCollapse('collapsibleNavbar')}
                  >
                    <i
                      class={
                        this.state.collapseID == ''
                          ? 'fa fa-bars'
                          : 'fas fa-times'
                      }
                      id='icon-change'
                      aria-hidden='true'
                    ></i>
                    {/* <span class="text-menu mx-2" id="Menu-Close">
                    {this.state.collapseID == "" ? <Trans>menu</Trans> : <Trans>close</Trans>}
                  </span> */}
                  </button>
                  {this.state.availableLocales &&
                  this.state.availableLocales.length > 1 ? (
                    <div
                      class='dropdown dropdown-language lang-dropdown'
                      onClick={this.OnClickAccountDropDown}
                    >
                      <button id='dLabel' class='dropdown-select' type='button'>
                        <img
                          src={this.selectedDropDownLanguage.flag}
                          width='15'
                        />{' '}
                        {this.selectedDropDownLanguage.value.toUpperCase()}
                        <span class='caret'></span>
                      </button>
                      <ul
                        class='dropdown-menu lang-menu'
                        aria-labelledby='dLabel'
                      >
                        {/* <option selected >English</option>  */}
                        {this.state.availableLocales.map(obj => (
                          <li onClick={() => this.languageClick(obj.value)}>
                            <img src={obj.flag} width='15' />{' '}
                            {obj.value.toUpperCase()}
                          </li>

                          // <option value={obj.value}>{obj.name}</option>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  {loggedUser === null ? (
                    // process.env.REACT_APP_ENV_NAME !== "production" ?
                    <ul class='list-inline mb-0 bg-transparent p-1 mob-btn-list mr-2'>
                      {this.props.event.is_jobfair == 0 ? (
                        <>
                          <li class='list-inline-item border-0'>
                            <a
                              onClick={this.props.onLoginClick}
                              class='btn bg_secondary bookin-btn px-3'
                            >
                              <Trans>login</Trans>
                            </a>
                          </li>
                          <li class='list-inline-item border-0'>
                            <a
                              onClick={this.props.onSignupClick}
                              class='btn bg_secondary bookin-btn px-3'
                            >
                              {this.props.isMarketplace == 1 ? (
                                'Join Now'
                              ) : (
                                <Trans>signUp</Trans>
                              )}
                            </a>
                          </li>
                        </>
                      ) : (
                        <li class='list-inline-item border-0'>
                          <a
                            onClick={this.props.onSignupClick}
                            class='btn bg_secondary bookin-btn px-3'
                          >
                            <Trans>register-bth</Trans>
                          </a>
                        </li>
                      )}
                    </ul>
                  ) : //  : null

                  // <ul class="list-inline mb-0 bg-transparent p-1 mob-btn-list mr-2">
                  //   <li class="list-inline-item border-0">
                  //     <a
                  //       onClick={this.props.onLoginClick}
                  //       class="btn bg_secondary bookin-btn px-3"
                  //     >
                  //       <Trans>login</Trans>
                  //     </a>
                  //   </li>
                  //   <li class="list-inline-item border-0">
                  //     <a
                  //       onClick={this.props.onSignupClick}
                  //       class="btn bg_secondary bookin-btn px-3"
                  //     >
                  //       <Trans>signUp</Trans>
                  //     </a>
                  //   </li>
                  // </ul>

                  !loggedUser.isBooking || !Constants.showVirtualZone ? (
                    <div class='user_btn'>
                      <div
                        class='dropdown header_dropdown navigation'
                        onClick={this.OnClickAccountDropDown}
                      >
                        <a class='btn dropdown-toggle rounded-0'>
                          <span class='user-short-name mx-2'>
                            {loggedUser.firstName
                              .substring(0, 1)
                              .toUpperCase() +
                              ' ' +
                              loggedUser.lastName !=
                            null
                              ? loggedUser.lastName
                              : ''}
                          </span>
                          {loggedUser.profileImageUrl == '' ||
                          loggedUser.profileImageUrl == null ? (
                            <i
                              class='fas fa-user-circle header-user'
                              style={{ fontSize: 25 }}
                            ></i>
                          ) : (
                            <img
                              class='profileImage'
                              src={loggedUser.profileImageUrl}
                            />
                          )}
                        </a>
                        <div class='dropdown-menu profile-dropdown dropdown-menu-right border-0 acc_sec_dropdown'>
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(0)}
                          >
                            My Account
                          </a>
                          {this.user &&
                          utility.getJobBoardAccessStatus(
                            this.user.boardType
                          ) ? (
                            <a
                              class='dropdown-item'
                              onClick={() =>
                                this.props.onClickAccountItem(null, '')
                              }
                            >
                              Job Seeker Profile
                            </a>
                          ) : null}
                          {this.props.event.isFree == 1 ? null : (
                            <a
                              class='dropdown-item'
                              onClick={() => this.OnClickAccountItem(1)}
                            >
                              My Bookings
                            </a>
                          )}
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(2)}
                          >
                            My Favourites
                          </a>
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(3)}
                          >
                            Change Password
                          </a>
                          <div class='dropdown-divider'></div>
                          <a
                            class='dropdown-item'
                            onClick={() => this.OnClickAccountItem(-1)}
                          >
                            Log out
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <MDBCollapse
                    className='navbar-collapse'
                    id='collapsibleNavbar'
                    isOpen={this.state.collapseID}
                  >
                    <ul class={`navbar-nav text-center ml-auto p-0`}>
                      {this.props.elements.map((item, index) => {
                        return (
                          <li
                            class={
                              this.props.selectedIndex == index
                                ? `nav-item active`
                                : 'nav-item'
                            }
                          >
                            <a
                              class={
                                this.props.selectedIndex == index
                                  ? `nav-link text-light`
                                  : 'nav-link text-light'
                              }
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (
                                  !`${window.location}`
                                    .toString()
                                    .includes(item.href)
                                ) {
                                  item.href.includes('https') ||
                                  item.href.includes('http')
                                    ? this.onHeaderMenuClick(
                                        item.href,
                                        item.target
                                      )
                                    : this.props.onMenuItemClick(item.href)
                                }
                              }}
                            >
                              {item.text}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </MDBCollapse>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class='container-fluid d-block d-lg-none py-2 bg-white logo-section-mob'>
          <div class='row'>
            <div class='col-12'>
              <Link className='navbar-brand text-light' to='/'>
                {this.props.eventLogo != null ? (
                  <img src={this.props.eventLogo} alt='logo-img' />
                ) : (
                  this.nameArray.map((item, index) => {
                    return (
                      <h4 class='text-uppercase font-weight-bold mb-0'>
                        {item}
                      </h4>
                    )
                  })
                )}
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(Header)
