import React,{useState,useEffect,useRef} from 'react'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Helmet from 'react-helmet'
import Header from '../Header/Index';
import { Constants } from '../../Constants';
import Footer1 from '../Footer/Footer1'
import Footer2 from '../Footer/Footer2'
import MainView from './mainView';
import OneonOneMeetingView from './1on1MeetingView';
import RoundTableView from './RoundTable';
import RoundTableSeatBookingView from './RoundTable/seatBooking';
import api from './api'
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import { useLastLocation } from 'react-router-last-location';
import NetworkingGala from './Networking Gala';
import utility from '../../Utility/utility';
import $ from 'jquery'
import VirtualZone from '../Header/virtualZone';
import { MeetingsLoaded } from './actions';
import { GAPageView, GARecordEvent } from '../../Utility/GA';
var meetingScreens = {
    "101meeting" : 1,
    "roundtable" : 2,
    "gala" : 4
}
function Networking(props){
    const [selectedIndex,setSelectedIndex] = useState(props.headerMenuItems.findIndex(x=>x.href.indexOf('/networking') > -1) || -1)
    const [selectedSubIndex,setSelectedSubIndex] = useState(-1)
    const [menu,setMenu] = useState(props.headerMenuItems.find(x=>x.href == (props.event.alias+"/networking")))
    const [screenId,setScreenId] = useState(0)
    const [meetingsData,setMeetingsData] = useState(null)
    const [loadingMeetings,setMeetingLoadStatus] = useState(false)
    const [loadMeetingsError,setLoadMeetingsError] = useState('')
    const [singleMeetingData,setMeetingData ] = useState({})
    const [selectedMeetingIndex,setMeetingIndex] = useState(0)
    const [userBookedSlots,setUserBookedSlots] = useState({})
    const [passedMeetingId,setPassedMeetingId] = useState(-1)
    const [tempMeetingIndex,setTempMeetingIndex] = useState(null)
    const [mainViewNavigation,setMainViewNavigation] = useState(true)
    const [selectedRoundTableMeetingIndex,setSelectedRoundMeetingIndex] = useState(0)
    const [showComingSoonView,setComingSoomStatus] = useState(false)
    const [bookingDisabled,setBookingDisabled] = useState([])
    const isFirstRender = useRef(null)
    const lastLocation = useLastLocation()
    const eventUser =utility.getLoggedInUser(props.event.alias)
    useEffect(()=>{
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        let data = props.location.state
        if(data != null && lastLocation != null){
            setPassedMeetingId(data.meetingId)
            localStorage.setItem("meetingId",data.meetingId)
        }else{
            let meetingIdTemp = localStorage.getItem("meetingId")
            if(meetingIdTemp != null){
                setPassedMeetingId(meetingIdTemp)
                localStorage.removeItem("meetingId")
            }else{
                setPassedMeetingId(-1)
            }
        }
        if(selectedIndex == -1){
            for(var i=0;i<props.headerMenuItems.length;i++){
                var result = utility.searchTree(props.headerMenuItems[i],'Networking')
                if(result != null){
                    setMenu(result)
                    let selectedSubIndex = props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/networking') > -1)
                    setSelectedIndex(i)
                    setSelectedSubIndex(selectedSubIndex)
                    break
                }
            }
        }
        if(props.meetings == null){
            LoadMeetings()
        }
        else{
            setMeetingsData(props.meetings)
        }
        document.addEventListener('navigateToLogin',(event)=>{
            OnNavigateToPage(props.event.alias+"/login")
        })
    },[])
    useEffect(()=>{
        if(isFirstRender.current){
            if(passedMeetingId > -1){
                let meetingIndex = meetingsData.meetings.findIndex(x=>x.id == passedMeetingId)
                setTempMeetingIndex(meetingIndex)
            }
        }
    },[meetingsData])
    const LoadMeetings = () => {
        setMeetingLoadStatus(true)
        api.getMeetings(props.event.alias,(err,data)=>{
            if(err){
                setLoadMeetingsError(err.message)
            }else{
                isFirstRender.current = {}
                setMeetingsData(data)
                props.meetingsLoaded(data)
                if(data.meetings.length == 0){
                    setComingSoomStatus(true)
                }
            }
            setMeetingLoadStatus(false)
        })
    }
    const OnNavigateToPage = (path) => {
        if(path == props.event.alias+"/networking"){
            // if($('.dropdown-menu-right1').hasClass('show')){
            //     $('.dropdown-menu-right1').removeClass('show')
            // }
            // setScreenId(0)
            window.location.reload()
        }
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(path.indexOf("login") < 0){
            localStorage.removeItem("selectedMeetingIndex")
        }
        props.history.push(path)
    }
    const languageClick = (value) =>{
        props.languageClick(value);
    }
    const OnClickAccountItem = (index,path) => {
        props.onClickAccountItem(index,path);  
        if(index > -1){
            props.history.replace('')
            props.history.push({
                pathname : props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+props.event.alias+'user_info')
            window.location.reload()
        }
    }
    const OnClickMeetingJoin = (index,type)=> {
        setMeetingIndex(index)
    }
    const SingleMeetingDataLoaded = (type,data,userBookedSlotsData,fullScreenLoadingView) => {
        setMeetingData(data)
        if(userBookedSlotsData != null){
            setUserBookedSlots(userBookedSlotsData)
        }
        setMainViewNavigation(fullScreenLoadingView)
        setScreenId(meetingScreens[type])
        window.scrollTo(0,0)
    }
    const OnPressRetryMeetings = () => {
        LoadMeetings()
    }
    const OnRemoveMeetingIndex = () => {
        setTempMeetingIndex(null)
    }
    const OnClickBookRoundTableButton = (index) => {
        window.scrollTo(0,0)
        setSelectedRoundMeetingIndex(index)
        setScreenId(3)
    }
    const joinGalaButton = (meetingName) => {
        GARecordEvent("join"+meetingName+"Clicked", eventUser ? eventUser.id : 'anonymous user',{
            category : 'Join'+''+meetingName,
            action : 'Join'+''+meetingName+'clicked',
            userName: eventUser ? (eventUser.firstName+''+eventUser.lastName) : 'anonymous user',
            userEmail: eventUser ? eventUser.email : 'anonymous user'
        })
    }
    const OnClickMeetingName = () => {
        window.scrollTo(0,0)
        setScreenId(meetingScreens[meetingsData.meetings[selectedMeetingIndex].type])
    }
    //used for round table and networking seat booking
    const OnSeatBooked = (id,bookedSeats) => {
        if(bookingDisabled.indexOf(id) < 0){
            bookingDisabled.push(id)
            setBookingDisabled([...bookingDisabled])
        }
        singleMeetingData[selectedRoundTableMeetingIndex].bookedSeats = bookedSeats
    }
    const OnClickNetworkingTab = () => {
        window.scrollTo(0,0)
        setScreenId(0)
    }
    // const OnRemoveMeeting = (type) => {
    //     let meetings = [...meetingsData.meetings]
    //     let index = meetings.findIndex(x=>x.type == type)
    //     meetings.splice(index,1)
    //     meetingsData.meetings = meetings
    //     setMeetingData(meetingsData)
    // }
    const getClasses = ()=>{
        if(props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    let user = eventUser;
    return(
       <div>
           <Helmet>
                <meta charSet="utf-8" />
                <title>{menu != null ? menu.title : "Agenda"}</title>
                <meta property="description" content={props.seoData == null ? "" : props.seoData.meta_description} />
                <meta property="title" content={props.seoData == null ? "" : props.seoData.seo_title} />
            </Helmet>
            <div id="header-top">
            <Header
                selectedIndex = {selectedIndex}
                selectedSubIndex = {selectedSubIndex}
                onMenuItemClick = {OnNavigateToPage}
                elements = {props.headerMenuItems}
                facebookLink = {props.event.facebook}
                isMarketplace = {props.event.is_marketplace}
                linkedInLink = {props.event.linkedIn}
                twitterLink = {props.event.twitter}
                instagramLink = {props.event.instagram}
                googleLink = {props.event.google}
                phone = {props.event.phone_number}
                eventLogo = {props.event.logo}
                event = {props.event}
                eventName = {props.event.name}
                eventAlias = {props.event.alias}
                onLoginClick = {()=>OnNavigateToPage(props.event.alias+'/login')}
                onSignupClick = {()=>OnNavigateToPage(props.event.alias+'/sign_up')}
                availableLocales = {props.availableLocales}
                languageClick  = {languageClick}
                onClickAccountItem = {OnClickAccountItem}
            />
            {user != null && user.isBooking && Constants.showVirtualZone?
            <VirtualZone
                
                eventAlias = {props.event.alias}
                event = {props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        OnNavigateToPage(path)
                    }
                }}  
                unReadCount = {props.unReadCount}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                notificationsCount = {props.unReadNotificationsCount}
                notifications = {props.notifications}
                onClickAccountItem = {OnClickAccountItem}
                onClickNotification = {(path)=>OnNavigateToPage(props.event.alias+path)}
                menuItems = {props.virtualMenuItems}
                user = {user} 
            /> : null}
            </div>
            <div className = {`content-main`}>
            {loadingMeetings ?
                <section class = "blog networking py-3 py-md-5">
                <div style = {{width : '100%',height : "calc(60vh - 16px)",display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                    <Loader
                        type = 'Oval'
                        color = "#00b6ff"
                        height = {40}
                        width = {40}
                    />
                </div> </section> :
                loadMeetingsError != "" ?
                <section class = "blog networking py-3 py-md-5">
                <ErrorScreen 
                    message = {loadMeetingsError}
                    onPressRetry = {OnPressRetryMeetings}
                /> </section>: 
                showComingSoonView ?
                <div className = "text-center bg-light">
                    <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                </div>:
                screenId == 0 && meetingsData != null?
                <MainView
                    meetingsData = {meetingsData}
                    eventName = {props.event.name}
                    eventAlias = {props.event.alias}
                    onClickJoin = {OnClickMeetingJoin}
                    navigate = {OnNavigateToPage}
                    dataLoaded = {SingleMeetingDataLoaded}
                    selectedMeetingIndex = {tempMeetingIndex}
                    removeMeetingIndex = {OnRemoveMeetingIndex}
                    joinGalaButton ={joinGalaButton}
                    showFullScreenLoader = {passedMeetingId > -1}
                    // removeMeeting = {OnRemoveMeeting}
                />: screenId == 1 ?
                <OneonOneMeetingView
                    eventAlias = {props.event.alias}
                    meetingName = {meetingsData.meetings[selectedMeetingIndex].meeting_heading}
                    meetingDescription = {meetingsData.meetings[selectedMeetingIndex].meeting_sub_heading}
                    meetingId = {meetingsData.meetings[selectedMeetingIndex].id}
                    meetingType = {meetingsData.meetings[selectedMeetingIndex].type}
                    meetingData = {singleMeetingData}
                    userBookedSlots = {userBookedSlots}
                    mainViewNavigationEnabled = {mainViewNavigation}
                    onClickNetworking = {OnClickNetworkingTab}
                /> : screenId == 2 ?
                <RoundTableView
                    eventAlias = {props.event.alias}
                    meetingName = {meetingsData.meetings[selectedMeetingIndex].meeting_heading}
                    meetingDescription = {meetingsData.meetings[selectedMeetingIndex].meeting_sub_heading}
                    meetingId = {meetingsData.meetings[selectedMeetingIndex].id}
                    meetingType = {meetingsData.meetings[selectedMeetingIndex].type}
                    meetings = {singleMeetingData}
                    onClickNetworking = {OnClickNetworkingTab}
                    onClickBook = {OnClickBookRoundTableButton}
                /> : screenId == 3 ? 
                <RoundTableSeatBookingView
                    eventAlias = {props.event.alias}
                    meetingName = {meetingsData.meetings[selectedMeetingIndex].meeting_heading}
                    meetingId = {meetingsData.meetings[selectedMeetingIndex].id}
                    meetingType = {meetingsData.meetings[selectedMeetingIndex].type}
                    selectedMeeting = {singleMeetingData[selectedRoundTableMeetingIndex]}
                    bookingDisabled = {bookingDisabled}
                    onClickNetworking = {OnClickNetworkingTab}
                    onClickMeetingName = {OnClickMeetingName}
                    onSeatBooked = {OnSeatBooked}
                /> : screenId == 4 ?
                <NetworkingGala
                    eventAlias = {props.event.alias}
                    meetingName = {meetingsData.meetings[selectedMeetingIndex].meeting_heading}
                    meetingDescription = {meetingsData.meetings[selectedMeetingIndex].meeting_sub_heading}
                    meetingId = {meetingsData.meetings[selectedMeetingIndex].id}
                    meetingType = {meetingsData.meetings[selectedMeetingIndex].type}
                    meeting = {singleMeetingData}
                    onClickNetworking = {OnClickNetworkingTab}
                    onClickMeetingName = {OnClickMeetingName}
                /> : null
            }
            <Footer1 
                event = {props.event}
                elements = {props.footerMenuItems}
                onMenuItemClick = {OnNavigateToPage}
            />
        </div>
        <Footer2 
           event = {props.event}
            copyRightLogo = {props.event.copyright_logo}
            copyRightText = {props.event.copyright_text}
            copyRightLink = {props.event.copyright_link}
            showCopyRight = {props.event.copyright_text || props.event.copyright_logo}
        />
       </div>
    )
}
const mapStateToProps = state => ({
    meetings : state.networking.meetings
})
const mapDispatchToProps = dispatch => ({
    meetingsLoaded : data => dispatch(MeetingsLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Networking);
