import React from 'react';
import {Trans} from 'react-i18next' 
class Content extends React.Component{
    
    render(){
        return(
            <div class = "content">
            <section class = "delegate-details my-5">
                <div class="container bg-light border py-2 text-center">
                    <h4 class = "text-uppercase f-600 my-4"><Trans>thankyou</Trans></h4>
                    <h5 class = "text-uppercase my-3"> <Trans>lookForward</Trans></h5>
                    <div class="row  py-3">
                        <div class="col-12 px-5">
                        
                            <p>
                                <Trans>customerService</Trans><br/> <span style = {{color : '#007bff'}}>{this.props.userEmail}</span>
                                <Trans>within</Trans> 
                            </p>
                            
                            <p>
                                <Trans>anyError</Trans> <br/>  <a href = {"mailto:"+this.props.eventEmail} >{this.props.eventEmail}</a>
                                 <Trans>orCall</Trans> {this.props.eventPhone}. 
                            </p>
                            
                            <p>
                                <Trans>checkFolder</Trans>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}
export default Content
