import axios from '../axios/index'
import { Constants } from '../../Constants'
import utility, { monthNames } from '../../Utility/utility'

export default {
  getHomeVideos (eventAlias, params, callback) {
    axios
      .get(`${eventAlias}/videos/home`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          apiToken: Constants.API_TOKEN
        }
      })
      .then(response => {
        if (response.data.status == 'success') {
          for (var i = 0; i < response.data.videos.length; i++) {
            let date = utility.convertUTCDateToLocalDate(
              new Date(response.data.videos[i].created_at)
            )
            let dateString =
              date.getDate() +
              ' ' +
              monthNames[date.getMonth() - 1] +
              ' ' +
              date.getFullYear()
            response.data.videos[i].dateString = dateString
            if (response.data.videos[i].link != null) {
              // let id = utility.getYoutubeVideoId(response.data.videos[i].link)
              // response.data.videos[i].link = "https://www.youtube.com/embed/"+id
              if (
                response.data.videos[i].link?.includes('player.vimeo') ||
                response.data.videos[i].link?.includes('vimeo')
              ) {
                response.data.videos[i].link = response.data.videos[
                  i
                ].link?.includes('player.vimeo')
                  ? response.data.videos[i].link
                  : response.data.videos[i].link?.replace(
                      'vimeo.com',
                      'player.vimeo.com/video'
                    )
              } else {
                let id = utility.getYoutubeVideoId(response.data.videos[i].link)
                response.data.videos[i].link =
                  'https://www.youtube.com/embed/' + id
              }
            }
          }
          callback(
            null,
            response.data.videos,
            response.data.page_details.homepage_title
          )
        } else {
          callback(
            {
              message: 'Error'
            },
            null
          )
        }
      })
      .catch(error => {
        callback(error, null)
      })
  }
}
