import axios from '../../axios/index'
import { Constants } from '../../../Constants';
var fileDownload = require('js-file-download');
export default {
    async getLobby(eventAlias,token,callback){
        await axios.get(`${eventAlias}/getEventLobby`,
        {
            'Content-Type': 'application/json',
            headers: 
            { 
                'apiToken': Constants.API_TOKEN ,
                'Authorization' : 'Bearer '+token},
        }).then(response=>{
            callback(null,response.data.lobby)
        }).catch(error=>{
            callback({
                message : 'Something went wrong'
            },null)
        });
    },
    downloadBroucher(eventAlias,id,title,callback){
        axios.get(`${eventAlias}/downloadLobbyBranding`,
            {
                timeout : 120000,
                responseType: 'arraybuffer',
                params: {brandingId : id,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            fileDownload(response.data,`${title}.pdf`)
            callback(null,{})
        }).catch(error => {
            callback(error,null)
        })
    }
}