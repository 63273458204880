import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import './styles.css'
import { MDBCollapse } from "mdbreact";
import Lightbox from 'react-image-lightbox';
import{Trans} from 'react-i18next'
export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            collapseID : '',
            showImageSlider : false
        };
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    OnClickShowImageSlider = () => {
        this.setState({showImageSlider : true})
    }
    render(){
        let image = this.props.textBlock.length > 0 ? this.props.textBlock[0].image : ''
        const divStyle = {
            backgroundImage: `url(${image})`,
            backgroundPosition : 'top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: this.props.textBlock.length > 0 ? '75vh' : 0,
            position: 'relative'
        };
        return(
            <div>
                <section class = "about_banner" style = {divStyle}>
                    
                </section>

                <section class = "about-detail py-3">
                    <div class="container">
                        {this.props.textBlock.length > 0 ? <h3 class = "font-weight-bold text-main mt-3">{this.props.textBlock[0].heading}</h3> : null}
                        {this.props.textBlock.length > 0 ?<h5 class = "font-weight-bold text-main mt-3">{this.props.textBlock[0].sub_heading}</h5> : null}
                        <div class="row py-3">
                            {this.props.textBlock.length > 0 ?
                            <div class="col-12 mt-3 mb-2">
                                
                               {ReactHtmlParser(this.props.textBlock[0].details)}

                            </div> : null}
                            {this.props.eTextBlock.map((item,index)=>{
                                return(
                                <div class="col-12 mb-2">
                                    <div class="company_address bg-light p-4 border">
                                        <h4 class = "font-weight-bold">{item.heading}</h4>
                                        <h6 class = "font-weight-bold">{item.sub_heading}</h6>
                                        <p class = "f-500 mt-3">
                                            {ReactHtmlParser(item.details)}
                                        </p>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
                {this.props.exibitors.length == 0 && this.props.floorPlan.length == 0 ? null :
                <section class = "exhibitors sponsors_detail py-3 bg-light">
                    {this.props.exibitors.length > 0 ?
                    <div class ="container py-3">
                            <div class="col-12">
                                <h5 class = "f-500 text-main my-3"><Trans>exhibitors</Trans></h5>
                            </div>
                            {this.props.exibitors.map((item,parentIndex)=>{
                                return(
                                    <div id="accordion">
                                    <div class = "row">
                                        {this.props.exibitors[parentIndex].map((item,index)=>{
                                            return(
                                                <>
                                                <div  class="col-lg-3 col-md-6 mb-3 more_click" style = {{pointerEvents : item.details != null ? 'auto' : 'none'}} onClick = {this.toggleCollapse("collapsibleNavbar"+parentIndex+index)}>
                                                    <div class="card text-center p-3 justify-content-center">
                                                        {item.image != null ?
                                                        <img class="card-img-top" src={item.image} alt="spon_logo"/> : 
                                                        <img class="card-img-top" src="/images/image-placeholder.png" alt="spon_logo"/>}
                                                    {item.details != null ?
                                                    <span class = "text-center" id = "more">{this.state.collapseID == "collapsibleNavbar"+parentIndex+index ?  <Trans>less</Trans>: <Trans>more</Trans>}</span> : null}
                                                    </div>
                                                </div>
                                                
                                                <MDBCollapse className="col-12 mb-3 event-collapse" id={"collapsibleNavbar"+parentIndex+index} isOpen={this.state.collapseID == "collapsibleNavbar"+parentIndex+index}>
                                                    <div class = "p-4 rounded sp_inner overflow-hidden">
                                                        <h4 class = "f-600 mb-2 w-100">{item.heading}</h4>
                                                        <p className="collapseCancel-btn" onClick = {this.toggleCollapse("collapsibleNavbar"+parentIndex+index)}>X</p>
                                                         <p class = "f-500">{item.sub_heading}</p>
                                                        <p class = "my-3">
                                                            {ReactHtmlParser(item.details)}
                                                        </p>
                                                        {item.link !=null || item.facebook != null || item.twitter != null || item.linkedin!=null ?
                                                            
                                                        <p class = "f-600 mb-0 text-center">
                                                            {item.facebook != null || item.twitter != null || item.linkedin!=null ?
                                                                <>
                                                                Follow Social Link: 
                                                                <span class = "ml-3">
                                                                {item.facebook != null ?
                                                                <a href = {item.facebook} target = "_blank"><i class="fab fa-facebook-f mr-2"></i></a> : null}
                                                                {item.twitter != null ?
                                                                <a href = {item.facebook} target = "_blank"><i class="fab fa-twitter mr-2"></i></a> : null}
                                                                {item.linkedin != null ?
                                                                <a href = {item.linkedin} target = "_blank"><i class="fab fa-linkedin-in mr-2"></i></a> : null}
                                                                </span></> : null}
                                                            {item.link != null ?
                                                            <>
                                                            <span class = "float-none float-lg-right mt-5 mt-lg-3 mt-xl-0">
                                                            <a href={item.link.indexOf('http://') == 0 || item.link.indexOf('https://') == 0 ? item.link : '//'+item.link} class = "btn">
                                                                <Trans>website</Trans> <i class="fas fa-angle-right ml-2"></i>
                                                            </a>
                                                            </span></> : null}
                                                        </p> : null}
                                                        
                                                    </div>
                                                </MDBCollapse>
                                                </>

                                            )
                                        })}
                                        </div>
                                    </div>
                                )
                            })}
                            
                    </div> : null}
                    {this.props.floorPlan.length > 0 ?
                    <div class = "container py-3 py-md-4 tz-gallery">
                        <div class = "row">
                            <div class = "col-12 text-center pb-3 pb-md-5">
                                <a style = {{cursor : 'pointer'}} onClick = {this.OnClickShowImageSlider} class = "lightbox btn text-light rounded-0 px-5">
                                    <Trans>floorPlan</Trans>
                                </a>
                            </div>
                        </div>
                    </div> : null}
                    {this.state.showImageSlider ?
                    <Lightbox
                        mainSrc={this.props.floorPlan[0].image}
                        onCloseRequest={() => this.setState({ showImageSlider: false })}
                    /> : null}
                </section>}
            </div>
        )
    }

}

export default Content;