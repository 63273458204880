import React from 'react';
import './styles.css'
import {Link}  from 'react-router-dom'
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    }
    componentDidMount(){
    }
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <div className="error-container" >

            <h1>Something went wrong</h1>
            <p>Please Check your Internet Connection</p>
            <Link to= '/' className="btn-close">Go Back</Link>

        </div>;
      }
  
      return this.props.children; 
    }
  }
export default ErrorBoundary