import React from 'react';
import {Trans} from 'react-i18next'
export class StepSelection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            event : this.props.event,
            event_alias: this.props.event_alias
        }
    }
    extractContent=(s)=> {
        var span = document.createElement('span');
        span.innerHTML = s;
        return " - "+span.textContent || " - "+span.innerText;
    };
    render(){
        let blurClass = this.props.eventExpired ? " blur" : ""
        return(
            <div>
                <section class = {"step-sec bg-light py-5 text-center position-relative event-closed"+blurClass}>
                    <div class="container-fluid">
                        <h3 class = "font-weight-bold text-capitalize">{this.props.eventName}</h3>
                        <h4 style = {{fontWeight : 100}}>{this.props.startDate+' - '+this.props.endDate+this.extractContent(this.props.location)}</h4>

                        <div class="row text-capitalize mt-5 step-row">
                            <div id = "order-summary" class="col-3" >
                                <p class = "f-600" style = {{minHeight : 35}}>
                                    {this.props.is_marketplace == "0" ?
                                    <Trans>selectTicket</Trans> : 
                                    <Trans>selectPlan</Trans>}
                                </p>
                                <p class = {this.props.selectedStep >= 1 ? "tick-mark activee" : "tick-mark"}>
                                    <i class="fas fa-check"></i>
                                </p>
                            </div>
                            <div class="col-3">
                                <p class = "f-600" style = {{minHeight : 35}}>{this.props.is_marketplace == 1 ? 'Enter Member Details' :<Trans>enterDelegates</Trans>}</p>
                                <p class = {this.props.selectedStep >= 2 ? "tick-mark activee" : "tick-mark"}>
                                    <i class="fas fa-check"></i>
                                </p>
                            </div>
                            <div class="col-3">
                                <p class = "f-600" style = {{minHeight : 35}}><Trans>verify</Trans></p>
                                <p class = {this.props.selectedStep >= 3 ? "tick-mark activee" : "tick-mark"}>
                                    <i class="fas fa-check"></i>
                                </p>
                            </div>
                            
                            <div class="col-3">
                                <p class = "f-600" style = {{minHeight : 35}}><Trans>checkout</Trans></p>
                                <p class = {this.props.selectedStep == 4 ? "tick-mark activee" : "tick-mark"}>
                                    <i class="fas fa-check"></i>
                                </p>
                            </div>
                            
                        </div>
                        <div class = "middle-line">
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default StepSelection;