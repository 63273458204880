import React from 'react'
import { withRouter } from 'react-router-dom'
import io from "socket.io-client";
import Peer from "simple-peer";
import utility from '../../Utility/utility';
import $ from 'jquery'
import { GroupChat } from './groupChat';
import { Constants } from '../../Constants';
export class VideoChat extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            allConnectedUsers : [],
            userVideo : null,
            callerSignal : null,
            receivingCall : false,
            caller : null,
            callAccepted : false,
            callingUser : false,
            receivingPeer : null,
            message : '',
            conversation : [],
            messageReceivingPeer : null,
            showChatBox : false,
            chatName : ''
        }
        this.user = null
        this.socket = null
        this.stream = null
        this.partnerStream = null
        this.userVideo = React.createRef()
        this.partnerVideo = React.createRef()
        this.receiverSocketId = ""
        this.messageReceiverSocketId = ""
        this.showgroupChat = false
    }
    componentDidMount(){
        // $("#form-line").on('submit',function(e) {
        //     e.preventDefault();
        // });
        try {
            const domain = 'meet.jit.si';
            const options = {
             roomName: 'Test Room',
             height: window.innerHeight,
            //  parentNode: document.getElementById('jitsi-container'),
             interfaceConfigOverwrite: {
              filmStripOnly: false,
              SHOW_JITSI_WATERMARK: false,
              TOOLBAR_BUTTONS: ['microphone', 'camera', 'fullscreen',
              , 'hangup', 'chat', 'recording',
              , 'sharedvideo', 'settings', 'raisehand',
              'videoquality','profile',
              'tileview']
             },
             configOverwrite: {
              disableSimulcast: false,
              prejoinPageEnabled : false
             },
             userInfo: {
                email: 'email@jitsiexamplemail.com',
                displayName: 'Awais'
             }
            };
         
            const api = new window.JitsiMeetExternalAPI(domain, options);
            api.addEventListener('videoConferenceJoined', () => {
             console.log('Local User Joined');
            //  api.executeCommand('displayName', 'MyName');
            });
           } catch (error) {
            console.error('Failed to load Jitsi API', error);
        }
        return
        if(this.showgroupChat){
            return
        }
        $(document).on('submit', '#form-line', function(event){
            event.preventDefault();
        });
        this.user = utility.getLoggedInUser(this.props.event.alias)
        let socketURL =  Constants.socketUrl
        // let socketURL = "localhost:41000"
        if(this.user != null){
            socketURL += "?userId="+this.user.id+"&userName="+this.user.firstName+" "+this.user.lastName
        }
        this.socket = io.connect(socketURL)
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            this.stream = stream
            console.log(this.userVideo)
            this.userVideo.current.srcObject = stream
        }).catch(err =>{
            alert(err.name)
        })
        this.socket.on('mySocketId',(id)=>{
            if(this.user != null){
                this.user.socketId = id
            }
        })
        this.socket.on('allConnectedUsers',(users)=>{
            this.setState({allConnectedUsers : users})
            console.log(users)
        })
        this.socket.on('hey',(data)=>{
            let caller = this.state.allConnectedUsers.find(x=>x.socketId == data.from)
            this.setState({receivingCall : true,caller : caller,callerSignal : data.signal})
        })
        this.socket.on('callEnded',()=>{
            this.StopPartnerStream()
            this.setState({callAccepted : false,callingUser : false})
        })
        this.socket.on('callDeclined',()=>{
            if(this.state.receivingCall){
                this.setState({receivingCall : false})
            }else{
                this.setState({callingUser : false})
            }
        })
        this.socket.on('messageReceived',(data)=>{
            this.messageReceiverSocketId = data.sendSocketId
            var conversation = this.state.conversation
            data.me = false
            conversation.push(data)
            this.setState({conversation,chatName : data.userName,showChatBox : true})
        })
    }
    StopUserStream = () =>{
        this.stream.getTracks().forEach(function(track) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }
    StopPartnerStream = () => {
        this.partnerStream.getTracks().forEach(function(track) {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }
    callPeer = (socketId) => {
        let receivingPeer = this.state.allConnectedUsers.find(x=>x.socketId == socketId)
        this.setState({receivingPeer})
        this.receiverSocketId = socketId
        const peer = new Peer({
            initiator : true,
            trickle : false,
            stream : this.stream
        })
        this.setState({callingUser : true})
        peer.on('signal',(data)=>{
            this.socket.emit("callUser",{
                userToCall : socketId,
                signalData : data,
                from : this.user.socketId
            })
        })
        peer.on('stream',(stream)=>{
            this.partnerStream = stream
            this.partnerVideo.current.srcObject = stream
        })
        this.socket.on('callAccepted',(signal)=>{
            this.setState({callAccepted : true,receivingCall : false})
            peer.signal(signal)
        })
    }
    acceptCall = () =>{
        this.setState({callAccepted : true,receivingCall : false},()=>{
            const peer = new Peer({
                initiator : false,
                trickle : false,
                stream : this.stream
            })
            peer.on('signal',(data)=>{
                this.socket.emit('acceptCall',{
                    signal : data,
                    to : this.state.caller.socketId
                })
            })
            peer.on('stream',(stream)=>{
                this.partnerStream = stream
                this.partnerVideo.current.srcObject = stream
            })
            peer.signal(this.state.callerSignal)
        })
    }
    rejectCall = () => {
        let peerSocketId = this.getPeerSocketId()
        if(this.state.receivingCall){
            this.setState({receivingCall : false})
        }else{
            this.setState({callingUser : false})
        }
        this.socket.emit('declineCall',{
            to : peerSocketId
        })
    }
    endCall = () => {
        this.StopPartnerStream()
        this.socket.emit('endCall',{
            to : this.getPeerSocketId()
        })
        this.setState({callAccepted : false,callingUser : false})
    }
    sendMessageToPeer = (socketId) => {
        this.messageReceiverSocketId = socketId
        let messageReceivingPeer = this.state.allConnectedUsers.find(x=>x.socketId == socketId)
        this.setState({chatName : messageReceivingPeer.userName,showChatBox : true})
    }
    getPeerSocketId = () => {
        let peerSocketId = ""
        if(this.receiverSocketId == ""){
            peerSocketId = this.state.caller.socketId
        }else{
            peerSocketId = this.receiverSocketId
        }
        this.receiverSocketId = ""
        return peerSocketId
    }
    OnTextInputChanged = (e) => {
        this.setState({message : e.target.value})
    }
    OnClickSendMessage = () => {
        if(this.state.message == ""){
            return
        }
        var message = {
            text : this.state.message,
            userId : this.user.id,
            userName : this.user.firstName,
            to : this.messageReceiverSocketId,
            me : true
        }
        this.socket.emit('messageSent',message)
        var conversation = this.state.conversation
        conversation.push(message)
        this.setState({conversation,message : ''})
    }
    render(){
        return null
        return(
            this.showgroupChat ?
            <GroupChat eventAlias = {this.props.event.alias}/> :
            <div className = "col-12">
                <div className = "mt-2 mb-3 row justify-content-center">
                    <video ref = {this.userVideo} className = "mr-5 col-3" id="currentUserVideoPlayer" style = {{border : '1px solid blue',height : 350,width : 350}} playsInline muted autoPlay/>
                   {this.state.callAccepted ? <video  ref = {this.partnerVideo} className = "col-3" id="partnerVideoPlayer" style = {{border : '1px solid blue',height : 350,width : 350}} playsInline autoPlay/> : null}
                </div>
                <div className = "row justify-content-center mb-3">
                    {!this.state.receivingCall && !this.state.callAccepted && !this.state.callingUser?
                    <div className = "mr-5 text-center">
                        <div className = "col-12 mb-2">
                            <span className = "f-600 ">Call</span> 
                        </div>
                        {this.state.allConnectedUsers.map((item,index)=>{
                            if(item.socketId == this.user.socketId){
                                return null
                            }
                            return(
                                <button className = "col-12 mb-1" onClick={() => this.callPeer(item.socketId)}>{item.userName}</button>
                            )
                        })}
                    </div>
                    : this.state.receivingCall ? 
                        <div className = "mr-5 text-center">
                            <div className = "col-12 mb-2">
                                <span>Incomming call from
                                    <span className = "f-600">{" "+this.state.caller.userName+" "}...</span>
                                </span>
                            </div>
                            <div>
                                <button className = "col-12 mb-1" onClick={this.acceptCall}>Accept</button>
                                <button className = "col-12 mb-1" onClick={this.rejectCall}>Reject</button>
                            </div>
                        </div>
                        : this.state.callAccepted ?
                        <div className = "mr-5 text-center">
                            <button className = "col-12 mb-1" onClick={this.endCall}>End Call</button>
                        </div> : 
                        <div className = "mr-5 text-center">
                            <div className = "col-12 mb-2">
                                <span>Calling
                                    <span className = "f-600">{" "+this.state.receivingPeer.userName}...</span>
                                </span>
                            </div>
                            <button className = "col-12 mb-1" onClick={this.rejectCall}>Decline</button>
                        </div>
                    }
                    <div className = "mr-5 text-center">
                        <div className = "col-12 mb-2">
                            <span className = "f-600 ">Chat</span> 
                        </div>
                        {this.state.allConnectedUsers.map((item,index)=>{
                            if(item.socketId == this.user.socketId){
                                return null
                            }
                            return(
                                <button className = "col-12 mb-1" onClick={() => this.sendMessageToPeer(item.socketId)}>{item.userName}</button>
                            )
                        })}
                    </div>
                </div>
                {this.state.showChatBox  ?
                <form id = "form-line" class="chat_window">
                    <div class="top_menu">
                        <div class="buttons">
                            <div onClick = {()=>this.setState({showChatBox : false})} class="button close"></div>
                        </div>
                        <div class="title">{this.state.chatName}</div>
                    </div>
                    <ul class="messages">
                        {this.state.conversation.map((item,index)=>{
                            let leftRight = item.me ? "right" : "left"
                            return(
                                <li class={"message appeared "+leftRight}>
                                    <div class="avatar"></div>
                                    <div class="text_wrapper">
                                        <div class="text">{item.text}</div>
                                    </div>
                                </li>
                            )
                        })}
                        
                    </ul>
                    <div class="bottom_wrapper clearfix">
                        <div class="message_input_wrapper">
                            <input value = {this.state.message} onChange = {this.OnTextInputChanged} id = "messageInput" class="message_input" placeholder="Type your message here..." />
                        </div>
                        <div onClick = {this.OnClickSendMessage} class="send_message"><div class="icon"></div><input type = "submit" class="text" value = "Send" />
                        </div>
                    </div>
                </form> : null}
            </div>
        )
    }
}
export default withRouter(VideoChat)