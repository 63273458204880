import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            blog: this.props.blog,

        };

    }
    componentDidMount(){
        if(this.props.blog){
            document.title = this.props.blog.title
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.blog){
            document.title = nextProps.blog.title;
            this.setState({blog:nextProps.blog})
        }
    }

    render(){

        const blog = this.state.blog;
        if(blog){

            return(
                <div>

                    <section class = "blog_banner" style = {{
                        display: blog.image == null ? "none" : "",
                        backgroundImage: `url(${blog.image})`,
                        height: '75vh',
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top"
                    }}>
                        {/* <img src = {blog.image} class = "w-100" alt = "about-banner"/> */}
                    </section>

                    <section class = "about-detail py-3 bg-light">
                        <div class="container">
                            <h5 class="font-weight-bold text-uppercase">{blog.title}</h5>
                            <div class="row py-3">
                                {ReactHtmlParser(blog.description)}

                            </div>
                        </div>
                    </section>
                </div>
            )

        }else{
            return(null)
        }

    }//end of render

}

export default Content;