import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    async getEventExibitions(eventAlias,type,callback){

        await axios.get(`${eventAlias}/getEventExibitions`,
            {
                params: {isActive:1,type : type,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    }
}