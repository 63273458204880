import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from './api';
import $ from 'jquery'
import 'bootstrap'
import {isMobile} from 'react-device-detect'
import { SpeakersDataLoaded } from '../Speaker/actions';
import { connect } from 'react-redux'
import { SpeakerHomeDataLoaded } from './actions';
import IntersectionVisible from 'react-intersection-visible'
import hexToRgba from 'hex-to-rgba';
import ContentLoader from 'react-content-loader'
import { isThisSecond } from 'date-fns';
import {Trans} from 'react-i18next'
export class SpeakerV1 extends React.Component{
	row_items = 4
	_isMounted = false
    constructor(props) {
		
        super(props);
        this.state = {
            event: this.props.event,
            event_alias: this.props.event_alias,
            startIndex:0,
			speakers : {},
			backgroundColor : "",
			showContent : false,
			isLoading : true
        };
		this.once = false
		this.sliderButtonClickDisabled = false
    }

    componentDidMount(){
		setTimeout(() => {
			let style = getComputedStyle(document.querySelector('.bg_primary'))
			var backgroundColor = style.backgroundColor
			var rgb = backgroundColor.match(/\d+/g);
			backgroundColor = "#" + this.componentToHex(parseInt(rgb[0])) + this.componentToHex(parseInt(rgb[1])) + this.componentToHex(parseInt(rgb[2]));
			this.setState({backgroundColor : backgroundColor})
		}, 0);
		this._isMounted = true
		if(isMobile){
			this.row_items = 2
		}
		else{
			this.row_items = 4
		}
		if(this.props.defaultSpeakers != null){  // incase component is receiving speakers
			this.setState({speakers : this.props.defaultSpeakers,isLoading : false,showContent : true})
			return
		}
		if(this.props.speakers != null){
			let showContent = false
			if(this.props.speakers.length > 0){
				showContent = true
			}
			this.setState({speakers : this.props.speakers,showContent,isLoading : false})
		}else{
			api.getEventSpeakers(this.state.event.alias,this.props.type,(err,data)=>{
				if(err == null && this._isMounted){
					this.props.speakersHomeLoaded(data.speakers)
					this.setState({speakers:data.speakers , startIndex : 0,showContent : true});
				}
				this.setState({isLoading : false})
			})
		}
		this.once = false
	}
	OnVisibilityChange = (isVisible) => {
		if(isVisible && !this.once){
			setInterval(() => {
				$('#speakerV1Slider').carousel('next')
				this.sliderButtonClickDisabled = true
				setTimeout(() => {
					this.sliderButtonClickDisabled = false
				}, 500);
			}, 5000); 
			this.once = true
		}
    }
	componentWillUnmount(){
		this._isMounted = false
	}
	componentToHex = (c) => {
		var hex = c.toString(16);
		return hex.length == 1 ? "0" + hex : hex;
	}
    render(){

    	const speakers = this.state.speakers;
		const row_items = this.row_items;
		if(this.state.isLoading){
			return(
				<section className = "speaker-1 pt-5 pb-5" id = "speakers">
					<div className="container">
					<h5 className = "text-center font-weight-bold text-uppercase"><Trans>speakers</Trans></h5>
					<ContentLoader style = {{padding : 10}} viewBox = "0 0 400 80">
						<rect x="25%" y="0" rx="50%" ry="50%" width="40" height="40" />
						<rect x="13%" y="50" rx="4" ry="4" width="140" height="8" />
						<rect x="17%" y="68" rx="4" ry="4" width="100" height="6" />
						<rect x="65%" y="0" rx="50%" ry="50%" width="40" height="40" />
						<rect x="53%" y="50" rx="4" ry="4" width="140" height="8" />
						<rect x="57%" y="68" rx="4" ry="4" width="100" height="6" />
					</ContentLoader>
					</div>
				</section>
			)
		}
    	if(speakers.length > 0){

            var speakerRows = Math.ceil(speakers.length/row_items);
            var startIndex = 0;
            return(
				<IntersectionVisible onShow = {()=>this.OnVisibilityChange(true)} onHide = {()=>this.OnVisibilityChange(false)}>
				<section style = {{backgroundColor : hexToRgba(this.state.backgroundColor,0.3)}} className = "speaker-1 pt-5 pb-2" id = "speakers">
					<div className="container">
						<h5 className = "text-center font-weight-bold text-uppercase"><Trans>speakers</Trans></h5>
						<div className="row pt-5 pb-2">
							<div id="speakerV1Slider" className="carousel slide w-100" >


								<div class="carousel-inner">

                                    {

                                        speakers.length > 0 ?
                                            speakers.map(function (speaker,index){
                                            	var divName = index == 0 ? "carousel-item active" : "carousel-item";

                                            	if (index < speakerRows){

													var rec = index*row_items;
													var centerClass = speakers.length > 4 ? " justify-content-center" : " justify-content-center"
                                                    return(
														<div className={divName} key={index}>
															<div className="row justify-content-center">

																{
                                                                    speakers.map(function (s,i){

																		if(i<row_items && i+rec < speakers.length){
																			return(
																				<div className="col-md-3 sliderItemV1">
																					<div className = "card_inner bg-white py-3 px-2 rounded text-center h-100">
																						<div className = "user_img">
																							{speakers[i+rec].image != null ?
																								<img src = { speakers[i+rec].image } className = "rounded-circle" alt = "user_1-img"/>:
																								<img src = '/images/profile-placeholder.png' className = "rounded-circle" alt = "user_1-img"/>}
																						</div>
																						<div className = "user_caption mt-4">
																							<h6 className = "font-weight-bold">{speakers[i+rec].name} {speakers[i+rec].surname == null ? "" : speakers[i+rec].surname }</h6>
																							<p className = "mb-0">{ speakers[i+rec].job_title }</p>
																							<p className = "mt-2 f-500">{ speakers[i+rec].company }</p>
																						</div>
																					</div>
																				</div>
																			);
																		}
                                                                    })
																}

															</div>
														</div>
                                                    );

												}

											})
											: null
                                    }


								</div>

								{speakers.length > 4 ?
								<>
								<a className="carousel-control-prev" style = {{cursor : 'pointer'}} onClick = {()=> {
										if(!this.sliderButtonClickDisabled){
											$('#speakerV1Slider').carousel('prev')}
										}
									}>
									<i className="fas fa-caret-left"></i>
								</a>
								<a className="carousel-control-next" style = {{cursor : 'pointer'}} onClick = {()=> {
										if(!this.sliderButtonClickDisabled){
											$('#speakerV1Slider').carousel('next')}
										}
									}>
									<i className="fas fa-caret-right"></i>
								</a>
								</> : null}
							</div>
						</div>
						{this.props.defaultSpeakers == null ?
						<div className="row py-2 justify-content-center">
							<a onClick = {this.props.onClickSeeAllSpeakers} style = {{cursor : 'pointer'}} className = "btn-default see_btn">
								<Trans>seeAll</Trans></a>
						</div> : null}

					</div>
				</section>
				</IntersectionVisible>
            );

		}//end of if
		else{
			return null
		}
    }

}

const mapStateToProps = state => ({
    speakers: state.speakersHome.speakersHomeData
})
const mapDispatchToProps = dispatch => ({
	speakersHomeLoaded : data => dispatch(SpeakerHomeDataLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(SpeakerV1);