import { combineReducers } from 'redux';
import appData from '../components/App/reducer/index';
import bookings from '../components/Booking/reducer';
import speakers from '../components/Speaker/reducer';
import venues from '../components/Venue/reducer';
import pageData from '../components/contact/reducer';
import sepratePageBlog from '../components/1 - Sub menu components/Blogs/reducer';
import getGalleryImages from '../components/1 - Sub menu components/Gallery/reducer';
import agenda from '../components/Schedule/reducer';
import contactUs from '../components/ContactUs/reducer';
import presentations from '../components/Presentation/reducer';
import exhibitions from '../components/Exibition/reducer';
import sponsors from '../components/SponserPage/reducer';
import testimonials from '../components/Testimonials/reducer';
import blogs from '../components/EventBlog/reducer';
import speakersHome from '../components/SpeakerV1/reducer';
import contactInfo from '../components/ContactInfo/reducer';
import imageGallery from '../components/Image-Gallary/reducer';
import panelistData from '../components/PanelistModeratorView/reducer'
import videos from '../components/VideoPage/reducer'
import myAccount from '../components/Account/reducer'
import networking from '../components/Networking/reducer'
import virtualExhibition from '../components/1 - Sub menu components/Virtual Exhibition/reducer'
import virtualLobby from '../components/1 - Sub menu components/Lobby/reducer'
import participants from '../components/1 - Sub menu components/Participants/reducer'
import chat from '../components/1 - Sub menu components/Chat/reducer'
import invitations from '../components/1 - Sub menu components/Invitation/reducer'
import marketPlaceReducer from '../components/marketplace/reducer';
import mapFloorReducer from '../components/1 - Sub menu components/Mapfloor/reducer'
export default combineReducers({
    appData : appData,
    bookings : bookings,
    speakers : speakers,
    venues : venues,
    pageData : pageData,
    agenda : agenda,
    contactus : contactUs,
    presentations : presentations,
    exhibition : exhibitions,
    partners : sponsors,
    testimonials : testimonials,
    blog : blogs,
    speakersHome : speakersHome,
    contactInfo : contactInfo,
    imageGallery : imageGallery,
    panelistData : panelistData,
    videos : videos,
    myAccount : myAccount,
    networking : networking,
    virtualExhibition : virtualExhibition,
    virtualLobby : virtualLobby,
    participants : participants,
    chat : chat,
    invitations : invitations,
    marketPlaceReducer: marketPlaceReducer,
    mapFloorReducer:mapFloorReducer,
    sepratePageBlog:sepratePageBlog,
    getGalleryImages:getGalleryImages

});