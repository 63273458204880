import * as Actions from "./actionType";
const initialState = {
   mapfloordata :{}
  };
const mapFloorReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_MAP_FLOOR : {
            return{
                ...state,
                mapfloordata:action.payload
            }
        }
        default:{
            return state
        }
   
  }
}
  export default  mapFloorReducer