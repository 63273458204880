import React from 'react';
import ReactDOM from 'react-dom';

export class Footer1 extends React.Component{
    // console.log(this.props)
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            socails: [{href:null,text:'Facebook',icon: 'fab fa-facebook-f'},{href:null,text:'Twitter',icon: 'fab fa-twitter'},
                {href:null,text:'LinkedIn',icon: 'fab fa-linkedin-in'},{href:null,text:'Google+',icon: 'fab fa-google-plus-g'},
                {href:null,text:'Instagram',icon: 'fab fa-instagram'}
            ]
        };

	}
	componentDidMount(){
		if(this.props.event.phone_number != null){
			var socails = this.state.socails
			socails.push({
				href:"tel:"+this.props.event.phone_number,
				text:this.props.event.phone_number,
				icon: 'fas fa-phone',
				isPhone : true
			})
			this.setState({socails})
		}
	}
	OnClickSocialIcon = (link,isPhone) => {
		if(isPhone){
			window.open(link,"_self")
		}else{
			window.open(link,"_blank")
		}
	}
	MakeCall=()=>{
		if(this.state.event.phone_number == null){
			return
		}
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = "tel:"+this.state.event.phone_number;
		// the filename you want
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL("tel:"+this.state.event.phone_number);
	}
	render(){

    	const event = this.state.event;
    	this.state.socails[0].href = event.facebook ? event.facebook : null;
        this.state.socails[1].href = event.twitter ? event.twitter : null;
        this.state.socails[2].href = event.linkedIn ? event.linkedIn : null;
        this.state.socails[3].href = event.google ? event.google : null;
        this.state.socails[4].href = event.instagram ? event.instagram : null;

    	if(event){

            return(
				this.props.event.is_marketplace == "0"?
				<section class = "footer-top py-3 py-md-3">
					<div class="container">
						<div class="row d-none d-md-block">
							<div class="col-md-12">
								<ul class = "nav justify-content-center f-500">
									{this.props.elements != null? 
									this.props.elements.map((item,index)=>{
										return(
											<li class="nav-item">
												<a onClick = {()=>this.props.onMenuItemClick(item.href)} style = {{cursor : 'pointer'}} class = "nav-link text-dark py-3">
													{item.text}
												</a>
											</li>
										)
										
									}) : null}
									
									{/* <li class="nav-item">
										<a href = "about.php" class = "nav-link text-dark py-3">
											About
										</a>
									</li>
									<li class="nav-item">
										<a href = "schedule.php" class = "nav-link text-dark py-3">
											Schedule
										</a>
									</li>
									<li class="nav-item">
										<a href = "speakers.php" class = "nav-link text-dark py-3">
											Speakers
										</a>
									</li>
									<li class="nav-item">
										<a href = "sponsors.php" class = "nav-link text-dark py-3">
											Sponsors
										</a>
									</li>
									<li class="nav-item">
										<a href = "contact-us.php" class = "nav-link text-dark py-3">
											Contact
										</a>
									</li>
									<li class="nav-item">
										<a href = "ticket-step1.php" class = "nav-link text-dark py-3">
											Book Now
										</a>
									</li>
								*/}

								</ul>
							</div>
						</div>

						<div class="row">
							<div class="col-md-12">
								<ul class = "nav justify-content-center text-center f-500">

									{
                                        this.state.socails.map((s,i)=>{
                                        	if(s.href){
												return(
													<li class = "nav-item" key={i}>
														<a onClick = {()=> this.OnClickSocialIcon(s.href,s.isPhone)} style = {{cursor : 'pointer'}} class = "nav-link text-dark py-2">
															<span class = "social-icons"><i class={s.icon}></i></span>
															{/* <span class = "mt-2">{s.text}</span> */}
														</a>
													</li>
												);
											}
										})
									}
									{/* {
                                    	event.phone_number ?
											<li class="nav-item">
												<a style = {{cursor : 'pointer'}} class = "nav-link text-dark py-3">
													<span class = "phone_link"><i class="fas fa-phone"></i></span>
													<span class="mt-2" style = {{cursor : 'pointer'}} onClick = {this.MakeCall}>{event.phone_number}</span>
												</a>
											</li>
											:null
                                    } */}

								</ul>
							</div>
						</div>


					</div>
				</section>:null
            );

		}else{
    		return(null);
		}//end of if
    }//end of render
}

export default Footer1;