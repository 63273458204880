import axios from '../axios/index'
import { Constants } from '../../Constants';
import utility from '../../Utility/utility';

export default {
    authenticateUser(eventAlias,data,isJobFair,callback){
        axios.post(`${eventAlias}/login`,
        data,
        {
            params : {isPreview : Constants.isPreview},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                let user = response.data.user
                if(isJobFair == 1 && utility.getJobBoardAccessStatus(user.boardType)){
                    this.jobFairLogin({
                        email : data.email,
                        password : data.password,
                        updateJwt : false
                    },(jwt, _id, disabledUser = false)=>{
                        if(jwt){
                            user.jobFairJwt = jwt
                        }
                        if(_id){
                            user.jobFairId = _id
                        }
                        if(disabledUser){
                            user.jobFairDisabled = true
                        }
                        callback(null,user)
                    })
                }else{
                    callback(null,user)
                }
            }
            else{
                var error = {
                    code : response.data.processCode,
                    message : response.data.message
                }
                callback(error,null)
            }
        }).catch(error => {
            console.log(error)
            var errormessage = {
                message : 'Something went Wrong,Please try again'
            }
            callback(errormessage,null)
        })
    },
    forgetPassword(eventAlias,data,callback){
        axios.post(`${eventAlias}/forgetPassword`,
        data,
        {
            params : {isPreview : Constants.isPreview},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data)
            }
            else{
                var error = {
                    message : response.data.message
                }
                callback(error,null)
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    getLinkedInUserInfo(eventAlias,code,clientId,clientSecret,redirectUri,callback){
        axios.post(`${eventAlias}/linkedinLogin`,
        {
            code : code,
            grant_type : 'authorization_code',
            redirect_uri : redirectUri,
            client_id : clientId,
            client_secret : clientSecret
        },
        {
            params : {isPreview : Constants.isPreview},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data)
            }
            else{
                callback('user not found',null)
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    getLinkedinAccessToken(code,clientId,clientSecret,redirectUri,callback){
        axios.post('https://www.linkedin.com/oauth/v2/accessToken',
        {
            params: {
                client_id: clientId,
                client_secret : clientSecret,
                redirect_uri : redirectUri,
                grant_type : 'authorization_code',
                code : code
            },
        }).then(response => {
            callback(null,response.data.access_token)
        }).catch(error => {
            callback(error,null)
        })
    },
    getLinkedinProfile(accessToken,callback){
        axios.get('https://api.linkedin.com/v2/me',
        {
            params : {isPreview : Constants.isPreview},
            headers: {
                'Authorization' : `Bearer ${accessToken}`
            }
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    getLinkedinProfileEmail(accessToken,callback){
        axios.get('https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))',
        {
            headers: {
                'Authorization' : `Bearer ${accessToken}`
            }
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    getSocialLoginStatus(id,eventAlias,callback){
        axios.post(`${eventAlias}/socialLogin`,
            {
                socialId : id
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    async jobFairLogin(body,callback){
        await axios({
            url: '/users/auth',
            baseURL: process.env.REACT_APP_JOBFAIR_API_ENDPOINT,
            method : 'POST',
            data : body,
            headers: { "Content-Type": "application/json" }
        }).then(response => {
            if(response.data.jwt_token){
                this.insertLoginHashJobFair(response.data.jwt_token)
                callback(response.data.jwt_token, response.data._id)
            }else{
                callback(null,null,true)
            }
        }).catch(error => {
            
        })
    },
    insertLoginHashJobFair(jwtToken){
        let hash = utility.makeid(128)
        localStorage.setItem("login_hash",hash)
        let body = {
            login_hash: hash
        }
        axios({
            url: '/users/auth',
            baseURL: process.env.REACT_APP_JOBFAIR_API_ENDPOINT,
            method : 'PUT',
            data : body,
            headers: { "Content-Type": "application/json",Authorization : jwtToken }
        }).then(response => {
            
        }).catch(error => {
            
        })
    }
}