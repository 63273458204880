import React from 'react';
import api from './api';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import Helmet from 'react-helmet'
import MetaTags from 'react-meta-tags'
import { Constants } from '../../Constants';
import utility from '../../Utility/utility';
import VirtualZone from '../Header/virtualZone';
import {Trans} from 'react-i18next';
class BlogPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            event: props.event,
            event_alias: props.event_alias,
            blogId : this.props.match.params.blogId,
            blog : {},
            loading:false
        };

    }

    async componentDidMount(){

        window.scrollTo(0,0)
        this.setState({loading:true})
        await api.getEventBlog(this.state.event.alias,this.props.match.params.blogId,(err,data)=>{
            console.log(data)
            this.setState({loading:false})
            console.log(err)
            if(err == null){
                var keyWordString = ""
                try{
                for(var i=0;i<data.feature.keywords.length;i++){
                    keyWordString += data.feature.keywords[i]
                    if(i < data.feature.keywords.length - 1){
                        keyWordString += ','
                    }
                }
                data.feature.keyWordString = keyWordString
            }
                catch(e){

                }
                
                this.setState({blog:data.feature});
                document.title = data.feature.title
            }
        })
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }

    languageClick = (value) =>{
        console.log("Trigger blogpage on lan select")
        this.props.languageClick(value);
    }


    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)

        return(
            this.state.event != null ?
                <div>
                    <div id="header-top">
                    <Header
                        selectedIndex = {0}
                        onMenuItemClick = {this.OnNavigateToPage}
                        elements = {this.props.headerMenuItems}
                        facebookLink = {this.props.event.facebook}
                        linkedInLink = {this.props.event.linkedIn}
                        twitterLink = {this.props.event.twitter}
                        instagramLink = {this.props.event.instagram}
                        event = {this.props.event}
                        isMarketplace = {this.props.event.is_marketplace}
                        googleLink = {this.props.event.google}
                        phone = {this.props.event.phone_number}
                        eventLogo = {this.props.event.logo}
                        eventName = {this.props.event.name}
                        eventAlias = {this.props.event.alias}
                        onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                        onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                        onClickAccountItem = {this.OnClickAccountItem}
                        availableLocales = {this.props.availableLocales}
                        languageClick  = {this.languageClick}

                    />
                    {user != null && user.isBooking && Constants.showVirtualZone?
                    <VirtualZone
                        
                        eventAlias = {this.props.event.alias}
                        event = {this.props.event}
                        onMenuItemClick = {(path)=>{
                            if(path != ''){
                                this.OnNavigateToPage(path)
                            }
                        }}  
                        unReadCount = {this.props.unReadCount}
                        onNotificationSeen = {this.props.onNotificationSeen} 
                        onClearNotifications = {this.props.onClearNotifications}
                        notificationsCount = {this.props.unReadNotificationsCount}
                        notifications = {this.props.notifications}
                        onClickAccountItem = {this.OnClickAccountItem}
                        onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                        menuItems = {this.props.virtualMenuItems}
                        user = {user} 
                    /> : null}
                    </div>
                    {this.state.blog != null ? 
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta property="description" content={this.state.blog.meta_description} />
                        <meta property="title" content={this.state.blog.seo_title} />
                        {/* <title>Blog</title>  */}
                    </Helmet>: null}
                    <div className = {`content-main`}>
                    {this.state.loading == false ? <Content blog = {this.state.blog}/>:
                    <div className = "d-flex" style = {{justifyContent : 'center',alignItems : 'center',zIndex : 9,height : '100vh',width : '100%',background : 'rgb(0 0 0 / 9%)'}}>
                    <div>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {30}
                            width = {30}
                        />
                    </div>
                </div>
                    }
                        {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ? <NewsLetter 
                            title = "SUBSCRIBE OUR NEWS LETTER" 
                            event={this.props.event} 
                            event_alias={this.props.event_alias}
                            buttonText = "Subscribe"
                            type = 'newsLetter'
                        />:null
}
                        <Footer1
                            event = {this.state.event}
                            elements = {this.props.footerMenuItems}
                            onMenuItemClick = {this.OnNavigateToPage}
                        />
                    </div>
                    <Footer2 
                       event = {this.props.event}
                        copyRightLogo = {this.props.event.copyright_logo}
                        copyRightText = {this.props.event.copyright_text}
                        copyRightLink = {this.props.event.copyright_link}
                        showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                    />
                </div> : <div><Trans>loading</Trans></div>
        )
    }
}

export default withRouter(BlogPage);