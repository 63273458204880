import axios from '../axios/index'
import { Constants } from '../../Constants';
var fileDownload = require('js-file-download');
export default {

    async getEventPage(eventAlias,pageId,callback){

        await axios.get(`${eventAlias}/getEventPage?pageId=${pageId}`,
        {
            params : {isPreview : Constants.isPreview},
            headers: { 'Content-Type': 'application/json','apiToken': Constants.API_TOKEN },
        }).then(response=>{
            callback(null,response.data)
        }).catch(error=>{
            callback(error,null)
        });
    },
    downloadPdfFile(eventAlias,pageId,title,callback){
        axios.get(`${eventAlias}/downloadPagePdf`,
            {
                timeout : 120000,
                responseType: 'arraybuffer',
                params: {pageId : pageId,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            fileDownload(response.data,title+".pdf")
            callback(null,{})
        }).catch(error => {
            callback(error,null)
        })
    },
    submitContactUsForm(eventAlias,data,callback){
        axios.post(`${eventAlias}/sendEventContactMasgPage`,
        data,
        {
            params : {isPreview : Constants.isPreview},
            headers: { 
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN },
        }).then(response=>{
            callback(null,response.data)
        }).catch(error=>{
            callback(error,null)
        });
    }
}