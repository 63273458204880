import React from 'react';
import Header from '../Header/Index';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import Content from './content';
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import Helmet from 'react-helmet';
import { SpeakerHomeDataLoaded } from '../SpeakerV1/actions';
import { Constants } from '../../Constants';
import { useLastLocation } from 'react-router-last-location';
import VirtualZone from '../Header/virtualZone';
import utility from '../../Utility/utility';
import $ from 'jquery'
// import zoom from '../../zoom-us/index.js'
import {Trans, withTranslation} from 'react-i18next'
import { GAPageView, GARecordEvent } from '../../Utility/GA'
import { compose } from 'redux';
import { isThisQuarter } from 'date-fns';
export class Home extends React.Component{
    _isMounted = false
    constructor(props){
        super(props);
        this.state = {
            event: {},
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/home") > -1),
            renderComponents : true,
            selectedSubmenuIndex : -1,
            availableLocales : this.props.availableLocales
        }
        this.user = utility.getLoggedInUser(this.props.event.alias)
        this.downloadAgendaTitle= <Trans>downloadAgenda</Trans>
        this.agendaSubTitle = <Trans>agendaSubTitle</Trans>
        this.downloadButtonText = <Trans>download</Trans>
    }
    OnNavigateToPage = (path,data=null) =>{
        console.log(path)
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data){
            this.props.history.push({
                pathname: path,
                state: data
            })
        }else{
            this.props.history.push(path)
        }
    }
    componentDidMount(){
        if(this.props.seoData != null){
            fetch('/')
        }
        //console.log("USER",this.user)
        this.OnNavigateToPage(this.props.landingPage)
        //console.log("Available Locales on HomePage", this.state.availableLocales)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        //console.log("LCOAL STORAGE", localStorage.getItem("language"))
    }
    componentWillUnmount(){
        this.setState({renderComponents : false})
    }
    getLastLocation = () => {
        this.lastLocation = useLastLocation()
        if(this.lastLocation != null){
            this.lastLocation = this.lastLocation.pathname
        }
        return null
    }
    languageClick = (value) =>{
       //console.log("Trigger home on lan select")
        this.props.languageClick(value);
    }

    downloadAgendaEvent = () =>{
        //console.log("download agendda clicked")

        GARecordEvent("downloadAgendaClicked", this.user? this.user.id : "non user",{
            category : 'Download Agenda',
            action : 'Download agenda clicked',
            userName: this.user ? (this.user.firstName+''+this.user.lastName) : 'anonymous user',
            userEmail: this.user? this.user.email : 'no email'
        })

    }
    downloadAgenda=()=>{
        //console.log("download agendda clicked")
        !this.user ? 
        GARecordEvent("downloadAgendaClicked", 'anonymous user',{
            category : 'Download Agenda',
            action : 'Download agenda clicked',
            userName:  'anonymous user',
            userEmail:  'anonymous user'
        }) : GARecordEvent("downloadAgendaClicked", this.user.id,{
            category : 'Download Agenda',
            action : 'Download agenda clicked',
            userName:  (this.user.firstName+''+this.user.lastName) ,
            userEmail:  this.user.email
        }) 
       
    }
    downloadAgendaFormEvent= (name,email) =>{
        //console.log("DOWNLOAD ADENDA FORM EVENT")
        
        GARecordEvent("downloadAgendaButtonClicked", this.user? this.user.id : "non user",{
            category : 'Download Agenda button',
            action : 'Download agenda button clicked',
            userName: this.user ? (this.user.firstName+''+this.user.lastName) : name,
            userEmail: this.user? this.user.email : email
        })
    }
    registerInterestEvent = () => {
        //console.log("register interest clicked")
        !this.user ? 
        GARecordEvent("registerInterestClicked", 'anonymous user',{
            category : 'Register Interest',
            action : 'Register Interest clicked',
            userName:  'anonymous user',
            userEmail:  'anonymous user'
        }) : GARecordEvent("registerInterestClicked", this.user.id,{
            category : 'Register Interest',
            action : 'Register Interest clicked',
            userName:  (this.user.firstName+''+this.user.lastName) ,
            userEmail:  this.user.email
        }) 
    }
    registerInterestSubmitEvent = (name, email)=>{
        //console.log("register interest submit clicked")
        !this.user ? 
        GARecordEvent("registerInterestButtonClicked", 'anonymous user',{
            category : 'Register Interest Button',
            action : 'Register Interest button clicked',
            userName:  name,
            userEmail:  email
        }) : GARecordEvent("registerInterestButtonClicked", this.user.id,{
            category : 'Register Interest Button',
            action : 'Register Interest button clicked',
            userName:  (this.user.firstName+''+this.user.lastName) ,
            userEmail:  this.user.email
        }) 
    }
    subscribeEvent = () =>{
        //console.log("subcribe clicked")
        GARecordEvent("subscribe Clicked", this.user? this.user.id : 'anonymous user',{
            category : 'Subscribe',
            action : 'Subscribe button clicked',
            userName: this.user? (this.user.firstName+''+this.user.lastName) : 'anonymous user',
            userEmail: this.user? this.user.email : 'anonymous user'
        })
    }
    contactUsEvent = () =>{

        //console.log("Contact US button clicked")
        GARecordEvent("Contact us button Clicked", this.user? this.user.id : 'anonymous user',{
            category : 'Contact Us',
            action : 'ContactUs button clicked',
            userName:this.user? (this.user.firstName+''+this.user.lastName) : 'anonymous user',
            userEmail: this.user?this.user.email : 'anonymous user'
        })

        this.OnNavigateToPage(this.props.event.alias+'/contact_us')
    }

    onClickDetails = ()=>{
        //console.log("Apply job button clicked")
        GARecordEvent("applyJob button Clicked", this.user.id || 'anonymous user',{
            category : 'Apply Job',
            action : 'Apply job clicked',
            userName: (this.user.firstName+''+this.user.lastName) || 'anonymous user',
            userEmail: this.user.email || 'anonymous user'
        })
    }
    // jobBoardEvent =()=>{
    // console.log("Menu Job Board clicked")

    // GARecordEvent("menuJobBoardClicked", this.user.id,{
    //   category : 'Menu Job Board',
    //   action : 'Menu job board clicked',
    //   userName: this.user.firstName+''+this.user.lastName,
    //   userEmail: this.user.email
    //     })
    // }
    // visitJobSeeker= ()=>{
    //     console.log("Visit job Board clicked")

    //     GARecordEvent("visitJobBoardClicked", this.user.id,{
    //       category : 'Visit Job Board',
    //       action : 'visit job board clicked',
    //       userName: this.user.firstName+''+this.user.lastName,
    //       userEmail: this.user.email
    //         })
    // }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    render(){
        
        return(
            <div>
                <this.getLastLocation />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.event.name}</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                <div id="header-top">
                    {this.state.renderComponents? 
                    <Header 
                        selectedIndex = {this.state.selectedIndex}
                        selectedSubIndex = {this.state.selectedSubmenuIndex}
                        onMenuItemClick = {this.OnNavigateToPage}  
                        elements = {this.props.headerMenuItems}
                        isMarketplace = {this.props.event.is_marketplace}
                        facebookLink = {this.props.event.facebook}
                        linkedInLink = {this.props.event.linkedIn}
                        twitterLink = {this.props.event.twitter}
                        instagramLink = {this.props.event.instagram}
                        googleLink = {this.props.event.google}
                        phone = {this.props.event.phone_number}
                        event = {this.props.event}
                        eventLogo = {this.props.event.logo}
                        eventName = {this.props.event.name}
                        eventAlias = {this.props.event.alias}
                        availableLocales = {this.state.availableLocales}
                        languageClick  = {this.languageClick}
                        onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                        onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                        onClickAccountItem = {this.OnClickAccountItem}
                    /> : null}
                    {this.user != null && this.user.isBooking && Constants.showVirtualZone?
                    <VirtualZone
                        eventAlias = {this.props.event.alias}
                        event = {this.props.event}
                        onMenuItemClick = {(path)=>{
                            this.setState({selectedIndex : -1})
                            if(path != ''){
                                this.OnNavigateToPage(path)
                            }
                        }}
                        onNotificationSeen = {this.props.onNotificationSeen} 
                        onClearNotifications = {this.props.onClearNotifications}
                        notificationsCount = {this.props.unReadNotificationsCount}
                        notifications = {this.props.notifications}
                        onClickAccountItem = {this.OnClickAccountItem}
                        unReadCount = {this.props.unReadCount}
                        jobBoardEvent = {this.jobBoardEvent}
                        visitJobSeeker = {this.visitJobSeeker}
                        onClickNotification = {(path,isExternal)=>{
                            if(isExternal){
                                window.open(path,"_blank")
                            }else{
                                this.OnNavigateToPage(this.props.event.alias+path)
                            }
                        }}
                        menuItems = {this.props.virtualMenuItems}
                        user = {this.user} 
                    /> : null}
                </div>
                <div className={`content-main`}>
                    <Content
                        event = {this.props.event}
                        onClickBookNow = {()=>this.OnNavigateToPage(this.props.event.alias+'/booking')}
                        onClickAgenda = {()=>this.OnNavigateToPage(this.props.event.alias+'/agenda')}
                        downloadAgendaEvent ={this.downloadAgendaEvent}
                        downloadAgendaFormEvent = {this.downloadAgendaFormEvent} 
                        downloadAgenda = {this.downloadAgenda}
                        registerInterestEvent ={this.registerInterestEvent}
                        registerInterestSubmitEvent = {this.registerInterestSubmitEvent}
                        onClickContactUs = {this.contactUsEvent}
                        onClickSpeakers = {()=>this.OnNavigateToPage(this.props.event.alias+'/speakers')}
                        onClickSponsors = {()=>this.OnNavigateToPage(this.props.event.alias+'/partners')}
                        onClickReadMore = {(href)=>this.OnNavigateToPage(href)}
                        onNavigateToPage = {(path)=> this.OnNavigateToPage(this.props.event.alias+path)}
                        onClickDetails = {this.onClickDetails}
                        onClickSeeAllJobs = {(path)=> {
                            let selectedLocale = localStorage.getItem("language"+this.props.event.alias) || 'en';
                            if(this.user){
                                if(this.user.userRole != "boothRepresentative" && this.user.userRole != "organizer"){
                                var hash = localStorage.getItem('login_hash')
                                let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
                                +window.location.origin+"&alias="+this.props.event.alias+"&locale="+selectedLocale+"&route="+path
                                window.location.href = location
                                }
                            }
                            else{
                                // this.OnNavigateToPage(this.props.event.alias+'/login',{
                                //     allJobsNavigate: true,
                                //     route: path
                                // })
                                window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL+path.slice(1,path.length)+"?locale="+selectedLocale+"&URI="
                                +window.location.origin+"&alias="+this.props.event.alias
                            }
                        }}
                        downloadAgendaTitle ={this.downloadAgendaTitle}
                        agendaSubTitle = {this.agendaSubTitle}
                        downloadButtonText = {this.downloadButtonText}
                         />
                    <Footer1 
                        event = {this.props.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                </div>
                <Footer2 
                   event = {this.props.event}
                    isMarketplace = {this.props.event.is_marketplace}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
                
            </div>
        );
    }
}

export default compose(
    withRouter,
    withTranslation()
)(Home);