import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import $ from 'jquery'
import 'bootstrap'
import {Trans} from 'react-i18next'
export class BannerBottom extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            event:this.props.event,
			ajenda:{},
			speakers:{},
			gallary:{}
		}
		this.navbar = null
		this.sticky = null
	}
	
	OnClickNavItem = (id) => {
		let height = document.getElementById('stickyMenu').offsetHeight
		if(window.scrollY < 60){
			height = 100
		}
		if(document.getElementById(id) != null){
			$('html, body').animate({
				scrollTop: $("#"+id).offset().top - height
			}, 800);
		}
		
	}
    render(){
        if(this.state.event){
        	return (
				this.props.event.homepageNavbarEnabled == 1 ? 
				<section class = "bg_primary" id = "nav_b">
					<div class="container">
						<div class="row">
							<div class="col-md-12">
								<ul class = "nav justify-content-center nav_b">
									<li class="nav-item">
										<a style = {{cursor : 'pointer',fontSize : 17,wordBreak : 'break-word'}} onClick = {()=> this.OnClickNavItem('about')} class = "nav-link text-light py-3">
											<i class="fas fa-info-circle mr-2"></i><Trans>aboutEvent</Trans>
										</a>
									</li>
									<li class="nav-item">
										<a style = {{cursor : 'pointer',fontSize : 17,wordBreak : 'break-word'}} onClick = {()=> this.OnClickNavItem('ajenda')} class = "nav-link text-light py-3">
											<i class="far fa-calendar-alt mr-2" ></i><Trans>agenda</Trans>
										</a>
									</li>
									<li class="nav-item">
										<a style = {{cursor : 'pointer',fontSize : 17,wordBreak : 'break-word'}} onClick = {()=> this.OnClickNavItem('speakers')} class = "nav-link text-light py-3">
											<i class="fas fa-info-circle mr-2" ></i><Trans>speakers</Trans>
										</a>
									</li>
									<li class="nav-item">
										<a style = {{cursor : 'pointer',fontSize : 17,wordBreak : 'break-word'}} onClick = {()=> this.OnClickNavItem('event-photo')} class = "nav-link text-light py-3">
											<i class="fas fa-camera mr-2"></i><Trans>eventPhoto</Trans>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>:null
			);
		}
    }
}

export default BannerBottom;