import React from 'react';
import './styles.css'
import utility,{jobBoardNavigator} from '../../../Utility/utility'
import { withRouter } from 'react-router';
const StepByStepBanner = (props)=>{
    const user  = utility.getLoggedInUser(props.event.alias)
    const OnNavigateToPage = (path,data = null) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            props.history.push(path)
        }else{
            props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    return (
        <>
        <section class = "steps-banner py-3 py-md-5" style={{cursor:'pointer'}}  onClick={()=>{
            if(user == null){
                OnNavigateToPage(`${props.event.alias}/sign_up`)
            }else{
                // let selectedLocale = localStorage.getItem("language"+props.event.alias) || 'en';
                //     var hash = localStorage.getItem('login_hash')
                //     let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
                //     +window.location.origin+"&alias="+props.event.alias+"&locale="+selectedLocale
                //     window.location.href = location
                jobBoardNavigator(process.env.REACT_APP_JOBFAIR_REDIRECT_URL,process.env.REACT_APP_JOBFAIR_REDIRECT_URL,props.event.alias,'_self',user)
                }
                // else
                //     // this.OnNavigateToPage(this.props.event.alias+'/login',{
                //     //     allJobsNavigate: true,
                //     //     route: path
                //     // })
                //     window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL+path.slice(1,path.length)+"?locale="+selectedLocale+"&URI="
                //     +window.location.origin+"&alias="+this.props.event.alias
                // }
            // window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL
        // }

        }}>
		<div class="container text-center">
			<div class="row">
				<div class="col-md-12 position-relative">
					<img class="w-100" src={props.event.homepageBannerMiddle}/>
				</div>
			</div>
		</div>
	</section>
        {/* <div className="step-container" onClick={()=>{
            if(user == null){
                OnNavigateToPage(`${props.event.alias}/sign_up`)
            }else{
                let selectedLocale = localStorage.getItem("language"+props.event.alias) || 'en';
                    var hash = localStorage.getItem('login_hash')
                    let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
                    +window.location.origin+"&alias="+props.event.alias+"&locale="+selectedLocale
                    window.location.href = location
                }
                // else
                //     // this.OnNavigateToPage(this.props.event.alias+'/login',{
                //     //     allJobsNavigate: true,
                //     //     route: path
                //     // })
                //     window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL+path.slice(1,path.length)+"?locale="+selectedLocale+"&URI="
                //     +window.location.origin+"&alias="+this.props.event.alias
                // }
            // window.location.href = process.env.REACT_APP_JOBFAIR_REDIRECT_URL
        // }

        }}>
             <img className="step-image" src={props.event.homepageBannerMiddle}/>
        </div> */}
        </>)}


export default withRouter(StepByStepBanner)