import React from "react";
import { HeaderImage } from "../Header-Image";
import { BannerBottom } from "../Banner-Bottom";
import { WelcomeSection } from "../Welcome-Section";
import { RegisterModel } from "../RegisterModel";
import Agenda from "../Agenda";
import AgendaTime from "../AgendaTime";
import SpeakerV1 from "../SpeakerV1";
import SpeakerV2 from "../SpeakerV2";
import EventNumbers from "../EventNumbers";
import EventBlog from "../EventBlog";
import Sponsers from "../Sponsers";
import ContactInfo from "../ContactInfo";
import {Trans} from 'react-i18next'
import { Constants } from "../../Constants";
import Testimonials from "../Testimonials";
import ImageGallary from "../Image-Gallary";
import NewsLetter from "../NewsLetter";
import HomeVideos from "../HomeVideos";
import JobBoard from "../JobBoard/index.js";
import Offer from "../marketplace/offer/index";
import PremiumContent from "../marketplace/preminumContent";
import TeleHealthHeadr from "../marketplace/Header-image";
import TeleHealthAbout from "../marketplace/about-us";
import utitlity from "../../Utility/utility";
import MapFloor from '../1 - Sub menu components/Mapfloor/index'
import StepByStepBanner from "../1 - Sub menu components/step-by-step-banner";

export class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.event,
      event_alias: this.props.event_alias,
      blog:[],
      gallery:[]
    };
    this.myRef = React.createRef();
    this.user = null;
  }
  render() {
    this.user = utitlity.getLoggedInUser(this.props.event.alias);
    return (
      <div>
        {this.props.event.is_marketplace == "0" ? (
          <HeaderImage
            event={this.state.event}
            onClickBookNow={this.props.onClickBookNow}
            marketplace={this.props.is_marketplace}
            user = {this.user}
          />
        ) : (
          <TeleHealthHeadr event={this.state.event} user={this.user} />
        )}
        {/* {this.props.event.is_marketplace == "0" ? ( */}
          <BannerBottom event={this.state.event} />
        {/* ) : null} */}
         {this.props.event.is_jobfair == 1 && (!this.user || this.user.boardType == 'jobSeeker')? (
          <JobBoard
            alias={this.state.event.alias}
            onClickDetails={this.props.onClickDetails}
            onClickSeeAllJobs={this.props.onClickSeeAllJobs}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" ? (
          <WelcomeSection
            event={this.state.event}
            onClickDownloadAgenda={this.props.onClickAgenda}
            onClickContactUs={this.props.onClickContactUs}
            registerInterestEvent={this.props.registerInterestEvent}
            downloadAgenda={this.props.downloadAgenda}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" ? (
          <RegisterModel
            eventAlias={this.state.event.alias}
            agendaTitle={this.props.downloadAgendaTitle}
            agendaSubTitle={this.props.agendaSubTitle}
            agendaSubmitButtonText={this.props.downloadButtonText}
            registerInterestSubmitEvent={this.props.registerInterestSubmitEvent}
            downloadAgendaEvent={this.props.downloadAgendaEvent}
            downloadAgendaFormEvent={this.props.downloadAgendaFormEvent}
            showCloseButton={true}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" ? (
          <Agenda
            event={this.state.event}
            event_alias={this.state.event.alias}
            type={Constants.Highlight}
            onClickCard={this.props.onClickAgenda}
          />
        ) : (
          <TeleHealthAbout
            homepageVideo={this.props.event.homepageVideo}
            aboutus={this.props.event.welcome_description}
            event={this.props.event}
            user={this.user}
          />
        )}
        {this.props.event.is_marketplace == "0"  && this.props.event.is_jobfair == 0 ? (
          <AgendaTime
            event={this.state.event}
            event_alias={this.state.event_alias}
            onClickFullAgenda={this.props.onClickAgenda}
          />
        ) : null}

        {this.props.event.is_marketplace == "0" ? (
          this.props.event.is_jobfair == 0 ?
          <EventBlog
            event={this.state.event}
            event_alias={this.state.event_alias}
            type={Constants.Blog}
            onClickReadMore={this.props.onClickReadMore}
          />:this.props.event.homepageBannerMiddle == null ? null :< StepByStepBanner
          event={this.props.event}
          />
        ) : (
          <Offer event={this.props.event} user={this.user} />
        )}
        {this.props.event.is_marketplace == "0"  &&  this.props.event.is_jobfair == 0 ? (
          this.state.event.speaker_layout == "V1" ? (
            <SpeakerV1
              event={this.state.event}
              event_alias={this.state.event_alias}
              onClickSeeAllSpeakers={this.props.onClickSpeakers}
            />
          ) : (
            <SpeakerV2
              event={this.state.event}
              event_alias={this.state.event_alias}
              onClickSeeAllSpeakers={this.props.onClickSpeakers}
            />
          )
        ): null} 
        {
        this.props.event.is_marketplace == "1" ?
          <PremiumContent alias = {this.state.event}  />
        :null}

        {/* insert here */}
        {this.props.event.is_marketplace == "0" ? (
          <HomeVideos
            eventAlias={this.state.event.alias}
            onNavigateToPage={this.props.onNavigateToPage}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" ? (
          <EventNumbers
            event={this.state.event}
            event_alias={this.state.event_alias}
            type={Constants.Counter}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" ? (
          <Sponsers
          title={ this.props.event.is_jobfair == 1 ? <Trans>partners1</Trans>:<Trans>partners</Trans>}
            event={this.state.event}
            event_alias={this.state.event_alias}
            onClickSeeAllSponsors={this.props.onClickSponsors}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair ==0 ? (
          <ContactInfo
            event={this.state.event}
            event_alias={this.state.event_alias}
            type={Constants.Additional}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" ? (
          <Testimonials
            event={this.state.event}
            event_alias={this.state.event_alias}
            type={Constants.Testimonial}
          />
        ) : null}
        {
          this.props.event.is_jobfair == 1 ? <MapFloor event =  {this.props.event} type="home"/> :null 
        }
        {this.props.event.is_marketplace == "0" ? (
          <ImageGallary
            event={this.state.event}
            event_alias={this.state.event_alias}
            type={Constants.Gallary}
          />
        ) : null}
        {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ? (
          <NewsLetter
            title="SIGN UP FOR EVENT UPDATES"
            event={this.state.event}
            event_alias={this.state.event_alias}
            buttonText="Sign Up"
            type="eventUpdate"
          />
        ) : null}
      </div>
    );
  }
}

export default Content;
