import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import {Trans} from 'react-i18next'
export class PresentaionView extends React.Component{

    constructor(props) {
        super(props);
        this.selectedPresentationIndex = 0
        this.state = {
            selectedPresentationIndex : 0,
            showErrorMessage : true
        };
    }
    componentDidMount(){
        var passField = document.getElementById("filePass")
        if(passField){
            passField.addEventListener("keypress", (event)=> {
                if (event.keyCode === 13) { // key code of the keybord key
                  event.preventDefault();
                 this.OnClickSubmit()
                }
              });
        }
    }
    componentWillUnmount(){
        var passField = document.getElementById("filePass")
        if(passField){
            passField.removeEventListener("keypress", (event)=> {
                if (event.keyCode === 13) { // key code of the keybord key
                  event.preventDefault();
                 this.OnClickSubmit()
                }
              });
        }
    }

    OnClickSubmit = () => {
        var value = document.getElementById('filePass').value
        this.setState({showErrorMessage : true})
        this.props.onSubmitPassword(value,this.selectedPresentationIndex)
        setTimeout(()=>{
            this.setState({showErrorMessage : false})
        },3000)
    }
    OnClickAccessPresentations = (index) => {
        this.selectedPresentationIndex = index
        this.setState({selectedPresentationIndex : index,showErrorMessage : false})
    }
    render(){
        return(
            <div>
                <section class = "presentation py-3 py-md-5">
                    <div class = "content">
                        {this.props.presentations.length > 0 ?
                        <>
                        <h5 class = "text-center font-weight-bold"><Trans>downloadPresentations</Trans></h5>
                        <div class = "container py-3 py-md-5">
                        {this.props.presentations.map((item,index)=>{
                            return(
                                <div class = "row presentation-col border py-3 px-2 bg-light mb-2">
                                    <div class = "col-sm-8 mb-2 mb-md-0">
                                        <p class = "mb-0 mt-2 f-500">{item.title}</p>
                                    </div>
                                    
                                    <div class = "col-sm-4 text-sm-right">
                                        <a href = "" onClick = {()=>this.OnClickAccessPresentations(index)} class = "btn rounded-0" data-toggle="modal" data-target="#pass_modal">
                                            <Trans>access</Trans></a>
                                    </div>
                                </div>  
                            )
                        })}
                        </div>
                        </> : <div style = {{width : '100%',height : '100%',justifyContent : 'center',alignItems : 'center',display : 'flex'}}>
                            <span><Trans>noPresentations</Trans></span>
                            </div>}
                    </div>
                </section>

                <div class="modal fade" id="pass_modal" tabIndex = '-1'>
                    <div class="modal-dialog">
                        <div class="modal-content">
                
                            <div class="modal-header border-0">
                            <h4 class="modal-title">{this.props.presentations != null && this.props.presentations.length > 0? this.props.presentations[this.state.selectedPresentationIndex].title : ""}</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            
                            <div class="modal-body">
                                <form action = "">
                                    <div class = "form-group">
                                        <input id = "filePass" type = "password" class = "form-control" placeholder = {this.props.translation.enterPasscode}/>
                                    </div>
                                    <div class = "form-group">
                                        <a onClick = {this.OnClickSubmit} class = "btn form-control bg_main rounded-0">
                                            <Trans>submit</Trans></a>
                                        {this.state.showErrorMessage ? 
                                        <div style = {{position : 'absolute',left : 0,right : 0,width : '100%',display : 'flex',justifyContent : 'center',alignItems : 'center'}} className = "text-danger">{this.props.passwordError}</div> : null}
                                        {/* <span id="click" class="text-danger">{this.props.passwordError}</span> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        )
    }//end of render

}

export default PresentaionView;