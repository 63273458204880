import React, { useState, useEffect } from "react";
import Footer2 from "../../Footer/Footer2";
import Header from "../../Header/Index";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { getVideos, getFilters } from "../actions/index";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import * as Actions from "../actionTypes";
import VideoContent from "./content";
import { Link } from "react-router-dom";
import getVideoId from "get-video-id";
import "./styles.css";
import PremiumContent from "../preminumContent/index";
import VirtualZone from "../../Header/virtualZone";
import { Constants } from "../../../Constants";
import { toLower } from "lodash";
import utitlity, { wordsRestrictor } from "../../../Utility/utility";
import $ from "jquery";
import  PermiumUserModal  from "../../modals/premiumUserModal";
import Empty from "../empty-container";
import { fetchPagesData } from "../api";

const Videos = (props) => {
  const [selectedIndex, setIndex] = useState(
    props.headerMenuItems.findIndex((x) => x.href.indexOf("/mp-videos") > -1)
  );
  const [selectedSubmenuIndex, selectSubmenu] = useState(-1);
  const [Videos, setVides] = useState([]);
  const [filterVideosData, setFilterVideos] = useState([]);
  const [loading, setLoadin] = useState(true);
  const [availableLocales, setAvaliableLocales] = useState(
    props.availableLocales
  );
  const [contentComponent, setComponent] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [filter, setFilter] = useState([]);
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterVideoIds, setFilterId] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageTitle,setPageTitle] = useState("")
  const [pageDescription,setPageDescription] = useState('')
  var user = utitlity.getLoggedInUser(props.event.alias);
  var dispatch =  useDispatch ()
  useEffect(() => {
     console.log(Constants.showVirtualZone)
    async function fetchData() {
      let prams = {
        type:'videos'
      }
      await fetchPagesData(props.event.alias,prams,null,(err,data)=>{
        if(data){
          setPageTitle(data.data.title)
          setPageDescription(data.data.description)
        }
      })
    }
    fetchData();
  },[]);
  
  useEffect(() => {
    setLoadin(props.loading);
  }, [props.loading])
  useEffect(() => {
    let params = {
      type: "videos",
    };
    if (user == null) {
      props.getVideos(props.event.alias, params);
      // dispatch({type: Actions.LOADING+Actions.CONTENT})
      // setLoadin(props.loading);
    } else {
      props.getVideos(props.event.alias, params, user.token);
      // dispatch({type: Actions.LOADING+Actions.CONTENT})
      // 
    }
  },[])

  useEffect(() => {
    if (props.videos != undefined) {
      setVides([...props.videos]);
      setFilterVideos([...props.videos]);
      setLoadin(props.loading);
      let params = {
        type: "videos",
        subType: props.fileFormatId,
      };
      props.getFilters(props.event.alias, params);
    }
  }, [props.videos]);
  useEffect(() => {
    return () => {
      dispatch({
        type: Actions.VIDEOS,
        payload: []
    })
    }
}, [])

  useEffect(() => {
    console.log(props.videos);
    let updateVideo = Videos.map((item) => {});
  }, [props.videos]);

  const searchVideo = () => {
    if (searchQuery.replace(/\s/g, "").length == 0) {
      setVides([...filterVideosData]);
    } else {
      var searchVideos = filterVideosData.filter((item) =>
        toLower(item.details.title).includes(toLower(searchQuery))
      );
      setVides([...searchVideos]);
    }
    // setSearchQuery('')
  };
  const openPlayer = () => {
    setComponent(false);
  };
  const filterVideos = (id) => {
    var FilterContent = [];
    if (id == "filter") {
      if (filterVideoIds.length > 0) {
        var filterVideo = filterVideosData.filter(
          (item) => Object.keys(item.filters).length > 0
        );
        filterVideo.map((item) => {
          Object.keys(item.filters).map((key) => {
            item.filters[key].map((ids) => {
              if (filterVideoIds.includes(ids.id)) {
                FilterContent.push(item);
              }
            });
          });
        });
        setVides([...new Set(FilterContent)]);
      } else {
        setVides([...filterVideosData]);
      }
    } else {
      setVides([...filterVideosData]);
      setFilterId([]);
    }
  };
  const OnClickAccountItem = (index, path) => {
    props.onClickAccountItem(index, path);
    if (index > -1) {
      props.history.replace("");
      props.history.push({
        pathname: props.event.alias + "/account",
        state: { tabIndex: index },
      });
    } else {
      localStorage.removeItem(
        Constants.API_TOKEN + props.event.alias + "user_info"
      );
      window.location.reload();
    }
  };

  const OnNavigateToPage = (path) => {
    console.log(path);
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    }
    props.history.push(path);
  };

  // const vimeoThumbnail = async(url ="https://vimeo.com/537672480")=>{
  // 	const { id } = getVideoId(url);
  // 	console.log(id)
  // 	const response = await fetch(
  // 		`https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${id}`
  // 	  );
  // 	  const json = await response.json();
  // 	//   console.log(json.thumbnail_url.split("_")[0] + "_320x180")
  // 	  console.log(json)
  // 	  return json.thumbnail_url;
  // }
  return (
    <>
      <PermiumUserModal
        id="premiumSession"
        iconClass="fa-star"
        title="Premium Video"
        description="<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
        buttonText="Join Now"
        showButton={true}
        onClose = {()=>{
          $("#premiumSession").modal('hide')
      $('.modal-backdrop').remove();
        }}
        onClickBookNow={() => {
          $("#premiumSession").modal("hide");
          $(".modal-backdrop").remove();
          OnNavigateToPage(`${props.event.alias}/membership`);
        }}
      />
      <div id="header-top">
        <Header
          selectedIndex={selectedIndex}
          onMenuItemClick={OnNavigateToPage}
          selectedSubIndex = {-1}
          elements={props.headerMenuItems}
          isMarketplace={props.event.is_marketplace}
          facebookLink={props.event.facebook}
          linkedInLink={props.event.linkedIn}
          twitterLink={props.event.twitter}
          instagramLink={props.event.instagram}
          googleLink={props.event.google}
          event = {props.event}
          phone={props.event.phone_number}
          eventLogo={props.event.logo}
          eventName={props.event.name}
          eventAlias={props.event.alias}
          availableLocales={availableLocales}
          languageClick={props.languageClick}
          onLoginClick={() => OnNavigateToPage(props.event.alias + "/login")}
          onSignupClick={() => OnNavigateToPage(props.event.alias + "/sign_up")}
          onClickAccountItem={OnClickAccountItem}
        />
        {user != null && user.isBooking && Constants.showVirtualZone ? (
          <VirtualZone
            eventAlias={props.event.alias}
            event = {props.event}
            onMenuItemClick={(path) => {
              if (path != "") {
                OnNavigateToPage(path);
              }
            }}
            unReadCount={props.unReadCount}
            onNotificationSeen={props.onNotificationSeen}
            onClearNotifications={props.onClearNotifications}
            notificationsCount={props.unReadNotificationsCount}
            notifications={props.notifications}
            onClickAccountItem={OnClickAccountItem}
            onClickNotification={(path, isExternal) => {
              if (isExternal) {
                window.open(path, "_blank");
              } else {
                OnNavigateToPage(this.props.event.alias + path);
              }
            }}
            menuItems={props.virtualMenuItems}
            user={user}
          />
        ) : null}
      </div>
      {contentComponent == false ? (
        <>
          <section class={`telehealth-inner-banner  bg_primary py-3`}>
            <div class="container py-2 text-center">
              <div class="row">
                <div class="col-12">
                  <h2>{pageTitle}</h2>
                </div>
              </div>
            </div>
          </section>
          <section class="presentation-sec videos-sec py-3">
            <div class="container">
              <div class="search-feild my-3">
              <p>{pageDescription}</p>
                <div class="row">
                  <div class="col-8 col-md-9 form-inline">
                    <div class="form-group has-clear">
                      <input
                        id="ppt-input"
                        type="text"
                        class="form-control search-input"
                        value={searchQuery}
                        placeholder="Type your keyword"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          if (e.target.value.replace(/\s/g, "").length == 0) {
                            setVides([...filterVideosData]);
                          } else {
                            var searchVideos = filterVideosData.filter((item) =>
                              toLower(item.details.title).includes(
                                toLower(searchQuery)
                              )
                            );
                            setVides([...searchVideos]);
                          }
                        }}
                        onKeyUp={(e) => {
                          if (e.key == "Enter") {
                            searchVideo();
                          }
                        }}
                      />
                      <span
                        class={`form-control-clear ${
                          searchQuery.length > 0 ? "" : "d-none"
                        }`}
                        onClick={() => {
                          setSearchQuery("");
                          setVides([...filterVideosData]);
                        }}
                      >
                        <i class="fas fa-times"></i>
                      </span>
                      <button
                        type="submit"
                        class="btn search-btn"
                        onClick={() => {
                          searchVideo();
                        }}
                      >
                        <i class="fas fa-search mr-2"></i>
                        <span>Search</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-4 col-md-3 text-right">
                    <div class="filter-area position-relative">
                      {Videos.length > 0 ?
                      <button
                        class="btn btn-filter"
                        onClick={() => {
                          setFilterDialog(!filterDialog);
                        }}
                      >
                        <i class="fas fa-filter mr-2"></i> Filters
                      </button>:null}
                      <div
                        class={`filter-option ${
                          filterDialog == true ? "" : "hiddens"
                        }`}
                      >
                        <div class="filter-opt-header">
                          <button
                            class="btn reset-filter"
                            onClick={() => {
                              filterVideos("remove");
                            }}
                          >
                            Reset Filter
                          </button>
                          <button
                            class="btn apply-filter"
                            onClick={() => {
                              filterVideos("filter");
                              document.getElementById("cross-filter-btn").click()

                            }}
                          >
                            Apply Filter
                          </button>
                          <div
                            class="cross-filter"
                            id = "cross-filter-btn"
                            onClick={() => {
                              setFilterDialog(false);
                            }}
                          >
                            <i class="fas fa-times"></i>
                          </div>
                        </div>
                        <div class="filter-opt-body p-4">
                          {props.filters &&
                            Object.keys(props.filters).map((key) => {
                              return (
                                <div class="form-group">
                                  {console.log(key)}
                                  <p> {key}: </p>
                                  {props.filters[key].map((key) => {
                                    return (
                                      <>
                                        <div class="form-check form-check-inline">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="gridCheck"
                                            checked={
                                              filterVideoIds.includes(key.id)
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              if (
                                                filterVideoIds.includes(key.id)
                                              ) {
                                                let videos =
                                                  filterVideoIds.filter(
                                                    (item) => item != key.id
                                                  );
                                                setFilterId([...videos]);
                                              } else {
                                                setFilterId([
                                                  ...filterVideoIds,
                                                  key.id,
                                                ]);
                                              }
                                            }}
                                          />
                                          <label
                                            style ={{pointerEvents:'none'}}
                                            class="form-check-label"
                                            for="gridCheck"
                                          >
                                            {key.value}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container video-listing mt-4">
              <div class="row">
                {loading == false ? (
                  Videos.length > 0 ? (
                    Videos &&
                    Videos.map((item) => {
                      // {console.log(item)}
                      return (
                        <div class="col-md-4 mb-3">
                          <div class="card video-card h-100" style={{cursor:'pointer'}}  onClick={(e) => {
                                e.preventDefault();
                                let loggedIn =  utitlity.getLoggedInUser(props.event.alias)
                                if (item.details.is_allowed == 1) {
                                  setComponent(true);
                                  setVideoId(item.details.id);
                                } else {
                                  if (loggedIn != null) {
                                    if (item.details.is_allowed == 0) {
                                      $("#premiumSession").modal("show");
                                    } else {
                                      setComponent(true);
                                      setVideoId(item.details.id);
                                    }
                                  } else {
                                    OnNavigateToPage(
                                      `${props.event.alias}/login`
                                    );
                                  }
                                }
                              }}>
                            <div class="video-banner">
                              {item.details.thumbnail != null ? (
                                <img
                                  src={item.details.thumbnail}
                                  alt="blog-1_img"
                                />
                              ) : (
                                <img
                                  src={
                                      item.details.videoUrl == true
                                      ? `${item.details.thumbNails}`
                                      : //  item && item.details && item.details.data_url && vimeoThumbnail('https://vimeo.com/537672480'.toString())
                                        // 'https://i.vimeocdn.com/video/1112998799_640'
                                        "/images/telehealth-images/video_placeholder.png"
                                  }
                                  alt = ""
                                />
                              )}
                            </div>
                            <div
                              class="video-caption w-btn my-3"
                            >
                              <ul class="nav vc-content px-3">
                                <li class="nav-lsit pr-3">
                                  <button class="vp-btn">
                                    <i class="fas fa-play"></i>
                                  </button>
                                </li>
                                <li class="nav-lsit pr-3 pre-title">
                                  {wordsRestrictor(item.details.title, 10)}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Empty
                      icon={<>
                      <svg xmlns="http://www.w3.org/2000/svg" width="101.102" height="76.642" viewBox="0 0 101.102 76.642"><g transform="translate(0 -61.936)"><path d="M97.862,61.936H16.328a9.747,9.747,0,0,0-6.523,2.491L8.154,71.72v47.409a9.784,9.784,0,0,0,9.784,9.784H97.862l7.293-1.75a9.748,9.748,0,0,0,2.491-6.523V71.72a9.784,9.784,0,0,0-9.784-9.784Z" transform="translate(-6.544 0)" fill="#0e9ac8"/><path d="M154.435,436.809H92.47a1.631,1.631,0,1,1,0-3.261h61.966a1.631,1.631,0,1,1,0,3.261Z" transform="translate(-72.902 -298.232)" fill="#0e9ac8"/><path d="M225.736,168.086l-25.321-12.66a3.32,3.32,0,0,0-.689-.256l-2.614,3.173v25.321l3.3,1.339,25.321-12.66,1.05-3.434A3.273,3.273,0,0,0,225.736,168.086Z" transform="translate(-158.19 -74.823)" fill="#fff"/><path d="M9.784,137.286a6.523,6.523,0,0,1-6.523-6.523V74.55A9.782,9.782,0,0,0,0,81.843v48.92a9.784,9.784,0,0,0,9.784,9.784H91.318a9.781,9.781,0,0,0,7.293-3.261Z" transform="translate(0 -10.123)" fill="#1f6ccc"/><path d="M201.046,178.521a4.892,4.892,0,0,1-7.08-4.376v-19.36a3.265,3.265,0,0,0-4.031,3.173v25.321a3.261,3.261,0,0,0,4.72,2.917l25.321-12.66a3.27,3.27,0,0,0,1.05-5.012Z" transform="translate(-152.43 -74.44)" fill="#d9e4ef"/></g></svg>
                      </>}
                      msg="No Videos Yet"
                    ></Empty>
                  )
                ) : (
                  <div
                    style={{
                      marginTop: 6,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                      width: "100%",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color="#00b6ff"
                      height={45}
                      width={45}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      ) : (
        <VideoContent
          videos={Videos}
          videoId={videoId}
          backTo={() => {
            setComponent(false);
          }}
          event={props.event}
          OnNavigateToPage={OnNavigateToPage}
          openPlayer={openPlayer}
          
        />
      )}
      {/* <section class = "middle-banner text-center">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<p>
					<img src="/images/logos/ic-diamond.svg" alt="icon-diamond"/>
				</p>
				<p>
					Interested to access Premium Content?
				</p>
				<a href="#" class = "btn">
					Become a Member
				</a>
			</div>
		</div>
	</div>
</section> */}
      {contentComponent == false ? (
          <PremiumContent alias={props.event} />
      ) : null}
      <Footer2
      event = {props.event}
        isMarketplace={props.event.is_marketplace}
        copyRightLogo={props.event.copyright_logo}
        copyRightText={props.event.copyright_text}
        copyRightLink={props.event.copyright_link}
        showCopyRight={props.event.copyright_text || props.event.copyright_logo}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  videos: state.marketPlaceReducer.videos,
  loading: state.marketPlaceReducer.loading,
  fileFormatId: state.marketPlaceReducer.fileFormatId,
  filters: state.marketPlaceReducer.filters,
});
const mapDispatchToProps = (dispatch) => ({
  getVideos: (alias, params, token) =>
    dispatch(getVideos(alias, params, token)),
  getFilters: (alias, params) => dispatch(getFilters(alias, params)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Videos);
