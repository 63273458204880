import React from 'react'
import { Calendar, Views,momentLocalizer } from 'react-big-calendar'
// import '../Invitation/node_modules/react-big-calendar/lib/css/react-big-calendar.css'
import '../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import { monthNames } from '../../../Utility/utility'
import { send } from 'react-ga'
import _ from 'lodash'
import {Trans} from 'react-i18next'
// import globalize from 'globalize'
export const Content = (props) => {
    const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
        backgroundColor: 'lightblue',
        },
    })

    let allViews = Object.keys(Views).map(k => Views[k])
    const localizer = momentLocalizer(moment)
    const CustomEvent = ({ event }) => (
        <span onClick = {()=>alert('clicked')}> <strong> {event.title} </strong> </span> 
    );
    var meetingId = []
    var filterMeetings = props.allInvites.received.filter(item=>{
        if(!meetingId.includes(item.id)){
            meetingId.push(item.id)
            return item
        }
    })
    return (
    <section class="calendar-event">
            <div class="container-fluid d-block d-lg-none py-3">
                <div class="row">
                    <div class="col-4 col-md-6 d-flex allign-item-center">
                        <h5 class = "f-600"><Trans>invitations</Trans></h5>
                    </div>
                    <div class="col-8 col-md-6 d-flex allign-item-center justify-content-end">
                        <a href="#" class="btn see_invitations">
                            <Trans>seeInvitations</Trans>
                        </a>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4 bg-light tab-invitation-left">
                            {props.loadingInvites ?
                            <div style = {{width : '100%',height : window.innerHeight - 150,background : 'rgba(0,0,0,0.1)',position : 'absolute',top : 0,left : 0,display : 'flex',justifyContent : 'center',alignItems : 'center',zIndex : 999}}>
                                <Loader
                                    type = 'Oval'
                                    color = "#00b6ff"
                                    height = {30}
                                    width = {30}
                                />
                            </div> : null}
                            <div class="calendar-tab  py-5 py-lg-2 px-3 px-3">
                                <div class="c_tab-header">
                                <div class="row">
                                    <a href="#" class="close-icons d-block d-lg-none" id="close-m">
                                        <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                                    </a>
                                    <div class="col-4 col-md-6 d-flex allign-item-center">
                                        <h5 class = "f-600"><Trans>invitations</Trans></h5>
                                    </div>
                                    <div class="col-8 col-md-6 d-flex allign-item-center">
                                        <div class="btn-group invitation w-100">
                                            <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickFilterToggle(true)} class={props.showReceived ? "btn btn-c-tab rcv active" : "btn btn-c-tab rcv invitation-btn"}>
                                                    <Trans>received</Trans>
                                                </a>
                                            <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickFilterToggle(false)} class={!props.showReceived ? "btn btn-c-tab snd active" : "btn btn-c-tab snd invitation-btn"}>
                                                    <Trans>sent</Trans>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div class="c_tab-content mt-4">
                                    {props.allInvites != undefined && <div style = {{overflowY : filterMeetings.length == 0 ? "hidden" : "scroll"}} class={props.showReceived ? "c_tab-body active" : "c_tab-body"} id = "receive">
                                        {filterMeetings.length == 0 ?
                                        <div className = "text-center">
                                            <Trans>noInvitation</Trans>
                                        </div> : null}
                                        <div class="row">
                                            {filterMeetings.map((invite,index)=>{
                                                let sender = invite.sender_details
                                                let meetingDateSplit = invite.meeting_date.split('-')
                                                let meetingDate = monthNames[meetingDateSplit[1]-1]+" "+meetingDateSplit[2]+", "+meetingDateSplit[0]
                                                let meetingTime = invite.meeting_start_time + " - " +invite.meeting_end_time
                                                let bottomMarginClass = invite.status == 0 ? " mb-2" : ""
                                                let profileImageUrl = sender == null ? "" : sender.profileImageUrl == null ? "" : sender.profileImageUrl
                                                if(sender == null){
                                                    return null
                                                }
                                                return(
                                                <div class="col-12 px-0">
                                                    <div class="msg-block bg-white mb-2">
                                                        <p class="top">
                                                            <a>{`${sender.firstName}`.replace(null,'')+ "  "+`${sender.lastName}`.replace(null,'')}</a> Sent you a Meeting Request
                                                        </p>
                                                        <p class="spn">
                                                            <a><Trans>schedule</Trans>:</a> {meetingDate + " "+ meetingTime}
                                                        </p>

                                                        <div class="row spk-rows">
                                                            <div class="col-3 col-md-2 d-flex allign-item-center">
                                                                <img src={profileImageUrl != "" ? profileImageUrl : "/images/profile-placeholder.png"} alt="chat-img"/>
                                                            </div>
                                                            <div class="col-9 col-md-10">
                                                                <h6>{`${sender.firstName}`.replace(null,'')+ "  "+`${sender.lastName}`.replace(null,'')}</h6>
                                                                <p>{sender.jobTitle}</p>
                                                                <p>{sender.companyName}</p>
                                                            </div>
                                                        </div>
                                                        <div class={"row mt-3 position-relative text-center"+bottomMarginClass}>
                                                            {invite.status == 0 ?
                                                            <div class="col-12 px-0 px-md-3">
                                                                <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickUpdateInviteStatus(index,invite.id,1)} class="btn t_action-btn accept mx-1">
                                                                    <Trans>acceptInvitation</Trans>
                                                                </a>
                                                                <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickUpdateInviteStatus(index,invite.id,2)} class="btn t_action-btn mx-1">
                                                                    <Trans>decline</Trans>
                                                                </a>
                                                                {props.updatingInviteStatus && invite.id == props.updatingInviteId ? 
                                                                <div style = {{position : 'absolute',top : 46,left : '50%',transform: 'translate(-50%,-50%)'}}>
                                                                    <Loader
                                                                        type = 'Oval'
                                                                        color = "#00b6ff"
                                                                        height = {20}
                                                                        width = {20}
                                                                    />
                                                                </div> : null}
                                                                <div style = {{position : 'absolute',fontSize : 11}} class="text-center text-red">{invite.errorMessage}</div>
                                                            </div> : 
                                                            invite.status == 1 ?
                                                            <div class="text-center col-12 px-0 px-md-3">
                                                                <span class = "text-success"><Trans>invitationAccepted</Trans></span>
                                                            </div> :
                                                            <div class="text-center col-12 px-0 px-md-3">
                                                                <span class = "text-red"><Trans>invitationRejected</Trans></span>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>}

                                    {props.allInvites != undefined && <div style = {{overflowY : props.allInvites.sent.length == 0 ? "hidden" : "scroll"}} class={!props.showReceived ? "c_tab-body active" : "c_tab-body"} id = "send">
                                        {props.allInvites.sent.length == 0 ?
                                        <div className = "text-center">
                                            <Trans>noInvitationSent</Trans>
                                        </div> : null}
                                        <div class="row">
                                            {props.allInvites.sent.map((invite,index)=>{
                                                let receiver = invite.receiver_details
                                                let meetingDateSplit = invite.meeting_date.split('-')
                                                let meetingDate = monthNames[meetingDateSplit[1]-1]+" "+meetingDateSplit[2]+", "+meetingDateSplit[0]
                                                let meetingTime = invite.meeting_start_time + " - " +invite.meeting_end_time
                                                let profileImageUrl = receiver == null ? "" : receiver.profileImageUrl == null ? "" : receiver.profileImageUrl
                                                if(receiver == null){
                                                    return null
                                                }
                                                return(
                                                <div class="col-12 px-0">
                                                    <div class="msg-block bg-white mb-2">
                                                        <p class="top">
                                                            <Trans>meetingReqSent</Trans> <a>{`${receiver.firstName}`.replace(null,'')+ "  "+`${receiver.lastName}`.replace(null,'')}</a>
                                                        </p>
                                                        <p class="spn">
                                                            <a><Trans>schedule</Trans>:</a> {meetingDate + " "+ meetingTime}
                                                        </p>

                                                        <div class="row spk-rows">
                                                            <div class="col-3 col-md-2 d-flex allign-item-center">
                                                                <img src={profileImageUrl != "" ? profileImageUrl : "/images/profile-placeholder.png"} alt="chat-img"/>
                                                            </div>
                                                            <div class="col-9 col-md-10">
                                                                <h6>{`${receiver.firstName}`.replace(null,'')+ "  "+`${receiver.lastName}`.replace(null,'')}</h6>
                                                                <p>{receiver.jobTitle}</p>
                                                                <p>{receiver.companyName}</p>
                                                            </div>
                                                        </div>

                                                        <div class="row mt-3">
                                                            {/* <div class="col-12 px-0 px-md-3">
                                                                <a href="#" class="btn t_action-btn accept">
                                                                    Accept Invitation
                                                                </a>
                                                                <a href="#" class="btn t_action-btn">
                                                                    Ignore
                                                                </a>
                                                            </div> */}
                                                            {invite.status == 1 ?
                                                            <div class="text-center col-12 px-0 px-md-3">
                                                                <span class = "text-success"><Trans>invitationAccepted</Trans></span>
                                                            </div> :
                                                            invite.status == 2 ?
                                                            <div class="text-center col-12 px-0 px-md-3">
                                                                <span class = "text-red"><Trans>invitationRejected</Trans></span>
                                                            </div> : 
                                                            <div class="text-center col-12 px-0 px-md-3">
                                                                <span style = {{color : 'blue'}}><Trans>pending</Trans></span>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>}
                                </div>

                            </div>
                    </div>
                    <div class = "col-lg-8" id="calendar-area">
                        {/* <div class = "container-fluid calendar-header pt-4"> */}
                            <Calendar
                                events={[...new Set(props.calendarEvents)]}
                                // views = {allViews}
                                defaultView = 'day'
                                showMultiDayTimes
                                onSelectEvent = {props.onClickEvent}
                                // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                                defaultDate={new Date()}
                                components={{
                                    // timeSlotWrapper: ColoredDateCellWrapper,
                                    toolbar : props.getCustomToolbar
                                }}
                                localizer={localizer}
                            />
                        {/* </div> */}
                    </div>
                </div>
            </div>


        </section>
    )
}