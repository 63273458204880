    const AppData = (state = {floatingPlayerLink: "",onlineUserdata:[],featureLoading:false,allFeature:[],naviagtionValue:false}, action) => {
    switch (action.type) {
        case 'EVENT_NUMBERS' :
            return{
                ...state,
                eventNumbers : action.payload
            }
        case 'FLOATING_PLAYER' :
            return{
                ...state,
                floatingPlayerLink : action.payload
            }
        case 'AGENDA_SESSION_ID':
            console.log(action.payload)
            return {
                ...state,
                slug:action.payload
            }
        case 'ONLINE_USER':
            return {
                ...state,
                onlineUserdata:[...action.payload]

            }
        case 'ALL_EVENT_FEATURE':
            return {
                ...state,
                allFeature:action.payload.data,
                featureLoading:action.payload.loading
            }
        case 'AGENDA_NAVIGATION':
            return {
                ...state,
                naviagtionValue:action.payload
            }
        default:
            return state
        }
  }
  export default AppData