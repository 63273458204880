import React from "react"
import ContentLoader from "react-content-loader"
import {Trans} from 'react-i18next'
const MyLoader = (props) => (
  
  <section class="py-3">
    <div class="container">
        <div class="row py-3">
            <div class="col-12">
                <ContentLoader
                    width={400}
                    height={200}
                    viewBox="0 0 400 200"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="5" y="2" rx="4" ry="3" width="270" height="140" />
                    <rect x="280" y="2" rx="4" ry="3" width="25" height="15" />
                    <rect x="310" y="2" rx="4" ry="3" width="25" height="15" />
                    <rect x="340" y="2" rx="4" ry="3" width="25" height="15" />
                    <rect x="370" y="2" rx="4" ry="3" width="25" height="15" />
                    <circle cx="295" cy="38" r="13" />
                    <rect x="312" y="32" rx="4" ry="3" width="70" height="5" />
                    <rect x="312" y="40" rx="4" ry="3" width="40" height="5" />
                    <circle cx="295" cy="70" r="13" />
                    <rect x="312" y="64" rx="4" ry="3" width="70" height="5" />
                    <rect x="312" y="72" rx="4" ry="3" width="40" height="5" />
                    <circle cx="295" cy="102" r="13" />
                    <rect x="312" y="96" rx="4" ry="3" width="70" height="5" />
                    <rect x="312" y="104" rx="4" ry="3" width="40" height="5" />
                    <rect x="5" y="150" rx="4" ry="3" width="60" height="35" />
                    <rect x="75" y="150" rx="4" ry="3" width="60" height="35" />
                    <rect x="145" y="150" rx="4" ry="3" width="60" height="35" />
                    <rect x="215" y="150" rx="4" ry="3" width="60" height="35" />
                    <rect x="285" y="150" rx="4" ry="3" width="60" height="35" />
                    <rect x="355" y="150" rx="4" ry="3" width="60" height="35" />
                </ContentLoader>
            </div>
        </div>
    </div>
  </section>
)
export default MyLoader