import React,{useState,useEffect,useRef} from 'react'
import {compose} from 'redux'
import { withRouter } from 'react-router-dom';
import MainView from './mainViewClass';
import LoginView from './loginView';
import {SessionsPopulated} from './actions/index'
import { connect } from 'react-redux'
import utility from '../../Utility/utility';
import api from './api'
function PanelistModeratorView(props){
    const [currentView,setCurrentView] = useState(0)
    const [selectedSession,setSelectedSession] = useState({agendaIndex : 0,sessionIndex : 0})
    const [loadedQuestions,setSavedQuestions] = useState([])
    const [loadedAnnouncements,setSavedAnnouncements] = useState([])
    const [sessionLoggedIn,setSessionLoggedIn] = useState(false)
    const isFirstRender = useRef(false)
    useEffect(()=>{
        setTimeout(() => {
            isFirstRender.current = true
        }, 0);
        document.body.style.background = "#F7F7F7";
        let loggedInSession = localStorage.getItem("loggedInSession")
        if(loggedInSession != null){
            loggedInSession = JSON.parse(loggedInSession)
            setSelectedSession(loggedInSession)
            setSessionLoggedIn(true)
            var obj = {
                event_id : props.event.alias,
                event_session_id : loggedInSession.sessionId,
                event_agenda_id : loggedInSession.agendaId,
                event_sub_session_id : loggedInSession.subSessionId, 
                pin : loggedInSession.pin
            }
            api.authenticatePin(props.event.alias,obj,(err,data)=>{
                setSessionLoggedIn(false)
                if(err){
                    return
                }
                setQuestionsAndAnnouncements(data.questions,data.announcements)
            })
        }
    },[])
    useEffect(()=>{
        if (isFirstRender.current) {
            setCurrentView(1)
        }
    },[loadedQuestions])
    const OnLoginSuccess = (savedQuestions,savedAnnouncements,obj) => {
        setQuestionsAndAnnouncements(savedQuestions,savedAnnouncements)
        setCurrentView(1)
        localStorage.setItem("loggedInSession",JSON.stringify(obj))
    }
    const setQuestionsAndAnnouncements = (savedQuestions,savedAnnouncements) =>{
        for(var i=0;i<savedQuestions.length;i++){
            var upVotedUserIds = []
            for(var j=0;j<savedQuestions[i].user_votes.length;j++){
                upVotedUserIds.push(savedQuestions[i].user_votes[j].user_id)
            }
            savedQuestions[i].eventAlias = savedQuestions[i].event_id
            savedQuestions[i].agendaId = savedQuestions[i].event_agenda_id
            savedQuestions[i].sessionId = savedQuestions[i].event_session_id
            savedQuestions[i].responseId = savedQuestions[i].id
            savedQuestions[i].id = i+1
            savedQuestions[i].message = savedQuestions[i].question
            savedQuestions[i].upVoteCount = {
                count : savedQuestions[i].votes_count,
                by : upVotedUserIds
            }
            savedQuestions[i].senderName = savedQuestions[i].name
            savedQuestions[i].approved = savedQuestions[i].is_approved == 1 ? true : false
            savedQuestions[i].userId = savedQuestions[i].user_id
            savedQuestions[i].time = savedQuestions[i].added_time
            let date = utility.calculateZoneSpecificTime(savedQuestions[i].created_at.replace(" ","T"))
            let hours = date.getHours()
            let minutes = date.getMinutes()
            if(minutes < 10){
                minutes = "0"+minutes
            }
            let ampm = hours >= 12 ? "PM" : "AM"
            if(hours > 12){
                hours = hours - 12
            }
            let timeString = hours + ":" + minutes + " "+ampm
            savedQuestions[i].timeString = timeString
            
        }
        savedQuestions.sort((a,b)=>{
            return new Date(b.created_at) - new Date(a.created_at)
        })
        savedQuestions.sort((a,b)=>{
            return b.upVoteCount.count - a.upVoteCount.count
        })
        setSavedQuestions([...savedQuestions])
        for(var i=0;i<savedAnnouncements.length;i++){
            savedAnnouncements[i].responseId = savedAnnouncements[i].id
            savedAnnouncements[i].id = i+1
            savedAnnouncements[i].eventAlias = savedAnnouncements[i].event_id
            savedAnnouncements[i].agendaId = savedAnnouncements[i].event_agenda_id
            savedAnnouncements[i].sessionId = savedAnnouncements[i].event_session_id
            savedAnnouncements[i].sender = savedAnnouncements[i].name
            savedAnnouncements[i].userId = savedAnnouncements[i].user_id
            savedAnnouncements[i].time = savedAnnouncements[i].added_time
            let date = utility.calculateZoneSpecificTime(savedAnnouncements[i].created_at.replace(" ","T"))
            let hours = date.getHours()
            let minutes = date.getMinutes()
            if(minutes < 10){
                minutes = "0"+minutes
            }
            let ampm = hours >= 12 ? "PM" : "AM"
            if(hours > 12){
                hours = hours - 12
            }
            let timeString = hours + ":" + minutes + " "+ampm
            savedAnnouncements[i].timeString = timeString
        }
        setSavedAnnouncements([...savedAnnouncements])
    }
    const OnAllSessionsLoaded = (sessions) => {
        props.sessionsLoaded(sessions)
    }
    const OnSessionSelected = (session) => {    
        setSelectedSession(session)
    }
    const OnClickLogout = () => {
        if(props.sessions != null){
            var session = props.sessions[0]
            let newSelectedSession = {    //only using data which is required
                name : session.title,
                agendaIndex : session.agendaIndex,
                sessionIndex : session.sessionIndex,
                agendaId : session.agendaId,
                sessionId : session.sessionId == null ? session.id : session.sessionId,
                subSessionId : session.subSessionId == null ? 0 : session.subSessionId
            }
            setSelectedSession(newSelectedSession)
        }
        setCurrentView(0)
        localStorage.removeItem("loggedInSession")
    }
    return(
        currentView == 1 ? 
        <MainView
            sessionLoggedIn = {sessionLoggedIn}
            loadedQuestions = {loadedQuestions}
            loadedAnnouncements = {loadedAnnouncements}
            selectedSession = {selectedSession}
            eventAlias = {props.event.alias}
            socket = {props.socket}
            onLogout = {OnClickLogout} 
        /> : 
        <LoginView
            sessionLoggedIn = {sessionLoggedIn}
            eventName = {props.event.name}
            eventAlias = {props.event.alias}
            timeZone = {props.event.timezone}
            selectedSession = {selectedSession}
            loadedSessions = {props.sessions}
            onSessionsLoaded = {OnAllSessionsLoaded}
            onSessionSelected = {OnSessionSelected}
            onLoginSuccess = {OnLoginSuccess} 
        />
    )
}
const mapStateToProps = state => ({
    sessions: state.panelistData.sessionsData
})
const mapDispatchToProps = dispatch => ({
    sessionsLoaded : data => dispatch(SessionsPopulated(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PanelistModeratorView);