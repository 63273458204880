import React from "react";
import axios from 'axios';

export default class Home extends React.Component{

    constructor(props){
        super(props);
        // this.getEvent = this.getEvent.bind(this);
        this.state = {
            users : this.props.users,
            posts : this.props.posts,
            speakers : this.props.speakers,

        };
    }

    getEvent(){
        //live server : https://jsonplaceholder.typicode.com/posts
        //local server : http://localhost:8000/api/v1/world-cup/getEventSpeakers
        const response = axios.get("https://eventmanagement.mwancloud.com/world-cup/getEventSpeakers",
            {
                params: {name: 'bruno'},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken':'ux2Ipv0NLJ3T2oPZU0he7Qn99av8IkIA2r618VUr5vYLJObLzHTwW3mDOCzvb4NI'

                }
            }
        ).then(response=>{
            this.setState({speakers:response.data.speakers});
            this.setState({posts:response.data.speakers});


        }).catch(error=>{
        });

    }//end of get event function

    render(){
        
        var text = "Something like that.";
        const speakers = this.state.speakers;
        const posts = this.state.posts;

        return(
            <div>
                <p>This is Home Component</p>
                <p>{ text }</p>
                <button onClick={this.getEvent} >Get Speakers</button>
                {/* {
                    this.state.posts.length ?
                    this.state.posts.map(function (speaker,index) {
                    return (
                    <div key={index}>

                        <p >ID : {speaker.id}</p>
                        <img src={speaker.image} alt="speaker pic" width="50px" />
                        <p >Name : {speaker.title} {speaker.surname} {speaker.name}</p>
                        <p>Job Title : {speaker.job_title} Email : {speaker.email}</p>
                        <p>Phone Number : {speaker.phone_number} Mobile Number : {speaker.mobile_number}</p>
                        <p>Linkedin : {speaker.linkedin}</p>
                        <p>Description : {speaker.description}</p>
                        <hr />
                    </div>
                    
                    );
                    }) : null
                } */}
                
                
            </div>
        );
    }
}