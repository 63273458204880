import React from 'react';

import api from './api';
import Header from '../Header/Index';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import Content from './content';
import Testimonials from '../Testimonials';
import Sponsers from '../Sponsers';
import { Constants } from '../../Constants';
import SpeakerV1 from '../SpeakerV1';
import SpeakerV2 from '../SpeakerV2';

import {withRouter} from 'react-router-dom';
import Loader from 'react-loader-spinner'
import {ErrorScreen} from '../ErrorScreen';
import NewsLetter from '../NewsLetter';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import {compose} from 'redux'
import { PageDataLoaded } from './actions';
import axios from '../axios/index'
import MetaTags from 'react-meta-tags'
import Speaker from '../Speaker';
import { AdditionalContent } from './additionalContent';
import VirtualZone from '../Header/virtualZone';
import { isMobile } from 'react-device-detect';
import { GAPageView } from '../../Utility/GA';
import $ from 'jquery'
import DOMPurify from "dompurify";
import {withTranslation} from 'react-i18next'
import {Trans} from 'react-i18next'
const myHTML = `ru63tflo23dbaukmk0du1n2ld5`;
const mySafeHTML = DOMPurify.sanitize(myHTML);
class Contact extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            pageId : this.props.match.params.id,
            event_alias : this.props.event.alias,
            page : null,
            loadingError : '',
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.page_id == this.props.match.params.id),
            selectedSubIndex: -1,
            menu : this.props.headerMenuItems.find(x=>x.href == (this.props.event.alias+"/page/"+this.props.match.params.title+"/"+this.props.match.params.id)),
            submittingContactUsForm : false,
            menuCollapse:false
        }
        this.user = utility.getLoggedInUser(this.props.event.alias)
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+localStorage.getItem("eventAlias"))|| 'en')
    }

    naviagtorLevel = ()=>{
        this.setState({menuCollapse:false})
    }
    
    OnNavigateToPage = (path) =>{
        if(isMobile){
            // this.headerRef.closeCollapse()
            this.setState({menuCollapse:true})
        }

        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }

    componentDidMount(){
        console.log(this.props)
        console.log(this.props.event.alias+"/page/"+this.props.match.params.title+"/"+this.props.match.params.id)
        console.log(this.props.headerMenuItems.findIndex(x=>x.page_id == this.props.match.params.id))
        if(this.state.pageId == 'godaddy.html' || this.state.pageId == 'pki-validation'){
            this.setState({page : {
                isVerificationPage : true
            }})
            return
        }
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        var pageData = this.props.pages.find(x=>x.pageID == this.state.pageId)
        if(pageData != null){
            this.setState({page : pageData.page,pageID : pageData.page.id})
        }else{
            this.getEventPage(this.props.event.alias,this.props.match.params.id,this.props.match.params.title);
        }
        this.preId = this.props.match.params.id;
        this.pageTitle = this.props.match.params.title
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        
    }
    componentDidUpdate(){
        var id = this.props.match.params.id;
        if(id == 'godaddy.html' || id == 'pki-validation'){
            return
        }
        if(this.preId != id){
            var pageData = this.props.pages.find(x=>x.pageID == id)
            if(pageData != null){
                this.setState({page : pageData.page,pageId : pageData.page.id})
            }else{
                this.setState({page : null})
                this.getEventPage(this.props.event.alias,this.props.match.params.id,this.props.match.params.title);
            }
            this.state.selectedIndex = this.props.headerMenuItems.findIndex(x=>x.page_id == this.props.match.params.id)
            this.preId = id;
            this.pageTitle = this.props.match.params.title
        }
    }
    // componentWillReceiveProps=(nextProps)=>{
    //     console.log(nextProps.pages)
    //     console.log(this.props.pages)
    //     var id = nextProps.match.params.id;
    //     if(id == 'godaddy.html' || id == 'pki-validation'){
    //         return
    //     }
    //     if(this.preId != id){
    //         var pageData = nextProps.pages.find(x=>x.pageID == id)
    //         console.log(pageData)
    //         if(pageData != null){
    //             this.setState({page : pageData.page,pageId : pageData.page.id})
    //         }else{
    //             this.setState({page : null})
    //             this.getEventPage(this.props.event.alias,nextProps.match.params.id);
    //         }
    //         this.state.selectedIndex = nextProps.headerMenuItems.findIndex(x=>x.page_id == nextProps.match.params.id)
    //         this.preId = id;
    //     }
    // }
    getMenuItem (pageID,pageTitle) {
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],"",this.props.event.alias+"/page/"+pageTitle+"/"+pageID)
                // if(result == null){
                //     result = utility.searchTree(this.props.headerMenuItems[i],'Pharma Trainings')
                // }
                if(result != null){
                    this.setState({menu : result})
                    if(result.page_id == this.props.match.params.id){
                        let selectedSubIndex = -1
                        if(this.props.headerMenuItems[i].children){
                            selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.page_id == this.props.match.params.id)
                        }
                        this.setState({selectedIndex : i,selectedSubIndex})
                        break
                    }
                }
            }
        }
    }
    getEventPage(eventAlias,PageId,pageTitle = ''){
        this.setState({selectedIndex : -1},()=>{
            this.getMenuItem(PageId,pageTitle)
        })
        api.getEventPage(eventAlias,PageId,(err,data)=>{
            if(err != null && this.props.page == null){
                this.setState({loadingError : 'Something went wrong'})
                return
            }
            var seoData = {
                seo_title : data.page.seo_title,
                keywords : data.page.keywords,
                meta_description : data.page.meta_description
            }
            // fetch(`/setCurrentPage?page=dynamic&seoData=${seoData}`)
            axios({
                method : 'GET',
                url : '/setCurrentPage',
                baseURL : '',
                params : {page : 'dynamic',seoData : JSON.stringify(seoData)}
            })
            setTimeout(() => {
                fetch(`/${this.props.event.alias}/dynamic`)
            }, 0);

            var keyWordString = ""
            for(var i=0;i<data.page.keywords.length;i++){
                keyWordString += data.page.keywords[i]
                if(i < data.page.keywords.length - 1){
                    keyWordString += ','
                }
            }
            data.page.keyWordString = keyWordString
            var pagesData = this.props.pages
            var pageData = {
                pageID : PageId,
                page : data.page
            }
            if(pagesData.findIndex(x=>x.pageID == pageData.pageID) < 0){
                pagesData.push(pageData)
            }
            this.props.pageLoaded(pagesData)
            
            this.setState({page : data.page,loadingError : ''})
        })

    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
        this.getEventPage(this.props.event.alias,this.preId)
    }
    languageClick = (value) =>{
        console.log("Trigger contact on lan select")
        this.props.languageClick(value);
    }
   


    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnClickDownloadPdf = (pageid,title) => {
        api.downloadPdfFile(this.props.event.alias,pageid,title,(err,data)=>{
            this.contentRef.OnPDFDownloaded()
        })
    }
    OnSubmitContactUsForm = (data) => {
        this.setState({submittingContactUsForm : true})
        api.submitContactUsForm(this.props.event.alias,data,(err,res)=>{
            if(err == null){
                $('#contact_us_modal').modal('hide');
                $('.modal-backdrop').remove();

                $('#message_Modal').modal('show');
            }
            this.setState({submittingContactUsForm : false})
        })
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render() {
        if(this.props.event){
            if(this.state.page && this.state.page.isVerificationPage){
                return (
                    <div dangerouslySetInnerHTML={{ __html: mySafeHTML }} />
                )
            }
            return (
                <div>
                    <div id="header-top">
                    <Header
                        ref = {(ref)=>this.headerRef = ref}
                        selectedIndex = {this.state.selectedIndex}
                        naviagtorLevel = {this.naviagtorLevel}
                        menuCollapse = {this.state.menuCollapse}
                        selectedSubIndex = {this.state.selectedSubIndex}
                        onMenuItemClick = {this.OnNavigateToPage}
                        elements = {this.props.headerMenuItems}
                        isMarketplace = {this.props.event.is_marketplace}
                        facebookLink = {this.props.event.facebook}
                        linkedInLink = {this.props.event.linkedIn}
                        twitterLink = {this.props.event.twitter}
                        instagramLink = {this.props.event.instagram}
                        googleLink = {this.props.event.google}
                        phone = {this.props.event.phone_number}
                         event = {this.props.event}
                        eventLogo = {this.props.event.logo}
                        eventName = {this.props.event.name}
                        eventAlias = {this.props.event.alias}
                        onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                        onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                        onClickAccountItem = {this.OnClickAccountItem}
                        availableLocales = {this.props.availableLocales}
                        languageClick  = {this.languageClick}
                    />
                    {this.user != null && this.user.isBooking && Constants.showVirtualZone?
                    <VirtualZone
                        
                        eventAlias = {this.props.event.alias}
                        event = {this.props.event}
                        onMenuItemClick = {(path)=>{
                            if(path != ''){
                                this.OnNavigateToPage(path)
                            }
                        }}  
                        unReadCount = {this.props.unReadCount}
                        onNotificationSeen = {this.props.onNotificationSeen} 
                        onClearNotifications = {this.props.onClearNotifications}
                        notificationsCount = {this.props.unReadNotificationsCount}
                        notifications = {this.props.notifications}
                        onClickAccountItem = {this.OnClickAccountItem}
                        onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                        menuItems = {this.props.virtualMenuItems}
                        user = {this.user} 
                    /> : null}
                    </div>
                    <div className = {`content-main`}>
                        {this.state.page != null ?
                        <div className = "additional_page">
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{this.state.menu != null ? this.state.menu.title : "About Us"}</title> 
                                <meta property="description" content={this.state.page.meta_description} />
                                <meta property="title" content={this.state.page.seo_title} />
                            </Helmet>
                            <Content
                                ref = {(ref)=> this.contentRef = ref}
                                event = {this.props.event} 
                                page={this.state.page}
                                onClickDownloadPdf = {()=>this.OnClickDownloadPdf(this.state.page.id,this.state.page.title)}
                                onClickBookNow = {()=>this.OnNavigateToPage(this.props.event.alias+"/booking")}
                                submittingContactUsForm = {this.state.submittingContactUsForm}
                                submitContactUsForm = {this.OnSubmitContactUsForm}
                                translation ={this.translation}
                            />
                            {
                            this.state.page.speakers!= null && this.state.page.speakers.length > 0?
                            <Speaker 
                                defaultSpeakers = {this.state.page.speakers}
                                speakerHeading = {this.state.page.section_heading}
                                alias = {this.props.event.alias}
                            /> : null}
                            {this.state.page ?
                                this.state.page.showTestimonials == 1 ?
                                    <Testimonials event={this.props.event} event_alias={this.props.event.alias} type={Constants.Testimonial} />
                                    :null
                                :null
                            }
                            {this.state.page.additional_detail != null ?
                            <AdditionalContent
                                content = {this.state.page.additional_detail} 
                            /> : null}
                            {this.state.page ?
                                this.state.page.sponsers!= null && this.state.page.sponsers.length > 0 ?
                                    <Sponsers
                                        title={<Trans>partners</Trans>}
                                        event={this.props.event}
                                        sponsors = {this.state.page.sponsers} 
                                        event_alias={this.props.event.alias}
                                        inAdditionalPage = {true}
                                        onClickSeeAllSponsors = {()=>this.OnNavigateToPage(this.props.event.alias+"/partners")} 
                                    />
                                    :null
                                :null
                            }
                            </div>
                            : 
                            <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                                {this.state.loadingError == "" ?
                                    <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {40}
                                        width = {40}
                                    />:
                                    <ErrorScreen 
                                        message = {this.state.loadingError}
                                        onPressRetry = {this.OnPressRetry}
                                    />
                                }
                            </div>
                        }
                        {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ?<NewsLetter 
                            title = "SUBSCRIBE OUR NEWS LETTER" 
                            event={this.props.event} 
                            event_alias={this.props.event.alias}
                            buttonText = "Subscribe"
                            type = 'newsLetter'
                        />:null}
                        <Footer1
                            event = {this.props.event}
                            elements = {this.props.footerMenuItems}
                            onMenuItemClick = {this.OnNavigateToPage}
                        />
                    </div>
                    <Footer2 
                    event = {this.props.event}
                        copyRightLogo = {this.props.event.copyright_logo}
                        copyRightText = {this.props.event.copyright_text}
                        copyRightLink = {this.props.event.copyright_link}
                        showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                    />
                    
                </div>

            );
        }else{
            return(<p>event not found</p>);
        }
    }
}

const mapStateToProps = state => ({
    pages: state.pageData.dynamicPagesData
})
const mapDispatchToProps = dispatch => ({
    pageLoaded : data => dispatch(PageDataLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Contact);