import ReactGA from 'react-ga'
export const GAPageView = (path) => {
    ReactGA.pageview(path)
}
export const GARecordEvent = (eventName,userId,obj) => {
    // ReactGA.event({
    //     category: obj.category,       
    //     action: obj.action,
    //     label : obj.label,
    //     value : obj.value 
    //})
    try{
    window.Indicative.buildEvent(eventName, userId, obj)
    }catch(e){
        console.error(e)
    }
}