import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import $ from 'jquery'
import 'bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isMobile } from 'react-device-detect';
import {Trans} from 'react-i18next'
import {compose} from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {RemoveScroll} from '../../1 - Sub menu components/Virtual Exhibition/actions/index'
export class ThirdStep extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selectedDelegateIndex : 0,
            allDelegates : [],
            selectedDelegate : {},
            termsCondtionEror : '',
            consentError : '',
            termsConditions : false,
            yesConsent : false,
            noConsent : false,
            email : false,
            phone : false
        }
    }
    componentDidMount () {
        var allDelegates = []
        this.order = this.props.order
        var packages = this.props.order.packages
        for(var i=0;i<packages.length;i++){
            if(packages[i].isActive == 1){
                for(var j=0;j<packages[i].delegates.length;j++){
                    allDelegates.push(packages[i].delegates[j])
                }
            }
        }
        var selectedDelegate = allDelegates[0]
        console.log(this.props)
        if(this.props.selectedtermandcondition == true){
            this.setState({termsConditions:this.props.selectedtermandcondition})
        }
        else{
            this.setState({termsConditions : this.order.termsConditions})
        }
        if(this.props.phone == true){
            this.setState({phone:this.props.phone})
        }
        else{
            this.setState({phone:this.order.phone})
        }
        if(this.props.email == true){
            this.setState({email:this.props.email})
        }
        else{
            this.setState({email:this.order.phone})
        }
        if(this.props.setYes == true){
            this.setState({yesConsent:this.props.setYes})
        }
        else{
            this.setState({yesConsent : this.order.yesConsent})
        }
        if(this.props.setNo == true){
            this.setState({noConsent:this.props.setNo})
        }
        else{
            this.setState({noConsent : this.order.noConsent})
        }
        this.setState({
            allDelegates : allDelegates,selectedDelegate : selectedDelegate})
    }
    OnClickDelegateIten = (index) => {
        var selectedDelegate = this.state.allDelegates[index]
        this.setState({selectedDelegateIndex : index,selectedDelegate : selectedDelegate})
    }
    OnChangeTermsCheck = () => {
        this.props.selecTermandCondition()
        var val = document.getElementById('termsCondtions').checked
        this.setState({termsConditions : val})
        if(val){
            this.setState({termsCondtionEror : ""})
        }
    }
    OnChangeConsentCheck = () => {
        var yesConsentCheck  = document.getElementById('yesConsent').checked
        var noConsentCheck  = document.getElementById('noConsent').checked
        this.setState({yesConsent : yesConsentCheck,noConsent : noConsentCheck})
        if(yesConsentCheck || noConsentCheck){
            this.setState({consentError : ""})
        }
    }
    OnChangeRecieptVia = () => {
        var emailCheck  = document.getElementById('email').checked
        var phoneCheck  = document.getElementById('phone').checked
        this.setState({email : emailCheck,phone : phoneCheck})
    }
    OnContinueClick = () => {
        var shoudlProceed = true
        var termsCheck  = document.getElementById('termsCondtions').checked
        var yesConsentCheck  = document.getElementById('yesConsent').checked
        var noConsentCheck  = document.getElementById('noConsent').checked
        let height = document.getElementById('stickyMenu').offsetHeight + 20
        if(!termsCheck){
            $('html, body').animate({
                scrollTop: $("#termsSection").offset().top - height
            }, 800);
            this.setState({termsCondtionEror : <Trans>agree</Trans>})
            shoudlProceed = false
        }
        else{
            shoudlProceed = true
            this.setState({termsCondtionEror : ""})
        }
        if(!yesConsentCheck && !noConsentCheck){
            if(shoudlProceed){
                $('html, body').animate({
                    scrollTop: $("#consentSection").offset().top
                }, 800);
            }
            this.setState({consentError : <Trans>required</Trans>})
            shoudlProceed = false
        }
        else{
            shoudlProceed = true
            this.setState({consentError : ""})
        }
        if(!termsCheck){
            return
        }
        if(!yesConsentCheck && !noConsentCheck){
            return
        }
        this.order.termsConditions = termsCheck ? 1 : 0
        this.order.isConsent = document.getElementById('yesConsent').checked ? 1 : 0
        this.order.yesConsent = document.getElementById('yesConsent').checked
        this.order.noConsent = document.getElementById('noConsent').checked
        this.order.email = document.getElementById('email').checked ? 1 : 0
        this.order.phone = document.getElementById('phone').checked ? 1 : 0

        this.props.onProceed(this.order)
    }
    OnClickBack = () => {
        this.props.onClickGoBack()
    }
    OnClickEdit = () => {
        this.props.onClickEdit(this.state.selectedDelegate.packageIndex)
    }
    renderDelegatesTab = () => {
        return(
            <ul class="nav nav-tabs aganda_tab new_tab" role="tablist">
                {this.state.allDelegates.map((item,index)=>{
                    return(
                        <li onClick = {()=>this.OnClickDelegateIten(index)} class="nav-item">
                            <a class={index == this.state.selectedDelegateIndex ? "nav-link active bookingTab" : "nav-link bookingTab"} data-toggle="tab" href="">
                                <i class="fas fa-user"></i>
                                <span class = "f-600 ml-2">{index+1}</span>
                            </a>
                        </li>
                    )
                })}
                
            </ul>
        )
    }
    render(){
        return(
            <div>
                <section class = "tab_show mt-5">
                    <div class = "container">
                        <div class = "col-12">
                            {!isMobile ?
                            <PerfectScrollbar style = {{height : 'auto',minHeight:0,maxHeight:200}}>
                                <this.renderDelegatesTab />
                            </PerfectScrollbar> : 
                            <div style = {{overflowX : 'scroll',minHeight:0,maxHeight:200}}>
                                <this.renderDelegatesTab />
                            </div> }
                            <div class="tab-content">
                            <div id="home" class="container tab-pane active">
                            
                                <section class = "delegate-details mt-3">
                                    <div class="container">
                                        <div class="row bg-light py-3 border">
                                            <div class="col-12 px-2 px-lg-5">
                                                <h6 class = "text-uppercase f-600 mt-3 mb-3"><Trans>delegate</Trans> {this.state.selectedDelegateIndex+1} Details</h6>
                                                <div class = "table-responsive">
                                                    <table class="table table-borderless">
                                                        <tbody>
                                                        <tr>
                                                            <td style = {tableColumn1} class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>fullName</Trans></span></td>
                                                            <td style = {tableColumn2}><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.title + " "+this.state.selectedDelegate.firstName+ " " + this.state.selectedDelegate.lastName}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>jobTitle</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.jobTitle != null ? this.state.selectedDelegate.jobTitle != "" ? this.state.selectedDelegate.jobTitle  : "-" : "-"}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>mobile</Trans></span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.mobileNumber != null ? this.state.selectedDelegate.mobileNumber != "" ? this.state.selectedDelegate.mobileNumber : "-" : "-"}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>phone</Trans></span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.phoneNumber != null ? this.state.selectedDelegate.phoneNumber != "" ? this.state.selectedDelegate.phoneNumber : "-" : "-"}</span></td>
                                                        </tr>

                                                        {/* <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main">Age Group:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">25 - 34</span></td>
                                                        </tr> */}

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>workEmail</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.emailWork ? this.state.selectedDelegate.emailWork != "" ? this.state.selectedDelegate.emailWork : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>nationality</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.nationality ? this.state.selectedDelegate.nationality != "" ? this.state.selectedDelegate.nationality : "-" : "-"}</span></td>
                                                        </tr>

                                                    </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 px-2 px-lg-5">
                                                <h6 class = "text-uppercase f-600 mt-3 mb-3"><Trans>companyDetails</Trans></h6>
                                                <div class = "table-responsive">
                                                    <table class="table table-borderless">
                                                        <tbody>
                                                        <tr>
                                                            <td style = {tableColumn1} class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>companyName</Trans>:</span></td>
                                                            <td style = {tableColumn2}><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.companyName ? this.state.selectedDelegate.companyName != "" ? this.state.selectedDelegate.companyName : "-" : "-"}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>state</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.state ? this.state.selectedDelegate.state != "" ? this.state.selectedDelegate.state : "-" : "-"}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>city</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.city ? this.state.selectedDelegate.city != "" ? this.state.selectedDelegate.city : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>country</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.country ? this.state.selectedDelegate.country != "" ? this.state.selectedDelegate.country : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>add1</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.address1 ? this.state.selectedDelegate.address1 != "" ? this.state.selectedDelegate.address1 : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>add2</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.address2 ? this.state.selectedDelegate.address2 != "" ? this.state.selectedDelegate.address2 : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>zipCode</Trans></span></td>
                                                            <td><span class = "ml-0 ml-md-5  text-main f-500">{this.state.selectedDelegate.zipCode ? this.state.selectedDelegate.zipCode != "" ? this.state.selectedDelegate.zipCode : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>companyWebsite</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5 text-main f-500">{this.state.selectedDelegate.companyWebsite ? this.state.selectedDelegate.companyWebsite != "" ? this.state.selectedDelegate.companyWebsite : '-' : "-"}</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>companyPhone</Trans></span></td>
                                                            <td><span class = "ml-0 ml-md-5  text-main f-500">{this.state.selectedDelegate.companyTelephone ? this.state.selectedDelegate.companyTelephone != "" ?this.state.selectedDelegate.companyTelephone : "-" : "-"}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class = "text-left text-md-right"><span class = "f-600 text-main"><Trans>companyVatNum</Trans>:</span></td>
                                                            <td><span class = "ml-0 ml-md-5  text-main f-500">{this.state.selectedDelegate.companyVatNo ? this.state.selectedDelegate.companyVatNo != "" ? this.state.selectedDelegate.companyVatNo : "-" : "-"}</span></td>
                                                        </tr>

                                                    </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 text-right">
                                                <a onClick = {()=>{
                                                    this.OnClickEdit()
                                                    this.props.RemoveScroll(1)
                                                    }} style = {{cursor : 'pointer'}}  type="sumbit" class="btn btn-default border f-600">
                                                    <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>editDetails</Trans>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                            </div>
                            
                            <div id="menu2" class="container tab-pane fade"><br/>
                            <h3><Trans>menu2</Trans></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
			
                        </div>
                    </div>
                    </div>
                </section>
                <section class = "text-detail">
                    <div class="container">
                    
                    <div id= "termsSection" class = "row mt-4">
                        <div class="col-sm-6">
                            <label class="form-check-label check-cont">
                                <input checked = {this.state.termsConditions} onChange = {this.OnChangeTermsCheck} id = "termsCondtions" class="form-check-input hide_input" type="checkbox"/> <Trans>read</Trans><a href = "/terms-conditions" target = "_blank" class = "text-red f-600" style = {{wordBreak : 'break-word'}}> <Trans>termsAndConditions</Trans> <i className = "text-red">*</i> </a>
                                <span class="checkmark"></span>
                            </label>
                            {this.state.termsCondtionEror != '' ?
                            <div className="alert alert-danger contactUsAlert">{this.state.termsCondtionEror}</div>:null}
                        </div>

                    </div>
                        
                    <div id = "consentSection" class = "row">
                        <div class="col-12 mt-3 cancelation_policy">
                                <p>
                                    <Trans>consent</Trans>
                                    <br/>
                                        <form class = "form-inline">
                                            <label class="form-check-label check-cont mr-4">
                                                <input checked = {this.state.email} onChange = {()=>{
                                                    this.OnChangeRecieptVia()
                                                    this.props.selectEmail()
                                                    }} id = "email" class="form-check-input" type="checkbox"/><Trans>email</Trans>
                                                <span class="checkmark"></span>
                                            </label>
                                            
                                            <label class="form-check-label check-cont">
                                                <input checked = {this.state.phone} onChange = {()=>{
                                                    this.OnChangeRecieptVia()
                                                    this.props.selectPhone()
                                                    }} id = "phone" class="form-check-input" type="checkbox"/><Trans>phone</Trans>
                                                <span class="checkmark"></span>
                                            </label>
                                        </form>
                                </p>

                                <p>
                                    <Trans>thirdParty</Trans><i className = "text-red">*</i>
                                    <br/>
                                        
                                        <form class = "form-inline">
                                            <label class="cm_cont mr-4"><Trans>yesConsent</Trans>
                                            <input checked = {this.state.yesConsent} onChange = {()=>{
                                                this.OnChangeConsentCheck()
                                                this.props.onConsetsYes()
                                                }} id = "yesConsent" type="radio" name="radio"/>
                                            <span class="checkmark">
                                                <div></div>
                                            </span>
                                            </label>
                                            
                                            <label class="cm_cont"><Trans>noConsent</Trans>
                                            <input checked = {this.state.noConsent} onChange = {()=>{
                                                this.OnChangeConsentCheck()
                                                this.props.onConsetsNo()
                                            }} id = "noConsent" type="radio" name="radio"/>
                                            <span class="checkmark">
                                                <div></div>
                                            </span>
                                            </label>
                                        </form>
                                        {this.state.consentError != '' ?
                                       <div className="col-sm-6 alert alert-danger contactUsAlert">{this.state.consentError}</div>  : null}
                                    <br/>
                                    
                                </p>

                                {/* <p>
                                    {ReactHtmlParser(this.props.dataProtection)}
                                </p>
                                <p>
                                    {ReactHtmlParser(this.props.cancellationPolicy)}
                                </p> */}
                                
                        </div>
                    </div>
                    
                    </div>
                    
                </section> 
                
                <section class = "order-form py-3">

                    <div class = "container">
                        
                        <div class = "row d-none-mob">
                        
                            <div class="col-md-6 mb-3 mb-sm-0">
                                <a onClick = {this.OnClickBack} style = {{cursor : 'pointer'}}  class="btn btn-default f-600">
                                <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans> 
                                </a>
                            </div>
                            
                            <div class="col-md-6 text-sm-right">
                                <a onClick = {this.OnContinueClick} style = {{cursor : 'pointer'}} class="btn btn-default f-600">
                                    <Trans>check_out</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                </a>
                            </div>
                            
                        </div>
                        
                        <div class = "row d-block d-md-none">
                        
                            <div class="col-12 mb-3 mb-sm-0 text-center">
                            
                                <a onClick = {this.OnClickBack} style = {{cursor : 'pointer',marginRight : 5}}  class="btn btn-default f-600">
                                <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans> 
                                </a>
                                
                                <a onClick = {this.OnContinueClick} style = {{cursor : 'pointer',marginLeft : 5}}  class="btn btn-default f-600">
                                <Trans>check_out</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                </a>
                                
                            </div>
                            
                        </div>
                        
                    </div>

                </section>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    RemoveScroll : data => dispatch(RemoveScroll(data))
  })
  export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
  )(ThirdStep);

const tableColumn1 = {
    width : 173,
};
const tableColumn2 = {
    width : 577,
};