import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    authenticatePin(eventAlias,data,callback){
        axios.post('/pin/verify',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data)
            }
            else{
                var error = {
                    code : response.data.error.code,
                    message : response.data.error.message
                }
                callback(error,null)
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    saveAnnouncement(eventAlias,data,callback){
        axios.post('/announcements/save',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data)
            }
            else{
                var error = {
                    code : response.data.error.code,
                    message : response.data.error.message
                }
                callback(error,null)
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    deleteAnnouncement(eventAlias,data,callback){
        axios.post('/announcements/delete',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            },
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.announcement_id)
            }
            else{
                var error = {
                    code : response.data.error.code,
                    message : response.data.error.message
                }
                callback(error,null)
            }
        }).catch(error => {
            callback(error,null)
        })
    }
}