import React from 'react';
import api from './api';
import Loader from 'react-loader-spinner'
import {Trans, withTranslation} from 'react-i18next';
import utility from '../../Utility/utility';
import { GARecordEvent } from '../../Utility/GA';
export class NewsLetter extends React.Component{
    _isMounted = false
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias: this.props.event_alias,
            responseMsg:null,
            email : null,
            emailError:null,
            sendingRequest : false,
            apiError : false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.eventAlias= localStorage.getItem("alias")
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+this.eventAlias) || 'en')
        this.user = utility.getLoggedInUser(this.props.event_alias)
    }

    componentDidMount(){
        this._isMounted = true;
      //  console.log("newsletter", localStorage.getItem("language"+this.eventAlias))
              
    }
    componentWillUnmount(){
        this._isMounted = false
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    validate = () =>{

        let emailError = null;

        if(this.state.email){

            var emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            emailError = emailValid ? null : <Trans>validEmail</Trans>;
        }else{
            emailError = <Trans>pleaseEnterMail</Trans>;
        }

        if(emailError){
            this.setState({emailError});
            return false;
        }else{
            this.setState({emailError});
            return true;
        }


    }//end of validate form
    subscribeEvent = () =>{
        //console.log("subcribe clicked")
        GARecordEvent("subscribe Clicked", this.user? this.user.id : 'anonymous user',{
            category : 'Subscribe',
            action : 'Subscribe button clicked',
            userName: this.user? (this.user.firstName+''+this.user.lastName) : 'anonymous user',
            userEmail: this.user? this.user.email : 'anonymous user'
        })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.subscribeEvent()
        const isValid = this.validate();
        this.setState({responseMsg : null,apiError : false})
        if(isValid){
            if(this.state.email){
                this.setState({sendingRequest : true})
                api.sendEventSubscription(this.state.event.alias,this.state.email,this.props.type,(err,data)=>{
                    if(this._isMounted){
                        this.setState({sendingRequest : false})
                        if(err == null){
                            this.setState({responseMsg:data.message});
                            setTimeout(() => {
                                this.setState({responseMsg : null})
                            }, 2000);
                        }else{
                            this.setState({responseMsg:<Trans>loginError</Trans>,apiError : true});
                            setTimeout(() => {
                                this.setState({responseMsg : null,apiError : false})
                            }, 2000);
                        }
                    }
                    
                })

            }else{
                this.setState({responseMsg:null});
            }//end of if
        }//end is valid if

    }

    render(){
        return(
            <section className = "news_letter py-2 py-md-2 text-center">
                
                <div className="container">
                    
                    <h5 className = "text-center font-weight-bold text-uppercase text-main">
                        <Trans>subscribeForAgenda</Trans>
                    </h5>
                    <div className="row py-2">
                        <div className="col-sm-12">
                            <form className="form-inline justify-content-center mt-1 mt-md-2" onSubmit={this.handleSubmit}>
                                <div className="form-group col-md-8 pr-md-0">
                                    {this.state.sendingRequest ?
                                    <div style = {{position : 'absolute',top : 60,left : '70%'}}>
                                        <Loader
                                            type = 'Oval'
                                            color = "#00b6ff"
                                            height = {25}
                                            width = {25}
                                        />
                                    </div> : null}
                                    
                                    <input type = "email" className = "form-control input-1 w-100" placeholder={this.translation.enterYourEmail}
                                    id="email" name="email" value={this.state.email} onChange={this.handleChange}/>
                                    

                                </div>
                                <div className="form-group col-md-4 pl-md-0">
                                    {/* this.props.buttonText */}
                                    <input type = "submit" className = "form-control bg_main input-2 w-100" value={this.translation.subscribe} />
                                </div>

                                {
                                    this.state.emailError ?
                                        <div style = {{marginTop : '2%'}} className="col-sm-10 alert alert-danger">{this.state.emailError}</div>
                                        :null
                                }

                                    {
                                        this.state.responseMsg ?
                                            <div style = {{marginTop : '2%'}} className={this.state.apiError ? "col-sm-10 alert alert-danger" : "col-sm-10 alert alert-success"}>
                                                {this.state.responseMsg}
                                            </div>
                                            :null
                                    }

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation() (NewsLetter);