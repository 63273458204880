import React from 'react'
import { withRouter } from 'react-router-dom'
import $ from 'jquery'
import { connect } from 'react-redux'
import {compose} from 'redux'
import utility,{ jobBoardNavigator} from '../../../../Utility/utility';
import Header from '../../../Header/Index';
import VirtualZone from '../../../Header/virtualZone';
import { Helmet } from 'react-helmet';
import api from '../api'
import {Content} from './content'
import {Modal,Button} from 'react-bootstrap'
import Loader from 'react-loader-spinner'
import ImageMap from "image-map";
import imageMapResize from 'image-map-resizer'
import { Constants } from '../../../../Constants'
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { ExhibitorDataLoaded, RemoveScroll } from '../actions'
import io from 'socket.io-client'
import { GARecordEvent } from '../../../../Utility/GA'
import participantApi from '../../Participants/api'
import ModalElement from '../../../modals/JobsModal'
import emailApi from './api'
export class VirtualExhibitionInner extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            selectedBooth : {},
            selectedBranding : {},
            selectedVideoIndex : 0,
            downloadingPdf : false,
            loading : false,
            loadingTemplateImage : false,
            showTootlTip : false,
            tooltipText : '',
            brandings : [],
            representatives : [],
            showBoothChatDrawer : false,
            selectedVideoLink : '',
            selectedPdfId : '',
            loadingResprenstatives : false,
            public_chat:false,
            user_chat_data:[],
            isPublicChatEnabled:1,
            emailResponse:null,
            missingRepresentative:[],
            message:'',
            chatData:[],
            missingRepresentativeEmailCount:[],
            bootRepresentative :[],
            emailCount:0,
            missingRepresentativeIds:[],
            representaiveForReload :[],
            missingUserList:[],
            exibitionBooth:false,
            virtualZoneItemIndex : null,
            participentdata:this.props.participants,
            load:1,
            boothName:''
        }
        this.hallIndex = 0
        this.numberOfBoothRepresentatives = 0
        this.boothId = ''
        this.socket = this.props.socket
        this.user = {}
    }
    componentWillReceiveProps=(nextProps)=>{
        this.setState({participentdata:[...nextProps.participants]})

    }
    async componentDidMount () {
            if(this.redirectedFromLobby){
                 let index =  this.props.virtualMenuItems.findIndex(x=>x.path == '/lobby')
                 this.setState({virtualZoneItemIndex:index})
            }
            else{
                let index =  this.props.virtualMenuItems.findIndex(x=>x.path == '/virtual_exhibition')
                this.setState({virtualZoneItemIndex:index})
            }

        var user2 = utility.getLoggedInUser(this.props.event.alias)
        document.addEventListener('participantsLoaded',this.handleOnParticipantsLoadedFromApi)
        this.boothId = this.props.match.params.id
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        if(this.socket == null){
            let socketURL =  Constants.socketUrl
            socketURL = socketURL+"?userId="+user2.id+"&userName="+(user2.firstName+" "+user2.lastName)+"&eventAlias="+this.props.eventAlias
            this.socket = io.connect( Constants.socketUrl,{forceNew : true,upgrade : false,transports: ['websocket']})
        }
        this.socket.on("publicChat",data=>{
            if(data.currentBooth == this.props.match.params.id )
            {
                this.setState({user_chat_data:[{
                    // name:data.name,
                    profileImageUrl:data.profileImageUrl,
                    name:data.name,
                    message:data.message,created_at:data.date,
                    sender_id:data.userid
                    // socketId:data.socketId,id:data.userid
                },...this.state.user_chat_data]})
            }
            

        })
        this._isMounted = true
        this.user = utility.getLoggedInUser(this.props.event.alias)
        if(this.user == null){
            this.OnNavigateToPage('/')
            return
        }

        if(this.boothId.length > 0){
            this.setState({loading : true})
            api.getSingleExhibitor(this.props.event.alias,this.boothId,this.user.token,(err,exhibitor)=>{
                if(err != null){
                    this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})
                    this.setState({boothName:exhibitor.name})
                }else{
                    if(exhibitor.length == 0){
                        this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})
                        this.setState({boothName:''})
                    }else{
                        this.InitializeBooth(exhibitor,true)
                    }
                }
                this.setState({loading : false})
            })
        }
        let data = this.props.location.state
        if(data == null){
            // this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})

            // Incase coming from direct link previous implementation does not allow to directly open this component unless there is
            // data from calling component
            this.setState({loading : true})
            if(this.boothId.length > 0){
                api.getSingleExhibitor(this.props.event.alias,this.boothId,this.user.token,(err,exhibitor)=>{
                    if(err != null){
                        this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})
                        this.setState({boothName:exhibitor.name})
                    }else{
                        if(exhibitor.length == 0){
                            this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})
                            this.setState({boothName:''})
                        }else{
                            this.InitializeBooth(exhibitor)
                        }
                    }
                    this.setState({loading : false})
                })
            }else{
                this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})
            }
            return
        }
        this.hallIndex = data.hallIndex
        this.redirectedFromLobby = data.fromLobby
        this.isHelpDesk = data.isHelpDesk
        if(data.booth.brandings.length == 0){
            var exhibitorData = this.props.customExhibitors.find(x=>x.externalLink == data.booth.externalLink)
            if(exhibitorData != null){
                this.InitializeBooth(exhibitorData.booth)
            }else{
                this.setState({loading : true})
                api.getSingleExhibitor(this.props.event.alias,data.booth.externalLink,this.user.token,(err,exhibitor)=>{
                    if(err != null){
                        this.OnNavigateToPage(this.props.event.alias+"/virtual_exhibition",{hallIndex : this.hallIndex})
                    }else{
                        this.InitializeBooth(exhibitor)
                        var exhibitors = this.props.customExhibitors
                        var exhibitorData = {
                            externalLink : data.booth.externalLink,
                            booth : exhibitor
                        }
                        exhibitors.push(exhibitorData)
                        this.props.updateExhibitorsData(exhibitors)
                        this.setState({boothName:exhibitor.name})
                    }
                    this.setState({loading : false})
                })
            }
        }else{
            this.InitializeBooth(data.booth)
        }
        window.history.replaceState(null, '')
    }
    sendEmailToRep = async(params,id,callback)=>{
        await emailApi.senEmailToBoothRepresentative(this.props.event.alias,params,this.user.token,(err,data)=>{
            if(data.status == "0"){

               this.setState({emailResponse:false,message:data.error_message})
            }
            else{
                let updatedRepresentative = this.state.representatives.map(item=>{
                    if(item.id ==id){

                        return {...item,count:data.count,isOnline:true}

                    }else{
                        return item
                    }
                })
                this.setState({emailResponse:true,representatives:[...updatedRepresentative]})
            }
            callback(data)

        })

    }
    chatWithUser =  async(data)=>{
        this.props.socket.emit("publicChat",data)
        const params = {
            booth_id:data.currentBooth,
            message:data.message
        }
        await  emailApi.sendPublicMessage(this.props.event.alias,params,this.user.token,(err,data)=>{
            if(data.status == 1){
                this.setState({public_chat:[...data.data]})
            }
            else{
            }
        })
    }
    componentWillUnmount(){
        this._isMounted = false
        document.removeEventListener('participantsLoaded',this.handleOnParticipantsLoadedFromApi)
        // $('.tooltips').remove();
    }
    InitializeBooth = async (booth,status= false) => {
        this.setState({boothName:booth.title})
        if(status == true){
            this.setState({ bootRepresentative:[...booth.representatives]})
        var userList = []
        var  missingParticipent =  booth.publicChat.map(item=>parseInt(item.sender_id))
        if((missingParticipent || []).length>0 ){
            await participantApi.getEventParticipants(this.props.event.alias,(list)=>{
                 userList = list
            },'','',[...new Set(missingParticipent)])
    
        }  
        let userUpdate = booth.publicChat.map(item=>{
            var dateTimeConversion = utility.calculateZoneSpecificTime(item.created_at.replace(' ','T'))
            let month =  dateTimeConversion.toLocaleString('default', { month: 'short' })
            let date =   dateTimeConversion.getDate()
            let year =   dateTimeConversion.getFullYear()
            let Hour =   dateTimeConversion.getHours()<10 ? '0'+dateTimeConversion.getHours():dateTimeConversion.getHours()
            let min = dateTimeConversion.getMinutes()<10 ? '0'+dateTimeConversion.getMinutes():dateTimeConversion.getMinutes()
            let finalData = `${date}-${month}-${year} ${Hour}:${min}`
            let filterUser = userList.filter(user=>item.sender_id == user.id)[0]
            if(filterUser != undefined){
              
                return {...item,name:filterUser.firstName + ' '+filterUser.lastName,profileImageUrl:filterUser.profileImageUrl,created_at:finalData}

            }
            else{
                return {...item,name:'Test User',created_at:finalData}

            }
        })
        this.setState({user_chat_data:[...userUpdate],isPublicChatEnabled:booth.isPublicChatEnabled})

        }else{
        this.setState({ bootRepresentative:[...booth.representatives]})
        var userList = []
        var  missingParticipent =  booth.publicChat.map(item=>parseInt(item.sender_id))
        if((missingParticipent || []).length>0 ){
            await participantApi.getEventParticipants(this.props.event.alias,(list)=>{
                 userList = list
            },'','',[...new Set(missingParticipent)])
    
        }  
        let userUpdate = booth.publicChat.map(item=>{
            var dateTimeConversion = utility.calculateZoneSpecificTime(item.created_at.replace(' ','T'))
            let month =  dateTimeConversion.toLocaleString('default', { month: 'short' })
            let date =   dateTimeConversion.getDate()
            let year =   dateTimeConversion.getFullYear()
            let Hour =   dateTimeConversion.getHours()<10 ? '0'+dateTimeConversion.getHours():dateTimeConversion.getHours()
            let min = dateTimeConversion.getMinutes()<10 ? '0'+dateTimeConversion.getMinutes():dateTimeConversion.getMinutes()
            let finalData = `${date}-${month}-${year} ${Hour}:${min}`
            let filterUser = userList.filter(user=>item.sender_id == user.id)[0]
            if(filterUser != undefined){
              
                return {...item,name:filterUser.firstName + ' '+filterUser.lastName,profileImageUrl:filterUser.profileImageUrl,created_at:finalData}

            }
            else{
                return {...item,name:'Test User',created_at:finalData}

            }
        })
        this.setState({user_chat_data:[...userUpdate],isPublicChatEnabled:booth.isPublicChatEnabled})
        let helpDesk = this.isHelpDesk ? '(help desk)' : ''
        GARecordEvent("boothClicked", this.user.id,{
            category : this.redirectedFromLobby ? 'Lobby' : 'Virtual Exhibition',
            booth : booth.title == null ? booth.bothId : booth.title ,
            email : 'By user: '+this.user.email
        })
        this.numberOfBoothRepresentatives = booth.representatives.length
        let representatives = this.state.representatives
        this.setState({missingRepresentativeEmailCount:[...booth.representatives]})
        let representativeIds = []
        booth.representatives.map(item=>representativeIds.push(item.id))
        for(var i=0;i<booth.representatives.length;i++){
            let participant = this.props.participants.find(x=>x.id == representativeIds[i])
            if(participant != null){
                let count = booth.representatives.filter(x=>x.id == participant.id)[0].count
                participant['count'] = count
                representatives.push(participant)
            }else{
                let getMissingData = booth.representatives.filter(x=>representativeIds.includes(x.id))
                this.setState({missingRepresentative:[...getMissingData,...this.state.missingRepresentative],missingRepresentativeIds:[...this.state.missingRepresentativeIds,representativeIds[i]]})
                //this.missingRepresentativeIds.push(representativeIds[i])
            }
        }

        let videoBranding = booth.brandings.find(x=>x.type == "video_link")
        if(videoBranding != null){
            for(var k=0;k<videoBranding.link.length;k++){
                let url = videoBranding.link[k].link
                if(url.indexOf('youtu') > -1){
                    let id = utility.getYoutubeVideoId(url)
                    videoBranding.link[k].link = "https://www.youtube.com/embed/"+id
                }
            }
        }
        this.setState({selectedBooth : booth,brandings : booth.brandings,loadingTemplateImage : true,representatives},()=>{
            setTimeout(() => {
                imageMapResize()
            }, 0);
            let videoElem = document.getElementById("branding_video")
            if(videoElem != null){
                videoElem.addEventListener('click',(e)=>{
                    this.OnClickBrandingItem("video_link")
                })
                this.AddMouseListenersToElem(videoElem)
            }
            let broucherElem = document.getElementById("branding_broucher")
            if(broucherElem != null){
                broucherElem.addEventListener('click',(e)=>{
                    this.OnClickBrandingItem("brochure")
                })
                this.AddMouseListenersToElem(broucherElem)
            }
            let brandingTypeIndex = this.state.selectedBooth.brandings.findIndex(x=>x.type == "branding")
            if(brandingTypeIndex > -1){
                for(var i=0;i<this.state.selectedBooth.brandings[brandingTypeIndex].link.length;i++){
                    let elem = document.getElementById("branding"+(i+1))
                    if(elem != null){
                        elem.addEventListener('click',(e)=>{
                            // let index = parseInt(e.target.id.replace('branding',''))
                            // index -= 1
                            let index = this.state.selectedBooth.brandings[brandingTypeIndex].link.findIndex(x=>x.index_type == e.target.id)
                            this.OnClickBrandingItem("branding",brandingTypeIndex,index)
                        })
                        this.AddMouseListenersToElem(elem)
                    }
                }
            }
            if(this.state.missingRepresentativeIds.length > 0){
                this.setState({loadingResprenstatives : true})
                let missingIds = booth.representatives.map(item=>item.id)
                let listofMIssingId = this.state.load == 1 ? missingIds : this.state.missingRepresentativeIds
                participantApi.getEventParticipants(this.props.event.alias,(list)=>{
                    this.setState({load:this.state.load+1})
                   let updateList =  list.map(item=>{
                        let count =  this.state.missingRepresentativeEmailCount.filter(rep=>rep.id== item.id)[0].count 
                        item['count'] = count

                        return item
                    })
                    this.setState({representatives : [...this.state.representatives,...updateList],loadingResprenstatives : false}) 
                },'','',listofMIssingId)
            }
        })}
    }
    handleOnParticipantsLoadedFromApi = async (e) => {
        var updatedUserList = []
        if(this.state.missingRepresentativeIds.length > 0){
            this.setState({loadingResprenstatives : true})
            participantApi.getEventParticipants(this.props.event.alias,(list)=>{
                for(var i=0;i<this.props.onlineParicipants.length;i++){
                    let index = list.findIndex(x=>x.id == this.props.onlineParicipants[i].userId)
                    if(index > -1){
                        list[index].isOnline = true
                        list[index].socketId = this.props.onlineParicipants[i].socketId
                    }
                }
                updatedUserList = list.map(item=>{
                    const filterRep = this.state.bootRepresentative.filter(user=>user.id == item.id)[0]
                    item['count'] = filterRep.count
                    return item 
                })
        
                this.setState({representatives : [...updatedUserList],loadingResprenstatives : false}) 
            },'','',this.state.missingRepresentativeIds)
        }
    }
    AddMouseListenersToElem = (elem) => {
        // elem.onmouseenter = (e) => {
        //     let tooltipText = elem.getAttribute('title')
        //     elem.removeAttribute('title')
        //     this.setState({showTootlTip : true,tooltipText})
        // }
        // elem.onmouseleave = () => {
        //     elem.setAttribute('title',this.state.tooltipText)
        //     this.setState({showTootlTip : false})
        // }
    }
    OnNavigateToPage = (path,data = null) =>{
        window.scrollTo(0,0)
        this.props.removeBlockingScroll()
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data == null){
            this.props.history.push(path)
        }else{
            this.props.history.push({
                pathname : path,
                state : data
            })
        }
    }
    
    OnClickBrandingItem = (type,brandingTypeIndex = -1,brandingTypeSubIndex = -1) => {
        var branding = null
        if(type != "branding"){
            let index = this.state.selectedBooth.brandings.findIndex(x=>x.type == type)
            branding = this.state.selectedBooth.brandings[index]
            if(branding.link.length > 0){
                this.setState({selectedBranding : branding},()=>{
                    if(type == 'brochure'){
                        GARecordEvent("broucherDownloadClicked", this.user.id,{
                            category : 'Virtual Exhibition',
                            action : 'Broucher download clicked',
                            email : 'By user: '+this.user.email,
                            title: branding ? branding.title : "not available"
                        })
                        $('#Brochure-modal').modal('show')
                    }
                    if(type == 'video_link'){
                        GARecordEvent("videoClicked", this.user.id,{
                            category : 'Virtual Exhibition',
                            action : 'Video clicked',
                            email : 'By user: '+this.user.email,
                            title: branding ? branding.title : "not available"
                        })
                        if(branding.link.length > 0){
                            this.setState({selectedVideoLink : branding.link[this.state.selectedVideoIndex].link},()=>{
                                $('#video-modal-exhb').modal('show')
                            })
                        }
                    }
                })
            }
        }
        else if(type == 'branding'){
            if(brandingTypeIndex > -1 && brandingTypeSubIndex > -1){
                branding = this.state.selectedBooth.brandings[brandingTypeIndex].link[brandingTypeSubIndex]
                //console.log("innerpage branding",branding)
                this.setState({selectedBranding : {}})
                GARecordEvent("Booth brandingClicked", this.user.id,{
                    category : 'Virtual Exhibition',
                    action : 'Branding clicked '+branding.link,
                    email : 'By user: '+this.user.email,
                    title : branding? branding.title : "not available"
                })
                if(branding.link == 'jobFair'){
                    $('#job-modal').modal('show');
                }else{
                    // console.log(new URL(branding.link).pathname)
                    if((branding.link != null) && (!`${branding.link}`.includes(null)) ){
                        jobBoardNavigator(process.env.REACT_APP_JOBFAIR_REDIRECT_URL,branding.link,this.props.event_alias,'_self',this.user,new URL(branding.link).pathname)
                    }else{
                    
                    }
                    
                    // window.open(branding.link,"_blank")
                }
            }
        }
    }
    OnClickPDF = (id,title) => {
        GARecordEvent("broucherDownloadClicked", this.user.id,{
            category : 'Virtual Exhibition',
            action : 'Broucher downloaded '+id,
            email : 'By user: '+this.user.email
        })
        this.setState({downloadingPdf : true,selectedPdfId : id})
        api.downloadBroucher(this.props.event.alias,id,title,(err,data)=>{
            this.setState({downloadingPdf : false})
        })
    }
    languageClick = (value) =>{
        //console.log("Trigger Virtual Exibition inner on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    showChatDialog = (value)=>{
        this.setState({public_chat:value})

    }
    OnClickRepresentativeChat = (userId,socketId) => {
        this.setState({showBoothChatDrawer : false},()=>{
            setTimeout(() => {
                var data = {
                    id : userId,
                    socketId : socketId
                }
                this.OnNavigateToPage(this.props.event.alias+'/userChat',data)
            }, 200);
        })
    }
    handleClosePopup = () => {
        this.setState({showErrorPopup : false})
        localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
        this.OnNavigateToPage(this.props.event.alias+"/login")
    }
    render () {
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Exhibition</title>
            </Helmet>
            <div id="header-top">
            <Header
                onMenuItemClick = {this.OnNavigateToPage}  
                elements = {this.props.headerMenuItems}
                facebookLink = {this.props.event.facebook}
                linkedInLink = {this.props.event.linkedIn}
                twitterLink = {this.props.event.twitter}
                instagramLink = {this.props.event.instagram}
                isMarketplace = {this.props.event.is_marketplace}
                googleLink = {this.props.event.google}
                phone = {this.props.event.phone_number}
                eventLogo = {this.props.event.logo}
                 event = {this.props.event}
                eventName = {this.props.event.name}
                eventAlias = {this.props.event.alias}
                onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                availableLocales = {this.props.availableLocales}
                languageClick  = {this.languageClick}
                onClickAccountItem = {this.OnClickAccountItem}
            />
            {user != null && user.isBooking && Constants.showVirtualZone ?
            <VirtualZone
                selectedItemIndex = {this.state.virtualZoneItemIndex}
                eventAlias = {this.props.event.alias}
                event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                unReadCount = {this.props.unReadCount}
                onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                menuItems = {this.props.virtualMenuItems}
                user = {user} 
            /> : null}
            </div>
            <Content
                loading = {this.state.loading}
                boothName = {this.state.boothName}
                user = {this.user}
                chatData={this.state.chatData}
                currentBoothId= {this.props.match.params.id}
                chatWithUser = {this.chatWithUser}
                socket = {this.props.socket}
                mySocketId = {this.props.mySocketId}
                emailResponse = {this.state.emailResponse}
                user_chat_data = {this.state.user_chat_data}
                sendEmailToRep = {this.sendEmailToRep}
                loadingTemplateImage = {this.state.loadingTemplateImage}
                downloadingPdf = {this.state.downloadingPdf}
                selectedPdfId = {this.state.selectedPdfId}
                selectedBranding = {this.state.selectedBranding}
                alias = {this.props.event.alias}
                brandings = {this.state.brandings}
                isPublicChatEnabled={this.state.isPublicChatEnabled}
                selectedBooth = {this.state.selectedBooth}
                message={this.state.message}
                onClickCloseBooth = {()=>{
                    let closeBtn = document.getElementById('close-button')
                    closeBtn.classList.remove('lft-animt')
                    setTimeout(() => {
                        let path = ""
                        if(this.redirectedFromLobby){
                            path = "/lobby"
                        }else{
                            path = "/virtual_exhibition"
                        }
                        this.OnNavigateToPage(this.props.event.alias+path,{hallIndex : this.hallIndex})
                    }, 200);
                }}
                onClickBrandingItem = {this.OnClickBrandingItem}
                selectedVideoIndex = {this.state.selectedVideoIndex}
                selectedVideoLink = {this.state.selectedVideoLink}
                onClickChangeVideo = {(index)=> this.setState({selectedVideoIndex : index,selectedVideoLink : this.state.selectedBranding.link[index].link})}
                onClickCloseVideo = {()=> this.setState({selectedVideoLink : ''})}
                onClickPdf = {this.OnClickPDF}
                onClickChat = {this.OnClickRepresentativeChat}
                templateImageLoaded = {()=>{
                    setTimeout(() => {
                        let closeBtn = document.getElementById('close-button')
                        closeBtn.classList.add('lft-animt')
                        let boothChatBtn = document.getElementById('booth-chat-button')
                        let boothChatBtn2 = document.getElementById('booth-chat-button2')

                        if(boothChatBtn != null){
                            boothChatBtn.classList.add('rgt-animt')
                        } 
                        if(boothChatBtn2 != null){
                            boothChatBtn2.classList.add('rgt-animt')
                        } 
                    }, 0);
                    this.setState({loadingTemplateImage : false})
                }}
                loadingResprenstatives = {this.state.loadingResprenstatives}
                boothRepresentativeCount = {this.numberOfBoothRepresentatives}
                representatives = {this.state.representatives}
                showBoothChatDrawer = {this.state.showBoothChatDrawer}
                onClickBoothChat = {()=>{
                    this.setState({showBoothChatDrawer : true})
                }}
                onClickCloseBoothChat = {()=>{
                    this.setState({showBoothChatDrawer : false})
                }}
                onClickChat = {this.OnClickRepresentativeChat}
            />
            <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showErrorPopup} 
                onHide={this.handleClosePopup}
            >
                <Modal.Header>
                <Modal.Title>{this.state.popUpErrorHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.popUpErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"  className="telehealth-btn" onClick={this.handleClosePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            {this.state.selectedBooth && this.state.selectedBooth.job_portal_company ? 
            <ModalElement
                companyId = {this.state.selectedBooth.job_portal_company}
            /> : null}
            {/* <MouseTooltip
                visible={this.state.showTootlTip}
                offsetX={15}
                offsetY={10}
                className = "tooltips"
            >
                <span>{this.state.tooltipText}</span>
            </MouseTooltip> */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    customExhibitors : state.virtualExhibition.exhibitorsData,
    participants : state.participants.participantsList,
    onlineParicipants : state.participants.onlineParticipantIds
})
const mapDispatchToProps = dispatch => ({
    updateExhibitorsData : data => dispatch(ExhibitorDataLoaded(data)),
    removeBlockingScroll: ()=> dispatch(RemoveScroll())
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(VirtualExhibitionInner);