import React from 'react'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import Linkify from 'react-linkify';
import { Constants } from '../../../Constants';
import {compose} from 'redux';

import {Trans,withTranslation} from 'react-i18next'
import utility from '../../../Utility/utility';
export class TextChat extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name:'',
            profileImage: '',
            chatInfo:false,
            jobTilte:'',
            company:'',
            bio:'',
            visible:'',
            duration:'',
            userType:'',
            defaultChat:true,
            twitterLink:null,
            facebookLink:null,
            instagramLink:null,
            linkedInLink:null
            
        }
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+this.props.event.alias) || 'en')
    }
    componentDidMount(){
        console.log(this.props)
        $(document).ready(function () {
            // $('.close-icon').click(function(){
            //     console.log('cliced')
			// 	$('.chats').fadeOut(300);
            // })
            // $('.chat-list li').click(function(){
			// 	$('.chat-list li').removeClass('active');
			// 	$(this).addClass('active');
			// })
			// $('#chat-list li').click(function(){
			// 	$('.chats').fadeIn()
			// })
            // $('#chat-list li').click(function(){
			// 	$('.chats').fadeIn(300);
			// })
			$('.chat-inner').scrollTop(1000000);
			
			var height = $(window).height();
			//$('.participants-chat-main').css('height', (height - 180) + 'px');
            // $('.main-chat-area').css('height', (height - 180) + 'px');
            // $('.main-chat-area').addClass('overflow-hidden');
        })
    }
    componentWillReceiveProps= (nextprops)=>{
        this.setState({
            name:nextprops.selectedParticipantName.replace("null",""),
            profileImage: nextprops.selectedParticipantProfileImage,
            // chatInfo:true,
            jobTilte:nextprops.selectedParticipantJob,
            company:nextprops.selectedParticipentCompany,
            bio:nextprops.selectedParticipantbio,
            visible:'',
            duration:'',
            userType:nextprops.userType,
            twitterLink:nextprops.twitterProfile,
            facebookLink:nextprops.facebookProfile,
            instagramLink:nextprops.instagramProfile,
            linkedInLink:nextprops.linkedInProfile
            

        })
    }
    visitSocialMedia = (link)=>{
       if(link != null){
           window.open(link,'_blank')
       }
    }
    render(){
        let chatClass = this.props.selectedParticipantId != '' ? 'chats' : 'chats-closed'
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} style = {{color : 'blue',paddingLeft: 0,maxWidth : '100%'}} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
        return(
            <>
            <section class="participants-chat participants-chat-main main-chat-area" style={{height:'768px- !important'}}>
                <div class="container-fluid px-0">
                    <div class="row mx-0 position-relative">
                        <div className="chat-box-area d-md-flex">
                        <div class="col-lg-4 col-md-5 px-0 list-side">
                            {this.props.updatingChatList ?
                            <div className = "d-flex" style = {{position : 'absolute',justifyContent : 'center',alignItems : 'center',zIndex : 9,height : '100%',width : '100%',background : 'rgb(0 0 0 / 9%)'}}>
                                <div>
                                    <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {25}
                                        width = {25}
                                    />
                                </div>
                            </div> : null}
                            <div class="chat-list-header py-3 px-3">
                                <span><Trans>yourChats</Trans></span>
                                <a style = {{color : 'white'}} data-toggle="modal" data-target="#chat-list-modal" class="btn new_chat float-right">
                                    <i class="far fa-comment"> &nbsp; </i> <Trans>new</Trans>
                                </a>
                            </div>
                            <div class="chat-list">
                                <ul class="list-group rounded-0">
                                    {this.props.chatList.map((item,index)=>{
                                        let name = `${item.firstName}  ${item.lastName == null ? " " : item.lastName}`
                                        let active = this.props.selectedParticipantId == item.id ? " active" : ""
                                        let badgeClosed = item.unReadMessagesCount == 0 ? " closed" : ""
                                        return(
                                        <li class={"list-group-item py-3"+active} onClick = {()=>{
                                            this.props.getInvisibleUser(item.to,item.chatAvailabaility)
                                            console.log(item)
                                            this.setState({
                                            name:`${item.firstName}  ${item.lastName == null ? " " : item.lastName}`,
                                            profileImage: item.profileImageUrl,
                                            userType:item.userType,
                                            chatInfo:true,
                                            jobTilte:item.jobTitle,
                                            company:item.companyName,
                                            bio:item.profileBio,
                                            visible:item.avalibility || item.chatAvailabaility,
                                            duration:item.chatAvailabailityDuration,
                                            twitterLink:item.twitterProfileLink,
                                            facebookLink:item.facebookProfileLink,
                                            instagramLink:item.instagramProfileLink,
                                            linkedInLink:item.linkedinProfileLink


                                        },()=>{
                                            console.log(this.state)
                                        })
                                            this.props.onParticipantSelected(item.id,item.socketId,name,item.profileImageUrl,item.isOnline,index,item.chatAvailabaility,item.chatAvailabailityDuration,item.userType,item.jobTitle,item.profileBio,item.companyName,item.twitterProfileLink,item.facebookProfileLink,item.instagramProfileLink,item.linkedinProfileLink)}}>
                                            <div class="row">
                                                <div class="col-3 col-md-2">
                                                    <div class="chat-u-img">
                                                        {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                            <img src="/images/profile-placeholder.png" alt="participant_image" /> :
                                                            <img src={item.profileImageUrl} alt="participant_image" />}
                                                         {this.props.event.is_jobfair == 1 && (item.hasOwnProperty('userType') &&  `${item.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())?  
                                                         <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>
                                                         :null} 
                                                            <div class = {`${utility.chatIcon(item.avalibility || item.chatAvailabaility,item.chatAvailabailityDuration,item.isOnline)}`}></div> 
                                                    </div>
                                                </div>
                    
                                                <div class="col-9 col-md-10 d-flex align-items-center">
                                                    <div class="chat-u-name w-100">
                                                        <p class = "h-name">
                                                            {name.toLowerCase().includes(null) ? name.replace(null,''):name}
                                                        </p>
                                                        <span class="float-right">{item.time}</span>
                                                        <p class="short-msg mb-0">
                                                        {item.message}
                                                        </p>
                                                        <span class={"badge badge-pill badge-dark"+badgeClosed}>{item.unReadMessagesCount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        {/* {this.props.selectedParticipantId != '' ? */}
                        <div class="col-lg-5 col-md-7 px-0 px-md-2 col-chat-mob">
                            <div class = {chatClass}> 
                                <div class="chat-card card border-0 rounded-0" style = {{overflow : 'hidden',margin: '0px 0px'}}>
                                    <div class="card-header rounded-0 px-3 py-3">
                                        <div class="chat-u-img">
                                            {this.props.selectedParticipantProfileImage == null || this.props.selectedParticipantProfileImage == "" ? 
                                                <img src="/images/profile-placeholder.png" alt="participant_image" /> :
                                                <img src={this.props.selectedParticipantProfileImage} alt="participant_image" />}
                                             {this.props.event.is_jobfair == 1 && `${this.props.userType}`.toLowerCase() == 'Recruiter'.toLowerCase() ?  
                                                <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>
                                                :null}
                                                <div class = {`${utility.chatIcon(this.state.visible,this.state.duration,this.props.selectedParticipantOnline)}`}></div> 
                                                {this.props.chatAvailabaility == 2 ? <div class = "hidden-chat"></div> :this.state.visible=='' && this.state.duration == ''  && this.props.selectedParticipantOnline ? <div class = "online-chat"></div> : null} 
                                        </div>
                                        <h6 class="float-left m-0 ml-2"> 
                                            {this.state.name == '' ? this.props.selectedParticipantName.replace("null",""):this.state.name.replace("null","")}
                                        </h6>
                                        <a style = {{cursor : this.props.selectedParticipantId != '' ? 'pointer' : 'default'}} onClick = {()=>{
                                            this.setState({profileImage:'',
                                            name:'',
                                            jobTilte:'',
                                            company:'',
                                            bio:'',
                                            userType:'',
                                            visible:'',
                                            duration:'',
                                        chatInfo:false,
                                        twitterLink:null,
                                            facebookLink:null,
                                            instagramLink:null,
                                            linkedInLink:null,

                                    defaultChat:false})
                                            this.props.onClickCloseChat()}} class = "close-icon float-right">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                        </a>
                                        {this.props.event.is_jobfair == 1 ? null :
                                        Constants.showVideoCallButton  && this.props.selectedParticipantOnline ?
                                        <a style = {{color : 'white',cursor : this.props.selectedParticipantId != '' ? 'pointer': 'default'}} onClick = {this.props.onClickCall} class="btn btn-sch">
                                            <Trans>videoCall</Trans>
                                        </a> : null}
                                        
                                    </div>
                                </div>
        
                                <div class="chat-message-area">
                                    {this.props.loadingSingleChat ?
                                    <div className = "d-flex" style = {{position : 'absolute',justifyContent : 'center',alignItems : 'center',zIndex : 9,height : '100%',width : '100%',background : 'rgb(0 0 0 / 9%)'}}>
                                        <div>
                                            <Loader
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {30}
                                                width = {30}
                                            />
                                        </div>
                                    </div> : null}
                                    <div class="row m-0 p-0 position-relative">
                                        <div class="col-12 m-0 p-0">
        
                                            <div id = "chat-inner" class="card chat-inner rounded-0 p-0 m-0">
                                                <div id="sohbet" class="chat-inner-list card border-0 m-0 p-3 position-relative bg-transparent">
                                                    {this.props.chat.map((chatDate,index)=>{
                                                        return(
                                                            <>
                                                            <div className = "date-status">
                                                                {chatDate.date}
                                                            </div>
                                                            {chatDate.chat.map((chat,chatIndex)=>{
                                                                let side = chat.me ? "receive-msg" : "send-msg"
                                                                let float = chat.me ? 'float-right' : 'float-left'
                                                                let senderName = chat.me ? "Me" : chat.senderName
                                                                let time = " "+chat.time
                                                                return(
                                                                    chat.type == "callLog" ? 
                                                                    <div className = "call-log">
                                                                        {time}<br/>
                                                                        <span> <Trans>call</Trans> </span>
                                                                        <span className = "duration">
                                                                            {chat.message}
                                                                        </span>
                                                                    </div>:
                                                                    <div class={"p-2 m-0 position-relative "+side} data-is={time}>
                                                                        <a class={float} style = {{wordBreak : 'break-word'}}>
                                                                            <span>{`${senderName}`.toString().toLowerCase().includes(null) ? `${senderName}`.toString().replace(null,''):senderName}</span> <br/>
                                                                            <Linkify componentDecorator={componentDecorator}>
                                                                                {chat.message}
                                                                            </Linkify> 
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                            </>
                                                        )
                                                    })}
                                                </div>
        
                                            </div>
        
                                        </div>
        
                                    </div>
                                    <div class="row m-0 p-0 chat-enter-msg">
                                        <div class="col-12 px-1 py-4 py-md-2 pr-0 enter-text">
                                            <form id = "chat-form" class = "chat-form">
                                                <div class="row m-0 p-0 position-relative">
                                                    <div class="col-10 pl-3 pr-0">
                                                        {/* <input value = {this.props.chatMessage} onChange = {this.props.onInputTextChanged} type="text" class="form-control h-100" placeholder = "Type your message.." /> */}
                                                        <textarea disabled = {this.props.selectedParticipantId == ''} placeholder = {this.translation.typeyourMessage} autoFocus id = "chatInput" onChange = {this.props.onInputTextChanged} value = {this.props.chatMessage} class = "form-control h-100" rows="1"></textarea>
                                                    </div>
                                                    <div class="col-2 pl-1 pr-0">
                                                        <button disabled = {this.props.selectedParticipantId == ''} onClick = {this.props.onClickSendMessage} class="btn btn-light w-100 h-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.712" height="19.119" 
                                                            viewBox="0 0 20.712 19.119">
                                                                <path d="M3.375,23.619l20.712-9.559L3.375,4.5v7.435l14.837,2.124L3.375,16.184Z"
                                                                transform="translate(-3.375 -4.5)" fill="#808080"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        {this.state.chatInfo == true ? <div class="col-lg-3 col-md-3 px-0 d-none d-md-block">
            <div class="chat-person-card bg-light p-4 text-center h-100">
                <div class="chat-person-img">
                    <div style={{width:"85px",height:"85px",position:"relative"}}>
                    {this.state.profileImage == "" ?
                    <img src="/images/profile-placeholder.png" alt="participant_image" />:
                    <img src={this.state.profileImage} alt="participant_image" />
                    
    }
     { `${this.state.userType}`.toLowerCase() == 'Recruiter'.toLowerCase() ? 
                                                           <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:'0%',left:'0%'}} alt="badge-img"/>
                                                         :null} 
                                                         </div>
                </div>
                
                <h4>{this.state.name}</h4>
                <p class="designation mb-1">
                    {this.state.jobTilte}
                </p>
                <p class="comp-name">
                    {this.state.company}
                </p>
                {/* <div class="chat-person-content px-3">
                    <p class="chat-person-bio mb-1">
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.
                        <span class="d-none remaning-bio">
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.
                        </span>
                    </p>
                    <a href="#" class="text-underline text-dark" id = "show-full-bio">show in full</a>
                </div> */}
               { (this.props.event.islinkedinProfileLink != null && this.props.event.islinkedinProfileLink == 1 ) ||
                    (this.props.event.istwitterProfileLink != null && this.props.event.istwitterProfileLink == 1) ||
                    (this.props.event.isfacebookProfileLink != null && this.props.event.isfacebookProfileLink == 1) || 
                    (this.props.event.isinstagramProfileLink != null && this.props.event.isinstagramProfileLink == 1)  
                    ?
                    this.state.twitterLink == null && this.state.facebookLink == null &&  this.state.instagramLink == null && this.state.linkedInLink == null ? null :
                <div className="socialMediaContainer">
                   {this.state.linkedInLink != null && this.props.event.islinkedinProfileLink == 1 ?  <div className="socialMediaIcons" style={{background:"#0077b5"}}  onClick={()=>{
                    //    twitterLink:null,
                    //    facebookLink:null,
                    //    instagramLink:null,
                    //    linkedInLink:null
                       this.visitSocialMedia(this.state.linkedInLink)
                   }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" color="#fff" viewBox="0 0 24 24"><path fill="#fff" d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                    </div>:null}
                    {this.state.twitterLink != null && this.props.event.istwitterProfileLink == 1 ? <div className="socialMediaIcons" style={{background:"#00acee"}} onClick = {()=>{
                        this.visitSocialMedia(this.state.twitterLink)
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
                    </div>:null}
                    {this.state.facebookLink != null && this.props.event.isfacebookProfileLink == 1 ?
                        <div className="socialMediaIcons" style={{background:"#3b5998"}} onClick = {()=>{
                            this.visitSocialMedia(this.state.facebookLink)
                        }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                    </div>:null}
                    {this.state.instagramLink != null && this.props.event.isinstagramProfileLink == 1 ? <div className="socialMediaIcons" style={{background:"radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)"}} onClick={()=>{
                        this.visitSocialMedia(this.state.instagramLink)
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </div>:null}
                </div>:null}
                <div id="speakerDetail" class="spk-detail-text" style={{height:'calc(100vh - 430px)' ,overflow:'auto',
                // textAlign:'justify'
                }}>
                                    {this.state.bio != null ?
                                    <p class = "details pr-3">
                                        {!this.state.showFullSpeakerDescription && this.state.bio.length >= 500 ?
                                            this.state.bio.substring(0,500) :
                                            this.state.bio}
                                    </p> : null}
                                    {this.state.bio != null && this.state.bio.length >= 500 ?
                                    <a style = {{cursor : 'pointer',textDecoration:'underline'}} onClick = {()=>{
                                        this.setState({showFullSpeakerDescription : !this.state.showFullSpeakerDescription},()=>{
                                            if(!this.state.showFullSpeakerDescription){
                                                var myDiv = document.getElementById('speakerDetail');
                                                myDiv.scrollTop = 0
                                            }
                                        })
                                    }} class="text-underline text-dark">{!this.state.showFullSpeakerDescription ? "Show Full" : "Show Less"}</a> : null}
                            </div>
            </div>
        </div>:this.props.selectedParticipantName != '' && this.state.defaultChat == true ?<div class="col-lg-3 col-md-3 px-0 d-none d-md-block">
            <div class="chat-person-card bg-light p-4 text-center h-100">
                <div class="chat-person-img">
                    <div style={{position:"relative",top:"0%",left:"0%"}}>
                    {this.props.selectedParticipantProfileImage == "" ?
                    <img src="/images/profile-placeholder.png" alt="participant_image"  />:
                    <img src={this.props.selectedParticipantProfileImage } alt="participant_image"/>
    }
      
       { `${this.state.userType}`.toLowerCase() == 'Recruiter'.toLowerCase() ? 
                                                          <img class="badge-top" src="/images/badge.png" style={{position:'absolute'}} alt="badge-img"/>
                                                         :null}
                                                         </div>
                </div>
                
                <h4 style={{cursor:'pointer'}}>{this.props.selectedParticipantName == null ? '':this.props.selectedParticipantName.replace("null","")}</h4>
                <p class="designation mb-1">
                    {this.props.selectedParticipantJob}
                </p>
                <p class="comp-name" >
                    {this.props.selectedParticipentCompany}
                </p>{
                    (this.props.event.islinkedinProfileLink != null && this.props.event.islinkedinProfileLink == 1 ) ||
                    (this.props.event.istwitterProfileLink != null && this.props.event.istwitterProfileLink == 1) ||
                    (this.props.event.isfacebookProfileLink != null && this.props.event.isfacebookProfileLink == 1) || 
                    (this.props.event.isinstagramProfileLink != null && this.props.event.isinstagramProfileLink == 1)?
                    this.state.twitterLink == null && this.state.facebookLink == null &&  this.state.instagramLink == null && this.state.linkedInLink == null ? null :
                <div className="socialMediaContainer">
                   {this.state.linkedInLink != null && this.props.event.islinkedinProfileLink == 1 ?  <div className="socialMediaIcons" style={{background:"#0077b5"}} onClick={()=>{
                       this.visitSocialMedia(this.state.linkedInLink)
                   }}
                   >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" color="#fff" viewBox="0 0 24 24"><path fill="#fff" d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
                    </div>:null}
                    {this.state.twitterLink != null && this.props.event.istwitterProfileLink == 1 ? <div className="socialMediaIcons" style={{background:"#00acee"}} onClick = {()=>{
                        this.visitSocialMedia(this.state.twitterLink)
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
                    </div>:null}
                    {this.state.facebookLink != null && this.props.event.isfacebookProfileLink == 1 ?
                        <div className="socialMediaIcons" style={{background:"#3b5998"}} onClick = {()=>{
                            this.visitSocialMedia(this.state.facebookLink)
                        }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                    </div>:null}
                    {this.state.instagramLink != null && this.props.event.isinstagramProfileLink == 1 ? <div className="socialMediaIcons" onClick = {()=>{
                        this.visitSocialMedia(this.state.instagramLink)
                    }} style={{background:"radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#fff" d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                    </div>:null}
                </div>:null}
                {/* <div class="chat-person-content px-3">
                    <p class="chat-person-bio mb-1">
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.
                        <span class="d-none remaning-bio">
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Anim pariatur cliche reprehenderit, 
                        enim eiusmod high life accusamus terry richardson ad squid.
                        </span>
                    </p>
                    <a href="#" class="text-underline text-dark" id = "show-full-bio">show in full</a>
                </div> */}
                <div id="speakerDetail" class="spk-detail-text" style={{height:'calc(100vh - 430px)' ,overflow:'auto',
                // textAlign:'justify'
                }}>
                                    {this.props.selectedParticipantbio != null ?
                                    
                                    <p class = "details pr-3">
                                        {!this.state.showFullSpeakerDescription && this.props.selectedParticipantbio.length >= 500 ?
                                            this.props.selectedParticipantbio.substring(0,500) :
                                            this.props.selectedParticipantbio}
                                    </p> : null}
                                    {this.props.selectedParticipantbio != null && this.props.selectedParticipantbio.length >= 500 ?
                                    <a style = {{cursor : 'pointer',textDecoration:'underline'}} onClick = {()=>{
                                        this.setState({showFullSpeakerDescription : !this.state.showFullSpeakerDescription},()=>{
                                            if(!this.state.showFullSpeakerDescription){
                                                var myDiv = document.getElementById('speakerDetail');
                                                myDiv.scrollTop = 0
                                            }
                                        })
                                    }} class="text-underline text-dark">{!this.state.showFullSpeakerDescription ? "Show Full" : "Show Less"}</a> : null}
                            </div>
            </div>
        </div>:null}

                    </div>
                    </div>
                </div>
                </section>
                <div class="modal" id="chat-list-modal">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content list-modal">
                            <div class = "modal-header border-bottom-0 px-3 px-lg-4">
                                <h3><Trans>newChat</Trans></h3>
                                <button data-dismiss="modal">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="modal-body px-3 px-lg-4 participants-chat">
                            <div class="row mb-3 participants-listing">
                                <div class="col-12">
                                    <form action="">
                                        <div class="form-row">
                                            <div class="col-12 input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">
                                                        <i class = "fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input onChange = {this.props.onParticipantSeacrhTextChanged} type="text" class="form-control" id = "list_input" placeholder="Search Name or Company or Country" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="chat-list"  id = "chatList">
                                {this.props.searchingParticipants ? <div className = "justify-content-center" style = {{width : '100%',height : '100%',zIndex : 99,position : 'absolute',top : 0,left : 0,display : 'flex',alignItems : 'center'}}>
                                    <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {30}
                                        width = {30}
                                    />
                                </div> : null}
                                <ul class="list-group rounded-0">
                                    {this.props.participants.map((item,index)=>{
                                        if(item.id == this.props.userId){
                                            return null
                                        }
                                        if(this.props.chatList.findIndex(x=>x.id == item.id) > -1){
                                            return null
                                        }
                                        let name = `${item.firstName} ${item.lastName == null  ? " " : item.lastName}`
                                        return(
                                            <li class="list-group-item py-2">
                                                <div class="row">
                                                    <div class="col-3 col-lg-1 d-flex align-items-center">
                                                        <div class="chat-u-img">
                                                        {item.profileImageUrl == null || item.profileImageUrl == "" ? 
                                                            <img src="/images/profile-placeholder.png" alt="participant_image" /> :
                                                            <img src={item.profileImageUrl} alt="participant_image" />}
                                                        {this.props.event.is_jobfair == 1 && (item.hasOwnProperty('userType') &&  `${item.userType}`.toLowerCase() == 'Recruiter'.toLowerCase())?
                                                          <img class="badge-top" src="/images/badge.png" style={{position:'absolute',top:0,left:0}} alt="badge-img"/>
                                                        :null}
                                                        <div class = {`${utility.chatIcon(item.avalibility || item.chatAvailabaility,item.chatAvailabailityDuration,item.isOnline)}`}></div> 
                                                        </div>
                                                    </div>
                        
                                                    <div class="col-9 col-md-11 d-flex align-items-center">
                                                        <div class="chat-u-name w-100">
                                                            {name}
                                                            <div class="post">
                                                                {item.jobTitle}
                                                            </div>
                                                            <div class = "c-btn d-none">
                                                                <a style = {{cursor : 'pointer',color : '#fff' }} onClick = {()=>{

                                                                    this.props.onParticipantSelected(item.id,item.socketId,name,item.profileImageUrl,item.isOnline,-3,item.chatAvailabaility,item.chatAvailabailityDuration,item.userType,item.jobTitle,item.profileBio,item.companyName,item.twitterProfileLink,item.facebookProfileLink,item.instagramProfileLink,item.linkedinProfileLink)}} class="btn new_chat float-right">
                                                                    <i class="far fa-comment"></i> <Trans>chat</Trans>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-block d-md-none">
                                                    <div class="col-12">
                                                            <div class = "c-btn-mob w-100">
                                                                <a style = {{cursor : 'pointer',color : '#fff' }} onClick = {()=>{
                                                                
                                                                    this.props.onParticipantSelected(item.id,item.socketId,name,item.profileImageUrl,item.isOnline,-3,item.chatAvailabaility,item.chatAvailabailityDuration,item.userType,item.jobTitle,item.profileBio,item.companyName,item.twitterProfileLink,item.facebookProfileLink,item.instagramProfileLink,item.linkedinProfileLink)}} class="btn new_chat w-100">
                                                                    <i class="far fa-comment"></i> <Trans>chat</Trans>
                                                                </a>
                                                            </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {this.props.laoding  ? 
                            <div className = "d-flex" style = {{justifyContent : 'center',alignItems : 'center',zIndex : 9,height : '100%',width : '100%',background : 'white'}}>
                                        <div>
                                            <Loader
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {30}
                                                width = {30}
                                            />
                                        </div>
                                    </div>
                                 :null}
                            
                            </div>
                
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default compose(withTranslation())(TextChat)