import { Constants } from '../../../Constants';
import axios from '../../axios'
import * as Actions from "./actionType";
export const getFloordata = (eventAlias,params) => {
    return async(dispatch)=>{
         return await axios.get(`${eventAlias}/getEventVirtualFloorPlan`,{
            headers: { 'Content-Type': 'application/json','apiToken': Constants.API_TOKEN },
        }).then(response=>{
            console.log(response.data)
            dispatch({
                type: Actions.FETCH_MAP_FLOOR,
                payload: response.data
            })
        }).catch(error=>{
            // dispatch({
            //     type: Actions.FETCH_MAP_FLOOR,
            //     payload: "Null data"
            // })
        })
    }

}