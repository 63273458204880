import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getEventFeatures(eventAlias,type,home = null,callback){
        let data = null;
        console.log(home)
        if(home == null){
            data = {type: type,isActive:1,isPreview : Constants.isPreview}
        }
        else{
            data = {type: type,isActive:1,isPreview : Constants.isPreview,isHome:home}
        }

        axios.get(`${eventAlias}/getEventFeatures`,
        {
            params: data,
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    }
}