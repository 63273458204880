import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import ReactHtmlParser from "react-html-parser";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import _ from "lodash";
import EmailPop from "../../../modals/email-popup";
import utility,{wordsRestrictor} from "../../../../Utility/utility";
import { useDispatch,useSelector } from "react-redux";
import "./styles.css";
import  {RemoveScroll}  from '../actions/index'
export const Content = (props) => {
  let [modelShow, setmodelShow] = useState(false);
  let [public_chat, setPublic_chat] = useState(false);
  let [message, setMessage] = useState("");
  let [textMaxValue, setMaxValue] = useState(1000);
  let [boothTitle,setBoothTitle]  = useState(props.boothName)
  let [rep_id, setId] = useState(null);
  let [represntative,setRepresentative] = useState([])
  var onlineRepresentative = []
  const dispatch = useDispatch();
  const online = useSelector(state => state.appData)
  const user = utility.getLoggedInUser(props.alias)

  useEffect(()=>{
    console.log(props)
   console.log(props.Name)
  },[props.boothName])
  useEffect(()=>{
    if(online.onlineUserdata.length > 0){
      let onlineParticpant = []
      online.onlineUserdata.map(item=>{
        onlineParticpant.push(item.userId)
      })
      let participent = props.representatives.map(item=>{
        if(onlineParticpant.includes(item.id)){
           return {...item,isOnline:true}
        }
        else{
          return item
        }
      })
      if(represntative.length != online.onlineUserdata.length){
        setRepresentative([...participent])
     }



    }
  },[online.onlineUserdata])
  if (props.brandings.length == 0) {
    return (
      <section
        style={{
          width: "100%",
          height: window.innerHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.loading ? (
          <div
            style={{
              width: "100%",
              height: window.innerHeight,
              background: "#f7f7f7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#00b6ff" height={40} width={40} />
          </div>
        ) : (
          <div className="text-center">{props.loadingError}</div>
        )}
      </section>
    );
  }
  const hideEmailDialog = () => {
    setmodelShow(false);
  };

  const stopScroll = (type)=>{
    dispatch(RemoveScroll(type))
  }

  const sendMessage = () => {
    if (message.replace(/\s/g, "").length > 0) {
      let date = new Date();
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month = date.toLocaleString("default", { month: "short" });
      let year = date.getFullYear();
      let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let min =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let dateAndTime = `${day}-${month}-${year} ${hour}:${min}`;
      props.chatWithUser({
        name: props.user.firstName + " " + props.user.lastName,
        profileImageUrl: props.user.profileImageUrl,
        message: message,
        currentBooth: props.currentBoothId,
        date: dateAndTime,
        socketId: props.mySocketId,
        userid: props.user.id,
      });
      setMessage("");
    } else {
      setMessage("");
    }
  };
  return (
    <>
      <EmailPop
        modelShow={modelShow}
        hideEmailDialog={hideEmailDialog}
        sendEmailToRep={props.sendEmailToRep}
        rep_id={rep_id}
        emailResponse={props.emailResponse}
        message={props.message}
        rep_id={rep_id}
      />
      {
        <div class={`public-chat-area ${public_chat ? "active" : ""}`}>
          <div class="p-header p-3">
            <h6>{props.boothName} - Booth Chat</h6>
            <span
              class="pc-close"
              onClick={() => {
                stopScroll(0)
                setPublic_chat(false);
              }}
            >
              {/* <img src = {process.env.PUBLIC_URL+'/images/icons/ic_chat_close.svg'} alt = "icon"/> */}
              <i class="fas fa-times"></i>
            </span>
          </div>

          <div class="pc-txtarea px-3 pt-2 pb-3">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <span class="text-counter">
                    <em class="jQTAreaCount"></em> {message.length}/
                    {textMaxValue} <em class="jQTAreaValue"></em>
                  </span>
                  <textarea
                    maxlength={1000}
                    class="form-control rounded-0"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        if (
                          message != "" &&
                          message.replace(/\s/g, "").length > 0
                        ) {
                          sendMessage();
                        } else {
                          setMessage("");
                        }
                      }
                    }}
                    placeholder="Type here & press submit to send"
                    limit={textMaxValue}
                  ></textarea>
                </div>
                <div class="form-group text-right mb-0">
                  <button
                    class="btn btn-spc"
                    onClick={(e) => {
                      e.preventDefault();
                      sendMessage();
                    }}
                    style={{ pointerEvents: message == "" ? "none" : "unset" }}
                  >
                    <Trans>send</Trans>
                    {/* <img class = "ml-3" src = {process.env.PUBLIC_URL+'/images/icons/ic_chat_close.svg'} alt = "icon"/> */}
                    <i
                      style={{ marginLeft: "5px" }}
                      class="fa fa-paper-plane"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="p-body px-3 pb-3">
            {props.user_chat_data.length > 0
              ? props.user_chat_data.map((item) => {
                  // console.log(item)
                  return (
                    <div class="cm-card mb-1">
                      <span class="cm-date">{item.created_at}</span>
                      <div class="row">
                        <div class="col-2">
                          <div class="cm-img">
                            {item.profileImageUrl == null ||
                            item.profileImageUrl == "" ? (
                              <img
                                src="/images/profile-placeholder.png"
                                alt="member-pic"
                              />
                            ) : (
                              <img
                                src={item.profileImageUrl}
                                alt="member-pic"
                              />
                            )}
                          </div>
                        </div>
                        <div class="col-10">
                          <p
                            class="cm-name"
                            className={`${
                              item.sender_id == props.user.id
                                ? "not-allowed"
                                : "allowed"
                            }`}
                            onClick={() => {
                              if (item.sender_id == props.user.id) {
                              } else {
                                setPublic_chat(false);
                                props.onClickChat(
                                  item.sender_id,
                                  item.socketId
                                );
                              }
                            }}
                          >
                            {`${item.name}`.includes("null") ? item.name.replace('null','') : item.name}
                          </p>
                          <p>{item.message}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      }
      <section
        style={{ left: 0, zIndex: -99 }}
        id="exhibitor-area-s"
        class="exhibitor-area"
      >
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-12 px-0"
              style={{
                minHeight: props.loadingTemplateImage ? window.innerHeight : 0,
              }}
            >
              {props.loadingTemplateImage ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                    background: "rgba(0,0,0,0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader type="Oval" color="#fff" height={40} width={40} />
                </div>
              ) : null}
              <div
                id="close-button"
                onClick={()=>{
                  props.onClickCloseBooth()
                  stopScroll()
                }}
                class="close-bth-btn"
              >
                <Trans>close</Trans>
                <div class="chat-icon">
                  <img src="/images/icons/ic_booth_close.svg" alt="close" />
                </div>
              </div>
              {/* {props.boothRepresentativeCount > 0 ? ( */}
                <>
                  {props.boothRepresentativeCount > 0 ? <div
                    id="booth-chat-button"
                    onClick={props.onClickBoothChat}
                    class="opn-chat-btn"
                    style={{ zIndex: public_chat ? 100 : 1 }}
                  >
                    <div class="chat-icon">
                      <img src="/images/icons/ic_cht.svg" alt="chat" />
                    </div>
                    <Trans>talkToUs</Trans>
                  </div>:null}
                  {props.isPublicChatEnabled != 1 ? null : (
                    <div
                      id="booth-chat-button2"
                      style={{paddingRight:"5px"}}
                      onClick={() => {
                        setPublic_chat(true);
                        stopScroll(1)
                      }}
                      class={`opn-chat-btn2 ${props.boothRepresentativeCount == 0 ? "opn-chat-btn_dialog2":"opn-chat-btn_dialog1"}`}
                      style={{ zIndex: public_chat ? 100 : 1 }}
                    >
                      <div class="chat-icon">
                        <img
                          src="/images/icons/public-chat-white.svg"
                          alt="chat"
                        />
                      </div>
                      Booth Chat  
                    </div>
                  )}
                  <ul
                    class={`
                     ${ props.showBoothChatDrawer
                        ? "list-group booth-chat-list active"
                        : "list-group booth-chat-list"}
                    `}
                  >
                    <li class="list-group-item booth-list-header">
                      <Trans>chatWithRep</Trans>
                      <span
                        onClick={props.onClickCloseBoothChat}
                        class="close-lsit"
                      >
                        <i class="fas fa-times"></i>
                      </span>
                    </li>
                    {_.uniq(props.representatives, 'id').map((item, index) => {
                      let onlineClass = item.isOnline ? " online" : ""
                      return (
                        <li class="list-group-item mem-name">
                          <div class={`member-img ${utility.chatIcon(
                                  item.chatAvailabaility,
                                  item.chatAvailabailityDuration,
                                  false,
                                  item.id,
                                  online.onlineUserdata

                                ) == "online-chat"
                                  ? "online"
                                  : " offline" }`}>
                            {item.profileImageUrl == null ||
                            item.profileImageUrl == "" ? (
                              <img
                                src="/images/profile-placeholder.png"
                                alt="member-pic"
                              />
                            ) : (
                              <img
                                src={item.profileImageUrl}
                                alt="member-pic"
                              />
                            )}
                            {/* <span
                              style={{ position: "unset" }}
                              class={`img-div ${
                                utility.chatIcon(
                                  item.chatAvailabaility,
                                  item.chatAvailabailityDuration,
                                  false,
                                  item.id,
                                  online.onlineUserdata

                                ) == "online-chat"
                                  ? "online"
                                  : ""
                              }`}
                            >
                              {" "}
                            </span> */}
                          </div>
                          <p class="name">
                         {` ${item.firstName} ${item.lastName == null ? " " : item.lastName}`}
                          </p>
                          <p class="job">{item.jobTitle != null ? wordsRestrictor(item.jobTitle,10):item.jobTitle}</p>
                          <ul class="list-inline btn-side">
                            {/* {item.email != null ?
                                    <li class="list-inline-item">
                                        <a href = {"mailto:"+item.email} class="btn new_chat">
                                            <i class="fas fa-envelope"></i> 
                                        </a>
                                    </li> : null} */}
                            {item.count >= 2 ? null : (
                              <li class="list-inline-item">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setmodelShow(true);
                                    setId(item.id);
                                  }}
                                  class="btn new_chat"
                                  data-backdrop="true"
                                  data-keyboard="false"
                                  data-toggle="modal"
                                  data-target="#send-eamil-m"
                                  role="modal"
                                >
                                  <i
                                    class="fas fa-envelope"
                                    style={{ marginRight: "4px" }}
                                  ></i>
                                  <Trans>email</Trans>
                                </a>
                              </li>
                            )}

                            {utility.chatIcon(
                              item.chatAvailabaility,
                              item.chatAvailabailityDuration,
                              false,
                              item.id,
                              online.onlineUserdata
                              
                            ) == "online-chat" ? (
                              <li class="list-inline-item">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.onClickChat(item.id, item.socketId)
                                  }
                                  class="btn new_chat"
                                >
                                  <i
                                    class="far fa-comment"
                                    style={{ marginRight: "4px" }}
                                  ></i>
                                  <Trans>chat</Trans>
                                </a>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                      );
                    })}
                    {props.loadingResprenstatives ? (
                      <div
                        className="text-center py-3"
                        style={{ width: "100%" }}
                      >
                        <Loader
                          type="Oval"
                          color="#00b6ff"
                          height={25}
                          width={25}
                        />
                      </div>
                    ) : null}
                  </ul>
                </>
              {/* ) : null} */}
              <div class="pyramid">
                <img
                  src={props.selectedBooth.templateImage}
                  id="image-map"
                  usemap="#image-map"
                  onLoad={props.templateImageLoaded}
                />
                {props.selectedBooth.brandings != null ? (
                  <map name="image-map" id="image-map">
                    {/* {props.selectedBooth.brandings.map((item,index)=>{
                                return(
                                    <area style = {{cursor : 'pointer'}} onClick = {()=> props.onClickBrandingItem(item)} alt="" title={item.title} coords={props.boothMapAreas[1][index]} shape="rect"/>
                                )
                            })} */}
                    {/* <area target="_blank" alt="" title="" href="banner-2" coords="1483,268,1644,611" shape="rect"/>
                            <area target="_blank" alt="" title="" href="vedeo-banner" coords="920,212,1176,358" shape="rect"/> */}
                    {ReactHtmlParser(props.selectedBooth.mapArea)}
                  </map>
                ) : null}
              </div>
              {/* <div class="video-open" data-toggle = "modal" data-target = "#Brochure-modal">
                    </div> */}
            </div>
          </div>
        </div>
      </section>
      <section class="mobile-both-btn d-block d-md-none position-relative">
        <div class="container">
          <div class="row">
            {/* <div className="nav justify-content-center text-center"> */}
            <div style = {{display:'flex',justifyContent:'center',width:"100%"}}>
            <div onClick={()=>{
              props.onClickCloseBooth()
              stopScroll()
            }} class="col-4">
              <div class="close-mob-btn">
                <img src="/images/icons/ic_booth_close.svg" alt="close" />
              </div>
              <div class="text-btm">
                <Trans>close</Trans>
              </div>
            </div>
            {props.boothRepresentativeCount > 0  ? <div onClick={props.onClickBoothChat} class="col-4">
              <div class="chat-mob-btn">
                <img src="/images/icons/ic_cht.svg" alt="chat" />
              </div>
              <div class="text-btm-chat">
                <Trans>talkToUs</Trans>
              </div>
            </div>:null}
            {props.isPublicChatEnabled != 1 ? null : (
              <div
                id="booth-chat-button2"
                onClick={() => {
                  setPublic_chat(true);
                stopScroll(1);
                }}
                class="col-4"
                style={{ opacity: public_chat ? "0" : "1" ,paddingRight:'5px'}}
              >
                <div class="chat2-mob-btn">
                  <img src="/images/icons/public-chat-white.svg" alt="chat" />
                </div>
                <div class="text-btm-chat">Booth Chat</div>
              </div>
            )}
            </div>
          </div>
        </div>
      </section>
      <div class="modal" id="Brochure-modal">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          {props.selectedBranding.link != null ? (
            <div class="modal-content bio-modal rounded-0 Brochure-modal">
              <div class="modal-header px-4 py-3">
                <h3 class="w-100 font-weight-bold">
                  <Trans>brouchers</Trans>
                </h3>
                <button data-dismiss="modal">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
              <div class="modal-body px-4">
                <ul class="list-group ptd-download-list">
                  {props.selectedBranding.link.map((link, index) => {
                    let title = link.title.replace(".pdf", "");
                    let longTitleEndEffectCls = "";
                    if (isMobile) {
                      longTitleEndEffectCls =
                        title.length > 20 ? "titleEffect" : "";
                    } else {
                      longTitleEndEffectCls =
                        title.length > 65 ? "titleEffect" : "";
                    }
                    return (
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-2 col-md-1 pr-0">
                            <img src="/images/ic_pdf.svg" alt="pdf-icon" />
                          </div>
                          <div
                            class={
                              "col-8 col-md-8 px-1 px-md-0 " +
                              longTitleEndEffectCls
                            }
                          >
                            <p>{title}</p>
                          </div>
                          <div class="col-2 col-md-3">
                            {props.downloadingPdf &&
                            props.selectedPdfId == link.id ? (
                              <>
                                <div className="d-none d-md-block float-right mx-2 pt-1">
                                  <Loader
                                    type="Oval"
                                    color="#00b6ff"
                                    height={25}
                                    width={25}
                                  />
                                </div>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 70,
                                  }}
                                  className="d-block d-md-none"
                                >
                                  <Loader
                                    type="Oval"
                                    color="#00b6ff"
                                    height={25}
                                    width={25}
                                  />
                                </div>
                              </>
                            ) : null}
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => props.onClickPdf(link.id, title)}
                              class="float-right btn d-none d-md-block"
                            >
                              <Trans>download</Trans>
                            </a>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => props.onClickPdf(link.id, title)}
                              class="float-right btn d-block d-md-none"
                            >
                              <i class="fas fa-arrow-circle-down"></i>
                            </a>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        class="modal"
        id="video-modal-exhb"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          {props.selectedBranding.link != null ? (
            <div class="modal-content bio-modal rounded-0 video-modal video-modal-exhibitor">
              <div class="modal-header px-3">
                {/* <h3>{props.selectedBranding.link[props.selectedVideoIndex].title}</h3> */}
                <button data-dismiss="modal">
                  <i
                    onClick={props.onClickCloseVideo}
                    class="fas fa-times-circle"
                  ></i>
                </button>
              </div>
              <div class="modal-body p-2">
                <div class="container-fluid">
                  <div class="row mx-0">
                    <div
                      class={
                        props.selectedBranding.link.length == 1
                          ? "col-md-12 px-0"
                          : "col-md-9 px-0"
                      }
                    >
                      <div class="embed-responsive embed-responsive-16by9">
                        <iframe
                          class="embed-responsive-item"
                          src={props.selectedVideoLink}
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                    {props.selectedBranding.link.length > 1 ? (
                      <div class="col-md-3 pl-0 pl-md-1 pr-0">
                        <div class="video-list-mdl bg-light">
                          <ul class="list-inline">
                            {props.selectedBranding.link.map((video, index) => {
                              if (index == props.selectedVideoIndex) {
                                return null;
                              }
                              return (
                                <li class="list-inline-item video-list-box">
                                  {video.thumnail != null ? (
                                    <img
                                      src={video.thumnail}
                                      alt="video_thumpnil"
                                    />
                                  ) : (
                                    <iframe
                                      style={{ width: "100%", height: "100%" }}
                                      src={video.link}
                                    ></iframe>
                                  )}
                                  <div
                                    onClick={() =>
                                      props.onClickChangeVideo(index)
                                    }
                                    class="thumpnil-overlay"
                                  >
                                    <i class="far fa-play-circle text-white"></i>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
