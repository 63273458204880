import React from 'react'
import './index.css'
import ReactPaginate from 'react-paginate';
import { Row, Col, Table } from "react-bootstrap";
// import {
//     UncontrolledDropdown,
//     DropdownToggle,
//     DropdownMenu,
//     DropdownItem
//   } from "reactstrap";
export const Paginator=(props)=>{
    return(
        <Row className={props.className}>
        <div>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            {props.pages.length > 1?
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={props.pages.length}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(item)=> props.setActivePage(item.selected+1)}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>: null}
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              {/* <UncontrolledDropdown style={{ width: "100%" }}>
                <DropdownToggle
                  // color={'light'}
                  style={{
                    backgroundColor: "#fff",
                    color: "#333",
                    borderColor: "#CED4DA",
                    fontSize: "0.9rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret
                >
                  <span
                    style={{
                      color: "#333",
                      fontSize: "0.7rem",
                    }}
                  >
                    Items per page
                  </span>
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    {props.dataLimit}
                  </span>
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: "5px" }}>
                {pagesToShowOptions.map((item,index)=>{
                    return(
                      <DropdownItem onClick={()=> props.setDataLimit(item)}>{item}</DropdownItem>
                    )
                })}
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>
          </Col>
        </div>
      </Row>
    )
}