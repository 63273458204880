import React, { useState ,useEffect } from "react";
import { connect } from "react-redux";
import {compose} from 'redux'
import { withRouter,Link } from 'react-router-dom';
import api from "../../Booking/api"
import { BookingDataLoaded } from "../../Booking/actions";
import Loader from 'react-loader-spinner'
import { Content } from "./content";
import VirtualZone from "../../Header/virtualZone";
import Header from "../../Header/Index";
import utility from "../../../Utility/utility";
import Booking from "../../Booking";
import { data } from "jquery";
import { Constants } from "../../../Constants";
import Footer1 from "../../Footer/Footer1";
import Footer2 from "../../Footer/Footer2";
import {withTranslation } from 'react-i18next';
import $ from 'jquery'
import {fetchPagesData} from '../api'
function MemberShip(props){
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState('')
    const [selectedStep,setSelectedStep] = useState(1)
    const [user] = useState(utility.getLoggedInUser(props.event.alias))
    const [planSelected, setPlanSelected] = useState(false)
    const [selectedIndex] = useState(props.headerMenuItems.findIndex(x=>x.href.indexOf('/membership') > -1) || -1)
    const [guestEmail, setGuestEmail] = useState('')
    const [eGuestEmail, setGuestEmailError] = useState('')
    const [translation] = useState(props.i18n.getResourceBundle(localStorage.getItem("language"+localStorage.getItem("eventAlias"))|| 'en'))
    const [pagetitle,setPageTitle] = useState('')
    const [pageDescription,setPageDescription] = useState('')
    useEffect(()=>{

        async function fetchData() {
            let prams = {
              type:'membership'
            }
            await fetchPagesData(props.event.alias,prams,null,(err,data)=>{
              if(data){
                setPageTitle(data.data.title)
                setPageDescription(data.data.description)
              }
            })
          }
          fetchData();
        if(!props.booking){
            setLoading(true)
            getBooking()
        }else{
            setData(props.booking.tickets)
        }
    },[])
    const getBooking=()=>{
        api.getEventBooking(props.event.alias,(err,data)=>{
            setLoading(false)
            if(err == null){
                for(var i=0;i<data.tickets.length;i++){
                    data.tickets[i].selectedDelegates = 0
                }
                setData(data.tickets)
                props.bookingLoaded(data)
            }else{
                setError('Something went wrong')
            }
        })
    }
    const retry=()=>{
        setError('')
        setLoading(true)
        getBooking()
    }
    const OnNavigateToPage = (path) => {
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        props.history.push(path)
    }
    const OnClickAccountItem = (index,path) => {
        props.onClickAccountItem(index,path);  
        if(index > -1){
            props.history.replace('')
            props.history.push({
                pathname : props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+props.event.alias+'user_info')
            window.location.reload()
        }
    }
    const languageClick = (value) =>{
        props.languageClick(value);
    }
    const OnClickContinueAsGuestPopup = () => {
        let error = utility.validateEmail(guestEmail) ? "" : translation.validEmail
        setGuestEmailError(error)
        if(error == ""){
            $('#loginPopup').modal('hide');
            setPlanSelected(true)
        }
    }
    const getClasses = ()=>{
		let user = utility.getLoggedInUser(props.event.alias)
		if(user == null){
			return 'telehealthBooking content-data'
		}
		else if(user != null && user.isBooking == false){
			return 'telehealthBooking content-data'
		}
		else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
		  return 'telehealthBooking content-data'
	  }
		else{
			return ''
		}
	  }
    return(
        <>
        <div id="header-top">
            <Header 
                selectedIndex = {selectedIndex}
                selectedSubIndex = {-1} 
                onMenuItemClick = {OnNavigateToPage}  
                elements = {props.headerMenuItems}
                isMarketplace = {props.event.is_marketplace}
                facebookLink = {props.event.facebook}
                linkedInLink = {props.event.linkedIn}
                twitterLink = {props.event.twitter}
                event = {props.event}
                instagramLink = {props.event.instagram}
                googleLink = {props.event.google}
                phone = {props.event.phone_number}
                eventLogo = {props.event.logo}
                eventName = {props.event.name}
                eventAlias = {props.event.alias}
                onLoginClick = {()=>OnNavigateToPage(props.event.alias+'/login')}
                onSignupClick = {()=>OnNavigateToPage(props.event.alias+'/sign_up')}
                onClickAccountItem = {OnClickAccountItem}
                availableLocales = {props.availableLocales}
                languageClick  = {languageClick}
            />
            {user != null && user.isBooking && Constants.showVirtualZone?
            <VirtualZone
                eventAlias = {props.event.alias}
                event = {props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        OnNavigateToPage(path)
                    }
                }}  
                unReadCount = {props.unReadCount}
                onNotificationSeen = {props.onNotificationSeen} 
                onClearNotifications = {props.onClearNotifications}
                notificationsCount = {props.unReadNotificationsCount}
                notifications = {props.notifications}
                onClickAccountItem = {OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                    if(isExternal){
                        window.open(path,"_blank")
                    }else{
                        OnNavigateToPage(props.event.alias+path)
                    }
                }}
                menuItems = {props.virtualMenuItems}
                user = {user} 
            /> : null}
        </div>
        {!planSelected ?
        user  != null ?
        <section class = {`telehealth-inner-banner bg_primary py-3 py-3`}>
            <div class = "container py-3 text-center">
                <div class = "row">
                    <div class = "col-12">
                        <h2>
                            {pagetitle}
                        </h2>
                    </div>
                </div>
            </div>
        </section> :
        <section class = {`telehealth-inner-banner py-3 bg_primary`}>
        <div class = "container py-2">
            <div class = "row">
                <div class = "col-md-6 text-center text-md-left mb-4 mb-md-0 pt-md-2">
                    <h2>
                    {pagetitle}
                    </h2>
                </div>
                <div class = "col-md-6 text-center text-md-right">
                    <h2>
                        Already have an account 
                        <Link  class = "btn signin-btn" onClick={()=>{
                            OnNavigateToPage(props.event.alias+'/login')

                        }}>
                            Sign In 
                        </Link>
                    </h2>
                </div>
            </div>
        </div>
    </section>
        : null}
        {loading || error != '' ?
        <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
            <Loader
                type = 'Oval'
                color = "#00b6ff"
                height = {40}
                width = {40}
            />
        </div> :
        planSelected ?
        <Booking
            event = {props.event}
            data = {{tickets : data,guestEmail: guestEmail}}
            countries = {props.countries}
            nationalities = {props.nationalities}
            onHandleBack = {()=> {
                for(var i=0;i<data.length;i++){
                    data[i].selectedDelegates = 0
                }
                setPlanSelected(false)
            }}
        /> :
        <Content
            tickets = {data}
            pageDescription = {pageDescription}
            onSelectPlan = {(id)=>{
                let ticket = data.find(x=>x.id == id)
                ticket.selectedDelegates = 1
                if(user != null){
                    setPlanSelected(true)
                }else{
                    $('#loginPopup').modal('show');
                }
            }}
            onClickGuestContinue = {OnClickContinueAsGuestPopup}
            onLoginClick = {()=> {
                $('.modal-backdrop').remove();
                OnNavigateToPage(props.event.alias+'/login')
            }}
            onChangeEmail = {(val)=> setGuestEmail(val)}
            eGuestEmail = {eGuestEmail}
            translation = {translation}
        />}
        <Footer2 
        event = {props.event}
            copyRightLogo = {props.event.copyright_logo}
            copyRightText = {props.event.copyright_text}
            copyRightLink = {props.event.copyright_link}
            showCopyRight = {props.event.copyright_text || props.event.copyright_logo}
        />
        </>
    )
}
const mapStateToProps = (state) => ({
    booking: state.bookings.bookingData
});
const mapDispatchToProps = dispatch => ({
    bookingLoaded : data => dispatch(BookingDataLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(MemberShip);
