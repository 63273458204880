import React from 'react'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import { isMobile } from 'react-device-detect';
import {compose} from 'redux';
import { withTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import {Trans} from 'react-i18next'
export class VideoCall extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            partnerVideoLoaded : false
        }
        this.stream = null
        this.userVideo = React.createRef()
        this.partnerVideo = React.createRef()
        this.videoToggle = true
        this._isMounted = false
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+this.props.event.alias) || 'en')
    }
    componentDidMount(){
        $(document).ready(function () {
            // $('.chat-btn').click(function(){
            //     $('.chats').fadeIn(300);
            // }) 
            var height = $(window).height();
            $('.video-frame').css('height', (height - 262) + 'px');
        })
        this._isMounted = true
        $(document).ready(function () {
			var height = $(window).height();
			$('.participants-chat-main').css('height', (height - 150) + 'px');
        })
        if(this.props.isInitiator){ //start stream straight away when user is caller
            this.startStream()
        }
        let pVideo = document.getElementById('partnerVideoPlayer')
        pVideo.onloadeddata = ()=>{
            console.log("loaded")
            this.setState({partnerVideoLoaded : true})
        }
    }
    startStream = (sendSignal = false) => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            if(!this._isMounted){
                return
            }   
            let sendSignalDataToPeer = sendSignal
            if(!sendSignal){
                sendSignalDataToPeer = this.props.caller == null
            }
            this.props.onUserStreamStarted(stream,sendSignalDataToPeer)
            this.stream = stream
            this.userVideo.current.srcObject = stream
        }).catch(err =>{
            alert(err.name)
            console.log(err)
        })
    }
    OnPartnerJoined = (stream) => {
        this.partnerStream = stream
        this.partnerVideo.current.srcObject = stream
    }
    OnEndCall = () => {
        this.props.onEndCall()
    }
    OnDisableVideo = () => {
        this.videoToggle = !this.videoToggle
        // if(!this.videoToggle){
        //     this.stream.getTracks().forEach(function(track) {
        //         if (track.readyState == 'live' && track.kind === "video") {
        //             track.enabled = false
        //         }
        //     });
        // }else{
        //     this.stream.getTracks().forEach(function(track) {
        //         if (track.readyState == 'live' && track.kind === "video") {
        //             track.enabled = true
        //         }
        //     });
        //     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
        //         this.stream = stream
        //         this.userVideo.current.srcObject = stream
        //     })
        //     // this.startStream(true)
        // }
        this.stream.getVideoTracks()[0].enabled = !this.stream.getVideoTracks()[0].enabled
        this.props.onDisableVideo()
    }
    componentWillUnmount(){
        this._isMounted = false
        if(this.stream != null){
            this.stream.getTracks().forEach(function(track) {
                if (track.readyState == 'live') {
                    track.stop();
                }
            });
        }
        if(this.partnerStream != null){
            this.partnerStream.getTracks().forEach(function(track) {
                console.log("partner: "+track.readyState)
                if (track.readyState == 'live') {
                    
                }
                track.stop();
            });
        }
    }
    render(){
        let chatClass = !isMobile ? 'chats' : this.props.showChat ? 'chats' : 'chats-closed'
        let microphoneClass = this.props.userMuted ? ' fa-microphone-slash' : ' fas fa-microphone'
        let videoButtonClass = this.props.videoDisabled ? ' fa-video-slash' : ' fa-video'
        const componentDecorator = (href, text, key) => (
            <a href={href} key={key} style = {{color : 'blue',paddingLeft: 0,maxWidth : '100%'}} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
        return(
            <section class="participants-chat main-chat-area">
   
                <div class="container-fluid px-0 h-100">
                    <div class="row mx-0 position-relative h-100 pb-2">

                        <div class="col-lg-8 col-md-7 px-0 video-main">
                            <div class="video-area">
                                {this.props.showCloseButton ? 
                                <div onClick = {this.OnEndCall} class="video-btn closed">
                                    <img src="/images/ic_chat_close-w.svg" alt="close-icon-btn"/>
                                </div> : null}
                                {/* <div class="video-btn expend">
                                    <i class="fas fa-expand"></i>
                                </div> */}
                                <div class="video-frame">
                                    {!this.state.partnerVideoLoaded ?
                                    <div class = "call-title" style = {{background : 'black',display : 'flex',position : 'absolute',zIndex : 999,justifyContent : 'center',alignItems : 'center',fontSize : 20,height : '100%',width : '100%',color : 'white'}}>
                                        <Trans>connecting</Trans>  <span>.</span> <span>.</span> <span>.</span>
                                    </div>:null}
                                    <video ref = {this.partnerVideo} id="partnerVideoPlayer" style = {{height : '100%',width : '100%'}} playsInline autoPlay muted = {this.props.partnerMuted}/>
                                    {this.props.partnerVideoDisable ?
                                    <div className = "text-center" style = {{background : 'black',position : 'absolute',zIndex : 99,top : 0,display : 'flex',justifyContent : 'center',alignItems : 'center',fontSize : 25,height : '80%',width : '100%',color : 'white'}}>
                                        {this.props.selectedParticipantName}
                                    </div> : null}
                                </div>
                                <div class="video-control bg-dark">
                                    <div class="row mx-0">
                                    <div class="col-12 text-center">
                                        {!isMobile ?
                                        <div style = {{display : 'grid'}} class="call-time">
                                            <span><Trans>callDuration</Trans></span>
                                            {this.props.callDuration}
                                        </div> : null}
                                            <ul class="list-inline mb-0">
                                                <li class="list-inline-item">
                                                    <a style = {{cursor : 'pointer'}} onClick = {this.props.onMuteCall} class="btn mute-unmute">
                                                        <i id = "mute-icon" class={"fas"+microphoneClass}></i>
                                                    </a><br/>
                                                <span><Trans>mute</Trans></span>
                                                </li>

                                                <li class="list-inline-item">
                                                    <a style = {{cursor : 'pointer'}} onClick = {this.OnEndCall} class="btn skip-call">
                                                        <i class="fas fa-phone"></i>
                                                    </a><br/>
                                                    <span><Trans>endCall</Trans></span>
                                                </li>

                                                <li class="list-inline-item ">
                                                    <a style = {{cursor : 'pointer'}} onClick = {this.OnDisableVideo} class="btn cam-btn">
                                                        <i id = "video-icon" class={"fas"+videoButtonClass}></i>
                                                    </a><br/>
                                                    <span> <Trans>video</Trans></span>
                                                </li>
                                                <li class="list-inline-item chat-btn">
                                                    <a style = {{cursor : 'pointer'}} onClick = {this.props.onClickChat} class="btn">
                                                        <i class="far fa-comment-dots text-white"></i>
                                                    </a><br/>
                                                    <span><Trans>chat</Trans></span>
                                                </li>
                                            </ul>
                                            <div class="your-video">
                                                <div class = "y-video-frame">
                                                <video ref = {this.userVideo} id="currentUserVideoPlayer" style = {{height : '100%',width : '100%'}} playsInline muted autoPlay/> :
                                                    {/* {!this.props.videoDisabled ?
                                                    
                                                    <div className = "text-center" style = {{background : 'black',height : '100%',width : '100%',color : 'white'}}>
                                                        
                                                    </div>:null} */}
                                                </div>
                                            <Trans>you</Trans>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-5 px-0 px-md-2 col-chat-mob">
                            <div class = {chatClass}>
                                <div class="chat-card card border-0 rounded-0" style = {{overflow : 'hidden'}}>
                                    <div class="card-header rounded-0 px-3 py-3">
                                        <div class="chat-u-img">
                                            {this.props.selectedParticipantProfileImage == null || this.props.selectedParticipantProfileImage == "" ? 
                                                <img src="/images/profile-placeholder.png" alt="participant_image" /> :
                                                <img src={this.props.selectedParticipantProfileImage} alt="participant_image" />}
                                                {this.props.selectedParticipantOnline ? <div class = "online-chat"></div> : null}
                                        </div>
                                        <h6 class="float-left m-0 ml-2"> 
                                            {this.props.selectedParticipantName}
                                        </h6>
                                        <a style = {{cursor : 'pointer'}} onClick = {this.props.onClickCloseChat} class = "close-icon float-right">
                                            <img src="/images/ic_chat_close.svg" alt="close-icon-btn"/>
                                        </a>
                                        <a style = {{color : 'white'}} class="btn btn-sch">
                                            <Trans>scheduleMeeting</Trans>
                                        </a>
                                    </div>
                                </div>

                                <div class="chat-message-area">
                                    {this.props.loadingSingleChat ?
                                    <div className = "d-flex" style = {{position : 'absolute',justifyContent : 'center',alignItems : 'center',zIndex : 9,height : '100%',width : '100%',background : 'rgb(0 0 0 / 9%)'}}>
                                        <div>
                                            <Loader
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {30}
                                                width = {30}
                                            />
                                        </div>
                                    </div> : null}
                                    <div class="row m-0 p-0 position-relative">
                                        <div class="col-12 m-0 p-0">
        
                                            <div id = "chat-inner" class="card chat-inner rounded-0 p-0 m-0">
                                                <div id="sohbet" class="card border-0 m-0 p-3 position-relative bg-transparent">
                                                    {this.props.chat.map((chatDate,index)=>{
                                                        return(
                                                            <>
                                                            <div className = "date-status">
                                                                {chatDate.date}
                                                            </div>
                                                            {chatDate.chat.map((chat,chatIndex)=>{
                                                                let side = chat.me ? "receive-msg" : "send-msg"
                                                                let float = chat.me ? 'float-right' : 'float-left'
                                                                let senderName = chat.me ? "Me" : chat.senderName
                                                                let time = " "+chat.time
                                                                return(
                                                                    chat.type == "callLog" ? 
                                                                    <div className = "call-log">
                                                                        {time}<br/>
                                                                        <span> <Trans>call</Trans> </span>
                                                                        <span className = "duration">
                                                                            {chat.message}
                                                                        </span>
                                                                    </div>:
                                                                    <div class={"p-2 m-0 position-relative "+side} data-is={time}>
                                                                        <a class={float} style = {{wordBreak : 'break-word'}}>
                                                                            <span>{senderName}</span> <br/>
                                                                            <Linkify componentDecorator={componentDecorator}>
                                                                                {chat.message}
                                                                            </Linkify> 
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })}
                                                            </>
                                                        )
                                                    })}
                                                </div>
        
                                            </div>
        
                                        </div>
        
                                    </div>
        
                                    <div class="row m-0 p-0 position-relative">
                                        <div class="col-12 px-1 py-4 py-md-2 pr-0 enter-text">
                                            <form id = "chat-form" class = "chat-form">
                                                <div class="row m-0 p-0 position-relative">
                                                    <div class="col-10 pl-3 pr-0">
                                                        {/* <input value = {this.props.chatMessage} onChange = {this.props.onInputTextChanged} type="text" class="form-control h-100" placeholder = "Type your message.." /> */}
                                                        <textarea placeholder = {this.translation.typeyourMessage} autoFocus id = "videoChatInput" onChange = {this.props.onInputTextChanged} value = {this.props.chatMessage} class = "form-control h-100" rows="1"></textarea>
                                                    </div>
                                                    <div class="col-2 pl-1 pr-0">
                                                        <button onClick = {this.props.onClickSendMessage} class="btn btn-light w-100 h-100">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.712" height="19.119" 
                                                            viewBox="0 0 20.712 19.119">
                                                                <path d="M3.375,23.619l20.712-9.559L3.375,4.5v7.435l14.837,2.124L3.375,16.184Z"
                                                                transform="translate(-3.375 -4.5)" fill="#333"/>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
        
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                </section>
        )
    }
}
export default compose(withTranslation())(VideoCall)