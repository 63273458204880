import React, { useEffect, useState, useDispatch } from "react";
import utility, {
  countNumberOfUser,
  wordsRestrictor,
  dateExctrator,
} from "../../../Utility/utility";
import "./styles.css";
import { compose } from "redux";
import { connect } from "react-redux";
import { Constants } from "../../../Constants";
import { withRouter } from "react-router-dom";
import { saveForumMessages, deleteForumMessage } from "../actions/index";
import Loader from "react-loader-spinner";
import io from "socket.io-client";
import DeleteModel from "../MsgDeleteModal";
const FoumContent = (props) => {
  const [selectedForum, setSelectedForum] = useState(null);
  const [forumadmin, setForumAdmin] = useState(null);
  const [message, setMessage] = useState("");
  const [defaultMessage, setDefault] = useState("");
  const [forumMessage, setForumMessage] = useState([]);
  const [activeMessage, setActive] = useState(false);
  const [emptydata, setEmptydata] = useState(false);
  const [deletedCurrentItem, setCurrentItem] = useState(false);
  const [currentForumMessageDelete, setDeletedForumMessage] = useState({});
  var user = utility.getLoggedInUser(props.alias);

  useEffect(() => {
    var currentForum = null;
    var forumAdmin = null;
    if (props.forumMessage.length == 0) {
      currentForum = props.forum.find(
        (item) => item.id == props.selectedForumId
      );
      forumAdmin = currentForum.messages.find(
        (item) => item.user_id == props.forumAdminId
      );
    } else {
      currentForum = props.forum.find(
        (item) => item.id == props.selectedForumId
      );
      forumAdmin = currentForum.messages.find(
        (item) => item.user_id == props.forumAdminId
      );
    }
    setForumAdmin(forumAdmin);
    setSelectedForum(currentForum);
    setForumMessage([...currentForum.messages]);
  }, []);
  props.socket &&
    props.socket.on("updateForumMessage", (data) => {
      if (data.topicId == props.selectedForumId) {
        let currentForum = data.data.find(
          (item) => item.id == props.selectedForumId
        );
        let forumAdmin = currentForum.messages.find(
          (item) => item.user_id == props.forumAdminId
        );
        setForumAdmin(forumAdmin);
        setSelectedForum(currentForum);
        setForumMessage([...currentForum.messages]);
      }
    });
  const deleteForumMsg = (id, forumMsgId,message) => {
    let mainData = [...props.forumdata];
    let selectedFilter = mainData.map((item) => {
      if (item.id == id) {
        let filterMessage = message.filter(item => item.id != forumMsgId)
        return { ...item, messages: [...filterMessage] };
      } else {
        return item;
      }
    });
    let params = {
      messageId: forumMsgId,
    };
    props.deleteForumMessage(
      props.alias,
      params,
      user.token,
      selectedFilter,
      id,
      props.selectdeForumIndex
    );
  };

  const clearForumData = () => {
    setDeletedForumMessage({});
  };

  const activeMessageDelete = () => {
    setCurrentItem(true);
  };
  useEffect(() => {
    if (props.loadingSaveForum == false && activeMessage == true) {
      var currentForum = props.forumMessageData.find(
        (item) => item.id == props.selectedForumId
      );
      var forumAdmin = currentForum.messages.find(
        (item) => item.user_id == props.forumAdminId
      );
      setForumAdmin(forumAdmin);
      setSelectedForum(currentForum);
      setForumMessage([...currentForum.messages]);
      setMessage("");
      setActive(false);
      props.socket &&
        props.socket.emit("updateForumMessage", {
          topicId: props.selectedForumId,
          data: props.forumMessageData,
        });
    }
  }, [props.forumMessageData]);

  useEffect(() => {
    if (props.deleteMsgLoading == false && deletedCurrentItem == true) {
      var currentForum = props.forumMessageData.find(
        (item) => item.id == props.selectedForumId
      );
      var forumAdmin = currentForum.messages.find(
        (item) => item.user_id == props.forumAdminId
      );
      setForumMessage([...currentForum.messages]);
      setCurrentItem(false);
      document.getElementById("forumDeleteMsg").click();
      props.socket &&
        props.socket.emit("updateForumMessage", {
          topicId: props.selectedForumId,
          data: props.forumMessageData,
        });
    }
  }, [props.forumMessageData]);
  const getClasses = ()=>{
    let user = utility.getLoggedInUser(props.alias)
    if(user == null){
        return 'telehealthBooking'
    }
    else if(user != null && user.isBooking == false){
        return 'telehealthBooking'
    }
    else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
      return 'telehealthBooking'
  }
    else{
        return ''
    }
  }
  return (
    <>
      <DeleteModel
        id="deleteMsg"
        forumInfo={currentForumMessageDelete}
        deleteMsgLoading={props.deleteMsgLoading}
        forumdata={props.forumMessageData}
        clearForumData={clearForumData}
        deleteForumMsg={deleteForumMsg}
        activeMessageDelete={activeMessageDelete}
      />

      {user != null && user.isBooking ? (
        <>
          <section class={`telehealth-inner-banner   videos-banner py-3 bg_primary`}>
            <div class="container py-2">
              <div class="row">
                <div class="col-12">
                  <button
                    class="btn btn-back"
                    onClick={() => {
                      props.showDetailedPage(false);
                    }}
                  >
                    <span>&#x2039;</span> Topics
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section class="topic-comment-sec py-3 py-md-5">
            <div class="container pb-3 pb-md-5">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <h5>{selectedForum && selectedForum.title}</h5>
                  <div class="tc-user-sec">
                    <div class="c-user">
                      {selectedForum &&
                      selectedForum.user.profileImageUrl == null ? (
                        <img
                          src="/images/logos/default-user.jpg"
                          alt="c-user"
                        />
                      ) : (
                        <img
                          src={
                            selectedForum && selectedForum.user.profileImageUrl
                          }
                          alt="c-user"
                        />
                      )}
                    </div>
                    <span class="tc-name">
                      {selectedForum &&
                        selectedForum.user.firstName +
                          " " +
                          selectedForum.user.lastName}
                    </span>
                    <span class="badge badge-info mx-2">Author</span>
                    <span class="msg mx-2">
                      <i class="fas fa-comment-dots mr-1"></i>
                      {selectedForum && selectedForum.messages.length}
                    </span>
                    <span class="share mx-2">
                      <i class="fas fa-users mr-1"></i>
                      {selectedForum &&
                        countNumberOfUser(selectedForum.messages)}
                    </span>
                    <span class="t-date ml-2">
                      Created At :
                      {selectedForum && dateExctrator(selectedForum.created_at)}
                    </span>
                  </div>

                  <p class="comment-text">
                    {selectedForum && selectedForum.description}
                  </p>

                  <div class="comment-post-area mt-4 p-3 mb-3 w-100">
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        value={message}
                        placeholder="Type Your Comment"
                        rows="4"
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        maxLength={2000}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            e.preventDefault();
                            if (message.trim().length > 0) {
                              var params = {
                                topicId: selectedForum.id,
                                message: message,
                                mailNotificationsEnabled: 0,
                              };
                              var filterData = props.forum.filter(
                                (item) => item.id != props.selectedForumId
                              );
                              var filterValueid = null;
                              props.forum.map((item, index) => {
                                if (item.id == props.selectedForumId) {
                                  filterValueid = index;
                                }
                              });
                              setActive(true);
                              props.saveForumMessages(
                                props.alias,
                                params,
                                user.token,
                                filterData,
                                filterValueid
                              );
                            } else {
                              let emptySpaces = message.replace(/\s/g, "");
                              setMessage(emptySpaces);
                              setEmptydata(true);
                              setTimeout(() => {
                                setEmptydata(false);
                              }, 3000);
                            }
                          }
                        }}
                      ></textarea>
                    </div>
                    <div class="form-group mb-0 row">
                      <div class="col-md-8 mb-3 mb-md-0">
                        {/* <div class="form-check">
								<input type="checkbox" class="form-check-input" id="exampleCheck2" />
								<label class="form-check-label" for="exampleCheck2">
									I have read and agree to the <a href = "#">Community Guidelines</a>
								</label>
							</div> */}
                      </div>
                      <div
                        class={`${
                          emptydata == true ? "col-md-12" : "col-md-4"
                        } text-md-right`}
                        style={{
                          display: "flex",
                          columnGap: "5px",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {props.loadingSaveForum == true ? (
                          <div
                            style={{
                              left: "50%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Loader
                              type="Oval"
                              color="#00b6ff"
                              height={25}
                              width={25}
                            />
                          </div>
                        ) : null}
                        {emptydata == true ? (
                          <div
                            style={{ marginTop: "10px" }}
                            class="alert alert-danger mr-2 ml-2"
                            role="alert"
                            id="error-message"
                          >
                            Please write your actual msg first....
                          </div>
                        ) : null}
                        <button
                          class="btn btn-post telehealth-btn"
                          style={{
                            pointerEvents: message == "" ? "none" : "auto",
                          }}
                          onClick={() => {
                            if (message.trim().length > 0) {
                              var params = {
                                topicId: selectedForum.id,
                                message: message,
                                mailNotificationsEnabled: 0,
                              };
                              var filterData = props.forum.filter(
                                (item) => item.id != props.selectedForumId
                              );
                              let filterValueid = null;
                              props.forum.map((item, index) => {
                                if (item.id == props.selectedForumId) {
                                  filterValueid = index;
                                }
                              });
                              setActive(true);
                              props.saveForumMessages(
                                props.alias,
                                params,
                                user.token,
                                filterData,
                                filterValueid
                              );
                            } else {
                              let emptySpaces = message.replace(/\s/g, "");
                              setMessage(emptySpaces);
                              setEmptydata(true);
                              setTimeout(() => {
                                setEmptydata(false);
                              }, 3000);
                            }
                          }}
                        >
                          Post Your Comment
                        </button>
                      </div>
                    </div>
                  </div>
                  {forumMessage &&
                    forumMessage.map((item) => {
                      return (
                        <div class="previous-comment pt-3 position-relative">
                          <div class="tc-user-sec">
                            <div class="c-user">
                              {item.user.profileImageUrl == null ? (
                                <img
                                  src="/images/logos/default-user.jpg"
                                  alt="c-user"
                                />
                              ) : (
                                <img
                                  src={item.user.profileImageUrl}
                                  alt="c-user"
                                />
                              )}
                            </div>
                            <span class="tc-name">
                              {item.user.firstName + " " + item.user.lastName}
                            </span>
                            <span class="t-date mx-2">
                              {dateExctrator(item.updated_at)}
                            </span>
                            {user && user.userRole == "organizer" ? (
                              <div
                                class="delete-topic-btn"
                                data-toggle="modal"
                                data-target="#deleteMsg"
                                onClick={() => {
                                  setDeletedForumMessage({
                                    forumId: props.selectedForumId,
                                    msgId: item.id,
                                    forum: props.forumMessageData,
                                    userid: item.user.id,
                                    title: item.message,
                                    name:
                                      item.user.firstName +
                                      " " +
                                      item.user.lastName,
                                      forumMessage:forumMessage,
                                    profile: item.user.profileImageUrl,
                                  });
                                  activeMessageDelete();
                                }}
                              >
                                <img
                                  src="/images/telehealth-images/ic_trash.svg"
                                  alt="Delete"
                                />
                              </div>
                            ) : null}
                          </div>

                          <p class="comment-text">{item.message}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section class={`telehealth-inner-banner videos-banner py-3 bg_primary`}>
            <div class="container py-2">
              <div class="row">
                <div class="col-12">
                  <button
                    class="btn btn-back"
                    onClick={() => {
                      props.showDetailedPage(false);
                    }}
                  >
                    <span>&#x2039;</span> Topics
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section class="topic-comment-sec py-3 py-md-5">
            <div class="container pb-3 pb-md-5">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <h5>{selectedForum && selectedForum.title}</h5>
                  <div class="tc-user-sec">
                    <div className="c-user">
                      {selectedForum &&
                      selectedForum.user.profileImageUrl == null ? (
                        <img
                          src="/images/logos/default-user.jpg"
                          alt="c-user"
                        />
                      ) : (
                        <img
                          src={
                            selectedForum && selectedForum.user.profileImageUrl
                          }
                          alt="c-user"
                        />
                      )}
                    </div>
                    <span class="tc-name">
                      {selectedForum &&
                        selectedForum.user.firstName +
                          " " +
                          selectedForum.user.lastName}
                    </span>
                    <span class="badge badge-info mx-2">Author</span>
                    <span class="msg mx-2">
                      <i class="fas fa-comment-dots mr-1"></i>
                      {selectedForum && selectedForum.messages.length}
                    </span>
                    <span class="share mx-2">
                      <i class="fas fa-users mr-1"></i>
                      {selectedForum &&
                        countNumberOfUser(selectedForum.messages)}
                    </span>
                    <span class="t-date ml-2">
                      Last update :{" "}
                      {selectedForum && dateExctrator(selectedForum.updated_at)}
                    </span>
                  </div>

                  <p class="comment-text">
                    {selectedForum && selectedForum.description}
                  </p>

                  <div class="comment-post-area border mt-4 p-3 mb-3 w-100 login-req">
                    <div class="form-group text-center mb-0">
                      <p> Post a comment </p>
                      {user != null ? null : (
                        <button
                          class="btn btn-post telehealth-btn"
                          onClick={() => {
                            props.OnNavigateToPage(`${props.alias}/login`);
                          }}
                        >
                          Login
                        </button>
                      )}
                      <button
                        class="btn btn-post-outline"
                        onClick={() => {
                          props.OnNavigateToPage(`${props.alias}/membership`);
                        }}
                      >
                        Become a member
                      </button>
                    </div>
                  </div>

                  <div class="before-login">
                    {forumMessage &&
                      forumMessage.map((item) => {
                        return (
                          <div class="previous-comment pt-3 position-relative">
                            <div class="tc-user-sec">
                              <div class="c-user">
                                {item.user.profileImageUrl == null ? (
                                  <img
                                    src="/images/logos/default-user.jpg"
                                    alt="c-user"
                                  />
                                ) : (
                                  <img
                                    src={item.user.profileImageUrl}
                                    alt="c-user"
                                  />
                                )}
                              </div>
                              <span class="tc-name">
                                {item.user.firstName +
                                  " " +
                                  item.user.firstName}
                              </span>
                              <span class="t-date mx-2">
                                {dateExctrator(item.updated_at)}
                              </span>
                            </div>

                            <p class="comment-text">{item.message}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loadingSaveForum: state.marketPlaceReducer.loadingSaveForum,
  forumdata: state.marketPlaceReducer.forumdata,
  forumMessageData: state.marketPlaceReducer.forumMessageData,
  deleteMsgLoading: state.marketPlaceReducer.deleteMsgLoading,
});
const mapDispatchToProps = (dispatch) => ({
  saveForumMessages: (alias, params, token, data, id) =>
    dispatch(saveForumMessages(alias, params, token, data, id)),
  deleteForumMessage: (eventAlias, param, token, data, id, index) =>
    dispatch(deleteForumMessage(eventAlias, param, token, data, id, index)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FoumContent);
