export const EventNumbersLoaded = data => ({
    type: 'EVENT_NUMBERS',
    payload : data
})
export const FloatingPlayerAdded = data => ({
    type: 'FLOATING_PLAYER',
    payload : data
})

export const AgendaSessionId = data=>({
    type:"AGENDA_SESSION_ID",
    payload:data
})

export const OnlineUser = data =>({
    type:"ONLINE_USER",
    payload:data
})

export const allFeatureData = (data)=>(
    {
    type:"ALL_EVENT_FEATURE",
    payload:data
})

export const naviagtionAgneda = (data)=>(
    {
        type:'AGENDA_NAVIGATION',
        payload:data
    }
)