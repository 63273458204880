import React from 'react';
import {Trans} from 'react-i18next'
export const CallingPopup = (props)=>{
    return(
        <div class="modal" id="callingUser" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content text-center incoming-call">
                    <div class="modal-body px-3 py-4 incoming-call-body">
                            <p class = "call-title">
                                <Trans>callTo</Trans> <span>.</span> <span>.</span> <span>.</span>
                            </p>

                            <div class="caller-img my-3 my-md-4">
                                {props.receiverImageUrl == null || props.receiverImageUrl == "" ? 
                                    <img src="/images/profile-placeholder.png" alt="participant_image" /> :
                                    <img src={props.receiverImageUrl} alt="participant_image" />}
                            </div>

                            <p class = "caller-name mb-4 mb-md-5">
                                {props.receiverName}
                            </p>

                            <div class="call-action">
                                <ul class="list-inline mt-3">

                                    <li class="list-inline-item mx-3 mx-md-4">
                                        <div onClick = {props.onRejectCall} class = "call-btn reject-btn call-to-rej" data-dismiss = "modal">
                                            <i class="fas fa-phone"></i>
                                        </div>
                                        <br/>
                                        <span><Trans>cancel</Trans></span>
                                    </li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}