import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../SpeakerV1/api';
import { MDBCollapse } from "mdbreact";
import $ from 'jquery'
import 'bootstrap'
import {isMobile} from 'react-device-detect'
import {Trans} from 'react-i18next'
export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            speakers: this.props.speakers,
            collapseID : ''
        };
        this.selectedIndex = ""
        this.prevSelectedIndex = ""
        this.indicatorsIndexArray = []
    }
    componentDidMount(){
        $('.angle-indicator').click(function(){
            // $(this).find('.fas').toggleClass('fa-sort-down fa-sort-up');
        });
        $(".bbb").on('mouseenter mouseleave', '.card_inner', function (evt) {
            var e = evt || window.event;
            switch (e.type || e.which) {
                case "click" && !isMobile:
                    // if (!($(this).find('.angle-indicator').hasClass('stayOpen'))) {
                    //     $('.stayOpen').removeClass('stayOpen').hide();
                    //     $(this).find('.angle-indicator').addClass('stayOpen');
                    // } else {
                    //     $(this).find('.angle-indicator').removeClass('stayOpen').hide();
                    // }
                    // break;
                // case "mouseenter":
                //     $(this).find('.angle-indicator').not('.stayOpen').addClass('open').show();
                //     break;
                // case "mouseleave":
                //     $(this).find('.angle-indicator').not('.stayOpen').removeClass('open').hide();
                //     break;
                // default:
                //     break;
            }
        });
        
    }
    OnMouseEnter = (parentIndex,index) => {
        if(isMobile){
            return
        }
        var speakerItem = this.props.speakers[parentIndex][index]
        $('#indicator'+parentIndex+index).not('.stayOpen').addClass('open').show();

    }
    OnMouseLeave = (parentIndex,index) => {
        if(isMobile){
            return
        }
        if($('#fas'+parentIndex+index).hasClass('fa-sort-up')){
            return
        }
        $('#indicator'+parentIndex+index).not('.stayOpen').addClass('open').hide();
    }
    OnClickIndicator = (parentIndex,index) => {

    }
    toggleCollapse = (collapseID,closeClicked = false) => {
        var selectedIndex = collapseID.substring(collapseID.length-2)
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }),()=>{
            if(this.state.collapseID != ""){
                this.scrollPosition = window.scrollY
            }else{
                if(isMobile){
                    window.scrollTo(0,this.scrollPosition)
                }
            }
            if(closeClicked && !isMobile){
                $('#indicator'+selectedIndex).css("display", "none");
            }
        });
        if(this.indicatorsIndexArray.indexOf(selectedIndex) < 0){
            this.indicatorsIndexArray.push(selectedIndex)
        }
        for(var i=0;i<this.indicatorsIndexArray.length;i++){
            var index = this.indicatorsIndexArray[i]
            if(index == selectedIndex){
                $('#fas'+index).toggleClass('fa-sort-down fa-sort-up')
                if($('#card_inner'+index).hasClass('selected')){
                    $('#card_inner'+selectedIndex).removeClass('selected')
                }else{
                    $('#card_inner'+selectedIndex).addClass('selected')
                }
            }
            else{
                if($('#fas'+index).hasClass('fa-sort-up')){
                    $('#fas'+index).toggleClass('fa-sort-down fa-sort-up')
                    $('#indicator'+index).not('.stayOpen').addClass('open').hide();
                }
                $('#card_inner'+index).removeClass('selected')
            }
        }
    }
    render(){
        let centerClass = this.props.otherPageContent ? " justify-content-center" : ""
		return(
		<section class = "speaker_detail py-4 bg-light">
            {this.props.otherPageContent ? 
		<h5 class = "text-center font-weight-bold text-uppercase">{this.props.speakerHeading != null ? this.props.speakerHeading :"Speakers"}</h5> :
        <div className="container">
        <div className="row py-0 pb-md-3 pb-3">
            <div className="col-sm-6">
                <h4 className = "font-weight-bold text-center text-md-left text-uppercase">{this.props.speakerHeading != null ? this.props.speakerHeading : "Speakers"}</h4>
            </div>
            {/* <div className="col-sm-6 text-left text-center text-md-right mt-3 mt-lg-0">
                <span onClick = {this.props.onClickBookNow} className = "btn bg_primary">
                    <Trans>bookNow</Trans> <i className="fas fa-angle-right ml-2"></i>
                </span>
            </div> */}
        </div>
        </div>}
		{this.props.speakers.map((item,parentIndex)=>{
            return(
                <div class="container">
                <div class= {!isMobile ? "row pt-1 mb-3"+centerClass : "row"+centerClass} id="accordion">
                    {this.props.speakers[parentIndex].map((item,index)=>{
                        return(
                            <>
                            <div onMouseEnter = {()=>this.OnMouseEnter(parentIndex,index)} onMouseLeave = {()=> this.OnMouseLeave(parentIndex,index)} class="col-md-6 col-lg-3 mb-3 mb-lg-0 bbb test" id = "test1">
                            <div id={"card_inner"+parentIndex+index} class = "card_inner py-3 px-2 rounded text-center h-100 border">
                                <div class = "user_img">
                                    {item.image != null ? 
                                    <img src = {item.image} class = "rounded-circle" alt = "user_1-img"/> :
                                    <img src = '/images/profile-placeholder.png' class = "rounded-circle" alt = "user_1-img"/>
                                }
                                </div>
                                <div class = "user_caption mt-4">
                                    <h6 class = "font-weight-bold">{item.name + " "+(item.surname == null ? "" : item.surname)}</h6>
                                    <p class = "mb-0">{item.job_title}</p>
                                    <p class = "mt-2 f-600">{item.company}</p>
                                </div>
                                {item.description != null || item.linkedin != null?
                                <div class = "angle-indicator" id = {"indicator"+parentIndex+index} onClick = {()=>this.toggleCollapse("collapsibleNavbar"+parentIndex+index)}>
                                    <div class = "angle-down bg_secondary">
                                        <i id={"fas"+parentIndex+index} class="fas fa-sort-down text-light font-weight-bold"></i>
                                    </div>
                                </div> : null}
                            </div>
                            </div>
                            {isMobile ? <MDBCollapse className = "mb-3 col-12 event-collapse" id = {"collapsibleNavbar"+parentIndex+index} isOpen = {this.state.collapseID == "collapsibleNavbar"+parentIndex+index}>
                                <div class = "p-4 rounded sp_inner position-relative">
                                    <p class = "my-3">
                                        {ReactHtmlParser(item.description)}
                                    </p>
                                    {item.facebook != null || item.twitter != null || item.linkedin!=null ?
                                    <p class = "f-600">
                                        Follow Social Link: 
                                        <span class = "ml-3">
                                        {item.facebook != null ?
                                        <a href = {item.facebook} target = "_blank"><i class="fab fa-facebook-f mr-2"></i></a> : null}
                                        {item.twitter != null ?
                                        <a href = {item.twitter} target = "_blank"><i class="fab fa-twitter mr-2"></i></a> : null}
                                        {item.linkedin != null ?
                                        <a href = {item.linkedin} target = "_blank"><i class="fab fa-linkedin-in mr-2"></i></a> : null}
                                        </span>
                                    </p> : null}
                                    <div class = "angle-close" onClick = {()=>this.toggleCollapse("collapsibleNavbar"+parentIndex+index,true)}>
										<div class = "angle-close-ic bg_secondary">
											<i class="fas fa-times text-light font-weight-bold"></i>
										</div>
									</div>
                                </div>
                            </MDBCollapse> : null}
                            </>
                        )
                    })}
                    {!isMobile ? <div className ={ `$container ${isMobile ? 'p-4':''} d-none d-md-block`}>
                    {this.props.speakers[parentIndex].map((item,index)=>{
                        return(
                            <MDBCollapse className = "row mb-3 event-collapse" id = {"collapsibleNavbar"+parentIndex+index} isOpen = {this.state.collapseID == "collapsibleNavbar"+parentIndex+index}>
                                <div className = "col-12">
                                    <div class = "p-4 rounded sp_inner">
                                        <p class = "my-3">
                                            {ReactHtmlParser(item.description)}
                                        </p>
                                        {item.facebook != null || item.twitter != null || item.linkedin!=null ?
                                        <p class = "f-600">
                                            <Trans>followSocial</Trans>: 
                                            <span class = "ml-3">
                                            {item.facebook != null ?
                                            <a href = {item.facebook} target = "_blank"><i class="fab fa-facebook-f mr-2"></i></a> : null}
                                            {item.twitter != null ?
                                            <a href = {item.twitter} target = "_blank"><i class="fab fa-twitter mr-2"></i></a> : null}
                                            {item.linkedin != null ?
                                            <a href = {item.linkedin} target = "_blank"><i class="fab fa-linkedin-in mr-2"></i></a> : null}
                                            </span>
                                        </p> : null}
                                        <div class = "angle-close" onClick = {()=>this.toggleCollapse("collapsibleNavbar"+parentIndex+index,true)}>
                                            <div class = "angle-close-ic bg_secondary">
                                                <i class="fas fa-times text-light font-weight-bold"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </MDBCollapse> 
                        )})}
                    </div> : null}
                        
                    </div>
                    {/* </div> */}
                </div>
            )
        })}
	    </section>
		)
	}
}

export default Content;