import React from 'react';
import $ from 'jquery';
import 'bootstrap'
import utility from '../../../Utility/utility';
import Loader from 'react-loader-spinner'
import { MDBCollapse } from "mdbreact";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Constants } from '../../../Constants';
import {Trans} from 'react-i18next'
export class FourthStep extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tickets : this.props.tickets || [],
            selectedOption : {},
            eIntention : '',
            eBillingAddress1 : '',
            eEmail : '',
            eTelephone : '',
            eCity : '',
            eCompanyName : '',
            eCountry : '',
            collapseID : '',
            showInvoiceFormOnly : false,
            selectedPayment:null
        }
        this.onlyInvoiceTickets = []
    }
    editedFields = {}
    componentDidMount(){
        // $("#invoice").prop("checked", true);
        if(this.props.order.totalPrice == 0){
            window.scrollTo(0,0)
            this.props.confirmBooking()
        }else{
            let selectedOption = this.props.selectedOption
            if(selectedOption != null){
                this.setState({selectedOption})
            }else{
                // selectedOption = {
                //     email : this.props.userEmail
                // }
            }
        }
        this.totalPrice = 0
        let showInvoiceFormOnly = false
        this.activePackages = this.props.order.packages.filter(x=>x.isActive == 1)
        this.onlyInvoiceTickets = this.activePackages.filter(x=>x.isInvoice== 1)
        if(this.onlyInvoiceTickets.length > 0){
            this.setState({collapseID : 'invoice'})
            showInvoiceFormOnly = true
            for(var i=0;i<this.onlyInvoiceTickets.length;i++){
                this.totalPrice += this.onlyInvoiceTickets[i].subTotal
            }
            this.totalPrice = Math.round((this.totalPrice + (this.totalPrice * this.props.order.vatPercent/100))*100)/100
        }else{
            this.totalPrice = this.props.order.totalPrice
        }
        this.setState({showInvoiceFormOnly},()=>{
            window.scrollTo(0,0)
        })
    }
    toggleCollapse = (collapseID,paymentId) => {
        // this.paymentId = paymentId
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
        if(paymentId ==  this.state.selectedPayment){
            this.setState({selectedPayment:null})
            this.paymentId = null

        }else{
        this.setState({selectedPayment:paymentId})
        this.paymentId = paymentId
        }
    }
    componentDidUpdate(preProps,preState){
        if(this.props.invoiceSave == true){
            this.OnInvoiceSubmitted()
            this.props.onInvoiceSave()
        }

    }
    OnChangeValue = (key,event) => {
        if(event.target == null){
            return
        }
        var selectedOption = this.state.selectedOption
        selectedOption[key] = event.target.value
        this.setState({selectedOption})
        this.editedFields[key] = true
    }
    renderCountryOptions = () => {
        var options = []
        for(var i=0;i<this.props.countries.length;i++){
            options.push(<option>{this.props.countries[i]}</option>)
        }
        return options
    }
    OnChangeCountry = () => {
        var value = document.getElementById("country_selector").value
        var selectedOption = this.state.selectedOption
        selectedOption.country = value
        this.setState({selectedOption})
        this.editedFields.country = true
    }
    OnChangeUseCompanyDetails = () => {
        var val = document.getElementById('companyDetailCheck').checked
        if(val){
            var selectedOption = this.state.selectedOption
            let packageIndex = 0
            for(var i=0;i<this.props.order.packages.length;i++){
                if(this.props.order.packages[i].isActive == 1 && this.props.order.packages[i].delegates.length > 0){
                    packageIndex = i
                    break
                }
            }
            selectedOption.email = this.props.userEmail
            selectedOption.companyName = this.props.order.packages[packageIndex].delegates[0].companyName
            selectedOption.billingAddress1 = this.props.order.packages[packageIndex].delegates[0].address1
            selectedOption.billingAddress2 = this.props.order.packages[packageIndex].delegates[0].address2
            selectedOption.telephone = this.props.order.packages[packageIndex].delegates[0].companyTelephone
            selectedOption.city = this.props.order.packages[packageIndex].delegates[0].city
            selectedOption.country = this.props.order.packages[packageIndex].delegates[0].country || this.props.countries[0]
            selectedOption.postCode = this.props.order.packages[packageIndex].delegates[0].zipCode

            this.setState({selectedOption})
        }else{
            var selectedOption = this.state.selectedOption
            if(!this.editedFields.companyName){
                selectedOption.companyName = ""
                this.editedFields.companyName = false
            }
            if(!this.editedFields.billingAddress1){
                selectedOption.billingAddress1 = ""
                this.editedFields.billingAddress1 = false
            }
            if(!this.editedFields.billingAddress2){
                selectedOption.billingAddress2 = ""
                this.editedFields.billingAddress2 = false
            }
            if(!this.editedFields.city){
                selectedOption.city = ""
                this.editedFields.city = false
            }
            if(!this.editedFields.country){
                selectedOption.country = ""
                this.editedFields.country = false
            }
            if(!this.editedFields.postCode){
                selectedOption.postCode = ""
                this.editedFields.postCode = false
            }
            if(!this.editedFields.email){
                selectedOption.email = ""
                this.editedFields.email = false
            }
            if(!this.editedFields.telephone){
                selectedOption.telephone = ""
                this.editedFields.telephone = false
            }
            this.setState({selectedOption})
        }
    }
    OnContinueClick = () => {
        if(this.state.collapseID.indexOf('invoice') < 0 ){
            var data = {
                id : this.paymentId,
                totalPrice : this.totalPrice
            }
            this.props.onProceed(data,true)
            return
        }
        var selectedOption = this.state.selectedOption
        var infoCompleted = true
        selectedOption.billingAddress2 = selectedOption.billingAddress2 == null ? "" : selectedOption.billingAddress2
        selectedOption.postCode = selectedOption.postCode == null ? "" : selectedOption.postCode
        selectedOption.purchaseOrderNo = selectedOption.purchaseOrderNo == null ? "" : selectedOption.purchaseOrderNo
        selectedOption.country = selectedOption.country == null ? document.getElementById("country_selector").value : selectedOption.country

        if(selectedOption.sendTointentionOf == null || selectedOption.sendTointentionOf == ""){
            infoCompleted = false
            this.setState({eIntention : "enter valid intention"})
        }
        else{
            this.setState({eIntention : ''})
        }
        if(selectedOption.email == null || !utility.validateEmail(selectedOption.email)){
            infoCompleted = false
            this.setState({eEmail : "enter valid email"})
        }
        else{
            this.setState({eEmail : ''})
        }
        if(selectedOption.telephone == null || !utility.validatePhone(selectedOption.telephone)){
            infoCompleted = false
            this.setState({eTelephone : "enter valid email"})
        }
        else{
            this.setState({eTelephone : ''})
        }
        if(selectedOption.billingAddress1 == null || selectedOption.billingAddress1 == ""){
            infoCompleted = false
            this.setState({eBillingAddress1 : "enter valid email"})
        }
        else{
            this.setState({eBillingAddress1 : ''})
        }
        if(selectedOption.city == null || selectedOption.city == ""){
            infoCompleted = false
            this.setState({eCity : "enter valid email"})
        }
        else{
            this.setState({eCity : ''})
        }
        if(selectedOption.companyName == null || selectedOption.companyName == ""){
            infoCompleted = false
            this.setState({eCompanyName : "enter valid email"})
        }
        else{
            this.setState({eCompanyName : ''})
        }
        var data = {
            selectedInfo : selectedOption,
            type : 'invoice',
            totalPrice : this.totalPrice,
            onlyInvoiceTickets : this.state.showInvoiceFormOnly && this.activePackages.length != this.onlyInvoiceTickets.length
        }
        this.props.onProceed(data,infoCompleted)
    }
    OnClickBack = () => {
        this.props.onClickGoBack(this.state.selectedOption)
    }
    OnInvoiceSubmitted = () => {
        this.totalPrice = 0
        let ticketsWithoutInvoiceType = this.props.order.packages.filter(x => !this.onlyInvoiceTickets.includes(x))
        for(var i=0;i<ticketsWithoutInvoiceType.length;i++){
            this.totalPrice += ticketsWithoutInvoiceType[i].subTotal
        }
        this.totalPrice = Math.round((this.totalPrice + (this.totalPrice * this.props.order.vatPercent/100))*100)/100
        this.setState({showInvoiceFormOnly : false,collapseID : ''})
    }
    render(){
        return(
            <div class = "content_b">
            {this.props.order.totalPrice > 0 ?
            <div id = "accordion" class = "mt-5">
                {!this.state.showInvoiceFormOnly ?
                <>
                {this.onlyInvoiceTickets.length > 0 ?
                <div className = "container">
                    <div style = {{fontSize : 23,textDecoration : 'underline'}} className = "text-center text-success f-600 mb-4"><Trans>invoiceTicketSuccess</Trans></div>
                    <div className = "text-center mb-3 f-400"><Trans>payRemainingTicket</Trans></div>
                </div> : null}
                <section class = "delegate-details mb-3">
                    <div class="container bg-light border py-2">
                        <h6 class = "text-uppercase f-600 mt-3 mb-3"><Trans>payHow</Trans></h6>
                        <div class="row  py-3">
                            <div class="col-md-4">
                            {/* {this.props.paymentMethods.map((item,index)=>{
                                var itemObj = item
                                if(itemObj.type == "telr"){
                                    itemObj.name = "Credit Card"
                                    itemObj.company_manage = 1
                                }
                                return(
                                    <label class="cm_cont" data-toggle="collapse" href="#div2">{itemObj.company_manage == 0 ? itemObj.name.trim() + ' (Managed by MWAN)' : itemObj.name}
                                    <input id = {item.type} type="radio" name="radio" onClick = {()=>this.toggleCollapse(itemObj.type+index,itemObj.id)}/>
                                    <span class="checkmark">
                                        <div></div>
                                    </span>
                                    </label>
                                    )
                                })}    */}
                                <div className="payment-conatiner">
                                {this.props.paymentMethods.map((item,index)=>{
                                   console.log(item)
                                   var itemObj = item
                                   if(itemObj.type == "telr"){
                                       itemObj.name = "Credit Card"
                                       itemObj.company_manage = 1
                                   }
                                    return <div className="payment-method">
                                    <label for={itemObj.id}>{itemObj.name}</label>
                                    <input type="radio" id= {itemObj.id} checked={this.state.selectedPayment == itemObj.id ? true:false} onClick = {()=>this.toggleCollapse(itemObj.type+index,itemObj.id)}/>
                                    </div>
                                }
                                )
                                    
                                }
                                </div>
                            </div> 
                        </div> 
                    </div>
                </section>
                </> : 
                <div className = "container">
                    <div className = "text-center f-600"><Trans>invoiceOnlyTicket</Trans></div>
                </div>}
                <MDBCollapse className = "order-form py-3 event-collapse" isOpen = {this.state.collapseID.indexOf("invoice") < 0 && this.state.collapseID != ""}>
                    <div class="container">
                    <section class = "order-form py-3">

                            <div class = "container">
                                
                                <div class = "row d-none-mob">
                                
                                    <div class="col-md-6 mb-3 mb-sm-0">
                                        <a onClick = {this.OnClickBack} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',marginRight : 5}} class="btn btn-default f-600">
                                        <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans> 
                                        </a>
                                    </div>
                                    
                                    <div class="col-md-6 text-sm-right">
                                        <a onClick = {this.OnContinueClick} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',marginLeft : 5}}  class="btn btn-default f-600">
                                            
                                            <Trans>submit</Trans><i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                        </a>
                                    </div>
                                    
                                </div>
                                
                                <div class = "row d-block d-md-none">
                                
                                    <div class="col-12 mb-3 mb-sm-0 text-center">
                                    
                                        <a onClick = {this.OnClickBack} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',marginRight : 5}} class="btn btn-default f-600">
                                        <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans>
                                        </a>
                                        
                                        <a onClick = {this.OnContinueClick} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',marginRight : 5}} class="btn btn-default f-600">
                                        <Trans>submit</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                        </a>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>

                        </section>
                    </div>
                </MDBCollapse>
                
                <MDBCollapse className = "order-form py-3event-collapse" isOpen = {this.state.collapseID.indexOf("invoice") >= 0}>
                {/* <MDBCollapse class = "order-form py-3 collapse show" id = "div2" data-parent="#accordion"> */}
                    <div class="container">
                        <div class="row mt-3 bg-light px-3 py-3 border">
                                <div class="col-md-12 mt-3">
                                    <p><Trans>forBankTransfer</Trans></p>
                                    <p class="f-14"><Trans>provideAll</Trans> <span class="text-red f-600">( <i class="fas fa-star-of-life"></i> )</span> <Trans>infoBelow</Trans></p>
                                <label class="form-check-label check-cont mr-4">
                                        <input id = "companyDetailCheck" onChange = {this.OnChangeUseCompanyDetails} class="form-check-input" type="checkbox"/>
                                        <Trans>useCompanyDetails</Trans>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                
                                    <div class = "col-md-6">
                                    
                                        <div class="form-group">
                                            <label className = {this.state.eIntention != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> <Trans>sendInvoice</Trans></label>
                                            <input
                                                id = "attentionOf"
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.sendTointentionOf == null ? '' : this.state.selectedOption.sendTointentionOf}
                                                onChange = {(e)=>this.OnChangeValue('sendTointentionOf',e)}
                                                type="text"
                                                class={this.state.eIntention != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}
                                                />
                                        </div>
                                        
                                        <div class="form-group">
                                            <label className = {this.state.eEmail != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> <Trans>email</Trans> </label>
                                            <input
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.email == null ? '' : this.state.selectedOption.email}
                                                onChange = {(e)=>this.OnChangeValue('email',e)}
                                                onKeyDown ={(e)=>{
                                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                                    let keyCode = e.which || e.keyCode
                                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                                        e.preventDefault();
                                                        return false
                                                    }
                                                    else{
                                                        return true
                                                    }
                
                                                }}
                                                type="email"  
                                                class={this.state.eEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
                                        </div>
                                        
                                        <div class="form-group">
                                            <label className = {this.state.eTelephone != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> <Trans>telephone</Trans> </label>
                                            {/* <input 
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.telephone == null ? '' : this.state.selectedOption.telephone}
                                                onChange = {(e)=>this.OnChangeValue('telephone',e)}
                                                type="text" class="form-control rounded-0" /> */}
                                            <PhoneInput
                                                inputStyle = {{width : '99.5%',marginLeft : 2,height : 'calc(2.25rem + 2px)',borderRadius : 0}}
                                                inputClass = {this.state.eTelephone != "" ? "text-red-border" : "phone-picker"}
                                                id="phone"
                                                country={Constants.defaultCountry.code}
                                                countryCodeEditable = {false}
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.telephone == null ? '' : this.state.selectedOption.telephone}
                                                onChange = {(phone,country,e) => this.OnChangeValue('telephone',e)}
                                            />
                                        </div>
                                        
                                        <div class="form-group">
                                            <label><Trans>purchaseMumber</Trans> </label>
                                            <input
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.purchaseOrderNo == null ? '' : this.state.selectedOption.purchaseOrderNo}
                                                onChange = {(e)=>this.OnChangeValue('purchaseOrderNo',e)}
                                                type="text" class="form-control rounded-0" />
                                        </div>
                                        
                                        <div class="form-group">
                                            <label className = {this.state.eCompanyName != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>  <Trans>companyName</Trans> </label>
                                            <input 
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.companyName == null ? '' : this.state.selectedOption.companyName}
                                                onChange = {(e)=>this.OnChangeValue('companyName',e)}
                                                type="text"
                                                class={this.state.eCompanyName != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}/>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                    <div class = "col-md-6">
                                    
                                        <div class="form-group">
                                            <label className = {this.state.eBillingAddress1 != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i> <Trans>billingAdd</Trans>1 </label>
                                            <input
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.billingAddress1 == null ? '' : this.state.selectedOption.billingAddress1}
                                                onChange = {(e)=>this.OnChangeValue('billingAddress1',e)} 
                                                type="text" 
                                                class={this.state.eBillingAddress1 != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"} />
                                        </div>
                                    
                                        <div class="form-group">
                                            <label><Trans>billingAdd</Trans>2</label>
                                            <input
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.billingAddress2 == null ? '' : this.state.selectedOption.billingAddress2}
                                                onChange = {(e)=>this.OnChangeValue('billingAddress2',e)}  
                                                type="text" class="form-control rounded-0" />
                                        </div>
                                        
                                        <div class="form-group">
                                            <label><Trans>postalCode</Trans></label>
                                            <input
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.postCode == null ? '' : this.state.selectedOption.postCode}
                                                onChange = {(e)=>this.OnChangeValue('postCode',e)}  
                                                type="text" class="form-control rounded-0" />
                                        </div>
                                        
                                        <div class="form-group">
                                            <label className = {this.state.eCity != "" ? "text-red" : ""}><i class="fas fa-star-of-life text-red"></i>  <Trans>city</Trans> </label>
                                            <input 
                                                value = {this.state.selectedOption == null ? "" : this.state.selectedOption.city == null ? '' : this.state.selectedOption.city}
                                                onChange = {(e)=>this.OnChangeValue('city',e)} 
                                                type="text"
                                                class={this.state.eCity != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}
                                                />
                                        </div>
                                        
                                        <div class="form-group">
                                            <label><i class="fas fa-star-of-life text-red"></i>  <Trans>country</Trans> </label>
                                            <select onChange = {this.OnChangeCountry} value = {this.state.selectedOption == null ? "" : this.state.selectedOption.country == null ? this.props.countries[0] : this.state.selectedOption.country} id="country_selector" type="text" class="form-control rounded-0 selector" >
                                                {this.renderCountryOptions()}
                                            </select>
                                        </div>
                                        
                                    </div>
                                
                        </div>
                        
                        <section class = "order-form py-3">

                            <div class = "container">
                                
                                <div class = "row d-none-mob">
                                
                                    <div class="col-md-6 mb-3 mb-sm-0">
                                        <a onClick = {this.OnClickBack} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',pointerEvents : this.props.submitPaymentButtonDisabled ? 'none' : 'auto',marginRight : 5}} class="btn btn-default f-600">
                                        <i class="fas fa-arrow-left mr-2 mt-1"></i>	<Trans>goBack</Trans> 
                                        </a>
                                    </div>
                                    
                                    <div class="col-md-6 text-sm-right">
                                        <a onClick = {this.OnContinueClick} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',pointerEvents : this.props.submitPaymentButtonDisabled ? 'none' : 'auto',marginLeft : 5}}  class="btn btn-default f-600">
                                            
                                            <Trans>submit</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                        </a>
                                    </div>
                                    
                                </div>
                                
                                <div class = "row d-block d-md-none">
                                
                                    <div class="col-12 mb-3 mb-sm-0 text-center">
                                    
                                        <a onClick = {this.OnClickBack} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',pointerEvents : this.props.submitPaymentButtonDisabled ? 'none' : 'auto',marginRight : 5}} class="btn btn-default f-600">
                                        <i class="fas fa-arrow-left mr-2 mt-1"></i><Trans>goBack</Trans>
                                        </a>
                                        
                                        <a  onClick = {this.OnContinueClick} style = {{cursor : this.props.savingPayment ? 'not-allowed' :'pointer',pointerEvents : this.props.submitPaymentButtonDisabled ? 'none' : 'auto',marginRight : 5}} class="btn btn-default f-600">
                                            <Trans>submit</Trans> <i class="fas fa-arrow-right ml-3 float-right mt-1"></i>
                                        </a>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>

                        </section>

                    </div>
                    
                </MDBCollapse>
                {this.props.savingPayment ?
                    <div className = "text-center mb-3">
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {25}
                            width = {25}
                        /> 
                    </div> : 
                    this.props.failedSaving && this.props.order.totalPrice > 0 ?
                    <div class = "text-red text-center mb-3">{this.props.failedSaving}</div> : null
                }   
                
            </div> :
            <div className = "col-12 mt-5 text-center">
                {this.props.failedSaving == null ?
                <div>
                    <Loader
                        type = 'Oval'
                        color = "#00b6ff"
                        height = {35}
                        width = {35}
                    />
                <div className = "mt-2"><Trans>confirmingBooking</Trans></div>
                </div> : null}
                {this.props.failedSaving ?
                <>
                <div className = "text-red mt-2">{this.props.failedSaving}</div> 
                <button className = "btn mt-3 mb-2" onClick = {()=> this.props.confirmBooking()}><Trans>retry</Trans></button>
                </>: null}
            </div> }
            </div>
        );
    }
}

export default FourthStep;