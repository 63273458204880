import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import {Trans} from 'react-i18next'
export class DownloadPresenationView extends React.Component{

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    OnClickDownload = (index) => {
        this.props.onClickDownload(index)
    }
    render(){
        return(
            <section class = "presentation py-3 py-md-5">
                <div class = "content">
                    <h5 class = "text-center font-weight-bold">{this.props.title}</h5>
                    <div class = "container py-3 py-md-5">
                        {this.props.files.map((item,index)=>{
                            return(
                                item.path != null ?
                                <div class = "row presentation-col border-bottom p-2  mb-2">
                                    <div class = "col-sm-8 mb-2 mb-md-0">
                                        <p class = "mb-0 f-500">{item.title}</p>
                                    </div>
                                    
                                    <div class = "col-sm-4 text-sm-right">
                                        <a href = {this.props.files[index].path} target = "_blank" ><Trans>download</Trans></a>
                                    </div>
                                </div> :null
                            )
                        })}
                        <div class = "row presentation-col p-2  mb-2">
				
                            <div class = "col-12 text-center">
                                <a style = {{cursor : 'pointer'}} onClick = {this.props.onGoBack} class = "text-dark"><Trans>goBack</Trans></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }//end of render

}

export default DownloadPresenationView;