import React from "react";
import ReactHtmlParser from 'react-html-parser'
import {Trans} from 'react-i18next'
export const Content=(props)=>{
    return(
        <section class = "membership-sec py-3">
            <div class="container text-center py-3 py-md-5">
            <p>{props.pageDescription}</p>
                <p class = "membership-hd">
                    Select the Plan that suits you
                </p>
                <div class="row">
                    {props.tickets.map((item,index)=>{
                        if(item.price.length == 0){
                            return null
                        }
                        return(
                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                <div class = "card membership-card rounded-0 h-100">
                                    <div class = "card-header text-center rounded-0">
                                        {item.title}
                                    </div>
                                    <div class = "card-body text-left pt-5">
                                        <div class = "card-middle">
                                            {ReactHtmlParser(item.description)}
                                        </div>
                                        
                                        <p class = "price-sec">
                                            <span>{item.price[0].currency+""+item.price[0].price}</span> Valid for 
                                            <span>{item.duration_in_days % 30 == 0 ? " "+item.duration_in_days/30 : item.duration_in_days % 365 == 0 ? " "+item.duration_in_days/365 :  " "+item.duration_in_days }</span> {item.duration_in_days % 30 == 0 ? 'month(s)' : item.duration_in_days % 365 == 0 ? 'year(s)' : 'day(s)'}
                                        </p>
                                        <a onClick={()=>props.onSelectPlan(item.id)} class = "btn btn-tele">
                                            Select Plan
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div class="modal fade" id="loginPopup">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-body model-form p-3 py-md-4 px-md-5 border shadow-sm">
                        <form id="form-line" class = "">
                            <h5 class = "text-center mb-3"><Trans>continueBooking</Trans></h5>
                            <div class = "form-group" style = {{marginBottom : '1.5rem'}}>
                                <input 
                                    type="text" 
                                    class="form-control rounded-0" 
                                    placeholder={props.translation.enterYourEmail} 
                                    id="email"
                                    onKeyDown ={(e)=>{
                                        let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                        let keyCode = e.which || e.keyCode
                                        if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                            e.preventDefault();
                                            return false
                                        }
                                        else{
                                            return true
                                        }
    
                                    }}
                                    class={props.eGuestEmail != "" ? "form-control rounded-0 text-red-border" : "form-control rounded-0"}
                                    onChange = {(e)=>props.onChangeEmail(e.target.value)} 
                                />
                                <div className = 'text-red' style = {{position : 'absolute'}}>{props.eGuestEmail}</div>
                            </div>
                            
                            <div class = "form-group">
                                {/* <a style = {{justifyContent : 'center',display : 'flex'}} type="submit" class="btn form-control rounded-0">Continue as a Guest</a> */}
                                <input id="guest-continue" type="button" class="btn form-control rounded-0" onClick = {props.onClickGuestContinue} style = {{justifyContent : 'center',display : 'flex'}} value={props.translation.continueAsGuest}></input>
                            </div>
                            <div class = "form-group text-center">
                                <span><Trans>or</Trans></span>
                            </div>
                            <div class = "form-group">
                                {/* <a style = {{justifyContent : 'center',display : 'flex'}} onClick = {this.props.onLoginClick} type="submit" class="btn form-control rounded-0">Login / Signup</a> */}
                                <input type="submit" class="btn form-control rounded-0" onClick = {props.onLoginClick} style = {{justifyContent : 'center',display : 'flex'}} value={props.translation.loginSignup}></input>
                                <small id="emailHelp" class="form-text text-muted text-center"><Trans>recommended</Trans></small>
                            </div>
                        </form>
                    </div>

                    </div>
                </div>
                </div>
        </section>
    )
}