import React,{useState,useEffect,useRef} from 'react'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import agendaApi from '../AgendaTime/api'
import api from './api'
import utility from '../../Utility/utility'
import session from '../Schedule/session'
export default function LoginView(props){
    const [password,setPassword] = useState("")
    const [ePass,setPasswordError] = useState("")
    const [eSession,setSessionSelectError] = useState("")
    const [sessions,setSessions] = useState([])
    const [loadingSessions,setSessionLoading] = useState(true)
    const [selectedSession,setSelectedSession] = useState(props.selectedSession)
    const [validatingSession,setSessionValidation] = useState(false)
    const [autheticationError,setAuthError] = useState("")
    useEffect (()=>{
        $("#form-line").submit(function(e) {
            e.preventDefault();
        });
        let currentDate = new Date().setHours(0,0,0,0)
        if(props.loadedSessions != null){
            setSessionLoading(false)
            setSessions(props.loadedSessions)
            return
        }
        if(props.sessionLoggedIn){
            return
        }
        agendaApi.getEventAgenda(props.eventAlias,0,"",(err,data)=>{
            var sessions = []
            if(data != null){
                let agenda = UpdateSessionLiveStatus(data.data)
                for(var i=0;i<agenda.length;i++){
                    let agendaItem = agenda[i]
                    // let agendaDateArr = agendaItem.date.split('-')
                    // let dateString = agendaDateArr[2]+","+agendaDateArr[1]+","+agendaDateArr[0]
                    // let sessionDate = new Date(dateString).getTime()
                    for(var j=0;j<agendaItem.sessions.data.length;j++){
                        let session = agendaItem.sessions.data[j]
                        if(session.hasMultiSession == 1 /*&& session.virtual_access_status == 1*/){
                            for(var k=0;k<session.subSessions.length;k++){
                                let subSession = session.subSessions[k]
                                subSession.agendaIndex = i
                                subSession.sessionIndex = k
                                subSession.agendaId = agendaItem.id
                                subSession.sessionId = session.id
                                subSession.subSessionId = session.subSessions[k].id
                                sessions.push(subSession)
                            }
                        }else{
                            // if(session.virtual_access_status == 1){
                                session.agendaIndex = i
                                session.sessionIndex = j
                                session.agendaId = agendaItem.id
                                sessions.push(session)
                            // }
                        }
                    }
                }
                props.onSessionsLoaded(sessions)
                setSessions(sessions)
                let loggedInSession = localStorage.getItem("loggedInSession")
                if(loggedInSession == null){
                    OnSessionSelecetd(sessions[0])
                }
            }
            setSessionLoading(false)
        })
    },[])
    const UpdateSessionLiveStatus = (agenda) => {  // based on current time according to event time zone and session start time
        let timeZoneSplit = props.timeZone.split(":")
        let timeZoneHour = parseInt(timeZoneSplit[0])
        let timeZone = parseFloat(timeZoneHour + "." + timeZoneSplit[1])
        let currentTimeInEventTimeZone = utility.calculateZoneSpecificTime('',timeZone)
        let currentTimeInMinutes = currentTimeInEventTimeZone.getHours() * 60 + currentTimeInEventTimeZone.getMinutes()
        for(var i=0;i<agenda.length;i++){
            let dateSplit = agenda[i].date.split("-")
            let dateString = dateSplit[2]+","+parseInt(dateSplit[1])+","+parseInt(dateSplit[0])
            let date = new Date(dateString).getTime()
            agenda[i].dateMilliseconds = date
            for(var j=0;j<agenda[i].sessions.data.length;j++){
                let sessionItem = agenda[i].sessions.data[j]
                if(sessionItem.start_time == null){
                    sessionItem.virtual_access_status = 0
                    continue
                }
                let sessionStartTimeSplit = sessionItem.start_time.split(":")
                let sessionEndTimeSplit = sessionItem.end_time.split(":")
                let sessionStartTimeMinutes =  (parseInt(sessionStartTimeSplit[0]) * 60) + parseInt(sessionStartTimeSplit[1])
                let sessionEndTimeMinutes =  (parseInt(sessionEndTimeSplit[0]) * 60) + parseInt(sessionEndTimeSplit[1])
                sessionItem.sessionStartTimeMinutes = sessionStartTimeMinutes
                sessionItem.sessionEndTimeMinutes = sessionEndTimeMinutes
                if(sessionItem.virtual_access_status != 0){
                    if(date == currentTimeInEventTimeZone.setHours(0,0,0,0)){
                        if(currentTimeInMinutes < sessionEndTimeMinutes){
                            sessionItem.virtual_access_status = 1
                        }else{
                            sessionItem.virtual_access_status = 2
                        }
                    }else if(currentTimeInEventTimeZone.setHours(0,0,0,0) < date ){
                        sessionItem.virtual_access_status = 1
                    }else{
                        sessionItem.virtual_access_status = 2
                    }
                }
            }
        }
        return agenda
    }
    const renderOptions = () => {
        var options = []
        for(var i=0;i<sessions.length;i++){
            options.push(<option>{sessions[i].title}</option>)
        }
        return options
    }
    const handleChangePassword = (e)=>{
        setPassword(e.target.value)
    }
    const OnChangeSelector = (e)=>{
        let selectedValue = e.target.value
        selectedValue = selectedValue.replaceAll(/\s/g, '')
        let selectedSession = sessions.find(x=>x.title.replaceAll(/\s/g, '') == selectedValue)
        OnSessionSelecetd(selectedSession)
    }
    const OnSessionSelecetd = (session) => {
        let newSelectedSession = {    //only using data which is required
            name : session.title,
            agendaIndex : session.agendaIndex,
            sessionIndex : session.sessionIndex,
            agendaId : session.agendaId,
            sessionId : session.sessionId == null ? session.id : session.sessionId,
            subSessionId : session.subSessionId == null ? 0 : session.subSessionId
        }
        props.onSessionSelected(newSelectedSession)
        setSelectedSession(newSelectedSession)
    }
    const OnClickLogin = () => {
        setSessionSelectError("")
        setPasswordError("")
        setAuthError("")
        let sessionSelect = document.getElementById("sessionSelect").value
        if(sessionSelect == ""){
            setSessionSelectError("Select a valid session")
            return
        }
        if(password == ""){
            setPasswordError("Required")
            return
        }
        setSessionValidation(true)
        var obj = {
            event_id : props.eventAlias,
            event_session_id : selectedSession.sessionId,
            event_agenda_id : selectedSession.agendaId,
            event_sub_session_id : selectedSession.subSessionId, 
            pin : password
        }
        api.authenticatePin(props.eventAlias,obj,(err,data)=>{
            setSessionValidation(false)
            if(err){
                setAuthError(err.message)
                return
            }
            var sessionData = {
                name : selectedSession.name,
                agendaIndex : selectedSession.agendaIndex,
                sessionIndex : selectedSession.sessionIndex,
                sessionId : selectedSession.sessionId,
                agendaId : selectedSession.agendaId,
                subSessionId : selectedSession.subSessionId,
                pin : password
            }
            props.onLoginSuccess(data.questions,data.announcements,sessionData)
        })
    }
    return(
        <>
            <nav class="navbar navbar-expand-lg navbar-light bg-white py-3 login-header">
                <div class="container text-center login-hd mb-0">
                    
                    <h4 class = "w-100">
                        {props.eventName}
                    </h4>
                    
                </div>
            </nav>
            <section class = {props.sessionLoggedIn ? "login py-3 py-md-5" : "login bg-light py-3 py-md-5"}>
                <div class="container mobtix-container py-3 py-md-5">  
                    <div class = "row justify-content-center">
                        {props.sessionLoggedIn ?
                        <div style = {{width : '100%',height : window.innerHeight,display : 'flex',justifyContent : 'center',alignItems : 'center',position : 'absolute',top : 0}}>
                            <Loader
                                type = 'Oval'
                                color = "#00b6ff"
                                height = {40}
                                width = {40}
                            />
                        </div>:
                        <div class = "col-md-6">
                            <div class = "form-area bg-white rounded p-3 p-md-5 text-center">
                                <img src = "/images/lock_icon.svg" class = "mb-4" alt = "Locked" />
                                <h5 class = "text-center mb-4">Access Session</h5>
                                <form id = "form-line" onSubmit = {(e)=>e.preventDefault()}>
                                    
                                    <div class = "form-group">
                                        {loadingSessions ? 
                                        <div style = {{position : 'absolute',right : 35}}>
                                            <Loader
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {20}
                                                width = {20}
                                            />
                                        </div> : null}
                                        <select onChange = {OnChangeSelector} id = "sessionSelect" class={eSession != "" ? "selectpicker form-control w-100 text-red-border" : "selectpicker form-control w-100"}>
                                            {renderOptions()}
                                        </select>
                                    </div>
                                    <div class = "form-group">
                                        <input id = "passInput" onChange = {handleChangePassword}  type = "password" class = {ePass == "" ? "form-control btn-light" : "form-control btn-light text-red-border"}
                                        placeholder = "Enter Your Pin" />
                                    </div>
                                    <div class = "form-group">
                                        <input onClick = {OnClickLogin} type = "submit" class = "form-control btn-primary"
                                        value = "Login" id = "loginButton" />
                                        {validatingSession ?<div style = {{position : 'absolute',right : '50%',marginTop : 15}}>
                                            <Loader
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {20}
                                                width = {20}
                                            />
                                        </div> : null}
                                        <div style = {{
                                            position : 'absolute',
                                            left : '50%',
                                            transform : 'translate(-50%, -50%)',
                                            marginTop : 30,
                                            fontSize : 14
                                            }} className="text-red">{autheticationError}</div>
                                    </div>
                                </form>
                        
                            </div>
                        </div>}
                    </div>
                </div>  
            </section>
        </>
    )
}