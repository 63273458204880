import React,{useState} from 'react';
import Loader from 'react-loader-spinner'
import {Trans} from 'react-i18next'
import $ from 'jquery'
export const DeleteAccount = (props)=>{
  const [confirmationMsg,setConfirmationMessage] = useState("")
  const [apiUrl,setApiUrl] = useState('')
    return(
        <>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">{props.loggedInUser != null && props.loggedInUser.account_deleted == 1 ? 'Reactivate Account' :'Delete Account'}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {confirmationMsg}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-secondary close_delete_btn" data-dismiss="modal" onClick={()=>{
          setConfirmationMessage('')
        }}>Close</button>
        <button type="button" class="btn btn-primary close_btn" onClick = {()=>{
          if(props.loggedInUser.account_deleted == 1){
             props.onReactiveAccount()
          }else{
            props.onDeleteAccount()
          }
            $("#exampleModalCenter").modal("hide");
            setConfirmationMessage('')
        }}>{props.loggedInUser != null && props.loggedInUser.account_deleted == 1  ? 'Reactivate' : 'Delete'}</button>
      </div>
    </div>
  </div>
</div>
        <div class="card-header">Delete Account</div>
        <div class="card-body border-bottom rounded-0">
                <div style = {{display : 'flex',flexDirection : 'column',alignItems:'center'}}>  
{props.loggedInUser != null && props.loggedInUser.account_deleted == 1 ? <p>Are you sure to Reactivate your Account</p>:<p>Are You Sure You Want To Delete Your Account. This Action is Permanent!</p>}
                   {props.loggedInUser != null && props.loggedInUser.account_deleted == 1 ?
                   <div class="form-group">
                   <a style = {{pointerEvents : props.loading ? 'none' : 'auto'}} onClick = {()=>{
                         $("#exampleModalCenter").modal("show");
                         setConfirmationMessage('Are you sure to reactive your Account')
                   }} class="btn bg_main btn-tele text-light px-5 rounded-0">
                       Reactive Account</a>
               </div>
                    : <div class="form-group">
                        <a style = {{pointerEvents : props.loading ? 'none' : 'auto'}} onClick = {()=>{
                              $("#exampleModalCenter").modal("show");
                              setConfirmationMessage('Are You Sure You Want To Delete Your Account. This Action is Permanent!')
                        }} class="btn bg_main btn-tele text-light px-5 rounded-0">
                            <Trans>Delete Account</Trans></a>
                    </div>}
                    {props.loading ?
                    <div style = {{marginTop : 6,marginLeft : 12}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {30}
                            width = {30}
                        />
                    </div> : null}
                </div>
                {props.deleteAccountMessage != '' ?
                    <div className="alert alert-danger contactUsAlert">{props.deleteAccountMessage}</div>:null}
                {props.successDeleteAccount != '' ?
                    <div className="alert alert-success contactUsAlert">{props.successDeleteAccount}</div>:null}
        </div>
        </>
    )
}