import React,{useState,useEffect} from 'react'
import Loader from 'react-loader-spinner';
import $ from 'jquery'
import { Trans } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
export const Content = (props) => {
    const [hallIndex,setHallIndex] = useState(0)
    useEffect(() => {
    },[hallIndex])
    useEffect(()=>{
        let currentUrl  = window.location.href.split('/')
        let currentIndex = props.halls != undefined && props.halls.findIndex(item=>item.id == parseInt(currentUrl[currentUrl.length-1]))
        if(currentIndex > -1){
        setHallIndex(currentIndex)
        }

    })
    if(props.halls.length == 0){
        return(
            <section style = {{width : '100%',height : window.innerHeight,display : 'flex',alignItems : 'center',justifyContent : 'center'}}>
                {props.loading ? 
                <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                    <Loader
                        type = 'Oval'
                        color = "#00b6ff"
                        height = {40}
                        width = {40}
                    />
                </div> :
                <div className = "text-center">{props.loadingError}</div>}
            </section>
        )
    }
    $('#more-halls').click(function(){
        $('.side-booth-menu').addClass('active');
    })
    
    $('.close-booth-lsit').click(function(){
        $('.side-booth-menu').removeClass('active');
    })
    return (
        
        <>
        
        <section id= "exhibitor-area" class="exhibitor-area">
        {props.mapfloordata.virtualFloorPlan && Object.keys(props.mapfloordata.virtualFloorPlan).length > 0 ? <div class = "floor-maps">
					<a href="" class = "floor-map-btn" onClick = {(e)=>{
                        e.preventDefault()
                        props.OnNavigateToPage(`${props.alias}/virtual-floor-plan`)
                    }}>
						<Trans>Floor_Map</Trans>
					</a>
				</div>:null}
        {props.halls != null && props.halls.length > 1  ?
        <div class = "side-booth-menu" onClick={()=>{

        }}>
					<div class = "booth-menu-inner">
						<div class = "booth-menu-header">
							<Trans>visit_more_hall</Trans>
							<span class = "close-booth-lsit">
								<i class="fas fa-times"></i>
							</span>
						</div>
						<ul class = " hall-list">{
                            props.halls != undefined && props.halls.map((item,index)=>{
                                return <li className={`${hallIndex ==  index ? ' activee':''}`}
                                onClick={(e)=>{
                                    if(index != hallIndex){
                                    e.preventDefault()
                                    props.OnVisitHall(index,item.id)
                                    setHallIndex(index)
                                    }
                                  // props.OnNavigateToPage(`${props.alias}/virtual_exhibition//${item.bothId}`)
                                }}
                                
                                >{item.name == null ? `Hall ${index+1}` : item.name}</li>
                            })
                        }
						</ul>
					</div>
					<div id = "more-halls" class = "booth-menu-btn">
						<Trans>more_halls</Trans>
					</div>
				</div>:null}
        {props.selectedHallIndex == -1 ?
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-0" >
                    <div class="pyramid">
                        <img src={props.selectedHallmage} id = "Map" usemap="#Map" onLoad = {props.templateImageLoaded}/>
                    </div>
                </div>
            </div>
        </div> :
        props.halls.map((item,index)=>{
            return(
                props.selectedHallIndex == index ?
                <div class="container-fluid" id = {"hall"+index}>
                    <div class="row">
                        <div class="col-12 px-0" style = {{minHeight : props.loadingTemplateImage ? window.innerHeight : 0}}>
                        {props.loadingTemplateImage ?
                            <div style = {{width : '100%',height : '100%',position : 'absolute',zIndex : 1,background : 'rgba(0,0,0,0.5)',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                                <Loader
                                    type = 'Oval'
                                    color = "#fff"
                                    height = {40}
                                    width = {40}
                                />
                            </div> : null}
                            <div class="pyramid">
                                <img src={item.templateImage} id = "Map" usemap="#Map" onLoad = {props.templateImageLoaded}/>
                                <map name="Map" id="Map">
                                    {/* {item.exhibitors.map((exhibitor,index)=>{
                                        return(
                                            <area style = {{cursor : 'pointer'}} onClick = {()=>props.onClickBooth(index)} data-toggle="tooltip" data-placement="top" title={exhibitor.title} class={index < 4 ? "maparea" : "maparea-b"} coords={props.expoHallMapAreas[1][index]} shape="rect"/>
                                        )
                                    })} */}
                                    
                                    {/* <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea" href="" coords="854,515,1277,807" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea" href="" coords="1564,508,1997,807" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea" href="" coords="2142,561,2640,863" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea-b" href="" coords="477,841,1064,1250" shape="rect"/>
                                    <area target="_blank"  data-toggle="tooltip" data-placement="top" title="Visit Company Booth" class="maparea-b" href="" coords="1651,880,2246,1296" shape="rect"/> */}
                                    {ReactHtmlParser(item.mapArea)}
                                </map>
                                {/* {props.selectedHallIndex > 0 ?
                                <div onClick = {()=>props.onClickBack(index)} class="back-room-btn" id = {"back-imgs"+index}>
                                    <img src="/images/ic_back.svg" alt="back-icon" /> Back
                                </div> : null}
                                {props.selectedHallIndex != props.halls.length - 1 ?
                                <div onClick = {()=>props.onClickNext(index)} class="next-room-btn" id = {"nxt-imgs"+index}>
                                    Next <img src="/images/ic_next.svg" alt="next-icon"/>
                                </div> : null} */}
                            </div>
                        </div>
                    </div>
                </div>:null
            )
        })}
        </section>
        {/* <div onClick = {()=>props.onClickBack()} class="back-room-btn" id = "back-imgs">
            <i class="fas fa-long-arrow-alt-left"></i>
        </div>
        <div onClick = {()=>props.onClickNext()} class="next-room-btn" id = "nxt-imgs">
            <i class="fas fa-long-arrow-alt-right"></i>
        </div> */}
        </>
    )
}