import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import utility from '../../Utility/utility';
import Loader from 'react-loader-spinner'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Constants } from '../../Constants';
import { isMobile } from 'react-device-detect';
import {Trans} from 'react-i18next'
import Recaptcha from 'react-recaptcha';


export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            eFirstName : '',
            eLastName : '',
            eEmail : '',
            ePassword : '',
            eCPassword : '',
            ePhone : '',
            termsCondtionEror : '',
            phone : '',
            eCompanyName : '',
            eJobTitle : '',
            eHearAboutUs : '',
            eSecondName : '',
            hearOptionsAPI : this.props.hearOptionsAPI,
            recapError: '',
            idiomaCheckBox:true
        };
        this.defaultDailCode = ""
        this.lang = localStorage.getItem("language"+this.props.eventAlias)
        this.translation = this.props.useTranslation.getResourceBundle(this.lang)
        this.captchaVerified = false
        //this.hearOptionsAPI= this.props.hearOptionsAPI
    }
     
    componentDidMount(){
        $('#firstName').bind('keypress', this.testInput);
        $('#lastName').bind('keypress', this.testInput);
        $('#secondName').bind('keypress', this.testInput);
        //console.log("SIGN UP COMPONENT DID MOUNT", this.state.hearOptionsAPI)
         
        //this.fetchHearOptions()

        //console.log("lang",this.lang)
        //console.log("SIGN UP",localStorage.getItem("language"+localStorage.getItem("eventAlias")))
    }
    OnClickSignUp= (type) => {
        if(type=='1'){
        var email = document.getElementById('email').value
        var firstName = document.getElementById('firstName').value
        var password = document.getElementById('password').value
        var confirmPassword = document.getElementById('confirmPassword').value
        var termsCondition = document.getElementById('termsCondtions').checked
        var idomaconstes = document.getElementById("idomaConsets") && document.getElementById("idomaConsets").checked
        var companyName = ""
        var jobTitle = ""
        
        this.setState({eEmail : '',eFirstName : '',eSecondName : '',eLastName : '',ePhone : '',ePassword : '',eCPassword : '',termsCondtionEror : '',eCompanyName : '',eJobTitle : '',eHearAboutUs : '',recapError : ''})
        if(!utility.validateEmail(email)){
            this.setState({eEmail : <Trans>validEmail</Trans>})
        }
        if(!utility.validName(firstName)){
            this.setState({eFirstName : <Trans>validName</Trans>})
        }
        // if(!utility.validName(secondName)){
        //     this.setState({eSecondName : <Trans>validName</Trans>})
        // }
        // if(companyName == ""){
        //     this.setState({eCompanyName : 'Please enter a valid company name'})
        // }
        // if(jobTitle == ""){
        //     this.setState({eJobTitle : 'Please enter a valid job title'})
        // }
        if(!utility.validPassFormat(password)){
            this.setState({ePassword : <Trans>minPassword</Trans>})
        }
        if(!utility.validPassFormat(confirmPassword)){
            this.setState({eCPassword : <Trans>minPassword</Trans>})
        }
        if(confirmPassword != password){
            this.setState({eCPassword : <Trans>notMatch</Trans>})
        }
        if(process.env.REACT_APP_ENV_NAME == "development"){
            this.captchaVerified = true
        }else{
            if(!this.captchaVerified){
                this.setState({recapError : <Trans>required</Trans>})
            }
        }
        if(!utility.validateEmail(email) || 
        password == "" ||
        password.length < 8 || 
        confirmPassword == "" ||
        confirmPassword != password){
            return
        }
        if(!termsCondition){
            this.setState({termsCondtionEror : <Trans>agree</Trans>})
            return
        }
        if(firstName.trim().length >0){
            if(this.captchaVerified == true){
        this.props.signUpEvent(firstName, email)
        this.props.processSignup(firstName,'','',email,'',password,'manual',companyName,jobTitle,'','',idomaconstes)
            }
        }
            
        }else{
        var phoneNumberIsValid = true
        var email = document.getElementById('email').value
        var firstName = document.getElementById('firstName').value
        var lastName = document.getElementById('lastName').value
        var secondName = document.getElementById('secondName').value
        var phone = this.state.phone
        var password = document.getElementById('password').value
        var confirmPassword = document.getElementById('confirmPassword').value
        var termsCondition = document.getElementById('termsCondtions').checked
        var companyName = ""
        var jobTitle = ""
        var aboutUs = document.getElementById('aboutUs').value

        
        this.setState({eEmail : '',eFirstName : '',eSecondName : '',eLastName : '',ePhone : '',ePassword : '',eCPassword : '',termsCondtionEror : '',eCompanyName : '',eJobTitle : '',eHearAboutUs : '',recapError : ''})
        if(!utility.validateEmail(email)){
            this.setState({eEmail : <Trans>validEmail</Trans>})
        }
        if(!utility.validName(firstName)){
            this.setState({eFirstName : <Trans>validName</Trans>})
        }
        if(!utility.validName(lastName)){
            this.setState({eLastName : <Trans>validName</Trans>})
        }
        // if(!utility.validName(secondName)){
        //     this.setState({eSecondName : <Trans>validName</Trans>})
        // }
        // if(companyName == ""){
        //     this.setState({eCompanyName : 'Please enter a valid company name'})
        // }
        // if(jobTitle == ""){
        //     this.setState({eJobTitle : 'Please enter a valid job title'})
        // }
        if(phone != this.defaultDailCode){
            if(!utility.validatePhone(phone)){
                phoneNumberIsValid = false
                this.setState({ePhone : <Trans>validPhone</Trans>})
            }else{
                if(phone.charAt(0) != "+"){
                    phone = "+"+phone
                }
            }
        }
        if(!utility.validPassFormat(password)){
            this.setState({ePassword : <Trans>minPassword</Trans>})
        }
        if(!utility.validPassFormat(confirmPassword)){
            this.setState({eCPassword : <Trans>minPassword</Trans>})
        }
        if(confirmPassword != password){
            this.setState({eCPassword : <Trans>notMatch</Trans>})
        }
        if(aboutUs == ''){
            this.setState({eHearAboutUs : <Trans>selectOption</Trans>})
        }
        if(process.env.REACT_APP_ENV_NAME == "development"){
            this.captchaVerified = true
        }else{
            if(!this.captchaVerified){
                this.setState({recapError : <Trans>required</Trans>})
            }
        }
        if(!utility.validateEmail(email) || 
        password == "" ||
        password.length < 8 || 
        confirmPassword == "" ||
        confirmPassword != password || 
        !utility.validName(firstName) || 
        !utility.validName(lastName) ||
        !phoneNumberIsValid ||
        aboutUs == '' || !this.captchaVerified){
            return
        }
        if(!termsCondition){
            this.setState({termsCondtionEror : <Trans>agree</Trans>})
            return
        }
        if(phone == this.defaultDailCode){
            phone = ""
        }
        if(phone && phone.length > 0){
            let phoneWithOutCode = phone.substr(this.defaultDailCode.length,phone.length)
            var formattedPhoneNumner = this.defaultDailCode+" "+phoneWithOutCode
        }
        if(this.captchaVerified == true){
        this.props.signUpEvent(firstName+''+lastName, email)
        this.props.processSignup(firstName,secondName,lastName,email,phone,password,'manual',companyName,jobTitle,aboutUs,formattedPhoneNumner,false)
        }
    }
    }
    OnChangePhone = (phone,country) => {
        let dialCode = country.dialCode
        let phoneWithOutCode= phone.replace(dialCode,'')
        let phoneNumber = dialCode+' '+phoneWithOutCode
        console.log(phoneNumber)
        this.setState({phone: phoneNumber})
    }
    OnFocusPhone = (country) =>{
        this.defaultDailCode = "+"+country.dialCode
        if(this.state.phone == ""){
            this.setState({phone : this.defaultDailCode})
        }
    }
    testInput(event) {
        var value = String.fromCharCode(event.which);
        var pattern = new RegExp(/(fiktiv)|[^0-9]/g);
        return pattern.test(value);
    }
    verifyCaptchaCallback = (response) => {
        let recapError = null;
        this.recapResponse = response
        if(!response){
            recapError = "This is required";
        }
        else{
            this.captchaVerified = true
        }
        this.setState({recapError});
    }
    render(){
        return(
            <section class = " signup py-3 py-md-5 bg-light">
                <h3 class = "font-weight-bold text-main text-center"><Trans>createAccount</Trans></h3>
                <div class = "row py-3">
                        {this.props.joBFAIR == 0 ? 
                        null:
                        <div class = "col-12 text-center">
                        <p style={{fontSize:'18px'}}><Trans>haveAccount</Trans> <a  onClick = {this.props.onLoginClick} style = {{cursor : 'pointer',fontSize:'18px',textDecoration:'underline',textTransform:'uppercase',pointerEvents : this.props.signUpInProcess ? 'none' : 'auto'}} class = "text-danger underline-text font-weight-bold">
                                <Trans>login</Trans></a></p>
                    </div>
                        }
                    </div>
                <div class = "container">
                    <div class = "row py-3 justify-content-center">
                        <div class = "col-md-6">
                            <form class = "p-3 py-md-4 px-md-5 border shadow-sm" action="">
                            <div class="form-group">
                                <label for="email" ><i class="fas fa-star-of-life text-red"></i> <Trans>firstName</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "firstName" type="text" class = {this.state.eFirstName == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterFirstName} required />
                                {this.state.eFirstName != '' ? <div class="invalid-feedback">{this.state.eFirstName}</div> : null}
                            </div>
                           {this.props.joBFAIR == "1" ? null : <div class="form-group">
                                <label for="email"> <Trans>secondName</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "secondName" type="text" class = { "form-control"} placeholder={this.translation.enterSecondName} />
                                {/* {this.state.eSecondName != '' ? <div class="invalid-feedback">{this.state.eSecondName}</div> : null} */}
                            </div>}
                            {this.props.joBFAIR == "1" ? null :
                            <div class="form-group">
                                <label for="email"><i class="fas fa-star-of-life text-red"></i><Trans>lastName</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "lastName" type="text" class = {this.state.eLastName == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterLastName} />
                                {this.state.eLastName != '' ? <div class="invalid-feedback">{this.state.eLastName}</div> : null}
                            </div>}
                            <div class="form-group">
                                <label for="pwd" ><i class="fas fa-star-of-life text-red"></i><Trans>email</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "email" type="email" onKeyDown ={(e)=>{
                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    let keyCode = e.which || e.keyCode
                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                        e.preventDefault();
                                        return false
                                    }
                                    else{
                                        return true
                                    }

                                }} class={this.state.eEmail == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterYourEmail} />
                                {this.state.eEmail != '' ? <div class="invalid-feedback">{this.state.eEmail}</div> : null}
                            </div>
                            {/* <div class="form-group">
                                <label for="pwd" ><Trans>companyName</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "companyName" type="text" class={this.state.eCompanyName == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterCompanyName} />
                                {this.state.eCompanyName != '' ? <div class="invalid-feedback">{this.state.eCompanyName}</div> : null}
                            </div> */}
                            {/* <div class="form-group">
                                <label for="pwd" > <Trans>jobTitle</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "jobTitle" class={this.state.eJobTitle == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterJobTitle} />
                                {this.state.eJobTitle != '' ? <div class="invalid-feedback">{this.state.eJobTitle}</div> : null}
                            </div> */}
                            {this.props.joBFAIR == "1" ? null :
                            <div class="form-group">
                                <label for="email"><Trans>phone</Trans></label>
                                {/* <input disabled = {this.props.signUpInProcess} id = "phone" type="text" class={this.state.ePhone == "" ? "form-control" : "form-control text-red-border"} placeholder="Enter Your Phone" />
                                {this.state.ePhone != '' ? <div class="invalid-feedback">{this.state.ePhone}</div> : null} */}
                                <PhoneInput
                                    inputStyle = {{width : '99.5%',marginLeft : 2,height : 'calc(2.25rem + 2px)'}}
                                    inputClass = {this.state.ePhone != "" ? "text-red-border" : "phone-picker"}
                                    id="phone"
                                    country={Constants.defaultCountry.code}
                                    value = {this.state.phone}
                                    jumpCursorToEnd
                                    countryCodeEditable = {false}
                                    onChange = {(phone,country) => this.OnChangePhone(phone,country)}
                                    onFocus = {(phone,country,e)=> this.OnFocusPhone(country)}
                                />
                                {this.state.ePhone != '' ? <div class="invalid-feedback">{this.state.ePhone}</div> : null}
                            </div>}
                            <div class="form-group">
                                <label for="pwd"><i class="fas fa-star-of-life text-red"></i> <Trans>password</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "password" type="password" class={this.state.ePassword == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterPassword} />
                                {this.state.ePassword != '' ? <div class="invalid-feedback">{this.state.ePassword}</div> : null}
                            </div>
                            <div class="form-group">
                                <label for="pwd" ><i class="fas fa-star-of-life text-red"></i> <Trans>confirmPassword</Trans></label>
                                <input disabled = {this.props.signUpInProcess} id = "confirmPassword" type="password" class={this.state.eCPassword == "" ? "form-control" : "form-control text-red-border"} placeholder={this.translation.enterConfirmPassword} />
                                {this.state.eCPassword != '' ? <div class="invalid-feedback">{this.state.eCPassword}</div> : null}
                            </div>
                            {this.props.joBFAIR == "1" ? null :
                            <div class="form-group">
                                <label for="pwd" ><i class="fas fa-star-of-life text-red"></i> <Trans>learn</Trans></label>
                                
                                <select onChange = {()=>this.setState({eAboutUs : ''})} disabled = {this.props.signUpInProcess} class={this.state.eHearAboutUs == "" ? "form-control form_select" : "form-control form_select text-red-border"} id="aboutUs">
                                    <option value="" selected disabled>{this.translation.hear}</option>
                                    
                                    {this.props.hearOptionsAPI.map((option)=>(
                                        typeof option === 'object' ?(
                                        <option value={option.id}>{option.name}</option>):
                                        (<option value={option}>{option}</option>)
                                    ))}
                                    {/* <option>Facebook</option>
                                    <option>LinkedIn</option>
                                    <option>Twitter</option>
                                    <option>Instagram</option>
                                    <option>{this.translation.email}</option>
                                    <option>{this.translation.friends}</option>
                                    <option>{this.translation.other}</option> */}
                                </select>
                              
                                {this.state.eHearAboutUs != '' ? <div class="invalid-feedback">{this.state.eHearAboutUs}</div> : null}
                            </div>}
                            <div class="form-group">
                            <input disabled = {this.props.signUpInProcess} id="termsCondtions" class="form-check-input idomaConsets" type="checkbox" style={{marginLeft:"0.1%"}}/> 
                                <label class="form-check-label check-cont1">
                                    {this.props.joBFAIR != 1 ? <><Trans>iUnderstand</Trans><span onClick = {(e)=>{
                                        e.stopPropagation()
                                        window.open('/terms-conditions')}} class="text-red f-600">
                                        <Trans>termsAndConditions</Trans></span></>:<><Trans>idoma_term</Trans><span onClick = {(e)=>{
                                            e.stopPropagation()
                                            window.open('/terms-conditions')}} class="text-red f-600">
                                        <Trans>termsAndConditions</Trans></span> and <span onClick = {(e)=>{
                                            e.stopPropagation()
                                            window.open('/privacy-policy')}} class="text-red f-600">
                                        <Trans>privacyPolicy</Trans></span><Trans>idomapart_2</Trans>  </>}
                                    {/* <span class="checkmark"></span> */}
                                </label>
                                {this.state.termsCondtionEror != '' ?
                                    <div className="alert alert-danger contactUsAlert">{this.state.termsCondtionEror}</div>:null}
                            </div>
                           {this.props.joBFAIR == 1 ?  <div class="form-group">
                           <input disabled = {this.props.signUpInProcess} id="idomaConsets" class="form-check-input idomaConsets" type="checkbox" style={{marginLeft:"0.1%"}} onChange={(e)=>{
                              console.log(e.target.checked)
                           }}/>
                           <label class="form-check-label check-cont1">
                                     <Trans>idoma_p1</Trans>
                                        <Trans>idoma_p2</Trans>
                                        <span onClick = {()=>{localStorage.getItem("language"+this.props.eventAlias) == 'en' ? 
                                        window.open('page/privacypolicy/76','_blank')
                                        // this.props.consetClick(this.props.eventAlias+'/page/76')
                                        :
                                        // this.props.consetClick(this.props.eventAlias+'/page/73')
                                        window.open('page/privacypolicy/74','_blank')
                                        }} class="text-red f-600">
                                        <Trans>privacyPolicy</Trans> </span> and 
                                    <span onClick = {()=>{localStorage.getItem("language"+this.props.eventAlias) == 'en' ? 
                                    // this.props.consetClick(this.props.eventAlias+'/page/75')
                                    window.open('page/termandCondition/75','_blank')
                                    :
                                    // this.props.consetClick(this.props.eventAlias+'/page/74')
                                    window.open('page/termandCondition/73','_blank')
                                    }} class="text-red f-600">
                                        <Trans>termsAndConditions</Trans> </span>
                                        <Trans>idoma_p3</Trans>
                                    {/* <span class="checkmark"></span> */}
                                </label>
                            </div>:null}
                            <div class="form-group">
                                <Recaptcha
                                    sitekey= {process.env.REACT_APP_RECAPTCHA_KEY}
                                    render="explicit"
                                    verifyCallback={this.verifyCaptchaCallback}
                                    expiredCallback = {()=>this.captchaVerified = false}
                                    hl= {localStorage.getItem("language"+this.props.eventAlias)}
                                />
                                {this.state.recapError ? <div className="alert alert-danger contactUsAlert">{this.state.recapError}</div>:null}
                            </div>
                             <div style = {{display : isMobile ? '' : 'flex',flexDirection : 'row'}}>   
                             {this.props.joBFAIR != "1" ? 
                                <div class="form-group loginForm">
                                    <a style = {{pointerEvents : this.props.signUpInProcess ? 'none' : 'auto'}} onClick = {()=>this.OnClickSignUp("0")} class="btn btn-tele tele-text bg_main text-light px-5 rounded-0"> 
                                    <Trans>signUp</Trans> </a>
                                </div>:
                                <div style={{width:'100%',display:'flex',justifyContent:'flex-start'}}>
                                <div class="form-group loginForm">
                                <a style = {{pointerEvents : this.props.signUpInProcess ? 'none' : 'auto'}} onClick = {()=>this.OnClickSignUp("1")} class="btn btn-tele tele-text bg_main text-light px-5 rounded-0"> 
                                <Trans>next</Trans> </a>
                            </div>
                            </div>
                                }
                                {this.props.signUpInProcess ?
                                <div style = {{marginTop : 6,marginLeft : isMobile ? '45%' : 12}}>
                                    <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {30}
                                        width = {30}
                                    />
                                </div>: null}
                            </div>
                            {this.props.signUpError != '' ?
                                <div className="alert alert-danger contactUsAlert">{this.props.signUpError}</div>:null}
                            </form>
                        </div>
                    </div>
                    <div class = "row py-3">
                        {this.props.joBFAIR == 0 ? 
                        <div class = "col-12 text-center">
                            <p><Trans>haveAccount</Trans> <a style = {{pointerEvents : this.props.signUpInProcess ? 'none' : 'auto'}} onClick = {this.props.onLoginClick} style = {{cursor : 'pointer'}} class = "text-danger font-weight-bold">
                                <Trans>login</Trans></a></p>
                        </div>:
                        null
                        }
                    </div>
                    
                </div>
                
            </section>
        )
    }//end of render

}

export default Content;