import React from 'react'
import Footer2 from '../../Footer/Footer2'
import Header from '../../Header/Index';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {getDataTypes,getContent} from '../actions/index'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import { Constants } from "../../../Constants";
import {Link} from 'react-router-dom'
import PremiumContent from '../preminumContent';
import  PermiumUserModal  from '../../modals/premiumUserModal'
import utitlity from '../../../Utility/utility';
import VirtualZone from "../../Header/virtualZone";
import $ from 'jquery';
import { fetchPagesData } from "../api";
class Networking extends React.Component{

    _isMounted = false
    constructor(props){
        super(props);
        this.state = {
            event: {},
            selectedIndex : this.props.headerMenuItems.findIndex(x=>x.href.indexOf("/mp-networking") > -1),
            renderComponents : true,
            selectedSubmenuIndex : -1,
            availableLocales : this.props.availableLocales,
			contentType:[],
			loading:false,
			pageTitle:"",
			description:""
        }
		this.user = utitlity.getLoggedInUser(props.event.alias)

    }
	
    OnNavigateToPage = (path,data=null) =>{
        console.log(path)
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        if(data){
            this.props.history.push({
                pathname: path,
                state: data
            })
        }else{
            this.props.history.push(path)
        }
    }
	async componentDidMount(){
		window.scrollTo(0, 0);
		let prams = {
			type:'networking'
		}
		 await fetchPagesData(this.props.event.alias,prams,null,(err,data)=>{
			if(data){
				this.setState({
					pageTitle:data.data.title,
					description:data.data.description
				})
			}
		})
	}
	OnClickAccountItem = (index, path) => {
		this.props.onClickAccountItem(index, path);
		if (index > -1) {
		  this.props.history.replace("");
		  this.props.history.push({
			pathname: this.props.event.alias + "/account",
			state: { tabIndex: index },
		  });
		} else {
		  localStorage.removeItem(
			Constants.API_TOKEN + this.props.event.alias + "user_info"
		  );
		  window.location.reload();
		}
	  };
	componentWillReceiveProps(newProps){
		this.setState({contentType:newProps.contentType,loading:newProps.loading})
	}
	moveToParticipent = ()=>{
		if(this.user == null){
			$("#loginSession").modal('show')
		}
	else if (this.user != null && this.user.isBooking == false)
		{
			$("#premiumSession").modal('show')
		}
		else{
			this.OnNavigateToPage(`${this.props.event.alias}/participants`)
		}

	}
    render(){
        return (<>
		   <PermiumUserModal
		id = "premiumSession"
		iconClass = "fa-lock"
		title = "Premium Content"
		description = "<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
		buttonText = "Join Now"
		showButton = {true}
		onClose = {()=>{
			$("#premiumSession").modal('hide')
			$('.modal-backdrop').remove();
		}}
		onClickBookNow = {()=>{
		$("#premiumSession").modal('hide')
		$('.modal-backdrop').remove();
		this.OnNavigateToPage(`${this.props.event.alias}/membership`)}
    }/>
	<PermiumUserModal
		id = "loginSession"
		iconClass = "fas fa-lock"
		title = "login"
		description = "<p>Please Login before search Participent.</p>"
		buttonText = "Login"
		showButton = {true}
		onClose = {()=>{
			$("#loginSession").modal('hide')
			$('.modal-backdrop').remove();
		}}
		onClickBookNow = {()=>{
		$("#loginSession").modal('hide')
		$('.modal-backdrop').remove();
		this.OnNavigateToPage(`${this.props.event.alias}/login`)}
    }/>    
	        <div id="header-top">
           <Header 
            selectedIndex = {this.state.selectedIndex}
			selectedSubIndex = {-1}
            onMenuItemClick = {this.OnNavigateToPage}  
            elements = {this.props.headerMenuItems}
            isMarketplace = {this.props.event.is_marketplace}
            facebookLink = {this.props.event.facebook}
            linkedInLink = {this.props.event.linkedIn}
            twitterLink = {this.props.event.twitter}
            instagramLink = {this.props.event.instagram}
            googleLink = {this.props.event.google}
			event = {this.props.event}
            phone = {this.props.event.phone_number}
            eventLogo = {this.props.event.logo}
            eventName = {this.props.event.name}
            eventAlias = {this.props.event.alias}
            availableLocales = {this.state.availableLocales}
            languageClick  = {this.languageClick}
            onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
            onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
            onClickAccountItem = {this.OnClickAccountItem}
            />
			{this.user != null && this.user.isBooking && Constants.showVirtualZone?
            <VirtualZone
                eventAlias = {this.props.event.alias}
				event = {this.props.event}
                onMenuItemClick = {(path)=>{
                    if(path != ''){
                        this.OnNavigateToPage(path)
                    }
                }}  
                unReadCount = {this.props.unReadCount}
                onNotificationSeen = {this.props.onNotificationSeen} 
                onClearNotifications = {this.props.onClearNotifications}
                notificationsCount = {this.props.unReadNotificationsCount}
                notifications = {this.props.notifications}
                onClickAccountItem = {this.OnClickAccountItem}
                onClickNotification = {(path,isExternal)=>{
                    if(isExternal){
                        window.open(path,"_blank")
                    }else{
                        this.OnNavigateToPage(this.props.event.alias+path)
                    }
                }}
                menuItems = {this.props.virtualMenuItems}
                user = {this.user} 
            /> : null}

			</div>
{this.state.loading != true ?
<>
            <section class = {`telehealth-inner-banner bg_primary py-3`}>
	<div class = "container py-2 text-center">
		<div class = "row">
			<div class = "col-12">
				<h2>
					<img class = "mr-3" src = "/images/networking/ic-sm-networking.svg" 
					alt = "kh-icon"/>
					{this.state.pageTitle}
				</h2>
			</div>
		</div>
	</div>
</section>


<section class = "blog knowledge-hub py-3">
	<div class="container text-center py-3">
		<p>
			{this.state.description}
		</p>
		<div class="row justify-content-center">
			<div class="col-md-6 col-lg-4 mb-3 mb-lg-0">
				<div class="thumbnail h-100">
					<div class = "thumbnail-img">
						<img src = "/images/networking/ic-search-contacts.svg" 
						alt = "icon-card"/>
					</div>
					<div class="caption">
						<h6 class = "text-main my-3">
							Search & Contact Members
						</h6>
						<p>
							Best presentations are available for you. Get involved as this is dummy text
						</p>
						{/* Search & Contact Members */}
						{/* { */}
						{/* // (this.user == null) || (this.user  && this.user.isBooking == false) ? */}
						<Link class = "btn d-block mx-auto rounded-0"  onClick={(e)=>{
                         e.preventDefault();
						this.moveToParticipent()}}>Visit</Link>
						{/* // :
						// null}
						// { */}
						{/* // this.user != null || this.user  && this.user.isBooking == true ?
						// <Link 
						// to={`/${this.props.event.alias}/participants`}
						//  class = "btn d-block mx-auto rounded-0">
						// 	Visit
						// </Link>
						// : null
	                    // } */}
					</div>
				</div>
			</div>
			<div class="col-md-6 col-lg-4 mb-3 mb-lg-0">

				<div class="thumbnail h-100">
					<div class = "thumbnail-img">
						<img src = "/images/networking/ic-forum.svg" 
						alt = "icon-card"/>
					</div>
					<div class="caption">
						<h6 class = "text-main my-3">
							Discussion Forums
						</h6>
						<p>
							Best presentations are available for you. Get involved as this is dummy text
						</p>
						<Link to={`/${this.props.event.alias}/forum`} class = "btn d-block mx-auto rounded-0">
							Visit
						</Link>
					</div>
				</div>
				
			</div>
			
		</div>
	</div>
</section>

{/* <section class = "middle-banner text-center">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<p>
					<img src="/images/logos/ic-diamond.svg" alt="icon-diamond"/>
				</p>
				<p>
					Interested to access Premium Content?
				</p>
				<a href="#" class = "btn">
					Become a Member
				</a>
			</div>
		</div>
	</div>
</section> */}
 <PremiumContent alias = {this.props.event}/>
<Footer2 
event = {this.props.event}
isMarketplace = {this.props.event.is_marketplace}
copyRightLogo = {this.props.event.copyright_logo}
copyRightText = {this.props.event.copyright_text}
copyRightLink = {this.props.event.copyright_link}
showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
/>
</>
:<div style = {{marginTop : 6,display:'flex',justifyContent:'center',height:'100vh',alignItems:'center'}}>
                                        <Loader
                                            type = 'Oval'
                                            color = "#00b6ff"
                                            height = {45}
                                            width = {45}
                                        />
                                    </div>}


</>

            )}
}
const mapStateToProps = state => (
	{
    contentType: state.marketPlaceReducer.dataTypes,
	loading:state.marketPlaceReducer.loading
	
})
const mapDispatchToProps = dispatch => ({
    getDataTypes : (alias,params) => dispatch(getDataTypes(alias,params)),
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Networking);