import React, { useEffect } from "react";
import "./styles.css";

const DeleteModel = (props) => {
  return (
    <div class="modal fade" id={props.id} role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered ct-modal" role="document">
        <div class="modal-content delete-modal">
          <div class="modal-body p-4">
            <h5>Delete</h5>
            <p>Are you sure you want to delete? This cannot be undone.</p>

            <div class="outer-ftopic p-3">
              <div class="forum-topic">
                <div class="c-user">
                  {props.forumInfo.profile != null ? (
                    <img src={props.forumInfo.profile} alt="c-user" />
                  ) : (
                    <img src="/images/logos/default-user.jpg" alt="c-user" />
                  )}
                </div>
                <h6>{props.forumInfo && props.forumInfo.title}</h6>
                <p>{`by ${props.forumInfo.name}`}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 pb-4">
            <button
              href="#"
              class="btn btn-cancel mb-3 mb-md-0"
              id="forumDelete"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              class="btn btn-delete"
              id="forum-delete"
              onClick={() => {
                props.forumDeleted(props.forumInfo.forumId);
              }}
            >
              {props.loadingSaveForum == true ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              <span style={{ marginLeft: "5px" }}>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModel;
