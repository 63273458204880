export const BookingDataLoaded = data => ({
    type: 'BOOKING_DATA_LOADED',
    payload : data
})
export const UpdateDelegatesInfo = delegates => ({
    type: 'UPDATE_DELEGATES',
    payload : delegates
})
export const UpdateOrderId = id => ({
    type: 'UPDATE_ORDERID',
    payload : id
})