import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getEventAgenda(eventAlias,type,callback){
        axios.get(`${eventAlias}/getEventAgenda`,
            {
                params: {type: type,isActive:1,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },
    async getPollQuestionData(eventAlias,token,params,callback){
        await axios.get(
            `${eventAlias}/polls`,{
                params:params,
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token
                }
            }
        ).then(response=>{
           if(response.data.status =="success"){
               callback(null,response.data.data)
           }
           else{
            callback(null,[])
           }
        })
        .catch(err=>{
            callback(err,null)
        })


    },
    async savePollQuestion(eventAlias,token,params,callback){
        await axios.post(
            `${eventAlias}/polls/save`,
                params,{
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token
                }
            
            }).then(response=>{
           if(response.data.status =="success"){
               callback(null,response.data.data)
           }
           else{
            callback(null,[])
           }
        })
        .catch(err=>{
            callback(err,null)
        })


    },
    markAgendaFavorite(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/favourite/save`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.data)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback(response.data.error,null)
                }
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    async getGroupChat(eventAlias,token,params,callback){
        await axios.get(`${eventAlias}/getGroupChat`,
        {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.chats)
            }else{
                callback(null,[])
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    saveGroupChatMessage(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/saveGroupChat`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.messageId,response.data.id)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback(response.data.error,null)
                }
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    deleteGroupChatMessage(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/deleteGroupChat`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.masg)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback(response.data.error,null)
                }
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    updateLikeCount(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/updateGroupChat`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.masg)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback(response.data.error,null)
                }
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    requestCertificate(eventAlias,data,callback){
        axios.post('/certificates/request',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.message)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback(response.data.error,response.data.error.message)
                }
            }
        }).catch(error => {
            callback(error,"Something went wrong . Please try again !")
        })
    }
}