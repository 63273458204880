import axios from '../../axios/index'
import { Constants } from '../../../Constants'
const qs = require('querystring')

export default {
    sendEventSubscription(eventAlias,email,type,callback){

        const requestBody = {
            email: email,
            type : type,
            isActive: 1
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            }
        }
        axios.post(`${eventAlias}/sendEventNewsLetter`, requestBody, config)
            .then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    }
}