export const ExhibitionHallsLoaded = data => ({
    type: 'EXHIBITION_HALLS',
    payload : data
})
export const ExhibitorDataLoaded = data => ({
    type: 'EXHIBITOR_DATA_LOADED',
    payload : data
})
export const RemoveScroll = data => ({
    type: 'REMOVE_SCROLL',
    payload : data
})