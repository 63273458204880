import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactHtmlParser from "react-html-parser";
import { isMobile } from "react-device-detect";
import ContentLoader from "react-content-loader";
import NewsLetter from "../../NewsLetter";
import Footer2 from "../../Footer/Footer2";
import { Trans } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
const Content = (props) => {
  const [blog, setBlog] = useState([...props.blogs]);

  useEffect(() => {
    console.log(props);
    setBlog([...props.blogs]);
  }, [props.blogs]);
  const extractContent = (s) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  const OnClickReadMore = (link) => {
    window.scrollTo(0, 0);
  };
  const OnNavigateToPage = (path, data = null) => {
    console.log(path);
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    }
    if (data) {
      props.history.push({
        pathname: path,
        state: data,
      });
    } else {
      props.history.push(path);
    }
  };
  return (
    <>
      {props.loading == true ? 
      <div
        style={{
          width: "100%",
          height: "100vh",
        //   position: "absolute",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          top: 0,
          left: 0,
          background: "#0000000d",
          zIndex: 999,
        }}
      >
        <div style={{ 
        // position: "absolute", 
        left: "50%", top: 250 }}>
          <Loader type="Oval" color="#00b6ff" height={40} width={40} />
        </div>
      </div>
       : 
      blog.length > 2 ? (
        <section className="blog py-2">
          <div className="container">
            <h2 className="text-center font-weight-bold text-uppercase">
              {props.title}
            </h2>
            <div className="row justify-content-center py-3">
              {blog
                ? blog.map((feature, index) => {
                    let descriptionString =
                      feature.description != null
                        ? extractContent(feature.description)
                        : "";
                    if (feature.isActive == 1) {
                      var hrefLink = `/blog/${feature.title}/${feature.id}`.replaceAll(' ','-');
                      var description =
                        descriptionString.length > 250
                          ? feature.description.substr(0, 200)
                          : feature.description;
                      let image =
                        feature.image != null
                          ? feature.image
                          : "/images/image-placeholder.png";
                      let readMoreVisibility =
                        descriptionString.length >= 180 ? "block" : "none";
                      let isLocalUrl = true;
                      if (feature.isEnable == 1) {
                        let link = feature.button_link;
                        if (!/^(?:f|ht)tps?\:\/\//.test(link)) {
                          link = "http://" + link;
                        }
                        let url = new URL(link);
                        if (
                          url.host.indexOf("preview") > -1 ||
                          url.hostname == "localhost"
                        ) {
                          isLocalUrl = true;
                          let split = url.pathname.split("/");
                          if (split.length > 2) {
                            let path = "";
                            for (var i = 2; i < split.length; i++) {
                              path += split[i];
                              if (i < split.length - 1) {
                                path += "/";
                              }
                            }
                            hrefLink =
                              "/" +props.event.alias + "/" + path;
                          }
                        } else {
                          isLocalUrl = false;
                          hrefLink = link;
                        }
                      }
                      let heightClass = isMobile ? "" : "h-100";
                      return (
                        <div
                          style={{
                            marginTop:
                              !isMobile && index > 0 && index > 2 ? 15 : 0,
                          }}
                          className="col-md-4 mb-3 mb-md-0"
                          key={index}
                        >
                          <div className={"thumbnail " + heightClass}>
                            {
                              <div
                                style={{
                                  backgroundImage: `url(${image})`,
                                  height: 200,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              ></div>
                            }
                            <div className="caption">
                              <h6 className="f-600 text-main my-3">
                                {feature.title}
                              </h6>
                              {/* ReactHtmlParser(description) */}
                              <p
                                style={{
                                  marginBottom:
                                    descriptionString.length < 180 ? 0 : "",
                                }}
                                className={
                                  descriptionString.length >= 180
                                    ? "blogEffect"
                                    : ""
                                }
                              >
                                {descriptionString.substr(0, 180)}
                              </p>
                              {/* <Linkify componentDecorator={componentDecorator}><p className = {descriptionString.length >= 250 ? "blogEffect" : ""}>{ descriptionString.substr(0,250)}</p></Linkify> */}
                              {/* <span onClick = {()=>this.props.onClickReadMore(index)} style = {{cursor : 'pointer',position : 'absolute',bottom : 12,width : '90%'}} className = "btn bg_primary d-block mx-auto rounded-0"> */}
                              {isLocalUrl ? (
                                <Link
                                  onClick={OnClickReadMore}
                                  to={hrefLink}
                                  style={{ display: readMoreVisibility }}
                                  className="btn mx-auto rounded-0 readMore"
                                >
                                  <Trans>readMore</Trans>
                                </Link>
                              ) : (
                                <a
                                  href={hrefLink}
                                  target="_blank"
                                  style={{ display: readMoreVisibility }}
                                  className="btn mx-auto rounded-0 readMore"
                                >
                                  <Trans>readMore</Trans>
                                </a>
                              )}
                              {/* </span> */}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                : null}
            </div>
          </div>
        </section>
      ) : (
        <section class="blog py-2">
          <div class="container blog-container">
            <h2 className="text-center font-weight-bold text-uppercase pb-3">
              Blogs
            </h2>
            {blog.map((item, index) => {
              let image =
                item.image != null
                  ? item.image
                  : "/images/image-placeholder.png";
              let dynamicClass = index == 0 ? "bg_primary" : "bg_secondary";
              let description = item.description.substr(0, 420);
              let hrefLink = `/blog/${item.title}/${item.id}`.replaceAll(' ','-');
              let textClass = index == 0 ? "" : "blogColor";
              let readMoreVisibility = description.length >= 420 ? "" : "none";
              let paddingTextRight = index == 0 ? "px-3" : "px-4";
              let isLocalUrl = true;
              if (item.isEnable == 1) {
                let link = item.button_link;
                if (!/^(?:f|ht)tps?\:\/\//.test(link)) {
                  link = "http://" + link;
                }
                let url = new URL(link);
                if (
                  url.host.indexOf("preview") > -1 ||
                  url.hostname == "localhost"
                ) {
                  isLocalUrl = true;
                  let split = url.pathname.split("/");
                  if (split.length > 2) {
                    let path = "";
                    for (var i = 2; i < split.length; i++) {
                      path += split[i];
                      if (i < split.length - 1) {
                        path += "/";
                      }
                    }
                    hrefLink = "/" + props.event.alias + "/" + path;
                  }
                } else {
                  isLocalUrl = false;
                  hrefLink = link;
                }
              }
              return (
                <div
                  class={"row blog-card-vII overflow-hidden " + dynamicClass}
                  id="blogcard-I"
                >
                  {index == 0 ? (
                    <div class="col-sm-6 pl-md-5 py-5">
                      <div
                        style={{ backgroundImage: `url(${image})` }}
                        class="blog-bg-img"
                      ></div>
                    </div>
                  ) : null}

                  <div class="col-sm-6 pr-md-4">
                    <div class={"blog-text py-5 " + paddingTextRight}>
                      <h4 class="f-600" class={textClass}>
                        {item.title}
                      </h4>
                      <p class={textClass}>{ReactHtmlParser(description)}</p>
                      {isLocalUrl ? (
                        <Link
                          onClick={OnClickReadMore}
                          to={hrefLink}
                          style={{ display: readMoreVisibility }}
                          class={index == 0 ? "text-light" : "blogColorLink"}
                        >
                          <Trans>readMore</Trans>
                        </Link>
                      ) : (
                        <a
                          href={hrefLink}
                          target="_blank"
                          style={{ display: readMoreVisibility }}
                          class={index == 0 ? "text-light" : "blogColorLink"}
                        >
                          <Trans>readMore</Trans>
                        </a>
                      )}
                    </div>
                  </div>
                  {index == 1 ? (
                    <div class="col-sm-6 pr-md-5 py-5">
                      <div
                        style={{ backgroundImage: `url(${image})` }}
                        class="blog-bg-img"
                      ></div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </section>
      )}
      <NewsLetter
        title="SUBSCRIBE OUR NEWS LETTER"
        event={props.event}
        event_alias={props.event.alias}
        buttonText="Subscribe"
        type="newsLetter"
      />
      <Footer2
        event={props.event}
        copyRightLogo={props.event.copyright_logo}
        copyRightText={props.event.copyright_text}
        copyRightLink={props.event.copyright_link}
        showCopyRight={props.event.copyright_text || props.event.copyright_logo}
      />
    </>
  );
};

export default withRouter(Content);
