import React, { useState, useEffect } from 'react';
import './index.css'
import {Trans} from 'react-i18next';
import api from '../../JobBoard/api.js'; //CHANGE PATH TO YOURS
import ContentLoader from '../../JobBoard/ContentLoader.js'


const ModalElement = (props) => {
    const [jobs, setJobs] = useState([]);
    const [load, setLoad] = useState(false);
    //fetching jobs
    useEffect(() => {
        //console.log("New modal")
        let companyId = props.companyId ? props.companyId : ''
        api.getJobs(companyId,0).then(res => {
            //setTimeout(() => { setLoad(true); }, 5000)
            setLoad(true)
            //console.log("get data", res.data);
            setJobs(res.data);
        }).catch(err => {
            setLoad(true)
        });
    }, []);
    const apply=(jobId)=>{
        let hash = localStorage.getItem('login_hash')
        let selectedLocale = localStorage.getItem("language"+props.alias) || 'en';
        let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
        +window.location.origin+"&alias="+props.alias+"&locale="+selectedLocale+"&route=/users/company/jobs/"+jobId
        window.location.href = location
    }
    if (!load) {
        return (
            <div class="modal" id="job-modal">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content bio-modal rounded-0 job-modal">

                        <div class="modal-header px-4 py-3 text-left">
                            <h3 class="w-100 font-weight-bold">Current Openings</h3>
                            <button data-dismiss="modal">
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>

                        <div class="modal-body px-4 pb-4">

                            {/*<div class="row py-2 participants-listing">
                                <div class="col-12">
                                     <form>
                                        <div class="form-row">
                                            <div class="col-md-8 input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">
                                                        <i class="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="sp_input" placeholder="Search Jobs"/>
                                            </div>
                                            <div class="col-md-4 pl-0 pl-md-3">
                                                <label class="switch mt-2 ml-3">
                                                    <input type="checkbox" />
                                                    <span class="sliderr round"></span>
                                                </label>
                                                <span class="ml-2 switch-txt f-600">Show Matched Jobs</span>
                                            </div>
                                        </div>
                                    </form> 
                                </div>
                            </div>*/}
                            <ContentLoader />
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div class="modal" id="job-modal">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content bio-modal rounded-0 job-modal">

                        <div class="modal-header px-4 py-3 text-left">
                            <h3 class="w-100 font-weight-bold"><Trans>currentOpennings</Trans></h3>
                            <button data-dismiss="modal">
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>

                       <div class="modal-body px-4 pb-4" style = {{minHeight : 200}}>
                           {jobs.length == 0 ?
                            <div style = {{width : '100%',minHeight: 200,display: 'flex',justifyContent : 'center',alignItems : 'center'}}>
                                <Trans>noJobs</Trans>
                            </div> :
                            /* FORM CODE */
                             /*<div class="row py-2 participants-listing">
                                <div class="col-12">
                                     <form>
                                    <div class="form-row">
								<div class="col-md-8 input-group">
									<div class="input-group-prepend">
										<span class="input-group-text" id="basic-addon1">
											<i class="fas fa-search"/>
										</span>
									</div>
									<input type="text" class="form-control" id="sp_input" placeholder="Search Jobs"></input>
								</div>
								<div class="col-md-4 pl-0 pl-md-3">
									<label class="switch mt-2 ml-3">
										<input type="checkbox"/>
										<span class="sliderr round"></span>
									</label>
									<span class="ml-2 switch-txt f-600">Show Matched Jobs</span>
								</div>
							</div> 

                                    </form> 
                                </div>
                            </div>*/
                            <ul class="list-group list-group-flush">
                                {jobs.map((job) => (
                                    <li class="list-group-item px-1">
                                        <div class="row">
                                            <div class="col-10 col-md-8 text-md-left" >
                                                <span>{job.name}</span>
                                            </div>
                                            <div class="col-2 col-md-4 text-md-right">
                                                <a style={{cursor: 'pointer'}} onClick={()=>apply(job._id)} class="btn-apply">
                                                    <span class="d-none d-md-block">
                                                        <Trans>viewJob</Trans>
										            </span>
                                                    <i class="fas fa-angle-right d-block d-md-none"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                ))
                                }
                            </ul>}
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}
export default ModalElement;