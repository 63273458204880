import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../SpeakerV1/api';
import { Carousel } from 'react-responsive-carousel'
import $ from 'jquery';
import 'bootstrap';
import {isMobile} from 'react-device-detect'
import {  SpeakersDataLoaded } from '../Speaker/actions';
import { connect } from 'react-redux'
import { SpeakerHomeDataLoaded } from '../SpeakerV1/actions';
import IntersectionVisible from 'react-intersection-visible'
import hexToRgba from 'hex-to-rgba';
import ContentLoader from 'react-content-loader'
import {Trans} from 'react-i18next'
export class SpeakerV2 extends React.Component{
	minSliderHeight = 485
	row_items = 0
	_isMounted = false
    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias: this.props.event_alias,
			speakers : {},
			backgroundColor : "",
			showContent : false,
			isLoading : true
        };
		this.once = false
    }
	componentToHex = (c) => {
		var hex = c.toString(16);
		return hex.length == 1 ? "0" + hex : hex;
	}
    componentDidMount(){
		// setTimeout(() => {
		// 	let style = getComputedStyle(document.querySelector('.bg_primary'))
		// 	var backgroundColor = style.backgroundColor
		// 	var rgb = backgroundColor.match(/\d+/g);
		// 	backgroundColor = "#" + this.componentToHex(parseInt(rgb[0])) + this.componentToHex(parseInt(rgb[1])) + this.componentToHex(parseInt(rgb[2]));
		// 	this.setState({backgroundColor : backgroundColor})
		// }, 0);
		this._isMounted = true
		if(isMobile){
			this.row_items = 2
			this.minSliderHeight = 800
		}
		else{
			this.row_items = 4
			this.minSliderHeight = 485
		}
		if(this.props.defaultSpeakers != null){  // incase component is receiving speakers
			this.setState({speakers : this.props.defaultSpeakers,showContent : true,isLoading : false})
			return
		}
		if(this.props.speakers != null){
			let showContent = false
			if(this.props.speakers.length > 0){
				showContent = true
			}
			this.setState({speakers : this.props.speakers,isLoading : false,showContent})
		}else{
			api.getEventSpeakers(this.state.event.alias,this.props.type,(err,data)=>{
				if(err == null && this._isMounted){
					this.props.speakersLoaded(data.speakers,true)
					this.setState({speakers:data.speakers , startIndex : 0});
				}
				this.setState({showContent : true,isLoading : false})
			})
		}
		this.once = false
	}
	OnVisibilityChange = (isVisible) => {
		if(isVisible && !this.once){
			setInterval(() => {
				$('#speakerV2Slider').carousel('next')
			}, 5000);
			this.once = true
		}
    }
	componentWillUnmount(){
		this._isMounted = false
	}
    render(){
		if(this.state.isLoading){
			return(
				<section className = "speaker-2 py-5 pb-2" id = "speakers">
					<div className="container">
					<h5 className = "text-center font-weight-bold text-uppercase"><Trans>speakers</Trans></h5>
					<ContentLoader style = {{padding : 10}} viewBox = "0 0 400 80">
						<rect x="80" y="20" rx="3" ry="3" width="100" height="7" />
						<rect x="80" y="37" rx="3" ry="3" width="80" height="6" />
						<circle cx="44" cy="42" r="28" />
						<rect x="256" y="20" rx="3" ry="3" width="100" height="7" />
						<rect x="256" y="37" rx="3" ry="3" width="80" height="6" />
						<circle cx="220" cy="42" r="28" />
					</ContentLoader>
					</div>
				</section>
			)
		}
        const speakers = this.state.speakers;
        if (speakers.length > 0){

            var speakerRows = Math.ceil(speakers.length/this.row_items);

            return(
				<IntersectionVisible onShow = {()=>this.OnVisibilityChange(true)} onHide = {()=>this.OnVisibilityChange(false)}>
				<section className = "speaker-2 py-5 pb-2" id="speakers">
					<div className="container">
						<h5 className = "text-center font-weight-bold text-uppercase"><Trans>speakers</Trans></h5>
						<div className="row pt-5 pb-2">
							<div id="speakerV2Slider" className="carousel vert slide w-100" data-ride="carousel" data-interval="5000">

                                {/* <!-- Indicators --> */} 
								{speakers.length > 4 ?
								<ul className="carousel-indicators custom_indicator" style = {{position : 'absolute',left : '103%'}}>

                                    {
                                        Array.from({length: speakerRows}, function(item,index){
                                            if(index == 0){
												return(
													<li data-target="#speakerV2Slider" data-slide-to={index} className="active"></li>
												);
											}else{
												return(
													<li data-target="#speakerV2Slider" data-slide-to={index}></li>
												);
											}
                                        })
                                    }

								</ul>:null}

                                {/* <!-- The slideshow --> */}

								<div className="carousel-inner slider_inner">
								{/* <Carousel  axis = 'vertical' ref = {(ref)=>this.carouselRef = ref} autoPlay = {true} showArrows = {true} showThumbs = {false} infiniteLoop = {true} interval = {1000}> */}
                                    {

                                        Array.from({length: speakerRows}, (item,ind)=>{
                                            var item_div = ind == 0 ? "carousel-item active" : "carousel-item";
											var heightClass = speakers.length > 2 ? "speakerV2Slider" : ""

                                            var rec = ind*this.row_items;

                                            return(

													<div className = {item_div + " " + heightClass} >
														<div className="row">

                                                            {
                                                                speakers.map( (s,i)=>{

                                                                    if(i<this.row_items && i+rec < speakers.length){
                                                                        return(
																			<div className="col-md-6 mb-2">
																				<div className = "card_2-inner rounded border overflow-hidden">
																					<div className = "row speakerItemContent">
																						<div className = "col-md-4 position-rlative primary_c">
																							<div className = "card-sp2 h-100">
																								{
																									<div className = "user_imb-2 p-3">
																										{speakers[i+rec].image != null ?
																											<img src = { speakers[i+rec].image } className = "rounded-circle shadow" alt = "user_1-img"/> : 
																											<img src = '/images/profile-placeholder.png' className = "rounded-circle shadow" alt = "user_1-img"/>
																										}
																									</div>
																								}

																							</div>
																						</div>

																						<div className = "col-md-8 p-0">
																							<div className = "card2-caption p-5 bg-light h-100">
																								<h6 className = "font-weight-bold">{speakers[i+rec].name} {speakers[i+rec].surname == null ? "" : speakers[i+rec].surname}</h6>
																								<p className = "mb-0 mt-2">{ speakers[i+rec].job_title }</p>
																								<p className = "mt-2 f-500">{ speakers[i+rec].company }</p>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
                                                                        );
                                                                    }
                                                                })
                                                            }


														</div>
													</div>
                                            );







                                        })
                                    }

								</div>




							</div>
						</div>

						<div className="row py-2 justify-content-center">
							<a onClick = {this.props.onClickSeeAllSpeakers} style = {{cursor : 'pointer'}} className = "btn-default see_btn">
								<Trans>seeAll</Trans>
							</a>
						</div>

					</div>
				</section>
				</IntersectionVisible>
            );

		}//end of if
		else{
			return null
		}
    }
}

const mapStateToProps = state => ({
    speakers: state.speakersHome.speakersHomeData
})
const mapDispatchToProps = dispatch => ({
    speakersLoaded : (data,home) => dispatch(SpeakerHomeDataLoaded(data,home))
})
export default connect(mapStateToProps, mapDispatchToProps)(SpeakerV2);