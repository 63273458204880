import axios from '../axios/index'
import { Constants } from '../../Constants'
import { data } from 'jquery'
import utility, { monthNames } from '../../Utility/utility'

export default {
  getAllVideos (eventAlias, params, callback) {
    axios
      .get(`${eventAlias}/videos/all`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          apiToken: Constants.API_TOKEN
        }
      })
      .then(response => {
        if (response.data.status == 'success') {
          for (var i = 0; i < response.data.videos.length; i++) {
            let date = utility.convertUTCDateToLocalDate(
              new Date(response.data.videos[i].created_at)
            )
            let dateString =
              date.getDate() +
              ' ' +
              monthNames[date.getMonth() - 1] +
              ' ' +
              date.getFullYear()
            response.data.videos[i].dateString = dateString
            if (response.data.videos[i].link != null) {
              if (
                response.data.videos[i].link?.includes('player.vimeo') ||
                response.data.videos[i].link?.includes('vimeo')
              ) {
                response.data.videos[i].link = response.data.videos[
                  i
                ].link?.includes('player.vimeo')
                  ? response.data.videos[i].link
                  : response.data.videos[i].link?.replace(
                      'vimeo.com',
                      'player.vimeo.com/video'
                    )
              } else {
                let id = utility.getYoutubeVideoId(response.data.videos[i].link)
                response.data.videos[i].link =
                  'https://www.youtube.com/embed/' + id
              }
            }
          }
          var obj = {
            title: response.data.page_details.title,
            description: response.data.page_details.description,
            videos: response.data.videos
          }
          callback(null, obj)
        } else {
          callback(
            {
              message: 'Error'
            },
            null
          )
        }
      })
      .catch(error => {
        callback(error, null)
      })
  },
  async getFormSubmittionStatus (eventAlias, userId, callback) {
    await axios
      .get(`${eventAlias}/getEventVideoPageFromDetails`, {
        params: { userId: userId },
        headers: {
          'Content-Type': 'application/json',
          apiToken: Constants.API_TOKEN
        }
      })
      .then(response => {
        callback(response.data.status == 'success')
      })
      .catch(error => {
        callback(false)
      })
  },
  saveContactForm (eventAlias, data, callback) {
    axios
      .post(`${eventAlias}/sendEventVideoPageFrom`, data, {
        params: { isPreview: Constants.isPreview },
        headers: {
          'Content-Type': 'application/json',
          apiToken: Constants.API_TOKEN
        }
      })
      .then(response => {
        callback(null, response.data)
      })
      .catch(error => {
        callback(error, null)
      })
  }
}
