import React, { Component,useEffect } from 'react';
import MapFloorContent from './content';
import { compose } from "redux";
import { connect } from "react-redux";
import { getFloordata } from './actions';
import { withRouter } from "react-router-dom";
import utility from '../../../Utility/utility';
const MapFloor  = (props)=>{
    const user =  utility.getLoggedInUser(props.event.alias)
    useEffect(()=>{
        props.getFloordata(props.event.alias)
    },[])

    useEffect(()=>{
      console.log(Object.keys(props.mapfloordata).length)
    },[props.mapfloordata])
    return (
      props.mapfloordata && props.mapfloordata.virtualFloorPlan &&  Object.keys(props.mapfloordata.virtualFloorPlan).length > 0 ? <MapFloorContent mapFloorData = {props.mapfloordata} type={props.type} user = {user} alias = {props.event.alias}/>:null)
}

const mapStateToProps = (state) => ({
    mapfloordata: state.mapFloorReducer.mapfloordata,
  });
  const mapDispatchToProps = (dispatch) => ({
    getFloordata: (alias) => dispatch(getFloordata(alias)),
  });
  export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(MapFloor);