import axios from '../axios/index'
import { Constants } from '../../Constants';
import utility from '../../Utility/utility';

export default {
    signUpUser(eventAlias,data,isJobFair,callback){
        axios.post(`${eventAlias}/signUp`,
        data,
        {
            params : {isPreview : Constants.isPreview},
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN}
        }).then(response => {
            if(response.data.status == "success"){
                let user = response.data.user
                if(isJobFair == 1){
                    let dataObj = {
                        email : data.email,
                        first_name : data.firstName,
                        last_name : data.lastName,
                        second_name : data.secondName,
                        password : data.password,
                        verifyUser : true,
                        login_hash : utility.makeid(128),
                        socialId: data.socialId,
                        idioma_consent: data.idioma_consent
                    }
                    if(data.phone) dataObj.contact_number = data.phone
                    this.jobFairSignUp(dataObj,(jwtToken, _id)=>{
                        user.jobFairJwt = jwtToken
                        user.jobFairId =  _id
                        callback(null,user)
                    })
                }else{
                    callback(null,user)
                }
            }
            else{
                if(isJobFair == 1){
                    if(response.data.processCode == 2 || response.data.processCode == 3){
                        this.initiateJobFairSignup(data,({jwtToken,jobFairId})=>{
                            response.data.user.jobFairJwt = jwtToken
                            response.data.user.jobFairId = jobFairId
                            callback(response.data,null)
                        })
                    }
                    
                }else{
                    callback(response.data,null)
                }
                
            }
        }).catch(error => {
            callback(error,null)
        })
    },
    initiateJobFairSignup(data,callback){
        let dataObj = {
            email : data.email,
            first_name : data.firstName,
            last_name : data.lastName,
            second_name : data.secondName,
            password : data.password,
            verifyUser : true,
            login_hash : utility.makeid(128),
            socialId: data.socialId
        }
        if(data.phone) dataObj.contact_number = data.phone
        this.jobFairSignUp(dataObj,(jwtToken, _id)=>{
            callback({jwtToken,_id})
        })
    },
    jobFairSignUp(body,callback){
        axios({
            url: '/users/candidates',
            baseURL: process.env.REACT_APP_JOBFAIR_API_ENDPOINT,
            method : 'POST',
            data : body,
            headers: { "Content-Type": "application/json" }
        }).then(response => {
            localStorage.setItem("login_hash",body.login_hash)
            callback(response.data.jwt_token, response.data._id)
        }).catch(error => {
            callback(error)
        })
    },
    async fetchHearOptions(language){

        let res = await fetch(process.env.REACT_APP_FETCH_HEAROPTIONS_URL+`/nodes/${language}/origin`)                 
        return res.json() }

}