import React from 'react';
import {Header} from "../Header/Index";
import {Footer1} from "../Footer/Footer1";
import {Footer2} from "../Footer/Footer2";

import { withRouter } from 'react-router-dom'
import Testimonials from '../Testimonials';
import { Constants } from '../../Constants';
import NewsLetter from '../NewsLetter';
import Content from './content';
import utility from '../../Utility/utility';
import VirtualZone from '../Header/virtualZone';
class About extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            event : props.event
        }
    }
    componentDidMount (){
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }
    getClasses = ()=>{
        console.log(this.props.event.is_marketplace)
        if(this.props.event.is_marketplace == "1"){
            console.log("Kia mASLA HY BHAI")
            console.log( Constants.showVirtualZone)

            let user = utility.getLoggedInUser(this.props.event.alias)
            console.log(this.user)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
                return ''
            }
            else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return (
            <div>
                <div id="header-top">
                <Header 
                    selectedIndex = {1} 
                    onMenuItemClick = {this.OnNavigateToPage}  
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    isMarketplace = {this.props.event.is_marketplace}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    event = {this.props.event}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    
                    eventAlias = {this.props.event.alias}
                    event = {this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    onClickAccountItem = {this.OnClickAccountItem}
                    notifications = {this.props.notifications}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div>
                <div className = {`content-main`}>
                    <Content />
                    <Testimonials event={this.state.event} event_alias={this.state.event_alias} type={Constants.Testimonial} />
                    {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ?<NewsLetter 
                        title = "SUBSCRIBE OUR NEWS LETTER" 
                        event={this.props.event} 
                        event_alias={this.props.event_alias}
                        buttonText = "Subscribe"
                        type = 'newsLetter'
                    />:null}
                    <Footer1 
                        event={this.props.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                </div>
                <Footer2 
                event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        );
    }
}

export default withRouter(About)