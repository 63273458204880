import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  withRouter
} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";
import api from "./api";
import ContentLoader from "react-content-loader";
import PermiumUserModal  from "../../modals/premiumUserModal";
const Offer = (props) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blogdata, setblogData] = useState([]);
  const [blofTilte,setBlogTitle] = useState('')
  const OnNavigateToPage = (path,data = null) => {
    window.scrollTo(0, 0);
    if(path.indexOf('home') > -1){
      path = "/"
  }else if(path != '/'){
      path = "/"+path
  }
  if(data == null){
      props.history.push(path)
  }else{
      props.history.push({
          pathname : path,
          state : data
      })
  }
  };
  const userNavigation = (type) =>{

    if(type=='virtual_exhibition'){
        OnNavigateToPage(`${props.event.alias}/${type}`);
      }
    
    else{
      OnNavigateToPage(`${props.event.alias}/${type}`);
    }
   
  }
  useEffect(() => {
    let params = {
      type: "blog",
    };
    setLoading(true);
    api.getTelehealthPost(props.event.alias, "blog", (err, data) => {
      if (data) {
        setblogData([...data.feature.features]);
        setLoading(false);
        setBlogTitle(data.feature.title)
      }
    });
  }, []);

  useEffect(() => {
    console.log(blogdata);
  }, [blogdata]);
  return (
    <>
      <PermiumUserModal
        id="networkFile"
        iconClass="fa-lock"
        title="Login"
        description="<p>Please Login first before move forward.</p>"
        buttonText="Login"
        showButton={true}
        onClose = {()=>{
          $("#networkFile").modal('hide')
          $('.modal-backdrop').remove();
        }}
        onClickBookNow={() => {
          $("#networkFile").modal("hide");
          $(".modal-backdrop").remove();
          OnNavigateToPage(`${props.event.alias}/login`);
        }}
      />
      <PermiumUserModal
        id="member"
        iconClass="fa-star"
        title="Premium Session"
        description="<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
        buttonText="Join Now"
        showButton={true}
        onClose = {()=>{
          $("#member").modal("hide")
          $('.modal-backdrop').remove();
        }}
        onClickBookNow={() => {
          $("#member").modal("hide");
          $(".modal-backdrop").remove();
          OnNavigateToPage(`${props.event.alias}/membership`);
        }}
      />
     <section class="blog py-3">
        <div class="container">
          {blogdata && blogdata.length > 0 ? <h2>{blofTilte.toUpperCase()}</h2>:null}
          {blogdata.length > 0 && loading == false ? (
            <div class="row">
              {blogdata &&
                blogdata.map((item) => {
                  return (
                    <div class="col-md-4 mb-3 mb-md-0">
                      <div class="thumbnail h-100">
                        <img src={item.image} alt="icon-card" />
                        <div class="caption">
                          <h6 class="f-700 text-main my-3">{item.title}</h6>
                          <p>
                          {ReactHtmlParser(item.description)}
                          </p>
                          {/* {item.button_link != "virtual_exhibition" ? */}
                           <Link
                          //  to={`/${props.event.alias}/${item.button_link}`}
                           class="btn d-block mx-auto rounded-0"
                           onClick={(e)=>{
                             e.preventDefault();
                             if(item.button_link == "knowledge-hub"){
                              OnNavigateToPage(`${props.event.alias}/${item.button_link}`)

                             }
                             else{
                              if (props.user == null) {
                                $("#networkFile").modal("show");
                              } else if (
                                props.user != null &&
                                props.user.isBooking == false
                              ) {
                                $("#member").modal("show");
                              } 
                              else{
                                userNavigation(item.button_link)
                              }

                             }
                           }}
                         >
                           Learn more
                         </Link>
                          {/* : */}
                          {/* <Link
                            class="btn d-block mx-auto rounded-0"
                            onClick={() =>{
                              // if(item.button_link == "virtual_exhibition")
                              if (props.user == null) {
                                $("#networkFile").modal("show");
                              } else if (
                                props.user != null &&
                                props.user.isBooking == false
                              ) {
                                $("#member").show("show");
                              } 
                              else{
                                userNavigation(item.button_link)
                              }

                              
                            }}
                          >
                            Learn more
                          </Link>} */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div class="col-md-4 mb-3 mb-md-0">
                <div class="thumbnail h-100">
                  <img src="./images/blog/ic-knowledge.svg" alt="icon-card" />
                  <div class="caption">
                    <h6 class="f-600 text-main my-3">KNOWLEDGE HUB</h6>
                    <p>
                      The last few years have witnessed rapid progress in
                      adopting Telehealth services across GCC and MENA regions.
                      COVID - 19 pandemic has accelerated ...
                    </p>
                    <Link
                      to={`/${props.event.alias}/knowledge-hub`}
                      class="btn d-block mx-auto rounded-0"
                    >
                      Join Now
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-md-4 mb-3 mb-md-0">
                <div class="thumbnail h-100">
                  <img src="./images/logos/ic-virtual.svg" alt="icon-card" />
                  <div class="caption">
                    <h6 class="f-600 text-main my-3">
                      VIRTUAL EXHIBITION ZONE
                    </h6>
                    <p>
                      The last few years have witnessed rapid progress in
                      adopting Telehealth services across GCC and MENA regions.
                      COVID - 19 pandemic has accelerated ...
                    </p>
                    {props.user == null ||
                    (props.user != null && props.user.isBooking == false) ? (
                      <Link
                        id="networkZone"
                        class="btn d-block mx-auto rounded-0"
                        onClick={() => {
                          if (props.user == null) {
                            $("#networkFile").modal("show");
                          } else if (
                            props.user != null &&
                            props.user.isBooking == false
                          ) {
                            $("#member").show("show");
                          }
                        }}
                      >
                        Join Now
                      </Link>
                    ) : null}

                    {props.user != null && props.user.isBooking == true ? (
                      <Link
                        to={`/${props.event.alias}/virtual_exhibition`}
                        class="btn d-block mx-auto rounded-0"
                      >
                        Join Now
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div> */}
            </div>
          ) : 
            loading == true ?
            <section class="blog py-2">
              <div class="container blog-container">
                {/* <h2 className = "text-center font-weight-bold text-uppercase pb-3">What we Offer</h2> */}
                <ContentLoader style={{ padding: 10 }}>
                  <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />
                  <rect x="70" y="14" rx="4" ry="4" width="300" height="8" />
                  <rect x="70" y="30" rx="3" ry="3" width="250" height="6" />
                  <rect x="0" y="60" rx="5" ry="5" width="50" height="50" />
                  <rect x="70" y="74" rx="4" ry="4" width="300" height="8" />
                  <rect x="70" y="90" rx="3" ry="3" width="250" height="6" />
                </ContentLoader>
              </div>
            </section>:null}
        </div>
      </section>
    </>
  );
};

export default withRouter(Offer);
