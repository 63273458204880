export const UpdateParticipantsList = data => ({
    type: 'PARTICIPANTS_LIST',
    payload : data
})
export const UpdateOnlineParticipantsList = data => ({
    type: 'ONLINE_PARTICIPANTS',
    payload : data
})
export const updateOnlineUser = data =>({
    type:"ONLINE_USER",
    payload:data

})

export const meetingParticipent = data=>(
{
    type:"MEETING_USER",
    payload:data
})
