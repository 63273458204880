import React from 'react';
import api from './api';
import signUpApi from '../Signup/api'
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import ResetPassword from './ResetPassword';
import EmailLogin from './EmailLogin';
import { useLastLocation } from 'react-router-last-location';
import MetaTags from 'react-meta-tags';
import { Constants } from '../../Constants';
import appLevelApi from '../App/api'
import { subscribeUser } from '../Notification/subscription';
import inviteApi from '../1 - Sub menu components/Invitation/api'
import { GAPageView, GARecordEvent } from '../../Utility/GA';
import {Trans, withTranslation} from 'react-i18next'
import { compose } from 'redux';
import { data } from 'jquery';

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loadingError : '',
            recoverPassword : false,
            processingLogin : false,
            loginError : '',
            resetEmailSent : false,
            resetPasswordSuccessMsg : '',
            socialEmailLogin : false
        };
        this.lastLocation = null
        this.jobFairNavigation = false
        this.jobFairRoute = ''
    }

    componentDidMount(){
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        fetch('/setCurrentPage?page=login')
        fetch(`/${this.props.event.alias}/login`)
        if(utility.getLoggedInUser(this.props.event.alias) != null){
            this.OnNavigateToPage(this.props.event.alias+'/account')
        }
        let data = this.props.location.state || {}
        if(data.lastLocation != null){
            this.lastLocation = data.lastLocation
        }
        if(data.allJobsNavigate){
            this.jobFairNavigation = data.allJobsNavigate
            this.jobFairRoute = data.route
        }
    }
    OnNavigateToPage = (path,sendData = false,loginSuccess = true) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        else{
            if(this.lastLocation != null){
                path = this.lastLocation
                if(path.indexOf('sign_up') >= 0){
                    path = path.replace('sign_up','account')
                }
            }
        }
        if(localStorage.getItem("agendaIndex") == null && path.indexOf("sign_up") < 0 && loginSuccess){
            if(this.props.virtualMenuItems.length > 0){
                let lobbyIndex = this.props.virtualMenuItems.findIndex(x=>x.path == "/lobby")
                if(localStorage.getItem("bookingLink") != null){
                    path= "booking"

                }
                else{
                    if(lobbyIndex > -1){
                        path = "lobby"
                    }else{
                        let agendaIndex = this.props.virtualMenuItems.findIndex(x=>x.path == "/agenda")
                        if(agendaIndex > -1){
                            path = "agenda"
                        }
                    }
                }
            }
        }
        if(!sendData){
            this.props.history.push(path)
        }else{
            var dataToSend = {}
            if(this.lastLocation){
                dataToSend.lastLocation = this.lastLocation
            }
            if(this.jobFairNavigation){
                dataToSend.allJobsNavigate= true
                dataToSend.route= this.jobFairRoute
            }
            this.props.history.push({
                pathname : path,
                state : dataToSend
            })
        }
        
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
    }
    OnClickLoginUser = (email,password) => {
        var data = {
            email : email,
            password : password,
            profileImageUrl : "",
        }
        this.setState({processingLogin : true,loginError : ''})
        api.authenticateUser(this.props.event.alias,data,this.props.event.is_jobfair,async (err,data)=>{
            if(err == null){
                localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(data))
                if(!Constants.showVirtualZone){
                    // subscribeUser(data.id,this.props.event.alias)
                    if(this.jobFairNavigation){
                        this.navigateToJobFair()
                    }else{
                        this.OnNavigateToPage('/')
                    }
                    return
                }
                var unReadCount = 0
                var unReadMessagesArry = []
                var chatList = []
                var allNotifications = []
                var _unReadNotificationsCount = 0
                await appLevelApi.getUnReadMessages(this.props.event.alias,data.token,(count,unReadMessages)=>{
                    unReadCount = count
                    unReadMessagesArry = unReadMessages
                })
                await appLevelApi.getUserChatList(this.props.event.alias,data.token,(list)=>{
                    chatList = list
                })
                await appLevelApi.getUserNotifications(this.props.event.alias,data.token,(err,notifications,unReadNotificationsCount)=>{
                    if(err == null){
                        allNotifications = notifications
                        _unReadNotificationsCount = unReadNotificationsCount
                    }
                })
                await inviteApi.getInviatations(this.props.event.alias,data.token,(err,allInvites)=>{
                    var _allInvites = {
                        sent : [],
                        received : []
                    }
                    if(err == null){
                        _allInvites = allInvites
                    }
                    this.props.onLoginSuccess(data,unReadCount,unReadMessagesArry,chatList,allNotifications,_unReadNotificationsCount,_allInvites)
                    this.setState({processingLogin : false})
                    subscribeUser(data.id,this.props.event.alias)
                    if(this.jobFairNavigation){
                        this.navigateToJobFair()
                    }else{
                        this.OnNavigateToPage('/')
                    }
                })
            }else{
                this.setState({processingLogin : false})
                if(err.toString().indexOf('timeout') > -1 ){
                    this.setState({loginError : 'Invalid Email'})
                }else{
                    if(err.code == 1){
                        this.setState({recoverPassword : true,loginError : ''},()=>{
                        })
                    }
                    else{
                        this.setState({loginError : err.message})
                    }
                }
                
            }
        })
    }
    OnProcessSocialLogin = (firstName,lastName,email,type,profileImageUrl = '',id = '') => {
        let processingLogin = true
        if(type == 'linkedin'){
            if(this.state.processingLogin){
                if(firstName == ""){
                    processingLogin = false
                }
            }
        }
        this.setState({processingLogin,loginError : '',socialEmailLogin : false})
        if(firstName == ""){
            return
        }
        if(email == null){
            api.getSocialLoginStatus(id,this.props.event.alias,(err,data)=>{
                if(err == null){
                    if(data.status == 'success'){
                        this.LoginUser(firstName,lastName,email,type,profileImageUrl,id,data.isBooking,data.token)
                    }
                    else{
                        this.setState({socialEmailLogin : true,processingLogin : false})
                        setTimeout(() => {
                            window.FB.logout(function(response){
                            });
                        }, 0);
                        this.responseData = {
                            firstName : firstName,
                            lastName : lastName,
                            type : type,
                            profileImageUrl : profileImageUrl,
                            socialId : id
                        }
                        return
                    }
                }
            })
        }
        else{
            this.LoginUser(firstName,lastName,email,type,profileImageUrl,id)
        }
    }
    LoginUser = (firstName,lastName,email,type,profileImageUrl,id,isBooking = false,userToken = "") => {
        var data = {
            firstName : firstName,
            lastName : lastName,
            email : email,
            termsCondition : 1,
            type : type,
            profileImageUrl : profileImageUrl, 
            domainUrl : window.location.origin,
            socialId : id
        }
        signUpApi.signUpUser(this.props.event.alias,data,this.props.event.is_jobfair,async (err,user)=>{
            if(err == null){
                this.setState({loginError : ''})
                user.profileImageUrl = profileImageUrl
                localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(user))
                this.props.onLoginSuccess(user)
                subscribeUser(user.id,this.props.event.alias)
                if(this.props.event.is_jobfair == 1 && utility.getJobBoardAccessStatus(user.boardType)){
                    this.navigateToJobFair()
                }else{
                    this.OnNavigateToPage('/')
                }
            }
            else{
                if(err.toString().indexOf('timeout') > -1 ){
                    this.setState({loginError : 'Login Error'})
                }else{
                    var message = ""
                    var unReadCount = 0
                    var unReadMessagesArry = []
                    var chatList = []
                    var allNotifications = []
                    var _unReadNotificationsCount = 0
                    if(err.processCode == 2){
                        var userObj = {
                            firstName : firstName,
                            lastName : lastName,
                            email : email,
                            type : type,
                            passwordSet : 0,
                            profileImageUrl : profileImageUrl,
                            isBooking : isBooking,
                            token : userToken
                        }
                        if(this.props.event.is_jobfair == 1){
                            userObj.jobFairJwt = err.user.jobFairJwt
                        }
                        localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(userObj))
                        if(!Constants.showVirtualZone){
                            subscribeUser(userObj.id,this.props.event.alias)
                            if(this.jobFairNavigation){
                                this.navigateToJobFair()
                            }else{
                                this.OnNavigateToPage('/')
                            }
                            return
                        }
                        await appLevelApi.getUnReadMessages(this.props.event.alias,userObj.token,(count,unReadMessages)=>{
                            unReadCount = count
                            unReadMessagesArry = unReadMessages
                        })
                        await appLevelApi.getUserChatList(this.props.event.alias,userObj.token,(list)=>{
                           chatList = list
                        })
                        await appLevelApi.getUserNotifications(this.props.event.alias,userObj.token,(err,notifications,unReadNotificationsCount)=>{
                            if(err == null){
                                allNotifications = notifications
                                _unReadNotificationsCount = unReadNotificationsCount
                            }
                        })
                        await inviteApi.getInviatations(this.props.event.alias,userObj.token,(err,allInvites)=>{
                            var _allInvites = {
                                sent : [],
                                received : []
                            }
                            if(err == null){
                                _allInvites = allInvites
                            }
                            this.props.onLoginSuccess(userObj,unReadCount,unReadMessagesArry,chatList,allNotifications,_unReadNotificationsCount,_allInvites)
                            this.setState({processingLogin : false})
                        })
                        subscribeUser(userObj.id,this.props.event.alias)
                        if(this.jobFairNavigation){
                            this.navigateToJobFair()
                        }else{
                            this.OnNavigateToPage('/')
                        }
                    }
                    else if(err.processCode == 3){
                        let user = err.user
                        user.profileImageUrl = profileImageUrl
                        if(this.props.event.is_jobfair == 1){
                            user.jobFairJwt = err.user.jobFairJwt
                        }
                        localStorage.setItem(Constants.API_TOKEN+this.props.event.alias+'user_info',JSON.stringify(user))
                        if(!Constants.showVirtualZone){
                            subscribeUser(user.id,this.props.event.alias)
                            if(this.jobFairNavigation){
                                this.navigateToJobFair()
                            }else{
                                this.OnNavigateToPage('/')
                            }
                            return
                        }
                        await appLevelApi.getUnReadMessages(this.props.event.alias,user.token,(count,unReadMessages)=>{
                            unReadCount = count
                            unReadMessagesArry = unReadMessages
                        })
                        await appLevelApi.getUserChatList(this.props.event.alias,user.token,(list)=>{
                            chatList = list
                        })
                        await appLevelApi.getUserNotifications(this.props.event.alias,user.token,(err,notifications,unReadNotificationsCount)=>{
                            if(err == null){
                                allNotifications = notifications
                                _unReadNotificationsCount = unReadNotificationsCount
                            }
                        })
                        await inviteApi.getInviatations(this.props.event.alias,user.token,(err,allInvites)=>{
                            var _allInvites = {
                                sent : [],
                                received : []
                            }
                            if(err == null){
                                _allInvites = allInvites
                            }
                            this.props.onLoginSuccess(user,unReadCount,unReadMessagesArry,chatList,allNotifications,_unReadNotificationsCount,_allInvites)
                            this.setState({processingLogin : false})
                        })
                        subscribeUser(user.id,this.props.event.alias)
                        if(this.jobFairNavigation){
                            this.navigateToJobFair()
                        }else{
                            this.OnNavigateToPage('/')
                        }
                    }
                    else{
                        message = err.message
                        this.setState({loginError : message})
                    }
                }
            }
        })
    }
    OnSendPasswordResetEmail = (email) =>{
        var data = {
            email : email,
            domainUrl : window.location.origin
        }
        this.setState({processingLogin : true,loginError : ''})
        api.forgetPassword(this.props.event.alias,data,(err,data)=>{
            try{
            this.setState({processingLogin : false})
            if(err == null){
                this.setState({resetEmailSent : true,resetPasswordSuccessMsg : data.message})
            }
            else{
                if(err.message.trim() == "Cannot read properties of undefined (reading 'status')"){
                    this.setState({loginError : 'Something Went wrong! Please try Again'})
                }else{
                this.setState({loginError : err.message})
                }
            }
        }catch(e){
            this.setState({loginError : 'Something Went wrong! Please try Again'})
        }
        })
    }
    OnClickRecoverPassword = () => {
        this.setState({recoverPassword : true})
    }
    languageClick = (value) =>{
       // console.log("Trigger Login on lan select")
        this.props.languageClick(value);
    }
    loginEventClicked = (email) =>{
        GARecordEvent("loginClicked", email,{
            category : 'Login Button',
            action : 'Login button clicked',
            userEmail: email
        })
    }
    getLastLocation = () => {
        if(this.lastLocation == null){
            this.lastLocation = useLastLocation()
            if(this.lastLocation != null){
                this.lastLocation = this.lastLocation.pathname
            }
        }
        return null
    }
    navigateToJobFair = () => {
        var hash = localStorage.getItem('login_hash')
        let selectedLocale = localStorage.getItem("language"+this.props.event.alias) || 'en';
        let location =  process.env.REACT_APP_JOBFAIR_REDIRECT_URL+"?hash="+hash+"&URI="
        +window.location.origin+"&alias="+this.props.event.alias+"&locale="+selectedLocale+"&route="+this.jobFairRoute
        window.location.href = location
    }
    render(){
        return(
            <div>
                <this.getLastLocation />
                {this.state.processingLogin ?
                <div style = {{position : 'absolute',top: 0,left: 0,right : 0,bottom : 0,zIndex : 999}}/> : null}
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login</title>
                </Helmet>
                <div id="header-top">
                <Header
                    onMenuItemClick = {(path)=>this.OnNavigateToPage(path,false,false)}
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    linkedInLink = {this.props.event.linkedIn}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    isMarketplace = {this.props.event.is_marketplace}
                    eventLogo = {this.props.event.logo}
                    event = {this.props.event}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                    onLoginClick = {()=>this.setState({recoverPassword : false})}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up',this.lastLocation != null || this.jobFairNavigation,false)}
                />
                </div>
                <div className = {`content-main`}>
                    <div>
                        {this.state.recoverPassword ?
                        <ResetPassword
                            resetingPassword = {this.state.processingLogin}
                            resetError = {this.state.loginError}
                            successMessage = {this.state.resetPasswordSuccessMsg}
                            onLoginClick = {()=>this.setState({recoverPassword : false,resetPasswordSuccessMsg : '',loginError : '',processingLogin : false,resetEmailSent : false})}
                            sendPasswordResetEmail = {this.OnSendPasswordResetEmail}
                            emailSent = {this.state.resetEmailSent}
                        /> :
                        this.state.socialEmailLogin ?
                        <EmailLogin
                            loggingUser = {this.state.processingLogin}
                            loginUser = {(email) => this.OnProcessSocialLogin(this.responseData.firstName,this.responseData.lastName,email,this.responseData.type,this.responseData.profileImageUrl,this.responseData.socialId)}
                            loginError = {this.state.loginError} 
                            onLoginClick = {()=>this.setState({loginError : '',processingLogin : false,socialEmailLogin : false})}
                        /> :
                        <Content
                            alias = {this.props.event.alias}
                            event = {this.props.event}
                            onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up',this.lastLocation != null,false)}
                            onProcessLogin = {this.OnClickLoginUser}
                            onProcessSocialLogin = {this.OnProcessSocialLogin}
                            onRecoverPassword = {this.OnClickRecoverPassword}
                            processingLogin = {this.state.processingLogin}
                            loginEventClicked = {this.loginEventClicked}
                            loginError = {this.state.loginError}
                            useTranslation= {this.props.i18n}
                        />}
                    </div>
                    <Footer1
                        event = {this.props.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {(path)=>this.OnNavigateToPage(path,false,false)}
                    />
                </div>
                <Footer2 
                  event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                />
            </div>
        )
    }
}

export default compose(
    withRouter,
    withTranslation()
)(Login);