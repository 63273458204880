import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {

            event: this.props.event,
        };

    }

    render(){

        return(
            <section className = "about-detail py-3">
                <div className="container">
                    <div className="row py-3">

                        <div className="col-12">
                            <h5 className = "font-weight-bold text-uppercase text-main">Terms and Conditions</h5>

                            <p className = "f-500">{ReactHtmlParser(this.state.event.terms_conditions)}</p>


                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Content;