import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactHtmlParser from "react-html-parser";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import { Swipeable } from "react-swipeable";
import Modal from "react-modal";
import Lightbox from "react-image-lightbox";
import NewsLetter from "../../NewsLetter";
import Footer2 from "../../Footer/Footer2";
const Content = (props) => {
  const [gallery, setGallery] = useState([...props.gallery]);
  const [showImageSlider, setshowImageSlider] = useState(false);
  const [selectedImageIndex, setselectedImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);

  const OnClickImageItem = (imageIndex, version = "V1") => {
    console.log(imageIndex);
    // selectedVersion = version
    setshowImageSlider(true);
    setIsOpen(true);
    setselectedImageIndex(imageIndex);
  };
  const SetImages = (data) => {
    var images = {
      V1: [],
    };
    var version = "V1";
    for (var i = 0; i < data.features.length; i++) {
      if (data.features[i].image != null) {
        images[version].push(data.features[i].image);
      }
    }
  };
  useEffect(() => {
    console.log(props);
    setGallery([...props.gallery]);
    setImages([...props.gallery]);
  }, [props.gallery]);
  return props.loading== true ? (
    <div
        style={{
          width: "100%",
          height: "100vh",
        //   position: "absolute",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          top: 0,
          left: 0,
          background: "#0000000d",
          zIndex: 999,
        }}
      >
        <div style={{ 
        // position: "absolute", 
        left: "50%", top: 250 }}>
          <Loader type="Oval" color="#00b6ff" height={40} width={40} />
        </div>
      </div>
  ) : (
    <>
      {console.log(images)}
      {isOpen && (
        <Lightbox
          mainSrc={images[selectedImageIndex].image}
          nextSrc={images[(selectedImageIndex + 1) % images.length].image}
          prevSrc={
            images[(selectedImageIndex + images.length - 1) % images.length]
              .image
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            setselectedImageIndex(
              (selectedImageIndex + images.length - 1) % images.length
            );
          }}
          onMoveNextRequest={() =>
            setselectedImageIndex((selectedImageIndex + 1) % images.length)
          }
        />
      )}
      {props.event.gallary_layout != "V2" ? (
        <section className="py-5" id="event-photo">
          {/* {renderImageSlider()} */}
          <div className="container gallery-container">
            <h2 className="text-center font-weight-bold text-uppercase">
              {props.title}
            </h2>

            <div className="tz-gallery">
              <div className="row mb-2 pt-3 pb-3">
                {gallery
                  ? gallery.map((feature, index) => {
                      var activeName = index == 0 ? "active" : "";
                      return (
                        <div className="col-md-3 col-6" key={index}>
                          <div className="card">
                            <a
                              className="lightbox img-link-frame"
                              style={{ cursor: "pointer" }}
                              onClick={() => OnClickImageItem(index)}
                            >
                              <img
                                src={feature.image}
                                alt="ph-1"
                                className="card-img-top"
                              />
                            </a>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="py-5 bg-light" id="event-photo">
          <div className="container">
            <h2 className="text-center font-weight-bold text-uppercase">
              Gallery
            </h2>
            <div className="row py-5">
              {gallery
                ? gallery.map((feature, index) => {
                    var activeName = index == 0 ? "active" : "";
                    return (
                      <div className="col-md-4" key={index}>
                        <div className="gallery_card">
                          <img
                            src={feature.image}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              OnClickImageItem(index);
                            }}
                            className="img-thumbnail"
                            alt="Cinque Terre"
                          />
                          <h6 className="f-600 mt-2">{feature.title}</h6>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>
      )}
      <NewsLetter
        title="SUBSCRIBE OUR NEWS LETTER"
        event={props.event}
        event_alias={props.event.alias}
        buttonText="Subscribe"
        type="newsLetter"
      />
      <Footer2
        event={props.event}
        copyRightLogo={props.event.copyright_logo}
        copyRightText={props.event.copyright_text}
        copyRightLink={props.event.copyright_link}
        showCopyRight={
          props.event.copyright_text || props.event.copyright_logo
        }
      />
    </>
  );
};

export default Content;
