import React,{useState,useEffect} from 'react'
import api from '../api'
import RoundCircle from './seatingStyles/roundCircle'
import SemiCirlce from './seatingStyles/semiCircle'
import FullSeat from './seatingStyles/fullSeat'
import utility from '../../../Utility/utility'
import Loader from 'react-loader-spinner'
import $ from 'jquery'
export default function SeatBooking(props){
    const [loadAllSpeakers,setLoadSpeakers] = useState(false)
    const [selectedSeat,setSelectedSeat] = useState(-1)
    const [bookedSeats,setBookedSeats] = useState(props.selectedMeeting.bookedSeats)
    const [bookedUsers,setBookedUsers] = useState(props.selectedMeeting.bookedUsers)
    const [bookingSeat,setSeatBookingStatus] = useState(false)
    const [bookingSeatResponse,setBookingSeatResponse] = useState(null)
    const [bookingDisabled,setBookingDisabled] = useState(props.bookingDisabled.indexOf(props.selectedMeeting.id) > -1)
    const [showInlineError,setShowInlineError] = useState(false)
    const [selectedMeetingType] = useState(props.selectedMeeting.seatingStyle)
    useEffect(()=>{
        let user = utility.getLoggedInUser(props.eventAlias)
        let maxMumberOfSeats = 0
        if(selectedMeetingType == "roundTable"){
            maxMumberOfSeats = 10
        }else if(selectedMeetingType == "semiCircle"){
            maxMumberOfSeats = 20
        }else{
            maxMumberOfSeats = 30
        }
        let numberOfExtraSeats = maxMumberOfSeats-props.selectedMeeting.totalSeats
        for(var i=maxMumberOfSeats - 1;i>(maxMumberOfSeats - 1) - numberOfExtraSeats;i--){
            bookedSeats.push(i)
            setBookedSeats([...bookedSeats])
        }
        if(bookedUsers.indexOf(user.id) > -1){
            setBookingDisabled(true)
        }
        $("#thankyouModal").on("hidden.bs.modal", function () {
            let checkDisabled = document.getElementById("error-msg").accessKeyLabel
            document.getElementById("thanks-msg").style.display = "block"
            document.getElementById("error-msg").style.display = "none"
            if(checkDisabled == "isDisabled"){
                props.onClickNetworking()
            }else{
                props.onClickMeetingName()
            }
            $("#thankyouModal").modal('hide')
            $('body').removeClass('modal-open')
            $('.modal-backdrop').remove()
        })
    },[])
    const OnSeatSelected = (index) => {
        setSelectedSeat(index)
    }
    const OnClickConfirmBooking = () => {
        if(bookingSeat){
            return
        }
        setBookingSeatResponse(null)
        var responseObj = {}
        if(bookingDisabled){
            responseObj.status = false
            responseObj.message = 'You have already booked a seat for this session'
            setBookingSeatResponse(responseObj)
            document.getElementById("thanks-msg").style.display = "none"
            document.getElementById("error-msg").style.display = "block"
            $('#thankyouModal').modal('show');
            setTimeout(() => {
                $('body').addClass('modal-open')
            }, 500);
            return
        }
        if(selectedSeat == -1){
            setShowInlineError(true)
            responseObj.status = false
            responseObj.message = 'Select a seat to proceed'
            setBookingSeatResponse(responseObj)
            return
        }
        let user = utility.getLoggedInUser(props.eventAlias)
        var data = {
            meetingId : props.selectedMeeting.id,
            type : props.meetingType,
            userId : user.id,
            seatIndex : selectedSeat
        }
        setSeatBookingStatus(true)
        api.bookRoundTableSeat(props.eventAlias,false,data,(err,resposData)=>{
            setSeatBookingStatus(false)
            if(err){
                responseObj.message = err.isEnable == 0 ? "This meeting is disabled by the admin" : err.message
                if(err.isEnable == 0){
                    responseObj.disabled = true
                    document.getElementById("error-msg").accessKeyLabel = "isDisabled"
                }
                responseObj.status = false
                document.getElementById("thanks-msg").style.display = "none"
                document.getElementById("error-msg").style.display = "block"
                $('#thankyouModal').modal('show');
                setTimeout(() => {
                    $('body').addClass('modal-open')
                }, 500);
                setBookingSeatResponse(responseObj)
                return
            }
            bookedSeats.push(selectedSeat)
            setBookedSeats([...bookedSeats])
            setBookingDisabled(true)
            props.onSeatBooked(props.selectedMeeting.id,bookedSeats)
            setSelectedSeat(-1)
            // responseObj.message = resposData.message
            // responseObj.status = true
            // setBookingSeatResponse(responseObj)
            // setTimeout(()=>{
            //     setBookingSeatResponse(null)
            // },2000)
            $('#thankyouModal').modal('show');
            setTimeout(() => {
                $('body').addClass('modal-open')
            }, 500);
        })
    }
    return(
        <section class = "networking-meeting bg-light py-3">
            <div class="container text-center">
            
                <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item"><a style = {{cursor : 'pointer'}} onClick = {props.onClickNetworking}>Networking</a></li>
                    <li class="breadcrumb-item"><a style = {{cursor : 'pointer'}} onClick = {props.onClickMeetingName}>{props.meetingName}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Book Seat</li>
                </ol>
                </nav>
                
                <div class="row justify-content-center mt-3 mx-1">
                
                <div class = "col-12 round-m-sec py-3 px-0">
                
                    <div class = "row mx-0">
                        <div class = "col-12 py-3">
                            <h1>
                                {props.selectedMeeting.title}
                            </h1>
                        </div>
                        
                        <div class = "col-12 bg-light py-3 px-0">
                            <h2>
                                <span class = "mr-3"> <i class="far fa-calendar-alt mr-2"></i> {props.selectedMeeting.dateString} </span>
                                <br class="d-md-none"/>
                                <i class="far fa-clock mr-2"></i>
                                {props.selectedMeeting.totalTimeArray.map((item,index)=>{
                                    let splitterSlash = index == props.selectedMeeting.totalTimeArray.length - 1 ? "":" / "
                                    return(
                                        <span>{item.time+" "}<b>{item.country}</b>{" "+splitterSlash}</span>
                                    )
                                })}
                            </h2>
                        </div>

                        <div class = "col-12 py-3">

                            <div class="row">
                                <div class="col-sm-6 py-3">
                                    {props.selectedMeeting.speakers.slice(0,loadAllSpeakers ? props.selectedMeeting.speakers.length : 3).map((speaker,index)=>{
                                        return(
                                            <div class="host-spk mb-3 row">
                                                <div class="col-3 pr-0">
                                                    {speaker.image != null ? 
                                                    <img src={speaker.image} class="rounded-circle mt-2 adj_user" alt="user_1-img" /> : 
                                                    <img src="/images/profile-placeholder.png" class="rounded-circle mt-2 adj_user" alt="user_1-img"/>}
                                                </div>
                                                <div class="col-9 mt-3 pl-md-0 text-left">
                                                    <h3>{speaker.name + " " + (speaker.surname == null ? "" :speaker.surname)}</h3>
                                                    <p>{speaker.job_title}</p>
                                                    <p>{speaker.company}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {props.selectedMeeting.speakers.length > 3 ?
                                    <div class="row host-spk">
                                        <div class="col-12">
                                            <button onClick = {()=> setLoadSpeakers(!loadAllSpeakers)} class="btn btn-load">
                                                {loadAllSpeakers ? "Load Less" : "Load More"}
                                            </button>
                                        </div>
                                    </div> : null}
                                </div>
                                <div class="col-sm-6">
                                    {selectedMeetingType == "roundTable" ?
                                    <RoundCircle
                                        bookedSeats = {bookedSeats}
                                        disabled = {bookingDisabled}
                                        selectedSeat = {selectedSeat}
                                        onSeatSelected = {OnSeatSelected}
                                    /> : selectedMeetingType == "semiCircle" ?
                                    <SemiCirlce
                                        bookedSeats = {bookedSeats}
                                        disabled = {bookingDisabled}
                                        onSeatSelected = {OnSeatSelected}
                                    /> : 
                                    <FullSeat
                                        bookedSeats = {bookedSeats}
                                        disabled = {bookingDisabled}
                                        onSeatSelected = {OnSeatSelected}
                                    />}
                                    <div class="btn-sec mr-3 mt-5 text-right">
                                        <a style = {{cursor : 'pointer'}} onClick = {props.onClickMeetingName} class="btn cancel_btn mx-md-2 px-3">
                                            Cancel
                                        </a>
                                        <a style = {{cursor : 'pointer'}} onClick = {OnClickConfirmBooking} class="btn bg_main mx-md-2 px-3">
                                            Confirm Booking
                                        </a>
                                        {bookingSeat ?
                                        <div style = {{position : 'absolute',right : 6,bottom : 5}}>
                                            <Loader
                                                type = 'Oval'
                                                color = "#00b6ff"
                                                height = {25}
                                                width = {25}
                                            />
                                        </div> : null}
                                        {bookingSeatResponse != null && showInlineError ? 
                                        <span style = {{position : 'absolute',fontSize : 13,right : 39,bottom : -20}} class = {bookingSeatResponse.status ? "text-success" : "text-red" }>{bookingSeatResponse.message}</span> : null}
								    </div>
                                </div>
                                <div class="col-12 px-0 border-top mt-3 mt-md-4">
                                    <div class="sponsor-sec-logo">
                                        <ul class = "list-inline sponsor-list mb-0 mt-3">
                                            {props.selectedMeeting.sponsers.map((sponsor,index)=>{
                                                return(
                                                    <li class = "list-inline-item">
                                                        <img src={sponsor.image} alt="amazon_logo" />
                                                    </li>
                                                )
                                            })}
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div class="modal" id="thankyouModal">
                                    <div class="modal-dialog modal-dialog-centered modal-lg">
                                        <div class="modal-content">
                                        <div class="modal-body">
                                            <div class = "thanks-msg text-center py-3 py-md-4" id = "thanks-msg" style = {{display : 'block'}}>
                                                <p>
                                                    <i class="fas fa-check-circle"></i>
                                                </p>
                                                <h3>
                                                    Thank you !
                                                </h3>
                                                
                                                <p>
                                                    Your booking is confirmed. <br/>
                                                    Please check your email for more details
                                                </p>
                                                
                                                <p>
                                                    <button type="button" class="btn got-it" data-dismiss="modal">
                                                        Got it
                                                    </button>
                                                </p>
                                                
                                            </div>
                                            <div class = "thanks-msg text-center py-3 py-md-4" id = "error-msg">
                                                <p>
                                                    <i class="fas fa-times-circle" style = {{color : 'red'}}></i>
                                                </p>
                                                <h3>
                                                    Error
                                                </h3>
                                                {bookingSeatResponse != null ? 
                                                <p>
                                                    {bookingSeatResponse.message}
                                                </p> : null}
                                                
                                                <p>
                                                    <button type="button" class="btn got-it" data-dismiss="modal">
                                                        Got it
                                                    </button>
                                                </p>
                                                
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        
                    </div>
                
                </div>
                    
                </div>
                
            </div>

        </section>
    )
}