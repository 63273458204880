import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';


// import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './components/App/index';

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './reducer';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import './locales/i18n'
// var jsdom = require("jsdom");
// const { JSDOM } = jsdom;
// const { window } = new JSDOM();
// const { document } = (new JSDOM('')).window;
// global.document = document;

// var $ = jQuery = require('jquery')(window);
if(process.env.REACT_APP_ENV_NAME != "development" && process.env.REACT_APP_ENV_NAME != "local"){
  Sentry.init({
    dsn: 'https://5b79a91328814aa78669e480370358da@o498460.ingest.sentry.io/5576024',
    environment : process.env.REACT_APP_ENV_NAME,
    integrations : [
      new Integrations.BrowserTracing(),
    ]
  });
}
const store = createStore(reducer, compose(
  applyMiddleware(thunk)
));

const app = () => {
  const root = document && document.getElementById('root');
  return(
    <Provider store={store}>
      {/* <App /> */}
    </Provider>
  )
}

if (typeof window !== 'undefined') {
    const root = document && document.getElementById('root');
  ReactDOM.render(
      <Provider store={store}>
          <App />
      </Provider>,
      root);
}else{
  module.exports = app
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
