import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getAccountDetail(eventAlias,token,callback){
        axios.get(`${eventAlias}/myAccount`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token
                }
            }).then(response => {
                if(response.data.status == "success"){
                    callback(null,response.data.user)
                }
                else{
                    callback({
                        message : response.data.message
                    },null)
                }
        }).catch(error => {
            callback({
                message : 'Something went wrong ! Please try again'
            },null)
        })
    },
    async updateAccount(eventAlias,token,data,callback){
         await axios.post(`${eventAlias}/edit`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data,null)
            }
            else{
                callback({
                    message : response.data.message
                },null,response.data)
            }
        }).catch(error => {
            var errorMessage = {
                message:'Something went Wrong,Please try again'
            }
            callback(error,errorMessage)
        })
    },
    async deleteAccount(eventAlias,token,apiToken,callback){
        console.log(apiToken)
        await axios.get(`${eventAlias}/deleteAccount/delete`,{
           headers: {
               'Content-Type': 'application/json',
               'apiToken': apiToken,
               'Authorization' : 'Bearer '+token
           }
           }
       ).then(response => {
           if(response.data.status == "success"){
               callback(null,response.data,null)
           }
           else{
               callback({
                   message : response.data.message
               },null,response.data)
           }
       }).catch(error => {
           var errorMessage = {
               message:'Something went Wrong,Please try again'
           }
           callback(error,errorMessage)
       })
   },
   async reactivateAccount(eventAlias,token,apiToken,callback){
    await axios.post(`${eventAlias}/deleteAccount/reActivate`,{},{
       headers: {
           'Content-Type': 'application/json',
           'apiToken': apiToken,
           'Authorization' : 'Bearer '+token
       }
       }
   ).then(response => {
       if(response.data.status == "success"){
           callback(null,response.data,null)
       }
       else{
           callback({
               message : response.data.message
           },null,response.data)
       }
   }).catch(error => {
       var errorMessage = {
           message:'Something went Wrong,Please try again'
       }
       callback(error,errorMessage)
   })
},

    getMyBookings(eventAlias,token,callback){
        axios.get(`${eventAlias}/myBookings`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token}
            }).then(response => {
                if(response.data.status == "success"){
                    var array = response.data.myBookings

                    array.sort(function(a,b){return new Date(b.created_at.date.replace(" ","T")) - new Date(a.created_at.date.replace(" ","T"))});
                    var arryNew = []
                    for(var i=0;i<array.length;i++){
                        if(array[i].delegates.length > 0){
                            arryNew.push(array[i])
                        }
                    }
                    callback(null,arryNew)
                }
                else{
                    if(response.data.errorCode == 440){
                        callback(-1)
                    }else{
                        callback({
                            message : response.data.message
                        },null)
                    }
                }
        }).catch(error => {
            callback(error,null)
        })
    },
    async getMyFavorites(eventAlias,token,callback){
        await axios.get(`${eventAlias}/favourite/all`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization' : 'Bearer '+token}
            }).then(response => {
                if(response.data.status == "success"){
                    callback(null,response.data.data)
                }
                else{
                    callback({
                        message : response.data.message
                    },null)
                }
        }).catch(error => {
            callback(error,null)
        })
    },
    jobFairUpdatePassword(body,jwt,callback){
        axios({
            url: '/users/auth/password',
            baseURL: process.env.REACT_APP_JOBFAIR_API_ENDPOINT,
            method : 'PUT',
            data : body,
            headers: { 
                "Content-Type": "application/json" ,
                Authorization : jwt
            }
        }).then(response => {
            callback(response.data)
        }).catch(error => {
        })
    },
    jobFairUpdateUser(body,jwt,callback){
        axios({
            url: '/users/candidates',
            baseURL: process.env.REACT_APP_JOBFAIR_API_ENDPOINT,
            method : 'PATCH',
            data : body,
            headers: { 
                "Content-Type": "application/json" ,
                Authorization : jwt
            }
        }).then(response => {
            callback(response.data)
        }).catch(error => {
        })
    }

}