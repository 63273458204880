    const chat = (state = {
        chatList : []
    }, action) => {
    switch (action.type) {
        case 'CHAT_LIST' :
            return{
                ...state,
                chatList : action.payload
            }
        default:
            return state
        }
  }
  export default chat