import React from "react";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import $ from "jquery";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
const FloatingPlayer = (props) => {
  React.useEffect(() => {
    var floating_btn = document.getElementById("floating_btn_cancel");
    var navigated_btn = document.getElementById("floating_btn_navigate");

    if (floating_btn) {
      floating_btn.addEventListener(
        "touchstart",
        () => {
          props.onClickClose();
          props.naviagtionAgneda(false)
          localStorage.removeItem("SessionLocalStore")
        },
        false
      );
    }
    if (navigated_btn) {
      navigated_btn.addEventListener(
        "touchstart",
        () => {
          props.openAgenda();
          props.onClickClose();
          // localStorage.removeItem("SessionLocalStore")
        },
        false
      );
    }

    return () => {
      if (floating_btn) {
        floating_btn.removeEventListener("touchstart", () => {
          props.onClickClose();
          props.naviagtionAgneda(false)
          localStorage.removeItem("SessionLocalStore")
        });
      }
      if (navigated_btn) {
        navigated_btn.removeEventListener(
          "touchstart",
          () => {
            props.openAgenda();
            props.onClickClose();
            // localStorage.removeItem("SessionLocalStore")
          },
          false
        );
      }
    };
  });
  React.useEffect(() => {
    if(props.link != ""){
      localStorage.setItem("AgendaLink",props.agendaSlug)
      localStorage.setItem("AgendaNavigation",props.agendaSlug)
    }
    else{
      localStorage.removeItem("AgendaLink")
      // localStorage.removeItem("SessionLocalStore")
    }
    
    return () => {
     
    }
  })
  if (props.link == "") {
    return null;
  }
  const youtubeOptions = {
    height: "100%",
    width: "100%",
    playerVars: {
      start: 0,
      autoplay: 1,
      rel: 0,
      controls: 1,
      modestbranding: 1,
      showinfo: 0,
      autohide: 1,
      wmode: "transparent",
    },
  };
  return (
    <Draggable handle=".handle" bounds="parent">
      <div className="custom-youtube-player handle">
        <ResizableBox
          width={450}
          height={300}
          className="handle"
          style={{ padding: "10px" }}
        >
          <iframe
            id="player"
            type="text/html"
            style={{ width: "100%", height: "100%" }}
            src={props.link}
            frameborder="0"
            allowfullscreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <div
            className="floating-btn4 navigate d-flex justify-content-center
                        "
            id="floating_btn_navigate"
            style={{ right: "67px !important" }}
            onClick={(e) => {
              console.log("I am Floating Video  ");
              e.stopPropagation();
              props.openAgenda();
              props.onClickClose();
            }}
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <div
            className="floating-btn close d-flex justify-content-center float_close_btn"
            id="floating_btn_cancel"
            onClick={(e) => {
              e.stopPropagation();
              props.onClickClose();
            }}
          >
            <i
              className="fas fa-times"
              onClick={() => {
                props.onClickClose();
              }}
            ></i>
          </div>
          <div className=" floating-btn1 handle d-flex justify-content-center">
            <i className="fas fa-arrows-alt"></i>
          </div>
        </ResizableBox>
      </div>
    </Draggable>
  );
};

export default FloatingPlayer;
