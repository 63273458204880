import React from 'react'
import io from "socket.io-client";
import utility from '../../Utility/utility';
import $ from 'jquery'
export class GroupChat extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            allMessages : [],
            message : '',
            replyMessage : '',
            replyingToMessageId : ''
        }
    }
    componentDidMount(){
        $(document).on('submit', '#form-line', function(event){
            event.preventDefault();
        });
        this.user = utility.getLoggedInUser(this.props.eventAlias)
        // let socketURL =  Constants.socketUrl
        let socketURL = "localhost:41000"
        if(this.user != null){
            socketURL += "?userId="+this.user.id+"&userName="+this.user.firstName+" "+this.user.lastName
        }
        this.socket = io.connect(socketURL)
        this.socket.on('groupChatMessageReceived',(message)=>{
            var allMessages = this.state.allMessages
            message.me = false
            if(!message.isReply){
                allMessages.push(message)
            }else{
                var parentMessage = allMessages.find(x=>x.messageId == message.parentMessageId)
                parentMessage.replies.push(message)
            }
            this.setState({allMessages})
        })
    }
    OnCommentMessageChanged = (e) => {
        this.setState({message : e.target.value})
    }
    OnReplyMessageChanged = (e) => {
        this.setState({replyMessage : e.target.value})
    }
    OnClickSendMessage = () => {
        if(this.state.message == ""){
            return
        }
        var message = {
            messageId : Math.random().toString(36).substr(2, 9),
            text : this.state.message,
            userId : this.user.id,
            userName : this.user.firstName,
            me : true,
            date : new Date(),
            type : 'group',
            replies : []
        }
        this.socket.emit('messageSent',message)
        var allMessages = this.state.allMessages
        allMessages.push(message)
        this.setState({allMessages,message : ''})
    }
    OnClickReply = (messageId) => {
        this.setState({replyingToMessageId : messageId})
    }
    OnReplySubmit = (e) => {
        if(e.key === 'Enter'){
            let message = this.state.allMessages.find(x=>x.messageId == this.state.replyingToMessageId)
            let replyMessage = {
                text : e.target.value,
                messageId : Math.random().toString(36).substr(2, 9),
                parentMessageId : this.state.replyingToMessageId,
                userId : this.user.id,
                userName : this.user.firstName,
                date : new Date(),
                me : true,
                type : 'group',
                isReply : true,
            }
            this.socket.emit('messageSent',replyMessage)
            message.replies.push(replyMessage)
            this.setState({replyingToMessageId : ''})
        }
    }
    render(){
        return(
            <form id = "form-line" style = {{height : 400,display : 'inline-block',marginTop : 2,overflow : 'scroll'}}>
                {this.state.allMessages.map((item,index)=>{
                    return(
                        <div style = {{marginLeft : 10,marginBottom : 2,background : 'green',borderRadius : 10,color : '#fff'}} class="p-2 text_wrapper">
                            <div class="text">{item.text}
                                <div onClick = {()=>this.OnClickReply(item.messageId)} style = {{cursor : 'pointer',marginLeft : 20,fontSize : 12}}>Reply</div>
                                <div style = {{marginLeft : 20}}>
                                    {item.replies.map((reply,index)=>{
                                        return(
                                            <div style = {{fontSize : 14}}>{reply.text}
                                                <div onClick = {()=>this.OnClickReply(item.messageId)} style = {{cursor : 'pointer',marginLeft : 20,fontSize : 12}}>Reply</div>
                                            </div>
                                        )
                                    })}
                                    {this.state.replyingToMessageId == item.messageId ?
                                    <input onKeyDown = {this.OnReplySubmit} /> : null}
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div style = {{position : 'absolute',bottom : 5,width : '100%',height : 40}}>
                    <input value = {this.state.message} onChange = {this.OnCommentMessageChanged}  style = {{borderRadius : 10,width : '90%',height : '100%'}} />
                    <input onClick = {this.OnClickSendMessage} value = "Send" type = "submit" style = {{borderRadius : 10,width : '10%',height : '100%'}} />
                </div>
            </form>
        )
    }
}