import React from "react";
import api from "../AgendaTime/api";
import componentApi from "./api";
import Header from "../Header/Index";
import Content from "./content";
import Footer1 from "../Footer/Footer1";
import Footer2 from "../Footer/Footer2";
import NewsLetter from "../NewsLetter";
import { Trans } from "react-i18next";
import Sponsers from "../Sponsers";
import ContactInfo from "../ContactInfo";
import { Constants } from "../../Constants";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import sessionApi from "./api";
import { ErrorScreen } from "../ErrorScreen";
import utility, { weekDays } from "../../Utility/utility";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { RemoveScroll } from "../1 - Sub menu components/Virtual Exhibition/actions";
import { compose } from "redux";
import { AgendaDataLoaded, userChangeTimeZone } from "./actions/index";
import MetaTags from "react-meta-tags";
import RegisterModel from "../RegisterModel";
// import zoom from '../../zoom-us/index.js'
import { Modal, Button } from "react-bootstrap";
import Session, { session } from "./session";
import VirtualZone from "../Header/virtualZone";
import { UpdateMyFavourites } from "../Account/actions";
import { isSafari } from "react-device-detect";
import { GAPageView, GARecordEvent } from "../../Utility/GA";
import PermiumUserModal from "../modals/premiumUserModal";
import $ from "jquery";
import { LoginPopup } from "../modals/loginPopup";
import participantApi from "../1 - Sub menu components/Participants/api";
import { withTranslation } from "react-i18next";
import ContentLoader from "./sessionContentLaoder";
import {
  FloatingPlayerAdded,
  AgendaSessionId,
  naviagtionAgneda,
} from "../App/actions";
import firebase_config from "./firebase-configuration";
import queryString from 'query-string'
export class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.event,
      event_alias: this.props.event_alias,
      agenda: null,
      selectedIndex:
        this.props.headerMenuItems.findIndex(
          (x) => x.href.indexOf("/agenda") > -1
        ) || -1,
      selectedSubIndex: -1,
      loadingError: "",
      menu: this.props.headerMenuItems.find(
        (x) => x.href == this.props.event.alias + "/agenda"
      ),
      showComingSoonMsg: false,
      showUserErrorPopup: false,
      errorMessageHeading: "",
      errorMessageJoin: "",
      showCloseButton: true,
      showSessionView: false,
      selectedSessionTitle: "",
      selectedSessionSpeakers: [],
      selectedSessionSponsors: [],
      webinarId: "",
      webinarPassword: "",
      streamType: 1,
      isVimeo: 0,
      streamLink: "",
      videoId: "",
      selectedSessionIndex: -1,
      selectedSubSessionIndex: -1,
      selectedAgendaId: 0,
      selectedSessionId: 0,
      selectedSubSessionId: 0,
      selectedSessionCerificateEnabled: 0,
      selectedSessionDuration: 0,
      sessionSavedQuestions: [],
      sessionSavedAnnouncements: [],
      userLoadedSessionTime: 0,
      overAllSessionsWatchedTime: 0,
      groupChat: [],
      showingLocalTime: false,
      virtualZoneItemIndex: this.props.virtualMenuItems.findIndex(
        (x) => x.path == "/agenda"
      ),
      requestingCertificate: false,
      cerificateRequestResponse: {},
      certificateRequestSent: false,
      premiumSessionText: "",
      defaultAgendaIndex: 0,
      loadingNextSession: false,
      selectedTimeZone: -1,
      selectedSessionPolls: [],
      pollQuestion: [],
      loaded: false,
      loadingAgendaFromUrl: false,
      selectedSessionPolls: [],
      realTimeData: false,
      unReadGroupChatCount: "",
      loadingSessionGroupChat: true,
      loadingSessionQuestions: true,
      loadingSessionAnnouncements: true,
      loadingSessionPolls: true,
      MessageCount: 0,
      saveUserSession: false,
      streamTypeSet: "",
      agendaIndex: null,
      sessionIndex: null,
      subSessionIndex: null,
      loadSessionData: false,
      certificateSave: false,
      multiLangualSessoins: [{}]
    };
    this.statusLoaded = false;
    this.lastLoadedQuestionId = 0;
    this.certificateCompletionTime =
      parseInt(props.certificateCompletionTime) / 60;
    this.eventCertificateComplrtion = parseInt(props.certificateCompletionTime);
    this.user = utility.getLoggedInUser(this.props.event.alias);
    this.translation = this.props.i18n.getResourceBundle(
      localStorage.getItem("language" + localStorage.getItem("eventAlias")) ||
        "en"
    );
    // if (window.performance) {
    //     if (performance.navigation.type == 1) {
    //       alert( "This page is reloaded" );
    //     } else {
    //       alert( "This page is not reloaded");
    //     }
    // }
  }
  setSessionData = (type, agendaId, SessionId, SubSessionId) => {
    this.setState({
      streamTypeSet: type,
      agendaIndex: agendaId,
      sessionIndex: SessionId,
      subSessionIndex: SubSessionId,
      loadSessionData: true,
    });
  };
  unsetSessionData = () => {
    this.setState({
      streamTypeSet: "",
      agendaIndex: null,
      sessionIndex: null,
      subSessionIndex: null,
      loadSessionData: false,
    });
  };
  componentDidMount() {
    console.log(this.props);
    window.scrollTo(0, 0);
    let polls = firebase_config.database().ref("polls");
    polls.on("child_added", async (item) => {
      if (this.state.realTimeData == true) {
        if (
          item.val().sessionId == this.state.selectedSessionId &&
          item.val().parallelSessionId == this.state.selectedSubSessionId &&
          item.val().agendaId == this.state.selectedAgendaId
        ) {
          await this.getPollQuestion(
            item.val().sessionId,
            item.val().parallelSessionId,
            item.val().agendaId
          );
        }
      }
    });
    let loadingFromSlug = this.loadSessionFromUrl();
    GAPageView(
      window.location.hostname +
        window.location.pathname +
        window.location.search
    );
    let timeZoneSplit = this.props.event.timezone.split(":");
    let timeZoneHour = parseInt(timeZoneSplit[0]);
    this.timeZone = parseFloat(timeZoneHour + "." + timeZoneSplit[1]);
    this.setState({ selectedTimeZone: this.props.event.timezone });
    document.addEventListener("navigateToLogin", (event) => {
      this.OnNavigateToPage(this.props.event.alias + "/login");
    });
    let user = this.user;
    if (user != null) {
      let agendaId = localStorage.getItem("agendaId");
      let sessionId = null;
      let subSessionId = null;
      let isPurchased = true;
      this.sessionDetails = localStorage.getItem("sessionDetails");
      if (this.sessionDetails != null) {
        this.sessionDetails = JSON.parse(this.sessionDetails);
        sessionId = this.sessionDetails.sessionId;
        subSessionId = this.sessionDetails.subSessionId;
        isPurchased = this.sessionDetails.isPurchased;
      }
      var params = {
        agendaId: agendaId,
        sessionId: subSessionId == 0 ? sessionId : null,
        event_parallel_session_id: subSessionId,
      };
      api.getUserSessionStatus(
        this.props.event.alias,
        user.token,
        params,
        (error, data) => {
          if (data != null) {
            if (data.status == "success") {
              if (!data.user.isBooking) {
                this.ClearLocalStorage();
              } else {
                this.setState({
                  userLoadedSessionTime:
                    data.user.totalStreamWatched != null
                      ? parseInt(data.user.totalStreamWatched)
                      : 0,
                  overAllSessionsWatchedTime:
                    data.user.totalStreamWatchedEvent != null
                      ? parseInt(data.user.totalStreamWatchedEvent)
                      : 0,
                });
              }
            } else {
              this.ClearLocalStorage();
            }
          }
          this.getInitialPageData(agendaId);
          this.statusLoaded = true;
        }
      );
    } else {
      this.ClearLocalStorage();
      this.getInitialPageData();
      this.statusLoaded = true;
    }
  }
  loadSessionFromUrl() {
    this.agendaSlug = this.props.match.params.agendaSlug;
    this.sessionSlug = this.props.match.params.sessionSlug;
    this.subSessionSlug = this.props.match.params.subSessionSlug;
    var values = queryString.parse(window.location.search)
    this.videoLocale = values.locale || "en"
    if (this.agendaSlug && this.sessionSlug) {
      this.setState({ loadingAgendaFromUrl: true });
      return true;
    }
    return false;
  }
  async getInitialPageData(savedAgendaId = -1) {
    fetch("/setCurrentPage?page=agenda");
    fetch(`/${this.props.event.alias}/agenda`);
    if (this.props.agenda != null) {
      // let agendaIndex = await this.getSelectedSession(this.props.agenda)
      // if(agendaIndex == null){
      //     agendaIndex = -1
      // }
      // if(agendaIndex > -1){
      //     this.setState({agenda : this.props.agenda})
      // }
    }
    if (this.state.selectedIndex == -1) {
      for (var i = 0; i < this.props.headerMenuItems.length; i++) {
        var result = utility.searchTree(
          this.props.headerMenuItems[i],
          "Agenda"
        );
        if (result != null) {
          let selectedSubIndex = this.props.headerMenuItems[
            i
          ].children.findIndex((x) => x.href.indexOf("/agenda") > -1);
          this.setState({ selectedIndex: i, selectedSubIndex });
          break;
        }
      }
    }
    this.getEventAgenda(savedAgendaId);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.naviagtionValue == true) {
      let navigationdata = JSON.parse(
        localStorage.getItem("SessionLocalStore")
      );
      if (navigationdata != null) {
        if(this.state.agenda != null){
          if(this.floatingPlayerLocale != null){
            this.videoLocale = this.floatingPlayerLocale
          }else{
            let locale = localStorage.getItem("videoLocale")
            if(locale != null){
              this.videoLocale = locale
            }
          }
          this.OnClickVirtualAccessButton(
            navigationdata.type,
            navigationdata.agendaIndex,
            navigationdata.sessionIndex,
            navigationdata.subSessionIndex,
            navigationdata.isPurchased
          );
        }
        this.props.naviagtionAgneda(false);
      }
    }
  }
  async getSelectedSession(agenda) {
    let agendaIndex = localStorage.getItem("agendaIndex");
    let sessionIndex = null;
    let subSessionIndex = null;
    let certificateEnabled = null;
    if (this.sessionDetails != null) {
      sessionIndex = this.sessionDetails.sessionIndex;
      subSessionIndex = this.sessionDetails.subSessionIndex;
      certificateEnabled = this.sessionDetails.certificateEnabled;
    }
    if (subSessionIndex == null) {
      subSessionIndex = -1;
    }
    let streamType = localStorage.getItem("streamType");
    console.log("I am Local");
    console.log(localStorage.getItem("streamType"));
    if (agendaIndex != null) {
      this.setState({
        selectedSessionIndex: sessionIndex,
        selectedSubSessionIndex: subSessionIndex,
      });
      if (agendaIndex == -1) return;
      this.setSessionData(
        streamType,
        agendaIndex,
        sessionIndex,
        subSessionIndex
      );
      this.ClearLocalStorage();
      return;
      var agendaSession = agenda[agendaIndex].sessions.data[sessionIndex];
      var sessionId = agendaSession.id;
      var subSessionId = 0;
      if (subSessionIndex > -1) {
        agendaSession = agendaSession.subSessions[subSessionIndex];
        subSessionId = agendaSession.id;
      }
      let id = utility.getYoutubeVideoId(agendaSession.virtual_access_link);
      let autoPlay = streamType == 1 ? "?autoplay=1&rel=0" : "";
      let embededLink = "https://www.youtube.com/embed/" + id + autoPlay;
      await this.loadSavedQuestions(agenda[agendaIndex].id, sessionId);
      await this.loadSavedAnnouncements(agenda[agendaIndex].id, sessionId);
      await this.getGroupChat(agenda[agendaIndex].id, sessionId, subSessionId);
      this.setState(
        {
          selectedSessionTitle: agendaSession.title,
          selectedSessionSpeakers: agendaSession.speakers.data,
          selectedSessionSponsors: agendaSession.sponsers.data,
          webinarId: agendaSession.webinarId,
          webinarPassword: agendaSession.webinarPassword,
          streamType: streamType,
          streamLink: embededLink,
          videoId: id,
          selectedAgendaId: agenda[agendaIndex].id,
          selectedSessionId: sessionId,
          selectedSessionDuration: this.sessionDetails.sessionDuration,
          selectedSubSessionId: subSessionId,
          showSessionView: agendaSession.isPurchased == "1" ? true : false,
          selectedSessionCerificateEnabled: certificateEnabled,
        },
        () => {
          if (!this.state.showSessionView) {
            let premiumSessionText = "";
            let ticketTitles = "";
            if (agendaSession.ticketTitles.length > 1) {
              for (var i = 0; i < agendaSession.ticketTitles.length; i++) {
                ticketTitles += agendaSession.ticketTitles[i];
                if (i != agendaSession.ticketTitles.length - 1) {
                  ticketTitles += ",";
                }
              }
              premiumSessionText =
                "<p>You need to have booked at least one package from " +
                ticketTitles.bold() +
                " to access this content.</p>";
            } else {
              premiumSessionText =
                "<p>You need to have booked " +
                agendaSession.ticketTitles[0].bold() +
                " package to access this content.</p>";
            }
            this.setState({ premiumSessionText }, () => {
              $("#premiumSession").modal("show");
            });
            this.ClearLocalStorage();
            this.contentRef.OnUserStatusLoaded();
          }
        }
      );
    }
    return agendaIndex;
  }
  getEventAgenda = (savedAgendaId) => {
    let user = utility.getLoggedInUser(this.props.event.alias);
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }
    api.getEventAgenda(
      this.state.event.alias,
      userId,
      this.props.type,
      (err, data) => {
        if (err == null) {
          this.setAgenda(data, savedAgendaId);
          this.props.agendaLoaded(data);
        }
        if (this.props.agenda == null)
          this.setState({ loadingError: "Something went wrong" });
      }
    );
  };
  setAgenda = (data, savedAgendaId) => {
    if (data.data.length == 0) {
      this.setState({ showComingSoonMsg: true });
    } else {
      for (var i = 0; i < data.data.length; i++) {
        let firstSession = data.data[i].sessions.data[0];
        if (firstSession != null) {
          let timeStringSplit = firstSession.start_time.split(":");
          if (parseInt(timeStringSplit[0]) < 10) {
            firstSession.start_time =
              "0" + parseInt(timeStringSplit[0]) + ":" + timeStringSplit[1];
          }
        }
      }
    }
    let currentDate = new Date();
    var dateSelected = false;
    let defaultAgendaIndex = 0;
    for (var i = 0; i < data.data.length; i++) {
      data.data[i].originalDate = data.data[i].date;
      for (var j = 0; j < data.data[i].sessions.data.length; j++) {
        let sessionItem = data.data[i].sessions.data[j];
        sessionItem.original_start_time = sessionItem.start_time;
        sessionItem.original_end_time = sessionItem.end_time;
        if (sessionItem.hasMultiSession == 1) {
          for (var k = 0; k < sessionItem.subSessions.length; k++) {
            sessionItem.subSessions[k].start_time = sessionItem.start_time;
            sessionItem.subSessions[k].end_time = sessionItem.end_time;
            sessionItem.subSessions[k].eventObj = this.createSessionEventItem(
              data.data[i].date,
              sessionItem.subSessions[k]
            );
          }
        } else {
          sessionItem.eventObj = this.createSessionEventItem(
            data.data[i].date,
            sessionItem
          );
        }
        sessionItem.endDateNextSession = new Date(
          data.data[i].date.split("-").reverse().join("-") +
            "T" +
            sessionItem.end_time
        );
      }
      let dateSplit = data.data[i].date.split("-");
      let date = new Date(
        dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]
      );
      if (
        date.getDate() != currentDate.getDate() ||
        date.getMonth() != currentDate.getMonth() ||
        date.getFullYear() != currentDate.getFullYear()
      ) {
        if (!dateSelected) {
          defaultAgendaIndex += 1;
        }
      } else {
        dateSelected = true;
      }
    }
    if (defaultAgendaIndex == data.data.length) {
      defaultAgendaIndex = 0;
    }
    let agenda = data.data;
    let loadSpecificSession = false;
    let type = "";
    let isPurchased = false;
    if (this.agendaSlug) {
      var agendaItemIndex = agenda.findIndex((x) => x.slug == this.agendaSlug);
      if (agendaItemIndex > -1) {
        var sessionItemIndex = agenda[agendaItemIndex].sessions.data.findIndex(
          (x) => x.slug == this.sessionSlug
        );
        if (sessionItemIndex > -1) {
          isPurchased =
            agenda[agendaItemIndex].sessions.data[sessionItemIndex]
              .isPurchased == "1";
          if (
            agenda[agendaItemIndex].sessions.data[sessionItemIndex]
              .hasMultiSession == "1"
          ) {
            var subSessionIndex = agenda[agendaItemIndex].sessions.data[
              sessionItemIndex
            ].subSessions.findIndex((x) => x.slug == this.subSessionSlug);
            if (subSessionIndex > -1) {
              isPurchased =
                agenda[agendaItemIndex].sessions.data[sessionItemIndex]
                  .subSessions[subSessionIndex].isPurchased == "1";
              loadSpecificSession = true;
            }
          } else {
            loadSpecificSession = true;
          }
        }
      }
    }
    this.setState({ agenda, loadingError: "", defaultAgendaIndex }, () => {
      if (savedAgendaId > -1) {
        this.getSelectedSession(data.data);
      }
      if (loadSpecificSession) {
        this.setState({
          selectedSessionIndex: sessionItemIndex,
          selectedSubSessionIndex: subSessionIndex,
        });
        type = localStorage.getItem("streamTypeForNextSession") || 1;
        this.OnClickVirtualAccessButton(
          type,
          agendaItemIndex,
          sessionItemIndex,
          subSessionIndex,
          isPurchased
        );
      } else {
        this.setState({ loadingAgendaFromUrl: false });
      }
      localStorage.removeItem("streamTypeForNextSession");
    });
  };
  createSessionEventItem = (date, sessionItem) => {
    let dateString = date.split("-").reverse().join("-");
    let timeString = sessionItem.start_time;
    if (sessionItem.start_time == null) {
      return null;
    }
    let timeStringSplit = timeString.split(":");
    if (parseInt(timeStringSplit[0]) < 10) {
      timeString =
        "0" + parseInt(timeStringSplit[0]) + ":" + timeStringSplit[1];
    }
    let startTime = null;
    startTime = new Date(dateString + "T" + timeString);
    timeString = sessionItem.end_time;
    let endTime = null;
    endTime = new Date(dateString + "T" + timeString);
    let event = {
      title: sessionItem.title,
      description: utility.extractStringFromElement(sessionItem.description),
      location: this.props.event.venue_address,
      startTime: startTime,
      endTime: endTime,
    };
    return event;
  };
  saveSession = () => {
    this.setState({ saveUserSession: false });
  };
  cancelSaveCertificate = () => {
    this.setState({ certificateSave: false });
  };
  OnNavigateToPage = (path, data = null) => {
    this.setState({ certificateSave: true });
    console.log("state Data");
    console.log(this.state.streamType);
    if (this.state.showSessionView) {
      if (this.state.streamType == 1) {
        // this.sessionRef.SaveUserSessionAttendedTime()
        this.setState({ saveUserSession: true });
      }
      this.setState({
        showSessionView: false,
        selectedSessionIndex: -1,
        selectedSubSessionIndex: -1,
      });
    }
    this.setState({ loadingAgendaFromUrl: false });
    window.scrollTo(0, 0);
    if (path.indexOf("home") > -1) {
      path = "/";
    } else if (path != "/") {
      path = "/" + path;
    }
    if (data != null) {
      this.props.history.push({
        pathname: path,
        state: data,
      });
    } else {
      this.props.history.push(path);
    }
  };
  OnPressRetry = () => {
    this.setState({ loadingError: "" });
    this.getEventAgenda();
  };
  languageClick = (value) => {
    this.props.languageClick(value);
  };
  OnClickAccountItem = (index, path) => {
    this.props.onClickAccountItem(index, path);
    if (index == null) return;
    // if(this.state.showSessionView){
    //     alert("Please leave session view first")
    //     return
    // }
    if (index > -1) {
      this.props.history.replace("");
      this.props.history.push({
        pathname: this.props.event.alias + "/account",
        state: { tabIndex: index },
      });
    } else {
      localStorage.removeItem(
        Constants.API_TOKEN + this.props.event.alias + "user_info"
      );
      window.location.reload();
    }
  };

  ClearLocalStorage = () => {
    localStorage.removeItem("agendaIndex");
    localStorage.removeItem("streamType");
    localStorage.removeItem("agendaId");
    localStorage.removeItem("sessionDetails");
  };
  OnClickVirtualAccessButton = (
    type,
    agendaIndex,
    sessionIndex,
    subSessionIndex = -1,
    isPurchased = true,
    saveLocal = true
  ) => {
    if (saveLocal == true) {
      let sessionData = {
        type: type,
        agendaIndex: agendaIndex,
        sessionIndex: sessionIndex,
        subSessionIndex: subSessionIndex,
        isPurchased: isPurchased,
      };
      localStorage.setItem("SessionLocalStore", JSON.stringify(sessionData));
      localStorage.setItem("streamTypeForNextSession", 1);
    }
    let addSlug = true;
    let slugString = "";
    this.agendaIndex = agendaIndex;
    this.sessionIndex = sessionIndex;
    this.subSessionIndex = subSessionIndex;
    if (!this.statusLoaded) {
      return;
    }
    let agendaItem = this.state.agenda[agendaIndex];

    if (agendaItem.slug) {
      slugString = "/" + agendaItem.slug;
    } else {
      addSlug = false;
    }
    let user = utility.getLoggedInUser(this.props.event.alias);
    localStorage.setItem("agendaIndex", agendaIndex);
    localStorage.setItem("streamType", type); // using this for coming back from login , signup page
    localStorage.setItem("streamTypeForNextSession", type); // using this for new session start
    var agendaSession = agendaItem.sessions.data[sessionIndex];
    if (agendaSession.slug) {
      slugString += "/session/" + agendaSession.slug;
    } else {
      addSlug = false;
    }
    let endDateNextSession = agendaSession.endDateNextSession;
    var nextSession = agendaItem.sessions.data[sessionIndex + 1];
    let nextSessionTitle = "";
    if (nextSession) {
      if (nextSession.subSessions) {
        let titles = nextSession.subSessions.map((x) => x.title);
        nextSessionTitle = titles.join(",");
      } else {
        nextSessionTitle = nextSession.title;
      }
    }
    let sessionId = agendaSession.id;
    let certificateEnabled = 0;
    let subSessionId = 0;
    if (subSessionIndex > -1) {
      agendaSession = agendaSession.subSessions[subSessionIndex];
      subSessionId = agendaSession.id;
      if (agendaSession.slug) {
        slugString += "/" + agendaSession.slug;
      } else {
        addSlug = false;
      }
    }
    this.props.AgendaSessionId(slugString);
    certificateEnabled = agendaSession.certificateAvailable;
    var params = {
      agendaId: agendaItem.id,
      sessionId: subSessionId == 0 ? sessionId : null,
      event_parallel_session_id: subSessionId,
      parentSessionId: sessionId,
    };
    this.sessionDetails = {
      sessionIndex: sessionIndex,
      subSessionIndex: subSessionIndex > -1 ? subSessionIndex : null,
      sessionId: sessionId,
      subSessionId: subSessionId,
      certificateEnabled: certificateEnabled,
      sessionDuration: parseInt(agendaSession.session_duration),
      isPurchased: isPurchased,
    };
    localStorage.setItem("agendaId", params.agendaId);
    localStorage.setItem("sessionDetails", JSON.stringify(this.sessionDetails));
    this.shouldNavigate = true;
    let analyticObj = {
      category: "Agenda",
      action: "Agenda Watch Clicked",
      agendaId: params.agendaId,
      sessionId: sessionId,
      sessionTitle: agendaSession.title,
      isPurchased: isPurchased,
      status: type == "1" ? "Live" : "Recorded",
      isBreak: agendaSession.isBreak,
    };
    if (user == null) {
      $("#loginRequired").modal("show");
      window.scrollTo(0, 0);
      this.props.RemoveScroll(1);
    } else if (
      (user.boardType && user.jobBoardProfileCompleted == "0") ||
      (user.boardType && user.jobBoardProfileCompleted == null)
    ) {
      $("#profileInCompletePopup").modal("show");
      this.props.RemoveScroll(1);
      window.scrollTo(0, 0);
    } else {
      analyticObj.user = user.email;
      if (!isPurchased) {
        let premiumSessionText = "";
        let ticketTitles = "";
        if (agendaSession.ticketTitles.length > 1) {
          for (var i = 0; i < agendaSession.ticketTitles.length; i++) {
            ticketTitles += agendaSession.ticketTitles[i];
            if (i != agendaSession.ticketTitles.length - 1) {
              ticketTitles += ", ";
            }
          }
          premiumSessionText =
            "<p>You need to have booked at least one package from " +
            ticketTitles.bold() +
            " to access this content.</p>";
        } else {
          if (agendaSession.ticketTitles.length == 1) {
            premiumSessionText =
              "<p>You need to have booked " +
              agendaSession.ticketTitles[0].bold() +
              " package to access this content.</p>";
          } else {
            premiumSessionText =
              "<p>You need to have booked at least one paid package to access this content.</p>";
          }
        }
        this.setState({ premiumSessionText }, () => {
          $("#premiumSession").modal("show");
        });
        this.ClearLocalStorage();
        return;
      }
      api.getUserSessionStatus(
        this.props.event.alias,
        user.token,
        params,
        async (error, data) => {
          if (error != null) {
            this.shouldNavigate = false;
            this.setState({
              showUserErrorPopup: true,
              errorMessageHeading: "Something went wrong",
              errorMessageJoin:
                "We are unable to process your request at this moment please try again",
            });
            window.scrollTo(0, 0);
            this.props.RemoveScroll(1);
            $("#sessionExpire").modal("show");
            this.ClearLocalStorage();
            if (this.contentRef != null) {
              this.contentRef.OnUserStatusLoaded();
            }
            return;
          }
          if (data.status == "success") {
            if (data.user.isBooking) {
              let id = utility.getYoutubeVideoId(
                agendaSession.virtual_access_link
              );
              let multiLangualSessoins = (agendaSession.multi_lingual_session || []).map(item => ({...item, id: utility.getYoutubeVideoId(item.link)}))
              // let autoPlay = type == 1 ? "?autoplay=1&rel=0"
              let autoPlay = "?autoplay=1&rel=0&mute=0";
              let embededLink;
              if (agendaSession.is_vimeo == 0) {
                embededLink = "https://www.youtube.com/embed/i_d"+ autoPlay;
              } else {
                embededLink =
                  agendaSession.virtual_access_link + "?&autoplay=1";
              }
              let nextSessionLink;
              let nextSessionId;
              if(nextSession){
                if (nextSession.is_vimeo == 0) {
                  nextSessionId = utility.getYoutubeVideoId(
                    nextSession.virtual_access_link
                  );
                  nextSessionLink = "https://www.youtube.com/embed/i_d"+ autoPlay;
                } else {
                  nextSessionLink = nextSession.virtual_access_link + "?&autoplay=1"; 
                }
              }
              this.setState({
                loadingSessionGroupChat: true,
                loadingSessionQuestions: true,
                loadingSessionAnnouncements: true,
                loadingSessionPolls: true,
              });
              this.loadSavedQuestions(
                params.agendaId,
                params.sessionId,
                params.event_parallel_session_id
              );
              this.loadSavedAnnouncements(
                params.agendaId,
                params.sessionId,
                params.event_parallel_session_id
              );
              this.getPollQuestion(
                params.parentSessionId,
                params.event_parallel_session_id,
                params.agendaId
              );
              this.getGroupChat(
                this.state.agenda[agendaIndex].id,
                sessionId,
                subSessionId
              );

              window.scrollTo(0, 0);
              this.props.updateFloatingPlayerLink("");              
              this.setState({
                selectedSessionTitle: agendaSession.title,
                selectedSessionSpeakers: agendaSession.speakers.data,
                selectedSessionSponsors: agendaSession.sponsers.data,
                showSessionView: true,
                webinarId: agendaSession.webinarId,
                webinarPassword: agendaSession.webinarPassword,
                streamLink: embededLink,
                videoId: id,
                streamType: type,
                selectedAgendaId: params.agendaId,
                selectedSessionId: params.parentSessionId,
                selectedSessionDuration: this.sessionDetails.sessionDuration,
                selectedSubSessionId: subSessionId,
                userLoadedSessionTime:
                  data.user.totalStreamWatched != null
                    ? parseInt(data.user.totalStreamWatched)
                    : 0,
                overAllSessionsWatchedTime:
                  data.user.totalStreamWatchedEvent != null
                    ? parseInt(data.user.totalStreamWatchedEvent)
                    : 0,
                selectedSessionCerificateEnabled: certificateEnabled,
                isVimeo: agendaSession.is_vimeo,
                sessionEndTime: endDateNextSession,
                nextSession: nextSession
                  ? { title: nextSessionTitle, isBreak: nextSession.isBreak,link: nextSessionLink,id: nextSessionId,isVimeo: nextSession.is_vimeo}
                  : null,
                loadingNextSession: false,
                loadingAgendaFromUrl: false,
                multiLangualSessoins: multiLangualSessoins
              });
              if (addSlug) {
                let withOutOrigin = window.location.href.replace(
                  window.origin,
                  ""
                );
                withOutOrigin = withOutOrigin.replace(
                  "/" + this.props.event.alias,
                  ""
                );
                if (slugString.length > 0 && withOutOrigin.length < 8) {
                  slugString = new URL(window.location.href) + slugString;
                  if(multiLangualSessoins.length > 0){
                    slugString += "?locale="+this.videoLocale
                  }
                  window.history.replaceState(null, null, slugString);
                }
              }
            } else {
              let message =
                "You need to have booked at least one paid package to access this content";
              if (data.user.type == "invoice") {
                message =
                  "This session and content is only available for paid ticket holders, Your transaction is in progress and once payment is confirmed you can access";
                this.shouldNavigate = false;
              }
              this.navigateToBooking = true;
              this.setState({
                showUserErrorPopup: true,
                errorMessageHeading: "Paid Delegate Session",
                errorMessageJoin: message,
              });
              window.scrollTo(0, 0);
              this.props.RemoveScroll(1);
              $("#sessionExpire").modal("show");
            }
          } else {
            this.setState({
              showUserErrorPopup: true,
              errorMessageHeading: "Login Required",
              errorMessageJoin: data.masg,
            });
            window.scrollTo(0, 0);
            this.props.RemoveScroll(1);
            $("#sessionExpire").modal("show");
          }
          if (this.contentRef != null) {
            this.contentRef.OnUserStatusLoaded();
          }
        }
      );
    }
    GARecordEvent(
      "SessionWatchClicked",
      user ? user.id : utility.makeid(12),
      analyticObj
    );
  };
  OnShowJoinSessionError = (title, message) => {
    this.shouldNavigate = false;
    this.setState({
      showUserErrorPopup: true,
      errorMessageHeading: title,
      errorMessageJoin: message,
    });
    window.scrollTo(0, 0);
    this.props.RemoveScroll(1);
    $("#sessionExpire").modal("show");
  };
  getPollQuestion = async (session, subSessionId, agendaId) => {
    const params = {
      session_id: session,
      parallel_session_id: subSessionId,
      agenda_id: agendaId,
    };
    await componentApi.getPollQuestionData(
      this.props.event.alias,
      this.user.token,
      params,
      (err, data) => {
        if (data) {
          this.setState({
            pollQuestion: [...data],
            loaded: true,
            realTimeData: true,
            loadingSessionPolls: false,
          });
        } else {
        }
      }
    );
  };
  loadSavedQuestions = async (agendaId, sessionId, subSessionId) => {
    var getQparams = {
      event_id: this.props.event.alias,
      event_agenda_id: agendaId,
      event_session_id: sessionId,
      event_sub_session_id: subSessionId,
    };
    await api.getAllQuestions(
      this.props.eventAlias,
      getQparams,
      (err, data) => {
        let user = utility.getLoggedInUser(this.props.event.alias);
        if (err) {
          return;
        }
        var questions = data.questions;
        if (questions != null) {
          for (var i = 0; i < questions.length; i++) {
            var upVotedUserIds = [];
            for (var j = 0; j < questions[i].user_votes.length; j++) {
              upVotedUserIds.push(questions[i].user_votes[j].user_id);
            }
            questions[i].eventAlias = questions[i].event_id;
            questions[i].agendaId = questions[i].event_agenda_id;
            questions[i].sessionId = questions[i].event_session_id;
            questions[i].senderName = questions[i].name;
            questions[i].responseId = questions[i].id;
            questions[i].id = i + 1;
            questions[i].message = questions[i].question;
            questions[i].upVoteCount = {
              count: parseInt(questions[i].votes_count),
              by: upVotedUserIds,
            };
            questions[i].upVoteCount.showChecked =
              questions[i].upVoteCount.by.findIndex((x) => x == user.id) > -1
                ? true
                : false;
            questions[i].approved =
              questions[i].is_approved == 1 ? true : false;
            questions[i].userId = questions[i].user_id;
            questions[i].time = questions[i].added_time;
            let date = utility.calculateZoneSpecificTime(
              questions[i].created_at.replace(" ", "T")
            );
            let hours = date.getHours();
            let minutes = date.getMinutes();
            if (minutes < 10) {
              minutes = "0" + minutes;
            }
            let ampm = hours >= 12 ? "PM" : "AM";
            if (hours > 12) {
              hours = hours - 12;
            }
            let timeString = hours + ":" + minutes + " " + ampm;
            questions[i].timeString = timeString;
            this.lastLoadedQuestionId = i + 1;
          }
          questions = questions.filter((x) => x.approved == true);
          this.setState({
            sessionSavedQuestions: questions,
            loadingSessionQuestions: false,
          });
        } else {
          this.setState({
            sessionSavedQuestions: [],
            loadingSessionQuestions: false,
          });
        }
        return;
      }
    );
  };
  loadSavedAnnouncements = async (agendaId, sessionId, subSessionId) => {
    var getQparams = {
      event_id: this.props.event.alias,
      event_agenda_id: agendaId,
      event_session_id: sessionId,
      event_sub_session_id: subSessionId,
    };
    await api.getAllAnnoucements(
      this.props.eventAlias,
      getQparams,
      (err, data) => {
        if (err) {
          return;
        }
        var announcements = data.announcements;
        if (announcements != null) {
          for (var i = 0; i < announcements.length; i++) {
            announcements[i].eventAlias = announcements[i].event_id;
            announcements[i].agendaId = announcements[i].event_agenda_id;
            announcements[i].sessionId = announcements[i].event_session_id;
            announcements[i].sender = announcements[i].name;
            announcements[i].responseId = announcements[i].id;
            announcements[i].id = i + 1;
            announcements[i].userId = announcements[i].user_id;
            let date = utility.calculateZoneSpecificTime(
              announcements[i].created_at.replace(" ", "T")
            );
            let hours = date.getHours();
            let minutes = date.getMinutes();
            if (minutes < 10) {
              minutes = "0" + minutes;
            }
            let ampm = hours >= 12 ? "PM" : "AM";
            if (hours > 12) {
              hours = hours - 12;
            }
            let timeString = hours + ":" + minutes + " " + ampm;
            announcements[i].timeString = timeString;
          }
          this.setState({
            sessionSavedAnnouncements: announcements,
            loadingSessionAnnouncements: false,
          });
        } else {
          this.setState({
            sessionSavedAnnouncements: [],
            loadingSessionAnnouncements: false,
          });
        }
        return;
      }
    );
  };
  getGroupChat = async (agendaId, sessionId, subSessionId = 0) => {
    var getQparams = {
      agendaId: agendaId,
      sessionId: sessionId,
      subSessionId: subSessionId,
    };
    let user = utility.getLoggedInUser(this.props.event.alias);
    let unReadGroupChatCount = "";
    this.missingParticipantIds = [];
    this.missingReplyParticipantIds = [];
    await componentApi.getGroupChat(
      this.props.event.alias,
      user.token,
      getQparams,
      async (err, data) => {
        if (err) {
          return;
        }
        for (var i = 0; i < data.length; i++) {
          let time = data[i].time.date.replace(" ", "T");
          time = utility.calculateZoneSpecificTime(new Date(time));
          let timeString = utility.calculateTimeString(time);
          for (var j = 0; j < data[i].replies.length; j++) {
            let replyTime = data[i].replies[j].time.replace(" ", "T");
            replyTime = utility.calculateZoneSpecificTime(new Date(replyTime));
            let replyTimeString = utility.calculateTimeString(replyTime);
            data[i].replies[j].likedBy =
              data[i].replies[j].likedBy == null
                ? []
                : data[i].replies[j].likedBy;
            data[i].replies[j].likeCount = parseInt(
              data[i].replies[j].likeCount
            );
            data[i].replies[j].timeString = replyTimeString;
            data[i].replies[j].type = "group";
            data[i].replies[j].eventAlias = this.props.event.alias;
            let pIndex = this.props.participants.findIndex(
              (x) => x.id == data[i].replies[j].senderId
            );
            if (pIndex > -1) {
              data[i].replies[j].profileImageUrl =
                this.props.participants[pIndex].profileImageUrl;
              data[i].replies[j].companyName =
                this.props.participants[pIndex].companyName;
              data[i].replies[j].jobTitle =
                this.props.participants[pIndex].jobTitle;
              data[i].replies[j].profileBio =
                this.props.participants[pIndex].profileBio;
            } else {
              this.missingReplyParticipantIds.push(data[i].replies[j].senderId);
            }
          }
          data[i].likedBy = data[i].likedBy == null ? [] : data[i].likedBy;
          data[i].likeCount = parseInt(data[i].likeCount);
          data[i].timeString = timeString;
          data[i].type = "group";
          data[i].eventAlias = this.props.event.alias;
          let pIndex = this.props.participants.findIndex(
            (x) => x.id == data[i].senderId
          );
          let participant = this.props.participants[pIndex];
          if (participant != null) {
            data[i].profileImageUrl = participant.profileImageUrl;
            data[i].companyName = participant.companyName;
            data[i].jobTitle = participant.jobTitle;
            data[i].profileBio = participant.profileBio;
          } else {
            this.missingParticipantIds.push(data[i].senderId);
          }
        }
        let otherUserMessages = data.filter(
          (item) => item.senderId != this.user.id
        );
        unReadGroupChatCount =
          otherUserMessages.length > 0
            ? otherUserMessages.length.toString()
            : "";
        this.setState(
          {
            groupChat: data,
            unReadGroupChatCount,
            loadingSessionGroupChat: false,
          },
          () => {}
        );
      }
    );
    if (this.missingParticipantIds.length > 0) {
      await participantApi.getEventParticipants(
        this.props.event.alias,
        (list) => {
          if (list.length > 0) {
            let groupChat = this.state.groupChat;
            for (var i = 0; i < groupChat.length; i++) {
              let index = list.findIndex((x) => x.id == groupChat[i].senderId);
              if (index > -1) {
                groupChat[i].profileImageUrl = list[index].profileImageUrl;
                groupChat[i].companyName = list[index].companyName;
                groupChat[i].jobTitle = list[index].jobTitle;
                groupChat[i].profileBio = list[index].profileBio;
              }
            }
            this.setState({ groupChat, loadingSessionGroupChat: false });
          }
        },
        "",
        "",
        this.missingParticipantIds
      );
    }
    if (this.missingReplyParticipantIds.length > 0) {
      await participantApi.getEventParticipants(
        this.props.event.alias,
        (list) => {
          if (list.length > 0) {
            let groupChat = this.state.groupChat;
            for (var i = 0; i < groupChat.length; i++) {
              for (var j = 0; j < groupChat[i].replies.length; j++) {
                let index = list.findIndex(
                  (x) => x.id == groupChat[i].replies[j].senderId
                );
                if (index > -1) {
                  groupChat[i].replies[j].profileImageUrl =
                    list[index].profileImageUrl;
                  groupChat[i].replies[j].companyName = list[index].companyName;
                  groupChat[i].replies[j].jobTitle = list[index].jobTitle;
                  groupChat[i].replies[j].profileBio = list[index].profileBio;
                }
              }
            }
            this.setState({ groupChat, loadingSessionGroupChat: false });
          }
        },
        "",
        "",
        this.missingReplyParticipantIds
      );
    }
  };
  loadSessionPolls(agendaId, sessionId, subSessionId = 0) {}
  handleClosePopup = () => {
    this.setState({ showUserErrorPopup: false }, () => {
      if (this.shouldNavigate) {
        if (this.navigateToBooking) {
          setTimeout(() => {
            this.OnNavigateToPage(this.props.event.alias + "/booking");
          }, 200);
          this.navigateToBooking = false;
        } else {
          localStorage.removeItem(
            Constants.API_TOKEN + this.props.event.alias + "user_info"
          );
          if (this.agendaIndex == null) {
            this.OnNavigateToPage(this.props.event.alias + "/login");
            return;
          }
          localStorage.setItem("agendaIndex", this.agendaIndex);
          if (this.sessionDetails != null) {
            localStorage.setItem(
              "sessionDetails",
              JSON.stringify(this.sessionDetails)
            );
          }
          setTimeout(() => {
            this.OnNavigateToPage(this.props.event.alias + "/login");
          }, 200);
        }
      }
    });
  };
  OnMarkFavorite = (data) => {
    let user = utility.getLoggedInUser(this.props.event.alias);
    if (user != null) {
      componentApi.markAgendaFavorite(
        this.props.event.alias,
        user.token,
        data,
        (err, resObj) => {
          if (err != null) {
            if (err == -1) {
              this.shouldNavigate = true;
              this.setState({
                showUserErrorPopup: true,
                errorMessageHeading: "Login Required",
                errorMessageJoin: "Your session is expired please login again",
              });
              window.scrollTo(0, 0);
              this.props.RemoveScroll(1);
              $("#sessionExpire").modal("show");
              localStorage.removeItem(
                Constants.API_TOKEN + this.props.event.alias + "user_info"
              );
            }
          } else {
            let favourites = this.props.myFavourites;
            if (favourites != null) {
              let index = favourites.findIndex((x) => x.id == resObj.id);
              if (index < 0) {
                favourites.push(resObj);
              } else {
                favourites.splice(index);
              }
              this.props.updateMyfavourites(favourites);
            }
          }
        }
      );
    }
  };
  timeZoneChanged = (selectedTimeZone) => {
    this.setState({ selectedTimeZone });
    let timeZoneSplit = selectedTimeZone.split(":");
    let timeZoneHour = parseInt(timeZoneSplit[0]);
    selectedTimeZone = parseFloat(timeZoneHour + "." + timeZoneSplit[1]);
    this.convertToLocalTime(true, selectedTimeZone);
  };
  convertToLocalTime = (
    localTime,
    timeZone = new Date().getTimezoneOffset() / 60
  ) => {
    this.setState({ showingLocalTime: localTime });
    let desiredTimeOffset = timeZone;
    let offSet = 0;
    // if(desiredTimeOffset < 0){
    //     desiredTimeOffset = Math.abs(desiredTimeOffset)
    // }else{
    //     desiredTimeOffset = -desiredTimeOffset
    // }
    offSet = Math.abs(desiredTimeOffset - this.timeZone);
    if (timeZone < this.timeZone) {
      offSet = -offSet;
    }
    if (localTime) {
      let agenda = this.state.agenda;
      for (var i = 0; i < agenda.length; i++) {
        let agendaItem = agenda[i];
        agendaItem.firstDate = null;
        let agendDateSplit = agendaItem.originalDate.split("-");
        let dateString =
          agendDateSplit[2] + "-" + agendDateSplit[1] + "-" + agendDateSplit[0];
        var convertedStartTime = null;
        var convertedEndTime = null;
        var firstSessionStartDate = null;
        for (var j = 0; j < agendaItem.sessions.data.length; j++) {
          var sessionItem = agendaItem.sessions.data[j];

          let startTimeString = sessionItem.original_start_time;
          let timeStringSplit = startTimeString.split(":");
          if (parseInt(timeStringSplit[0]) < 10) {
            startTimeString =
              "0" + parseInt(timeStringSplit[0]) + ":" + timeStringSplit[1];
          }
          let startTimeDateString = dateString + "T" + startTimeString;
          let endTimeDateString =
            dateString + "T" + sessionItem.original_end_time;
          convertedStartTime = utility.calculateZoneSpecificTime(
            startTimeDateString,
            offSet
          );
          convertedEndTime = utility.calculateZoneSpecificTime(
            endTimeDateString,
            offSet
          );
          let hours = convertedStartTime.getHours();
          let minutes = convertedStartTime.getMinutes();

          sessionItem.start_time =
            (hours < 10 ? "0" + hours : hours) +
            ":" +
            (minutes < 10 ? "0" + minutes : minutes);
          hours = convertedEndTime.getHours();
          minutes = convertedEndTime.getMinutes();
          sessionItem.end_time =
            (hours < 10 ? "0" + hours : hours) +
            ":" +
            (minutes < 10 ? "0" + minutes : minutes);
          if (sessionItem.hasMultiSession == "1") {
            for (var k = 0; k < sessionItem.subSessions.length; k++) {
              sessionItem.subSessions[k].start_time = sessionItem.start_time;
              sessionItem.subSessions[k].end_time = sessionItem.end_time;
              sessionItem.subSessions[k].eventObj = this.createSessionEventItem(
                agenda[i].date,
                sessionItem.subSessions[k]
              );
            }
          } else {
            sessionItem.eventObj = this.createSessionEventItem(
              agenda[i].date,
              sessionItem
            );
          }
          if (j == 0) {
            firstSessionStartDate = convertedStartTime;
          }
          if (j == agendaItem.sessions.data.length - 1) {
            let date = convertedEndTime.getDate();
            if (date < 10) {
              date = "0" + date;
            }
            let month = convertedEndTime.getMonth() + 1;
            if (month < 10) {
              month = "0" + month;
            }
            dateString =
              date + "-" + month + "-" + convertedEndTime.getFullYear();
            agendaItem.date = dateString;

            if (
              firstSessionStartDate.getDate() != convertedEndTime.getDate() ||
              firstSessionStartDate.getMonth() != convertedEndTime.getMonth() ||
              firstSessionStartDate.getFullYear() !=
                convertedEndTime.getFullYear()
            ) {
              date = firstSessionStartDate.getDate();
              if (date < 10) {
                date = "0" + date;
              }
              month = firstSessionStartDate.getMonth() + 1;
              if (month < 10) {
                month = "0" + month;
              }
              dateString =
                date + "-" + month + "-" + convertedEndTime.getFullYear();
              agendaItem.firstDate = dateString;
            }
          }
        }
        // if(convertedEndTime != null){
        //     let date = convertedEndTime.getDate()
        //     if(date < 10){
        //         date = "0"+date
        //     }
        //     let month = convertedEndTime.getMonth()+1
        //     if(month < 10){
        //         month = "0"+month
        //     }
        //     dateString = date+"-"+month+"-"+convertedEndTime.getFullYear()
        //     agendaItem.date = dateString
        // }
      }
      this.setState({ agenda });
    } else {
      let agenda = this.state.agenda;
      for (var i = 0; i < agenda.length; i++) {
        let agendaItem = agenda[i];
        agendaItem.date = agendaItem.originalDate;
        for (var j = 0; j < agendaItem.sessions.data.length; j++) {
          let sessionItem = agendaItem.sessions.data[j];
          sessionItem.start_time = sessionItem.original_start_time;
          sessionItem.end_time = sessionItem.original_end_time;
        }
      }
      this.setState({ agenda });
    }
  };
  changeUserTimeZone = (data) => {
    this.props.userChangeTimeZone(data);
  };
  donwloadAgendaButtonEvent = () => {
    if (this.user) {
      GARecordEvent("downloadAgendaButtonClicked", this.user.id, {
        category: "Download Agenda Button",
        action: "Agenda page download agenda clicked",
        userName: this.user.firstName + "" + this.user.lastName,
        userEmail: this.user.email,
      });
    } else {
      GARecordEvent("downloadAgendaButtonClicked", "non user", {
        category: "Download Agenda Button",
        action: "Agenda page download agenda clicked",
        userName: "unknown",
        userEmail: "unknown",
      });
    }
  };
  donwloadAgendaFormEvent = (name, email) => {
    if (this.user) {
      GARecordEvent("downloadAgendaSubmitClicked", this.user.id, {
        category: "Download Agenda Submit",
        action: "Agenda page download agenda submit",
        userName: this.user.firstName + "" + this.user.lastName,
        userEmail: this.user.email,
      });
    } else {
      GARecordEvent("downloadAgendaSubmitClicked", "non user", {
        category: "Download Agenda Submit",
        action: "Agenda page download agenda submit",
        userName: name,
        userEmail: email,
      });
    }
  };
  startNewSession = (subSessionIndex) => {
    this.setState({ loadingNextSession: true });
    let agendaItem = this.state.agenda[this.agendaIndex];
    let newSessionIndex = this.sessionIndex + 1;
    var agendaSession = agendaItem.sessions.data[newSessionIndex];
    if (agendaSession.subSessions) {
      if (subSessionIndex == -1) {
        subSessionIndex = 0;
      } else {
        subSessionIndex += 1;
      }
    } else {
      subSessionIndex = -1;
    }
    let slugString =
      window.location.origin + "/" + this.props.event.alias + "/agenda";
    window.history.replaceState(null, null, slugString);
    if (agendaItem.slug) {
      slugString += "/" + agendaItem.slug;
      if (agendaSession.slug) {
        slugString += "/session/" + agendaSession.slug;
      }
      if (subSessionIndex > -1) {
        agendaSession = agendaSession.subSessions[subSessionIndex];
        let slug = agendaSession.slug;
        if (slug) {
          slugString += "/" + slug;
        }
      }
      slugString += "?locale=en"
      window.history.replaceState(null, null, slugString);
      this.props.AgendaSessionId(slugString);
    }
    this.videoLocale = "en"
    this.OnClickVirtualAccessButton(
      agendaSession.virtual_access_status,
      this.agendaIndex,
      newSessionIndex,
      subSessionIndex,
      agendaSession.isPurchased
    );
  };
  render() {
    let user = this.user; // utility.getLoggedInUser(this.props.event.alias)
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {this.state.menu != null ? this.state.menu.title : "Agenda"}
          </title>
          <meta
            property="description"
            content={
              this.props.seoData == null
                ? ""
                : this.props.seoData.meta_description
            }
          />
          <meta
            property="title"
            content={
              this.props.seoData == null ? "" : this.props.seoData.seo_title
            }
          />
        </Helmet>
        {/* {!this.state.showSessionView? */}
        <div id="header-top">
          <Header
            selectedIndex={this.state.selectedIndex}
            selectedSubIndex={this.state.selectedSubIndex}
            onMenuItemClick={this.OnNavigateToPage}
            elements={this.props.headerMenuItems}
            facebookLink={this.props.event.facebook}
            linkedInLink={this.props.event.linkedIn}
            twitterLink={this.props.event.twitter}
            instagramLink={this.props.event.instagram}
            isMarketplace={this.props.event.is_marketplace}
            googleLink={this.props.event.google}
            phone={this.props.event.phone_number}
            eventLogo={this.props.event.logo}
            eventName={this.props.event.name}
            eventAlias={this.props.event.alias}
            event={this.props.event}
            onLoginClick={() =>
              this.OnNavigateToPage(this.props.event.alias + "/login")
            }
            onSignupClick={() =>
              this.OnNavigateToPage(this.props.event.alias + "/sign_up")
            }
            onClickAccountItem={this.OnClickAccountItem}
            availableLocales={this.props.availableLocales}
            languageClick={this.languageClick}
          />
          {user != null && user.isBooking && Constants.showVirtualZone ? (
            <VirtualZone
              selectedItemIndex={this.state.virtualZoneItemIndex}
              event={this.props.event}
              eventAlias={this.props.event.alias}
              onMenuItemClick={(path) => {
                if (path != "") {
                  this.OnNavigateToPage(path);
                }
              }}
              unReadCount={this.props.unReadCount}
              onNotificationSeen={this.props.onNotificationSeen}
              onClearNotifications={this.props.onClearNotifications}
              notificationsCount={this.props.unReadNotificationsCount}
              notifications={this.props.notifications}
              onClickAccountItem={this.OnClickAccountItem}
              onClickNotification={(path, isExternal) => {
                if (isExternal) {
                  window.open(path, "_blank");
                } else {
                  this.OnNavigateToPage(this.props.event.alias + path);
                }
              }}
              menuItems={this.props.virtualMenuItems}
              user={user}
            />
          ) : null}
        </div>
        {/* : null} */}
        <RegisterModel
          eventAlias={this.props.event.alias}
          agendaTitle={this.translation.downloadAgenda}
          agendaSubTitle={this.translation.provideDetail}
          agendaSubmitButtonText={this.translation.download}
          downloadAgendaFormEvent={this.donwloadAgendaFormEvent}
          showCloseButton={true}
        />
        <div className={`content-main`}>
          {this.state.loadingAgendaFromUrl ? (
            <ContentLoader />
          ) : this.state.agenda != null ? (
            <div>
              {this.state.showComingSoonMsg ? (
                <div className="text-center">
                  <img
                    style={{ width: "50%", margin: "50px 0px" }}
                    src="/images/coming-soon.svg"
                  />
                </div>
              ) : !this.state.showSessionView ? (
                <Content
                  showingLocalTime={false}
                  ref={(ref) => (this.contentRef = ref)}
                  agenda={this.state.agenda}
                  defaultTimeZone={this.props.event.timezone}
                  event={this.props.event}
                  saveSession={this.saveSession}
                  streamType={this.state.streamType}
                  agendaIndex={this.state.agendaIndex}
                  sessionIndex={this.state.sessionIndex}
                  subSessionIndex={this.state.subSessionIndex}
                  loadSessionData={this.state.loadSessionData}
                  unsetSessionData={this.unsetSessionData}
                  countryCode={this.props.event.countryCode}
                  timeZone={this.state.selectedTimeZone}
                  userSelectedTimeZone={this.props.userSelectedTimeZone}
                  userChangeTimeZone={this.changeUserTimeZone}
                  onShowJoinSessionError={this.OnShowJoinSessionError}
                  onClickRegister={() =>
                    this.OnNavigateToPage(this.props.event.alias + "/booking")
                  }
                  onClickVirtualAccessButton={this.OnClickVirtualAccessButton}
                  onMarkFavorite={this.OnMarkFavorite}
                  onClickAddToCalendar={this.OnClickAddToClendar}
                  user={user}
                  defaultAgendaIndex={this.state.defaultAgendaIndex}
                  timeZoneChanged={this.timeZoneChanged}
                  donwloadAgendaButtonEvent={this.donwloadAgendaButtonEvent}
                  alreadyLoadedSessionIndex={this.state.selectedSessionIndex}
                  alreadyLoadedSubSessionIndex={
                    this.state.selectedSubSessionIndex
                  }
                  onClickAgendaTab={(index) =>
                    this.setState({ defaultAgendaIndex: index })
                  }
                  translation={this.translation}
                />
              ) : (
                <Session
                  ref={(ref) => (this.sessionRef = ref)}
                  eventAlias={this.props.event.alias}
                  user={this.user}
                  certificateSave={this.state.certificateSave}
                  cancelSaveCertificate={this.cancelSaveCertificate}
                  eventLogo={this.props.event.logo}
                  getPollQuestion={this.getPollQuestion}
                  clearCurrentAgendadata={() => {
                    this.setState({
                      speakers: [],
                      pollQuestion: [],
                      groupChat: [],
                      savedQuestions: [],
                      savedAnnouncements: [],
                    });
                  }}
                  title={this.state.selectedSessionTitle}
                  speakers={this.state.selectedSessionSpeakers}
                  sponsors={this.state.selectedSessionSponsors}
                  webinarId={this.state.webinarId}
                  pollQuestion={this.state.pollQuestion}
                  webinarPassword={this.state.webinarPassword}
                  streamType={this.state.streamType}
                  streamLink={this.state.streamLink}
                  videoId={this.state.videoId}
                  realTimePoll={this.state.realTimeData}
                  agendaId={this.state.selectedAgendaId}
                  sessionId={this.state.selectedSessionId}
                  sessionDuration={this.state.selectedSessionDuration}
                  subSessionId={this.state.selectedSubSessionId}
                  sessionCertificateEnabled={
                    this.state.selectedSessionCerificateEnabled
                  }
                  savedQuestions={this.state.sessionSavedQuestions}
                  savedAnnouncements={this.state.sessionSavedAnnouncements}
                  lastQuestionId={this.lastLoadedQuestionId}
                  savedSessionTime={this.state.userLoadedSessionTime}
                  overAllSessionsWatchedTime={
                    this.state.overAllSessionsWatchedTime
                  }
                  unReadGroupChatCount={this.state.unReadGroupChatCount}
                  groupChat={this.state.groupChat}
                  socket={this.props.socket}
                  onClickBackToAgendaView={(link) => {
                    this.setState({ showSessionView: false });
                    if (link) {
                      this.props.updateFloatingPlayerLink(link);
                    }
                    this.videoLocale = "en"
                  }}
                  NavigateToLogin={() =>
                    this.OnNavigateToPage(this.props.event.alias + "/login")
                  }
                  certificateCompletionTime={this.certificateCompletionTime}
                  eventCertificateCompletion={this.eventCertificateComplrtion}
                  requestingCertificate={this.state.requestingCertificate}
                  cerificateRequestResponse={
                    this.state.cerificateRequestResponse
                  }
                  certificateRequestSent={this.state.certificateRequestSent}
                  isVimeo={this.state.isVimeo}
                  sessionEndTime={this.state.sessionEndTime}
                  nextSession={this.state.nextSession}
                  onClickStartNewSection={this.startNewSession}
                  loadingNextSession={this.state.loadingNextSession}
                  eventTimeZone={this.props.event.timezone}
                  selectedTimeZone={this.state.selectedTimeZone}
                  defaultTimeZone={this.props.event.timezone}
                  polls={this.state.selectedSessionPolls}
                  onClickRequestCertificate={(data) => {
                    if (data == null) {
                      let cerificateRequestResponse = {
                        isError: true,
                        message:
                          "Complete the required time given to become eligible for the certificate",
                      };
                      this.setState({ cerificateRequestResponse });

                      setTimeout(() => {
                        this.setState(
                          this.setState({ cerificateRequestResponse: {} })
                        );
                      }, 3000);
                      return;
                    }
                    data.user_id = user.id;
                    this.setState({ requestingCertificate: true });
                    componentApi.requestCertificate(
                      this.props.event.alias,
                      data,
                      (err, message) => {
                        let cerificateRequestResponse = {};
                        let certificateRequestSent = true;
                        if (err) {
                          cerificateRequestResponse.isError = true;
                          // certificateRequestSent = false
                        }
                        cerificateRequestResponse.message = message;
                        this.setState({
                          cerificateRequestResponse,
                          certificateRequestSent,
                          requestingCertificate: false,
                        });
                      }
                    );
                    setTimeout(() => {
                      this.setState(
                        this.setState({ cerificateRequestResponse: {} })
                      );
                    }, 1000);
                  }}
                  loadingSessionGroupChat={this.state.loadingSessionGroupChat}
                  loadingSessionQuestions={this.state.loadingSessionQuestions}
                  loadingSessionAnnouncements={
                    this.state.loadingSessionAnnouncements
                  }
                  loadingSessionPolls={this.state.loadingSessionPolls}
                  multiLangualSessoins = {this.state.multiLangualSessoins}
                  selectedVideoLocale = {this.videoLocale}
                  onLanguageChanged = {(locale)=> this.floatingPlayerLocale = locale}
                />
              )}
              {!this.state.showSessionView ? (
                <>
                  <Sponsers
                    event={this.state.event}
                    event_alias={this.state.event_alias}
                    onClickSeeAllSponsors={() =>
                      this.OnNavigateToPage(
                        this.props.event.alias + "/partners"
                      )
                    }
                  />
                  <ContactInfo
                    event={this.state.event}
                    event_alias={this.state.event_alias}
                    type={Constants.Additional}
                  />
                  {this.props.event.is_marketplace == "0" &&
                  this.props.event.is_jobfair == 0 ? (
                    <NewsLetter
                      title="SIGN UP FOR AGENDA UPDATES"
                      event={this.state.event}
                      event_alias={this.state.event_alias}
                      buttonText="Sign Up"
                      type="agendaUpdate"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: window.innerHeight,
                background: "#f7f7f7",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.loadingError == "" ? (
                <Loader type="Oval" color="#00b6ff" height={40} width={40} />
              ) : (
                <ErrorScreen
                  message={this.state.loadingError}
                  onPressRetry={this.OnPressRetry}
                />
              )}
            </div>
          )}
          {!this.state.showSessionView ? (
            <Footer1
              event={this.state.event}
              elements={this.props.footerMenuItems}
              onMenuItemClick={this.OnNavigateToPage}
            />
          ) : null}
        </div>
        {!this.state.showSessionView ? (
          <Footer2
            event={this.props.event}
            copyRightLogo={this.props.event.copyright_logo}
            copyRightText={this.props.event.copyright_text}
            copyRightLink={this.props.event.copyright_link}
            showCopyRight={
              this.props.event.copyright_text || this.props.event.copyright_logo
            }
          />
        ) : null}
        {/* <Modal 
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showUserErrorPopup} 
                    onHide={this.handleClosePopup}
                >
                    <Modal.Header>
                    <Modal.Title>{this.state.errorMessageHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.errorMessageJoin}</Modal.Body>
                    <Modal.Footer>
                        {this.state.showCloseButton ?
                        <Button variant="primary" className="telehealth-btn" onClick={this.handleClosePopup}>
                            Close
                        </Button> : null}
                    </Modal.Footer>
                </Modal> */}
        <PermiumUserModal
          id="sessionExpire"
          iconClass="fa-lock"
          title={this.state.errorMessageHeading}
          description={this.state.errorMessageJoin}
          buttonText="Ok"
          backdrop="static"
          keyboard={false}
          showButton={true}
          onClose={() => {
            $("#sessionExpire").modal("hide");
            $(".modal-backdrop").remove();
          }}
          onClickBookNow={() => {
            this.handleClosePopup();
            this.props.RemoveScroll(0);
            $("#sessionExpire").modal("hide");
            $(".modal-backdrop").remove();
            //   this.OnNavigateToPage(`${this.props.event.alias}/login`);
          }}
        />
        <PermiumUserModal
          id="premiumSession"
          iconClass="fa-star"
          title="Premium Session"
          description={this.state.premiumSessionText}
          buttonText="Book Now"
          onClose={() => {
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
          }}
          showButton={true}
          onClickBookNow={() => {
            $("#premiumSession").modal("hide");
            $(".modal-backdrop").remove();
            this.props.RemoveScroll(0);
            this.OnNavigateToPage(this.props.event.alias + "/booking");
          }}
        />
        <LoginPopup
          title={<Trans>loggin_required</Trans>}
          description={<Trans>description_LOGIN</Trans>}
          onClickRedirect={(path) => {
            $("#loginRequired").modal("hide");
            $(".modal-backdrop").remove();
            this.props.RemoveScroll(0);
            this.OnNavigateToPage(this.props.event.alias + path);
          }}
          RemoveScroll={this.props.RemoveScroll}
        />
        <PermiumUserModal
          id="profileInCompletePopup"
          iconClass="fa-info-circle"
          title="Complete your profile"
          description="<p>Before proceeding please complete your jobseeker profile</p>"
          buttonText="Complete"
          showButton={true}
          onClose={() => {
            $("#profileInCompletePopup").modal("hide");
            $(".modal-backdrop").remove();
          }}
          onClickBookNow={() => {
            $("#profileInCompletePopup").modal("hide");
            $(".modal-backdrop").remove();
            this.props.RemoveScroll(0);
            this.props.onClickAccountItem(null, "");
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  agenda: state.agenda.agendaData,
  myFavourites: state.myAccount.favourites,
  participants: state.participants.participantsList,
  userSelectedTimeZone: state.agenda.userSelectedTimeZone,
  naviagtionValue: state.appData.naviagtionValue,
});
const mapDispatchToProps = (dispatch) => ({
  agendaLoaded: (data) => dispatch(AgendaDataLoaded(data)),
  updateMyfavourites: (data) => dispatch(UpdateMyFavourites(data)),
  userChangeTimeZone: (data) => dispatch(userChangeTimeZone(data)),
  updateFloatingPlayerLink: (data) => dispatch(FloatingPlayerAdded(data)),
  RemoveScroll: (data) => dispatch(RemoveScroll(data)),
  AgendaSessionId: (data) => dispatch(AgendaSessionId(data)),
  naviagtionAgneda: (data) => dispatch(naviagtionAgneda(data)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Schedule);
