import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from './api';
import hexToRgba from 'hex-to-rgba';
import { HomeVideosLoaded } from '../VideoPage/actions';
import utility from '../../Utility/utility';
import { connect } from 'react-redux'
import $ from 'jquery'
import { Thumbs } from 'react-responsive-carousel';
import  PermiumUserModal  from '../modals/premiumUserModal';
import ContentLoader from 'react-content-loader'
import {Trans} from 'react-i18next'
export class HomeVideos extends React.Component{
	_isMounted = false
    constructor(props) {
        super(props);
        this.state = {
			videos : [],
            showContent : false,
            selectedVideoUrl : '',
            selectedVideoTitle : '',
            title : '',
            isLoading : true
        };
        this.bothVideosHasDescription = false
        this.isBooking = false
    }
    componentDidMount(){
        this.user = utility.getLoggedInUser(this.props.eventAlias)
        if(this.user != null){
            this.isBooking = this.user.isBooking
        }
        this.isPremiumUser = this.user == null ? false : this.isBooking
        // console.log('val :',this.backgroundColor)
        $(document).ready(function() {
            
            
        })
        this._isMounted = true
        if(this.props.videos != null){
            let showContent = false
            if(this.props.videos.videos.length > 0){
                showContent = true
            }
            let pVideoIndex = this.props.videos.videos.findIndex(x=>x.is_premium == "1")
            var videoUrl = null
            if(pVideoIndex > -1){
                videoUrl = this.props.videos.videos[pVideoIndex].link
            }else{
                videoUrl = "1"
            }
            if(videoUrl == null && this.isBooking){
                this.loadVideosFromApi()
            }else{
                this.checkForVideosPresentationStyle(this.props.videos.videos)
                this.setState({videos : this.props.videos.videos,title : this.props.videos.title,showContent,isLoading : false}) 
            }
        }else{
            this.loadVideosFromApi()
        }
    }
    loadVideosFromApi = () => {
        var params = {
            view_premium : this.isPremiumUser ? 1 : 0
        }
        api.getHomeVideos(this.props.eventAlias,params,(err,videos,title)=>{
            if(err == null && this._isMounted){
                this.checkForVideosPresentationStyle(videos)
                if(videos.length > 0){
                    this.setState({videos,title})
                }
                var videosData = {
                    title : title,
                    videos : videos
                }
                this.props.homeVideosLoaded(videosData)
            }
            this.setState({showContent : true,isLoading : false})
        })
    }
    checkForVideosPresentationStyle = (videos) => {
        if(videos.length == 2){
            var count = 0
            for(var i=0;i<videos.length;i++){
                if(videos[i].description != null){
                    count += 1
                }
            }
            if(count == 2){
                this.bothVideosHasDescription = true
            }
        }
    }
	OnClickHighLightCard = () => {
		this.props.onClickCard()
	}
	componentWillUnmount(){
		this._isMounted = false
    }
    OnClickWatch = (isPremium,url,title,dateString) => {
        // let completeTitle = (title == null ? "" : title) + " " + dateString
        let completeTitle = title == null ? "" : title
        if(isPremium == 0){
            this.setState({selectedVideoTitle : completeTitle,selectedVideoUrl : url},()=>{
                $("#video-modal").modal('show')
                // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                document.body.style.overflowY = "hidden"
            })
        }else{
            if(this.user != null && this.isBooking){
                this.setState({selectedVideoTitle : completeTitle,selectedVideoUrl : url},()=>{
                    $("#video-modal").modal('show')
                    // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                    document.body.style.overflowY = "hidden"
                })
            }else{
                $("#premiumSession").modal('show')
                // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
                document.body.style.overflowY = "hidden"
                document.addEventListener('mousedown', this.handleClickOutside);
            }
        }
    }
    handleClickOutside = () => {
        // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
        document.body.style.overflowY = "scroll"
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
	render(){
        if(this.state.isLoading){
            return(
                <ContentLoader style = {{padding : 25}} viewBox = "0 0 400 70">
                    <rect x="20%" y="0" rx="5" ry="5" width="110" height="50" />
                    <rect x="20%" y="55" rx="4" ry="4" width="110" height="6" />
                    <rect x="50%" y="0" rx="5" ry="5" width="110" height="50" />
                    <rect x="50%" y="55" rx="4" ry="4" width="110" height="6" />
                </ContentLoader>
            )
        }
        const videos = this.state.videos; 
		if(this.state.showContent){
			return(
				<>
                <PermiumUserModal
                    id = "premiumSession"
                    iconClass = "fa-star"
                    title = "Premium Video"
                    description = "<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
                    buttonText = "Book Now"
                    showButton = {true}
                    onClose = {()=>{
                        $("#premiumSession").modal('hide')
                        $('.modal-backdrop').remove();
                    }}
                    onClickBookNow = {()=>{
                        $("#premiumSession").modal('hide')
                        $('.modal-backdrop').remove();
                        this.props.onNavigateToPage('/booking')}
                }/>
                {this.state.videos.length >= 3 ?
                <section class="blog videos py-3">
                    <div class="container">
                        {this.state.title != null ?
                        <h5 class = "text-center font-weight-bold text-uppercase">{this.state.title}</h5> : null}
                        <div class="row py-3">
                            {this.state.videos.map((item,index)=>{
                                return(
                                    <div class="col-md-4 mb-3 mb-md-0">
                                        <div class="thumbnail h-100">
                                        {item.thumbnail == null ?
                                            item.is_premium == 0 || this.isPremiumUser?
                                            <iframe style = {{height : '100%',width : '100%'}} class="embed-responsive-item" src={item.link} allowfullscreen></iframe>:
                                            <>
                                                <img src = {item.thumbnail != null ? item.thumbnail : '/images/no_video_thmbnail.svg'} alt = "blog-1_img"/>
                                                <div class="mt-2">
                                                    <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickWatch(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0">
                                                    {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> : 
                                                    this.isPremiumUser ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> 
                                                    :<> <i class="fas fa-lock mr-2"></i><Trans>premiumVideo</Trans></>}
                                                    </a>
                                                </div>
                                            </>:
                                            <>
                                                <img src = {item.thumbnail} alt = "blog-1_img"/>
                                                <div class="mt-2">
                                                    <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickWatch(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0">
                                                    {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> : 
                                                    this.isPremiumUser ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> 
                                                    :<> <i class="fas fa-lock mr-2"></i><Trans>premiumVideo</Trans></>}
                                                    </a>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </div>

                        <div class="row py-2 justify-content-center">
                            <a style = {{cursor : 'pointer'}} onClick = {()=>this.props.onNavigateToPage('/videos')} class = "font-weight-bold text-uppercase text-dark text-underline see_btn">
                                <Trans>seeAll</Trans></a>
                        </div>

                    </div>
                </section> :
                (this.state.videos.length == 2 && this.bothVideosHasDescription) || this.state.videos.length == 1 ?
                <section class = "blog py-3">
                    <div class="container blog-container">
                        {this.state.title != null ?
                        <h5 class = "text-center font-weight-bold text-uppercase">{this.state.title}</h5> : null}
                            {this.state.videos.map((item,index)=>{
                                return(
                                index == 0 ?
                                <div class="row blog-card-vII border bg_primary overflow-hidden" id = "blogcard-I">
                                    <div class = "col-sm-6 pl-md-6 py-5">
                                        <div class="thumbnail border-0">
                                            {item.thumbnail == null && (item.is_premium == 0 || this.isPremiumUser) ?
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item" src={item.link} allowfullscreen></iframe>
                                            </div> :
                                            <>
                                            <img src = {item.thumbnail != null ? item.thumbnail : '/images/no_video_thmbnail.svg'} alt = "blog-2_img"/>
                                            <div class="mt-2">
                                                <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickWatch(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0" >
                                                {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> : 
                                                    this.isPremiumUser ? <><i class="fas fa-play mr-2"></i><Trans>watchNow</Trans></> 
                                                    :<> <i class="fas fa-lock mr-2"></i><Trans>premiumVideo</Trans></>}
                                                </a>
                                            </div>
                                            </>}
                                        </div>
                                    </div>
                                    <div class = "col-sm-6 pr-md-6">
                                        <div class = "blog-text py-5 px-3">
                                            <h4 class = "f-600">
                                                {item.title}
                                            </h4>
                                            <p>
                                                {item.description}
                                            </p>
                                        
                                        </div>
                                    </div>
                                </div> :
                                <div class = "row blog-card-vII border bg_secondary overflow-hidden" id = "blogcard-II">
                                    <div class = "col-sm-6 pl-md-6">
                                        <div class = "blog-text py-5 px-3">
                                            <h4 class = "f-600">
                                                {item.title}
                                            </h4>
                                            <p>
                                                {item.description}
                                            </p>
                                            
                                        </div>
                                    </div>
                                    <div class = "col-sm-6 pr-md-6 py-5">
                                        <div class="thumbnail border-0">
                                            {item.thumbnail == null && (item.is_premium == 0 || this.isPremiumUser) ?
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item" src={item.link} allowfullscreen></iframe>
                                            </div> :
                                            <>
                                            <img src = {item.thumbnail != null ? item.thumbnail : '/images/no_video_thmbnail.svg'} alt = "blog-2_img"/>
                                            <div class="mt-2">
                                                <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickWatch(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0" >
                                                {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i><Trans>watchNow</Trans> </> : 
                                                    this.isPremiumUser ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> 
                                                    :<> <i class="fas fa-lock mr-2"></i><Trans>premiumVideo</Trans></>}
                                                </a>
                                            </div>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        <div class="row py-2 justify-content-center">
                            <a style = {{cursor : 'pointer'}} onClick = {()=>this.props.onNavigateToPage('/videos')} class = "font-weight-bold text-uppercase text-dark text-underline see_btn"><Trans>seeAll</Trans></a>
                        </div>
                    </div>
                </section> : 
                this.state.videos.length == 2 ?
                <section class="blog videos py-3 bg-light">
                <div class="container">
                        {this.state.title != null ?
                        <h5 class = "text-center font-weight-bold text-uppercase">{this.state.title}</h5> : null}
                        <div class="row py-3">
                            {this.state.videos.map((item,index)=>{
                                return(
                                <div class="col-md-6 mb-3 mb-md-0">
                                    <div class="thumbnail h-100">
                                        {item.thumbnail == null && (item.is_premium == 0 || this.isPremiumUser) ?
                                        <iframe style = {{height : '100%',width : '100%'}} class="embed-responsive-item" src={item.link} allowfullscreen></iframe>:
                                        <>
                                        <img src = {item.thumbnail != null ? item.thumbnail : '/images/no_video_thmbnail.svg'} alt = "blog-1_img"/>
                                        <div class="mt-2">
                                            <a style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickWatch(item.is_premium,item.link,item.title,item.dateString)} class = "btn d-block mx-auto rounded-0" >
                                                {item.is_premium == 0 ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> : 
                                                this.isPremiumUser ? <><i class="fas fa-play mr-2"></i> <Trans>watchNow</Trans> </> 
                                                :<> <i class="fas fa-lock mr-2"></i><Trans>premiumVideo</Trans></>}
                                            </a>
                                        </div>
                                        </> }
                                    </div>
                                </div>
                                )
                            })}
                            
                        </div>

                        <div class="row py-2 justify-content-center">
                            <a style = {{cursor : 'pointer'}} onClick = {()=>this.props.onNavigateToPage('/videos')} class = "font-weight-bold text-uppercase text-dark text-underline see_btn">
                            <Trans>seeAll</Trans></a>
                        </div>

                    </div>
                </section>:null}
                <div class="modal" id="video-modal" data-keyboard="false" data-backdrop="static">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content bio-modal rounded-0 video-modal">
                        
                            <div class = "modal-header px-3">
                                <h3>{this.state.selectedVideoTitle}</h3>
                                <button data-dismiss="modal" onClick = {()=> {
                                        this.setState({selectedVideoUrl : ''})
                                        // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
                                        document.body.style.overflowY = "scroll"
                                    }}>
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="modal-body p-2">
                        
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src={this.state.selectedVideoUrl} allowfullscreen></iframe>
                            </div>
                            
                            </div>

                        </div>
                    </div>
                </div>
                </>
			);
		}else{
			return (
               null
            )
		}

    }
}
const mapStateToProps = state => ({
    videos: state.videos.homeVideosData
})
const mapDispatchToProps = dispatch => ({
    homeVideosLoaded : data => dispatch(HomeVideosLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(HomeVideos);