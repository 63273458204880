import React from 'react';
import './styles.css';

const Empty = (props)=>{
    return(
        <section class = "blank-page py-3" style={{width:'100%'}}>
	<div class="container">
		
		<div class = "row">
			<div class = "blank-section text-center py-5">
				<div class = "icon-div">
					{props.icon}
					{/* <img src = {props.icon} 
						alt = "icon-card"/> */}
						{/* <svg xmlns="http://www.w3.org/2000/svg" width="101.102" height="76.642" viewBox="0 0 101.102 76.642"><g transform="translate(0 -61.936)"><path d="M97.862,61.936H16.328a9.747,9.747,0,0,0-6.523,2.491L8.154,71.72v47.409a9.784,9.784,0,0,0,9.784,9.784H97.862l7.293-1.75a9.748,9.748,0,0,0,2.491-6.523V71.72a9.784,9.784,0,0,0-9.784-9.784Z" transform="translate(-6.544 0)" fill="#0e9ac8"/><path d="M154.435,436.809H92.47a1.631,1.631,0,1,1,0-3.261h61.966a1.631,1.631,0,1,1,0,3.261Z" transform="translate(-72.902 -298.232)" fill="#0e9ac8"/><path d="M225.736,168.086l-25.321-12.66a3.32,3.32,0,0,0-.689-.256l-2.614,3.173v25.321l3.3,1.339,25.321-12.66,1.05-3.434A3.273,3.273,0,0,0,225.736,168.086Z" transform="translate(-158.19 -74.823)" fill="#fff"/><path d="M9.784,137.286a6.523,6.523,0,0,1-6.523-6.523V74.55A9.782,9.782,0,0,0,0,81.843v48.92a9.784,9.784,0,0,0,9.784,9.784H91.318a9.781,9.781,0,0,0,7.293-3.261Z" transform="translate(0 -10.123)" fill="#1f6ccc"/><path d="M201.046,178.521a4.892,4.892,0,0,1-7.08-4.376v-19.36a3.265,3.265,0,0,0-4.031,3.173v25.321a3.261,3.261,0,0,0,4.72,2.917l25.321-12.66a3.27,3.27,0,0,0,1.05-5.012Z" transform="translate(-152.43 -74.44)" fill="#d9e4ef"/></g></svg> */}
				</div>
				<p class = "mt-3">
					{props.msg} 
				</p>
			</div>
		</div>
		
	</div>
</section>
        )
}

export default Empty