import axios from '../../axios/index'
import { Constants } from '../../../Constants';

export default {
    saveUserChat(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/saveChat`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.messageId,response.data.id)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback({
                        message : response.data.message
                    },null)
                }
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong ! Please try again'
            },null)
        })
    },
    updateChat(eventAlias,token,data,callback){
        axios.post(`${eventAlias}/updateChat`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,'')
            }
            else{
                if(response.data.errorCode == 440){
                    callback(response.data,null)
                }else{
                    callback({
                        message : response.data.message
                    },null)
                }
            }
        }).catch(error => {
            callback({
                message : 'Something went wrong ! Please try again'
            },null)
        })
    },
    async getUserChat(eventAlias,token,receiverId,callback){
        await axios.get(`${eventAlias}/getUserChat?receiverId=${receiverId}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN,
                'Authorization' : 'Bearer '+token
            }
        }).then(response => {
            if(response.data.status == "success"){
                callback(null,response.data.chat)
            }
            else{
                if(response.data.errorCode == 440){
                    callback(-1,null)
                }else{
                    callback({
                        message : response.data.message
                    },null)
                }
            }
        })
    }
}