import React from 'react' 
import Loader from 'react-loader-spinner'
import ReactHtmlParser from 'react-html-parser';
import { monthNamesShort } from '../../../Utility/utility';
import {Trans} from 'react-i18next'
export const MyFavorites = (props)=>{
    return(
        <>
        <div class="card-header"><Trans>myFavourites</Trans></div>
            <div class="card-body rounded-0" style = {{minHeight : 100,display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                {props.loading ?
                <div>
                    <Loader
                        type = 'Oval'
                        color = "#00b6ff"
                        height = {30}
                        width = {30}
                    />
                </div> : 
                props.favorites.length == 0 ?
                <div >
                    <Trans>noFavourite</Trans>
                </div>:
                <div class="row w-100">
                    {props.favorites.map((item,index)=>{
                        let starClass = item.is_favourite == 1 ? "fas" : 'far'
                        let startTime = item.session_details == null ? item.parallel_session_details.start_time : item.session_details.start_time
                        let endTime = item.session_details == null ? item.parallel_session_details.session_endtime : item.session_details.session_endtime
                        let title = item.parallel_session_details == null ? item.session_details.session_title : item.parallel_session_details.session_title
                        let desicription = item.parallel_session_details == null ? item.session_details.session_description : item.parallel_session_details.session_description
                        let agendaDateSplit = item.agenda_details.date.split('-')
                        let speakers = item.parallel_session_details == null ? item.session_details.speakers : item.parallel_session_details.speakers
                        let agendaDate = '('+agendaDateSplit[0] + ' '+monthNamesShort[agendaDateSplit[1] - 1] + ' ' + agendaDateSplit[2] +')'
                        speakers = speakers == null ? [] : speakers
                        return(
                            <div class="col-12 mb-3">
                                <div class="favourite-listing border rounded p-3">
                                    <h6 class = "f-600">
                                        {(startTime != null ? startTime : '--') + " - "+(endTime != null ? endTime : '--')}
                                        <span>{" "+agendaDate}</span>
                                    </h6>
                                    {/* <p class = "mb-1" style = {{fontWeight : 700}}>
                                        {title}
                                    </p> */}
                                    <p class = "mb-2">
                                        {title}
                                    </p>
                                    <i onClick = {()=>props.onUpdateFavouriteItem(index)} id = "bookmark-icon" class={"ic "+starClass+" fa-star"}></i>
                                    <ul class="list-inline s_list mb-0">
                                        {speakers.map((speaker,index)=>{
                                            return(
                                                <li onClick = {()=> props.onClickSpeaker(speaker)} class="list-inline-item spk_info-div">
                                                    <img src={speaker.image != null ? speaker.image : '/images/profile-placeholder.png'} alt="speakers"/>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>}
            </div>
        </>
    )
}