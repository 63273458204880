import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import utility from '../../../Utility/utility';
import Loader from 'react-loader-spinner'
import {Trans} from 'react-i18next'
export class EmailLogin extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            eEmail : ''
        };
    }
    OnClickLogin = () => {
        var email = document.getElementById('email').value
        this.setState({eEmail : ''})
        if(!utility.validateEmail(email)){
            this.setState({eEmail : <Trans>validEmail</Trans>})
            return
        }
        this.props.loginUser(email)
    }
    render(){
        return(
            <section class="login py-3 py-md-5 bg-light">
            <div class="content">
                <h3 class="font-weight-bold text-main text-center">Login</h3>
                <div class="container">
                    <div class="row py-3 justify-content-center">
                        <div class="col-md-6">
                            <form class="p-3 py-md-4 px-md-5 border shadow-sm" action="">
                            <div class="form-group">
                                <label for="pwd">Enter your email to login</label>
                                <input disabled = {this.props.loggingUser} type="email" onKeyDown ={(e)=>{
                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    let keyCode = e.which || e.keyCode
                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                        e.preventDefault();
                                        return false
                                    }
                                    else{
                                        return true
                                    }

                                }} id = "email" class= {this.state.eEmail == ""?"form-control":"form-control text-red-border"} placeholder="Enter Your Email" />
                                {this.state.eEmail != '' ? <div class="invalid-feedback">{this.state.eEmail}</div> : null}
                            </div>
                            <div style = {{display : 'flex',flexDirection : 'row'}}>  
                                <div class="form-group">
                                    <a style = {{pointerEvents : this.props.loggingUser ? 'none' : 'auto'}} onClick = {this.OnClickLogin} class="btn bg_main text-light px-5 rounded-0"><Trans>send</Trans></a>
                                </div>
                                {this.props.loggingUser ?
                                <div style = {{marginTop : 6,marginLeft : 12}}>
                                    <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {30}
                                        width = {30}
                                    />
                                </div> : null}
                            </div>
                            {this.props.loginError != '' ?
                                <div className="alert alert-danger contactUsAlert">{this.props.loginError}</div>:null}
                            </form>
                        </div>
                    </div>
                    
                    <div class="row py-3">
                        <div class="col-12 text-center">
                            <p><Trans>backTo</Trans> <a onClick = {this.props.onLoginClick} style = {{cursor : 'pointer'}} class="text-danger font-weight-bold"><Trans>login</Trans></a></p>
                        </div>
                    </div>
                    
                </div>
            </div>
            </section>
        )
    }//end of render

}

export default EmailLogin;