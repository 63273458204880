import React from 'react';
import Header from '../Header/Index';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';

import { withRouter } from 'react-router-dom'
import StepSelection from './stepSelection';
import api from './api';

import FirstStep from './steps/firstStep';
import SecondStep from './steps/secondStep';
import ThirdStep from './steps/thirdStep';
import FourthStep from './steps/fourthStep';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import ReactHtmlParser,{ processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import PaymentSuccessInvoice from './PaymentSuccessInvoice';
import { BookingDataLoaded,UpdateDelegatesInfo,UpdateOrderId } from './actions';
import { connect } from 'react-redux'
import {compose} from 'redux'
import MetaTags from 'react-meta-tags'
import { Constants } from '../../Constants';
import Dialog from 'react-bootstrap-dialog'
import VirtualZone from '../Header/virtualZone';
import newsLetterApi from '../NewsLetter/api'
import { GAPageView, GARecordEvent } from '../../Utility/GA';
import { Trans, withTranslation } from 'react-i18next';
class Booking extends React.Component{
    orderId = 0
    order = {
        packages : [],
        subTotal : 0,
        vat : 0,
        grandTotal : 0,
        totalDelegates : 0
    }
    guestEmail = ""
    vipDiscountPercent = 0
    discountName = ""
    constructor(props){
        super(props);
        this.state = {
            selectedIndex : this.props.headerMenuItems ? this.props.headerMenuItems.findIndex(x=>x.href.indexOf('/booking') > -1) || -1 : -1,
            selectedSubIndex: -1,
            event: {},
            selectedStep : 1,
            tickets : null,
            selectedCurrency : {},
            savingDelegate : false,
            delegateSaveResponse : null,
            selectedPaymentInfo : null,
            savingPaymentInfo : false,
            eSavingPayment : null,
            loadingError : '',
            menu : this.props.headerMenuItems ? this.props.headerMenuItems.find(x=>x.href == (this.props.event.alias+"/booking")) : {},
            bookingSuccessFul : false,
            submitPaymentButtonDisabled : false,
            showLoginPopup : false,
            selectedPackageIndex : props.packageIndex ? props.packageIndex : -1,
            showComingSoonMsg : false,
            vipCode : '',
            bookingError : '',
            subscriberEmail : '',
            eNewsLetterEmail : '',
            submittingNewsLetter : false,
            submitNewsLetterResponse : {},
            delegateInfoSave:false,
            invoiceSave:false,
            selectedtermandcondition:false,
            selectedEmailCheckbox:false,
            email:false,
            phone:false,
            setYes:false,
            setNo:false,
            paymentMethod:""


        }
        this.translation = this.props.i18n.getResourceBundle(localStorage.getItem("language"+localStorage.getItem("eventAlias"))|| 'en')
        this.user = utility.getLoggedInUser(this.props.event.alias)   
    }
    OnNavigateToPage = (path,sendData = false,lastStep = false) =>{
        window.scrollTo(0,0)
        if(path.indexOf('booking') > -1){
            if(this.state.bookingSuccessFul){
                this.setState({bookingSuccessFul : false,selectedStep : 1})
                return
            }
        }
        if(path.indexOf('home') > -1){
			path = "/"
        }
        else if(path != "/"){
            path = "/"+path
        }
        
        if(sendData){
            this.props.history.push({
                pathname : path,
                state : {tickets : this.state.tickets,selectedStep : this.state.selectedStep,order : this.order,bookingSaved : this.state.bookingSuccessFul}
            })
        }
        else{
            if(lastStep){
                this.props.history.push({
                    pathname : path,
                    state : {invoiceEmail : this.state.selectedPaymentInfo.email}
                })
            }
            else{
                this.props.history.push(path)
            }
        }
    }
    selectPaymentMethod = (method)=>{
       this.setState({paymentMethod:method})
    }
    componentWillUnmount(){
        if(this.unListen != null){
            setTimeout(() => {
                this.unListen()
            }, 0);
        }
        // document.getElementsByTagName('html')[0].style.overflowY = "scroll";
        document.body.style.overflowY = "scroll"
    }
    selecTermandCondition = ()=>{
        this.setState({selectedtermandcondition:!this.state.selectedtermandcondition})
    }
    selectEmail = ()=>{
        this.setState({email:!this.state.email})
    }
    selectPhone = ()=>{
        this.setState({phone:!this.state.phone})
    }
    onConsetsYes = ()=>{
        this.setState({setYes:!this.state.setYes,setNo:false})
    }
    onConsetsNo = ()=>{
        this.setState({setNo:!this.state.setNo,setYes:false})
    }

    // componentWillReceiveProps(nextProps){
    //     if(nextProps.booking != undefined){
    //         if(nextProps.booking.booking_page_details.event_currency.length != this.state.selectedCurrency){
    //            console.log(nextProps.booking.booking_page_details.event_currency)
    //         }
    //     }
        
    // }

    componentDidMount(){
        console.log(this.props.booking)
        var selectedCurrency = {}
        if(this.props.booking != undefined){
            for(var i=0;i<this.props.booking.booking_page_details.event_currency.length;i++){
                var currency = this.props.booking.booking_page_details.event_currency[i]
                if(currency.code == "USD"){
                    selectedCurrency = currency
                    this.setState({selectedCurrency})
                    break
                }
                else{
                    if(i == this.props.booking.booking_page_details.event_currency.length - 1){
                        selectedCurrency = this.props.booking.booking_page_details.event_currency[0]
                        this.setState({selectedCurrency})
                    }
                }
            }
        }else{
            var defaultCurrency = {
                id : 12,
                country: "United States of America",
                currency: "Dollars",
                code: "USD",
                symbol: "$"
            }
            selectedCurrency = defaultCurrency
            this.setState({selectedCurrency})
        }
        if(this.props.event.is_marketplace == "1"){
            this.setState({selectedStep: 2,tickets: this.props.data.tickets},()=>{
                this.SetTicketPricing(this.props.data,selectedCurrency)
                this.onFirstStepCompleted(this.props.data)
            })
            this.loggedInUser = utility.getLoggedInUser(this.props.event.alias)
            return
        }

        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        $(document).on('submit', '#newsletter-form', function(event){
            event.preventDefault();
        });
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        this.getEventExpiry()
        this.orderId = this.props.orderId
        fetch('/setCurrentPage?page=booking')
        fetch(`/${this.props.event.alias}/booking`)
        this.setState({submitPaymentButtonDisabled : false})
        if(this.props.booking!= null){
            if(this.props.booking.tickets.length == 0){
                this.setState({tickets : this.props.booking.tickets,showComingSoonMsg : !this.eventExpired})
            }
        }
        this.loggedInUser = utility.getLoggedInUser(this.props.event.alias)
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],'Booking')
                if(result != null){
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/booking') > -1)
                    this.setState({selectedIndex : i,selectedSubIndex})
                    break
                }
            }
        }
        // prev currency setting here
        this.unListen = this.props.history.listen((location,action)=>{
            if(action == 'POP'){
                $("#delegate_modal").modal("hide")
                $('body').removeClass('modal-open')
                $('.modal-backdrop').remove()
                if(this.state.selectedStep == 1){
                    return
                }
                else{
                    this.OnNavigateToPage(this.props.event.alias+'/booking',true)
                }
            }
        })
        var data = this.props.location.state
        if(data == null){
            if(this.props.booking == null){
                this.getEventBooking()
            }else{
                this.bookingData = this.props.booking
                this.SetTicketPricing(this.props.booking,selectedCurrency)
            }
        }
        else{
            this.order = data.order
            var selectedStep = 1
            if(!data.bookingSaved){
                selectedStep = data.selectedStep - 1
                this.setState({tickets : data.tickets,selectedStep : selectedStep})
            }
            else{
                this.OnNavigateToPage(this.props.event.alias+'/booking')
            }
        }
        window.history.replaceState(null, '')
    }
    getEventBooking = () => {
        api.getEventBooking(this.props.event.alias,(err,data)=>{
            if(data.tickets.length == 0){
                this.setState({showComingSoonMsg : !this.eventExpired})
            }
            this.bookingData = data
            if(err == null){
                this.SetTicketPricing(data,this.state.selectedCurrency)
                return
            }
            if(this.props.booking == null){
                this.setState({loadingError : 'Something went wrong'})
            }
        })
    }
    SetTicketPricing = (data,currency)=>{
        if(this.eventExpired && data.tickets.length == 0){
            // document.getElementsByTagName('html')[0].style.overflowY = "hidden";
            document.body.style.overflowY = "hidden"
        }
        for(var i=0;i<data.tickets.length;i++){
            if(data.tickets[i].price.length == 0){
                for(var j=0;j<this.props.event.event_currency.length;j++){
                    var priceItem = {
                        price : "0",
                        currency : this.props.event.event_currency[j].symbol
                    }
                    data.tickets[i].price.push(priceItem)
                }
            }
            var date = new Date()
            var lastDateString = ""
            if(data.tickets[i].ticket_conditions.length == 0){
                lastDateString = "Book after "+utility.getDateString(this.props.event.start_date)
            }else{
                lastDateString = "Book after "+utility.getDateString(data.tickets[i].ticket_conditions[data.tickets[i].ticket_conditions.length-1].date)
            }
            var item = data.tickets[i].ticket_conditions.find(x=>x.isDefault)
            if(item == null){
                data.tickets[i].ticket_conditions.push({
                    title : '',
                    discount_percentage : 0,
                    date : date.setDate(date.getDate() + 1),
                    dateString : lastDateString, 
                    isActive : 1,
                    isDefault : true
                })
            }
            if(data.tickets[i].price.find(x=>x.currency == currency.symbol) == null){
                data.tickets[i].show = false
            }
            else{
                data.tickets[i].show = true 
            }
            for(var k=0;k<data.tickets[i].price.length;k++){
                var expiredConditions = 0
                var actualPrice = data.tickets[i].price[k].price
                var discountedPrices = [data.tickets[i].price[k].price]
                data.tickets[i].price[k].prices = []
                
                for(var j=0;j<data.tickets[i].ticket_conditions.length;j++){
                    data.tickets[i].ticket_conditions[j].show = true
                    var ticketCondition = data.tickets[i].ticket_conditions[j]
                    ticketCondition.dateObj =  new Date(ticketCondition.date)
                    if(new Date().setHours(0,0,0,0) <= ticketCondition.dateObj){
                        data.tickets[i].price[k].actualPrice = actualPrice
                        var p = Math.round(actualPrice - (actualPrice * (ticketCondition.discount_percentage/100)))
                        data.tickets[i].price[k].prices.push(p)
                    }
                    else{
                        expiredConditions += 1
                    }
                }
                // data.tickets[i].ticket_conditions.sort((a,b)=>{
                //     return new Date(a.dateObj) - new Date(b.dateObj)
                // })
                if(expiredConditions == data.tickets[i].ticket_conditions.length - 1){
                    data.tickets[i].ticket_conditions = []
                }
                
            }
            
            for(var l=0;l<data.tickets[i].price.length;l++){
                var expiredConditions = 0
                if(data.tickets[i].price[l].currency == currency.symbol){
                    if(new Set(data.tickets[i].price[l].prices).size !== data.tickets[i].price[l].prices.length){
                        data.tickets[i].ticket_conditions.splice(data.tickets[i].ticket_conditions.length-1,1)
                    }
                }
                data.tickets[i].price[l].prices = data.tickets[i].price[l].prices.filter((item,index)=>data.tickets[i].price[l].prices.indexOf(item)===index)
                for(var j=0;j<data.tickets[i].ticket_conditions.length;j++)
                {
                    var ticketCondition = data.tickets[i].ticket_conditions[j]
                    if(new Date().setHours(0,0,0,0) >= new Date(ticketCondition.date)){
                        expiredConditions += 1
                    }
                    if(expiredConditions == data.tickets[i].ticket_conditions.length - 1){
                        data.tickets[i].ticket_conditions = []
                    }
                }
            }
        }
        for(var i=0;i<data.tickets.length;i++){
            var ticketsArray = []
            for(var j=0;j<data.tickets[i].ticket_conditions.length;j++){
                if(new Date().setHours(0,0,0,0) <= new Date(data.tickets[i].ticket_conditions[j].date)){
                    ticketsArray.push(data.tickets[i].ticket_conditions[j])
                }
            }
            data.tickets[i].ticket_conditions = ticketsArray

            for(j=0;j<data.tickets[i].ticket_conditions.length ;j++){
                if(!data.tickets[i].ticket_conditions[j].isDefault){
                    var dateString = "Book by "+utility.getDateString(data.tickets[i].ticket_conditions[j].date)
                    var discountString = "Get "+data.tickets[i].ticket_conditions[j].discount_percentage+"% Off"
    
                    data.tickets[i].ticket_conditions[j].dateString = dateString
                    data.tickets[i].ticket_conditions[j].discountString = discountString
                }
            }
            if(data.tickets[i].ticket_conditions.length == 1){
                let index = data.tickets[i].ticket_conditions.length - 1
                data.tickets[i].ticket_conditions[index].dateString = data.tickets[i].ticket_conditions[index].dateString.replace("after","by")
            }
        }
        if(this.props.event.is_marketplace == "0"){
            this.props.bookingLoaded(data)
        }
        this.setState({tickets : data.tickets,loadingError : ''})
    }
    SaveOrder = () => {
        for(var i=0;i<this.order.packages.length;i++){
            // sanity checks if delegate info item is not there send it with null
            for(var j=0;j<this.order.packages[i].delegates.length;j++){
                var delegateItem = this.order.packages[i].delegates[j]
                if(delegateItem != undefined){
                delegateItem.id = delegateItem.id == null ? "" : delegateItem.id
                delegateItem.title = delegateItem.title == null ? "Mr" : delegateItem.title
                delegateItem.firstName = delegateItem.firstName == null ? "" : delegateItem.firstName
                delegateItem.lastName = delegateItem.lastName == null ? "" : delegateItem.lastName
                delegateItem.phoneNumber = delegateItem.phoneNumber == null ? "" : delegateItem.phoneNumber
                delegateItem.mobileNumber = delegateItem.mobileNumber == null ? "" : delegateItem.mobileNumber
                delegateItem.nationality = delegateItem.nationality == null ? "": delegateItem.nationality
                delegateItem.emailWork = delegateItem.emailWork == null ? "" : delegateItem.emailWork
                delegateItem.companyName = delegateItem.companyName == null ? "" : delegateItem.companyName
                delegateItem.address1 = delegateItem.address1 == null ? "" : delegateItem.address1
                delegateItem.city = delegateItem.city == null ? "" : delegateItem.city
                delegateItem.jobTitle = delegateItem.jobTitle == null ? "" : delegateItem.jobTitle
                delegateItem.state = delegateItem.state == null ? "" : delegateItem.state
                delegateItem.address2 = delegateItem.address2 == null ? "" : delegateItem.address2
                delegateItem.zipCode = delegateItem.zipCode == null ? "" : delegateItem.zipCode
                delegateItem.companyTelephone = delegateItem.companyTelephone == null ? "" : delegateItem.companyTelephone
                delegateItem.companyVatNo = delegateItem.companyVatNo == null ? "" : delegateItem.companyVatNo
                delegateItem.companyWebsite = delegateItem.companyWebsite == null ? "" : delegateItem.companyWebsite
                delegateItem.country = delegateItem.country == null ? "" : delegateItem.country

                this.order.packages[i].delegates[j] = delegateItem
                }
            }
        }
        api.saveOrder(this.props.event.alias,this.order,(err,data)=>{
            if(err == null){
                this.orderId = data.orderId
                this.order.orderId = this.orderId
                this.props.onUpdateOrderId(this.orderId)
            }
            if(this.updatingDelegateInfo){
                this.setState({savingDelegate : false},()=>{
                    var res = {
                        error : err != null ? true : false,
                        message : err == null ? 'Delegate Info Saved' : 'Something went wrong'
                    }
                    this.setState({delegateSaveResponse : res},()=>{
                        this.setState({delegateInfoSave:true})
                        // this.secondStepRef.OnDelegateInfoSaved()
                    })
                    
                })
                this.updatingDelegateInfo = false
            }
            
        })
    }
    SavePaymentInfo = (amount,onlyInvoiceTickets) => {
        this.state.selectedPaymentInfo.orderId = this.orderId
        this.state.selectedPaymentInfo.currency = this.state.selectedCurrency.code
        this.state.selectedPaymentInfo.amount = amount
        api.savePayment(this.props.event.alias,this.state.selectedPaymentInfo,(err,data)=>{
            this.setState({savingPaymentInfo : false})
            if(err == null){
                setTimeout(() => {
                    window.scrollTo(0,0)
                }, 0);
                if(onlyInvoiceTickets){
                    // this.fourthStepRef.OnInvoiceSubmitted()
                    this.setState({invoiceSave:true})
                }else{
                    this.orderId = 0
                    this.setState({bookingSuccessFul : true,eSavingPayment : ''})
                    this.props.onUpdateDelegatesInfo([])
                    this.props.onUpdateOrderId(0)
                }
            }
            else{
                this.setState({eSavingPayment : 'Something went wrong'})
            }
        })
    }
    GetPaymentRedirection = (data) => {
        if(this.loggedInUser){
            data.email = this.loggedInUser.email
        }else{
            data.email = this.guestEmail
        }
        api.paymentRedirection(this.props.event.alias,data,(err,data)=>{
            this.setState({savingPaymentInfo : false})
            if(err == null){
                this.setState({submitPaymentButtonDisabled : true,eSavingPayment : ''})
                window.open(data.url,"_self")
            }
            else{
                this.setState({eSavingPayment : err.message})
            }
        })
    }
    ConfirmFreeBooking = () => {
        this.setState({eSavingPayment : null})
        var data = {
            orderId : this.orderId,
            userId : this.loggedInUser != null ? this.loggedInUser.id : 0,
            userEmail : this.loggedInUser != null ? this.loggedInUser.email : this.guestEmail
        }
        api.confirmFreeBooking(this.props.event.alias,data,(err,data)=>{
            localStorage.removeItem("isBookingPageShown")
            if(err == null){
                this.props.history.push({
                    pathname : "/"+this.props.event.alias+'/booking_success',
                    state : {isFreeBooking : true}
                })
            }else{
                // this.OnGoBack()
                // this.setState({eSavingPayment : err.message})
                this.props.history.push({
                    pathname : "/"+this.props.event.alias+'/booking_failure',
                    state : {isFreeBooking : true}
                })
            }
        })
    }
    OnUpdateDelegateInfo = (packages) =>{
        this.order.packages = packages
        var delegates = []
        for(var i=0;i<packages.length;i++){
            var item = {
                index : i,
                delegates : []
            }
            for(var j=0;j<packages[i].delegates.length;j++){
                item.delegates.push(packages[i].delegates[j])
            }
            delegates.push(item)
        }
        this.props.onUpdateDelegatesInfo(delegates)
    }
    getEventExpiry = () => {
        this.eventExpired = false
        let timeZoneSplit = this.props.event.timezone.split(":")
        let timeZoneHour = parseInt(timeZoneSplit[0])
        this.timeZoneOffset = parseFloat(timeZoneHour + "." + timeZoneSplit[1])
        
        let currentDate = utility.calculateZoneSpecificTime('',this.timeZoneOffset)
        let currentTimeInMinutes = (currentDate.getHours() * 60) + currentDate.getMinutes()
        let eventEndDate = new Date(this.props.event.end_date).setHours(0,0,0,0)
        let eventEndTimeSplit = this.props.event.end_time.split(":")
        let hour = parseInt(eventEndTimeSplit[0])
        if(this.props.event.end_time.indexOf('pm') > -1 || this.props.event.end_time.indexOf('PM') > -1){
            hour += 12
        }
        let minutes = parseInt(eventEndTimeSplit[1].slice(0,eventEndTimeSplit[1].length - 2))
        let eventEndTimeInMinutes = (hour * 60) + minutes
        if(eventEndDate < currentDate.setHours(0,0,0,0)){
            this.eventExpired = true
        }else if(eventEndDate == currentDate.setHours(0,0,0,0)){
            if(eventEndTimeInMinutes < currentTimeInMinutes){
                this.eventExpired = true
            }
        }
    }
    continueButtonEvent = () => {
        //console.log("continue clicked")
        GARecordEvent("Continue button Clicked", this.user? this.user.id: "not a user",{
            category : 'Continue Booking',
            action : 'Continue button clicked',
            userName: this.user? (this.user.firstName+''+this.user.lastName) : "not available",
            userEmail: this.user? this.user.email : "not available"
        })
    }

    // steps completion
    onFirstStepCompleted = (data) => {
        var tickets = data.tickets
        this.vipCode = data.vipCode
        if(this.guestEmail == ""){
            this.guestEmail = data.guestEmail
        }
        var currentDate = new Date()
        currentDate.setHours(0,0,0,0)
        var packages = []
        var subTotal = 0
        var totalDelegates = 0
        var selectedTicketId = ""
        this.multiDiscountPercent = ""
        var alreadyEnteredDelegates = []
        if(this.props.delegatesData != null){
            alreadyEnteredDelegates = this.props.delegatesData
        }
        for(var i=0;i<tickets.length;i++){
            // if(tickets[i].selectedDelegates > 0){
                var discountPercent = 0
                var ticketItem = tickets[i]
                var showVipDiscount = true
                var showMultiDelegateDiscount = true
                var indexOfSelectedCurrency = ticketItem.price.findIndex(x=>x.currency == this.state.selectedCurrency.symbol)
                
                if(indexOfSelectedCurrency < 0){
                    continue
                }
                for(var j=0;j<tickets[i].ticket_conditions.length;j++){
                    if(currentDate <= new Date(tickets[i].ticket_conditions[j].date)){
                        discountPercent = tickets[i].ticket_conditions[j].discount_percentage
                        selectedTicketId = tickets[i].ticket_conditions[j].id
                        ticketItem.price[indexOfSelectedCurrency].price = ticketItem.price[indexOfSelectedCurrency].prices[j]
                        break
                    }
                }
                var basePrice = ticketItem.price[indexOfSelectedCurrency].price * ticketItem.selectedDelegates
                var multiDelegatesDiscount = this.props.booking && this.props.booking.booking_page_details.multiple_booking_discount != null ? basePrice * (this.props.booking.booking_page_details.multiple_booking_discount / 100) : 0
                this.multiDiscountPercent = this.props.booking && this.props.booking.booking_page_details.multiple_booking_discount
                if(this.props.booking && this.props.booking.booking_page_details.multiple_booking_number != null){
                    if(ticketItem.selectedDelegates < this.props.booking.booking_page_details.multiple_booking_number){
                        multiDelegatesDiscount = 0
                    }
                }
                if(tickets[i].selectedDelegates > 0){
                    if(multiDelegatesDiscount > 0){
                        showMultiDelegateDiscount = true
                    }else{
                        showMultiDelegateDiscount = false
                    }
                }
                var delegates = []
                // if(alreadyEnteredDelegates.length > 0){
                //     var savedDelegateItem = alreadyEnteredDelegates.find(x=>x.index == i)
                //     for(var k=0;k<ticketItem.selectedDelegates;k++){
                //         if(savedDelegateItem.delegates[k] != null){
                //             delegates.push(savedDelegateItem.delegates[k])
                //         }else{
                //             delegates.push({})
                //         }
                //     }
                // }
                if(this.order.packages.length > 0){
                    let index = this.order.packages.findIndex(x=>x.title == tickets[i].title)
                    if(index > -1){
                        delegates = this.order.packages[index].delegates
                        let delegatesNew = []
                        for(var k=0;k<tickets[i].selectedDelegates;k++){
                            if(delegates[k] != null){
                                delegatesNew.push(delegates[k])
                            }
                        }
                        delegates = delegatesNew
                    }
                }else{
                    var delegateItem = null
                    if(this.loggedInUser != null){
                        delegateItem = {
                            firstName : this.loggedInUser.firstName,
                            lastName : this.loggedInUser.lastName,
                            emailWork : this.loggedInUser.email,
                            mobileNumber : this.loggedInUser.phone,
                            city : this.loggedInUser.city,
                            country : this.loggedInUser.country,
                            companyName : this.loggedInUser.companyName,
                            jobTitle : this.loggedInUser.jobTitle
                        }
                    }else{
                        delegateItem = {
                            emailWork : this.guestEmail
                        }
                    }
                    delegates.push(delegateItem)
                    // for(var k=1;k<tickets[i].selectedDelegates;k++){
                    //     delegates.push({})
                    // }
                }
                
                // for(var k=0;k<delegates.length;k++){
                //     if(delegates[k] == null){
                //         delegates[k] = {}
                //     }
                // }
                multiDelegatesDiscount = Math.round((multiDelegatesDiscount)*100)/100
                
                var vipDiscount = 0
                this.vipDiscountPercent = 0
                var discountCode = this.props.booking && this.props.booking.booking_page_details.discounts.find(x => x.discountCode == this.vipCode.trim())
                var vipDiscountPercent = 0
                if(discountCode != null){
                    this.discountName = discountCode.discountCode
                    vipDiscountPercent = discountCode.discount
                    this.discountName = this.discountName.charAt(0).toLocaleUpperCase()+this.discountName.slice(1)
                    if(!this.discountName.includes('discount')){
                        this.discountName = this.discountName + " Discount"
                    }
                    this.vipDiscountPercent = discountCode.discount
                    vipDiscount = basePrice * (discountCode.discount/100)
                    vipDiscount = Math.round((vipDiscount) * 100) / 100
                    showMultiDelegateDiscount = false
                    showVipDiscount = true
                }else{
                    showVipDiscount = false
                }
                var delegatesInfo = []
                // using this for mapping in dom
                for(var k=1;k<=ticketItem.selectedDelegates;k++){
                    delegatesInfo.push(k)
                }
                var newSubTotal = 0
                if(showVipDiscount){
                    newSubTotal = Math.round((basePrice - vipDiscount)*100) / 100
                }else if(showMultiDelegateDiscount){
                    newSubTotal = Math.round((basePrice - multiDelegatesDiscount)*100) / 100
                }else{
                    newSubTotal = basePrice
                }
                var packageItem = {
                    isInvoice : ticketItem.is_invoice == 1 ? true : false,
                    packageId : ticketItem.id,
                    conditionId : selectedTicketId,
                    title : ticketItem.title,
                    price : ticketItem.price[indexOfSelectedCurrency].price,
                    delegates : delegates,
                    numberOfDelegates : tickets[i].selectedDelegates,
                    delegatesInfo : delegatesInfo,
                    basePrice : basePrice,
                    multiDelegateDiscount : multiDelegatesDiscount,
                    discountType : showVipDiscount ? "vipDiscount" : "multiDelegates",
                    discount : showVipDiscount ? vipDiscountPercent : this.props.booking && this.props.booking.booking_page_details.multiple_booking_discount,
                    vipDiscount : vipDiscount,
                    subTotal : newSubTotal,
                    showMultiDelegateDiscount : showMultiDelegateDiscount,
                    showVipDiscount : showVipDiscount
                }
                packageItem.isActive = packageItem.numberOfDelegates > 0 ? 1 : 0
                subTotal +=  packageItem.subTotal
                
                totalDelegates += parseInt(ticketItem.selectedDelegates)
                packages.push(packageItem)
                
            // }
        }
        var vatPercent = this.props.booking && this.props.booking.booking_page_details.vat != null ?  this.props.booking && this.props.booking.booking_page_details.vat : 0
        this.order = {
            orderId : this.orderId,
            userId : this.loggedInUser == null ? 0 : this.loggedInUser.id,
            packages : packages,
            currency : this.state.selectedCurrency.symbol,
            delegateSelected : totalDelegates,
            newSubTotal : subTotal,
            vatDiscount : Math.round((subTotal * vatPercent/100)*100)/100,
            totalPrice : Math.round((subTotal + (subTotal * vatPercent/100))*100)/100,
            vatPercent : vatPercent,
            termsConditions : 0,
            isConsent : 0,
            email : 0,
            phone : 0,
            vip_code : this.vipCode,
            userEmail : this.loggedInUser == null ? this.guestEmail : this.loggedInUser.email,
            discountType : showVipDiscount ? "vipDiscount" : "multiDelegates",
            discount : showVipDiscount ? vipDiscountPercent : this.props.booking && this.props.booking.booking_page_details.multiple_booking_discount,
        }
        this.order.vatPercentage = vatPercent
        if(totalDelegates > 0){
            this.SaveOrder()
        }
        this.setState({selectedIndex: this.state.selectedIndex})
    }
    onSecondStepCompleted = (data) => {
        this.order = data.order
    }
    onThirdStepCompleted = (updatedOrder) => {
        this.setState({submitPaymentButtonDisabled : false})
        this.order = updatedOrder
        this.SaveOrder()
    }
    OnProceed = (data,paymentCompleted = false) => {
        var selectedStep = this.state.selectedStep
        if(selectedStep == 1){
            var totalDelegates = 0
            for(var i=0;i<data.tickets.length;i++){
                let index = data.tickets[i].price.findIndex(x=>x.currency == this.state.selectedCurrency.symbol)
                if(index > -1){
                    totalDelegates += parseInt(data.tickets[i].selectedDelegates)
                }
            }
            if(totalDelegates > 0){
                var guestEmail = this.guestEmail
                if(guestEmail == ""){
                    guestEmail = data.guestEmail
                }
                if(this.loggedInUser == null && !utility.validateEmail(guestEmail)){
                    $('#loginPopup').modal('show');
                    return
                }
                this.onFirstStepCompleted(data)
            }
            else{
                this.dialog.showAlert(<Trans>selectDelagates</Trans>)
            }
            
        }
        else if(selectedStep == 2){
            this.onSecondStepCompleted(data)
        }
        else if(selectedStep == 3){
            this.onThirdStepCompleted(data)
        }
        else {
            if(this.state.savingPaymentInfo){
                return
            }
            if(data.type == "invoice"){
                if(paymentCompleted){
                    this.setState({savingPaymentInfo : true})
                }
                this.setState({selectedPaymentInfo : data.selectedInfo},()=>{
                    if(paymentCompleted){
                        // save invoice
                        this.SavePaymentInfo(data.totalPrice,data.onlyInvoiceTickets)
                    }
                })
            }
            else {
                this.setState({savingPaymentInfo : true})
                var data = {
                    orderId : this.order.orderId,
                    amount : data.totalPrice,
                    currency : this.state.selectedCurrency.code,
                    paymentId : data.id,
                    redirect_url : window.location.origin
                }
                this.GetPaymentRedirection(data)
            }
        }
        if(this.state.selectedStep == 4){
            return
        }
        if(selectedStep == 1){
            if(totalDelegates > 0)
                selectedStep += 1
        }else{
            selectedStep += 1 
        }
        this.setState({selectedStep})
    }
    OnGoBack = (data = null) => {
        if(this.state.savingPaymentInfo){
            return
        }
        this.setState({selectedPackageIndex : -1})
        var selectedStep = this.state.selectedStep
        if(selectedStep == 4){
            this.setState({selectedPaymentInfo : data})
        }
        if(selectedStep == 2){
            if(this.props.event.is_marketplace == "1"){
                this.props.onHandleBack()
            }
        }
        if(selectedStep > 0){
            selectedStep -= 1
            this.setState({selectedStep})
        }
    }
    OnClickDelegateItem = (index) => {
        this.setState({savingDelegate : false},()=>{
            this.setState({delegateSaveResponse : null})
        })
    }
    OnUpdateOrder = (updatedData,check) => {
        if(this.state.selectedStep == 2){
            this.setState({savingDelegate : true},()=>{
                this.setState({delegateSaveResponse : null})
            })
        }
        this.updatingDelegateInfo = true
        this.order = updatedData
        if(check != false){
        this.SaveOrder()
        }
    }
    OnEnterDelegate = (index) => {
        this.setState({savingDelegate : false},()=>{
            this.setState({delegateSaveResponse : null})
        })
    }
    OnCurrencyUpdated = (currency) => {
        this.SetTicketPricing(this.bookingData,currency)
        this.setState({selectedCurrency : currency})
    }
    OnPressRetry = () => {
        this.setState({loadingError : '',bookingError : ''})
        this.getEventBooking()
    }
    languageClick = (value) =>{
        //console.log("Trigger booking on lan select")
        this.props.languageClick(value);
    }

    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnClickEditDelegate = (packageIndex) => {
        this.OnGoBack()
        this.setState({selectedPackageIndex : packageIndex})
    }
    SubscribeToNewsLetter = () => {
        this.setState({eNewsLetterEmail : ''})
        if(!utility.validateEmail(this.state.subscriberEmail)){
            this.setState({eNewsLetterEmail : 'Enter a valid email'})
            return
        }
        this.setState({submittingNewsLetter : true})
        newsLetterApi.sendEventSubscription(this.props.event.alias,this.state.subscriberEmail,'eventUpdate',(err,data)=>{
            this.setState({submittingNewsLetter : false})
            this.setState({subscriberEmail : ''})
            if(err == null){
                let submitNewsLetterResponse = {
                    message : data.message,
                    success : true
                }
                this.setState({submitNewsLetterResponse});
                setTimeout(() => {
                    this.OnNavigateToPage('/')
                }, 1000);
            }else{
                let submitNewsLetterResponse = {
                    message : data.message,
                    success : false
                }
                this.setState({submitNewsLetterResponse});
            }
        })
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.menu != null ? this.state.menu.title : "Booking"}</title>
                    <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                    <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                </Helmet>
                {this.props.event.is_marketplace == "0" ?
                <div id="header-top">
                <Header 
                    selectedIndex = {this.state.selectedIndex}
                    selectedSubIndex = {this.state.selectedSubIndex} 
                    onMenuItemClick = {this.OnNavigateToPage}  
                    elements = {this.props.headerMenuItems}
                    facebookLink = {this.props.event.facebook}
                    isMarketplace = {this.props.event.is_marketplace}
                    linkedInLink = {this.props.event.linkedIn}
                     event = {this.props.event}
                    twitterLink = {this.props.event.twitter}
                    instagramLink = {this.props.event.instagram}
                    googleLink = {this.props.event.google}
                    phone = {this.props.event.phone_number}
                    eventLogo = {this.props.event.logo}
                    eventName = {this.props.event.name}
                    eventAlias = {this.props.event.alias}
                    onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                    onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                    onClickAccountItem = {this.OnClickAccountItem}
                    availableLocales = {this.props.availableLocales}
                    languageClick  = {this.languageClick}
                />
                {user != null && user.isBooking && Constants.showVirtualZone?
                <VirtualZone
                    eventAlias = {this.props.event.alias}
                    event={this.props.event}
                    onMenuItemClick = {(path)=>{
                        if(path != ''){
                            this.OnNavigateToPage(path)
                        }
                    }}  
                    unReadCount = {this.props.unReadCount}
                    onNotificationSeen = {this.props.onNotificationSeen} 
                    onClearNotifications = {this.props.onClearNotifications}
                    notificationsCount = {this.props.unReadNotificationsCount}
                    notifications = {this.props.notifications}
                    onClickAccountItem = {this.OnClickAccountItem}
                    onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                    menuItems = {this.props.virtualMenuItems}
                    user = {user} 
                /> : null}
                </div> : null}
                <div className = {`content-main`}>
                    {this.state.tickets != null ?
                    this.state.showComingSoonMsg ?  
                        <div className = "text-center bg-light">
                            <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                        </div> :
                        this.state.bookingError != '' ?
                        <ErrorScreen
                            onPressRetry = {this.OnPressRetry}
                        /> :
                        <div className = "section-full-ticket position-relative">
                            {!this.state.bookingSuccessFul ?
                        <StepSelection
                            is_marketplace = {this.props.event.is_marketplace}
                            selectedStep = {this.state.selectedStep}
                            eventName = {this.props.event.name}
                            startDate = {
                                new Date(this.props.event.start_date).toLocaleDateString('en-GB', {
                                    day : 'numeric',
                                    month : 'long',
                                    year : 'numeric'
                                })
                            }
                            endDate = {
                                new Date(this.props.event.end_date).toLocaleDateString('en-GB', {
                                    day : 'numeric',
                                    month : 'long',
                                    year : 'numeric'
                                })
                            }
                            location = {this.props.event.venue_address}
                            eventExpired = {this.eventExpired && this.state.tickets.length == 0}
                        /> : null}
                        {this.state.selectedStep == 1 ? 
                        <FirstStep
                            eventVipCode = {this.props.booking && this.props.booking.booking_page_details.discounts}
                            bookingInfo = {this.props.booking && this.props.booking.booking_page_details.booking_page_info}
                            eventCurrencies = {this.props.event.event_currency}
                            selectedCurrency = {this.state.selectedCurrency} 
                            tickets = {this.state.tickets}
                            onProceed = {this.OnProceed}
                            onCurrencyUpdated = {this.OnCurrencyUpdated}
                            showLoginPopup = {this.state.showLoginPopup}
                            onLoginClick = {()=> {
                                $('#loginPopup').modal('hide');
                                this.OnNavigateToPage(this.props.event.alias+'/login')
                            }}
                            cancellationPolicy = {this.props.booking && this.props.booking.booking_page_details.cancellation_policy}
                            dataProtection = {this.props.booking && this.props.booking.booking_page_details.data_protection}
                            eventDateString = {utility.getDateString(this.props.event.start_date)}
                            showVipCodeField = {this.props.booking && this.props.booking.booking_page_details.discounts.length > 0}
                            vipCode  = {this.vipCode}
                            eventExpired = {this.eventExpired && this.state.tickets.length == 0}
                            translation ={this.translation}
                            continueButtonEvent ={this.continueButtonEvent}
                        /> : 
                        this.state.selectedStep == 2 ?
                        <SecondStep
                            ref = {(ref)=>this.secondStepRef = ref}
                            countries = {this.props.countries}
                            event = {this.props.event}
                            delegateInfoSave = {this.state.delegateInfoSave}
                            nationalities = {this.props.nationalities}
                            selectedCurrency = {this.state.selectedCurrency}
                            onSecondStep ={()=>{
                                this.setState({delegateInfoSave:false})
                            }}
                            order = {this.order}
                            onProceed = {this.OnProceed}
                            onClickGoBack = {this.OnGoBack}
                            onUpdateOrder = {this.OnUpdateOrder}
                            savingDelegate = {this.state.savingDelegate}
                            response = {this.state.delegateSaveResponse}
                            onClickDelegateItem = {this.OnClickDelegateItem}
                            onPressEnterDelegate = {this.OnEnterDelegate}
                            packageToEdit = {this.state.selectedPackageIndex}
                            vipDiscountPercent = {this.vipDiscountPercent}
                            discountName = {this.discountName}
                            multiDiscountPercent = {this.multiDiscountPercent}
                            onUpdatePackageInfo = {this.OnUpdateDelegateInfo}
                            translation ={this.translation}
                        /> : 
                        this.state.selectedStep == 3 ?
                        <ThirdStep
                            selectedCurrency = {this.state.selectedCurrency}
                            order = {this.order}
                            email={this.state.email}
            phone={this.state.phone}
            setYes={this.state.setYes}
            setNo={this.state.setNo}
            selectPhone = {this.selectPhone}
            selectEmail = {this.selectEmail}
            onConsetsNo = {this.onConsetsNo}
            onConsetsYes = {this.onConsetsYes}
                            selectedtermandcondition = {this.state.selectedtermandcondition}
                            selecTermandCondition = {this.selecTermandCondition}
                            onProceed = {this.OnProceed}
                            onClickGoBack = {this.OnGoBack}
                            onClickEdit = {this.OnClickEditDelegate}
                            cancellationPolicy = {this.props.booking && this.props.booking.booking_page_details.cancellation_policy}
                            dataProtection = {this.props.booking && this.props.booking.booking_page_details.data_protection} />: 
                        this.state.selectedStep == 4 ?
                        !this.state.bookingSuccessFul ?
                        <FourthStep
                            ref = {(ref)=>this.fourthStepRef = ref}
                            invoiceSave = {this.state.invoiceSave}
                            onInvoiceSave = {()=>{
                                this.setState({invoiceSave:false})
                            }}
                            selectPaymentMethod = {this.selectPaymentMethod}
                            paymentMethod = {this.state.paymentMethod}
                            order = {this.order}
                            userEmail = {this.loggedInUser == null ? this.guestEmail : this.loggedInUser.email}
                            countries = {this.props.countries}
                            selectedOption = {this.state.selectedPaymentInfo}
                            savingPayment = {this.state.savingPaymentInfo}
                            failedSaving = {this.state.eSavingPayment}
                            selectedCurrency = {this.state.selectedCurrency}
                            onProceed = {this.OnProceed}
                            paymentMethods = {this.props.event.payments}
                            submitPaymentButtonDisabled = {this.state.submitPaymentButtonDisabled}
                            onClickGoBack = {this.OnGoBack}
                            confirmBooking = {this.ConfirmFreeBooking}/> : 
                            <PaymentSuccessInvoice
                                userEmail = {this.state.selectedPaymentInfo.email}
                                eventEmail = {this.props.event.email}
                                eventPhone = {this.props.event.phone_number}
                            />
                            : null}
                            {this.eventExpired && this.state.tickets.length == 0 ?
                            <div class="ended-modal" id="event_ended">
                                <div class="ended-modal-center">
                                    <div class="modal-content text-center">
                                    <div class="modal-body model-form p-3 py-md-4 px-md-5">
                                        <p>
                                            <img src="/images/ic_sad.svg" alt="sad-face" />
                                        </p>
                                        <h5 class = "font-weight-bold text-main">
                                            <Trans>eventEnded</Trans>
                                        </h5>
                                        <p>
                                        <Trans>stillSubscribe</Trans>
                                        </p>
                                        <form id = "newsletter-form">
                                            <div class = "form-group">
                                                <input value = {this.state.subscriberEmail} onChange={(e)=>this.setState({subscriberEmail : e.target.value})} type="text" class={this.state.eNewsLetterEmail == "" ? "form-control rounded-0" : "form-control rounded-0 text-red-border"} placeholder={this.translation.enterYourEmail} id="email" />
                                            </div>
                                            <div class = "form-group position-relative">
                                                <button onClick = {this.SubscribeToNewsLetter} type="submit" class="form-control btn text-light rounded-0 f_btn"><Trans>subscribeNewsletter</Trans></button>
                                                {this.state.submittingNewsLetter ? <div style = {{position : 'absolute',top : 42,left : '50%'}}>
                                                    <Loader
                                                        type = 'Oval'
                                                        color = "#00b6ff"
                                                        height = {25}
                                                        width = {25}
                                                    />
                                                </div> : null}
                                                <div className = {this.state.submitNewsLetterResponse.success ? 'text-success' : 'text-red'} style = {{position : 'absolute',top : 57,transform: 'translate(-50%, -50%)',left : '50%'}}>{this.state.submitNewsLetterResponse.message}</div>
                                            </div>
                                        </form>
                                    </div>
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                        :
                        <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                            {this.state.loadingError == "" ?
                                <Loader
                                    type = 'Oval'
                                    color = "#00b6ff"
                                    height = {40}
                                    width = {40}
                                />:
                                <ErrorScreen 
                                    message = {this.state.loadingError}
                                    onPressRetry = {this.OnPressRetry}
                                />
                            }
                        </div>
                    }
                    {this.props.event.is_marketplace == "0" ?
                    <Footer1 
                        event = {this.props.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    /> : null}
                </div>
                {this.props.event.is_marketplace == "0" ?
                <Footer2 
                event = {this.props.event}
                    copyRightLogo = {this.props.event.copyright_logo}
                    copyRightText = {this.props.event.copyright_text}
                    copyRightLink = {this.props.event.copyright_link}
                    showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                /> : null}
                <Dialog ref={(component) => { this.dialog = component }} />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    booking: state.bookings.bookingData,
    delegatesData : state.bookings.delegates,
    orderId : state.bookings.orderId
})
const mapDispatchToProps = dispatch => ({
    bookingLoaded : data => dispatch(BookingDataLoaded(data)),
    onUpdateDelegatesInfo : delegates => dispatch(UpdateDelegatesInfo(delegates)),
    onUpdateOrderId : id => dispatch(UpdateOrderId(id))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Booking);