import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import { MDBCollapse } from "mdbreact";
import {isMobile} from 'react-device-detect'
import {Trans} from 'react-i18next'
export class Content extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            partners: this.props.partners,
            collapseID : ''
        };

    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    closePartnerbtn = collapseID => () => {
        this.setState(prevState => ({
          collapseID: ""
        }));
    }
    componentDidMount(){
        // $(".more_click").click(function () { 
        //     $(this).find('#more').text(function(i, v){
        //        return v === 'More' ? 'Less' : 'More'
        //     })
            
        // });
    }
    render(){
        const partners = this.props.partners;
        return(
            <section className = "sponsors_detail py-3 py-md-5 bg-light">

                <div className="container">
                    <div className="row py-0 py-md-3">
                        <div className="col-sm-6">
                            <h5 className = "font-weight-bold text-center text-md-left text-uppercase">
                                <Trans>sponsorsAndPartners</Trans></h5>
                        </div>
                        {/* <div className="col-sm-6 text-left text-center text-md-right mt-3 mt-lg-0">
                            <span onClick = {this.props.onClickOpportunities} className = "btn bg_primary">
                                <Trans>sponsorshipOpps</Trans> <i className="fas fa-angle-right ml-2"></i>
                            </span>
                        </div> */}
                    </div>
                </div>

                {
                    partners ?

                        partners.map( (partner,parentIndex) => {
                            var accordionDivID = "accordion-"+parentIndex;
                            var accordionId = "#accordion-"+parentIndex;
                            return(
                                <div className ="container py-1 py-md-3">

                                    <div className="col-12">
                                        <h5 className = "f-500 text-center text-md-left text-main my-3">{partner.name}</h5>
                                    </div>
                                    {partner.sponsers.length > 0 ?
                                            partner.sponsers.map( (sponser,iparentIndex)=> {
                                        return(
                                    <div id={accordionDivID}>
                                        <div className = "row">

                                    {
                                        partner.sponsers.length > 0 ?
                                            partner.sponsers[iparentIndex].map( (sponser,i)=> {

                                                var hrefId = "#sponsor-"+parentIndex+i;

                                                return(
                                                        <>
                                                        <div className="col-lg-3 col-md-6 mb-3 more_click collapsed" onClick = {this.toggleCollapse("collapsibleNavbar"+parentIndex+iparentIndex+i)} href = {hrefId} key={i}>
                                                            <div className="card text-center p-3 justify-content-center">
                                                                <img className="card-img-top" src={sponser.image} alt="spon_logo" />
                                                                <span className = "text-center" id = "more">{this.state.collapseID == "collapsibleNavbar"+parentIndex+iparentIndex+i ?  <Trans>less</Trans> : <Trans>more</Trans>}</span>
                                                            </div>
                                                        </div>
                                                        <MDBCollapse className = "col-12 mb-3 event-collapse" id={"collapsibleNavbar"+parentIndex+iparentIndex+i} isOpen={this.state.collapseID == "collapsibleNavbar"+parentIndex+iparentIndex+i}>
                                                            {/* <div className="col-12 mb-3 collapse" id = {hrefDivId} data-parent = {accordionId} key={i}> */}
                                                                <div className = "p-4 rounded sp_inner overflow-hidden" /*style = {{width : isMobile ? window.innerWidth : window.innerWidth * 0.8}}*/>
                                                                    <div>
                                                                    <h4 className = "f-600 mb-2 w-100">{sponser.name}</h4>
                                                                    <div class="angle-close" onClick = {this.toggleCollapse("collapsibleNavbar"+parentIndex+iparentIndex+i)}><div class="angle-close-ic bg_secondary partnerBtn"><i class="fas fa-times text-light font-weight-bold"></i></div></div>
                                                                    </div>

                                                                    <p className = "my-3">{ReactHtmlParser(sponser.description)}</p>

                                                                    <p className = "f-600 mb-0 text-center">

                                                                        {sponser.website != null ?
                                                                        <span className = "float-none float-lg-right mt-md-5 mt-1 mt-lg-3 mt-xl-0">
                                                                            <a href={sponser.website.indexOf('http://') == 0 || sponser.website.indexOf('https://') == 0 ? sponser.website : '//'+sponser.website} target = "_self" className = "btn">
                                                                                {this.props.jobFair ==  1 ? <><Trans>vCompanyProfile</Trans> <i className="fas fa-angle-right ml-2"></i></>: <><Trans>website</Trans> <i className="fas fa-angle-right ml-2"></i></>}
                                                                            </a>
                                                                        </span> : null}
                                                                    </p>
                                                                </div>
                                                            </MDBCollapse>
                                                            </>
                                                );

                                            })
                                            
                                            :null
                                    }
                                    
                                        </div>
                                    </div>
                                        )
                                    }): 
                                    null}
                                </div>
                            );
                        })

                    :null
                }


            </section>
        )

    }//end of render

}

export default Content;