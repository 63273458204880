let initialState = {
    userSelectedTimeZone:false
}
const agenda = (state = initialState, action) => {
    switch (action.type) {
        case 'AGENDA_DATA_LOADED' : 
            return{
            ...state,
                agendaData : action.payload
            }
        case 'HIGHLIGHTS_DATA_LOADED' :
            return{
                ...state,
                highlights : action.payload 
            }
        case 'AGENDA_TIME_DATA_LOADED' :
            return{
                ...state,
                agendaTime : action.payload 
            }
        case 'USER_SELECTED_TIMEZONE':
            return {
                ...state,
                userSelectedTimeZone:action.payload
            }
        default:
            return state
        }
  }
  export default agenda