import axios from '../../axios/index'
import { Constants } from '../../../Constants';
import { decrypt,encrypt } from '../../../Utility/Encryption';
var cancel = null
export default {
    async getEventParticipants(eventAlias,callback,searchString = '',pageNo = 1,ids = null){
        // if(cancel){
        //     cancel.cancel()
        // }
        // cancel = axios.CancelToken.source()
        await axios.post(`${eventAlias}/getAllParticipants`,
        {
            searchQuery: searchString,
            pageNumber: pageNo,
            ids: ids
        },
        {
            //cancelToken : cancel.token,
            params : {isPreview: Constants.isPreview},
            headers: { 
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN },
        }).then(response=>{
            let encryptedStr = response.data.u
            let participants = []
            if(encryptedStr != null){
                participants = decrypt(encryptedStr,Constants.encyptionKey)
                participants = JSON.parse(participants)
            }
            console.log(participants)
            for(var i=0;i<participants.length;i++){
                let profileUrl = participants[i].profileImageUrl
                let skip = false
                if(profileUrl != null){
                    if (profileUrl.indexOf("http://") == 0 || profileUrl.indexOf("https://") == 0) {
                        skip = true
                    }
                }else{
                    profileUrl = ""
                }
                if(!skip && profileUrl != ""){
                    profileUrl = process.env.REACT_APP_API_ENDPOINT.replace("/api/v1","") + profileUrl.substr(1)
                }
                participants[i].profileImageUrl = profileUrl
            }
            callback(participants)
        }).catch(err=>{
            if(axios.isCancel(err)){
                return
            }
            callback([])
        })
    }
}