import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import $ from 'jquery'
import 'bootstrap'
import utility from '../../Utility/utility';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import api from './api';
import { Constants } from '../../Constants';
import Loader from 'react-loader-spinner'
import EmailLogin from './EmailLogin';
import { isMobile } from 'react-device-detect';
import {Trans} from 'react-i18next'
export class Content extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            eEmail : '',
            ePassword : '',
            fbLoginEmail : false,
            loginError:'',
            loginMsg:false

        };
        this.translation = this.props.useTranslation.getResourceBundle(localStorage.getItem("language"+this.props.alias) || 'en') 
    }
    componentDidMount(){
        $("#form-line").submit(function(e) {
            e.preventDefault();
        });
        //let language=localStorage.getItem("language")
       //console.log(this.translation.pleaseEnterMail) 
    }
    OnFocus = (e) => {
        e.preventDefault()
    }
    OnClickLogin = () => {
        
        var email = document.getElementById('email').value
        var password = document.getElementById('pass').value
        if(email != '' && password != '' ){
        
        this.props.loginEventClicked(email)
        
        if(!utility.validateEmail(email)){
            this.setState({eEmail : <Trans>validEmail</Trans>})
        }
        else{
            this.setState({eEmail : ''})
        }
        // if(password == ""){
        //     this.setState({ePassword : 'Please enter valid password'})
        // }
        // else{
        //     this.setState({ePassword : ''})
        // }

        if(!utility.validateEmail(email)){
            return
        }
        this.props.onProcessLogin(email,password)
    }else if(email != '' && password == ''){

        this.setState({loginError:'Please Enter your Password',loginMsg:true})

        setTimeout(()=>{
            this.setState({loginError:'',loginMsg:false})

        },2000)
    }
    else if(email == '' && password != ''){

        this.setState({loginError:'Please Enter your Email',loginMsg:true})

        setTimeout(()=>{
            this.setState({loginError:'',loginMsg:false})

        },2000)
    }
    else if(email == '' && password == ''){

        this.setState({loginError:'Please Enter your Information',loginMsg:true})

        setTimeout(()=>{
            this.setState({loginError:'',loginMsg:false})

        },2000)
    }
    }
    faceBookLoginResponse = (response) => {
        if(response.status == 'unknown'){
            return
        }
        var nameSplit = response.name.split(" ")
        let firstName = nameSplit[0]
        let lastName = nameSplit[1]
        if(nameSplit.length > 2){
            firstName = nameSplit[0] + " " + nameSplit[1]
            lastName = nameSplit[2]
        }
        this.setState({fbLoginEmail : false})
        this.props.onProcessSocialLogin(firstName,lastName,response.email,'facebook',response.picture.data.url,response.id)
    }
    googleLoginResponse = (response) => {
        if(response.error != null){
            return
        }
        if(response.profileObj != undefined){
        var userObj = response.profileObj
        var nameSplit = userObj.name.split(" ")
        let firstName = nameSplit[0]
        let lastName = nameSplit[1]
        if(nameSplit.length > 2){
            firstName = nameSplit[0] + " " + nameSplit[1]
            lastName = nameSplit[2]
        }
        this.props.onProcessSocialLogin(firstName,lastName,userObj.email,'google',userObj.imageUrl,userObj.googleId)
    }
    }
    linkedInLoginSuccessResponse = (data) => {
        this.props.onProcessSocialLogin('','','','linkedin')
        api.getLinkedInUserInfo(
                this.props.alias,
                data.code,
                Constants.linkedInClientId,
                Constants.linkedInClientSecret,
                window.location.origin + Constants.linkedInRedirectUri,
                (err,data)=>{
            if(err == null){
                var userData = {
                    id : data.user.id,
                    firstName : data.user.firstName,
                    lastName : data.user.lastName,
                    profilePicture : data.user.profilePicture ? data.user.profilePicture['displayImage~'].elements[0].identifiers[0].identifier : null,
                    email : data.user.email[0]['handle~'].emailAddress
                }
                this.props.onProcessSocialLogin(userData.firstName,userData.lastName,userData.email,'linkedin',userData.profilePicture,userData.id)
            }else{
                this.props.onProcessSocialLogin('','','','linkedin')
            }
        })
    }
    linkedInLoginFailureResponse = (err) => {
        console.log('Linked in error : ',err)
    }
    render(){
        return(
            <section class = "login py-3 py-md-5 bg-light">
                <div class = "content">
                    <h3 class = "heading-main text-main text-center">
                        <i class="fas fa-lock mr-2"></i>
                        <Trans>login</Trans>
                    </h3>
                    <div class = "row py-3">
                            {this.props.event.is_jobfair != 1 ? 
                            null:
                            <div class = "col-12 text-center">
                            <p style={{fontSize:'18px'}}><Trans>noAccount</Trans> <a onClick = {this.props.onSignupClick} style = {{cursor : 'pointer',textTransform:'capitalize',fontSize:'18px',textDecoration:'underline'}} class = "text-danger font-weight-bold">
                                <Trans>createAccount</Trans></a></p>
                        </div>}
                    </div>
                    <div class = "container">
                        {/* <div style = {{display : 'none'}} class = "row py-3">
                            <div class = "col-12 text-center">
                                {Constants.googleClientId != "" ?
                                <GoogleLogin
                                    clientId={Constants.googleClientId}
                                    buttonText="Login"
                                    onSuccess={this.googleLoginResponse}
                                    onFailure={this.googleLoginResponse}
                                    cookiePolicy={'single_host_origin'}
                                    render={renderProps => (
                                        <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {renderProps.onClick} class = "btn btn-round mx-3">
                                            <img src = "../../images/icons/search.svg" alt = "google_logo"/>
                                        </a>
                                    )}
                                /> : null}
                                {Constants.faceBookAppId != "" ?
                                <FacebookLogin
                                    appId={Constants.faceBookAppId}
                                    fields="name,email,picture"
                                    callback={this.faceBookLoginResponse}
                                    render={renderProps => (
                                        <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {renderProps.onClick} class = "btn btn-round mx-3">
                                            <img src = "../../images/icons/facebook-logo.svg" alt = "google_logo"/>
                                        </a>
                                    )}
                                /> : null}
                                {Constants.linkedInClientId != "" ?
                                <LinkedIn
                                    clientId={Constants.linkedInClientId}
                                    onFailure={this.linkedInLoginFailureResponse}
                                    onSuccess={this.linkedInLoginSuccessResponse}
                                    redirectUri= {window.location.origin+Constants.linkedInRedirectUri}
                                    scope = "r_emailaddress r_liteprofile"
                                    renderElement={({ onClick, disabled }) => (
                                        <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {onClick} class = "btn btn-round mx-3">
                                            <img src = "../../images/icons/linkedin.svg" alt = "google_logo"/>
                                        </a>
                                    )}
                                /> : null}
                            </div>
                        </div> */}
                        <div class = "row py-3 justify-content-center">
                            <div class = "col-md-6">
                                <form id = "form-line" class = "p-3 py-md-4 px-md-5 border shadow-sm">
                                <div class = "form-group row login-option-btns mx-0 mb-4">
                                    <label class = "w-100"><Trans>socialLogin</Trans></label>
                                    {Constants.googleClientId != "" ?
                                    <div class = "col pl-0 pr-2">
                                        <GoogleLogin
                                            clientId={Constants.googleClientId}
                                            buttonText="Login"
                                            onSuccess={this.googleLoginResponse}
                                            onFailure={this.googleLoginResponse}
                                            cookiePolicy={'single_host_origin'}
                                            render={renderProps => (
                                                <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {renderProps.onClick} class = "btn w-100">
                                                    <img src = "../../images/icons/search.svg" alt = "google_logo"/>
                                                </a>
                                            )}
                                        />
                                    </div>:null}
                                    {Constants.faceBookAppId != "" ?
                                    <div class = "col px-2">
                                        <FacebookLogin
                                            disableMobileRedirect = {true}
                                            appId={Constants.faceBookAppId}
                                            fields="name,email,picture"
                                            callback={this.faceBookLoginResponse}
                                            render={renderProps => (
                                                <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {renderProps.onClick} class = "btn w-100">
                                                    <img src = "../../images/icons/facebook-logo.svg" className = "fb-img-icon" alt = "facebook_logo"/>
                                                </a>
                                            )}
                                        /> 
                                    </div> : null}
                                    {Constants.linkedInClientId != "" ?
                                    <div class = "col pl-2 pr-0">
                                        <LinkedIn
                                            clientId={Constants.linkedInClientId}
                                            onFailure={this.linkedInLoginFailureResponse}
                                            onSuccess={this.linkedInLoginSuccessResponse}
                                            redirectUri= {window.location.origin+Constants.linkedInRedirectUri}
                                            scope = "r_emailaddress r_liteprofile"
                                            renderElement={({ onClick, disabled }) => (
                                                <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {onClick} class = "btn w-100">
                                                    <img src = "../../images/icons/linkedin.svg" alt = "linkedin_logo"/>
                                                </a>
                                            )}
                                        />
                                    </div>: null}
                                </div>
                                <div class="form-group">
                                    <label for="email"><Trans>email</Trans></label>
                                    <input onFocus = {this.OnFocus} disabled = {this.props.processingLogin} onKeyDown ={(e)=>{
                                    let upperCaseLetter = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
                                    let keyCode = e.which || e.keyCode
                                    if(keyCode == 32 || upperCaseLetter.includes(e.key) ){
                                        e.preventDefault();
                                        return false
                                    }
                                    else{
                                        return true
                                    }

                                }} id = "email" type="email" class= {this.state.eEmail == ""?"form-control":"form-control text-red-border"} placeholder={this.translation.pleaseEnterMail} />
                                    {this.state.eEmail != '' ? <div class="invalid-feedback">{this.state.eEmail}</div> : null}
                                </div>
                                <div class="form-group">
                                    <label class = {this.state.ePassword == "" ? "" : "text-red"} for="pwd"><Trans>password</Trans></label>
                                    <input disabled = {this.props.processingLogin} id = "pass" type="password" class= {this.state.ePassword == ""?"form-control":"form-control text-red-border"} placeholder={this.translation.enterPassword} />
                                </div>
                                <div class = "form-gorup mb-3">
                                    <p><Trans>forgotPassword</Trans> <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {this.props.onRecoverPassword} style = {{cursor : 'pointer'}} class = "text-danger font-weight-bold">
                                        <Trans>recoverHere</Trans></a></p>
                                </div>
                                <div style = {{display : isMobile ? '' : 'flex',flexDirection : 'row'}}>  
                                    <div class="form-group loginForm">
                                        {/* <a style = {{pointerEvents : this.props.processingLogin ? 'none' : 'auto'}} onClick = {this.OnClickLogin} class="btn bg_main text-light px-5 rounded-0">LOG IN</a> */}
                                        <input onClick = {this.OnClickLogin} type = "submit" class = "btn btn-tele bg_main tele-text text-light px-5 rounded-0  bg_secondary"
                                        value = {this.translation.login} id = "loginButton" />
                                    </div>
                                    {this.props.processingLogin ?
                                    <div style = {{marginTop : 6,marginLeft : isMobile ? '45%' : 12}}>
                                        <Loader
                                            type = 'Oval'
                                            color = "#00b6ff"
                                            height = {30}
                                            width = {30}
                                        />
                                    </div> : null}
                                </div>
                                {this.props.loginError != '' ?
                                <div className="alert alert-danger contactUsAlert">{this.props.loginError}</div>:null}
                                {this.state.loginMsg == true ?
                                <div className="alert alert-danger contactUsAlert">{this.state.loginError}</div>:null}
                                </form>
                            </div>
                        </div>
                        
                        <div class = "row py-3">
                            {this.props.event.is_jobfair != 1 ? 
                            <div class = "col-12 text-center">
                                <p><Trans>noAccount</Trans> <a onClick = {this.props.onSignupClick} style = {{cursor : 'pointer'}} class = "text-danger font-weight-bold">
                                    <Trans>createAccount</Trans></a></p>
                            </div>:
                         null

                            }
                        </div>
                        
                    </div>
                </div>
                </section>	
        )
    }//end of render

}

export default Content;