import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {Trans} from 'react-i18next'
export class WelcomeSection extends React.Component{

    constructor(props){

        super(props);
        this.state = {
            welcome_event:this.props.event
        };
    }
	
    render(){
        const event = this.props.event;

        if(event){
            return(
				<section className = "itro py-3" id = "about">
					<div className="container">
						<div className="row">
							<div className={`${this.props.event.homepageTopButtonEnabled == 0  ? 'col-xs-12 col-md-12 col-lg-12':'col-xs-8 col-md-7 col-lg-8'}`}>
                                { ReactHtmlParser(event.welcome_description) }
							</div>
							{
							this.props.event.homepageTopButtonEnabled == 1 ?
							<div className="col-xs-4 col-md-5 col-lg-4">
								<div style = {{float : 'right'}} className="btn-group-vertical w-100">
									<a style = {{fontWeight : 'bold'}} onClick ={this.props.registerInterestEvent} data-target ="#register_Modal" data-toggle = "modal" className="btn model_open mb-3 text-left py-3">
										<Trans>registerInterest</Trans> <i className="fas fa-arrow-right ml-5 float-right mt-1"></i>
									</a>

									<a style = {{fontWeight : 'bold'}} onClick={this.props.downloadAgenda} data-target ="#aganda_Modal" data-toggle = "modal" className="btn mb-3 text-left py-3">
										<Trans>downloadAgenda</Trans> <i className="fas fa-arrow-right ml-5 float-right mt-1"></i>
									</a>
									<a style = {{fontWeight : 'bold'}} onClick = {this.props.onClickContactUs} className="btn mb-3 text-left py-3">
										<Trans>contactUs</Trans> <i className="fas fa-arrow-right ml-5 float-right mt-1"></i>
									</a>
								</div>
							</div>:null}
						</div>
					</div>
				</section>
            );
		}else{
        	return(null);
		}
    }
}

export default WelcomeSection;