import { Constants } from "../../Constants"
import axios from '../axios/index'
const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)
const webPushId = "web.com.events-frontend"
const webServiceUrl = process.env.REACT_APP_API_ENDPOINT

function urlBase64ToUint8Array(base64String) {
  //console.log("VAPID KEY",process.env.REACT_APP_PUBLIC_VAPID_KEY)
  const padding = "=".repeat((4 - base64String.length % 4) % 4)
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function sendSubscription(subscription,eventAlias,userId) {
  const key = subscription.getKey('p256dh')
  const token = subscription.getKey('auth')
  const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]
  var updatedSubscriptionObj = {
    endpoint: subscription.endpoint,
    publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
    authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
    contentEncoding
  }
  var data = {
    user_id : userId,
    subscription : JSON.stringify(updatedSubscriptionObj)
  }  
  return axios.post(`${eventAlias}/webpush/subscriptions`,
    data,
    {
        headers: {
            'Content-Type': 'application/json',
            'apiToken': Constants.API_TOKEN}
    })
}

export function subscribeUser(userId,eventAlias) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(registration) {
      if ('safari' in window && 'pushNotification' in window.safari) {
        // var permissionData = window.safari.pushNotification.permission(webPushId);
        // checkRemotePermission(userId,permissionData);
        return
      }
      if (!registration.pushManager) {
        console.log('Push manager unavailable.')
        return
      }
      registration.pushManager.getSubscription().then(function(existedSubscription) {
        if (existedSubscription === null) {
          console.log('No subscription detected, make a request.')
          registration.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          }).then(function(newSubscription) {
            console.log('New subscription added.')
            sendSubscription(newSubscription,eventAlias,userId)
          }).catch(function(e) {
            if (Notification.permission !== 'granted') {
              console.log('Permission was not granted.')
            } else {
              console.error('An error ocurred during the subscription process.', e)
            }
          })
        } else {
          console.log('Existed subscription detected.')
          sendSubscription(existedSubscription,eventAlias,userId)
        }
      })
    })
      .catch(function(e) {
        console.error('An error ocurred during Service Worker registration.', e)
      })
  }
}
var checkRemotePermission = function (userId,permissionData) {
  if (permissionData.permission === 'default') {
      // This is a new web service URL and its validity is unknown.
      window.safari.pushNotification.requestPermission(
          webServiceUrl, 
          webPushId,    
          {
            userId : userId
          }, // Data that you choose to send to your server to help you identify the user.
          checkRemotePermission         // The callback function.
      );
  }
  else if (permissionData.permission === 'denied') {
      // The user said no.
  }
  else if (permissionData.permission === 'granted') {
      // The web service URL is a valid push provider, and the user said yes.
      // permissionData.deviceToken is now available to use.
  }
};
