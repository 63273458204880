import React from 'react' 
import Loader from 'react-loader-spinner'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Trans} from 'react-i18next'
export const UserProfile = (props)=>{
    return(
        <>
        <div class="card-header"><Trans>myProfile</Trans></div>
        <div class="card-body border-bottom rounded-0">
            {props.loading ?
            <div style = {{position : 'absolute',top:55,left:0,bottom:0,right:0,background:'rgba(0, 0, 0, 0.5)',zIndex : 10}}>
                <div style = {{position : 'absolute',top : '50%',left : '50%'}}>
                    <Loader
                        type = 'Oval'
                        color = "#fff"
                        height = {50}
                        width = {50}
                    />
                </div>
            </div> : null}
            <div class = "row">
                <div class="col-md-5">
                    <div class = "card_inner bg-light v_profile_card py-3 px-3 px-md-4 rounded border">
                        <div class = "user_img">
                            {props.user.profileImageUrl == null || props.user.profileImageUrl == "" ? 
                                <img src="/images/profile-placeholder.png" class = "rounded-circle" alt="user_1" /> :
                                <img src={props.user.profileImageUrl} class = "rounded-circle" alt="user_1" />}
                            <div class="c-edit-bt">
                                <i class="fas fa-pencil-alt"></i>
                                <input style = {{width : '100%',height : '100%',position :'absolute',left : 0}} class="file-input" type="file" id = "imageUpload" onChange = {props.onImageSelected}/>
                            </div>
                        </div>
                        {props.profileBio ?
                        <div class = "user_caption">
                            <p class = "mt-2 f-400 text-center">
                                {props.profileBio.substring(0,278)}
                                {props.profileBio.length >= 278 ?
                                <>
                                <span id="dots">....</span>
                                <span id="hidden-text">
                                    {props.profileBio.substring(278,props.profileBio.length)}
                                </span>
                                <span onClick = {props.onClickMore} id="more-less">more</span> </> : null}
                            </p>
                        </div> : null}
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="table table-account border-0 table-responsive">
                        <table>
                            <tr>
                                <td><Trans>firstName</Trans>:</td>
                                <td>{props.user.firstName}</td>
                            </tr>
                            <tr>
                                <td><Trans>secondName</Trans>:</td>
                                <td>{props.user.secondName}</td>
                            </tr>
                            <tr>
                                <td><Trans>lastName</Trans>:</td>
                                <td>{props.user.lastName}</td>
                            </tr>
                            <tr>
                                <td><Trans>email</Trans>:</td>
                                <td>{props.user.email}</td>
                            </tr>
                            <tr>
                                <td><Trans>companyName</Trans>:</td>
                                <td>{props.user.companyName == null ? "-" : props.user.companyName}</td>
                            </tr>
                            <tr>
                                <td><Trans>jobTitle</Trans>:</td>
                                <td>{props.user.jobTitle == null ? "-" : props.user.jobTitle}</td>
                            </tr>
                            <tr>
                                <td><Trans>phNumber</Trans>:</td>
                                <td>{props.user.phone == null ? "-" : props.user.phone}</td>
                            </tr>
                            
                            <tr>
                                <td><Trans>gender</Trans>:</td>
                                <td>{props.user.gender == null ? "-" : props.user.gender}</td>
                            </tr>
                            <tr>
                                <td><Trans>city</Trans>:</td>
                                <td>{props.user.city == null ? "-" : props.user.city}</td>
                            </tr>
                            <tr>
                                <td><Trans>country</Trans>:</td>
                                <td>{props.user.country == null ? "-" : props.user.country}</td>
                            </tr>
                            {/* {props.user.personal_zoom_meeting_link ?<tr>
                                <td><Trans>zoomPersonalLink</Trans>:</td>
                                <td>{props.user.personal_zoom_meeting_link}</td>
                            </tr> : null}
                            {props.user.zoom_password ? <tr>
                                <td><Trans>zoomPassword</Trans>:</td>
                                <td>{props.user.zoom_password}</td>
                            </tr> : null}
                            {props.user.zoom_user_name ?<tr>
                                <td><Trans>zoomUserName</Trans>:</td>
                                <td>{props.user.zoom_user_name}</td>
                            </tr> : null} */}
                        </table>
                    </div>
                </div>
            </div>
            <div class = "row pt-3">
                <div class = "col-12 col-md-8 offset-md-5">
                    <a onClick = {props.onClickEditProfile} style = {{cursor : 'pointer'}} class="btn btn-tele px-5 rounded-0 ml-3">
                        <Trans>edit</Trans></a>
                </div>
            </div>
            {props.successMessage != '' ?
                <div className = "d-flex justify-content-center">
                    <div className="alert col-md-4 alert-success contactUsAlert">{props.successMessage}</div>
                </div>
                :null}
        </div>
        <div class="modal" id="edit-img">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content bio-modal">
                
                    <div class = "modal-header px-3 px-md-5">
                        <h3 class = "text-center w-100"><Trans>profilePhoto</Trans></h3>
                        <button data-dismiss="modal">
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div>
                    {props.selectedImage != null ?
                    // <div class="modal-body px-3 px-md-5 upload-image">
                    //     <div class="uploadpreview" id = "imagePreview">
                    //         {props.user.profileImageUrl == null || props.user.profileImageUrl == "" ? 
                    //             <img src="/images/profile-placeholder.png" class = "rounded-circle" alt="user_new-img" /> :
                    //             <img style = {{height : 150,width : 150,objectFit : 'contain'}} src={props.user.profileImageUrl} class = "rounded-circle" alt="user_new-img" />}
                    //     </div>                    

                    //     <p class = "mt-5 text-center">
                    //         <label for = "imageUpload" class="btn bg_secondary"> Update Photo
                    //             <input class="file-input" type="file" id = "imageUpload" onChange = {props.onImageSelected}/>
                    //         </label> 
                    //     </p>
                    
                    // </div> : 
                        <div>
                            <div class="modal-body px-1 px-md-1 upload-image text-center">
                            <div id = "selectedImagePreview">
                                {/* <img style = {{width : '100%'}} src={URL.createObjectURL(props.selectedImage)}  alt="user_selected-img" /> */}
                                <ReactCrop
                                    src={URL.createObjectURL(props.selectedImage)}
                                    crop={props.crop}
                                    ruleOfThirds
                                    onImageLoaded={props.onImageLoaded}
                                    onComplete={props.onCropComplete}
                                    onChange={props.onCropChange}
                                    keepSelection = {true}
                                />
                            </div>
                            <p class = "row px-5 mx-5 mt-2 pt-3 justify-content-around">
                                <label class = "btn bg_secondary"><Trans>saveAndUpload</Trans>
                                    <button onClick = {props.onUploadImage}></button>
                                </label>
                                <label for = "imageUpload" class="btn bg_secondary"> <Trans>uploadAgain</Trans>
                                    <input class="file-input" type="file" id = "imageUpload" onChange = {props.onImageSelected}/>
                                </label> 
                            </p>
                        
                        </div>
                    </div>:null}

                </div>
            </div>
        </div>
        </>
    )
}