import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import {Constants} from '../../Constants';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from '../SpeakerV1/api';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import Loader from 'react-loader-spinner'
import { ErrorScreen } from '../ErrorScreen';
import utility from '../../Utility/utility';
import Helmet from 'react-helmet'
import { SpeakersDataLoaded } from './actions';
import { connect } from 'react-redux'
import {compose} from 'redux'
import MetaTags from 'react-meta-tags';
import VirtualZone from '../Header/virtualZone';
import { GAPageView, GARecordEvent } from '../../Utility/GA';
export class Speakers extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event,
            event_alias: this.props.event_alias,
            speakers : null,
            selectedIndex : this.props.headerMenuItems != null ? this.props.headerMenuItems.findIndex(x=>x.href.indexOf('/speakers') > -1) || -1 : -1,
            selectedSubIndex: -1,
            loadingError : '',
            allSpeakers : [],
            menu :  this.props.headerMenuItems != null ? this.props.headerMenuItems.find(x=>x.href == (this.props.event.alias+"/speakers")) : null,
            showComingSoonMsg : false
        };
        this.user = utility.getLoggedInUser(this.props.alias)
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.defaultSpeakers != null){
            var allSpeakers = []
            for(var i=0;i<nextProps.defaultSpeakers.length;i++){
                if(i%4 == 0){
                    var speakerObj = []
                    allSpeakers.push(speakerObj)
                }
                speakerObj.push(nextProps.defaultSpeakers[i])
            }
            this.setState({allSpeakers : allSpeakers})
            return
        }
    }
    
    componentDidMount(){
        GAPageView(window.location.hostname + window.location.pathname + window.location.search)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
        if(this.props.defaultSpeakers != null){
            var allSpeakers = []
            for(var i=0;i<this.props.defaultSpeakers.length;i++){
                if(i%4 == 0){
                    var speakerObj = []
                    allSpeakers.push(speakerObj)
                }
                speakerObj.push(this.props.defaultSpeakers[i])
            }
            this.setState({allSpeakers : allSpeakers})
            return
        }
        fetch('/setCurrentPage?page=speakers')
        fetch(`/${this.props.event.alias}/speakers`)
        if(this.state.selectedIndex == -1){
            for(var i=0;i<this.props.headerMenuItems.length;i++){
                var result = utility.searchTree(this.props.headerMenuItems[i],'Speakers')
                if(result != null){
                    let selectedSubIndex = this.props.headerMenuItems[i].children.findIndex(x=>x.href.indexOf('/speakers') > -1)
                    this.setState({selectedIndex : i,selectedSubIndex})
                    break
                }
            }
        }
        this.setState({allSpeakers : this.props.speakers},()=>{
            if(this.props.speakers != null){
                if(this.props.speakers.length == 0){
                    this.setState({showComingSoonMsg : true})
                }
            }
        })
        if(this.props.speakers == null){
            this.getEventSpeakers()
        }
    }
    getEventSpeakers = () => {
        api.getEventSpeakers(this.state.event.alias,"",(err,data)=>{
            if(err == null){
                if(data.speakers.length == 0){
                    this.setState({showComingSoonMsg : true})
                }
                var allSpeakers = []
                for(var i=0;i<data.speakers.length;i++){
                    if(i%4 == 0){
                        var speakerObj = []
                        allSpeakers.push(speakerObj)
                    }
                    speakerObj.push(data.speakers[i])
                }
                this.props.speakersLoaded(allSpeakers)
                this.setState({speakers:data.speakers,allSpeakers, startIndex : 0,loadingError : ''});
                return
            }
            this.setState({loadingError : 'Something went wrong'})
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else{
            path = "/"+path
        }
        this.props.history.push(path)
    }
    languageClick = (value) =>{
        console.log("Trigger Speaker on lan select")
        this.props.languageClick(value);
    }
    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    OnPressRetry = () => {
        this.setState({loadingError : ''})
        this.getEventSpeakers()
    }
    bookNowEvent = () =>{
        
        console.log("Book now button clicked, recording activity")
        GARecordEvent("bookNowClicked", this.user? this.user.id :"non user",{
            category : 'Book Now',
            action : 'Book now button clicked',
            userName: this.user? (this.user.firstName+''+this.user.lastName) : "not avaialble",
            userEmail: this.user ? this.user.email :"not available"
        })
        
        this.OnNavigateToPage(this.props.event.alias+"/booking")
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return 'content-data'
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = null
        if(this.props.defaultSpeakers == null){
            user = this.user
        }
        const speakers = this.state.speakers;
        return(
            this.props.defaultSpeakers != null ?
                <Content 
                    speakers = {this.state.allSpeakers}
                    otherPageContent = {true}
                    speakerHeading = {this.props.speakerHeading}
                    onClickBookNow = {()=>this.OnNavigateToPage(this.props.alias+"/booking")}
                /> :
				<div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.menu != null ? this.state.menu.title : 'Speakers'}</title>
                        <meta property="description" content={this.props.seoData == null ? "" : this.props.seoData.meta_description} />
                        <meta property="title" content={this.props.seoData == null ? "" : this.props.seoData.seo_title} />
                    </Helmet>
                    <div id="header-top">
					<Header
						selectedIndex = {this.state.selectedIndex}
                        selectedSubIndex = {this.state.selectedSubIndex}
						onMenuItemClick = {this.OnNavigateToPage}
                        elements = {this.props.headerMenuItems}
                        facebookLink = {this.props.event.facebook}
                        linkedInLink = {this.props.event.linkedIn}
                        isMarketplace = {this.props.event.is_marketplace}
                        twitterLink = {this.props.event.twitter}
                        instagramLink = {this.props.event.instagram}
                        googleLink = {this.props.event.google}
                        phone = {this.props.event.phone_number}
                        eventLogo = {this.props.event.logo}
                        event = {this.props.event}
                        eventName = {this.props.event.name}
                        eventAlias = {this.props.event.alias}
                        availableLocales = {this.props.availableLocales}
                        languageClick  = {this.languageClick}
                        onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                        onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                        onClickAccountItem = {this.OnClickAccountItem}
					/>
                    {user != null && user.isBooking && Constants.showVirtualZone?
                    <VirtualZone
                        
                        eventAlias = {this.props.event.alias}
                        event = {this.props.event}
                        onMenuItemClick = {(path)=>{
                            if(path != ''){
                                this.OnNavigateToPage(path)
                            }
                        }}  
                        unReadCount = {this.props.unReadCount}
                        onNotificationSeen = {this.props.onNotificationSeen} 
                        onClearNotifications = {this.props.onClearNotifications}
                        notificationsCount = {this.props.unReadNotificationsCount}
                        notifications = {this.props.notifications}
                        onClickAccountItem = {this.OnClickAccountItem}
                        onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                        menuItems = {this.props.virtualMenuItems}
                        user = {user} 
                    /> : null}
                    </div>
                    <div className = {`content-main`}>
                    {this.state.allSpeakers != null ?
                    this.state.showComingSoonMsg ?  
                    <div className = "text-center bg-light">
                        <img style = {{width : '50%',margin : '50px 0px'}} src="/images/coming-soon.svg"/>
                    </div> : 
                    <Content 
                        speakers = {this.state.allSpeakers}
                        onClickBookNow = {this.bookNowEvent}
                    /> :
                    <div style = {{width : '100%',height : window.innerHeight,background : '#f7f7f7',display : 'flex',justifyContent : 'center',alignItems : 'center'}}>
                        {this.state.loadingError == "" ?
                            <Loader
                                type = 'Oval'
                                color = "#00b6ff"
                                height = {40}
                                width = {40}
                            />:
                            <ErrorScreen 
                                message = {this.state.loadingError}
                                onPressRetry = {this.OnPressRetry}
                            />
                        }
                    </div>}
                    {this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ?<NewsLetter 
                        title = "SIGN UP FOR SPEAKER UPDATES" 
                        event={this.state.event} 
                        event_alias={this.state.event_alias}
                        buttonText = "Sign Up"
                        type = 'speakerUpdate'
                    />:null}
					<Footer1 
                        event = {this.state.event}
                        elements = {this.props.footerMenuItems}
                        onMenuItemClick = {this.OnNavigateToPage}
                    />
                    </div>
					<Footer2 
                       event = {this.props.event}
                        copyRightLogo = {this.props.event.copyright_logo}
                        copyRightText = {this.props.event.copyright_text}
                        copyRightLink = {this.props.event.copyright_link}
                        showCopyRight = {this.props.event.copyright_text || this.props.event.copyright_logo}
                    />
                </div>
                
        )
    }
}

const mapStateToProps = state => ({
    speakers: state.speakers.speakersData
})
const mapDispatchToProps = dispatch => ({
    speakersLoaded : data => dispatch(SpeakersDataLoaded(data))
})
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Speakers);