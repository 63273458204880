import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Header/Index';
import Content from './content';
import Footer1 from '../Footer/Footer1';
import Footer2 from '../Footer/Footer2';
import NewsLetter from '../NewsLetter';
import { Constants } from '../../Constants';
import utility from '../../Utility/utility';
import VirtualZone from '../Header/virtualZone';

export class CancellationPolicy extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            event: this.props.event
        };

    }

    componentDidMount(){
        window.scrollTo(0,0)
        document.addEventListener('navigateToLogin',(event)=>{
            this.OnNavigateToPage(this.props.event.alias+"/login")
        })
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push(path)
    }

    languageClick = (value) =>{
        console.log("Trigger cancellation policy on lan select")
        this.props.languageClick(value);
    }

    OnClickAccountItem = (index,path) =>{
        this.props.onClickAccountItem(index,path)
        if(index == null)
            return
        if(index > -1){
            this.props.history.replace('')
            this.props.history.push({
                pathname : this.props.event.alias+'/account',
                state : {tabIndex : index}
            })
        }
        else{
            localStorage.removeItem(Constants.API_TOKEN+this.props.event.alias+'user_info')
            window.location.reload()
        }
    }
    getClasses = ()=>{
        if(this.props.event.is_marketplace == "1"){
            let user = utility.getLoggedInUser(this.props.event.alias)
            if(user == null){
                return 'content-data'
            }
            else if(user != null && user.isBooking == false){
                return 'content-data'
            }
            else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
                return ''
            }
            else{
                return ''
            }  
        }
        else{
        let user = utility.getLoggedInUser(this.props.event.alias)
        if(user == null){
            return 'content-data'
        }
        // else if(user != null && user.isBooking == false){
        //     return ''
        // }
        else if(user != null && user.isBooking == true && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else if(user != null && user.isBooking == false && Constants.showVirtualZone == true){
            return ''
        }
        else if (user != null && user.isBooking == false && Constants.showVirtualZone == false){
            return 'content-data'
        }
        else{
            return ''
        }
    }
}
    render(){
        let user = utility.getLoggedInUser(this.props.event.alias)
        return(
            this.state.event != null ?
                <div>
                    <div id="header-top">
                    <Header
                        selectedIndex = {-1}
                        onMenuItemClick = {this.OnNavigateToPage}
                        elements = {this.props.headerMenuItems}
                        facebookLink = {this.props.event.facebook}
                        linkedInLink = {this.props.event.linkedIn}
                        twitterLink = {this.props.event.twitter}
                        instagramLink = {this.props.event.instagram}
                        isMarketplace = {this.props.event.is_marketplace}
                        event = {this.props.event}
                        googleLink = {this.props.event.google}
                        phone = {this.props.event.phone_number}
                        eventLogo = {this.props.event.logo}
                        eventName = {this.props.event.name}
                        eventAlias = {this.props.event.alias}
                        onLoginClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/login')}
                        onSignupClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/sign_up')}
                        onClickAccountItem = {this.OnClickAccountItem}
                        availableLocales = {this.props.availableLocales}
                        languageClick  = {this.languageClick}
                    />
                    {user != null && user.isBooking && Constants.showVirtualZone?
                    <VirtualZone
                        
                        eventAlias = {this.props.event.alias}
                        event = {this.props.event}
                        onMenuItemClick = {(path)=>{
                            if(path != ''){
                                this.OnNavigateToPage(path)
                            }
                        }}
                        unReadCount = {this.props.unReadCount}
                        onNotificationSeen = {this.props.onNotificationSeen} 
                        onClearNotifications = {this.props.onClearNotifications}
                        notificationsCount = {this.props.unReadNotificationsCount}
                        notifications = {this.props.notifications}
                        onClickAccountItem = {this.OnClickAccountItem}
                        onClickNotification = {(path,isExternal)=>{
                        if(isExternal){
                            window.open(path,"_blank")
                        }else{
                            this.OnNavigateToPage(this.props.event.alias+path)
                        }
                    }}
                        menuItems = {this.props.virtualMenuItems}
                        user = {user} 
                    /> : null}
                    </div>
                    <div className = {`content-main`}>
                        <Content event = {this.state.event}/>
                        { this.props.event.is_marketplace == "0" && this.props.event.is_jobfair == 0 ? <NewsLetter 
                            title = "SUBSCRIBE OUR NEWS LETTER" 
                            event={this.props.event} 
                            event_alias={this.props.event_alias}
                            buttonText = "Subscribe"
                            type = 'newsLetter'
                        />:null}
                        <Footer1 
                            event = {this.state.event}
                            elements = {this.props.footerMenuItems}
                            onMenuItemClick = {this.OnNavigateToPage}
                        />
                    </div>
                    <Footer2 
                    event = {this.props.event}
                    copyRightLogo = {this.state.event.copyright_logo}
                    copyRightText = {this.state.event.copyright_text}
                    copyRightLink = {this.state.event.copyright_link}
                    showCopyRight = {this.state.event.copyright_text || this.state.event.copyright_logo}
                />
                </div> : <div>Loading</div>
        )
    }
}

export default withRouter(CancellationPolicy);