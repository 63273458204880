
import React,{useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
// import { connect } from 'socket.io-client';
import $ from 'jquery';
import { connect } from 'react-redux'
import { RemoveScroll } from '../1 - Sub menu components/Virtual Exhibition/actions';
import './styles.css'
const PermiumUserModal = (props)=>{
    $(window).on('popstate', function() {
        $(`#${props.id}`).modal('hide');
        $( ".modal-backdrop" ).remove();
        $( ".in" ).remove();
        props.RemoveScroll(0)
    })
    return(
        <div class={`modal show`} id={props.id} aria-modal="true" data-keyboard="true" data-backdrop='static'>
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content text-center login-req">
                    <div class="modal-body p-3 p-md-5">
                        <div style={{position:'absolute',top:'5%',right:'5%'}} className="generic-modal" onClick = {()=>{
                            props.onClose()
                            props.RemoveScroll(0)
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x bi-cancel" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
                            </div>
                            <i class={"fas "+props.iconClass}></i>
                            <h1>{props.title}</h1>
                            {ReactHtmlParser(props.description)}
                            {props.showButton ?
                            <div class="btn-row mt-3 mt-md-4">
                                <ul class="list-inline">
                                    <li class="list-inline-item mx-3">
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>{
                                            props.onClickBookNow()
                                            }} class="btn btn-tele">{props.buttonText}</a>
                                    </li>
                                </ul>
                            </div> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapDispatchToProps = dispatch => ({
    RemoveScroll : data => dispatch(RemoveScroll(data))
  })
export default connect(null,mapDispatchToProps)(PermiumUserModal)