import firebase from "firebase";

var config = {
  apiKey: "AIzaSyDqRT2QgTko6L_-Bx_Tk4OWOx1BNCaA0bs",
  authDomain: "eventsnotifications-19d46.firebaseapp.com",
  databaseURL: "https://eventsnotifications-19d46-default-rtdb.firebaseio.com",
  projectId: "eventsnotifications-19d46",
  storageBucket: "eventsnotifications-19d46.appspot.com",
  messagingSenderId: "175100848892",
  appId: "1:175100848892:web:acaf748c9c45f5cc3c8579",
  measurementId: "G-MQ826CMR77"
};
var firebase_config =  firebase.initializeApp(config,"secondry") 


export default firebase_config;