import React from "react"
import ContentLoader from "react-content-loader"
import {Trans} from 'react-i18next'
const MyLoader = (props) => (
  
  <section class="job-board-section py-3">
    <div class="container">
      <h2 class="text-center font-weight-bold text-uppercase" style={{color:'black'}}>
      <Trans>jobBoard</Trans>
      </h2>

    <div class="row py-3">
      <div class="col-12">
        <ContentLoader viewBox="0 0 380 70" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" speed={6}>
          <rect x="10" y="5" rx="4" ry="3" width="150" height="10" />
          <rect x="340" y="5" rx="4" ry="3" width="40" height="10" />
          <rect x="10" y="25" rx="3" ry="3" width="150" height="10" />
          <rect x="340" y="25" rx="4" ry="3" width="40" height="10" />
          <rect x="10" y="45" rx="3" ry="3" width="150" height="10" />
          <rect x="340" y="45" rx="4" ry="3" width="40" height="10" />
        </ContentLoader>
      </div>
      </div>
    </div>
  </section>
)
export default MyLoader