import React,{useEffect,useState} from 'react'
import utitlity,{wordsRestrictor} from '../../../Utility/utility'
import  PermiumUserModal  from '../../modals/premiumUserModal'
import ReactHtmlParser from 'react-html-parser'
import { Constants } from '../../../Constants';
import * as Actions from "../actionTypes";
import $ from 'jquery'
export default function VideoContent(props) {

    const [currentVideo,setCurrentVideo] = useState({})
    const [videos,setMoreVideos] = useState(props.videos)
    const [numberOfVideos,setnumberOfVideos] = useState(5)
    var user = utitlity.getLoggedInUser(props.event.alias)


    useEffect(() => {
        setActiveVideo(props.videoId)
    },[])

    const setActiveVideo = (id)=>{
        var currentVideo = props.videos.find(item => item.details.id == id)
        if(currentVideo.details.is_allowed == 0){
            if(user != null){
                if(currentVideo.details.is_allowed == 0){
                 $("#premiumSession").modal('show')
                }
                else if(currentVideo.details.is_allowed == 1){
                    setCurrentVideo(currentVideo.details)
                    var moreVideos = props.videos.filter(item=>item.details.id != id)
                     setMoreVideos(moreVideos)
                }
                else if(user == null){
                    props.OnNavigateToPage(`${props.event.alias}/login`)        
            }
        }
        else{
            props.OnNavigateToPage(`${props.event.alias}/login`)
        }
        }
        else{
        setCurrentVideo(currentVideo.details)
        var moreVideos = props.videos.filter(item=>item.details.id != id)
        setMoreVideos(moreVideos)
        }
        

    }
    return (
    <>
    <PermiumUserModal
    id = "premiumSession"
    iconClass = "fa-star"
    title = "Premium Video"
    description = "<p>You need to have booked at least one paid package to access this content.If you already booked please login to see premium content.</p>"
    buttonText = "Join Now"
    showButton = {true}
    onClose = {()=>{
        $("#premiumSession").modal('hide')
    $('.modal-backdrop').remove();
      }}
    onClickBookNow = {()=>{
    $("#premiumSession").modal('hide')
    $('.modal-backdrop').remove();
    props.OnNavigateToPage(`${props.event.alias}/membership`)}
    }/>
    <section class = {`telehealth-inner-banner   bg_primary py-3`}>
	<div class = "container py-2 text-center">
		<div class = "row">
			<div class = "col-12">
				<h2>
					Videos
				</h2>
			</div>
			<button  class = "btn btn-back" onClick={()=>{
                props.openPlayer()
            }}>
				<span>&#x2039;</span> videos
			</button>
		</div>
	</div>
</section>
   <section class = "presentation-sec videos-sec py-3">
	
   <div class = "container video-player mt-4">
       <div class = "row">
           
           <div class = "col-md-8 pr-md-0">
               <div class = "plying-video-area">
                     <div style={{padding:'56.25% 0 0 0',position:"relative"}}>
                         {console.log(currentVideo.data_url)}
                    <iframe width="853" height="480" 
                    // src={currentVideo && currentVideo.data_url && currentVideo.data_url.includes('youtube') ? currentVideo && currentVideo.data_url && currentVideo.data_url.replace('/watch?v=','/embed/'): currentVideo && currentVideo.data_url && currentVideo.data_url.replace('vimeo.com/','player.vimeo.com/video/')} 
                    //  src='https://player.vimeo.com/video/76979871'
                    src ={currentVideo && utitlity.validateYoutubeUrl(currentVideo.data_url) == true ? 'https://www.youtube.com/embed/'+utitlity.getYoutubeVideoId(currentVideo.data_url) : currentVideo && currentVideo.data_url && currentVideo.data_url.replace('vimeo.com/','player.vimeo.com/video/') }
                    style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} frameBorder="0">
                     </iframe>
                     </div>
               </div>
               <div class = "Video-caption mt-3">
                   <h6>{
                   currentVideo.title
                   }
                   </h6>
                   <p>
                       {currentVideo.description && currentVideo.description > 1000?
                           ReactHtmlParser(currentVideo.description.substring(0,1000)):
                           ReactHtmlParser(currentVideo.description)
                       }
                   </p>
               </div>
           </div>
           {videos.length >0 ?
           <div class = "col-md-4">
               <div class = "container-fluid related-video-list">
                   <h6> More Videos </h6>
                   {videos.slice(0,numberOfVideos).map(item=>{
                   return <div class = "row mb-3">
                       <div class = "col-6 pr-0" onClick={()=>{
                             setActiveVideo(item.details.id)
                       }}>
                           <div class = "video-show">
                               <a href = "#">
                               {item.details.thumbnail != null ? 
						<img src={item.details.thumbnail} alt="blog-1_img"
						/> :
						<img  src={utitlity.validateYoutubeUrl(item.details.data_url) == true ? ` https://img.youtube.com/vi/${utitlity.getYoutubeVideoId(item.details.data_url)}/sddefault.jpg` : 
						//  item && item.details && item.details.data_url && vimeoThumbnail('https://vimeo.com/537672480'.toString())
						// 'https://i.vimeocdn.com/video/1112998799_640'
						'/images/telehealth-images/video_placeholder.png'
						}/>
                                //    <img src={item.details.thumbnail} alt="blog-1_img"/>
                    }
                               </a>
                           </div>
                       </div>
                       <div class = "col-6">
                           <div class = "rv-caption" onClick={()=>{
                             setActiveVideo(item.details.id)
                       }}>
                               <a href = "#">
                                   {
                                   item.details.title.split(' ').length > 10 ? 
                                   //    ReactHtmlParser(this.state.selectedSpeaker.speaker_description.substring(0,700))
                                   item.details.title.split(' ').slice(0,10).join(' ')+' ...'
                                      :
                                    item.details.title
                                   }
                               </a>
                           </div>
                       </div>
                   </div>})}
                   {videos.length != numberOfVideos && videos.length >5 ?
                   <div class = "row">
                       <div class = "col-12">
                           <button class = "btn load-more telebtn-1"  onClick={()=>{
                               let remainVideos = videos.length - numberOfVideos
                               if(remainVideos > 5){
                                setnumberOfVideos(numberOfVideos + 5)
                               }
                               else{
                                   setnumberOfVideos(numberOfVideos+remainVideos)
                               }
                               
                           }}>
                               Load More 
                           </button>
                       </div>
                   </div>:null}
                   
               </div>
           </div>:null}
           
       </div>
   </div>
   
</section>
</>

    )
}
