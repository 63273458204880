import React from 'react' 
import Loader from 'react-loader-spinner'
import {Trans} from 'react-i18next'
export const ZoomInfo = (props)=>{
   const [showPassword,setShowPassword] = React.useState(false)
   const [password,setPassword] = React.useState(props.user.zoom_password)
   const [plainPassword,setPlainPassword] = React.useState(props.user.zoom_password)
   const [attempFirst,setFirstAttempt] = React.useState(1)
   const [afterSave,setAfterSave] = React.useState(1)

   React.useEffect(()=>{
       if(attempFirst == 1){
    showPasswordBtn("hide")
    setFirstAttempt(2)
       }
      if(props.user.zoom_password!=plainPassword && afterSave == 1){
          showPasswordBtn("hide")
          setAfterSave(2)
      }
   })
   const showPasswordBtn=(value)=>{
       if(value == "hide"){
           if(props.user.zoom_password != null){
               let hidePassword = props.user.zoom_password.split('')
               let encodePassword = hidePassword.map(_=>'*')
               setPassword(encodePassword.join(''))
               setShowPassword(false)
           }
           else{
            setPassword(props.user.zoom_password)
           }
       }
       else{
           setPassword(props.user.zoom_password)
           setShowPassword(true)

       }

    //   setShowPassword(!showPassword)
   }
    return(
        <>
        <div class="card-header"><Trans>myZoom</Trans></div>
        <div class="card-body border-bottom rounded-0">
            {props.user.zoom_user_name != null || props.user.personal_zoom_meeting_link != null || props.user.zoom_password != null ?
            <form action = "">
                {props.user.zoom_user_name != null ? 
                <div class="form-group row pt-3">
                    <div class="col-md-4">
                        <label ><Trans>zoomUserName</Trans>:<span class="text-danger"></span></label>
                    </div>
                    <div class="col-md-8">
                    <p style={{fontWeight:"bold",fontSize:'14px'}}>{props.user.zoom_user_name == null ? props.user.firstName:props.user.zoom_user_name }</p>
                    </div>
                </div>
                :null} 

                {props.user.zoom_password != null ?<div class="form-group row">
                    <div class="col-md-4">
                        <label  for=""><Trans>zoomPassword</Trans>:<span class="text-danger"></span></label>
                    </div>
                    <div class="col-md-8" style={{display:"flex",columnGap:"10px"}}>
                    <p style={{fontWeight:"bold",fontSize:'14px'}}>{password}</p>
                    {showPassword == false ? 
                    <span onClick={()=>{
                        showPasswordBtn("show")
                    }}><i class="fas fa-eye"></i></span> :
                    <span onClick={()=>{
                        showPasswordBtn("hide")
                    }}><i class="fas fa-eye-slash"></i></span>
}
                    </div>
                </div>:null}
                
               {props.user.personal_zoom_meeting_link != null ? <div class="form-group row">
                    <div class="col-md-4">
                        <label for=""><Trans>zoomPersonalLink</Trans>:<span class="text-danger"></span></label>
                    </div>
                    <div class="col-md-8">
                    <p style={{fontWeight:"bold",fontSize:'14px'}}>{props.user.personal_zoom_meeting_link}</p>
                    </div>
                </div>:null}
                
                <div style = {{display : 'flex',flexDirection : 'row'}}>  
                    {<div class="form-group">
                        <a style = {{pointerEvents : props.loading ? 'none' : 'auto'}} onClick = {props.onClickSaveZoomInfo} class="btn bg_main btn-tele text-light px-5 rounded-0">
                            <Trans>edit</Trans></a>
                    </div>}
                    {props.loading ?
                    <div style = {{marginTop : 6,marginLeft : 12}}>
                        <Loader
                            type = 'Oval'
                            color = "#00b6ff"
                            height = {30}
                            width = {30}
                        />
                    </div> : null}
                </div>
                {props.changePassError != '' ?
                    <div className="alert alert-danger contactUsAlert">{props.changePassError}</div>:null}
                {props.successMessage != '' ?
                    <div className="alert alert-success contactUsAlert">{props.successMessage}</div>:null}
            
            </form>:<div style={{display:'flex',padding:'10px',justifyContent:'center',alignItems:'center',flexDirection:'column'}}><h5>No Info</h5>
            <div class="form-group">
                        <a style = {{pointerEvents : props.loading ? 'none' : 'auto'}} onClick = {props.onClickSaveZoomInfo} class="btn bg_main btn-tele text-light px-5 rounded-0">
                            <Trans>edit</Trans></a>
                    </div>
            </div>}
        </div>
        </>
    )
}