import React from 'react';
import axios from 'axios';
import {Constants} from '../../Constants';
import utility,{jobBoardNavigator } from '../../Utility/utility'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import api from './api';
import hexToRgba from 'hex-to-rgba';
import ContentLoader from 'react-content-loader'
import { HighLightsDataLoaded } from '../Schedule/actions';
import {connect} from 'react-redux'
export class Agenda extends React.Component{
	_isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            event: this.props.event,
			event_alias : this.props.event_alias,
			type : this.props.type,
			feature : {},
			showContent : false,
			backgroundColor : "",
			loading : true,
			EventHighlights:[],
			isLoading:false,
			title:'Highlights'
		};
	this.user = utility.getLoggedInUser(this.props.event_alias)
    }

	componentToHex = (c) => {
		var hex = c.toString(16);
		return hex.length == 1 ? "0" + hex : hex;
	}
    componentDidMount(){
		setTimeout(() => {
			let style = getComputedStyle(document.querySelector('.bg_primary'))
			var backgroundColor = style.backgroundColor
			var rgb = backgroundColor.match(/\d+/g);
			backgroundColor = "#" + this.componentToHex(parseInt(rgb[0])) + this.componentToHex(parseInt(rgb[1])) + this.componentToHex(parseInt(rgb[2]));
			this.setState({backgroundColor : backgroundColor})
		}, 0);
		
		
		// console.log('val :',this.backgroundColor)
		this._isMounted = true
		if(this.props.EventLoading != this.state.isLoading){
            this.setState({isLoading:this.props.EventLoading})
        }
        if(this.props.Eventhighlights.length>0){
            if(this.props.Eventhighlights[0].hasOwnProperty('highlights')){
                this.setState({EventHighlights:this.props.Eventhighlights[0].highlights.items,title:this.props.Eventhighlights[0].highlights.title})
                // this.SetImages(nextProps.EventGallery[0].gallery)

            }
        }
		// if(this.props.highlights != null){
		// 	let showContent = false
		// 	if(this.props.highlights.features.length > 0){
		// 		showContent = true
		// 	}
		// 	this.setState({feature : this.props.highlights,showContent,loading : false})
		// 	return
		// }
        // api.getEventFeatures(this.state.event.alias,this.state.type,null,(err,data)=>{
        //     if(err == null && this._isMounted){
		// 		if(data.feature != null){
		// 			console.log(data.feature)
		// 			this.props.highlightsLoaded(data.feature)
		// 			this.setState({feature:data.feature},()=>{
		// 				if(data.feature.features.length > 0){
		// 					this.setState({showContent : true})
		// 				}
		// 			});
		// 		}
                
		// 	}
		// 	this.setState({loading : false})
        // })
	}
	componentWillReceiveProps(nextProps){
		if(nextProps.EventLoading != this.state.isLoading){
            this.setState({isLoading:nextProps.EventLoading})
        }
        if(nextProps.Eventhighlights.length>0){
            if(nextProps.Eventhighlights[0].hasOwnProperty('highlights')){
                this.setState({EventHighlights:nextProps.Eventhighlights[0].highlights.items,title:nextProps.Eventhighlights[0].highlights.title})
                // this.SetImages(nextProps.EventGallery[0].gallery)

            }
        }

	}
	OnClickHighLightCard = () => {
		this.props.onClickCard()
	}
	
	componentWillUnmount(){
		this._isMounted = false
	}
	render(){
		if(this.state.isLoading){
			return (
				<ContentLoader style = {{padding : 5}} viewBox="0 0 380 50">
					{/* Only SVG shapes */}    
					<rect x="100" y="0" rx="5" ry="5" width="50" height="50" />
					<rect x="160" y="0" rx="5" ry="5" width="50" height="50" />
					<rect x="220" y="0" rx="5" ry="5" width="50" height="50" />
				</ContentLoader>
			)
		}
		const feature = this.state.EventHighlights;
		if(this.state.isLoading == false){
			if(feature && feature.length>0){
			return(
				<section style = {{backgroundColor :  hexToRgba(this.state.backgroundColor,0.3)}} className = "Ag_hig py-3">
					<div className="container">

						<h5 className = "text-center font-weight-bold text-uppercase">{this.state.title}</h5>
						<div className="row mt-3 justify-content-center">
							{
								feature ?
									feature.map((feature,index)=>{
										// if(feature.isActive == 1){
											return (

												<div className="col-md-6 col-lg-3 p-2 agenda_detail" style={{pointerEvents:feature.button_link == null ? 'none':'auto'}} key={index}>
													<div onClick = {()=>{
														// window.location.replace(feature.button_link);
														jobBoardNavigator(process.env.REACT_APP_JOBFAIR_REDIRECT_URL,feature.button_link,this.props.event_alias,'_self',this.user,new URL(feature.button_link).pathname)
													}} style = {{cursor : 'pointer'}}  className = "highlightCard text-left py-4 px-3 rounded h-100">
														<span style = {{display : 'none'}}  className="social-icons aganda_icon"><i className="fas fa-star"></i></span>
														<h5 className = "text-capitalize font-weight-bold mt-3">{feature.title}</h5>
														<p style = {{overflowWrap : 'break-word'}}>{ReactHtmlParser(feature.description)}</p>
													</div>
												</div>
											

											);
										// }

									}) : null
							}
						</div>
					</div>
				</section>
			);
						}else{
							return null 
						}
		}else{
			return null
		}

    }
}
const mapStateToProps = state => ({
    highlights: state.agenda.highlights,
	Eventhighlights:state.appData.allFeature,
    EventLoading:state.appData.featureLoading
})
const mapDispatchToProps = dispatch => ({
    highlightsLoaded : data => dispatch(HighLightsDataLoaded(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Agenda);