import React from 'react';
import Header from '../../Header/Index';
import Content from './content';
import { withRouter } from 'react-router-dom'
import Footer1 from '../../Footer/Footer1';
import Footer2 from '../../Footer/Footer2';
import queryString from 'query-string'
import api from '../api';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
class PaymentSuccessDummy extends React.Component{
    constructor(props){
        super(props)
        this.state = {
        }
    }
    componentDidMount(){
        var values = queryString.parse(window.location.search)
        this.token = values['transaction-token']
        localStorage.removeItem("isBookingPageShown")
        $(document).ready(function(){
            setTimeout(function(){
                $("#navigateButton").click();
            },1);
        
        });
    }
    OnNavigateToPage = (path) =>{
        window.scrollTo(0,0)
        if(path.indexOf('home') > -1){
            path = "/"
        }else if(path != '/'){
            path = "/"+path
        }
        this.props.history.push({
            pathname : path,
            state : {token : this.token}
        })
    }
    render (){
        return (
            <div id="navigateButton" onClick = {()=>this.OnNavigateToPage(this.props.event.alias+'/payment_success_page')}>test</div>
        )
    }
}
export default withRouter(PaymentSuccessDummy)
