import React from 'react';
import ReactDOM from 'react-dom';

import { Route, Link, BrowserRouter as Router ,Switch} from 'react-router-dom'
import App from '.';
import About from '../about-us';
import Contact from '../contact/index';
import Home from '../Home/index';
import Speakers from '../Speaker';
import TermsConditions from '../TermsConditions';
import PrivacyPolicy from '../PrivacyPolicy';
import CancellationPolicy from '../CancellationPolicy';
import Schedule from '../Schedule';
import SponserPage from '../SponserPage';
import BlogPage from '../BlogPage';
import Venue from '../Venue';
import Booking from '../Booking';
import ContactUs from '../ContactUs';
import Networking from '../Networking';
import PaymentSuccessInvoice from '../Booking/PaymentSuccessInvoice';
import Presentation from '../Presentation';
import Exibition from '../Exibition';
import Login from '../Login';
import Signup from '../Signup';
import Account from '../Account';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { LastLocationProvider } from 'react-router-last-location';
import PaymentSuccessOther from '../Booking/PaymentSuccessOthers';
import PaymentFailureOthers from '../Booking/PaymentFailureOthers';
import PaymentSuccessDummy from '../Booking/PaymentSuccessOthers/PaymentSuccessDummy';
import PaymentFailureDummy from '../Booking/PaymentSuccessOthers/PaymentFailureDummy';
import PanelistModeratorView from '../PanelistModeratorView';
import VideoChat  from '../TestVideoChat/videoChat';
import Participants  from '../1 - Sub menu components/Participants';
import UserChat from '../1 - Sub menu components/Chat';
import PageNotFound from '../404Component';
import VideoPage from '../VideoPage';
import Invitations from '../1 - Sub menu components/Invitation';
import VirtualExhibition  from '../1 - Sub menu components/Virtual Exhibition';
import VirtualExhibitionInner  from '../1 - Sub menu components/Virtual Exhibition/InnerPage/index';
import Blogs from '../1 - Sub menu components/Blogs/index';
import Gallery from '../1 - Sub menu components/Gallery/index';
import Lobby from '../1 - Sub menu components/Lobby';
import KnowedgeHub from '../marketplace/KnowledgeHub';
import { Constants } from '../../Constants';
import Membership from '../marketplace/Membership';
import MpForum from '../marketplace/Forum/index'
import utility from '../../Utility/utility';
import MarketNetworking from '../marketplace/Networking/index';
import MpVideos from '../marketplace/Videos'
import VideoContent from '../marketplace/Videos/content';
import MapFloorPlanHome from '../MapFloorPlan/index'
const Routes = (props)=>{
    function requireAuth(nextState, replace, next) {
        if (!utility.getLoggedInUser(props.event.alias)) {
          replace({
            pathname: "/"+props.event.alias+"/login",
            state: {nextPathname: nextState.location.pathname}
          });
        }
        next();
    }
    return(
        <Router>
            <LastLocationProvider>
            <Switch>
            <Route exact path="/">
                <Home 
                    event = {props.event} 
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    landingPage = {props.landingPage}
                    participants = {props.participants}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == "/home")!= null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/home").title) : null}
                />
            </Route>
            <Route exact path={"/:event_alias/home"}>
                <Home 
                    event = {props.event} 
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    landingPage = {props.landingPage}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    onClickAccountItem = {props.onClickAccountItem}
                    languageClick = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == "/home") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/home").title) : null}
                />
            </Route>
            {/*<Route path={"/"+props.headerMenuItems.find(x=>x.title == "About Us").href} >
                <About
                    event = {props.event} 
                    headerMenuItems = {props.headerMenuItems} 
                    footerMenuItems = {props.footerMenuItems}
                />
            </Route>*/}
            <Route path={"/"+props.event.alias+"/speakers"}>
                <Speakers
                    event = {props.event} 
                    alias = {props.event.alias}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/speakers") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/speakers").title) : null}
                />
            </Route>
            {Constants.showVirtualZone ? 
            <Route exact path={"/"+props.event.alias+"/virtual_exhibition/hall/:id"}>
                <VirtualExhibition
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            <Route exact path={["/"+props.event.alias+"/agenda",
            "/"+props.event.alias+"/agenda/:agendaSlug/session/:sessionSlug/:subSessionSlug",
            "/"+props.event.alias+"/agenda/:agendaSlug/session/:sessionSlug"]}>
                <Schedule
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    socket = {props.socket}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    participants = {props.participants}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    certificateCompletionTime = {props.certificateCompletionTime}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/agenda") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/agenda").title) : null}
                />
            </Route> 
            {Constants.showVirtualZone ? 
            <Route exact path={"/"+props.event.alias+"/virtual_exhibition/booth/:id"}>
                <VirtualExhibitionInner
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    participants = {props.participants}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    socket = {props.socket}
                    mySocketId = {props.mySocketId}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            <Route path={"/"+props.event.alias+"/partners"}>
                <SponserPage
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/partners") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/partners").title) : null}
                />
            </Route>


            <Route path="/terms-conditions">
                <TermsConditions
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                />
            </Route>

            <Route path="/privacy-policy">
                <PrivacyPolicy
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                />
            </Route>

            <Route path="/cancellation-policy">
                <CancellationPolicy
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                />
            </Route>
  
            <Route path="/blog/:blogTitle/:blogId">
                <BlogPage
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                />
            </Route>
            <Route exact path={"/"+props.event.alias+"/membership"}>
                <Membership
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    countries = {props.countries}
                    nationalities = {props.nationalities}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/booking") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/booking").title) : null}
                />
            </Route>
             {/* Market Place Routes */}
             <Route  exact path={"/"+props.event.alias+"/knowledge-hub"}>
            <KnowedgeHub
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/knowledge-hub")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/knowledge-hub").title) : null}
                />
            </Route>
            <Route  exact path={"/video-content"}>
            <VideoContent
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/video-content")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/video-content").title) : null}
                />
            </Route>
            <Route  exact path={"/"+props.event.alias+"/blogs"}>
            <Blogs
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                socket = {props.socket}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/blogs")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/blogs").title) : null}
                />
            </Route>
            <Route  exact path={"/"+props.event.alias+"/gallery"}>
            <Gallery
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                socket = {props.socket}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/gallery")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/gallery").title) : null}
                />
            </Route>
            <Route  exact path={"/"+props.event.alias+"/mp-networking"}>
            <MarketNetworking
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/mp-networking")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/mp-networking").title) : null}
                />
            </Route>
            <Route  exact path={"/"+props.event.alias+"/mp-videos"}>
            <MpVideos
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/mp-videos")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/mp-videos").title) : null}
                />
            </Route>
            <Route  exact path={"/"+props.event.alias+"/forum"}>
            <MpForum
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                socket = {props.socket}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/forum")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/forum").title) : null}
                />
            </Route>
            <Route  exact path={"/"+props.event.alias+"/virtual-floor-plan"}>
            <MapFloorPlanHome
                event = {props.event} 
                headerMenuItems = {props.headerMenuItems}
                footerMenuItems = {props.footerMenuItems}
                landingPage = {props.landingPage}
                socket = {props.socket}
                participants = {props.participants}
                unReadCount = {props.unReadMessageCount}
                unReadNotificationsCount = {props.unReadNotificationsCount}
                notifications = {props.allNotifications}
                onNotificationSeen = {props.onNotificationSeen}
                onClearNotifications = {props.onClearNotifications}
                virtualMenuItems = {props.virtualMenuItems}
                availableLocales = {props.availableLocales}
                languageClick = {props.languageClick}
                onClickAccountItem = {props.onClickAccountItem}
                seoData = {
                    props.seoDefaultData == null ? [] : 
                    props.allPages.find(x=>x.href == "/virtual-floor-plan")!= null ?
                    props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == "/virtual-floor-plan").title) : null}
                />
            </Route>
            <Route exact path={"/"+props.event.alias+"/:page/:title/:id"}>
                <Contact
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                />
            </Route>

            <Route path={"/"+props.event.alias+"/venue"}>
                <Venue
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/venue") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/venue").title) : null}
                />
            </Route>

            <Route path={"/"+props.event.alias+"/booking"}>
                <Booking
                    event = {props.event}
                    countries = {props.countries}
                    nationalities = {props.nationalities}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/booking") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/booking").title) : null}
                />
            </Route>

            <Route path={"/"+props.event.alias+"/contact_us"}>
                <ContactUs
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/contact_us") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/contact_us").title) : null}
                />
            </Route>

            <Route path={"/"+props.event.alias+"/payment_success"}>
                <PaymentSuccessDummy
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
    
                />
            </Route>

            <Route path={["/"+props.event.alias+"/payment_success_page","/"+props.event.alias+"/booking_success"]}>
                <PaymentSuccessOther
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    onFreeBookingSuccess = {props.onFreeBookingSuccess}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                />
            </Route>
            <Route path={"/"+props.event.alias+"/payment_failure"}>
                <PaymentFailureDummy
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route>
            <Route path={["/"+props.event.alias+"/payment_failure_page","/"+props.event.alias+"/booking_failure"]}>
                <PaymentFailureOthers
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                />
            </Route>
            <Route path={"/"+props.event.alias+"/presentations"}>
                <Presentation
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onClickAccountItem = {props.onClickAccountItem}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/presentations") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/presentations").title) : null}
                />
            </Route>
            <Route path={"/"+props.event.alias+'/exhibition'}>
                <Exibition
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/exhibition") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/exhibition").title) : null}
                />
            </Route>
            
            {/* {process.env.REACT_APP_ENV_NAME !== "production" ?  */}
            <Route path={"/"+props.event.alias+'/login'} >
                <Login
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    onLoginSuccess = {props.onUserLoginSuccess}
                    availableLocales = {props.availableLocales}
                    virtualMenuItems = {props.virtualMenuItems}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> 
            {/* : null} */}
            {/* {process.env.REACT_APP_ENV_NAME !== "production" ?  */}
            <Route path={"/"+props.event.alias+'/sign_up'}>
                <Signup
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    onSignUpSuccess = {props.onUserLoginSuccess}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                />
            </Route> 
             {/* : null} */}
            <Route path={"/"+props.event.alias+'/account'}>
                <Account
                    event = {props.event}
                    countries = {props.countries}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route>
            <Route path={"/"+props.event.alias+"/networking"}>
                <Networking
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                    seoData = {
                        props.seoDefaultData == null ? [] : 
                        props.allPages.find(x=>x.href == props.event.alias+"/networking") != null ?
                        props.seoDefaultData.find(x=>x.title == props.allPages.find(x=>x.href == props.event.alias+"/networking").title) : null}
                />
            </Route>
            <Route path={"/panelistModeratorView"}>
                <PanelistModeratorView 
                    event = {props.event}
                    socket = {props.socket}
                />
            </Route>
            {Constants.showVirtualZone ? 
            <Route path={"/videoChatTest"}>
                <VideoChat 
                    event = {props.event}
                />
            </Route> : null}
            {Constants.showVirtualZone ? 
            <Route path={"/"+props.event.alias+"/participants"}>
                <Participants 
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    loading = {props.loadingParticipants}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    allInvites = {props.allInvites}
                    socket = {props.socket}
                    onAddNewInvite = {props.onAddNewInvite}
                    virtualMenuItems = {props.virtualMenuItems}
                    onlineUsersCount = {props.onlineUsersCount}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            {Constants.showVirtualZone ? 
            <Route path={"/"+props.event.alias+"/userChat"}>
                <UserChat 
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    loadingList = {props.loadingChatList}
                    loadingParticipants = {props.loadingParticipants}
                    unReadCount = {props.unReadMessageCount}
                    unReadMessages = {props.unReadMessages}
                    socket = {props.socket}
                    mySocketId = {props.mySocketId}
                    markChatRead = {props.onMarkChatRead}
                    receivedMessagesWhileAway = {props.receivedMessagesWhileAway}
                    onChatListUpdated = {props.onChatListUpdated}
                    onUpdateChatList = {props.onUpdateChatList}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    onResetUnReadCount = {props.onResetUnReadCount}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            {Constants.showVirtualZone ? 
            <Route path={"/"+props.event.alias+"/invitations"}>
                <Invitations 
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    allInvites = {props.allInvites}
                    virtualMenuItems = {props.virtualMenuItems}
                    loadingInvites = {props.loadingInvites}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            <Route path={"/"+props.event.alias+"/videos"}>
                <VideoPage 
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route>
            {Constants.showVirtualZone ? 
            <Route exact path={"/"+props.event.alias+"/virtual_exhibition"}>
                <VirtualExhibition 
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            {/* {Constants.showVirtualZone ? 
            <Route exact path={"/"+props.event.alias+"/virtual_exhibition/hall/:id"}>
                <VirtualExhibition
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null} */}
            {/* {Constants.showVirtualZone ? 
            <Route exact path={"/"+props.event.alias+"/virtual_exhibition/booth/:id"}>
                <VirtualExhibitionInner
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    participants = {props.participants}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    socket = {props.socket}
                    mySocketId = {props.mySocketId}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null} */}
            {Constants.showVirtualZone ?
            <Route path={"/"+props.event.alias+"/lobby"}>
                <Lobby 
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    unReadCount = {props.unReadMessageCount}
                    unReadNotificationsCount = {props.unReadNotificationsCount}
                    notifications = {props.allNotifications}
                    onNotificationSeen = {props.onNotificationSeen}
                    onClearNotifications = {props.onClearNotifications}
                    virtualMenuItems = {props.virtualMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                    onClickAccountItem = {props.onClickAccountItem}
                />
            </Route> : null}
            <Route exact path="/linkedin" component={LinkedInPopUp} />
            <Route >
                <PageNotFound
                    event = {props.event}
                    headerMenuItems = {props.headerMenuItems}
                    footerMenuItems = {props.footerMenuItems}
                    availableLocales = {props.availableLocales}
                    languageClick  = {props.languageClick}
                />
            </Route>
            </Switch>
            </LastLocationProvider>
        </Router>
    )
}
export default Routes