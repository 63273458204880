
import React from 'react';
import $,{data} from 'jquery'
import ReactHtmlParser from 'react-html-parser';
import { Trans } from 'react-i18next';
import './loginPopup.css'
export const LoginPopup = (props)=>{
    return(
        <div class="modal" id="loginRequired" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content text-center login-req">
                <button style={{position:"absolute",width:'40px',height:"40px",border:"none",background:"lightgray",borderRadius:"50%",right:'3%',top:"3%",display:"flex",justifyContent:"center",alignItems:"center",color:"white",fontWeight:"bold",cursor:"pointer",zIndex:"50"}} onClick = {()=>{
                    $("#loginRequired").modal("hide");
                    $(".modal-backdrop").remove();
                    props.RemoveScroll(0)
                }}>X</button>
                    <div class="modal-body p-3 p-md-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33.018" height="46.526" viewBox="0 0 33.018 46.526"><defs>
                            </defs><g transform="translate(-5.625 -1.125)">
                                <path class="a" d="M5.625,21.753a6,6,0,0,1,6-6H32.64a6,6,0,0,1,6,6V36.762a6,6,0,0,1-6,6H11.628a6,6,0,0,1-6-6Z"
                                transform="translate(0 4.886)"/>
                                <path class="b" d="M10.125,11.631a10.506,10.506,0,1,1,21.012,0v9.005h-3V11.631a7.5,7.5,0,1,0-15.008,0v9.005h-3Z" 
                                transform="translate(1.503 0)"/>
                                </g>
                        </svg>
                            <h1>{props.title}</h1>
                            {props.description}
                            <div class="btn-row mt-3 mt-md-4">
                                <ul class="list-inline">
                                    <li class="list-inline-item mx-3 telehealth-btn mt-2">
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickRedirect("/login")} class="btn"><Trans>login</Trans></a>
                                    </li>
                                    <li class="list-inline-item mx-3 telehealth-btn mt-2">
                                        <a style = {{cursor : 'pointer'}} onClick = {()=>props.onClickRedirect("/sign_up")} class="btn"><Trans>signUp</Trans></a>
                                    </li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}