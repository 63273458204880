import React,{useState,useEffect} from 'react'
export default function RoundCircle(props){
    const [selectedSeat,setSelectedSeat] = useState(-1)
    const OnClickSeat = (index) => {
        if(props.bookedSeats.indexOf(index) < 0 && !props.disabled){
            props.onSeatSelected(index,props.tableIndex)
            // setSelectedSeat(index)
        }
    }
    return(
        <div class="round-table rounded-circle">
            <svg class = "spk-chair" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 262.818 252">
                <g transform="translate(-626.491 -1810)">
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(0)} class ={props.bookedSeats.indexOf(0) > -1 ? "svg-pth active" : props.selectedSeat == 0 ? "svg-pth selected":"svg-pth"} d="M14.277,46.3,1.088,31.95a4.212,4.212,0,0,1,.278-5.929,4.265,4.265,0,0,1,1.627-.931,4.576,4.576,0,0,1-1.581-1.12A4.262,4.262,0,0,1,1.609,18L20.058,1.038a4.3,4.3,0,0,1,6.923,1.975,4.33,4.33,0,0,1,1.086-1.55,4.222,4.222,0,0,1,5.939.229L47.2,16.04a4.153,4.153,0,1,1-6.115,5.62L27.894,7.312A4.56,4.56,0,0,1,26.853,5.66a4.419,4.419,0,0,1-1.176,1.491L7.228,24.108a4.354,4.354,0,0,1-1.586,1.045A4.611,4.611,0,0,1,7.2,26.33L20.391,40.679A4.152,4.152,0,1,1,14.277,46.3ZM7.912,25.216,26.722,7.926,40.122,22.5,21.312,39.794Z" transform="translate(658.062 1824.689)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(1)} class ={props.bookedSeats.indexOf(1) > -1 ? "svg-pth active" : props.selectedSeat == 1 ? "svg-pth selected":"svg-pth"} d="M25.456,48.624,6.809,42.957a4.22,4.22,0,0,1-1.84-6.878,4.581,4.581,0,0,1-1.933-.162A4.262,4.262,0,0,1,.175,30.67L7.462,6.7a4.294,4.294,0,0,1,6.967-1.812A4.338,4.338,0,0,1,14.578,3,4.221,4.221,0,0,1,19.812.179L38.458,5.846a4.152,4.152,0,1,1-2.414,7.946L17.4,8.123a4.57,4.57,0,0,1-1.736-.9,4.4,4.4,0,0,1-.256,1.882L8.118,33.084a4.372,4.372,0,0,1-.835,1.707,4.571,4.571,0,0,1,1.941.223L27.87,40.68a4.15,4.15,0,0,1-1.185,8.128A4.22,4.22,0,0,1,25.456,48.624ZM9.269,33.692,16.7,9.246,35.644,15l-7.43,24.446Z" transform="translate(628.229 1874.13)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(2)} class ={props.bookedSeats.indexOf(2) > -1 ? "svg-pth active" : props.selectedSeat == 2 ? "svg-pth selected":"svg-pth"} d="M12.9,45.043a4.259,4.259,0,0,1-.027-1.876,4.556,4.556,0,0,1-1.745.841,4.262,4.262,0,0,1-5.13-3.067L.1,16.585a4.295,4.295,0,0,1,5.084-5.1,4.34,4.34,0,0,1-.83-1.7A4.223,4.223,0,0,1,7.435,4.7L26.378.119A4.152,4.152,0,1,1,28.331,8.19L9.388,12.771a4.578,4.578,0,0,1-1.95.111,4.394,4.394,0,0,1,.735,1.75l5.89,24.356a4.37,4.37,0,0,1,.147,1.894A4.573,4.573,0,0,1,16,40.089l18.942-4.583a4.152,4.152,0,1,1,1.953,8.071L17.947,48.16a4.108,4.108,0,0,1-.968.116A4.228,4.228,0,0,1,12.9,45.043ZM9.357,14.094,28.6,9.439,34.61,34.272,15.364,38.927Z" transform="translate(627 1933.25)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(3)} class ={props.bookedSeats.indexOf(3) > -1 ? "svg-pth active" : props.selectedSeat == 3 ? "svg-pth selected":"svg-pth"} d="M26.583,46.735a4.256,4.256,0,0,1-.974-1.6,4.584,4.584,0,0,1-1.078,1.61,4.261,4.261,0,0,1-5.976-.039L1.115,28.71a4.294,4.294,0,0,1,1.792-6.972,4.312,4.312,0,0,1-1.579-1.046A4.222,4.222,0,0,1,1.4,14.748l14-13.563a4.152,4.152,0,1,1,5.779,5.963L7.179,20.711A4.58,4.58,0,0,1,5.555,21.8a4.406,4.406,0,0,1,1.522,1.135L24.515,40.925A4.369,4.369,0,0,1,25.6,42.482a4.6,4.6,0,0,1,1.135-1.59L40.733,27.33a4.152,4.152,0,1,1,5.779,5.963L32.517,46.857a4.138,4.138,0,0,1-2.891,1.163A4.229,4.229,0,0,1,26.583,46.735ZM7.824,21.866,22.042,8.086,39.824,26.432,25.605,40.213Z" transform="translate(653.618 1984.943)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(4)} class ={props.bookedSeats.indexOf(4) > -1 ? "svg-pth active" : props.selectedSeat == 4 ? "svg-pth selected":"svg-pth"} d="M31.148,40.69,6.989,34.039a4.3,4.3,0,0,1-2-6.917,4.324,4.324,0,0,1-1.89-.1A4.221,4.221,0,0,1,.148,21.866L5.321,3.075a4.152,4.152,0,1,1,8.006,2.2L8.154,24.069a4.591,4.591,0,0,1-.848,1.759,4.4,4.4,0,0,1,1.887.206l24.159,6.652a4.356,4.356,0,0,1,1.727.789,4.586,4.586,0,0,1,.171-1.945l5.173-18.79a4.152,4.152,0,1,1,8.005,2.2l-5.173,18.79a4.212,4.212,0,0,1-5.175,2.908,4.282,4.282,0,0,1-1.653-.886,4.574,4.574,0,0,1-.11,1.935,4.3,4.3,0,0,1-4.1,3.145A4.02,4.02,0,0,1,31.148,40.69ZM9.295,24.737,14.551,5.647l24.633,6.782-5.255,19.09Z" transform="translate(703.944 2020.573)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(5)} class ={props.bookedSeats.indexOf(5) > -1 ? "svg-pth active" : props.selectedSeat == 5 ? "svg-pth selected":"svg-pth"} d="M14.107,40.086a4.264,4.264,0,0,1-2.036-4.45,4.349,4.349,0,0,1-1.679.873,4.221,4.221,0,0,1-5.164-2.943L.147,14.751a4.152,4.152,0,1,1,8.016-2.166L13.244,31.4a4.617,4.617,0,0,1,.163,1.947,4.362,4.362,0,0,1,1.729-.782l24.191-6.532a4.387,4.387,0,0,1,1.89-.2,4.578,4.578,0,0,1-.839-1.763L35.295,5.26a4.152,4.152,0,1,1,8.018-2.165l5.08,18.815a4.22,4.22,0,0,1-4.856,5.208,4.578,4.578,0,0,1,.887,1.723,4.263,4.263,0,0,1-2.932,5.209L17.3,40.582a3.7,3.7,0,0,1-.965.126A4.525,4.525,0,0,1,14.107,40.086ZM9.4,12.281,34.07,5.62l5.162,19.116L14.566,31.4Z" transform="translate(762.845 2020.792)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(6)} class ={props.bookedSeats.indexOf(6) > -1 ? "svg-pth active" : props.selectedSeat == 6 ? "svg-pth selected":"svg-pth"} d="M15.192,46.786,1.265,33.154a4.152,4.152,0,1,1,5.807-5.934L21,40.851a4.606,4.606,0,0,1,1.127,1.594,4.379,4.379,0,0,1,1.094-1.552L40.749,22.988a4.363,4.363,0,0,1,1.528-1.129,4.576,4.576,0,0,1-1.619-1.093L26.73,7.134A4.152,4.152,0,1,1,32.538,1.2L46.466,14.832a4.219,4.219,0,0,1-1.542,6.951,4.552,4.552,0,0,1,1.639,1.034,4.263,4.263,0,0,1,.12,5.976L29.157,46.7A4.294,4.294,0,0,1,22.14,45.1a4.345,4.345,0,0,1-1,1.606,4.224,4.224,0,0,1-3.025,1.266A4.156,4.156,0,0,1,15.192,46.786ZM7.986,26.326,25.858,8.067l14.15,13.85L22.137,40.176Z" transform="translate(814.055 1985.364)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(7)} class ={props.bookedSeats.indexOf(7) > -1 ? "svg-pth active" : props.selectedSeat == 7 ? "svg-pth selected":"svg-pth"} d="M22.1,48.205,3.181,43.53A4.152,4.152,0,1,1,5.173,35.47l18.921,4.674a4.608,4.608,0,0,1,1.78.8,4.39,4.39,0,0,1,.155-1.892L32.04,14.728a4.377,4.377,0,0,1,.743-1.748,4.579,4.579,0,0,1-1.949-.119L11.915,8.186A4.152,4.152,0,1,1,13.906.124L32.827,4.8a4.21,4.21,0,0,1,3.043,5.1,4.26,4.26,0,0,1-.842,1.675,4.558,4.558,0,0,1,1.938.06A4.262,4.262,0,0,1,40.1,16.719l-6.01,24.326a4.294,4.294,0,0,1-6.861,2.176,4.344,4.344,0,0,1-.05,1.893,4.236,4.236,0,0,1-4.088,3.211A4.117,4.117,0,0,1,22.1,48.205ZM5.507,34.236l6.129-24.8,19.223,4.75-6.129,24.8Z" transform="translate(848.58 1933.758)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(8)} class ={props.bookedSeats.indexOf(8) > -1 ? "svg-pth active" : props.selectedSeat == 8 ? "svg-pth selected":"svg-pth"} d="M10.591,45.807a4.157,4.157,0,0,1,2.815-5.173l18.674-5.577a4.608,4.608,0,0,1,1.941-.213,4.4,4.4,0,0,1-.826-1.708L26.026,9.126a4.4,4.4,0,0,1-.247-1.883,4.586,4.586,0,0,1-1.739.885L5.365,13.706A4.152,4.152,0,1,1,2.99,5.749L21.664.172a4.213,4.213,0,0,1,5.209,2.847A4.26,4.26,0,0,1,27,4.89a4.549,4.549,0,0,1,1.7-.932A4.261,4.261,0,0,1,33.981,6.75L41.152,30.76a4.293,4.293,0,0,1-4.807,5.358,4.317,4.317,0,0,1,.919,1.656,4.221,4.221,0,0,1-2.806,5.24L15.782,48.591a4.223,4.223,0,0,1-1.209.178A4.147,4.147,0,0,1,10.591,45.807ZM5.758,14.921,24.731,9.255l7.311,24.482L13.068,39.4Z" transform="translate(846.45 1874.676)" fill="#60d98d" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <path opacity = {props.disabled ? 0.5 : 1} onClick = {()=>OnClickSeat(9)} class ={props.bookedSeats.indexOf(9) > -1 ? "svg-pth active" : props.selectedSeat == 9 ? "svg-pth selected":"svg-pth"} d="M28.007,46.613a4.157,4.157,0,0,1-.2-5.885L41.067,26.444a4.575,4.575,0,0,1,1.564-1.169,4.393,4.393,0,0,1-1.58-1.053L22.686,7.174a4.39,4.39,0,0,1-1.169-1.5A4.582,4.582,0,0,1,20.47,7.323L7.21,21.608a4.152,4.152,0,1,1-6.087-5.65L14.384,1.675a4.212,4.212,0,0,1,5.933-.192,4.262,4.262,0,0,1,1.057,1.549,4.547,4.547,0,0,1,.99-1.666,4.265,4.265,0,0,1,5.972-.277L46.7,18.136a4.294,4.294,0,0,1-1.42,7.058,4.317,4.317,0,0,1,1.631.961,4.22,4.22,0,0,1,.243,5.938L33.893,46.377a4.178,4.178,0,0,1-3.069,1.344A4.126,4.126,0,0,1,28.007,46.613ZM8.166,22.456,21.637,7.944,40.362,25.325,26.891,39.837Z" transform="translate(809.938 1825.031)" fill="#60d98d" stroke="rgba(3,3,3,0)" stroke-miterlimit="10" stroke-width="1"/>
                    <text transform="translate(726 1825)" font-size="14" font-family="OpenSans, Open Sans">
                        <tspan x="0" y="0">Book Seat</tspan>
                    </text>
                    <circle cx="6.5" cy="6.5" r="6.5" transform="translate(725 1926)" fill="#fb676a"/>
                        <text transform="translate(746 1937)" font-size="12" font-family="OpenSans, Open Sans">
                            <tspan x="0" y="0">Booked</tspan>
                        </text>
                    <circle cx="6.5" cy="6.5" r="6.5" transform="translate(725 1950)" fill="#0351c5"/>
                        <text transform="translate(746 1961)" font-size="12" font-family="OpenSans, Open Sans">
                            <tspan x="0" y="0">Selected</tspan>
                        </text>
                    <ellipse cx="6.5" cy="6" rx="6.5" ry="6" transform="translate(725 1902)" fill="#60d98d"/>
                    <text transform="translate(746 1912)" font-size="12" font-family="OpenSans, Open Sans">
                        <tspan x="0" y="0">Available</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}