import axios from '../axios/index'
import { Constants } from '../../Constants';

export default {
    getEventContactPerson(eventAlias,callback){

        axios.get(`${eventAlias}/getEventContactPersons`,
            {
                params: {isActive:1,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },

    getEventContactForm(eventAlias,callback){

        axios.get(`${eventAlias}/getEventContactForm`,
            {
                params: {isActive:1,isPreview : Constants.isPreview},
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN}
            }).then(response => {
            callback(null,response.data)
        }).catch(error => {
            callback(error,null)
        })
    },

    sendEventContactForm(eventAlias,data,callback){

        const requestBody = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message,
            isActive: 1
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'apiToken': Constants.API_TOKEN
            }
        }

        axios.post(`${eventAlias}/sendEventContactMasg`, requestBody, config)
            .then(response => {
                callback(null,response.data)
            }).catch(error => {
            callback(error,null)
        })



    }

}