const bookings = (state = {delegates : null,orderId : 0}, action) => {
    switch (action.type) {
        case 'BOOKING_DATA_LOADED' : 
            console.log(action.payload)
            return{
            ...state,
            bookingData : action.payload
        }
        case 'UPDATE_DELEGATES' : 
            return{
            ...state,
            delegates : action.payload
        }
        case 'UPDATE_ORDERID' : 
         return{
             ...state,
             orderId : action.payload
         }
        default:
            return state
        }
  }
  export default bookings