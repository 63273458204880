import React,{useEffect, useState} from 'react'
import './styles.css'
import $ from 'jquery'
import icon from './ic_chat_close_dark.svg'
import Loader from 'react-loader-spinner'
export default function EmailPop(props) {
   
	const [message,setMessage] = useState('')
	const [subject,setSubject] = useState('')
	const [isSendEmail,setIsEmail] = useState(false)
	const [mailStatus,setMailStatus] = useState(null)
	const [disableForm,setDisableForm] = useState(false)
    return (
        // <!--==== Send Email Modal ====-->
<div class={`modal show`} id="send-eamil-m" aria-modal="true" 
style={{display:`${props.modelShow ? "block":'none'}`}}
// style={{display:'block'}}

>
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content email-modal text-left">
		<div class = "modal-header px-4">
			<h6>
				Send Email
			</h6>
			<span class = "e-close" onClick={()=>{
                props.hideEmailDialog()
				setIsEmail(false)
				setMailStatus(null)
            }} data-dismiss="modal">
				<img src = {icon} alt = "icon"></img>
			</span>
		</div>
		  {/* <!-- Modal body --> */}
			<div class="modal-body px-4">
			
				<div  class={`row email-row ${isSendEmail == true && mailStatus != null  ? 'opacity-full':""}`}>
					<div class="col-12">
						<form class = "email-form" >
							<div class = "form-group">
								<label>Subject *</label>
								<input   type = "text"  disabled={disableForm == true  ? true :false} class = "form-control"  value = {subject} onChange={(e)=>{
									setSubject(e.target.value)
								}}  ></input>
							</div>
							
							<div class = "form-group">
								<label>Message *</label>
								<span class = "text-counter">
									<em class="jQTAreaCount"> {message.length} </em> / <em class="jQTAreaValue">{1000}</em>
								</span>
								<textarea maxlength={1000} disabled={disableForm == true ? true :false}  class = "form-control" onChange={(e)=>{
									setMessage(e.target.value)

									
								}}
								value = {message}></textarea>
							</div>
							
							<div class = "form-group">
								<input type = "submit" class = "form-control btn-send-eamil" style={{pointerEvents:(message != '' && subject != '') ? 'unset':'none'}} onClick={(e)=>{
									e.preventDefault()
									setIsEmail(true)
									setDisableForm(true)
									const params = {rep_id:props.rep_id,
										subject:subject,
										message:message}
									props.sendEmailToRep(params,props.rep_id,(data)=>{
                                        setSubject('')
									    setMessage('')
										setMailStatus(data.status)
										setDisableForm(false)
									})
									

								}}></input>
								{ isSendEmail==true && mailStatus ==null ?
								<div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%',marginTop:'10px'}}>
								<Loader
                    type = 'Oval'
                    color = "blue"
                    height = {30}
                    width = {30}
                />
								</div>:null}
							</div>
						</form>
					</div>
				</div>

			    {
				isSendEmail == true && mailStatus == 0    ? <div class = {`failure-msg ${isSendEmail == true && mailStatus == 0  ? '':'d-none'}`}>
					<div class = "icon-cross bg-danger">
						<i class="fas fa-times"></i>
					</div>
					<br/>
					<p>{props.message}</p>
					<div class = "try_again-btn py-2 px-3 text-center" onClick={()=>{
						setIsEmail(false)
						setMailStatus(null)
					}}>
						Try Again
					</div>
				</div>:null}
				
				{ isSendEmail == true && mailStatus == 1  ? <div class = {`success-msg ${ isSendEmail == true && mailStatus == 1  ? '':'d-none'}`}>
					<div class = "icon-tick bg-success">
						<i class="fas fa-check"></i>
					</div>
					<br/>
					<p>Email Sent</p>
					<div class = "ok-btn py-2 px-3 text-center d-none" data-dismiss="modal">
						OK
					</div>
				</div> :null }
			</div>

		</div>
	</div>
</div>
    )
}
