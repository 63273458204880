import React from 'react' 
import { MDBCollapse } from "mdbreact";
import $ from 'jquery'
import 'bootstrap'
import { Trans } from 'react-i18next';
export default class BookingDetailExtended extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            // collapseID : 'collapsibleNavbar'+props.defaultIndex
            collapseID : ""
        }
    }
    componentDidMount(){
        // $(document).ready(function(){
        //     $('#delegateHeader').click(function(){
        //         if(!$(this).hasClass('collapsed')){
        //             $(this).addClass('collapsed')
        //         }else{
        //             $(this).removeClass('collapsed')
        //         }
        //     })
        // })
    }
    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
        
    }
    render(){
        return(
            <>
            <div class="card-header">
                <div class = "row">
                    <div onClick = {this.props.onClickBack} style = {{cursor : 'pointer'}} class = "btn-back pl-4">
                        <i className = "fas fa-chevron-left" style = {{cursor : 'pointer'}}></i>
                    </div>
                    <div class = "pl-3">
                        {this.props.title}
                    </div>
                </div>
            </div>
            <div class="card-body border-bottom rounded-0"style = {{minHeight : 100}}>
            {this.props.orderSummary != null ?
            <div class = "row py-3 py-md-4 order-summary-new">
                <div class = "col-md-12">
                    <h4 class = "mb-3 hd-mainn"><Trans>orderSummary</Trans>:</h4>
                    
                        <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>event</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                            <h6>{this.props.orderSummary.event_name}</h6>
                            </div>
                        </div>
                        
                        <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>orderId</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                                <h6>{this.props.orderSummary.orderId}</h6>
                            </div>
                        </div>
                        {this.props.orderSummary.type != '-' && this.props.orderSummary.type != "free" ? 
                        <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>paymentType</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                            <h6>{this.props.orderSummary.type}</h6>
                            </div>
                        </div> : null}
                        {parseInt(this.props.orderSummary.total) > 0 ?
                        <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>paymentStatus</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                                <h6>{this.props.orderSummary.status}</h6>
                            </div>
                        </div> : null}
                        {parseInt(this.props.orderSummary.total) == 0 ?
                            <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>bookingStatus</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                                <h6><Trans>completed</Trans></h6>
                            </div>
                        </div> : null}
                        <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>ticketPrice</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                                {this.props.ticketInfo.price == 0 ? <h6><b><Trans>free</Trans></b></h6> :
                            <h6><b>{this.props.orderSummary.currency+( parseInt(this.props.ticketInfo.basePrice))}</b></h6>}
                            </div>
                        </div>
                        {parseInt(this.props.orderSummary.total) > 0 ?
                        <div class = "row px-3">
                            <div class = "col-md-4">
                                <h6><Trans>paidAmount</Trans>:</h6>
                            </div>
                            <div class = "col-md-6">
                            <h6><b>{this.props.orderSummary.currency+this.props.orderSummary.total}</b></h6>
                            </div>
                        </div> : null}
                </div>
            </div> : null}
            <h4 class = "mb-3 hd-mainn"><Trans>delegates</Trans></h4>
                {this.props.delegates.length == 0 ?
                <div style = {{position : 'absolute',left : '45%',top: '60%'}}>No Delegates</div>:
                <div id="accordion">
                    {this.props.delegates.map((item,index)=>{
                        return(
                        <div class = "card delegate_card">
                        <div class={this.state.collapseID == "collapsibleNavbar"+index ? "card-header" : "card-header collapsed"} id="delegateHeader" onClick = {this.toggleCollapse("collapsibleNavbar"+index)}>
                            <h6 class="mb-0">
                                <Trans>delegate</Trans> {index+1}
                            </h6>
                        </div>
                        <MDBCollapse id={"collapsibleNavbar"+index} isOpen={this.state.collapseID == "collapsibleNavbar"+index}>
                            <div class="card-body">
                            
                            <div class="table table-account border-0 table-responsive">
                                <table>
                                    <tr>
                                        <td><Trans>firstName</Trans>:</td>
                                        <td>{item.firstName}</td>
                                    </tr>
                                        <tr>
                                        <td><Trans>lastName</Trans>:</td>
                                        <td>{item.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td><Trans>email</Trans>:</td>
                                        <td>{item.emailWork}</td>
                                    </tr>
                                    <tr>
                                        <td><Trans>mobNumber</Trans>:</td>
                                        <td>{item.mobileNumber}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td><Trans>nationality</Trans>:</td>
                                        <td>{item.nationality}</td>
                                    </tr>
                                    <tr>
                                        <td><Trans>companyName</Trans>:</td>
                                        <td>{item.companyName}</td>
                                    </tr>
                                    <tr>
                                        <td><Trans>companyAddress</Trans>:</td>
                                        <td>{item.address1}</td>
                                    </tr>
                                    <tr>
                                        <td><Trans>city</Trans>:</td>
                                        <td>{item.city}</td>
                                    </tr>
                                    <tr>
                                        <td><Trans>country</Trans>:</td>
                                        <td>{item.country}</td>
                                    </tr>
                                    
                                </table>
                            </div>
                            
                            </div>
                        </MDBCollapse>
    
                    </div>
                        )
                    })}
                    
                </div>}
            </div>
            </>
        )
    }
}

    