import React from "react";
import $ from "jquery";
import "bootstrap";
import { MDBCollapse } from "mdbreact";
import Linkify from "react-linkify";
import { withRouter } from 'react-router-dom'
import "./index.css";
import { Constants } from '../../Constants';
import icon from './ic_chat_close_dark.svg'
import utility from '../../Utility/utility'
import {Trans} from 'react-i18next';
import {GARecordEvent } from '../../Utility/GA'
import api from '.././Account/api'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import {compose} from 'redux'
import {RemoveScroll} from '../1 - Sub menu components/Virtual Exhibition/actions/index'
import  PermiumUserModal  from "../modals/premiumUserModal";

export class VirtualZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemIndex: -1,
      menuToggle: false,
      showTime:false,
      startTime:'09:00 AM',
      endTime:'06:00 PM',
      chatAvailabaility:null,
      defaultEndTime:'06:00 PM',
      waitForResponse:false,
      textStatus:'',
      iconStatus:'',
      selectedIndex:null,
      support:this.props.menuItems.findIndex(item=>item.title=="Support")


    };
    this.innerLinkClicked = false;
    this.userType=""
    this.user= this.props.user
    this.userTime = ['01:00 AM',"02:00 AM","03:00 AM","04:00 AM","05:00 AM","06:00 AM","07:00 AM","08:00 AM","09:00 AM","10:00 AM","11:00 AM","12:00 PM",'01:00 PM',"02:00 PM","03:00 PM","04:00 PM","05:00 PM","06:00 PM","07:00 PM","08:00 PM","09:00 PM","10:00 PM","11:00 PM","12:00 AM"]
   
  }
  componentDidMount() {
    this.setState({chatAvailabaility:this.props.user.chatAvailabaility})
    if(this.props.user.chatAvailabailityDuration != null){
      var setTime = this.props.user.chatAvailabailityDuration.split('-')
      this.setState({showTime:true,startTime:setTime[0],endTime:setTime[1],iconStatus:utility.comparingUserSetTime(this.props.user.chatAvailabaility,this.props.user.chatAvailabailityDuration),textStatus:utility.checkUserStatus(this.props.user.chatAvailabaility,this.props.user.chatAvailabailityDuration),selectedIndex:this.userTime.indexOf(setTime[1])})
    }
    else{
    this.setCurrentStatus(this.props.user.chatAvailabaility)
    this.setCurrentStatusIcon(this.props.user.chatAvailabaility)
    }

    let url = window.location.href;
    this.setNotificationPopupTopPosition();
    //console.log("USER", this.user)
    //this.userType= localStorage.getItem(Constants.API_TOKEN+this.props.event.alias+'user_info');
    this.userType= this.props.user.boardType
    //console.log("VIRTUAL ZONE", this.userType)
    if(!this.props.user.jobFairDisabled){
      // $(".job-board").click(() => {
      //   if (!$(".job-board").hasClass("show")) {
      //     $(".job-board").addClass("show");
      //     $(".header_dropdown").removeClass("show");
  
      //   } else {
      //     $(".job-board").removeClass("show");
      //   }
      // });
    }

    $(".navbar-toggler-p").click(() => {
      $("#icon-change-b").toggleClass("fa fa-bars fas fa-times");
      // $("#collapsibleNavbarb").toggleClass("show hide")
      this.setState({ menuToggle: !this.state.menuToggle });
    });
    $(".notificationInnerLink").on("click", () => {
      this.innerLinkClicked = true;
      setTimeout(() => {
        this.innerLinkClicked = false;
      }, 0);
    });
    window.addEventListener("scroll", this.handleScroll, false);
    document.addEventListener(
      "eventLogoImageLoaded",
      this.handleOnEventLogoImageLoaded
    );
    this.navbar = document.getElementById("virtualStickyMenu");
    this.sticky = this.navbar.offsetHeight;
  }
  componentWillUnmount() {
    // document.getElementsByTagName("html")[0].style.overflowY = "scroll";
    // document.body.style.overflowY = "scroll"
    document.removeEventListener("mousedown", this.handleClickOutside)
    $(".header_dropdown").off(); //unbind() has been deprecated, off() is new addition
    $(".job-board").off();
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener(
      "eventLogoImageLoaded",
      this.handleOnEventLogoImageLoaded
    );
  }
  updateUserStatus = async()=>{
    this.setState({waitForResponse:true})
    const data = {
      chatAvailabaility:this.state.chatAvailabaility,
      chatAvailabailityDuration:this.state.showTime? this.state.startTime+'-'+this.state.endTime:null
    }
    var userdata = JSON.parse(localStorage.getItem(Constants.API_TOKEN+this.props.eventAlias+'user_info'))
    await api.updateAccount(this.props.eventAlias,this.props.user.token,data,(err,data)=>{

      if(data.status == 'success' && err == null){
        userdata.chatAvailabaility = data.user.chatAvailabaility
        userdata.chatAvailabailityDuration = data.user.chatAvailabailityDuration
        localStorage.setItem(Constants.API_TOKEN+this.props.eventAlias+'user_info',JSON.stringify(userdata)) 
        this.setState({iconStatus:utility.comparingUserSetTime(data.user.chatAvailabaility,data.user.chatAvailabailityDuration),textStatus:utility.checkUserStatus(data.user.chatAvailabaility,data.user.chatAvailabailityDuration)})
        // this.setCurrentStatus(data.user.chatAvailabaility)  
        // this.setCurrentStatusIcon(data.user.chatAvailabaility)     
        setTimeout(()=>{
          this.setState({waitForResponse:false})
          document.getElementById('close-btn').click()        
        },1000)
        let time = data.user.chatAvailabailityDuration.split('-')
        this.setState({startTime:time[0],endTime:time[1],chatAvailabaility:data.user.chatAvailabaility})

      }else{
        setTimeout(()=>{
          this.setState({waitForResponse:false})
          document.getElementById('close-btn').click()        
        },1000)
      } 
    })
  }
  setNotificationPopupTopPosition = () => {
    document.addEventListener("mousedown",this.handleClickOutside);
    if (window.outerHeight > 767) {
      $(document).ready(function () {
        function resizeContent() {
          var header = document.getElementById("header-top");
          if (header != null) {
            var header_height = header.offsetHeight + "px";
            $(".notification-modal").css({
              top: header_height,
            });
          }
        }
        resizeContent();
        $(window).resize(resizeContent);
      });
    }
  };
  OnClickAccountDropDown = () => {
    //console.log("Menu Job Board clicked")

    GARecordEvent("menuJobBoardClicked", this.user.id,{
      category : 'Menu Job Board',
      action : 'Menu job board clicked',
      userName: this.user.firstName+''+this.user.lastName,
      userEmail: this.user.email
        })
    this.dropDownClicked = true;
  };

  handleClickOutside = () => {
    setTimeout(() => {
      if (this.dropDownClicked) {
        this.dropDownClicked = false;
        return;
      }

      // if ($(".header_dropdown").hasClass("show")) {
      //   $(".header_dropdown").removeClass("show");
      // }
      if ($(".dropdown-account").hasClass("show")) {
        $(".dropdown-account").removeClass("show");
      }
      if ($(".job-board").hasClass("show")) {
        $(".job-board").removeClass("show");
      }
    }, 200);
  };

  handleScroll = () => {
    if (Constants.showVirtualZone) {
      if (document.documentElement.scrollHeight > 760) {
        if (window.pageYOffset >= 100) {
          this.navbar.classList.add("sticky");
        } else {
          if (window.pageYOffset <70) {
            this.navbar.classList.remove("sticky");
          }
        }
      } else {
        if (window.pageYOffset < 70) {
          this.navbar.classList.remove("sticky");
        }
      }
      // $(window).scroll(function(){ 
        // if (document.documentElement.scrollHeight > 750) { 
				// if (window.pageYOffset > this.sticky) {
        //   console.log(window.pageYOffset)
        //   console.log("Remove nahi ho rha ")
				//   $('#virtualStickyMenu').addClass("sticky");
          // this.navbar.classList.add("sticky")
				   // add padding top to show content behind navbar
				//    $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
				//  }
        //  if (window.pageYOffset < 40) {
        //   console.log(window.pageYOffset)
        //   console.log("Remove ho rha hy")
				//   $('#virtualStickyMenu').removeClass("sticky");
          //  this.navbar.classList.remove("sticky")
				   // add padding top to show content behind navbar
				//    $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
				//  }
        // } 
			//  }
      //  );

      
    }
  };
  HideOpenDrownDown = () => {
    // if($('.dropdown-account').hasClass('show')){
    //    $('.dropdown-account').removeClass('show')
    // }
    // if($('.header_dropdown').hasClass('show')){
    //    $('.header_dropdown').removeClass('show')
    // }
  };
  OnClickMenuItem = (path,index) => {
    this.props.onMenuItemClick(path);
  };
  handleOnEventLogoImageLoaded = (e) => {
    this.setNotificationPopupTopPosition();
  };

  jobBoardEvent = () => {

    //this.props.jobBoardEvent()
    
    //console.log("Menu Job Board clicked")

    GARecordEvent("menuJobBoardClicked", this.user.id,{
      category : 'Menu Job Board',
      action : 'Menu job board clicked',
      userName: this.user.firstName+''+this.user.lastName,
      userEmail: this.user.email
        })
    $("#disableUserModal").modal('show')
  }

  visitJobSeeker = ()=>{
    //this.props.visitJobSeeker()
    //console.log("Visit job Board clicked")

        GARecordEvent("visitJobBoardClicked", this.user.id,{
          category : 'Visit Job Board',
          action : 'visit job board clicked',
          userName: this.user.firstName+''+this.user.lastName,
          userEmail: this.user.email
            })
    this.props.onClickAccountItem(null,"/users/talent")
  }
  setCurrentStatus  = (status)=>{
    if(status == 1)
    {
      this.setState({textStatus:'Online'})

    }else if(status == 0){
      this.setState({textStatus:'Offline'})
    }
    else if(status == null){
      this.setState({textStatus:'Online'})
    }
    else{
      this.setState({textStatus:'Invisbile'})
    }
    
  }
  setCurrentStatusIcon  = (status)=>{
    if(status == 1)
    {
      this.setState({iconStatus:'online'})

    }else if(status == 0){
      this.setState({iconStatus:'offline'})
    }
    else if(status == null){
      this.setState({iconStatus:'online'})
    }
    else{
      this.setState({iconStatus:'invisible-status'})
    }
    
  }
  render() {
    const componentDecorator = (href, text, key) => (
      <a
        className="notificationInnerLink"
        href={href}
        key={key}
        style={{ color: "blue", paddingLeft: 0, maxWidth: "100%" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );
    return(
      <section id = "virtualStickyMenu" class="participants-header">
        <div class="container-fluid">
        <div class="modal show" id="status-modal">
	        <div class="modal-dialog modal-dialog-centered">
		<div class="modal-content email-modal text-left bg-white">
			<div class = "modal-header email-header px-4">
				<h6>
					<Trans>set_status</Trans>
				</h6>
				<span class = "e-close" style={{cursor:'pointer'}}
        data-dismiss="modal" id = "close-btn">
					<img src = {icon} alt = "icon"/>
				</span>
			</div>
			<div class="modal-body pb-4">

				<div class="row">
					<div class="col-12 px-4 border-bottom select-status">
						<div class = "form-group form-inline">
							<label class="cm_cont mr-4 online"><Trans>online</Trans>
							  <input type="radio" name="radio" value = "1" checked = {this.state.chatAvailabaility == 1?true:false} onChange = {(e)=>{
                  this.setState({chatAvailabaility:e.target.value})}}
                  />
							  <span class="checkmark">
								<div></div>
							  </span>
							</label>
						</div>
						
						<div class = "form-group form-inline">
							<label class="cm_cont mr-4 offline"><Trans>offline</Trans>
							  <input type="radio" name="radio" value = "0"  checked = {this.state.chatAvailabaility == 0?true:false} onChange = {(e)=>{
                  this.setState({chatAvailabaility:e.target.value,
                    showTime:false,
                    startTime:'09:00 AM',
      endTime:'06:00 PM',
                  })}}
                  />
							  <span class="checkmark">
								<div></div>
							  </span>
							</label>
						</div>
						
						<div class = "form-group form-inline">
							<label class="cm_cont invsible"><Trans>invisible</Trans>  
								<span class = "text-muted ml-2">
									<Trans>invisible_to_other</Trans>
								</span>
							  <input type="radio" name="radio" value = "2" checked = {this.state.chatAvailabaility == 2?true:false} onChange = {(e)=>{
                  this.setState({chatAvailabaility:e.target.value,
                    showTime:false,
                    startTime:'09:00 AM',
      endTime:'06:00 PM',
                  })}}
                  />
							  <span class="checkmark">
								<div></div>
							  </span>
							</label>
						</div>
					</div>
					
					<div class="col-12 px-4 pt-4 set-status-time">
            {
              this.state.chatAvailabaility == 1?
              <>
						<div class = "form-group text-left">
							<span class = "mr-2 switch-txt f-600"><Trans>active_hour</Trans></span>
							<label class="switch mt-2">
                  <input type="checkbox" checked = {this.state.showTime == true ? true :false} onChange ={(e)=>{
                    this.setState({showTime:!this.state.showTime})
                    //this.offTimeLimit()
                  }}/>
                  <span class="slider round"></span>
               </label>
						</div>
						
						<p class = "text-muted">
							<Trans>outside_click</Trans>
						</p>
						
						<div class = {`form-group row mt-4 time-select-status ${this.state.showTime ? '':'d-none'}`}>
							<div class = "col">
								<label>Start</label>
								<select class = "form-control" id ="startTime" value={this.state.startTime} onChange = {(e)=>{
                      
                      this.setState({startTime:e.target.value})
                      var index = parseInt(document.getElementById("startTime").selectedIndex)
                      this.setState({selectedIndex:index+1})
                }}>
                  {
                    this.userTime.map((item)=>{
                      return <option value={item}>{item}</option>

                    })
                  }
									{/* <option>9AM</option>
									<option>10AM</option>
									<option>11AM</option>
									<option>12PM</option> */}
								</select>
							</div>
							
							<div class = "col">
								<label>End</label>
								<select class = "form-control" value={this.state.endTime} onChange = {(e)=>{
                      
                      this.setState({endTime:e.target.value})
                }}>
                {
                    this.userTime.map((item)=>{
                     return  <option value={item}>{item}</option>

                    })
                  }
									{/* <option>6PM</option>
									<option>7PM</option>
									<option>8PM</option>
									<option>9PM</option> */}
								</select>
							</div>
						</div></>:null}
						<div class = "form-group text-right mt-4 status-loader1">
							<button class = {`btn btn-primary telehealth-btn status-change-btn ${this.state.waitForResponse == true ? 'disable-btn':''}`}
              // data-dismiss = "modal" 
              style={{
                pointerEvents:`${this.state.showTime == true && this.state.startTime == null && this.state.endTime==null ? 'none':'unset'}`}}
              onClick = {()=>{
                this.updateUserStatus()
              }}>
								<Trans>Save_Changes</Trans>
							</button>
              {this.state.waitForResponse ? <div className="status-loader">
                                    <Loader
                                        type = 'Oval'
                                        color = "#00b6ff"
                                        height = {25}
                                        width = {25}
                                    />
                                </div> : null}
						</div>
					</div>
					
				</div>
			</div>

		</div>
	</div>
        </div>
          <div class="row">
                <div class="col-12 px-0 px-md-3">
                  <nav class="navbar navbar-expand-lg participant-navbar py-0 px-0 px-lg-3">
                      <a class="navbar-brand text-light d-none d-lg-block">
                          <img src = "/images/v-zone.svg" alt = "virtual_icon" />
                      </a>
                      <ul class = "nav nav-fill v-zone-mob w-100 d-lg-none">
                        {this.props.event.is_jobfair != 1 ?<li class = "nav-item">
                          <a class = "nav-link">
                            <img src = "/images/v-zone.svg"  alt = "virtual_icon"/>
                          </a>
                        </li>:null}
                        <li class = "nav-item" >
                          <a class = "nav-link navbar-toggler navbar-toggler-p" data-target="#collapsibleNavbarb">
                            <i class="fa fa-bars" id="icon-change-b" aria-hidden="true"></i>
                          </a>
                        </li>
                        {this.props.event.is_marketplace == 0 ?<li onClick={(e)=>{
                          e.preventDefault()
                          this.OnClickMenuItem(this.props.eventAlias+'/agenda',0)
                        }}
                        ><a href = "#" class = "nav-link">
									<i class="fas fa-circle mr-2 icon-live"></i>
									  Agenda
								</a>
                </li>:null}
                {this.props.event.is_marketplace == 1 ? 
                        <li class = "nav-item">
                          <a class = "nav-link">{this.props.selectedItemIndex != null ? this.props.menuItems[this.props.selectedItemIndex] == undefined ? '' : this.props.menuItems[this.props.selectedItemIndex].title : ''}</a>
                        </li>:null}
            
                        <li class = "nav-item">
                          <a onClick={() => {
                            this.props.onNotificationSeen();
                            let elem = document.getElementById("notifications-list");
                            if (elem != null) {
                              window.scrollTo(0, 0);
                              elem.classList.add("show");
                              // document.getElementsByTagName("html")[0].style.overflowY =
                              //   "hidden";
                              document.body.style.overflowY = "hidden"
                            }
                          }}
                          style={{ cursor: "pointer" }} 
                          class="nav-link notification-link">
                            
                            <i class="fas fa-bell"></i>{" "} 
                            {this.props.notificationsCount > 0 ? (
                              <span class="badge badge-danger">
                                {this.props.notificationsCount}
                              </span>
                            ) : null}
                          </a>
                        </li>
                        <li>
                        {utility.getJobBoardAccessStatus(this.userType) ?
                      <div class="dropdown job-board"
                      style={{ cursor: "pointer",background: this.props.user.jobFairDisabled ? '#cc3300' : '' }}
                      onClick={()=>{
                        if (!$(".job-board").hasClass("show")) {
                          $(".job-board").addClass("show");
                          $(".header_dropdown").removeClass("show");
                        } else {
                          $(".job-board").removeClass("show");
                        }
                        this.OnClickAccountDropDown()
                        }}>
                        {this.props.user.jobFairDisabled ? <div onClick={this.jobBoardEvent} style={{position: 'absolute',zIndex:999,width : '100%',height : '100%'}}></div> : null}
                        <a class="nav-link dropdown-toggle">
                            <i class="fas fa-briefcase mr-2 position-relative" style = {{top: 3}}></i> Job Board
                        </a>
                        {this.userType == 'jobSeeker' ?
                        <div class="dropdown-menu u-dropdown">
                          <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}><Trans>visitJobBoard</Trans></a>
                         <a class="dropdown-item" onClick = {this.visitJobSeeker}><Trans>jobSeeker</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/my-jobs")}><Trans>myApplications</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}><Trans>jobMessages</Trans></a>
                        </div> : 
                        <div class="dropdown-menu u-dropdown">
                        <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}><Trans>visitJobBoard</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/users/company")}><Trans>companyProfile</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/manage-jobs")}><Trans>manageJobs</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}><Trans>jobMessages</Trans></a>
                      </div>}
                      </div> : null}
                        </li>
            
                        <li class = "nav-item">
                          <div class="dropdown header_dropdown d-block d-lg-none data" id="targetDrop" onClick={this.OnClickAccountDropDown}>
                            <a class="nav-link p_name dropdown-toggle">
                            <span className={`img-div ${this.state.iconStatus}`}>
                              {this.props.user.profileImageUrl == null ||
                              this.props.user.profileImageUrl == "" ? (
                                <i
                                  class="fas fa-user-circle"
                                  style={{ fontSize: 25 }}
                                ></i>
                              ) : (
                                <img
                                  src={this.props.user.profileImageUrl}
                                  alt="participant_image"
                                />
                              )}
                              </span>	
                            </a>
                            <div class="dropdown-menu u-dropdown dropdown-menu-right">
                            <a class="dropdown-item status-control" style={{background:"#313841"}} href="#" 
										                 data-toggle = "modal" data-target = "#status-modal">
										        	<span class = {`status-icn 
                              ${this.state.iconStatus}`}></span>
                               {this.state.textStatus == "Online" ? <Trans>online</Trans>:this.state.textStatus == "Offline" ? <Trans>offline</Trans> :'Invisible'}
											     <span class = "text-secondary ml-2"> <Trans>status_change</Trans> </span>
										          </a>
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(0)}><Trans>myProfile</Trans></a>
                              {this.props.event.isFree != null && this.props.event.isFree == 1 ? null :<a class="dropdown-item" onClick={() => this.props.onClickAccountItem(1)}><Trans>myBookings</Trans></a>}
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(2)}><Trans>myFavourites</Trans></a>
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(3)}><Trans>changePassword</Trans> </a>
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(-1)}><Trans>logout</Trans></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <MDBCollapse
                        isOpen={this.state.menuToggle}
                        className="navbar-collapse v-zone-nc"
                        id="collapsibleNavbarb"
                      >
                        <ul class="navbar-nav text-center mx-auto p-3 p-lg-0">
                            {this.props.showHomeButton ? (
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this.OnClickMenuItem("/")}
                                >
                                  <Trans>home</Trans>
                                </a>
                              </li>
                            ) : null}
                            {this.props.menuItems.map((item, index) => {
                              return (
                                item.path != null ?
                                <li
                                  class={
                                    this.props.selectedItemIndex == index
                                      ? "nav-item position-relative active"
                                      : "nav-item position-relative"
                                  }
                                >
                                  {item.path == "/agenda" ? (
                                    <i class="fas fa-circle mr-2 icon-live d-none d-lg-block"></i>
                                  ) : null}
                                  <a
                                    class="nav-link"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (item.path == null) {
                                        window.fcWidget.open();
                                        window.fcWidget.show();
                                        this.OnClickMenuItem("", 5);
                                      } else {
                                        this.OnClickMenuItem(
                                          this.props.eventAlias + item.path,
                                          index
                                        );
                                      }
                                    }}
                                  >
                                    {item.title}
                                    {item.path == "/userChat" &&
                                    this.props.unReadCount > 0 ? (
                                      <span style={{right: 1}} class="badge chat badge-danger">
                                        {this.props.unReadCount}
                                      </span>
                                    ) : null}
                                    
                                  </a>
                                </li>
                                :null
                              );
                            })}
                            {this.state.support > -1 ?<li class={this.props.selectedItemIndex == 9 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>{
                                            window.fcWidget.open()
                                            this.OnClickMenuItem('',5)
                                        }} >Support</a>
                                    </li>:null}
                            <li class="nav-item position-relative">
                              <a onClick={() => {
                                this.props.onNotificationSeen();
                                this.props.RemoveScroll(1)
                                let elem = document.getElementById("notifications-list");
                                if (elem != null) {
                                  window.scrollTo(0, 0);
                                  elem.classList.add("show");
                                  // document.getElementsByTagName("html")[0].style.overflowY =
                                  //   "hidden";
                                  document.body.style.overflowY = "hidden"
                                }
                              }}
                              style={{ cursor: "pointer" }} 
                              class="nav-link notification-link d-none d-lg-block">
                                
                                <i class="fas fa-bell"></i>{" "} 
                                {this.props.notificationsCount > 0 ? (
                                  <span class="badge badge-danger">
                                    {this.props.notificationsCount}
                                  </span>
                                ) : null}
                              </a>
                            </li>
                          </ul>
                      </MDBCollapse>
                      <a onClick={() => {
                            this.props.onNotificationSeen();
                            let elem = document.getElementById("notifications-list");
                            if (elem != null) {
                              window.scrollTo(0, 0);
                              elem.classList.add("show");
                              // document.getElementsByTagName("html")[0].style.overflowY =
                              //   "hidden";
                              document.body.style.overflowY = "hidden"
                            }
                          }}
                          style={{ cursor: "pointer",top:"0 !important" }}  
                          class="nav-link notification-link d-none">
                          <i class="fas fa-bell"></i>{" "}
                          {this.props.notificationsCount > 0 ? (
                            <span class="badge badge-danger">
                              {this.props.notificationsCount}
                            </span>
                          ) : null}
                      </a>
                      {utility.getJobBoardAccessStatus(this.userType) ?
                      <div class="dropdown job-board mob-version"
                      style={{ cursor: "pointer",background: this.props.user.jobFairDisabled ? '#cc3300' : '' }}
                      onClick={()=>{
                        if (!$(".job-board").hasClass("show")) {
                          $(".job-board").addClass("show");
                          $(".header_dropdown").removeClass("show");
                        } else {
                          $(".job-board").removeClass("show");
                        }
                        this.OnClickAccountDropDown()
                        }}>
                        {this.props.user.jobFairDisabled ? <div onClick={this.jobBoardEvent} style={{position: 'absolute',zIndex:999,width : '100%',height : '100%'}}></div> : null}
                        <a class="nav-link dropdown-toggle">
                            <i class="fas fa-briefcase mr-2 position-relative" style = {{top: 3}}></i> <Trans>jobBoard</Trans>
                        </a>
                        {this.userType == 'jobSeeker' ?
                        <div class="dropdown-menu u-dropdown">
                          <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}><Trans>visitJobBoard</Trans></a>
                         <a class="dropdown-item" onClick = {this.visitJobSeeker}><Trans>jobSeeker</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/my-jobs")}><Trans>myApplications</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}><Trans>jobMessages</Trans></a>
                        </div> : 
                        <div class="dropdown-menu u-dropdown">
                        <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}><Trans>visitJobBoard</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/users/company")}><Trans>companyProfile</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/manage-jobs")}><Trans>manageJobs</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}><Trans>jobMessages</Trans></a>
                      </div>}
                      </div> : null}
                      <div style={{cursor: 'pointer'}} class="dropdown header_dropdown d-none d-lg-block data" onClick={this.OnClickAccountDropDown}>
                          <a class="nav-link p_name dropdown-toggle" >
                              <span style={{display:'contents'}} data-abbr=""><Trans>myAccount</Trans></span>
                              <span className={`img-div ${this.state.iconStatus}`}>
                              {this.props.user.profileImageUrl == null ||
                              this.props.user.profileImageUrl == "" ? (
                                <i
                                  class="fas fa-user-circle"
                                  style={{ fontSize: 25 }}
                                ></i>
                              ) : (
                                <img
                                  src={this.props.user.profileImageUrl}
                                  alt="participant_image"
                                />
                              )}
                              </span>		
                          </a>
                          <div class="dropdown-menu dropdown-menu-right u-dropdown">
                          <a class="dropdown-item status-control" href="#" style={{background:"#313841"}}
										data-toggle = "modal" data-target = "#status-modal">
											<span class = {`status-icn 
                      ${this.state.iconStatus}`}></span> 
   {this.state.textStatus == "Online" ? <Trans>online</Trans>:this.state.textStatus == "Offline" ? <Trans>offline</Trans> :'Invisible'}
											<span class = "text-secondary ml-2"> <Trans>status_change</Trans> </span>
										</a>       <div class="dropdown-divider"></div>
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(0)}><Trans>myProfile</Trans></a>
                              {this.props.event.isFree != null && this.props.event.isFree == 1 ? null :<a class="dropdown-item" onClick={() => this.props.onClickAccountItem(1)}><Trans>myBookings</Trans></a>}
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(2)}><Trans>myFavourites</Trans></a>
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(3)}><Trans>changePassword</Trans> </a>
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" onClick={() => this.props.onClickAccountItem(-1)}><Trans>logout</Trans></a>
                          </div>
                      </div>
                  </nav>
                </div>
            </div>
        </div>
        <div
          class="modall fade right notification-modal"
          id="notifications-list"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content notification-screen">
              <div class="modal-body px-0 pt-0">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let elem = document.getElementById("notifications-list");
                    elem.classList.remove("show");
                    // document.getElementsByTagName("html")[0].style.overflowY =
                    //   "scroll";
                    document.body.style.overflowY = "hidden"
                    if(!window.location.href.split('/').includes('participants')){
                    this.props.RemoveScroll(0)
                    }
                  }}
                  class="close-icons"
                  id="close-m"
                >
                  <img src="/images/ic_chat_close.svg" alt="close-icon-btn" onClick = {()=>{
                  }} />
                </a>
                <div class="row mx-0 border-bottom notification-header">
                  <div class="col-12 py-3">
                    <h5 class="f-600 mb-0"><Trans>announcements</Trans></h5>
                  </div>
                </div>
                {this.props.notifications && this.props.notifications.length > 0 ? <div style={{width:'100%',display:'flex',justifyContent:'flex-end',padding:'6px 10px'}} className="notification-header"> <span style={{textDecoration:'underline'}}
                      onClick={() => this.props.onClearNotifications()}
                      id="clear_all"
                    >
                      <Trans>clearAll</Trans>
                    </span></div>:null}
                <div style={{ height:'700px',overflow:'scroll'}}>
                {this.props.notifications != null ? (
                  this.props.notifications.length == 0 ? (
                    <div class="row notification-placeholder blank-tab mx-0">
                      <div class="col text-center px-4">
                        <img class="mb-3" src="/images/icons/bell-ic.svg" alt="blank-icon" />
                        <h5><Trans>noNotifications</Trans></h5>
                        <p>
                        <Trans>noNotifications1</Trans>
                        </p>
                      </div>
                    </div>
                  ) : (
                    this.props.notifications.map((item, index) => {
                      let readClass = item.read_at == null ? " selected" : "";
                      return (
                        <div
                          onClick={(e) => {
                            if (this.innerLinkClicked) {
                              return;
                            }
                            if (item.action_url != null) {
                              this.props.onClickNotification(
                                item.action_url,
                                item.is_external_link ||
                                  item.action_url.indexOf("http") > 0 ||
                                  item.action_url.indexOf("https") > 0
                              );
                            }
                            this.props.RemoveScroll(1)
                          }}
                          class={
                            "row mx-0 py-2 border-bottom notification-list" +
                            readClass
                          }
                        >
                          <div class="col-2 d-flex align-items-center pr-0">
                            <div class="notification-list-icon">
                              <i class="fas fa-bell"></i>
                            </div>
                          </div>

                          <div class="col-10 d-flex align-items-center">
                            <div class="participant-details">
                              <div class="post">
                                <h6 class="spk-full-name">{`${item.title}`.replace(null,'')}</h6>
                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  {item.body == null ? null : <p>{item.body}</p>}
                                </Linkify>{" "}
                                {item.body == null ? null :<br />}
                                <span>{item.timeString}</span>
                              </div>
                              {/* <a
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // this.props.RemoveScroll(0)
                                  this.props.onClearNotifications([item.id],index);
                                }}
                                class="close-notification"
                              >
                                <img
                                  src="/images/ic_chat_close.svg"
                                  alt="close-icon-btn"
                                />
                              </a> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )
                ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PermiumUserModal
            id = "disableUserModal"
            iconClass = "fa-user-alt-slash"
            title = "Attention"
            description = "<p>Your profile is disabled.<br>For further assistance please contact CINDE Administration <a href='mailto:support@cinde.com'>support@cinde.com</a></p>"
            buttonText = "Ok"
            showButton = {true}
            onClose = {()=>{
              $("#disableUserModal").modal('hide')
              $('.modal-backdrop').remove();
          }}
            onClickBookNow = {()=>{
                $("#disableUserModal").modal('hide')
                $('.modal-backdrop').remove();
        }}/>
    </section>
    )
    return (
      <section class="participants-header bg-dark" id="virtualStickyMenu">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <nav class="navbar navbar-expand-lg participant-navbar py-0 px-0 px-lg-3">
                <a class="navbar-brand text-light d-none d-md-block">
                  <img
                    src="/images/ic_virtual.svg"
                    class="mr-2"
                    alt="virtual_icon"
                  />
                 <Trans>virtualZone.title</Trans>
                </a>
                <a
                  class="nav-link notification-link d-block d-lg-none"
                  onClick={() => {
                    this.props.onNotificationSeen();
                    let elem = document.getElementById("notifications-list");
                    if (elem != null) {
                      window.scrollTo(0, 0);
                      elem.classList.add("show");
                      document.getElementsByTagName("html")[0].style.overflowY =
                        "hidden";
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i class="fas fa-bell"></i>{" "}
                  {this.props.notificationsCount > 0 ? (
                    <span class="badge badge-danger">
                      {this.props.notificationsCount}
                    </span>
                  ) : null}
                </a>


                {/* <!--job board dropdown for mobile --> */}
                         
                {this.userType ? (
                   <div class="dropdown job-board d-block d-lg-none"
                   style={{ cursor: "pointer",background: this.props.user.jobFairDisabled ? '#cc3300' : '' }}
                   // onClick={this.onClickJobBoardDropDown}
                   onClick={this.OnClickAccountDropDown}>
                       {this.props.user.jobFairDisabled ? <div onClick={this.jobBoardEvent} style={{position: 'absolute',zIndex:999,width : '100%',height : '100%'}}></div> : null}
                       <a class="nav-link dropdown-toggle" >
                          <i class="fas fa-briefcase mr-2 position-relative" style = {{top:3}}></i><Trans>jobBoard</Trans>
                       </a>
                       {(this.userType === "jobSeeker") ? 
                       (<div class="dropdown-menu u-dropdown">
                       <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}><Trans>visitJobBoard</Trans></a>
                         <a class="dropdown-item" onClick = {this.visitJobSeeker}><Trans>jobSeeker</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/my-jobs")}><Trans>myApplications</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}><Trans>jobMessages</Trans></a>
                         </div>)
                       :(
                       <div class="dropdown-menu u-dropdown">
                       <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}><Trans>visitJobBoard</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/users/company")}><Trans>companyProfile</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/manage-jobs")}><Trans>manageJobs</Trans></a>
                         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}><Trans>jobMessages</Trans></a>
                         </div>  
                       )
                       }
                       {/* <div class="dropdown-menu u-dropdown">
         <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem()}><Trans>visitJobBoard</Trans></a>
                           <a class="dropdown-item" href="#"><Trans>jobSeeker</Trans></a>
                           <a class="dropdown-item" href="#"><Trans>myApplications</Trans></a>
                           <a class="dropdown-item" href="#"><Trans>messages</Trans></a>
                       </div> */}
                   </div>
                ) : null}
                  
							{/* <!--/job board dropdown for mobile --> */}

                
                  <div
                  class="dropdown header_dropdown d-block d-lg-none"
                  style={{ cursor: "pointer" }}
                  onClick={this.OnClickAccountDropDown}
                >
                  <a class="nav-link p_name dropdown-toggle">
                    {/* {this.props.user.firstName +
                      " " +
                      this.props.user.lastName +
                      " "} */}
                    {this.props.user.profileImageUrl == null ||
                    this.props.user.profileImageUrl == "" ? (
                      <i
                        class="fas fa-user-circle"
                        style={{ fontSize: 25, marginLeft: 10 }}
                      ></i>
                    ) : (
                      <img
                        src={this.props.user.profileImageUrl}
                        alt="participant_image"
                      />
                    )}
                  </a>
                  <div class="dropdown-menu dropdown-menu-right u-dropdown">
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(0)}
                    >
                      <Trans>myProfile</Trans>
                    </a>
                    {/* <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem()}>Job Board</a> */}
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(1)}
                    >
                      <Trans>myBookings</Trans>
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(2)}
                    >
                      <Trans>myFavourites</Trans>
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(3)}
                    >
                      <Trans>changePassword</Trans>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(-1)}
                    >
                      <Trans>logout</Trans>
                    </a>
                  </div>
                </div>
                
                

                <button
                  class="navbar-toggler navbar-toggler-p"
                  type="button"
                  data-target="#collapsibleNavbarb"
                >
                  {/* <!-- <span class="navbar-toggler-icon"></span> --> */}
                  <i
                    class="fa fa-bars"
                    id="icon-change-b"
                    aria-hidden="true"
                  ></i>
                </button>
                <MDBCollapse
                  isOpen={this.state.menuToggle}
                  className="navbar-collapse"
                  id="collapsibleNavbarb"
                >
                  <ul class="navbar-nav text-center ml-auto p-3 p-lg-0">
                    {this.props.showHomeButton ? (
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.OnClickMenuItem("/")}
                        >
                          Home
                        </a>
                      </li>
                    ) : null}
                    {this.props.menuItems.map((item, index) => {
                      return (
                        <li
                          class={
                            this.props.selectedItemIndex == index
                              ? "nav-item active position-relative"
                              : "nav-item position-relative"
                          }
                        >
                          {item.path == "/agenda" ? (
                            <i class="fas fa-circle mr-2 icon-live"></i>
                          ) : null}
                          <a
                            class="nav-link"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (item.path == null) {
                                window.fcWidget.open();
                                window.fcWidget.show();
                                this.OnClickMenuItem("", 5);
                              } else {
                                this.OnClickMenuItem(
                                  this.props.eventAlias + item.path,
                                  index
                                );
                              }
                            }}
                          >
                            {item.title}
                            {item.path == "/userChat" &&
                            this.props.unReadCount > 0 ? (
                              <span class="badge chat badge-danger">
                                {this.props.unReadCount}
                              </span>
                            ) : null}
                          </a>
                        </li>
                      );
                    })}
                    {/* <li class={this.props.selectedItemIndex == 0 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickMenuItem(this.props.eventAlias+'/agenda',0)}>Agenda</a>
                                    </li>
                                    <li class={this.props.selectedItemIndex == -1 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickMenuItem(this.props.eventAlias+'/lobby',-1)}>Lobby</a>
                                    </li>
                                    <li class={this.props.selectedItemIndex == 1 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickMenuItem(this.props.eventAlias+'/virtual_exhibition',1)}>Exhibition</a>
                                    </li>
                                    <li class={this.props.selectedItemIndex == 2 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickMenuItem(this.props.eventAlias+'/invitations',2)}>My Calendar</a>
                                    </li>
                                    <li class={this.props.selectedItemIndex == 3 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickMenuItem(this.props.eventAlias+'/participants',3)}>Participants</a>
                                    </li>
                                    <li class={this.props.selectedItemIndex == 4 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>this.OnClickMenuItem(this.props.eventAlias+'/userChat',4)}>
                                            Chats {this.props.unReadCount > 0 ?<span class="badge badge-danger">{this.props.unReadCount}</span> : null}
                                        </a>
                                    </li>
                                    <li class={this.props.selectedItemIndex == 5 ? "nav-item active" : "nav-item"}>
                                        <a class="nav-link" style = {{cursor : 'pointer'}} onClick = {()=>{
                                            window.fcWidget.open()
                                            this.OnClickMenuItem('',5)
                                        }} >Support</a>
                                    </li> */}
                  </ul>
                </MDBCollapse>

                <a
                  onClick={() => {
                    this.props.onNotificationSeen();
                    let elem = document.getElementById("notifications-list");
                    if (elem != null) {
                      window.scrollTo(0, 0);
                      elem.classList.add("show");
                      document.getElementsByTagName("html")[0].style.overflowY =
                        "hidden";
                    }
                  }}
                  style={{ cursor: "pointer" }}
                  class="nav-link notification-link d-none d-lg-block"
                >
                  <i class="fas fa-bell"></i>{" "}
                  {this.props.notificationsCount > 0 ? (
                    <span class="badge badge-danger">
                      {this.props.notificationsCount}
                    </span>
                  ) : null}
                </a>

                {/* <!-- job board dropdown for desktop --> */}
               {this.userType ? (
                 <div
                 class="dropdown job-board d-none d-lg-block"
                 style={{ cursor: "pointer",background: this.props.user.jobFairDisabled ? '#cc3300' : '' }}
                 // onClick={this.onClickJobBoardDropDown}
                 onClick={this.OnClickAccountDropDown}
               >
                 {this.props.user.jobFairDisabled ? <div onClick={this.jobBoardEvent} style={{position: 'absolute',zIndex:999,width : '100%',height : '100%'}}></div> : null}
                 <a class="nav-link p_name dropdown-toggle">
                   <i
                     class="fas fa-briefcase mr-2 position-relative"
                     style={{ top: 3 }}
                   ></i>
                   <Trans>jobBoard</Trans>
                 </a>
                 {(this.userType === "jobSeeker") ? 
                 (<div class="dropdown-menu dropdown-menu-right u-dropdown">
                 <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}>
                 <Trans>visitJobBoard</Trans>
                 </a>
                 {/* onClick={() => { $('#job-modal').modal('show') }} */}
                 <a class="dropdown-item" onClick = {this.visitJobSeeker}>
                   <Trans>jobSeeker</Trans>
                 </a>
                 <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/my-jobs")}>
                   <Trans>myApplications</Trans>
                 </a>
                 <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}>
                   <Trans>jobMessages</Trans>
                 </a>
               </div>)
                 :(<div class="dropdown-menu dropdown-menu-right u-dropdown">
                   <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"")}>
                   <Trans>visitJobBoard</Trans>
                   </a>
                   <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/users/company")}>
                     <Trans>companyProfile</Trans>
                   </a>
                   <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/manage-jobs")}>
                     <Trans>manageJobs</Trans>
                   </a>
                   <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem(null,"/chat")}>
                     <Trans>jobMessages</Trans>
                   </a>
                 </div>
               ) 
          } </div>
               ) : null }
                
                  {/* <div class="dropdown-menu dropdown-menu-right u-dropdown">
                    <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem()}>
                      Visit Job Board
                    </a>
                    <a class="dropdown-item" href="#">
                      My Job Seaker Profile
                    </a>
                    <a class="dropdown-item" href="#">
                      My Jobs
                    </a>
                    <a class="dropdown-item" href="#">
                      Messages
                    </a>
                  </div>
                </div> */}
                {/* <!-- /job board dropdown for desktop --> */}

                <div
                  class="dropdown header_dropdown d-none d-lg-block"
                  style={{ cursor: "pointer" }}
                  onClick={this.OnClickAccountDropDown}
                >
                  <a class="nav-link p_name dropdown-toggle" data-abbr={this.props.user.firstName + " " + (this.props.user.secondName ? this.props.user.secondName + " ": "")+ this.props.user.lastName}>
                    <span>{this.props.user.firstName + " " + (this.props.user.secondName ? this.props.user.secondName + " ": "")+ this.props.user.lastName}</span>
                    {this.props.user.profileImageUrl == null ||
                    this.props.user.profileImageUrl == "" ? (
                      <i
                        class="fas fa-user-circle"
                        style={{ fontSize: 25, marginLeft: 10 }}
                      ></i>
                    ) : (
                      <img
                        src={this.props.user.profileImageUrl}
                        alt="participant_image"
                      />
                    )}
                  </a>

                  <div class="dropdown-menu dropdown-menu-right u-dropdown">
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(0)}
                    >
                      <Trans>myProfile</Trans>
                    </a>
                    {/* <a class="dropdown-item" onClick = {()=>this.props.onClickAccountItem()}>Job Board</a> */}
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(1)}
                    >
                      <Trans>myBookings</Trans>
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(2)}
                    >
                      <Trans>myFavourites</Trans>
                    </a>
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(3)}
                    >
                     <Trans>changePassword</Trans> 
                    </a>
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      onClick={() => this.props.onClickAccountItem(-1)}
                    >
                      <Trans>logout</Trans>
                    </a>
                  </div>
                </div>
              </nav>
            </div>

            {/* <!-- <div class = "col-lg-4">
                        <ul class=" list-inline mb-0 text-right">
                            <li class="list-inline-item px-2">
                                <a class="nav-link" href="#">
                                    <i class="fas fa-bell"></i> <span class="badge badge-danger">3</span>
                                </a>
                            </li>
                            <li class="list-inline-item px-2">
                                <a class="nav-link p_name" href="#">
                                    Muzummil Ahmed Khan
                                    <img src="images/members/Nassr_Oman.jpg" alt="participant_image"> 
                                </a>
                            </li>
                        </ul>
                    </div> --> */}
          </div>
        </div>
        <div
          class="modall fade right notification-modal"
          id="notifications-list"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body px-0 pt-0">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let elem = document.getElementById("notifications-list");
                    elem.classList.remove("show");
                    document.getElementsByTagName("html")[0].style.overflowY =
                      "scroll";
                  }}
                  class="close-icons"
                  id="close-m"
                >
                  <img src="/images/ic_chat_close.svg" alt="close-icon-btn" />
                </a>
                <div class="row mx-0 border-bottom notification-header">
                  <div class="col-12 py-3">
                    <h5 class="f-600 mb-0"><Trans>announcements</Trans></h5>
                    <span
                      onClick={() => this.props.onClearNotifications()}
                      id="clear_all"
                    >
                      <Trans>clearAll</Trans>
                    </span>
                  </div>
                </div>
                {this.props.notifications != null ? (
                  this.props.notifications.length == 0 ? (
                    <div
                      style={{
                        width: "100%",
                        height: "70%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Trans>noNotifications</Trans>
                    </div>
                  ) : (
                    this.props.notifications.map((item, index) => {
                      let readClass = item.read_at == null ? " selected" : "";
                      return (
                        <div
                          onClick={(e) => {
                            if (this.innerLinkClicked) {
                              return;
                            }
                            if (item.action_url != null) {
                              this.props.onClickNotification(
                                item.action_url,
                                item.is_external_link ||
                                  item.action_url.indexOf("http") > 0 ||
                                  item.action_url.indexOf("https") > 0
                              );
                            }
                          }}
                          class={
                            "row mx-0 py-2 border-bottom notification-list" +
                            readClass
                          }
                        >
                          <div class="col-2 d-flex align-items-center pr-0">
                            <div class="notification-list-icon">
                              <i class="fas fa-bell"></i>
                            </div>
                          </div>

                          <div class="col-10 d-flex align-items-center">
                            <div class="participant-details">
                              <div class="post">
                                <h6 class="spk-full-name">{item.title}</h6>
                                <Linkify
                                  componentDecorator={componentDecorator}
                                >
                                  {item.body}
                                </Linkify>{" "}
                                <br />
                                <span>{item.timeString}</span>
                              </div>
                              {/* <a
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.props.onClearNotifications([item.id],index);
                                }}
                                class="close-notification"
                              >
                                <img
                                  src="/images/ic_chat_close.svg"
                                  alt="close-icon-btn"
                                />
                              </a> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <PermiumUserModal
            id = "disableUserModal"
            iconClass = "fa-user-alt-slash"
            title = "Attention"
            description = "<p>Your profile is disabled.<br>For further assistance please contact CINDE Administration <a href='mailto:support@cinde.com'>support@cinde.com</a></p>"
            buttonText = "Ok"
            showButton = {true}
            onClose = {()=>{
              $("#disableUserModal").modal('hide')
              $('.modal-backdrop').remove();
          }}
            onClickBookNow = {()=>{
                $("#disableUserModal").modal('hide')
                $('.modal-backdrop').remove();
        }}/>
      </section>
    );
  }
}
const mapStateToProps = state => ({
  // participants : state.participants.participantsList,
  // userChatList : state.chat.chatList,
  removeScroll : state.virtualExhibition.showScroll
})
const mapDispatchToProps = dispatch => ({
  RemoveScroll : data => dispatch(RemoveScroll(data))
})
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VirtualZone);

